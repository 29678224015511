import { createSelector } from 'reselect'
import { merge } from 'lodash'
import numeral from 'numeral'
import baseColumnChartConfig from '../Misc/baseColumnChartConfig'

const selectEstimatesByClientReport = state => state.screens.Dashboard.estimatesByClientReport

const types = {
  quantity: {
    label: 'Quantity',
    prefix: '',
  },
  subtotal: {
    label: 'Subtotal',
    prefix: '£',
  },
}

const dateRanges = {
  last_three_months: {
    label: 'Last 3 Months',
  },
  year_to_date: {
    label: 'Year to Date',
  },
}

export default createSelector(
  selectEstimatesByClientReport,
  (estimatesByClientReport) => {
    
    const { data, type, dateRange } = estimatesByClientReport

    const wonConfig = merge({}, baseColumnChartConfig, {

      chart: {
        height: 450,
      },

      title: {
        text: `Won ${types[type].label} - ${dateRanges[dateRange].label}`,
        margin: 10,
        style: {
          fontSize: 14,
        },
      },

      colors: ['#0AD600'],

      xAxis: {
        categories: data.won.map(row => row.field),
        labels: {
          useHTML: true,
          style: {
            width: 100,
            whiteSpace: 'normal',
          },
          step: 1,
          formatter: function () {
            return '<div align="right" style="word-wrap: break-word;word-break: break-all; width:100px;">' + this.value + '</div>'
          },
        },
      },

      yAxis: {
        title: false,
        labels: {
          formatter: function () {
            return `${types[type].prefix}${numeral(this.value).format('0,0')}`
          },
        },
      },

      tooltip: {
        pointFormat: '<b>{series.name}</b><br />Quantity: <b>{point.quantity:,.0f}</b><br />Subtotal: <b>£{point.subtotal:,.0f}</b>',
      },

      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: `${types[type].prefix}{point.y:,.0f}`,
          },
        },
      },

      series: [
        {
          name: 'Won',
          data: data.won.map(row => ({
            y: row[`won_${type}`],
            quantity: row.won_quantity,
            subtotal: row.won_subtotal,
          })),
        },
      ],

    })

    const lostConfig = merge({}, baseColumnChartConfig, {

      chart: {
        height: 450,
      },

      title: {
        text: `Lost ${types[type].label} - ${dateRanges[dateRange].label}`,
        margin: 10,
        style: {
          fontSize: 14,
        },
      },

      colors: ['#FF0000'],

      xAxis: {
        categories: data.lost.map(row => row.field),
        labels: {
          useHTML: true,
          style: {
            width: 100,
            whiteSpace: 'normal',
          },
          step: 1,
          formatter: function () {
            return '<div align="right" style="word-wrap: break-word;word-break: break-all; width:100px;">' + this.value + '</div>'
          },
        },
      },

      yAxis: {
        title: false,
        labels: {
          formatter: function () {
            return `${types[type].prefix}${numeral(this.value).format('0,0')}`
          },
        },
      },

      tooltip: {
        pointFormat: '<b>{series.name}</b><br />Quantity: <b>{point.quantity:,.0f}</b><br />Subtotal: <b>£{point.subtotal:,.0f}</b>',
      },

      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: `${types[type].prefix}{point.y:,.0f}`,
          },
        },
      },

      series: [
        {
          name: 'Lost',
          data: data.lost.map(row => ({
            y: row[`lost_${type}`],
            quantity: row.lost_quantity,
            subtotal: row.lost_subtotal,
          })),
        },
      ],

    })

    return [wonConfig, lostConfig]

  }
)
