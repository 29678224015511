import React from 'react';
import { Select } from 'antd';

import ChartPanel from './ChartPanel';
import Chart from './Chart';

type Props = {
  config: Object,
  clients: [],
  clientId: any,
  onChangeClientId: Function
}

type State = {
  searchTerm: string
}

export default class extends React.PureComponent<Props, State> {

  state = {
    searchTerm: undefined
  }

  handleSearch = (value) => {
    this.setState({ searchTerm: value })
  }

  handleChange = (value) => {
    this.props.onChangeClientId(value);
    this.setState({ searchTerm: undefined })
  }
  
  render = () => (
      
    <ChartPanel
      title="Last Three Months By Client"
      renderTools={() => this.renderTools()}
      renderChart={() => this.renderChart()}
    />
      
  )

  renderTools = () => (

    <Select
      value={this.props.clientId}
      placeholder="All Clients"
      defaultActiveFirstOption={false}
      showArrow={false}
      showSearch
      allowClear
      filterOption={false}
      notFoundContent={null}
      onSearch={this.handleSearch}
      onChange={this.handleChange}
      style={{ width: 330 }}
    >
      {this.props.clients.filter(opt => !this.state.searchTerm || opt.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())).map(opt => (
        <Select.Option key={opt.id}>
          {opt.name}
        </Select.Option>
      ))}
    </Select>

  )

  renderChart = () => [
    <Chart key={0} config={this.props.config[0]} />,
    <Chart key={1} config={this.props.config[1]} />,
    <Chart key={2} config={this.props.config[2]} />
  ]

}
