import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { clientContact } = getProps();
  
  if (getProps().clientContact) {
    
    dispatch(initialize(FORM_NAME, clientContact));

  }

}