import { fromJS } from 'immutable';
import { loadOrders } from '../State/orders'

export default ({ orderIndex, orderGroupIndex, orderItemIndex, value }) => (dispatch, getState) => {

  let orders = fromJS(getState().screens.ProjectView.TargetCosts.orders);

  const orderItem = orders.getIn([orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex]).toJS();

  const total = Number(orderItem.total);

  let targetCostAmount = Number(value);
  targetCostAmount = Number.isNaN(targetCostAmount) ? 0 : targetCostAmount; // reset to 0 if not a number
  if (total >= 0) targetCostAmount = targetCostAmount > Number(orderItem.total) ? Number(orderItem.total) : targetCostAmount; // dont go above order item total
  if (total >= 0) targetCostAmount = targetCostAmount < 0 ? 0 : targetCostAmount; // dont go below 0
  if (total < 0) targetCostAmount = targetCostAmount < Number(orderItem.total) ? Number(orderItem.total) : targetCostAmount; // dont go below order item total
  if (total < 0) targetCostAmount = targetCostAmount > 0 ? 0 : targetCostAmount; // dont go above 0
  targetCostAmount = Number(targetCostAmount.toFixed(2)); // round to 2 decimal spaces

  let targetCostPercentage = total !== 0 ? ((targetCostAmount / total) * 100) : 100;
  targetCostPercentage = Number(targetCostPercentage.toFixed(6)); // round to 6 decimal spaces

  orders = orders.updateIn(
    [orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex],
    map => map.merge(fromJS({
      target_cost_percentage: targetCostPercentage,
      target_cost_amount: value,
    }))
  );

  orders = orders.toJS();

  dispatch(loadOrders(orders));

}