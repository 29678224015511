import { message } from 'antd'

import { completeProject} from '../../../HttpRequests/projects';
import handleException from '../../../Core/Helpers/handleException';
import { getProps } from '../props';

export default (values) => async () => {

  try {
    const { projectId } = getProps();

    const data = values.retentionItems;
    
    await completeProject({ id: projectId, data });

    message.success("Project Complete.");

  } catch(error) {

    handleException(error);

  }
}