import { createAction, handleActions } from 'redux-actions';

const loadOrders = createAction("SCREENS/PROJECT_VIEW/TARGET_COSTS/LOAD_ORDERS");
const clearOrders = createAction("SCREENS/PROJECT_VIEW/TARGET_COSTS/CLEAR_ORDERS ");

const defaultState = null

const reducer = handleActions(
  {
    [loadOrders]: (state, action) => action.payload,
    [clearOrders]: () => null
  },
  defaultState
);

export { loadOrders, clearOrders, reducer };