// @flow

import { getProps } from '../props';
import update from '../Services/update';

export default async (values: Function, dispatch: Function) => {
  
  const { onClose, didCreate } = getProps();

  await dispatch(update(values));
  
  onClose();

  if (didCreate) didCreate()
    
  
  
}