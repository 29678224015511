
import { createAction, handleActions } from 'redux-actions';

const setShowEstimateModal = createAction("SCREENS/ESTIMATES/SET_SHOW_ESTIMATE_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowEstimateModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowEstimateModal, reducer };