import { first } from 'lodash';
import { setActiveItemInputKeys } from '../State/activeItemInputKeys';

export default () => async (dispatch, getState) => {

  const { orders, activeItemInputKeys } = getState().screens.ProjectView.TargetCosts;

  const orderItemIds = [];

  orders.forEach((order) =>
    order.order_groups.forEach((orderGroup) =>
      orderGroup.order_items.forEach((orderItem) => 
        orderItemIds.push(orderItem.id)
      )
    )
  )

  if (!activeItemInputKeys) return;

  if (activeItemInputKeys.input === 'percentage') {

    dispatch(setActiveItemInputKeys({
      orderItemId: activeItemInputKeys.orderItemId,
      input: 'amount'
    }));

  } else {

    const currentIndex = orderItemIds.indexOf(activeItemInputKeys.orderItemId);

    let orderItemId = null;

    if (currentIndex === (orderItemIds.length-1)) {
      orderItemId = first(orderItemIds);
    } else {
      orderItemId = orderItemIds[currentIndex+1];
    }

    dispatch(setActiveItemInputKeys({
      orderItemId,
      input: 'percentage'
    }));

  }
  

}