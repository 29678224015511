import React from 'react';
import styles from './ChartPanel.css';

type Props = {
  title: string,
  renderTools: any,
  renderChart: any
}

export default class extends React.PureComponent<Props> {
  
  render () {

    const { title, renderTools, renderChart } = this.props;

    return (
      
      <div className={styles.panel}>

        <div className={styles.header}>

          <div className={styles.title}>
            {title}
          </div>

          <div className={styles.tools}>

            {renderTools && renderTools()}

          </div>

        </div>

        <div className={styles.chart}>

          {renderChart && renderChart()}
        
        </div>
      
      </div>
        
    );
  }
}
