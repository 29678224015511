// @flow

import * as React from 'react';
import { AutoSizer, List } from 'react-virtualized';
import styles from './List.css';
import Item from './Item';

type Props = {
  project: Object,
  onClickViewEmail: Function
}

type RenderItemParams = {
  index: number,
  key: any,
  style: {},
};

export default class extends React.PureComponent<Props> {

  componentDidUpdate = () => {

    if (this.List) this.List.forceUpdateGrid();
  }

  renderItem = (rowProps: RenderItemParams) => {

    const {
      index,
      key,
      style
    } = rowProps;

    const {
      project,
      onClickViewEmail
    } = this.props;

    const item = project.audit_trail[index];

    return (
      <Item
        key={key}
        item={item}
        style={style}
        onClickViewEmail={onClickViewEmail}
      />
    )
    
  }

  List: any;

  render() {
    
    const { project } = this.props;
    
    return (
      <div className={styles.wrapper}>

        <AutoSizer>

          {({ width, height }) => {

            const auditTrail = project.audit_trail;

            return (

              <List
                width={width}
                height={height}
                rowHeight={60}
                rowRenderer={this.renderItem}
                rowCount={auditTrail.length}
                ref={ref => { this.List = ref }}
              />

            )

          }}

        </AutoSizer>

      </div>
    )
  };
}