import { createAction, handleActions } from 'redux-actions'

const loadInvoice = createAction("SCREENS/INVOICE_VIEW/LOAD_INVOICE")
const clearInvoice = createAction("SCREENS/INVOICE_VIEW/CLEAR_INVOICE")

const defaultState = null

const reducer = handleActions(
  {
    [loadInvoice]: (state, action) => action.payload,
    [clearInvoice]: () => null,
  },
  defaultState
)

export { loadInvoice, clearInvoice, reducer }