import { getProps } from '../props'
import { setFetching } from '../State/fetching'
import { getInvoice } from '../../../HttpRequests/invoices'
import { loadInvoice } from '../State/invoice'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {
  
  try {
    
    const { id } = getProps().match.params

    const params = {}
    
    params.include = [
      'project',
      'project.client_site',
      'project.client_site.client',
      'client_contact',
      'user',
      'order',
      'valuation.active_invoices.user',
      'valuation.active_invoices.order',
      'valuation.active_invoices.project.client_site.client',
      'retention_payment.active_invoices.user',
      'retention_payment.active_invoices.order',
      'retention_payment.active_invoices.project.client_site.client',
      'invoice_items',
      'batch.user',
      'batch.order',
      'batch.project.client_site.client'
    ].join()

    dispatch(setFetching(true))

    const response = await getInvoice({ id, params })

    const invoice = response.data.data

    dispatch(loadInvoice(invoice))

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error)

    dispatch(setFetching(false))

  }
}
