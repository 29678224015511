import { message } from 'antd'
import _props from '../props'
import { createRamsRecord, updateRamsRecord } from '../../../HttpRequests/ramsRecords'
import handleException from '../../../Core/Helpers/handleException'

export default (values) => async (dispatch, getState) => {
  
  try {

    const { project, ramsRecordId, onClose } = _props.get()
  
    const { files, removedFileIds } = getState().modals.RamsRecord

    const data = new FormData();
    
    if (project) data.append('project_id', project.id)
    data.append('client_contact_id', values.client_contact_id)
    data.append('valid_from', values.valid_from)
    data.append('valid_to', values.valid_to)
    data.append('notes', values.notes || '')
    
    values.services.forEach((item) => {
      data.append('services[]', item)
    })

    values.access_equipment.forEach((item) => {
      data.append('access_equipment[]', item)
    })

    values.paints.forEach((item) => {
      data.append('paints[]', item)
    })

    files.filter(file => file.status !== 'done').forEach((file) => {
      data.append('files[]', file.originFileObj)
    })

    removedFileIds.forEach(id => {
      data.append('removed_file_ids[]', id)
    })
    
    if (ramsRecordId) {

      await updateRamsRecord({ id: ramsRecordId, data })
    
      message.success("RAMS Updated")

      onClose(true)

    } else {

      await createRamsRecord({ data })
    
      message.success("RAMS Created")

      onClose(true)

    }

  } catch (error) {
    
    handleException(error)

  }
}