import React from 'react';
import { Modal } from 'antd'
import downloadPDF from '../../../Services/downloadPDF'
import exportToQuickbooks from '../../../Services/exportToQuickbooks'
import { setShowEmailModal } from '../../../State/showEmailModal'
import { setShowInvoiceModal } from '../../../State/showInvoiceModal'
import uninvoice from '../../../Services/uninvoice'

export default ({ key }) => async (dispatch, getState) => {

  switch (key) {

    default:
      break

    case 'SEND':
      dispatch(setShowEmailModal(true))
      break
    
    case 'DOWNLOAD':
      dispatch(downloadPDF())
      break
    
    case 'EXPORT':
      dispatch(exportToQuickbooks())
      break

    case 'EDIT':
      dispatch(setShowInvoiceModal(true))
      break

    case 'UNDO': {

      const { invoice } = getState().screens.InvoiceView;

      if (invoice.type === 'APPLICATION') {
        
        Modal.confirm({
          title: `Undo Application`,
          content: `Are you sure you want to undo this application?`,
          okText: 'Yes, Undo it',
          onOk: () => dispatch(uninvoice()),
          width: 450
        })

      }

      if (invoice.type === 'INVOICE_SINGLE') {
        
        Modal.confirm({
          title: `Undo Invoice`,
          content: `Are you sure you want to undo this invoice?`,
          okText: 'Yes, Undo it',
          onOk: () => dispatch(uninvoice()),
          width: 450
        })
        
      }

      if (invoice.type === 'INVOICE_SPLIT') {
        
        Modal.confirm({
          title: `Undo Invoice`,
          content: (
            <div>
              <strong>
                {`Note: This is a split invoice and will undo all ${invoice.valuation.active_invoices.length} invoices.`}
              </strong>
              <br />
              Are you sure you want to undo this split invoice?
            </div>
          ),
          okText: 'Yes, Undo it',
          onOk: () => dispatch(uninvoice()),
          width: 550
        })
        
      }
     

      break
    }

  }
}
