exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._26nPnbGqRgf62UGsA_bCOh {\n\n}\n\n._3jpBJMN5FYaOkFpikkM1ma {\n  \n}\n\n.eaJACXZoLHY0A4aEpAYfW {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n._1mwKl_9FYbcvAJMabjpWqW {\n  flex: 1;\n  margin: 0px;\n  font-size: 28px;\n  font-weight: 500;\n  color: black;\n}\n\n._1MEdYcRklFwWOoFBk3gOAV {\n  margin-left: 10px;\n  margin-right: 10px;\n  height: 32px;\n  display: flex;\n  align-items: center;\n  font-size: 15px;\n  font-weight: 500;\n}\n\n._5VFQGITqPWVUt4zutntN {\n  color: #14be71;\n  font-size: 17px !important;\n  padding-right: 5px;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/InvoiceView/Components/Header/Component.css"],"names":[],"mappings":"AAAA;;CAEC;;AAED;;CAEC;;AAED;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;CACd;;AAED;EACE,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,gBAAgB;EAChB,iBAAiB;CAClB;;AAED;EACE,eAAe;EACf,2BAA2B;EAC3B,mBAAmB;CACpB","file":"Component.css","sourcesContent":[".wrapper {\n\n}\n\n.breadcrumbs {\n  \n}\n\n.header {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.title {\n  flex: 1;\n  margin: 0px;\n  font-size: 28px;\n  font-weight: 500;\n  color: black;\n}\n\n.addedToQuickbooks {\n  margin-left: 10px;\n  margin-right: 10px;\n  height: 32px;\n  display: flex;\n  align-items: center;\n  font-size: 15px;\n  font-weight: 500;\n}\n\n.addedToQuickbooksIcon {\n  color: #14be71;\n  font-size: 17px !important;\n  padding-right: 5px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_26nPnbGqRgf62UGsA_bCOh",
	"breadcrumbs": "_3jpBJMN5FYaOkFpikkM1ma",
	"header": "eaJACXZoLHY0A4aEpAYfW",
	"title": "_1mwKl_9FYbcvAJMabjpWqW",
	"addedToQuickbooks": "_1MEdYcRklFwWOoFBk3gOAV",
	"addedToQuickbooksIcon": "_5VFQGITqPWVUt4zutntN"
};