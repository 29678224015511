import fetchTimesheets from '../Services/fetchTimesheets'
import { setPagination } from '../State/pagination'

export default () => (dispatch) => {

  dispatch(setPagination({ current: 1 }))

  dispatch(fetchTimesheets())
  
}
