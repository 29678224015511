import { createAction, handleActions } from 'redux-actions';

const loadItemDescriptions = createAction("CORE/ITEM_DESCRIPTIONS/LOAD_ITEM_DESCRIPTIONS");
const clearItemDescriptions = createAction("CORE/ITEM_DESCRIPTIONS/CLEAR_ITEM_DESCRIPTIONS");

const defaultState = []

const reducer = handleActions(
  {
    [loadItemDescriptions]: (state, action) => action.payload,
    [clearItemDescriptions]: () => []
  },
  defaultState
);

export { loadItemDescriptions, clearItemDescriptions, reducer };