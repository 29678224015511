import { connect } from 'react-redux';
import Component from './Component';

import selectCaveatsListData from '../../Selectors/selectCaveatsListData';

import onClickAddOrderCaveat from './Handlers/onClickAddOrderCaveat';
import onClickRemoveOrderCaveat from './Handlers/onClickRemoveOrderCaveat';
import onClickDuplicateOrderCaveat from './Handlers/onClickDuplicateOrderCaveat';
import onChangeOrderCaveatField from './Handlers/onChangeOrderCaveatField';
import onMoveOrderCaveat from './Handlers/onMoveOrderCaveat';

const mapStateToProps = (state) => ({
  order: state.screens.OrderView.order,
  listData: selectCaveatsListData(state),
  serviceTypes: state.core.serviceTypes,
  editable: state.screens.OrderView.editable
})

const actionCreators = {
  onClickAddOrderCaveat,
  onClickRemoveOrderCaveat,
  onClickDuplicateOrderCaveat,
  onChangeOrderCaveatField,
  onMoveOrderCaveat
};

export default connect(mapStateToProps, actionCreators)(Component);