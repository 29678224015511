import { combineReducers } from 'redux';

import { reducer as emailSearch } from './State/emailSearch';
import { reducer as fileList } from './State/fileList';


export default combineReducers({
  emailSearch,
  fileList
})
