import { getValuation } from '../../../HttpRequests/valuations'
import { setData } from '../State/data'
import { startFetching, finishFetching } from '../State/isFetching'
import handleException from '../../../Core/Helpers/handleException'

export default (valuationId) => async (dispatch) => {

  const params = {
    include: 'valuation_items.order_item',
  }

  dispatch(startFetching())

  try {

    const response = await getValuation({ id: valuationId, params })

    dispatch(setData(response.data.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
