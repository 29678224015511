// @flow

import * as React from 'react';

import Toolbar from './Components/Toolbar';
import Table from './Components/Table'


type Props = {
  searchTerm: string,
  project: {},
  

  onClickNewOrder: Function,
  onChangeSearch: Function,
  onChangeSelectedRows: Function,
}

export default class extends React.PureComponent<Props> {

  render(){
    const {
      searchTerm,
      project,
      onClickNewOrder,
      onChangeSearch,
      onChangeSelectedRows,
    } = this.props;

    
    return(
      <React.Fragment>

        <Toolbar
          searchTerm={searchTerm}
          onClickNewOrder={onClickNewOrder}
          onChangeSearch={onChangeSearch}
        />

        <Table project={project} onChangeSelectedRows={onChangeSelectedRows} />

      </React.Fragment>
    )
  }
}