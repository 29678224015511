exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3QjXrbUsMFtJZpaWaZ5Hqf {\n  position: absolute;\n  top: 0;\n  right: 10px;\n}\n\n._1v_cxyTfqu4ZwgFbBaDNnl {\n  cursor: pointer;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  color: #1990FF;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Modals/ProjectComplete/Components/InputFields.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,OAAO;EACP,YAAY;CACb;;AAED;EACE,gBAAgB;EAChB,iBAAiB;EACjB,0BAA0B;KACvB,uBAAuB;MACtB,sBAAsB;UAClB,kBAAkB;EAC1B,eAAe;CAChB","file":"InputFields.css","sourcesContent":[".removeContainer {\n  position: absolute;\n  top: 0;\n  right: 10px;\n}\n\n.link {\n  cursor: pointer;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  color: #1990FF;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"removeContainer": "_3QjXrbUsMFtJZpaWaZ5Hqf",
	"link": "_1v_cxyTfqu4ZwgFbBaDNnl"
};