import { connect } from 'react-redux'
import SubOrderItem from './SubOrderItem'

import onUpdateField from './Handlers/onUpdateField'

const mapStateToProps = (state) => ({
  serviceTypes: state.core.serviceTypes,
  editing: state.screens.SubValuation.editing,
})

const actionCreators = {
  onUpdateField,
}

export default connect(mapStateToProps, actionCreators)(SubOrderItem)
