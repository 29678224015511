export default (values) => {

  const errors = {};

  if (!values.project_id) errors.project_id = true;
  if (!values.client_contact_id) errors.client_contact_id = true;
  if (!values.reference) errors.reference = true;
  if (!values.target_total) errors.target_total = true;

  return errors;
  
}