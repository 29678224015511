import { connect } from 'react-redux'
import ProjectSortMenu from './ProjectSortMenu'

import onChangeSort from './Handlers/onChangeSort'

const mapStateToProps = () => ({
})

const actionCreators = {
  onChangeSort,
}

export default connect(mapStateToProps, actionCreators)(ProjectSortMenu)
