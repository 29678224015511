import screenProps from '../screenProps'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchProjects from '../../../Core/Services/fetchProjects'
import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors'
import fetch from '../Services/fetch'

export default (props) => (dispatch) => {

  screenProps(props)

  dispatch(fetchUsers())
  dispatch(fetchProjects())
  dispatch(fetchSubcontractors())

  dispatch(fetch())

}
