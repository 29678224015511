import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteTimesheetAdjustment } from '../../../HttpRequests/timesheetAdjustments'
import handleException from '../../../Core/Helpers/handleException'

export default () => async () => {

  const { timesheetAdjustment } = modalProps()

  try {

    await deleteTimesheetAdjustment({ id: timesheetAdjustment.id })

    message.success('Adjustment deleted')

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
