// @flow

import * as React from 'react';
import { Modal } from 'antd';
import Form from './Form';
import ProjectModal from '../Project'
import ClientContactModal from '../ClientContact'
import SelectEstimateOptionalsModal from '../SelectEstimateOptionals'

type Props = {
  order: ?any,
  projects: [],
  users: [],
  estimates: [],
  clientContacts: [],
  clientId: any,
  estimateId: any,
  isCreatingFromEstimate: boolean,
  submitting: boolean,
  showNewClientContactButton: boolean,
  showProjectModal: boolean,
  showClientContactModal: boolean,
  showSelectEstimateOptionalsModal: boolean,

  componentWillMount: (props: any) => void,
  componentWillUnmount: () => void,
  didCreateClientContact: () => void,
  didCreateProject: () => void,
  didSelectEstimateOptionals: () => void,
  onClickCancel: () => void,
  onClickSubmit: () => void,
  onChangeProject: () => void,
  onChangeEstimate: () => void,
  onClickNewProject: () => void,
  onClickNewClientContact: () => void,
  onCloseProjectModal: () => void,
  onCloseClientContactModal: () => void,
  onCloseSelectEstimateOptionalsModal: () => void
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      order,
      projects,
      users,
      estimates,
      clientContacts,
      clientId,
      estimateId,
      isCreatingFromEstimate,
      submitting,
      showNewClientContactButton,
      showProjectModal,
      showClientContactModal,
      showSelectEstimateOptionalsModal,

      didCreateProject,
      didCreateClientContact,
      didSelectEstimateOptionals,
      onClickCancel,
      onClickSubmit,
      onChangeProject,
      onChangeEstimate,
      onClickNewProject,
      onClickNewClientContact,
      onCloseProjectModal,
      onCloseClientContactModal,
      onCloseSelectEstimateOptionalsModal
    } = this.props;
    
    return (

      <Modal
        title={order ? 'Edit Details': 'New Order'}
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText={order ? 'Update' : 'Create'}
        width={650}
      >

        <Form
          order={order}
          projects={projects}
          estimates={estimates}
          users={users}
          clientContacts={clientContacts}
          isCreatingFromEstimate={isCreatingFromEstimate}
          showNewClientContactButton={showNewClientContactButton}
          onClickNewProject={onClickNewProject}
          onClickNewClientContact={onClickNewClientContact}
          onChangeProject={onChangeProject}
          onChangeEstimate={onChangeEstimate}
        />

        {showProjectModal && (
          <ProjectModal
            onClose={onCloseProjectModal}
            didCreate={didCreateProject}
            clientId={clientId}
          />
        )}

        {showClientContactModal && (
          <ClientContactModal
            onClose={onCloseClientContactModal}
            didCreate={didCreateClientContact}
            clientId={clientId}
          />
        )}

        {showSelectEstimateOptionalsModal && (
          <SelectEstimateOptionalsModal
            onClose={onCloseSelectEstimateOptionalsModal}
            estimateId={estimateId}
            didSelect={didSelectEstimateOptionals}
          />
        )}

      </Modal>
    )
  }
}