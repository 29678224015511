exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3TA1Ti0I6DG5ZHtPeFo_zW {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/ProjectView/Components/Activity/Components/List.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,cAAc;EACd,uBAAuB;EACvB,aAAa;EACb,0BAA0B;KACvB,uBAAuB;MACtB,sBAAsB;UAClB,kBAAkB;CAC3B","file":"List.css","sourcesContent":[".wrapper {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3TA1Ti0I6DG5ZHtPeFo_zW"
};