import { createSelector } from 'reselect';

const selectEmailSearch = state => state.modals.EmailValuation.emailSearch;
const selectCore = state => state.core;

export default createSelector(
  selectEmailSearch,
  selectCore,
  (emailSearch, core) =>  {
    
    const { globalContacts } = core;
    

    const mailList = [];
    
    if (emailSearch && emailSearch.length > 2) {
      
      const search = emailSearch.toLowerCase();

      globalContacts.forEach(item => {

        if (item.email) {

          const name = item.name.toLowerCase();
          const email= item.email.toLowerCase();

          if (name.includes(search) || email.includes(search)) {
            mailList.push({
              value: item.email,
              label: `${item.name} <${item.email}>`
            });
          }

        }

      });

      
    }

    return mailList;
  }
)