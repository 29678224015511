import { omit } from 'lodash';
import moment from 'moment';
import { getProjectTotals } from '../../../HttpRequests/projects';
import { loadProjectTotals } from '../State/projectTotals';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const { searchTerm, filters } = getState().screens.ProfitAndLoss;

  try {

    const params = {};

    params['filter[profit_and_loss]'] = 1;

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (filters.status_key !== 'all') {
      params['filter[status_key]'] = filters.status_key;
    }

    if (filters.phase_key.length > 0) {
      params['filter[phase_key]'] = filters.phase_key.join(',');
    }

    if (filters.client_id.length > 0) {
      params['filter[client_id]'] = filters.client_id.join(',');
    }

    if (filters.contracts_manager.length > 0) {
      params['filter[contracts_manager]'] = filters.contracts_manager.join(',');
    }

    if (Array.isArray(filters.start_between) && filters.start_between.length === 2) {
      params['filter[start_between]'] = filters.start_between.map(date => moment(date).format('YYYY-MM-DD')).join();
    }

    if (Array.isArray(filters.date_between) && filters.date_between.length === 2) {
      params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join();
    }

    if (filters.outstanding_income) {
      params['filter[outstanding_income]'] = 1;
    }

    const response = await getProjectTotals({ params: omit(params, ['page[size]', 'page[number]', 'csort']) });

    dispatch(loadProjectTotals(response.data.data));

  } catch (error) {

    handleException(error);

  }
}