exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1GfpMTybg7M4ysW98V5PQJ .ant-select-selector {\n  border: none !important;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/SubProject/Components/SubValuations/Components/Table/styles.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;CACzB","file":"styles.css","sourcesContent":[".antInput :global(.ant-select-selector) {\n  border: none !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"antInput": "_1GfpMTybg7M4ysW98V5PQJ"
};