// @flow

import * as React from 'react';
import List from './Components/List';

type Props = {
  estimate: any,
  listData: [],
  serviceTypes: [],
  editable: boolean,
  onClickAddEstimateCaveat: Function,
  onClickRemoveEstimateCaveat: Function,
  onClickDuplicateEstimateCaveat: Function,
  onChangeEstimateCaveatField: Function,
  onMoveEstimateCaveat: Function,
  onClickSelectCaveatFromList: Function
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      estimate,
      listData,
      serviceTypes,
      editable,
      onClickAddEstimateCaveat,
      onClickRemoveEstimateCaveat,
      onClickDuplicateEstimateCaveat,
      onChangeEstimateCaveatField,
      onMoveEstimateCaveat,
      onClickSelectCaveatFromList
    } = this.props;

    return (

      <React.Fragment>

        <List
          estimate={estimate}
          listData={listData}
          serviceTypes={serviceTypes}
          editable={editable}
          onClickAddEstimateCaveat={onClickAddEstimateCaveat}
          onClickRemoveEstimateCaveat={onClickRemoveEstimateCaveat}
          onClickDuplicateEstimateCaveat={onClickDuplicateEstimateCaveat}
          onChangeEstimateCaveatField={onChangeEstimateCaveatField}
          onMoveEstimateCaveat={onMoveEstimateCaveat}
          onClickSelectCaveatFromList={onClickSelectCaveatFromList}
        />

      </React.Fragment>

    )
  }
}