import React from 'react';
import { Tree } from 'antd';

const { TreeNode } = Tree;

const treeData = [
  {
    title: 'Avengers',
    key: '1',
    children: [{
      title: 'Avengers 1',
      key: '2',
      children: [
        { title: 'Avengers 2', key: '3' },
        { title: 'Avengers 3', key: '4' },
        { title: 'Avengers 4', key: '5' },
      ],
    }]
  }, {
    title: 'Harry Potter',
    key: '6',
    children: [
      { title: 'The Philosophers Stone (1997)', key: '7' },
      { title: 'The Chamber of Secrets (1998)', key: '8' },
      { title: 'The Prisoner of Azkaban (1999)', key: '9' },
      { title: 'The Goblet of Fire (2000)', key: '10' },
      { title: 'The Order of the Phoenix (2003)', key: '11' },
      { title: 'The Half-Blood Prince (2005)', key: '12' },
      { title: 'The Deathly Hallows (2007)', key: '13' },
    ],
  }
];

type Props = {
  submitting: boolean, 
  onSelectTree: Function
}

export default class extends React.PureComponent<Props> {

  renderTreeNodes = (data) => data.map((item) => {
    if (item.children) {
      return (
        <TreeNode title={item.title} key={item.key} dataRef={item}>
          {this.renderTreeNodes(item.children)}
        </TreeNode>
      );
    }
    return <TreeNode {...item} />;
  });
  

  render () {

    const {
      onSelectTree
    } = this.props;


    return (

      <div>

        <Tree onSelect={onSelectTree}>
          {this.renderTreeNodes(treeData)}
        </Tree>

      </div>

    )
  }
}