exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2t94SJBXxSymK-zkP-JtOx {\n  text-align: right !important;\n}\n\n._3Hsr6nYgPmmIa9ozxCHZte {\n  color: rgb(0, 168, 0);\n}\n\n._2_c-7FUm6BBTCMjVWLEF7C {\n  color: red;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/ProfitAndLoss/Components/Table.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;CAC9B;;AAED;EACE,sBAAsB;CACvB;;AAED;EACE,WAAW;CACZ","file":"Table.css","sourcesContent":[".numericColumn {\n  text-align: right !important;\n}\n\n.positive {\n  color: rgb(0, 168, 0);\n}\n\n.negative {\n  color: red;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"numericColumn": "_2t94SJBXxSymK-zkP-JtOx",
	"positive": "_3Hsr6nYgPmmIa9ozxCHZte",
	"negative": "_2_c-7FUm6BBTCMjVWLEF7C"
};