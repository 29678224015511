import { message } from 'antd'
import handleException from '../../../Core/Helpers/handleException';
import { uncompleteProject } from '../../../HttpRequests/projects';
import fetchProject from './fetchProject';

export default () => async (dispatch, getState) => {

  const { id } = getState().screens.ProjectView.project;
  
  try {

    await uncompleteProject({ id });
    
    dispatch(fetchProject());

    message.success('Project Uncompleted');
    
  } catch(error) {

    handleException(error);

  }
  
}