import { setSubOrderGroups } from '../State/subOrder'

const modValues = (field, value, subOrder, subOrderItem) => {

  const values = {}

  if (field === 'type' && value === 'text-only') {
    values.type = null
    values.quantity = null
    values.unit = null
    values.rate = null
    values.total = null
  }

  if (field === 'type' && value !== 'text-only') {
    values.unit = 'm'
  }

  if (field === 'quantity') {
    values.total = value * subOrderItem.rate
  }

  if (field === 'rate') {
    values.total = subOrderItem.quantity * value
  }

  return values

}

export default (guid, field, value) => (dispatch, getState) => {

  const { subOrder } = getState().screens.SubOrder

  const newSubOrderGroups = []

  subOrder.sub_order_groups.forEach(subOrderGroup => {

    const newSubOrderItems = []

    subOrderGroup.sub_order_items.forEach(subOrderItem => {
      if (subOrderItem.guid !== guid) {
        newSubOrderItems.push({ ...subOrderItem })
      } else {
        newSubOrderItems.push({
          ...subOrderItem,
          [field]: value,
          ...modValues(field, value, subOrder, subOrderItem),
        })
      }
    })

    const newSubOrderGroup = {
      ...subOrderGroup,
      sub_order_items: newSubOrderItems,
    }

    // Have they updated the sub order group?
    if (subOrderGroup.guid === guid) {
      newSubOrderGroup[field] = value
    }

    newSubOrderGroups.push(newSubOrderGroup)

  })

  dispatch(setSubOrderGroups(newSubOrderGroups))

}
