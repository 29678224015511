import { clearOrders } from '../State/orders'
import { setActiveItemInputKeys } from '../State/activeItemInputKeys'
import { setEditable } from '../State/editable'

export default () => (dispatch) => {

  dispatch(clearOrders())
  dispatch(setActiveItemInputKeys(null))
  dispatch(setEditable(false))
  
}