// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Table, Row, Col, Button, Input} from 'antd';
import { getTeamUrl } from 'Common';

type Props = {
  client: Object,
  searchTerm: string,

  onChangeSearch: Function,
  onClickNewClientSite: Function,
  onClickEditClientSite: Function
  
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      client,
      searchTerm,
      onChangeSearch,
      onClickNewClientSite,
      onClickEditClientSite
    } = this.props;

    const columns = [

      { 
        title: 'Sites', 
        dataIndex: '',
        render: (text, record) => (
          <NavLink to="#" onClick={() => onClickEditClientSite(record)}>
            { record.name }
          </NavLink>
        )
      },
      
      {
        title: 'Projects', 
        dataIndex: '',
        render: (text, record) => (
          <div>
            {record.projects.map(project => (
              <NavLink to={getTeamUrl(`projects/${project.id}/view`)}>
                {project.id}
                {' '}
              </NavLink>
            ))}
          </div>
        )
      },
      
      {
        title: 'Address', 
        dataIndex: '',
        render: (text, record) => (
          <span>
            {[
              record.address_line_1,
              record.address_line_2,
              record.address_line_3,
              record.town_or_city,
              record.county,
              record.postcode
            ].filter(line => line).join(', ')}
          </span>
        )
      }

    ];

    return (
      <React.Fragment>

        <Row>

          <Col span={12}>

            <Input.Search
              style={{ width: 250 }}
              placeholder="Search"
              onChange={onChangeSearch}
              value={searchTerm}
            />

          </Col>

          <Col span={12} className="text-right">

            <Button type="primary" onClick={onClickNewClientSite}>
              New Site
            </Button>

          </Col>

        </Row>

        <Row>

          <Col span={24} style={{ marginTop: 15 }}>

            <Table dataSource={client.client_sites} columns={columns} rowKey={record => record.id} />

          </Col>

        </Row>
  
      </React.Fragment>

      

    )
  };
}