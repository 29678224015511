import { createAction, handleActions } from 'redux-actions';

const setShowExportStatisticsModal = createAction("SCREENS/DASHBOARD/SET_SHOW_EXPORT_STATISTICS_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowExportStatisticsModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowExportStatisticsModal, reducer };