// @flow
import * as React from 'react';
import classnames from 'classnames';
import { PlusOutlined } from '@ant-design/icons';
import styles from './Footer.css';

type Props = {
  editable: boolean,
  onClickAddEstimateGroup: () => void
}

class Component extends React.PureComponent<Props> {

  render () {

    const {
      editable,
      onClickAddEstimateGroup
    } = this.props;

    if (!editable) return null;

    return (
      <div className={styles.wrapper}>

        <div className={styles.buttons}>

          <div
            className={classnames(styles.link, styles.addLink)}
            role="presentation"
            onClick={onClickAddEstimateGroup}
          >
            <PlusOutlined />
            {' Add Group'}
          </div>

        </div>

      </div>
    );
  }
}

export default Component;