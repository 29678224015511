import fetchProjectStatuses from '../../../Core/Services/fetchProjectStatuses'
import fetchProjectPhases from '../../../Core/Services/fetchProjectPhases'
import fetchClients from '../../../Core/Services/fetchClients'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors'
import fetchProjects from '../../../Core/Services/fetchProjects'

export default () => (dispatch) => {

  // Project
  dispatch(fetchProjectStatuses())
  dispatch(fetchProjectPhases())

  // Estimate
  dispatch(fetchClients())
  dispatch(fetchUsers())

  // Timesheet
  dispatch(fetchSubcontractors())
  dispatch(fetchProjects())

}
