import { setEditing } from '../../../State/editing'
import { setSubValuationItems } from '../../../State/subValuation'

export default () => (dispatch, getState) => {

  dispatch(setEditing(false))

  dispatch(setSubValuationItems(getState().screens.SubValuation.originalItems))

}
