import { createAction, handleActions } from 'redux-actions';

const loadProjectPhases = createAction("CORE/PROJECT_PHASEES/LOAD_PROJECT_PHASES");
const clearProjectPhases = createAction("CORE/PROJECT_PHASEES/CLEAR_PROJECT_PHASES");

const defaultState = []

const reducer = handleActions(
  {
    [loadProjectPhases]: (state, action) => action.payload,
    [clearProjectPhases]: () => []
  },
  defaultState
);

export { loadProjectPhases, clearProjectPhases, reducer };