// @flow

import * as React from 'react';
import { sortBy } from 'lodash';
import { Field } from 'redux-form';
import { Divider } from 'antd';
import { SelectField, DatePickerField, InputNumberField, InputField } from 'FormFields';
import IconButton from '../../../Components/IconButton';

const labelColSpan = 5;
const wrapperColSpan = 18;

type Props = {
  order: ?any,
  projects: [],
  estimates: [],
  users: [],
  clientContacts: [],
  isCreatingFromEstimate: boolean,
  submitting: boolean,
  showNewClientContactButton: boolean,
  onChangeProject: () => void,
  onChangeEstimate: () => void,
  onClickNewProject: () => void,
  onClickNewClientContact: () => void
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      order,
      projects,
      users,
      estimates,
      clientContacts,
      isCreatingFromEstimate,
      submitting,
      showNewClientContactButton,
      onChangeProject,
      onChangeEstimate,
      onClickNewProject,
      onClickNewClientContact
    } = this.props;

    const projectOptions = projects.map(item => ({
      value: item.id,
      label: `[${item.id}] ${item.client_site.client.name} - ${item.client_site.name}`
    }));

    const estimateOptions = estimates.map(item => ({
      value: item.id,
      label: `${item.full_reference}`
    }));

    const clientContactOptions = sortBy(clientContacts.map(item => ({
      value: item.id,
      label: `${item.first_name || ''} ${item.last_name || ''}`
    })), ['label']);

    const userOptions = users.map(item => ({
      value: item.id,
      label: `${item.first_name} ${item.last_name}`
    }));

    const vatOptions = [
      { value: 0, label: '0'},
      { value: 20, label: '20%'}
    ]

    const NewProjectButton = () => (
      <IconButton
        type="plus-circle"
        tooltip="New Project"
        onClick={onClickNewProject}
        style={{ marginBottom: -4 }}
      />
    );

    const NewClientContactButton = () => (
      <IconButton
        type="plus-circle"
        tooltip="New Contact"
        onClick={onClickNewClientContact}
        style={{ marginBottom: -4 }}
      />
    );
    
    return (

      <div>

        {(!order && !isCreatingFromEstimate) && (

          <React.Fragment>
        
            <Field
              required
              component={SelectField}
              name="project_id"
              label="Project"
              placeholder="Select Project"
              disabled={submitting}
              options={projectOptions}
              onChange={onChangeProject}
              renderAfter={() => <NewProjectButton />}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />

            <Field
              component={SelectField}
              name="estimate_id"
              label="Estimate"
              placeholder="Select Estimate"
              disabled={submitting}
              options={estimateOptions}
              onChange={onChangeEstimate}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />
            
            <Divider />

          </React.Fragment>

        )}

        <Field
          required
          component={SelectField}
          name="client_contact_id"
          label="Contact"
          placeholder="Select contact"
          disabled={submitting}
          options={clientContactOptions}
          renderAfter={() => showNewClientContactButton && <NewClientContactButton />}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}
        />

        <Field
          component={SelectField}
          name="user_id"
          label="Owner"
          placeholder="Select user"
          disabled={submitting}
          options={userOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />

        <Field
          component={DatePickerField}
          name="date"
          label="Date"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />

        <Field
          required
          component={InputField}
          name="reference"
          label="PO Number"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />

        <Field
          component={InputField}
          name="client_project_number"
          label="Client Project No"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />

        <Field
          required
          component={InputNumberField}
          name="target_total"
          label="Total Target (£)"
          formatter={(value) => value.toString().replace(/[^0-9.]/g, "")}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 8 }}
        />

        <Field
          component={InputNumberField}
          name="discount_percentage"
          label="Discount %"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 8 }}
        />
            
        <Field
          component={InputNumberField}
          name="uplift_percentage"
          label="Uplift %"
          disabled={submitting}
          min={0}
          max={100}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 8 }}
        />

        <Field
          component={InputNumberField}
          name="retention_percentage"
          label="Retention %"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 8 }}
        />

        <Field
          component={SelectField}
          name="vat_percentage"
          label="Vat %"
          disabled={submitting}
          options={vatOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 8 }}
        />

        {!!order && (
          <Field
            required
            component={SelectField}
            name="extra_works"
            label="New / Extra"
            disabled={submitting}
            options={[
              { label: 'New Works', value: 0 },
              { label: 'Extra Works', value: 1 },
            ]}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            fieldCol={{ span: 8 }}
          />
        )}


      </div>
      
    )
  };
}
