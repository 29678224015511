import { padStart } from 'lodash'
import moment from 'moment'
import { openTimesheetAdjustmentModal } from '../State/timesheetAdjustmentModal'

const pad = num => padStart(num, 2, '0')

export default (value, timesheet) => (dispatch) => {

  const endDateTime = moment(value)
  const closeDateTime = moment(`${timesheet.date} 17:00:00`)

  const duration = moment.duration(closeDateTime.diff(endDateTime))

  // eslint-disable-next-line no-underscore-dangle
  const { hours, minutes } = duration._data

  const initialValues = {
    type: endDateTime > closeDateTime ? 'reduction' : 'addition',
    time: `${pad(Math.abs(hours))}:${pad(Math.abs(minutes))}:00`,
    reason: endDateTime > closeDateTime ? 'Clocked out too late (after 5pm)' : 'Clocked out too early (before 5pm)',
  }

  dispatch(openTimesheetAdjustmentModal({ initialValues, timesheetId: timesheet.id }))

}
