import { createSelector } from 'reselect'

const selectAllFiles = state => state.modals.EmailValuation.allFiles

export default createSelector(
  selectAllFiles,
  (allFiles) =>  allFiles.map((path) => ({
    key: path,
    label: path.split('/').slice(2).join(' » '),
  }))
)
