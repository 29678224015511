import { connect } from 'react-redux'

import onChangeFilter from './Handlers/onChangeFilter'

import Component from './Component'

const mapStateToProps = (state) => ({
  filters: state.screens.RamsRecords.filters,
  clients: state.core.clients,
})

const actionCreators = {
  onChangeFilter
}

export default connect(mapStateToProps, actionCreators)(Component)