// @flow

import { message } from 'antd'
import { updateOrderItems, updateOrderCaveats } from '../../../HttpRequests/orders';
import { setEditable } from '../State/editable';
import { setSelectedOrderItemIds } from '../State/selectedOrderItemIds';
import fetchOrder from './fetchOrder';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch: Function, getState: Function) => {
  
  try {
    
    const { order } = getState().screens.OrderView;

    const { id } = order;

    await updateOrderItems({ id, data: order.order_groups });

    await updateOrderCaveats({ id, data: order.order_caveats });

    dispatch(setEditable(false));

    dispatch(setSelectedOrderItemIds([]));

    message.success("Order Updated");
  
    await dispatch(fetchOrder());

  } catch (error) {

    handleException(error);

    throw error;
    
  }

}