import { createAction, handleActions } from 'redux-actions';

const setCheckedItemPaths = createAction("SCREENS/PROJECT_VIEW/SET_CHECKED_ITEM_PATHS");
const clearCheckedItemPaths = createAction("SCREENS/PROJECT_VIEW/CLEAR_CHECKED_ITEM_PATHS");

const defaultState = [];

const reducer = handleActions(
  {
    [setCheckedItemPaths]: (state, action) => action.payload,
    [clearCheckedItemPaths]: () => []
  },
  defaultState
);

export { setCheckedItemPaths, clearCheckedItemPaths, reducer };