import { Modal } from 'antd'
import { getProps } from '../props'
import deleteProject from '../Services/delete'

export default () => (dispatch) => {

  const { project } = getProps()

  Modal.confirm({
    title: `Delete "${project.id}"`,
    content: 'Are you sure to delete this project?',
    okText: 'Yes',
    width: 400,
    onOk: () => dispatch(deleteProject()),
  })

}
