// @flow
import * as React from 'react';
import { WindowScroller, AutoSizer, List } from 'react-virtualized';
import OrderHeader from './OrderHeader';
import GroupHeader from './GroupHeader';
import GroupItem from './GroupItem';
import OrderFooter from './OrderFooter';
import Summary from './Summary';

type Props = {
  listData: [],
  serviceTypes: [],
  editable: boolean,
  activeItemInputKeys: any,
  onClickTargetCostPercentageOutlet: () => void,
  onClickTargetCostAmountOutlet: () => void,
  onChangeTargetCostPercentage: () => void,
  onChangeTargetCostAmount: () => void,
  onBlurTargetCostPercentage: () => void,
  onBlurTargetCostAmount: () => void,
  onTabTargetCostField: () => void,
};

type RowProps = {
  index: number,
  key: any,
  style: {}
};

export default class extends React.PureComponent<Props> {

  componentDidUpdate = () => this.List.recomputeRowHeights();

  List: any;

  renderRow = (rowProps: RowProps) => {

    const {
      index,
      key,
      style
    } = rowProps;

    const {
      listData,
      serviceTypes,
      editable,
      activeItemInputKeys,
      onClickTargetCostPercentageOutlet,
      onClickTargetCostAmountOutlet,
      onChangeTargetCostPercentage,
      onChangeTargetCostAmount,
      onBlurTargetCostPercentage,
      onBlurTargetCostAmount,
      onTabTargetCostField,
    } = this.props;

    const {
      type,
      order,
      orderIndex,
      orderGroup,
      orderGroupIndex,
      orderItem,
      orderItemIndex,
      typeTotals,
    } = listData[index];

    if (type === 'OrderHeader') {
      return (
        <div style={style} key={key}>
          <OrderHeader
            index={index}
            editable={editable}
            order={order}
          />
        </div>
      )
    }

    if (type === 'GroupHeader') {
      return (
        <div style={style} key={key}>
          <GroupHeader
            index={index}
            orderGroup={orderGroup}
          />
        </div>
      )
    }

    if (type === 'GroupItem') {
      return (
        <div style={style} key={key}>
          <GroupItem
            index={index}
            editable={editable}
            activeItemInputKeys={activeItemInputKeys}
            orderIndex={orderIndex}
            orderGroupIndex={orderGroupIndex}
            orderItemIndex={orderItemIndex}
            orderItem={orderItem}
            serviceTypes={serviceTypes}
            onClickTargetCostPercentageOutlet={onClickTargetCostPercentageOutlet}
            onClickTargetCostAmountOutlet={onClickTargetCostAmountOutlet}
            onChangeTargetCostPercentage={onChangeTargetCostPercentage}
            onChangeTargetCostAmount={onChangeTargetCostAmount}
            onBlurTargetCostPercentage={onBlurTargetCostPercentage}
            onBlurTargetCostAmount={onBlurTargetCostAmount}
            onTabTargetCostField={onTabTargetCostField}
          />
        </div>
      )
    }
    
    if (type === 'OrderFooter') {

      return (
        <div style={style} key={key}>
          <OrderFooter
            index={index}
            order={order}
          />
        </div>
      )
    }
    
    if (type === 'Summary') {

      return (
        <div style={style} key={key}>
          <Summary
            index={index}
            typeTotals={typeTotals}
            editable={editable}
          />
        </div>
      )
    }

    return null;
  }

  render () {

    const {
      listData
    } = this.props;

    return (

      <div style={{ }}>

        <WindowScroller>
          {({ height, scrollTop }) => (

            <AutoSizer disableHeight>
              {({ width }) => (

                <List
                  autoHeight
                  height={height}
                  width={width}
                  scrollTop={scrollTop}
                  overscanRowCount={5}
                  rowHeight={({ index }) => listData[index].height}
                  rowRenderer={this.renderRow}
                  rowCount={listData.length}
                  ref={ref => { this.List = ref }}
                />

              )}
            </AutoSizer>
            
          )}
        </WindowScroller>

      </div>

    )
  }
  
}