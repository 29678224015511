// @flow

import * as React from 'react';
import { Modal } from 'antd';
import Form from './Form';

type Props = {
  clientContacts: [],
  users: [],
  submitting: boolean,
  valuation: any,

  componentWillMount: (props: any) => void,
  onClickCancel: Function,
  onClickSubmit: Function

}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      clientContacts,
      users,
      submitting,
      valuation,
      onClickCancel,
      onClickSubmit,
    } = this.props;
    
    return (

      <Modal
        title='Invoice'
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText={`${valuation ? 'Create Invoice' : 'Update Invoice'}`}
      >

        <Form
          users={users}
          clientContacts={clientContacts}
        />

      </Modal>
    )
  }
}