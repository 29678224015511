import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openTimesheetModal = createAction('SCREENS/TIMESHEETS/OPEN_TIMESHEET_MODAL')
const closeTimesheetModal = createAction('SCREENS/TIMESHEETS/CLOSE_TIMESHEET_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openTimesheetModal]: (state, action) => ({
      ...state,
      show: true,
      id: get(action, 'payload.id', null),
    }),
    [closeTimesheetModal]: () => defaultState,
  },
  defaultState,
)

export { openTimesheetModal, closeTimesheetModal, reducer }
