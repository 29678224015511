// @flow

import * as React from 'react';
import numeral from 'numeral';
import { EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import styles from './Component.css';

import type { EstimateTotals } from '../../types';

type Props = {
  estimateTotals: EstimateTotals,
  estimate: any,
  editable: boolean,
  selectedEstimateItemIds: [],
  onClickEdit: () => void,
  onClickCancel: () => void,
  onClickSave: () => void,
  onClickDeleteItems: () => void
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      estimateTotals,
      estimate,
      editable,
      selectedEstimateItemIds,
      onClickEdit,
      onClickCancel,
      onClickSave,
      onClickDeleteItems
    } = this.props;

    return (
      <div className={styles.wrapper}>

        <div className={styles.buttons}>

          {!editable && (
            
            <Button
              className={styles.editButton}
              onClick={onClickEdit}
              disabled={estimate.action_statuses.handle_items !== true}
            >
              <EditOutlined />
              {'Edit Estimate'}
            </Button>

          )}

          {editable && (

            <React.Fragment>

              <Button
                className={styles.saveButton}
                onClick={onClickSave}
                type="primary"
              >
                {'Save Changes'}
              </Button>

              <div style={{ width: 8 }} />

              <Popconfirm
                title="Cancel your changes?"
                onConfirm={onClickCancel}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className={styles.cancelButton}
                  type="dashed"
                >
                  {'Cancel'}
                </Button>
              </Popconfirm>

            </React.Fragment>

          )}

          {(editable && selectedEstimateItemIds.length > 0) && (
            <Button
              className={styles.deleteSelectedButton}
              type="danger"
              style={{ marginLeft: 20 }}
              onClick={onClickDeleteItems}
            >
              {`Delete Items (${selectedEstimateItemIds.length})`}
            </Button>
          )}

        </div>

        <div className={styles.totals}>

          <div className={styles.total}>
            {`Subtotal: £${numeral(estimateTotals.subtotal).format('0,0.00')}`}
          </div>

          <div className={styles.total}>
            {`Discount: £${numeral(estimateTotals.discount).format('0,0.00')}`}
          </div>

          <div className={styles.total}>
            {`Total: £${numeral(estimateTotals.total).format('0,0.00')}`}
          </div>

        </div>

      </div>
    );
  }
}