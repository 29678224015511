import React from 'react'
import PropTypes from 'prop-types'
import { SortableElement } from 'react-sortable-hoc'
import styles from './styles.css'

class SubOrderGroupPlaceholder extends React.PureComponent {

  static propTypes = {
    subOrderGroup: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
    onAddItem: PropTypes.func.isRequired,
  }

  render() {

    const {
      subOrderGroup,
      editing,
      onAddItem,
    } = this.props

    return (

      <div className={styles.wrapper}>

        <div className={styles.message}>

          {editing ? (

            <React.Fragment>

              {'This group is empty. '}

              <span>
                <span
                  role='presentation'
                  onClick={() => onAddItem(subOrderGroup.guid)}
                  className={styles.addLink}
                >
                  {'Add an item'}
                </span>
                {' to get started.'}
              </span>

            </React.Fragment>

          ) : 'This group is empty.'}

        </div>

      </div>

    )

  }

}

export default SortableElement(SubOrderGroupPlaceholder)
