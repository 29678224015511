// @flow

import { setProps } from '../props';

import fetchProject from '../Services/fetchProject';
import fetchRootDirectories from '../Services/fetchRootDirectories';

import fetchProjects from '../../../Core/Services/fetchProjects';
import fetchProjectPhases from '../../../Core/Services/fetchProjectPhases';
import fetchProjectStatuses from '../../../Core/Services/fetchProjectStatuses';
import fetchEstimateStatuses from '../../../Core/Services/fetchEstimateStatuses';
import fetchEstimateTenderStatuses from '../../../Core/Services/fetchEstimateTenderStatuses';
import fetchEstimateLatestActions from '../../../Core/Services/fetchEstimateLatestActions';
import fetchServiceTypes from '../../../Core/Services/fetchServiceTypes';
import fetchUsers from '../../../Core/Services/fetchUsers';

export default (props: any) => async (dispatch: Function) => {

  setProps(props);

  dispatch(fetchProject());
  dispatch(fetchRootDirectories());

  dispatch(fetchProjectPhases());
  dispatch(fetchProjectStatuses());
  dispatch(fetchEstimateStatuses());
  dispatch(fetchEstimateTenderStatuses());
  dispatch(fetchEstimateLatestActions());
  dispatch(fetchServiceTypes());
  dispatch(fetchUsers());
  dispatch(fetchProjects());

}