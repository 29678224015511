export default (values) => {

  const errors = {}

  if (!values.subcontractor_id) errors.subcontractor_id = 'Subcontractor is required'

  if (!values.project_id) errors.project_id = 'Project is required'

  if (!values.user_id) errors.user_id = 'Owner is required'

  if (!values.date) errors.date = 'Date is required'

  if (!values.to_date) errors.to_date = 'To date is required'

  return errors
  
}
