import { connect } from 'react-redux'
import Actions from './Actions'

import onClickAutoAdjust from './Handlers/onClickAutoAdjust'
import onClickStatus from './Handlers/onClickStatus'

const mapStateToProps = (state) => ({
  selected: state.modules.TimesheetsDrawer.selected,
})

const actionCreators = {
  onClickAutoAdjust,
  onClickStatus,
}

export default connect(mapStateToProps, actionCreators)(Actions)
