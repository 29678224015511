import { message } from 'antd'
import { deleteSubcontractor } from '../../../HttpRequests/subcontractors'
import { getProps } from '../props'
import handleException from '../../../Core/Helpers/handleException'

export default () => async () => {

  const { subcontractor, onClose, didDelete } = getProps()

  try {

    await deleteSubcontractor({ id: subcontractor.id })

    message.success('Subcontractor Deleted')

    onClose(true)

    if (didDelete) didDelete()

  } catch (error) {

    handleException(error)

  }

}
