import { createSelector } from 'reselect';
import { merge } from 'lodash';
import moment from 'moment';
import baseColumnChartConfig from '../Misc/baseColumnChartConfig';

const selectData = state => state.screens.Dashboard.lastThreeMonthsReport.data;

function formatter () {
  return this.series.data[this.point.index].count
}

export default createSelector(
  selectData,
  (data) => {

    const categories = [];
    
    const estimates = {
      new: [],
      extra: [],
    };
    const orders = {
      new: [],
      extra: [],
    };
    const valuations = [];

    if (data) {
        
      data.estimates.slice(0,3).forEach(period => {
        categories.push(moment(period.start_date).format('MMM'));
        estimates.new.push({
          name: moment(period.start_date).format('MMM'),
          y: period.value_new,
          count: period.count_new,
        });
        estimates.extra.push({
          name: moment(period.start_date).format('MMM'),
          y: period.value_extra,
          count: period.count_extra,
        });
      })

      data.orders.slice(0,3).forEach(period => {
        orders.new.push({
          name: moment(period.start_date).format('MMM'),
          y: period.value_new,
          count: period.count_new,
        });
        orders.extra.push({
          name: moment(period.start_date).format('MMM'),
          y: period.value_extra,
          count: period.count_extra,
        });
      })

      data.valuations.slice(0,3).forEach(period => {
        valuations.push({
          name: moment(period.start_date).format('MMM'),
          y: period.value,
          count: period.count,
        });
      })
      
    }
    
    const configEstimates = merge({}, baseColumnChartConfig, {

      title: {
        text: 'Estimates',
        margin: 10,
        style: { 
          fontSize: 14
        }
      },

      colors: [ '#7EB6EA', '#1F6BD1' ],

      xAxis: {
        categories
      },

      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter
          }
        },
        column: {
          stacking: 'normal',
          dataLabels: {
              enabled: true
          }
        },
      },

      series: [
        {
          name: 'EXTRA WORKS',
          data: estimates.extra,
        },
        {
          name: 'NEW WORKS',
          data: estimates.new,
        },
      ]

    });
    
    const configOrders = merge({}, baseColumnChartConfig, {

      title: {
        text: 'Orders',
        margin: 10,
        style: { 
          fontSize: 14
        }
      },

      colors: [ '#93EB82', '#008002', ],

      xAxis: {
        categories
      },

      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter
          }
        },
        column: {
          stacking: 'normal',
          dataLabels: {
              enabled: true
          }
        },
      },

      series: [
        {
          name: 'EXTRA WORKS',
          data: orders.extra,
        },
        {
          name: 'NEW WORKS',
          data: orders.new,
        },
      ]

    });
    
    const configValuations = merge({}, baseColumnChartConfig, {

      title: {
        text: 'Valuations',
        margin: 10,
        style: { 
          fontSize: 14
        }
      },

      colors: [ '#F5A363' ],

      xAxis: {
        categories
      },

      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter
          }
        }
      },

      series: [
        {
          name: 'Valuations',
          data: valuations
        }
      ]

    });

    return [ configEstimates, configOrders, configValuations ];

  }
)
