import { connect } from 'react-redux';
import Component from './Component';

import selectListItems from './Selectors/selectListItems';
import selectProject from './Selectors/selectProject';
import selectActiveTab from './Selectors/selectActiveTab';

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import componentDidUpdate from './Handlers/componentDidUpdate';
import didUpdateProject from './Handlers/didUpdateProject';
import didDeleteProject from './Handlers/didDeleteProject';
import didCreateEstimate from './Handlers/didCreateEstimate';
import didCreateOrder from './Handlers/didCreateOrder';
import didCreateValuation from './Handlers/didCreateValuation';
import didUpdateClientContact from './Handlers/didUpdateClientContact';
import didUpdateClientSite from './Handlers/didUpdateClientSite';
import didSendEmail from './Handlers/didSendEmail';
import onClickActionMenuItem from './Handlers/onClickActionMenuItem';
import onCloseProjectModal from './Handlers/onCloseProjectModal';
import onCloseEstimateModal from './Handlers/onCloseEstimateModal';
import onCloseOrderModal from './Handlers/onCloseOrderModal';
import onCloseValuationModal from './Handlers/onCloseValuationModal';
import onCloseClientContactModal from './Handlers/onCloseClientContactModal';
import onCloseClientSiteModal from './Handlers/onCloseClientSiteModal';
import onCloseEmailModal from './Handlers/onCloseEmailModal';
import onSelectRootDirectory from './Handlers/onSelectRootDirectory';
import onSelectItem from './Handlers/onSelectItem';
import onDoubleClickItem from './Handlers/onDoubleClickItem';
import onToggleItemCheckbox from './Handlers/onToggleItemCheckbox';

import onClickNewEstimate from './Handlers/onClickNewEstimate';
import onClickNewOrder from './Handlers/onClickNewOrder';
import onClickNewValuation from './Handlers/onClickNewValuation';
import onChangeEstimateField from './Handlers/onChangeEstimateField';
import onChangeActiveTab from './Handlers/onChangeActiveTab';
import onChangeSearch from './Handlers/onChangeSearch';

import onClickGrid from './Handlers/onClickGrid';
import onClickList from './Handlers/onClickList';
import onClickUpload from './Handlers/onClickUpload';
import onClickDownload from './Handlers/onClickDownload';
import onCloseUploadModal from './Handlers/onCloseUploadModal';
import onClickNewFolder from './Handlers/onClickNewFolder';
import onCloseDirectoryModal from './Handlers/onCloseDirectoryModal';
import onClickDelete from './Handlers/onClickDelete';
import onClickEdit from './Handlers/onClickEdit';
import didCreateDirectory from './Handlers/didCreateDirectory';
import didRename from './Handlers/didRename';
import didUpload from './Handlers/didUpload';
import onClickFileContextMenuItem from './Handlers/onClickFileContextMenuItem';
import onClickPathBreadcrumb from './Handlers/onClickPathBreadcrumb';
import onChangeNotes from './Handlers/onChangeNotes';
import didCreateJobSheetModal from './Handlers/didCreateJobSheetModal';
import onCloseJobSheetModal from './Handlers/onCloseJobSheetModal';
import onCloseProjectCompleteModal from './Handlers/onCloseProjectCompleteModal';
import onDragEnter from './Handlers/onDragEnter';
import onClickMove from './Handlers/onClickMove';
import onCloseMoveFilesModal from './Handlers/onCloseMoveFilesModal';
import didMoveFiles from './Handlers/didMoveFiles';
import onCloseViewEmailModal from './Handlers/onCloseViewEmailModal';
import onClickViewEmail from './Handlers/onClickViewEmail';
import onCloseRetentionPaymentModal from './Handlers/onCloseRetentionPaymentModal';
import didUpdateRetentionPayment from './Handlers/didUpdateRetentionPayment';
import onCloseRamsRecordModal from './Handlers/onCloseRamsRecordModal';
import onCloseValuationDateModal from './Handlers/onCloseValuationDateModal';

const mapStateToProps = (state) => ({
  project: selectProject(state),
  fetching: state.screens.ProjectView.fetching,
  rootDirectories: state.screens.ProjectView.rootDirectories,
  currentDirectoryPath: state.screens.ProjectView.currentDirectoryPath,
  selectedItemPath: state.screens.ProjectView.selectedItemPath,
  listItems: selectListItems(state),
  activeTab: selectActiveTab(state),
  projectPhases: state.core.projectPhases,
  projectStatuses: state.core.projectStatuses,
  estimateStatuses: state.core.estimateStatuses,
  estimateTenderStatuses: state.core.estimateTenderStatuses,
  estimateLatestActions: state.core.estimateLatestActions,
  serviceTypes: state.core.serviceTypes,
  users: state.core.users,
  showProjectModal: state.screens.ProjectView.showProjectModal,
  showEstimateModal: state.screens.ProjectView.showEstimateModal,
  showOrderModal: state.screens.ProjectView.showOrderModal,
  showValuationModal: state.screens.ProjectView.showValuationModal,
  showClientContactModal: state.screens.ProjectView.showClientContactModal,
  showClientSiteModal: state.screens.ProjectView.showClientSiteModal,
  showUploadModal: state.screens.ProjectView.showUploadModal,
  showDirectoryModal: state.screens.ProjectView.showDirectoryModal,
  showEmailModal: state.screens.ProjectView.showEmailModal,
  searchTerm: state.screens.ProjectView.searchTerm,
  filemode: state.screens.ProjectView.filemode,
  selectedItemType: state.screens.ProjectView.selectedItemType,
  directoryActionType: state.screens.ProjectView.directoryActionType,
  showJobSheetModal: state.screens.ProjectView.showJobSheetModal,
  showProjectCompleteModal: state.screens.ProjectView.showProjectCompleteModal,
  showMoveFilesModal: state.screens.ProjectView.showMoveFilesModal,
  fetchingDirectory: state.screens.ProjectView.fetchingDirectory,
  checkedItemPaths: state.screens.ProjectView.checkedItemPaths,
  selectedEmailId: state.screens.ProjectView.selectedEmailId,
  showViewEmailModal: state.screens.ProjectView.showViewEmailModal,
  selectedRetentionPaymentId: state.screens.ProjectView.selectedRetentionPaymentId,
  showRetentionPaymentModal: state.screens.ProjectView.showRetentionPaymentModal,
  ramsRecordModal: state.screens.ProjectView.ramsRecordModal,
  valuationDateModal: state.screens.ProjectView.valuationDateModal,
})

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  componentDidUpdate,
  didUpdateProject,
  didDeleteProject,
  didCreateEstimate,
  didCreateOrder,
  didCreateValuation,
  didUpdateClientContact,
  didUpdateClientSite,
  didSendEmail,
  onClickActionMenuItem,
  onCloseProjectModal,
  onCloseEstimateModal,
  onCloseOrderModal,
  onCloseValuationModal,
  onCloseClientContactModal,
  onCloseClientSiteModal,
  onSelectRootDirectory,
  onSelectItem,
  onDoubleClickItem,
  onToggleItemCheckbox,
  onClickNewEstimate,
  onClickNewOrder,
  onClickNewValuation,
  onChangeEstimateField,
  onChangeActiveTab,
  onChangeSearch,
  onClickGrid,
  onClickList,
  onClickUpload,
  onClickDownload,
  onCloseUploadModal,
  onClickNewFolder,
  onCloseDirectoryModal,
  onClickDelete,
  onClickEdit,
  didCreateDirectory,
  didRename,
  didUpload,
  onClickFileContextMenuItem,
  onClickPathBreadcrumb,
  onChangeNotes,
  didCreateJobSheetModal,
  onCloseJobSheetModal,
  onCloseProjectCompleteModal,
  onDragEnter,
  onClickMove,
  onCloseMoveFilesModal,
  didMoveFiles,
  onCloseViewEmailModal,
  onClickViewEmail,
  onCloseRetentionPaymentModal,
  didUpdateRetentionPayment,
  onCloseRamsRecordModal,
  onCloseEmailModal,
  onCloseValuationDateModal,
};

export default connect(mapStateToProps, actionCreators)(Component);