import { closeSubInvoiceModal } from '../State/subInvoiceModal'
import fetch from '../Services/fetch'

export default (subInvoice) => (dispatch) => {

  dispatch(closeSubInvoiceModal())

  if (subInvoice) {
    dispatch(fetch())
  }

}
