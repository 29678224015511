export default (values) => {

  const errors = {};

  if (!values.project_id) errors.project_id = true;
  if (!values.client_contact_id) errors.client_contact_id = true;
  if (!values.reference) errors.reference = true;
  if (!values.target_total) errors.target_total = true;
  if (values.uplift_percentage === null || values.uplift_percentage === '') errors.uplift_percentage = true;

  if (values.id) {
    if (values.extra_works === undefined || values.extra_works === null) errors.extra_works = true;
  }

  return errors;
  
}