// @flow

import * as React from 'react';
import classnames from 'classnames';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';

import {
  CaretDownOutlined,
  CopyOutlined,
  DeleteOutlined,
  DragOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { Menu, Dropdown } from 'antd';
import styles from './Item.css';

import type {
  EstimateCaveat,
  UpdateEstimateCaveatFieldParams,
  AddEstimateCaveatParams,
  RemoveEstimateCaveatParams,
  DuplicateEstimateCaveatParams
} from '../../../types';

type Props = {
  editable: boolean,
  estimateCaveatIndex: number,
  estimateCaveat: EstimateCaveat,
  onChangeEstimateCaveatField: (params: UpdateEstimateCaveatFieldParams) => void,
  onClickAddEstimateCaveat: (params: AddEstimateCaveatParams) => void,
  onClickRemoveEstimateCaveat: (params: RemoveEstimateCaveatParams) => void,
  onClickDuplicateEstimateCaveat: (params: DuplicateEstimateCaveatParams) => void,
}

type State = {
  hoveredDragArea: boolean,
  hoveredActionArea: boolean
}

class Component extends React.PureComponent<Props,State> {

  state = {
    hoveredDragArea: false,
    hoveredActionArea: false
  }

  onChangeEstimateCaveatField = async (field: string, value: string) => {

    const {
      estimateCaveatIndex,
      onChangeEstimateCaveatField
    } = this.props;
    
    await onChangeEstimateCaveatField({
      estimateCaveatIndex,
      field,
      value
    })
  }

  onClickMenu = ({ key }: any) => {

    const {
      estimateCaveatIndex,
      onClickRemoveEstimateCaveat,
      onClickAddEstimateCaveat,
      onClickDuplicateEstimateCaveat
    } = this.props;

    if (key === 'remove') onClickRemoveEstimateCaveat({ estimateCaveatIndex });
    
    if (key === 'add') onClickAddEstimateCaveat({ estimateCaveatIndex });

    if (key === 'duplicate') onClickDuplicateEstimateCaveat({ estimateCaveatIndex });

  }

  render () {

    const {
      editable,
      estimateCaveat
    } = this.props;

    const DragArea = SortableHandle(props => (
      <span {...props}>
        {props.children}
      </span>
    ));

    const ActionMenu = (

      <Menu onClick={this.onClickMenu}>

        <Menu.Item key="add">
          <PlusOutlined />
          {` Add`}
        </Menu.Item>

        <Menu.Item key="duplicate">
          <CopyOutlined />
          {` Duplicate`}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="remove">
          <DeleteOutlined />
          {` Remove`}
        </Menu.Item>

      </Menu>

    )

    const showLineNumber = (!editable || !this.state.hoveredDragArea);

    const showDragIcon = editable && this.state.hoveredDragArea;

    return (
      <div className={styles.wrapper}>

        <DragArea
          className={styles.dragArea}
          style={{ cursor: editable ? 'grab' : 'default' }}
          onMouseEnter={() => editable && this.setState({ hoveredDragArea: true })}
          onMouseLeave={() => this.setState({ hoveredDragArea: false })}
        >

          {showLineNumber && (
            <div className={styles.lineNumber}>
              {estimateCaveat.line_number}
            </div>
          )}

          {showDragIcon && (
            <DragOutlined className={styles.dragIcon} />
          )}

        </DragArea>

        <div className={styles.table}>

          <div className={classnames(styles.cell, styles.flexCell)}>
            <textarea
              className={styles.textarea}
              value={estimateCaveat.content || ''}
              onChange={event => this.onChangeEstimateCaveatField('content', event.target.value)}
              disabled={!editable}
            />
          </div>

        </div>
      
        <div
          className={classnames(styles.actionArea, { [styles.actionAreaHovered]: this.state.hoveredActionArea })}
          onMouseEnter={() => this.setState({ hoveredActionArea: true })}
          onMouseLeave={() => this.setState({ hoveredActionArea: false })}
        >

          {editable && (
            <Dropdown overlay={ActionMenu} trigger={['click']}>
              <CaretDownOutlined className={styles.actionButton} />
            </Dropdown>
          )}
          
        </div>

      </div>
    );
  }
}

export default SortableElement(Component);