// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled, EditFilled, InfoCircleOutlined, RocketFilled } from '@ant-design/icons';
import { Table, Popover } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { sortBy, reverse } from 'lodash';
import { getTeamUrl } from 'Common';

type Props = {
  project: Object
}

const statusIcons = {
  '1': (
    <EditFilled style={{ fontSize: 15 }} />
  ),
  '2': (
    <RocketFilled style={{ fontSize: 15, color: '#179ded' }} />
  ),
  '3': (
    <CloseCircleFilled style={{ fontSize: 15, color: 'grey' }} />
  ),
  '4': (
    <CheckCircleFilled style={{ fontSize: 15, color: '#32bd6a' }} />
  ),
  '5': (
    <CheckCircleFilled style={{ fontSize: 15, color: '#32bd6a' }} />
  )
}

export default class extends React.PureComponent<Props> {

  render() {
    const { project } = this.props;

    const columns = [

      {
        title: 'Reference',
        dataIndex: '',
        key: 'id',
        width: 200,
        render: (text, record) => (
          <div style={{ width: 168 }}>
            <div>
              <NavLink to={getTeamUrl(`valuations/${record.id}/view`)}>
                { `Valuation ${record.number} ${record.reference_suffix ? `/ ${record.reference_suffix}` : ''}` }
              </NavLink>
            </div>
            <div>
              {record.reference}
            </div>
          </div>
        ),
      },

      {
        title: 'Client / Site',
        dataIndex: '',
        key: 'site.name',
        width: 252,
        render: () => (

          <div>

            <div className="ellipsis">
              <strong>
                {project.client_site.client.name}
              </strong>
            </div>

            <div className="ellipsis">
              {project.client_site.name}
            </div>

          </div>

        )
      },

      {
        title: ' ',
        dataIndex: '',
        key: 'notes',
        width: 25,
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } })
      },

      {
        title: 'Date',
        dataIndex: '',
        key: 'date',
        render: (text, record) => (
          <div>
            {record.date ? moment(record.date).format('DD MMM YYYY') : '--'}
          </div>
        ),
      },

      {
        title: 'Value',
        dataIndex: '',
        key: 'value_total',
        onHeaderCell: () => ({
          style: { textAlign: 'right' },
        }),
        onCell: () => ({
          style: { textAlign: 'right' },
        }),
        render: (text, record) => (
          <div>
            {`${record.complete_total < 0 ? '-' : ''}£${numeral(Math.abs(record.complete_total)).format('0,0.00')}`}
            <div style={{ fontSize: 12 }}>
              {record.valuation_id ? (
                <div style={{ color: record.complete_total_diff < 0 ? 'red' : 'green' }}>
                  {`${record.complete_total_diff < 0 ? '-' : '+'}£${numeral(Math.abs(record.complete_total_diff)).format('0,0.00')}`}
                </div>
              ) : (
                <div style={{ color: record.complete_total < 0 ? 'red' : 'green' }}>
                  {`${record.complete_total < 0 ? '-' : '+'}£${numeral(Math.abs(record.complete_total)).format('0,0.00')}`}
                </div>
              )}
            </div>
          </div>
        )
      },

      {
        title: ' ',
        dataIndex: '',
        key: 'status_key',
        render: (text, record) => {
          let title = '';
          let color = '#179ded'

          if (record.final === 1 && !record.final_confirmed_at) {
            title = 'Final Draft';
          } else if (record.final_confirmed_at) {
            title = 'Final';
            color = 'green';
          }

          return (title !== '' && (
            <span style={{ backgroundColor: color, padding: 5, borderRadius: 5, color: '#FFF' }}>
              {title}
            </span>
          ))

        }
      },

      {
        title: 'Status',
        dataIndex: 'status.title',
        key: 'status.key',
        render: (text, record) => (
          <span>
            {statusIcons[record.status.key]}
            {` ${record.status.title}`}
          </span>
        )
      },

      {
        title: 'Payment Certificate',
        dataIndex: '',
        render: (text, record) => (                  
          <React.Fragment>

            {record.payment_certificate_storage_path && (
              <React.Fragment>
              
                <CheckCircleFilled style={{ color: '#14be71', fontSize: 15, paddingRight: 5}} />
                {'File Uploaded'}

              </React.Fragment>

            )}

          </React.Fragment>
        )        
      }
    ];

    const valuations = reverse(sortBy(project.valuations, ['id']))

    return (
      <Table
        dataSource={valuations}
        columns={columns}
        rowKey={record => record.id}
      />
    )
  };
}
