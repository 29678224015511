// @flow
import * as React from 'react';
import { push } from 'connected-react-router';
import { getUser, getTeamUrl } from 'Common';

type Props = {
  dispatch: Function
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => {
    const user = getUser()
    if (user.type === 'Subcontractor') {
      this.props.dispatch(push(getTeamUrl('timesheet_posting')))
    } else if (user.type === 'Basic') {
      this.props.dispatch(push(getTeamUrl('estimates')))
    } else {
      this.props.dispatch(push(getTeamUrl('projects')))
    }
  };

  render = () => <div />
  
}