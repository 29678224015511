// @flow
import { getTeamUrl } from 'Common';

export default (type) => async (dispatch: Function, getState: Function) => {

  const { project, selectedEstimateIds, selectedOrderIds } = getState().screens.ProjectView;

  let url

  if (selectedEstimateIds.length) {
    url = `${getTeamUrl(`projects/${project.id}/draft_order_summary_pdf`, 'exports')}?estimate_ids=${selectedEstimateIds.join(',')}`
  } else {
    url = `${getTeamUrl(`projects/${project.id}/order_summary_${type}`, 'exports')}?order_ids=${selectedOrderIds.join(',')}`
  }

  window.open(url);

}
