// @flow

import * as React from 'react';
import { Tabs } from 'antd';
import Screen from '../../Components/Screen';
import Initializing from './Components/Initializing';
import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import Items from './Components/Items';
import Optionals from './Components/Optionals';
import Caveats from './Components/Caveats';
import Footer from './Components/Footer';
import Sidebar from './Components/Sidebar';
import EstimateModal from '../../Modals/Estimate'
import OrderModal from '../../Modals/Order'
import EmailEstimateModal from '../../Modals/EmailEstimate'
import EstimateDuplicate from '../../Modals/EstimateDuplicate';
import SelectCaveatsModal from '../../Modals/SelectCaveats';
import SelectItemDescriptionModal from '../../Modals/SelectItemDescription';
import ClientSiteModal from '../../Modals/ClientSite';
import ClientContactModal from '../../Modals/ClientContact';
import ProjectEditorModal from '../../Modals/Project';

type Props = {
  estimate: any,
  fetching: boolean,
  showEstimateModal: boolean,
  showOrderModal: boolean,
  showEmailModal: boolean,
  showSelectCaveatsModal: boolean,
  showSelectItemDescriptionModal: boolean,
  showEstimateDuplicateModal: boolean,
  showClientSiteModal: boolean,
  showClientContactModal: boolean,
  projectEditorModal: Object,

  componentWillMount: (props: any) => void,
  componentWillUnmount: () => void,
  componentDidUpdate: (props: any) => void,
  
  onChangeTab: Function,
  didUpdateEstimate: Function,
  didCreateOrder: Function,
  didSendEmail: Function,
  didUpdateClientSite: Function,
  didUpdateClientContact: Function,
  onSelectCaveats: Function,
  onSelectItemDescription: Function,
  onCloseEstimateModal: Function,
  onCloseOrderModal: Function,
  onCloseEmailModal: Function,
  onCloseSelectCaveatsModal: Function,
  onCloseSelectItemDescriptionModal: Function,
  onCloseEstimateDuplicateModal: Function,
  onCloseClientSiteModal: Function,
  onCloseClientContactModal: Function,
  onCloseProjectEditorModal: Function,
  didCreateEstimateDuplicateModal: Function
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentDidUpdate = () => this.props.componentDidUpdate(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      estimate,
      fetching,
      showEstimateModal,
      showOrderModal,
      showEmailModal,
      showSelectCaveatsModal,
      showSelectItemDescriptionModal,
      showEstimateDuplicateModal,
      showClientSiteModal,
      showClientContactModal,
      projectEditorModal,
      onChangeTab,
      didUpdateEstimate,
      didCreateOrder,
      didSendEmail,
      didUpdateClientSite,
      didUpdateClientContact,
      onSelectCaveats,
      onSelectItemDescription,
      onCloseEstimateModal,
      onCloseOrderModal,
      onCloseEmailModal,
      onCloseSelectCaveatsModal,
      onCloseSelectItemDescriptionModal,
      onCloseEstimateDuplicateModal,
      onCloseClientSiteModal,
      onCloseClientContactModal,
      onCloseProjectEditorModal,
      didCreateEstimateDuplicateModal
    } = this.props;

    if (!estimate) return <Initializing />

    return (

      <Screen style={{ marginRight: 280, paddingRight: 30 }}>
          
        <Breadcrumbs />

        <Header />

        <Tabs
          defaultActiveKey="ITEMS"
          animated={false}
          onChange={onChangeTab}
        >

          <Tabs.TabPane tab="ITEMS" key="ITEMS">
            <Items />
          </Tabs.TabPane>

          <Tabs.TabPane tab="OPTIONALS" key="OPTIONALS">
            <Optionals />
          </Tabs.TabPane>

          <Tabs.TabPane tab="CAVEATS" key="CAVEATS">
            <Caveats />
          </Tabs.TabPane>
        
        </Tabs>

        <Footer />

        <Sidebar />

        {showEstimateModal && (
          <EstimateModal
            estimate={estimate}
            onClose={onCloseEstimateModal}
            didUpdate={didUpdateEstimate}
          />
        )}

        {showOrderModal && (
          <OrderModal
            isCreatingFromEstimate
            projectId={estimate.project.id}
            estimate={estimate}
            onClose={onCloseOrderModal}
            didCreate={didCreateOrder}
          />
        )}

        {showEmailModal && (
          <EmailEstimateModal
            estimate={estimate}
            onClose={onCloseEmailModal}
            didSend={didSendEmail}
          />
        )}

        {showEstimateDuplicateModal && (

          <EstimateDuplicate
            estimateId={estimate.id}
            didCreate={didCreateEstimateDuplicateModal}
            onClose={onCloseEstimateDuplicateModal}            
          />

        )}

        {showSelectCaveatsModal && (
          <SelectCaveatsModal
            onSelect={onSelectCaveats}
            onClose={onCloseSelectCaveatsModal}            
          />
        )}

        {showSelectItemDescriptionModal && (
          <SelectItemDescriptionModal
            onSelect={onSelectItemDescription}
            onClose={onCloseSelectItemDescriptionModal}
          />
        )}

        {showClientSiteModal && (
          <ClientSiteModal
            onClose={onCloseClientSiteModal}
            didUpdate={didUpdateClientSite}
            clientSite={estimate.project.client_site}
          />
        )}

        {showClientContactModal && (
          <ClientContactModal
            onClose={onCloseClientContactModal}
            didUpdate={didUpdateClientContact}
            clientContact={estimate.client_contact}
          />
        )}

        {projectEditorModal.show && (
          <ProjectEditorModal
            {...projectEditorModal}
            onClose={onCloseProjectEditorModal}
          />
        )}

      </Screen>

    )
  }
}