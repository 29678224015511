import React from 'react'
import { Modal } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import deleteSubOrder from '../../../Services/delete'

export default () => (dispatch, getState) => {

  const { subOrder } = getState().screens.SubOrder

  Modal.confirm({
    title: `Delete "${subOrder.reference}"`,
    content: 'Are you sure you want to delete this order?',
    icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
    okType: 'danger',
    okText: 'Delete',
    onOk: () => dispatch(deleteSubOrder()),
  })

}
