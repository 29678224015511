exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2qkadqQ5cbcIgDLjlZDPuj {\n\n}\n\n.nFRt_ezknzfO5DhdL23rV {\n  \n}\n\n._2zUXzHB1ZRkUZtV2DX0sWb {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n._2zTJnGAiiICwGZzU007DUu {\n  flex: 1;\n  margin: 0px;\n  font-size: 28px;\n  font-weight: 500;\n  color: black;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Invoices/Components/Header/Component.css"],"names":[],"mappings":"AAAA;;CAEC;;AAED;;CAEC;;AAED;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;CACd","file":"Component.css","sourcesContent":[".wrapper {\n\n}\n\n.breadcrumbs {\n  \n}\n\n.header {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.title {\n  flex: 1;\n  margin: 0px;\n  font-size: 28px;\n  font-weight: 500;\n  color: black;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_2qkadqQ5cbcIgDLjlZDPuj",
	"breadcrumbs": "nFRt_ezknzfO5DhdL23rV",
	"header": "_2zUXzHB1ZRkUZtV2DX0sWb",
	"title": "_2zTJnGAiiICwGZzU007DUu"
};