// @flow

import { setSelectedItemPath } from '../State/selectedItemPath';
import { setSelectedItemType } from '../State/selectedItemType';

export default (path: string, type: string) => async (dispatch: Function) => {

  dispatch(setSelectedItemPath(path));

  dispatch(setSelectedItemType(type));

}