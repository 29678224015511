export default (values) => {

  const errors = {};

  if (!values.client_contact_id) errors.client_contact_id = true;
  if (!values.user_id) errors.user_id = true;
  if (!values.date) errors.date = true;
  
  return errors;
  
}