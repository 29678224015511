import { createSelector } from 'reselect';

const selectValuation = state => state.screens.ValuationView.valuation;

export default createSelector(
  selectValuation,
  (valuation) => {

    if (!valuation) return [];

    const items = [];

    valuation.orders.forEach((order, orderIndex) => {

      items.push({
        type: 'OrderHeader',
        height: 60,
        order,
        orderIndex
      });

      order.order_groups.forEach((orderGroup, orderGroupIndex) => {

        items.push({
          type: 'GroupHeader',
          height: 70,
          order,
          orderIndex,
          orderGroup,
          orderGroupIndex
        });

        orderGroup.order_items.forEach((orderItem, orderItemIndex) => {

          items.push({
            type: 'GroupItem',
            height: 50,
            order,
            orderIndex,
            orderGroup,
            orderGroupIndex,
            orderItem,
            orderItemIndex,
            valuationItem: orderItem.valuation_item
          });

        });
        
        if (orderGroup.order_items.length > 0) {
          items.push({
            type: 'GroupFooter',
            height: 25,
            orderIndex,
            orderGroupIndex
          });
        }

      })

      items.push({
        type: 'OrderFooter',
        height: 80,
        order,
        orderIndex
      });

    })

    items.push({
      type: 'Footer',
      height: 100,
      valuation
    });

    return items;

  }
)