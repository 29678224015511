import { Modal } from 'antd';
import { getProps } from '../props';
import { getEstimate } from '../../../HttpRequests/estimates';
import { setShowSelectEstimateOptionalsModal } from '../State/showSelectEstimateOptionalsModal';
import handleException from '../../../Core/Helpers/handleException';

export default (estimateId, delay = 0) => async (dispatch) => {

  if (getProps().order) return;

  try {

    const params = {
      include: 'estimate_optionals'
    }

    const response = await getEstimate({ id: estimateId, params });

    const estimate = response.data.data;

    if (estimate.estimate_optionals.length === 0) return;

    setTimeout(() => {

      Modal.confirm({
        title: `Optional Items`,
        content: `The selected estimate has some optional items. Would you like to review them?`,
        width: 430,
        okText: 'Review Optionals',
        cancelText: 'Ignore',
        onOk: () => {
          setTimeout(() => dispatch(setShowSelectEstimateOptionalsModal(true)), 500)
        }
      });

    }, delay);

  } catch (error) {

    handleException(error);
    
  }

}