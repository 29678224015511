import { getProps } from '../props';
import prepareCaveats from '../Services/prepareCaveats';

export default async (values, dispatch) => {
  
  const { onClose, onSelect } = getProps();

  const caveats = dispatch(prepareCaveats())

  onSelect(caveats);
  onClose();

};
