import * as React from 'react'
import { connect } from 'react-redux'
import numeral from 'numeral'
import classnames from 'classnames'
import styles from './Summary.css'

import onChangeTotalCost from '../Handlers/onChangeTotalCost'

const Summary = class extends React.PureComponent {

  render () {

    const {
      typeTotals,
      editable,
      project,
      onChangeTotalCost,
    } = this.props

    return (

      <div className={styles.wrapper}>

        <table className={styles.targetSummaryTable} cellPadding="0" cellSpacing="0">
          <tbody>

            <tr>
              <th colSpan="4">TARGET SUMMARY</th>
            </tr>
            
            <tr>
              <th>Service</th>
              <th>Value</th>
              <th>Target %</th>
              <th>Target &pound;</th>
            </tr>

            {Object.keys(typeTotals).filter(key => typeTotals[key].value > 0).map((key) => (
              <tr key={key}>
                <td>{typeTotals[key].label}</td>
                <td>{`£${numeral(typeTotals[key].value).format('0,0.00')}`}</td>
                <td>{`${Number(typeTotals[key].target_cost_percentage).toFixed(2)}%`}</td>
                <td>{`£${numeral(typeTotals[key].target_cost_amount).format('0,0.00')}`}</td>
              </tr>
            ))}
            
          </tbody>
        </table>

        <table className={styles.actualSummaryTable} cellPadding="0" cellSpacing="0">
          <tbody>

            <tr>
              <th colSpan="4">ACTUAL SUMMARY</th>
            </tr>
            
            <tr key='header'>
              <th>Total Spend £</th>
            </tr>
            
            <tr key='row'>
              <td>
                {!editable && (
                  <div
                    className={classnames(styles.outlet, { [styles.outletClickable]: editable })}
                    role="presentation"
                  >
                    {`£${numeral(project.total_cost).format('0,0.00')}`}
                  </div>
                )}
                {editable && (
                  <input
                    value={project.total_cost}
                    onChange={(event) => onChangeTotalCost(event.target.value)}
                    className={styles.numberInput}
                  />
                )}
              </td>
            </tr>
            
          </tbody>
        </table>

      </div>

    )
  }
}

const mapStateToProps = (state) => ({
  project: state.screens.ProjectView.project,
})

const actionCreators = {
  onChangeTotalCost,
}

export default connect(mapStateToProps, actionCreators)(Summary)
