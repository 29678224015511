import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openProjectEditorModal = createAction('SCREENS/CLIENT_VIEW/OPEN_PROJECT_EDITOR_MODAL')
const closeProjectEditorModal = createAction('SCREENS/CLIENT_VIEW/CLOSE_PROJECT_EDITOR_MODAL')

const defaultState = {
  show: false,
  project: null,
}

const reducer = handleActions(
  {
    [openProjectEditorModal]: (state, { payload }) => ({
      show: true,
      project: get(payload, 'project'),
    }),
    [closeProjectEditorModal]: () => defaultState,
  },
  defaultState,
)

export { openProjectEditorModal, closeProjectEditorModal, reducer }
