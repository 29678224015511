import { createSelector } from 'reselect'
import { sumBy } from 'lodash'

const selectSubValuation = state => state.screens.SubValuation.subValuation

export default createSelector(
  selectSubValuation,
  (subValuation) => {

    const subOrderItemTotal = subValuation.sub_valuation_items.reduce((carry, subValuationItem) => {
      return carry + subValuationItem.sub_order_item.total
    }, 0)

    const completeTotal = sumBy(subValuation.sub_valuation_items, 'complete_total')
    const aggregateTotal = sumBy(subValuation.sub_valuation_items, 'aggregate_total')

    const previous = aggregateTotal - completeTotal

    return {
      previous,
      new: aggregateTotal,
      difference: completeTotal,
      progress: (aggregateTotal / subOrderItemTotal) * 100,
    }

  },
)
