exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2FoAV8Y6i7IIAm0lA_8hbc {\n  display: flex;\n  justify-content: flex-end;\n}\n\n._2zG9YakaUEX619fqY69x6U {\n  margin-right: auto;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Modals/EditOrderItem/Components/Footer/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B;CAC3B;;AAED;EACE,mBAAmB;CACpB","file":"Component.css","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.deleteButton {\n  margin-right: auto;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"footer": "_2FoAV8Y6i7IIAm0lA_8hbc",
	"deleteButton": "_2zG9YakaUEX619fqY69x6U"
};