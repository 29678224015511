import { createAction, handleActions } from 'redux-actions'

const setSubcontractor = createAction('SCREENS/SUB_PROJECT/SET_SUBCONTRACTOR')
const resetSubcontractor = createAction('SCREENS/SUB_PROJECT/RESET_SUBCONTRACTOR')

const defaultState = null

const reducer = handleActions(
  {
    [setSubcontractor]: (state, { payload }) => payload,
    [resetSubcontractor]: () => defaultState,
  },
  defaultState,
)

export { setSubcontractor, resetSubcontractor, reducer }
