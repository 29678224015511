import { createAction, handleActions } from 'redux-actions';

const setFetchingRootDirectories = createAction("SCREENS/PROJECT_VIEW/SET_FETCHING_ROOT_DIRECTORIES");

const defaultState = false

const reducer = handleActions(
  {
    [setFetchingRootDirectories]: (state, action) => action.payload
  },
  defaultState
);

export { setFetchingRootDirectories, reducer };