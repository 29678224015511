import axios from './axios';

export const getOrders = ({ params } = {}) => axios.get(`orders`, { params });
export const getOrder = ({id, params }) => axios.get(`orders/${id}`, { params });
export const createOrder = ({ data }) => axios.post('orders', data);
export const updateOrder = ({ id, data }) => axios.put(`orders/${id}`, data);
export const deleteOrder = ({ id }) => axios.delete(`orders/${id}`);
export const updateOrderItems = ({ id, data }) => axios.put(`orders/${id}/items`, data);
export const updateOrderCaveats = ({ id, data }) => axios.put(`orders/${id}/caveats`, data);
export const emailOrderAcknowledgement = ({ id, data }) => axios.post(`orders/${id}/send_acknowledgement_email`, data);
export const uploadOrderFile = ({ id, data, config } = {}) => axios.post(`/orders/${id}/files/upload_file`, data, config);
export const deleteOrderFile = ({ id } = {}) => axios.delete(`/orders/${id}/files/delete_file`);
export const getOrderFileUri = ({id }) => axios.get(`orders/${id}/files/get_file_uri`);
export const getOrderTotals = ({ params } = {}) => axios.get(`orders/totals`, { params });
export const uploadOrderMedia = ({ id, data } = {}) => axios.post(`/orders/${id}/media`, data);