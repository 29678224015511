import { connect } from 'react-redux'
import TimesheetAdjustmentsPopover from './TimesheetAdjustmentsPopover'

const mapStateToProps = (state) => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(TimesheetAdjustmentsPopover)
