import { push } from 'connected-react-router'
import { message } from 'antd'
import { getTeamUrl } from 'Common'
import { deleteSubValuation } from '../../../HttpRequests/subValuations'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { subValuation } = getState().screens.SubValuation
  
  try {

    await deleteSubValuation({ id: subValuation.id })

    message.success(`Deleted "${subValuation.reference}"`)
    
    dispatch(push(getTeamUrl(`sub_projects/${subValuation.subcontractor_id}`)))
    
  } catch (error) {

    handleException(error)

  }
  
}
