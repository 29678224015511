import { createAction, handleActions } from 'redux-actions';

const loadProjectStatuses = createAction("CORE/PROJECT_STATUSES/LOAD_PROJECT_STATUSES");
const clearProjectsStatuses = createAction("CORE/PROJECT_STATUSES/CLEAR_PROJECT_STATUSES");

const defaultState = []

const reducer = handleActions(
  {
    [loadProjectStatuses]: (state, action) => action.payload,
    [clearProjectsStatuses]: () => []
  },
  defaultState
);

export { loadProjectStatuses, clearProjectsStatuses, reducer };