import { createSelector } from 'reselect';
import { assign } from 'lodash';

const selectEstimate = state => state.screens.EstimateView.estimate;

export default createSelector(
  selectEstimate,
  (estimate) => {

    if (!estimate) return [];

    let incrementer = 0;

    const items = [];

    if (estimate.estimate_optionals.length > 0) {

      items.push({
        type: 'Header',
        height: 60
      });

      estimate.estimate_optionals.forEach((estimateOptional, estimateOptionalIndex) => {
        
        let lineNumber = null;

        if (estimateOptional.type) {
          incrementer += 1;
          lineNumber = incrementer;
        }

        items.push({
          type: 'Item',
          height: 50,
          estimateOptional: assign({}, estimateOptional, { line_number: lineNumber }),
          estimateOptionalIndex
        });

      })

      items.push({
        type: 'Footer',
        height: 60
      });

    }

    if (estimate.estimate_optionals.length === 0) {

      items.push({
        type: 'Placeholder',
        height: 100
      });

    }

    return items;

  }
)