import { Modal } from 'antd'
import deleteValuationDate from '../Services/delete'

export default () => (dispatch) => {

  Modal.confirm({
    title: `Delete Date`,
    content: 'Are you sure you want to delete this valuation date?',
    okText: 'Yes',
    width: 400,
    onOk: () => dispatch(deleteValuationDate()),
  })

}
