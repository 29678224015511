import { pick } from 'lodash'
import { message } from 'antd'
import _props from '../props'
import { updateRetentionPayment } from '../../../HttpRequests/retentionPayments'
import handleException from '../../../Core/Helpers/handleException'

export default (values) => async () => {
  
  try {

    const { id, onClose, didUpdate } = _props.get()

    const data = pick(values, [
      'due_date',
      'notes',
    ])

    const response = await updateRetentionPayment({ id, data })

    const retentionPayment = response.data.data
  
    message.success("Retention Updated")

    onClose()
    
    if (didUpdate) didUpdate(retentionPayment)

  } catch (error) {
    
    handleException(error)

  }
}