import { getTeamUrl } from 'Common'

export default () => (dispatch, getState) => {

  const state = getState()

  const { searchTerm, filters, sorting } = state.screens.Users

  const params = {}

  if (searchTerm) params.search = searchTerm

  params['filter[type]'] = filters.type

  params['filter[status]'] = filters.status

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'full_name'

  let url = `users/export?`

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.location = getTeamUrl(url, 'exports')

}
