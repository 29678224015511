// @flow
import * as React from 'react';
import classnames from 'classnames';
import { PlusOutlined } from '@ant-design/icons';
import { SortableElement } from 'react-sortable-hoc';
import styles from './Footer.css';

import type {
  AddOrderCaveatParams
} from '../../../types';

type Props = {
  editable: boolean,
  onClickAddOrderCaveat: (params: AddOrderCaveatParams) => void
}

class Component extends React.PureComponent<Props> {

  render () {

    const {
      editable,
      onClickAddOrderCaveat,
    } = this.props;

    return (
      <div
        className={styles.wrapper}
      >

        {editable && (

          <React.Fragment>
          
            <div
              className={classnames(styles.link, styles.addLink)}
              onClick={() => onClickAddOrderCaveat({})}
              role="presentation"
            >
              <PlusOutlined />
              {' Add Caveat'}
            </div>

          </React.Fragment>
          
        )}

      </div>
    );
  }
}

export default SortableElement(Component);