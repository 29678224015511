// @flow
import * as React from 'react';
import numeral from 'numeral';
import styles from './OrderFooter.css';

import type {
  Order
} from '../../../types';

type Props = {
  order: Order
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      order
    } = this.props;

    return (

      <div className={styles.wrapper}>

        <div className={styles.total}>
          {`Order Total: £${numeral(order.total).format('0,0.00')}`}
        </div>

      </div>

    )
  }
}