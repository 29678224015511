import React from 'react'
import { Modal, Button } from 'antd'
import Form from './Form'

type Props = {
  submitting: boolean,
  componentWillMount: () => void,
  onClickSubmit: () => void,
  onClickCancel: () => void,
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props)

  render() {

    const {
      submitting,
      onClickSubmit,
      onClickCancel,
    } = this.props

    const footer = []

    footer.push([
      <Button key='cancel' disabled={submitting} onClick={onClickCancel}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
        Save
      </Button>,
    ])

    return (
        
      <Modal
        title='Reset Password'
        footer={footer}
        onCancel={onClickCancel}
        visible
      >
        
        <Form
          submitting={submitting}
        />

      </Modal>

    )

  }

}
