import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import Screen from '../../Components/Screen'
import Header from './Components/Header'
import Details from './Components/Details'
import Related from './Components/Related'
import Sidebar from './Components/Sidebar'
import InvoiceModal from '../../Modals/Invoice'
import EmailInvoiceModal from '../../Modals/EmailInvoice'
import ProjectEditorModal from '../../Modals/Project'

export default class _InvoiceView_ extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    invoice: PropTypes.object,
    fetching: PropTypes.bool.isRequired,
    showInvoiceModal: PropTypes.bool.isRequired,
    showEmailModal: PropTypes.bool.isRequired,
    projectEditorModal: PropTypes.object.isRequired,
    onCloseInvoiceModal: PropTypes.func.isRequired,
    onCloseEmailInvoiceModal: PropTypes.func.isRequired,
    didUpdateInvoice: PropTypes.func.isRequired,
    didSendEmail: PropTypes.func.isRequired,
    onCloseProjectEditorModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    invoice: null
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = () => this.props.componentDidUpdate(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      invoice,
      fetching,
      showInvoiceModal,
      showEmailModal,
      projectEditorModal,
      onCloseInvoiceModal,
      onCloseEmailInvoiceModal,
      didUpdateInvoice,
      didSendEmail,
      onCloseProjectEditorModal,
    } = this.props

    return (
    
      <Spin spinning={fetching}>

        <Screen style={{ marginRight: 280, paddingRight: 30 }}>

          {!!invoice && (

            <React.Fragment>

              <Header />

              <Details />

              <Related />

              <Sidebar />

            </React.Fragment>

          )}

        </Screen>

        {showInvoiceModal && (
          <InvoiceModal
            invoice={invoice}
            onClose={onCloseInvoiceModal}
            didUpdate={didUpdateInvoice}
          />
        )}

        {showEmailModal && (
          <EmailInvoiceModal
            invoice={invoice}
            onClose={onCloseEmailInvoiceModal}
            didSend={didSendEmail}
          />
        )}

        {projectEditorModal.show && (
          <ProjectEditorModal
            {...projectEditorModal}
            onClose={onCloseProjectEditorModal}
          />
        )}

      </Spin>

    )
  }
}