exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3FMxkueTBmT9hYYVUFr0C- {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n._1gTO6ALhV1O4ImzRA8QJQl {\n  justify-content: flex-end;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/SubProjects/Components/Projects/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,gBAAgB;CACjB;;AAED;EACE,0BAA0B;CAC3B","file":"styles.css","sourcesContent":[".column {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.numeric {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"column": "_3FMxkueTBmT9hYYVUFr0C-",
	"numeric": "_1gTO6ALhV1O4ImzRA8QJQl"
};