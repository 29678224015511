import * as React from "react";
import PropTypes from "prop-types";
import Screen from '../../Components/Screen'

import SettingsMenu from '../../Components/SettingsMenu';
import Breadcrumbs from "./Components/Breadcrumbs";
import Header from "./Components/Header";
import Table from "./Components/Table";
import ItemDescriptionModal from "../../Modals/ItemDescription";

export default class extends React.PureComponent {
  
  static propTypes = {
    selectedItemDescriptionId: PropTypes.number,
    showItemDescriptionModal: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onCloseItemDescriptionModal: PropTypes.func.isRequired,
    didCreateItemDescription: PropTypes.func.isRequired,
    didUpdateItemDescription: PropTypes.func.isRequired,
    didDeleteItemDescription: PropTypes.func.isRequired
  };

  static defaultProps = { selectedItemDescriptionId: null };

  componentDidMount = () => this.props.componentDidMount();

  render () {

    const {
      showItemDescriptionModal,
      selectedItemDescriptionId,
      onCloseItemDescriptionModal,
      didCreateItemDescription,
      didUpdateItemDescription,
      didDeleteItemDescription
    } = this.props;

    return (

      <Screen style={{ marginRight: 200, paddingRight: 30 }}>

        <Breadcrumbs />

        <Header />

        <Table />

        {showItemDescriptionModal && (
          <ItemDescriptionModal
            itemDescriptionId={selectedItemDescriptionId}
            onClose={onCloseItemDescriptionModal}
            didCreate={didCreateItemDescription}
            didUpdate={didUpdateItemDescription}
            didDelete={didDeleteItemDescription}
          />
        )}

        <SettingsMenu />

      </Screen>

    );
  }
}
