import chance from 'chance'
import modalProps from '../modalProps'
import { getPlannerPeriodSubcontractors } from '../../../../../HttpRequests/planner'
import { setSubcontractors } from '../State/subcontractors'
import { setJobs } from '../State/jobs'
import { setPlannerCell } from '../State/plannerCell'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { period, _jobs, _plannerCell } = modalProps()

  dispatch(setJobs(_jobs.map(job => ({
    key: chance().guid(),
    ...job,
  }))))

  if (_plannerCell) {
    dispatch(setPlannerCell(_plannerCell))
  }

  try {

    const response = await getPlannerPeriodSubcontractors({
      params: { date: period.date },
    })

    dispatch(setSubcontractors(response.data))

  } catch (error) {

    handleException(error)

  }

}
