import { message } from 'antd'
import { createTimesheet } from '../../../HttpRequests/timesheets'

export default (values) => async () => {

  const data = {
    subcontractor_id: values.subcontractor_id,
    date: values.date,
  }

  const response = await createTimesheet({ data })

  const timesheet = response.data

  message.success('Timesheet created')

  return timesheet

}
