import { connect } from 'react-redux'
import SubProject from './SubProject'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'

const mapStateToProps = (state) => ({
  subcontractor: state.screens.SubProject.subcontractor,
  filters: state.screens.SubProject.filters,
  activeTab: state.screens.SubProject.activeTab,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
}

export default connect(mapStateToProps, actionCreators)(SubProject)
