import { combineReducers } from 'redux'

import { reducer as activeTab } from './State/activeTab'
import { reducer as subcontractors } from './State/subcontractors'
import { reducer as jobs } from './State/jobs'
import { reducer as plannerCell } from './State/plannerCell'
import { reducer as duplicate } from './State/duplicate'

export default combineReducers({
  activeTab,
  subcontractors,
  jobs,
  plannerCell,
  duplicate,
})
