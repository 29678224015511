import React from 'react'
import PropTypes from 'prop-types'
import { FormatPainterFilled } from '@ant-design/icons'
import SprayerFilledIcon from '../../SprayerFilledIcon'

const SubcontractorIcon = ({ subcontractor }) => {

  if (subcontractor.is_multiple) return (
    <div
      style={{
        width: 10,
        height: 10,
        display: 'inline-block',
        borderRadius: '50%',
        backgroundColor: 'black',
        marginLeft: 2,
        marginRight: 7,
      }}
    />
  )

  if (subcontractor.type === 'Sprayer') return (
    <SprayerFilledIcon
      color={subcontractor.icon}
      style={{ top: 2, marginLeft: 1, marginRight: 5 }}
    />
  )

  return (
    <FormatPainterFilled
      style={{ color: subcontractor.icon, marginRight: 5 }}
    />
  )

}

SubcontractorIcon.propTypes = {
  subcontractor: PropTypes.object.isRequired,
}

export default SubcontractorIcon
