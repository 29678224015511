import React from 'react'
import PropTypes from 'prop-types'
import { Tabs as TabsProvider } from 'antd'

const Tabs = props => {

  const {
    activeTab,
    onChangeTab,
  } = props

  return (

    <TabsProvider activeKey={activeTab} onChange={onChangeTab} type='card'>
      <TabsProvider.TabPane key='OVERVIEW' tab='Overview' />
      <TabsProvider.TabPane key='SUB-PROJECTS' tab='Projects' />
      <TabsProvider.TabPane key='SUB-ORDERS' tab='Orders' />
      <TabsProvider.TabPane key='SUB-VALUATIONS' tab='Valuations' />
      <TabsProvider.TabPane key='SUB-INVOICES' tab='Invoices' />
    </TabsProvider>
    
  )

}

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
}

export default Tabs
