// @flow

import * as React from 'react';

type Props = {
  children: React.Node,
  style: any,
  innerStyle: any,
  maxWidth: any
}

const Screen = ({ children, style, innerStyle, maxWidth }: Props) => (
  
  <div className="portal-screen" style={style}>
  
    <div className="portal-screen-inner" style={Object.assign({} , maxWidth && { maxWidth }, innerStyle)}>

      {children}

    </div>
  
  </div>

)

export default Screen;