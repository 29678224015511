// @flow
import * as React from 'react';
import { Modal } from 'antd';
import _ from 'lodash';
import Form from './Form';

type Props = {  
  submitting: boolean,
  directoryActionType: string,
  selectedItemType: null|string,
  componentWillMount: (props: any) => void,
  onClickCancel: () => void,
  onClickSubmit: () => void
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      submitting,
      onClickCancel,
      onClickSubmit,
      directoryActionType,
      selectedItemType
    } = this.props;

    return (
        
      <Modal
        title={` ${ directoryActionType === 'new' ? 'New Directory' : `Update ${_.capitalize(selectedItemType)}`}`}
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText={`${directoryActionType === 'new' ? 'Create': 'Rename'}`}        
      >
        
        <Form
          directoryActionType={directoryActionType}
          selectedItemType={selectedItemType}
        />

      </Modal>
    )
  }
}