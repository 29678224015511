import handleException from '../../../Core/Helpers/handleException';
import { getClientContacts } from '../../../HttpRequests/clientContacts';
import { loadClientContact } from '../State/clientContacts';

export default () => async (dispatch) => {

  try {
    
    const params = {
      pagination: 0
    }

    const response = await getClientContacts({ params })

    dispatch(loadClientContact(response.data.data));

  } catch(error) {
    handleException(error);

  }
}