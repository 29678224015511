import updateValuationField from '../../../Services/updateValuationField';

import fetchValuation from '../../../Services/fetchValuation';

export default (field, value) => async (dispatch) => {

  await dispatch(updateValuationField(field, value));

  await dispatch(fetchValuation());

}