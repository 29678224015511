import modalProps from '../modalProps'
import { updatePlannerSubcontractorCell } from '../../../../../HttpRequests/planner'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { period, subcontractor } = modalProps()

  const { jobs, plannerCell, duplicate } = getState().screens.Planner.SubcontractorCellModal

  const { upToDate, incWeekends } = duplicate

  const data = {
    subcontractor_id: subcontractor.id,
    date: period.date,
    jobs: jobs.filter(job => !!job.project_id),
    planner_cell: plannerCell,
    up_to_date: upToDate ? upToDate.format('YYYY-MM-DD') : undefined,
    inc_weekends: incWeekends,
  }

  if (!plannerCell.absence && !plannerCell.alert) {
    data.planner_cell = null
  }

  try {

    await updatePlannerSubcontractorCell({ data })

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

    throw error

  }

}
