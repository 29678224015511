import immutable from 'immutable';
import { message } from 'antd'
import { loadOrder } from '../State/order';
import { updateOrder } from '../../../HttpRequests/orders';
import handleException from '../../../Core/Helpers/handleException';

export default (field, value) => async (dispatch, getState) => {

  try {

    // update local state

    let order = immutable.fromJS(getState().screens.OrderView.order);

    order = order.set(field, value);

    order = order.toJS();

    dispatch(loadOrder(order));

    // update on server

    const { id } = order;

    const data = {
      [field]: value || null
    };

    await updateOrder({ id, data });

    message.success('Order Updated.');

  } catch (error) {

    handleException(error);

  }
}