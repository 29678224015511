export default (values) => {

  const errors = {};

  if (!values.name) errors.name = true;
  if (!values.billing_type) errors.billing_type = true;
  if (!values.client_risk_level_key) errors.client_risk_level_key = true;

  return errors;
  
}