import { initialize } from 'redux-form';
import moment from 'moment';
import { getUserIdForInput } from 'Common';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { order, estimate, projectId, isCreatingFromEstimate } = getProps();

  if (order) {

    dispatch(initialize(FORM_NAME, order))

  } else if (isCreatingFromEstimate) {

    dispatch(initialize(FORM_NAME, {
      project_id: projectId || undefined,
      estimate_id: estimate.id || undefined,
      user_id: getUserIdForInput(),
      date: moment().format('YYYY-MM-DD'),
      discount_percentage: estimate.discount_percentage,
      uplift_percentage: estimate.uplift_percentage,
      vat_percentage: estimate.vat_percentage,
      retention_percentage: 0
    }));

  } else {

    dispatch(initialize(FORM_NAME, {
      project_id: projectId || undefined,
      user_id: getUserIdForInput(),
      date: moment().format('YYYY-MM-DD'),
      terms_and_conditions_type: 1,
      discount_percentage: 0,
      uplift_percentage: 0,
      vat_percentage: '20',
      retention_percentage: 0
    }))

  }

}