import { connect } from 'react-redux'
import SubOrder from './SubOrder'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(SubOrder)
