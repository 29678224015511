// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';

export type RemoveEstimateGroupParams = {
  estimateGroupIndex: number
}

export default (params: RemoveEstimateGroupParams) => (dispatch: Function, getState: Function) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);
  
  estimate = estimate.removeIn(['estimate_groups', params.estimateGroupIndex]);

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}