exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3cEfMbhgDEyW1vYjHOE7Jg {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n._3e3s5ctWtMTcYS_AGrlpra {\n  flex: 1;\n  display: flex;\n}\n\n._2fpwGhYYlPyF4NyqbLOMsy {\n  display: flex;\n}\n\n._2PF4hoZ5bEnQE3CGERJaFR {\n  width: 280px;\n}\n\n._2ALd1js4wOdceK8fQ89EGo {\n  margin-bottom: 12px;\n}\n\n._2ALd1js4wOdceK8fQ89EGo:last-child {\n  margin-bottom: 4px;\n}\n\n._1u0Dmz-ZVFW78E8Vp1_t1z {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n\n._2XivbslpCsYVZehtpiszHE {\n  min-width: 80px;\n  text-align: center;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/SubProjects/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;CACf;;AAED;EACE,cAAc;CACf;;AAED;EACE,aAAa;CACd;;AAED;EACE,oBAAoB;CACrB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;CAClB;;AAED;EACE,gBAAgB;EAChB,mBAAmB;CACpB","file":"styles.css","sourcesContent":[".controls {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n.left {\n  flex: 1;\n  display: flex;\n}\n\n.right {\n  display: flex;\n}\n\n.filters {\n  width: 280px;\n}\n\n.box {\n  margin-bottom: 12px;\n}\n\n.box:last-child {\n  margin-bottom: 4px;\n}\n\n.label {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n\n.radioButton {\n  min-width: 80px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"controls": "_3cEfMbhgDEyW1vYjHOE7Jg",
	"left": "_3e3s5ctWtMTcYS_AGrlpra",
	"right": "_2fpwGhYYlPyF4NyqbLOMsy",
	"filters": "_2PF4hoZ5bEnQE3CGERJaFR",
	"box": "_2ALd1js4wOdceK8fQ89EGo",
	"label": "_1u0Dmz-ZVFW78E8Vp1_t1z",
	"radioButton": "_2XivbslpCsYVZehtpiszHE"
};