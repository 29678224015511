import { initialize } from 'redux-form'
import modalProps from '../modalProps'
import { FORM_NAME } from '../Form'

export default () => (dispatch) => {
  
  const { timesheetAdjustment, initialValues } = modalProps()

  if (timesheetAdjustment) {

    const { hours_formatted: time } = timesheetAdjustment

    dispatch(initialize(FORM_NAME, {
      ...timesheetAdjustment,
      type: timesheetAdjustment.hours < 0 ? 'reduction' : 'addition',
      time: `${timesheetAdjustment.hours < 0 ? time.substring(1) : time}:00`,
    }))
    
  } else {

    dispatch(initialize(FORM_NAME, initialValues || {
      type: 'reduction',
    }))

  }

}
