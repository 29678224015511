import { message } from 'antd'
import modalProps from '../modalProps'
import { createSubInvoice, updateSubInvoice } from '../../../HttpRequests/subInvoices'
import handleException from '../../../Core/Helpers/handleException'

export default (values) => async () => {

  const { subInvoice } = modalProps()

  const data = {
    reference: values.reference,
    subcontractor_id: values.subcontractor_id,
    user_id: values.user_id,
    date: values.date,
    to_date: values.to_date,
  }

  let response

  try {

    if (subInvoice) {

      response = await updateSubInvoice({ id: subInvoice.id, data })

      message.success(`Updated "${response.data.reference}"`)

    } else {

      response = await createSubInvoice({ data })

      message.success(`Created "${response.data.reference}"`)

    }

    modalProps().onClose(response.data)

  } catch (error) {

    handleException(error)

  }

}
