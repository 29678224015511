import { createAction, handleActions } from 'redux-actions';

const loadServiceTypes = createAction("CORE/SERVICE_TYPEES/LOAD_SERVICE_TYPES");
const clearServiceTypes = createAction("CORE/SERVICE_TYPEES/CLEAR_SERVICE_TYPES");

const defaultState = []

const reducer = handleActions(
  {
    [loadServiceTypes]: (state, action) => action.payload,
    [clearServiceTypes]: () => []
  },
  defaultState
);

export { loadServiceTypes, clearServiceTypes, reducer };