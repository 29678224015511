import { setSortBy } from '../../../State/sortBy'
import fetch from '../../../Services/fetch'

export default (sortBy) => (dispatch) => {

  dispatch(setSortBy(sortBy))

  dispatch(fetch())

}
