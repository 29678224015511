import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'
import { FormatPainterOutlined } from '@ant-design/icons'

const CellRequired = ({ required, confirmedSlots, fontSize }) => (

  <Alert
    type={confirmedSlots === required ? 'success' : 'error'}
    icon={<FormatPainterOutlined />}
    banner
    message={`${confirmedSlots}/${required} Required`}
    style={{ width: '100%', fontSize }}
  />
  
)

CellRequired.propTypes = {
  required: PropTypes.number.isRequired,
  confirmedSlots: PropTypes.number.isRequired,
  fontSize: PropTypes.number,
}

CellRequired.defaultProps = {
  fontSize: 13,
}

export default CellRequired
