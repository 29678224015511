import { combineReducers } from 'redux'

import { reducer as subcontractor } from './State/subcontractor'
import { reducer as filters } from './State/filters'
import { reducer as activeTab } from './State/activeTab'

import Overview from './Components/Overview/reducer'
import SubProjects from './Components/SubProjects/reducer'
import SubOrders from './Components/SubOrders/reducer'
import SubValuations from './Components/SubValuations/reducer'
import SubInvoices from './Components/SubInvoices/reducer'

export default combineReducers({
  subcontractor,
  filters,
  activeTab,

  Overview,
  SubProjects,
  SubOrders,
  SubValuations,
  SubInvoices,
})
