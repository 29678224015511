import { connect } from 'react-redux'
import SubOrderPlaceholder from './SubOrderPlaceholder'

import onAddGroup from './Handlers/onAddGroup'

const mapStateToProps = (state) => ({
  editing: state.screens.SubOrder.editing,
})

const actionCreators = {
  onAddGroup,
}

export default connect(mapStateToProps, actionCreators)(SubOrderPlaceholder)
