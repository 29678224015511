import { createAction, handleActions } from 'redux-actions';

const loadCaveats = createAction("CORE/CAVEATS/LOAD_CAVEATS");
const clearCaveats = createAction("CORE/CAVEATS/CLEAR_CAVEATS");

const defaultState = []

const reducer = handleActions(
  {
    [loadCaveats]: (state, action) => action.payload,
    [clearCaveats]: () => []
  },
  defaultState
);

export { loadCaveats, clearCaveats, reducer };