import { createAction, handleActions } from 'redux-actions';

const loadClient = createAction("SCREENS/CLIENT_VIEW/LOAD_CLIENT");
const updateClient = createAction("SCREENS/CLIENT_VIEW/UPDATE_CLIENT");
const clearClient = createAction("SCREENS/CLIENT_VIEW/CLEAR_CLIENT");

const defaultState = null;

const reducer = handleActions(
  {
    [loadClient]: (state, action) => action.payload,
    [updateClient]: (state, action) => Object.assign({}, state, {...action.payload}),
    [clearClient]: () => null
  },
  defaultState
);

export { loadClient, updateClient, clearClient, reducer };