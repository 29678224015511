import { createAction, handleActions } from 'redux-actions';

const setSelectedCaveatId = createAction("SCREENS/CAVEATS/SET_SELECTED_CAVEAT_ID");

const defaultState = null;

const reducer = handleActions(
  {
    [setSelectedCaveatId]: (state, action) => action.payload
  },
  defaultState
);

export { setSelectedCaveatId, reducer };
