exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._35tjAnWK2iolAXaSrqBFnG:hover {\n  text-decoration: underline;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Planner/Components/SubcontractorName/styles.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;CAC5B","file":"styles.css","sourcesContent":[".name:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"name": "_35tjAnWK2iolAXaSrqBFnG"
};