import React from 'react'
import { Table, Tag } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import styles from './Table.css'

type Props = {
  users: [],
  fetching: boolean,
  pagination: {},
  onChangeTable: () => void,
  onClickRow: () => void,
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      users,
      fetching,
      pagination,
      onChangeTable,
      onClickRow,
    } = this.props

    const columns = [
      { 
        title: 'Name', 
        dataIndex: 'full_name',
        key: 'full_name',
        sorter: true,
        render: (text, record) => record.full_name,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        render: (text, record) => (
          <div>
            {record.type === 'Admin' && <Tag color='green'>Admin</Tag>}
            {record.type === 'Senior' && <Tag color='gold'>Senior</Tag>}
            {record.type === 'Office' && <Tag color='blue'>Office</Tag>}
            {record.type === 'Basic' && <Tag>Basic</Tag>}
            {record.type === 'Subcontractor' && <Tag color='red'>Subcontractor</Tag>}
          </div>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        render: (text, record) => record.email,
      },
      {
        title: 'Status',
        dataIndex: 'deactivated_at',
        key: 'deactivated_at',
        sorter: true,
        render: (text, record) => (
          <React.Fragment>
            {record.status === 'Active' && (
              <div style={{ color: 'green' }}>
                <CheckOutlined />
                {` Active`}
              </div>
            )}
            {record.status === 'Deactivated' && (
              <div style={{ color: '#CCCCCC' }}>
                <CloseOutlined />
                {` Deactivated`}
              </div>
            )}
          </React.Fragment>
        ),
      },
    ]

    return (

      <Table
        columns={columns}
        dataSource={users}
        rowKey='id'
        onChange={onChangeTable}
        bordered
        loading={fetching}
        className={styles.table}
        onRow={(record) => ({
          onClick: () => onClickRow(record.id),
        })}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          current: pagination.pageNumber,
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
      />

    )

  }

}
