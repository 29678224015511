import { combineReducers } from 'redux'

import { reducer as invoice } from './State/invoice'
import { reducer as fetching } from './State/fetching'
import { reducer as showInvoiceModal } from './State/showInvoiceModal'
import { reducer as showEmailModal } from './State/showEmailModal'
import { reducer as projectEditorModal } from './State/projectEditorModal'

export default combineReducers({
  invoice,
  fetching,
  showInvoiceModal,
  showEmailModal,
  projectEditorModal,
})
