import { createAction, handleActions } from 'redux-actions'

const setOriginalItems = createAction('SCREENS/SUB_INVOICE/SET_ORIGINAL_ITEMS')
const resetOriginalItems = createAction('SCREENS/SUB_INVOICE/RESET_ORIGINAL_ITEMS')

const defaultState = []

const reducer = handleActions(
  {
    [setOriginalItems]: (state, { payload }) => payload,
    [resetOriginalItems]: () => defaultState,
  },
  defaultState,
)

export { setOriginalItems, resetOriginalItems, reducer }
