import { connect } from 'react-redux'
import SubcontractorTable from './SubcontractorTable'

import onClickSubcontractor from './Handlers/onClickSubcontractor'
import onClickCell from './Handlers/onClickCell'

const mapStateToProps = (state) => ({
  periods: state.screens.Planner.data.periods,
  rows: state.screens.Planner.data.rows,
  fetching: state.screens.Planner.fetching,
})

const actionCreators = {
  onClickSubcontractor,
  onClickCell,
}

export default connect(mapStateToProps, actionCreators)(SubcontractorTable)
