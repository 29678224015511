import { createAction, handleActions } from 'redux-actions';

const setFilters = createAction("SCREENS/ESTIMATES/SET_FILTERS");

const defaultState = {
  smart_status: 'all',
  extra_works_status: 'all',
  assigned: undefined,
  user_id: undefined,
  client_id: [],
  status_key: [],
  latest_action_key: [],
  decision_maker_id: undefined,
  date_between: undefined,
  latest_only: false
};

const reducer = handleActions(
  {
    [setFilters]: (state, action) => Object.assign({}, state, action.payload)
  },
  defaultState
);

export { setFilters, reducer };