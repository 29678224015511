import { connect } from 'react-redux'
import SubOrderItemNotes from './SubOrderItemNotes'

import onUpdateField from '../../Handlers/onUpdateField'

const mapStateToProps = (state) => ({
  editing: state.screens.SubOrder.editing,
})

const actionCreators = {
  onUpdateField,
}

export default connect(mapStateToProps, actionCreators)(SubOrderItemNotes)
