import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchProjects from '../../../Core/Services/fetchProjects';
import fetchProject from '../Services/fetchProject';

export default (props: any) => async (dispatch: Function) => {

  setProps(props);
  
  dispatch(initialize());

  if (!props.valuation) {
    await dispatch(fetchProjects());
  }

  if (props.projectId || props.valuation) {
    await dispatch(fetchProject());
  }

  dispatch(initialize());
  
}