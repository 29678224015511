import { message } from 'antd';
import { last } from 'lodash';
import { renameFile } from '../../../HttpRequests/files';
import { getProps } from '../props';


export default (values) => async () => {

  const { currentDirectoryPath, selectedItemPath } = getProps();

  const splitPath = selectedItemPath.split('/');
    
  const filename = splitPath[splitPath.length - 1];
  const extention= last(filename.split('.'));

  const data = {
    current_path: `${currentDirectoryPath}/${values.from}`,
    new_path: `${currentDirectoryPath}/${values.filename}.${extention}`
  }

  const file = await renameFile({ data });

  message.success("File Renamed");
  
  return file;

 
}