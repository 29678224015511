import { setShowUploadModal } from '../State/showUploadModal';

export default () => async (dispatch, getState) => {
    
  const { currentDirectoryPath } = getState().screens.ProjectView

  if (currentDirectoryPath) {
    
    dispatch(setShowUploadModal(true));

  }
}