import { createAction, handleActions } from 'redux-actions';

const loadEstimateLatestActions = createAction("CORE/ESTIMATE_LATEST_ACTIONS/LOAD_ESTIMATE_LATEST_ACTIONS");
const clearEstimatesLatestActions = createAction("CORE/ESTIMATE_LATEST_ACTIONS/CLEAR_ESTIMATE_LATEST_ACTIONS");

const defaultState = []

const reducer = handleActions(
  {
    [loadEstimateLatestActions]: (state, action) => action.payload,
    [clearEstimatesLatestActions]: () => []
  },
  defaultState
);

export { loadEstimateLatestActions, clearEstimatesLatestActions, reducer };