// @flow

import * as React from 'react';
import { Breadcrumb, Divider } from 'antd';

export default () => (

  <React.Fragment>

    <Breadcrumb separator="»">

      <Breadcrumb.Item>
        Home
      </Breadcrumb.Item>

      <Breadcrumb.Item>
        Estimates
      </Breadcrumb.Item>

    </Breadcrumb>
      
    <Divider />

  </React.Fragment>

)