import React from 'react'

const style = {
  position: 'relative',
  bottom: 1,
  fontWeight: 500,
  backgroundColor: '#e6fffb',
  fontSize: 8,
  color: '#08979c',
  padding: 2,
  border: '1px solid #87e8de',
  borderRadius: 3,
  marginRight: 5,
}

export default () => <span style={style}>TR</span>
