import { openTimesheetsDrawer } from '../State/timesheetsDrawer'

export default (record) => async (dispatch, getState) => {

  const { setup, filters } = getState().screens.Insights

  const { model, field } = setup

  if (model === 'Timesheet' && field === 'subcontractor_id') {
    dispatch(openTimesheetsDrawer({
      title: record.field,
      filters: { ...filters, _subcontractorId: record.key },
    }))
  }

  if (model === 'Timesheet' && field === 'project_id') {
    dispatch(openTimesheetsDrawer({
      title: record.field.toString(),
      subtitle: `${record.h2} - ${record.h3}`,
      filters: { ...filters, _projectId: record.key },
    }))
  }
  
}
