
import { createAction, handleActions } from 'redux-actions';

const setCurrentEstimateItem = createAction("SCREENS/ESTIMATE_VIEW/SET_CURRENT_ESTIMATE_ITEM");

const defaultState = null;

const reducer = handleActions(
  {
    [setCurrentEstimateItem]: (state, action) => action.payload
  },
  defaultState
);

export { setCurrentEstimateItem, reducer };