import fetchProjectStatuses from '../../../Core/Services/fetchProjectStatuses'
import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors'
import fetchProjects from '../../../Core/Services/fetchProjects'
import fetchUsers from '../../../Core/Services/fetchUsers'

export default () => (dispatch) => {

  dispatch(fetchProjectStatuses())
  dispatch(fetchSubcontractors())
  dispatch(fetchProjects())
  dispatch(fetchUsers())

}
