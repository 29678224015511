import { merge } from 'lodash';
import baseChartConfig from './baseChartConfig';

export default merge({}, baseChartConfig, {

  chart: {
    type: 'pie',
  },

  tooltip: {
    pointFormat: 'Total: <b>£{point.y:,.0f}</b>'
  },

  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false
      },
      showInLegend: true
    }
  },

  legend: {
    itemMarginTop: 3,
    itemMarginBottom: 3
  }

});
