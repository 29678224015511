import { getTeamUrl } from 'Common';

export default (options) => async (dispatch, getState) => {

  const { searchTerm, filters, sorting } = getState().screens.Projects;

  const params = {};

  if (sorting.columnKey) {
    params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
  }
  
  if (searchTerm) {
    params.search = searchTerm;
  }

  if (filters.status_key !== 'all') {
    params['filter[status_key]'] = filters.status_key;
  }

  if (filters.phase_key.length > 0) {
    params['filter[phase_key]'] = filters.phase_key.join(',');
  }

  if (filters.client_id.length > 0) {
    params['filter[client_id]'] = filters.client_id.join(',');
  }

  if (filters.contracts_manager.length > 0) {
    params['filter[contracts_manager]'] = filters.contracts_manager.join(',');
  }

  if (filters.supervisor_id.length > 0) {
    params['filter[supervisor_id]'] = filters.supervisor_id.join(',');
  }

  if (filters.order_ack_sent !== undefined) {
    params['filter[order_ack_sent]'] = filters.order_ack_sent
  }

  if (filters.project_info_sent !== undefined) {
    params['filter[project_info_sent]'] = filters.project_info_sent
  }

  if (filters.no_valuation_this_month) {
    params['filter[no_valuation_this_month]'] = 1
  }

  let url

  switch (options.key) {
    case 'projects-excel':
      url = `projects/export?type=excel`
      break
    case 'projects-csv':
      url = `projects/export?type=csv`
      break
    case 'valuation-dates-excel':
      url = `valuation_dates/export?type=excel`
      break
    case 'valuation-dates-csv':
      url = `valuation_dates/export?type=csv`
      break
    default:
      //
      break
  }

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.location = getTeamUrl(url, 'exports');

}