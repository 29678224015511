// @flow
import React from 'react'
import { Divider } from 'antd'
import Screen from '../../Components/Screen'
import TimesheetsDrawer from '../../Modules/TimesheetsDrawer'

import Breadcrumbs from './Components/Breadcrumbs'
import Header from './Components/Header'
import Filters from './Components/Filters'
import ProjectsTable from './Components/ProjectsTable'
import EstimatesTable from './Components/EstimatesTable'
import TimesheetsTable from './Components/TimesheetsTable'

type Props = {
  projectStatuses: [],
  projectPhases: [],
  clients: [],
  users: [],
  subcontractors: [],
  projects: [],
  setup: Object,
  filters: Object,
  searchTerm: String,
  sortBy: String,
  data: [],
  fetching: Boolean,
  isFiltered: Boolean,
  isClickableRow: Boolean,
  timesheetsDrawer: Object,
  componentDidMount: () => Function,
  onChangeSetup: () => Function,
  onChangeFilter: () => Function,
  onChangeSearch: () => Function,
  onSubmitSearch: () => Function,
  onChangeSort: () => Function,
  onClickExport: () => Function,
  onClickRow: () => Function,
  onCloseTimesheetsDrawer: () => Function,
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount()

  render() {

    const {
      projectStatuses,
      projectPhases,
      clients,
      users,
      subcontractors,
      projects,
      setup,
      filters,
      searchTerm,
      sortBy,
      data,
      fetching,
      isFiltered,
      isClickableRow,
      timesheetsDrawer,
      onChangeSetup,
      onChangeFilter,
      onChangeSearch,
      onSubmitSearch,
      onChangeSort,
      onClickExport,
      onClickRow,
      onCloseTimesheetsDrawer,
    } = this.props

    return (

      <Screen>

        <Breadcrumbs />

        <Divider />

        <Header
          setup={setup}
          searchTerm={searchTerm}
          data={data}
          onChangeSearch={onChangeSearch}
          onSubmitSearch={onSubmitSearch}
          onClickExport={onClickExport}
        />

        <Filters
          projectStatuses={projectStatuses}
          projectPhases={projectPhases}
          clients={clients}
          users={users}
          subcontractors={subcontractors}
          projects={projects}
          setup={setup}
          filters={filters}
          isFiltered={isFiltered}
          onChangeSetup={onChangeSetup}
          onChangeFilter={onChangeFilter}
        />

        {setup.model === 'Project' && (

          <ProjectsTable
            setup={setup}
            sortBy={sortBy}
            data={data}
            fetching={fetching}
            onChangeSort={onChangeSort}
          />

        )}

        {setup.model === 'Estimate' && (

          <EstimatesTable
            setup={setup}
            sortBy={sortBy}
            data={data}
            fetching={fetching}
            onChangeSort={onChangeSort}
          />

        )}

        {setup.model === 'Timesheet' && (

          <TimesheetsTable
            setup={setup}
            sortBy={sortBy}
            data={data}
            fetching={fetching}
            isClickableRow={isClickableRow}
            hoursType={filters.hoursType}
            hoursFormat={filters.hoursFormat}
            onChangeSort={onChangeSort}
            onClickRow={onClickRow}
          />

        )}

        {timesheetsDrawer.show && (
          <TimesheetsDrawer
            {...timesheetsDrawer}
            onClose={onCloseTimesheetsDrawer}
          />
        )}

      </Screen>

    )

  }

}
