import { initialize } from 'redux-form'
import { getRamsRecord } from '../../../HttpRequests/ramsRecords'
import { FORM_NAME } from '../Form'
import { setFiles } from '../State/files'
import { setClientContacts } from '../State/clientContacts'
import _props from '../props'

export default () => async (dispatch) => {

  const { ramsRecordId, project } = _props.get()

  dispatch(setClientContacts(project.client_site.client.client_contacts))
  
  if (ramsRecordId) {

    const params = {
      append: 'media_with_url',
    }

    const response = await getRamsRecord({ id: ramsRecordId, params })

    const ramsRecord = response.data.data

    dispatch(initialize(FORM_NAME, ramsRecord))

    dispatch(setFiles(ramsRecord.media.map(file => ({
      uid: file.id,
      name: file.name,
      url: file.url,
      status: 'done',
      exists: true,
    }))))
    
  } else {

    dispatch(initialize(FORM_NAME, {
      services: [],
      access_equipment: [],
      paints: [],
    }))

  }

}