import { createAction, handleActions } from 'redux-actions';
import { assign } from 'lodash';

const loadData = createAction('SCREENS/DASHBOARD/TOP_CUSTOMERS_REPORT/LOAD_DATA');
const clearData = createAction('SCREENS/DASHBOARD/TOP_CUSTOMERS_REPORT/CLEAR_DATA');

const defaultState = {
  data: null
}

const reducer = handleActions(
  {
    [loadData]: (state, action) => assign({}, state, { data: action.payload }),
    [clearData]: () => null
  },
  defaultState
);

export { loadData, clearData, reducer };
