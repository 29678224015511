import { combineReducers } from 'redux'

import { reducer as subProjects } from './State/subProjects'
import { reducer as fetching } from './State/fetching'
import { reducer as filters } from './State/filters'

export default combineReducers({
  subProjects,
  fetching,
  filters,
})
