exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._4_RXVCU1_xpXExi9GG-qQ {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n._23Ue2RonJccepKWVWI0IWJ {\n  justify-content: flex-end;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Subbies/Components/SubProjects/Components/Projects/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,gBAAgB;CACjB;;AAED;EACE,0BAA0B;CAC3B","file":"styles.css","sourcesContent":[".column {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.numeric {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"column": "_4_RXVCU1_xpXExi9GG-qQ",
	"numeric": "_23Ue2RonJccepKWVWI0IWJ"
};