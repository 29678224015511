import create from '../Services/create';
import update from '../Services/update';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { onClose, didCreate, didUpdate } = getProps();
    
  if (!values.id) {

    const subcontractor = await dispatch(create(values));
    
    onClose(true);

    if (subcontractor && didCreate) didCreate(subcontractor);

  }  else {
    
    const subcontractor = await dispatch(update(values));
    
    onClose(true);
    
    if (subcontractor && didUpdate) didUpdate(subcontractor)

  }
  
}