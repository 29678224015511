import { message } from 'antd'
import { updateOrder } from '../../../HttpRequests/orders';

export default (values) => async () => {

  const { id } = values;
    
  const data = { ...values };

  const response = await updateOrder({ id, data });

  const order = response.data.data;

  message.success("Order Updated");

  return order;

}