import { fromJS } from 'immutable';
import updateValuationItemPercentage from './updateValuationItemPercentage';
import sanitiseValuationItemPercentage from './sanitiseValuationItemPercentage';

export default (percentage) => (dispatch, getState) => {

  const valuation = fromJS(getState().screens.ValuationView.valuation);

  const { selectedOrderGroupKeys } = getState().screens.ValuationView;

  valuation.get('orders').forEach((order, orderIndex) => {
    order.get('order_groups').forEach((orderGroup, orderGroupIndex) => {
      orderGroup.get('order_items').forEach((orderItem, orderItemIndex) => {

        if (selectedOrderGroupKeys) {
          if (orderIndex !== selectedOrderGroupKeys.orderIndex) return;
          if (orderGroupIndex !== selectedOrderGroupKeys.orderGroupIndex) return;
        }

        if (orderItem.toJS().type === null) return;
        if (orderItem.flag === 'Omit' || orderItem.flag === 'By Others') return;

        dispatch(updateValuationItemPercentage({
          orderIndex,
          orderGroupIndex,
          orderItemIndex,
          value: percentage
        }))

        dispatch(sanitiseValuationItemPercentage({
          orderIndex,
          orderGroupIndex,
          orderItemIndex,
        }))

      })
    })
  })

}