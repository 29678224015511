
import { createAction, handleActions } from 'redux-actions';

const setShowValuationModal = createAction("SCREENS/VALUATION_VIEW/SET_SHOW_VALUATION_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowValuationModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowValuationModal, reducer };