// @flow

import { clearEstimate } from '../State/estimate';
import { setEditable } from '../State/editable';
import { setSelectedEstimateItemIds } from '../State/selectedEstimateItemIds';

export default () => async (dispatch: Function) => {

  dispatch(clearEstimate())
  dispatch(setEditable(false))
  dispatch(setSelectedEstimateItemIds([]));

}