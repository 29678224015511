import { message } from 'antd'
import { createProject } from '../../../HttpRequests/projects';
import fetchProjects from '../../../Core/Services/fetchProjects';

export default (values) => async (dispatch) => {

  const data = { ...values }

  const response = await createProject({ data });

  const project = response.data.data;

  message.success('Project Created');

  await dispatch(fetchProjects({ force: true }));

  return project;

}