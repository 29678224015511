import { connect } from 'react-redux';
import Component from './Component';

import selectItemsListData from '../../Selectors/selectItemsListData';

import onClickValuationItemPercentageOutlet from './Handlers/onClickValuationItemPercentageOutlet';
import onClickValuationItemAmountOutlet from './Handlers/onClickValuationItemAmountOutlet';
import onChangeValuationItemPercentage from './Handlers/onChangeValuationItemPercentage';
import onChangeValuationItemAmount from './Handlers/onChangeValuationItemAmount';
import onBlurValuationItemPercentage from './Handlers/onBlurValuationItemPercentage';
import onBlurValuationItemAmount from './Handlers/onBlurValuationItemAmount';
import onTabValuationItem from './Handlers/onTabValuationItem';
import onClickSetGroupPercentage from './Handlers/onClickSetGroupPercentage';
import onClickDescription from './Handlers/onClickDescription';

const mapStateToProps = (state) => ({
  listData: selectItemsListData(state),
  serviceTypes: state.core.serviceTypes,
  editable: state.screens.ValuationView.editable,
  activeItemInputKeys: state.screens.ValuationView.activeItemInputKeys,
})

const actionCreators = {
  onClickValuationItemPercentageOutlet,
  onClickValuationItemAmountOutlet,
  onChangeValuationItemPercentage,
  onChangeValuationItemAmount,
  onBlurValuationItemPercentage,
  onBlurValuationItemAmount,
  onTabValuationItem,
  onClickSetGroupPercentage,
  onClickDescription
};

export default connect(mapStateToProps, actionCreators)(Component);