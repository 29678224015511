import React from "react"
import PropTypes from "prop-types"
import Screen from '../../Components/Screen'
import Header from "./Components/Header"
import Filters from "./Components/Filters"
import Table from "./Components/Table"
import Footer from "./Components/Footer"

export default class _Invoices_ extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount()

  render () {

    return (

      <Screen>

        <Header />

        <Filters />

        <Table />

        <Footer />

      </Screen>

    )
  }
}
