import { connect } from 'react-redux'
import Component from './Component'

import onChangeTable from './Handlers/onChangeTable'

const mapStateToProps = (state) => ({
  invoices: state.screens.Invoices.invoices,
  pagination: state.screens.Invoices.pagination,
  fetching: state.screens.Invoices.fetching,
})

const actionCreators = {
  onChangeTable,
}

export default connect(mapStateToProps, actionCreators)(Component)
