import { deleteMedia } from '../../../HttpRequests/media'
import fetchOrder from './fetchOrder'
import handleException from '../../../Core/Helpers/handleException'

export default (media) => async (dispatch) => {

  try {

    await deleteMedia({ id: media.id })

    dispatch(fetchOrder())

  } catch(error) {

    handleException(error)

  }

}
