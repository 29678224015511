/* eslint-disable no-underscore-dangle */
import moduleProps from '../moduleProps'
import { getTimesheets } from '../../../HttpRequests/timesheets'
import { setData } from '../State/data'
import { startFetching, finishFetching } from '../State/isFetching'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { filters } = moduleProps()

  const params = {
    include: [
      'subcontractor',
      'approved_by_user',
      'paid_by_user',
      'timesheet_postings.project.client_site.client',
      'timesheet_adjustments.user',
    ].join(','),
  }

  if (filters._subcontractorId) {
    params['filter[subcontractor_id]'] = filters._subcontractorId
  } else if (filters.subcontractorId.length > 0) {
    params['filter[subcontractor_id]'] = filters.subcontractorId.join()
  }

  if (filters._projectId) {
    params['filter[project_ids]'] = filters._projectId
  } else if (filters.projectId.length > 0) {
    params['filter[project_ids]'] = filters.projectId.join() // preserve project_id
  }

  if (filters.contractsManagerId.length > 0) {
    params['filter[contracts_manager_id]'] = filters.contractsManagerId.join()
  }

  if (filters.dateRange.length === 2) {
    params['filter[date_range]'] = filters.dateRange.map(date => date.format('YYYY-MM-DD')).join()
  }

  if (filters.hoursType === 'hourly-rate') {
    params['filter[hourly_rate]'] = 1
  }

  if (filters.hoursType === 'day-rate') {
    params['filter[day_rate]'] = 1
  }

  if (filters.hoursType === 'price-work') {
    params['filter[price_work]'] = 1
  }

  dispatch(startFetching())

  try {

    const response = await getTimesheets({ params })

    dispatch(setData(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
