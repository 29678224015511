// @flow

import { setShowEstimateModal } from '../../../State/showEstimateModal';
import { setShowOrderModal } from '../../../State/showOrderModal';
import reviseEstimate from '../../../Services/reviseEstimate';
import downloadPDF from '../../../Services/downloadPDF';
import downloadExcel from '../../../Services/downloadExcel';
import { setShowEmailModal } from '../../../State/showEmailModal';
import { setShowEstimateDuplicateModal } from '../../../State/showEstimateDuplicateModal';
import onDelete from './onDelete';

export default (key: string) => async (dispatch: Function) => {

  switch (key) {

    default:
      break;

    case 'SEND':
      dispatch(setShowEmailModal(true));
      break;
    
    case 'CLIENT_PDF':
      dispatch(downloadPDF({ version: 'client' }));
      break;
    
    case 'INTERNAL_PDF':
      dispatch(downloadPDF({ version: 'internal' }));
      break;
    
    case 'EXCEL':
      dispatch(downloadExcel());
      break;

    case 'EDIT':
      dispatch(setShowEstimateModal(true));
      break;
    
    case 'DUPLICATE':
      dispatch(setShowEstimateDuplicateModal(true));
      break;
    
    case 'REVISE':
      dispatch(reviseEstimate());
      break;
    
    case 'CREATE_ORDER':
      dispatch(setShowOrderModal(true));
      break;   
    
    case 'DELETE':
      dispatch(onDelete());
      break;  
  }

}