import React from 'react'
import { Spin } from 'antd'

const Initializing = () => (

  <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

    <Spin size='large' />

  </div>

)

export default Initializing
