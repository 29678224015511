import { connect } from 'react-redux'
import Header from './Header'

import onClickEdit from './Handlers/onClickEdit'
import onClickDelete from './Handlers/onClickDelete'
import onClickPdf from './Handlers/onClickPdf'
import onClickEmail from './Handlers/onClickEmail'

const mapStateToProps = (state) => ({
  subOrder: state.screens.SubOrder.subOrder,
})

const actionCreators = {
  onClickEdit,
  onClickDelete,
  onClickPdf,
  onClickEmail,
}

export default connect(mapStateToProps, actionCreators)(Header)
