// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';

export type RemoveEstimateCaveatParams = {
  estimateCaveatIndex: number
}

export default (params: RemoveEstimateCaveatParams) => (dispatch: Function, getState: Function) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);
  
  estimate = estimate.removeIn(['estimate_caveats', params.estimateCaveatIndex]);

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}