exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1f9MT6R6a-JSnmXj3ckMmP th, td {\n  padding: 4px 16px;\n}\n\n._1f9MT6R6a-JSnmXj3ckMmP th:first-child, td:first-child {\n  padding-left: 4px;\n}\n\n._1f9MT6R6a-JSnmXj3ckMmP th:last-child, td:last-child {\n  padding-right: 4px;\n}\n\n._2xTXdToHT6wJzF-9QuVlmV {\n  text-align: right !important;\n}\n\n._1bTQqJ5UWtaPbBpFbPyUhN:hover {\n  background-color: #f5f3f3;\n  cursor: pointer;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Modules/TimesheetAdjustmentsPopover/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;CACnB;;AAED;EACE,kBAAkB;CACnB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,6BAA6B;CAC9B;;AAED;EACE,0BAA0B;EAC1B,gBAAgB;CACjB","file":"styles.css","sourcesContent":[".table th, td {\n  padding: 4px 16px;\n}\n\n.table th:first-child, td:first-child {\n  padding-left: 4px;\n}\n\n.table th:last-child, td:last-child {\n  padding-right: 4px;\n}\n\n.num {\n  text-align: right !important;\n}\n\n.row:hover {\n  background-color: #f5f3f3;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"table": "_1f9MT6R6a-JSnmXj3ckMmP",
	"num": "_2xTXdToHT6wJzF-9QuVlmV",
	"row": "_1bTQqJ5UWtaPbBpFbPyUhN"
};