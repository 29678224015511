import { createAction, handleActions } from 'redux-actions'

const setFiles = createAction('MODALS/RAMS_RECORD/SET_FILES')
const pushFile = createAction('MODALS/RAMS_RECORD/PUSH_FILE')
const resetFiles = createAction('MODALS/RAMS_RECORD/RESET_FILES')

const defaultState = []

const reducer = handleActions(
  {
    [setFiles]: (state, action) => [ ...action.payload ],
    [pushFile]: (state, action) => [ ...state, action.payload ],
    [resetFiles]: () => [],
  },
  defaultState
)

export { setFiles, pushFile, resetFiles, reducer }