import { setFileList } from '../State/fileList';
import { resetAllFiles } from '../State/allFiles';
import { resetSelectedPaths } from '../State/selectedPaths';

export default () => async (dispatch) => {

  dispatch(setFileList([]));
  dispatch(resetAllFiles([]));
  dispatch(resetSelectedPaths([]));
  
}