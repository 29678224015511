import { Modal } from 'antd';
import { capitalize } from 'lodash';
import deleteFiles from '../Services/deleteFiles';

const { confirm } = Modal;

export default () => async (dispatch, getState) => {

  const { selectedItemType, checkedItemPaths } = getState().screens.ProjectView;

  const title = `Are you sure you want to delete ${checkedItemPaths.length > 0 ? `these ${checkedItemPaths.length} items` : `this ${capitalize(selectedItemType)}`}?`;

  confirm({
    title,
    content: 'Note: This action cannot be undone',
    okText: 'Yes, Delete',
    async onOk() {

      await dispatch(deleteFiles()); 
      
    }
  });

}