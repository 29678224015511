import { connect } from 'react-redux'
import TimesheetsDrawer from './TimesheetsDrawer'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseTimesheetModal from './Handlers/onCloseTimesheetModal'
import onCloseTimesheetAdjustmentModal from './Handlers/onCloseTimesheetAdjustmentModal'

const mapStateToProps = (state) => ({
  timesheetModal: state.modules.TimesheetsDrawer.timesheetModal,
  timesheetAdjustmentModal: state.modules.TimesheetsDrawer.timesheetAdjustmentModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseTimesheetModal,
  onCloseTimesheetAdjustmentModal,
}

export default connect(mapStateToProps, actionCreators)(TimesheetsDrawer)
