// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Table, Avatar, Popover, Tooltip, Tag } from 'antd';
import { FormatPainterOutlined, CarOutlined, InfoCircleFilled, SafetyCertificateOutlined } from '@ant-design/icons';
import { BiSprayCan } from 'react-icons/bi';
import numeral from 'numeral';
import moment from 'moment';
import { getTeamUrl } from 'Common';
import { expiries, getExpiryColor } from '../../../../../Config/expiries';

type Props = {
  subcontractors: [],
  pagination: {},
  fetching: boolean,

  onChangeTable: () => void
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      subcontractors,
      pagination,
      fetching,

      onChangeTable
    } = this.props;

    const columns = [

      { 
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: (text, record) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {record.type === 'Sprayer' ? (
              <BiSprayCan style={{ marginRight: 5 }} />
            ) : (
              <FormatPainterOutlined style={{ marginRight: 5 }} />
            )}
            <NavLink to={getTeamUrl(`subcontractors/${record.id}/view`)} style={{ marginRight: 5 }}>
              {record.name}
            </NavLink>
            {!!record.is_ltd_company && <Tag>Ltd</Tag>}
            {!!record.is_supervisor && <Tag color='volcano'>Supervisor</Tag>}
            {!!record.is_trainee && <Tag color='cyan'>Trainee</Tag>}
            {!!record.drives && <CarOutlined style={{ marginRight: 5 }} />}
            {!!record.has_right_to_work && (
              <Tooltip title={record.right_to_work_proof}>
                <SafetyCertificateOutlined style={{ marginRight: 5 }} />
              </Tooltip>
            )}
            {!!record.notes && (
              <Popover
                content={record.notes}
              >
                <InfoCircleFilled style={{ color: 'orange' }} />
              </Popover>
            )}
          </div>
        )
      },
      
      {
        title: 'Telephone',
        dataIndex: 'telephone',
      },
      
      {
        title: 'Rates',
        dataIndex: 'rates',
        key: 'rates',
        width: 120,
        render: (text, record) => (
          <table>
            <tbody>
              {!!record.hourly_rate && (
                <tr>
                  <td style={{ color: '#999' }}>Hour:</td>
                  <td style={{ textAlign: 'right' }}>{`£${numeral(record.hourly_rate).format('0,0.00')}`}</td>
                </tr>
              )}
              {!!record.day_rate && (
                <tr>
                  <td style={{ color: '#999' }}>Day:</td>
                  <td style={{ textAlign: 'right' }}>{`£${numeral(record.day_rate).format('0,0.00') }`}</td>
                </tr>
              )}
            </tbody>
          </table>
        )
      },

      {
        title: 'Card Type',
        dataIndex: 'card_type',
        width: 150,
        render: (text, record) => (
          <Tag
            color={record.card_type !== 'White' && record.card_type}
          >
            {record.card_type}
          </Tag>
        )
      },
      
      {
        title: 'Expiries',
        dataIndex: 'expiries',
        width: 470,
        render: (text, record) => Object.keys(expiries).map(key => (
          <Tooltip
            key={key}
            title={(
              <div>
                <div>{expiries[key].title}</div>
                {!!record[key] && `${moment(record[key]).format('DD/MM/YYYY')} - ${moment(record[key]).fromNow()}`}
              </div>
            )}
          >
            <Avatar
              style={{
                backgroundColor: getExpiryColor(record[key]),
                marginLeft: 2,
                marginRight: 2,
              }}
            >
              {expiries[key].abbrev}
            </Avatar>
          </Tooltip>
        ))
      },
      
      {
        title: 'Status',
        dataIndex: 'deactivated_at',
        key: 'deactivated_at',
        width: 200,
        sorter: true,
        onCell: record => ({
          style: { color: !!record.deactivated_at && '#999' },
        }),
        render: (text, record) => record.deactivated_at ? `Deactivated ${moment(record.deactivated_at).fromNow()}` : `Active for ${moment(record.created_at).fromNow(true)}`
      },

    ];

    return (
      <Table 
        dataSource={subcontractors}
        columns={columns}
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination
        }}
        bordered
      />
    )

  };

}