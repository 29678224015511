import { getTeamUrl } from 'Common'

export default ({ key: type }) => (dispatch, getState) => {

  const state = getState()

  const { searchTerm, filters, sorting } = state.screens.Subbies.Subcontractors

  const params = {}

  if (searchTerm) {
    params.search = searchTerm;
  }

  if (filters.status !== 'All') {
    params['filter[status]'] = filters.status;
  }

  if (filters.expiry) {
    if (filters.document) {
      params['filter[expiry]'] = `${filters.expiry},${filters.document}`;
    } else {
      params['filter[expiry]'] = filters.expiry;
    }
  }

  if (filters.hasRightToWork !== null) {
    params['filter[has_right_to_work]'] = filters.hasRightToWork;
  }

  if (sorting.columnKey) {
    params.sort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
  } else {
    params.sort = 'name';
  }

  let url = `subcontractors/export?type=${type}`

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.location = getTeamUrl(url, 'exports')

}
