import moment from 'moment'

export const expiries = {
  card_expiry: {
    title: 'CSCS Card',
    abbrev: 'CSCS',
  },
  insurance_expiry: {
    title: 'Liability Insurance',
    abbrev: 'LI',
  },
  asbestos_awareness_expiry: {
    title: 'Asbestos Awareness',
    abbrev: 'AA',
  },
  manual_handling_expiry: {
    title: 'Manual Handling',
    abbrev: 'MH',
  },
  working_at_height_expiry: {
    title: 'Working at Height',
    abbrev: 'WAH',
  },
  face_fit_mask_testing_expiry: {
    title: 'Face Fit Mask Testing',
    abbrev: 'FFMT',
  },
  smsts_expiry: {
    title: 'SMSTS',
    abbrev: 'SMST',
  },
  sssts_expiry: {
    title: 'SSSTS',
    abbrev: 'SSSTS',
  },
  pasma_expiry: {
    title: 'PASMA',
    abbrev: 'PASMA',
  },
  ipaf_expiry: {
    title: 'IPAF',
    abbrev: 'IPAF',
  },
  first_aid_at_work_expiry: {
    title: 'EFAAW',
    abbrev: 'EFAAW',
  },
  ladder_expiry: {
    title: 'Ladder / Step Ladder',
    abbrev: 'LE',
  },
}

const now = moment();

export const getExpiryColor = (expiry) => {
  if (!expiry) return null
  const diffInDays = now.diff(expiry, 'days')
  if (diffInDays >= 0) return 'red'
  if (diffInDays < 0 && diffInDays >= -60) return 'orange'
  if (diffInDays < -60) return 'green'
  return false
}
