export default (values) => {

  const errors = {}

  if (!values.password) errors.password = true

  if (!values.password_confirm) errors.password_confirm = true

  if (values.password_confirm !== values.password) errors.password_confirm = true

  return errors

}
