import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';

export default (caveats) => (dispatch, getState) => {
  
  let estimate = fromJS(getState().screens.EstimateView.estimate);

  caveats.forEach(caveat => {

    const estimateCaveat = {
      id: null,
      estimate_id: estimate.get('id'),
      content: caveat.content,
      line_number: null,
      order_number: null
    }

    estimate = estimate.updateIn(
      ['estimate_caveats'],
      (list) => list.push(estimateCaveat)
    );

  })

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}