exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".XMW2ZiQmE1gwKtMJIMgxz {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 65px;\n  margin-top: 10px;\n  border-top: 1px solid #DDD;\n  padding-top: 20px;\n  display: flex;\n  flex-direction: row;\n}\n\n._27w99C3tE4rvCr-qIBViz3 {\n  flex: 1;\n}\n\n._3CGbKkMBV8WHAHuUeH5IU5 {\n  cursor: pointer;\n  margin-right: 30px;\n  color: #1990FF;\n  font-weight: 500;\n  font-size: 14px;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n\n._1R2fPZ_aPLSElv8bFkn_MP {\n  color: #1990FF;\n  opacity: 0.9;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/EstimateView/Components/Items/Components/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;EACjB,2BAA2B;EAC3B,kBAAkB;EAClB,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;CACT;;AAED;EACE,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,0BAA0B;KACvB,uBAAuB;MACtB,sBAAsB;UAClB,kBAAkB;CAC3B;;AAED;EACE,eAAe;EACf,aAAa;CACd","file":"Footer.css","sourcesContent":[".wrapper {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 65px;\n  margin-top: 10px;\n  border-top: 1px solid #DDD;\n  padding-top: 20px;\n  display: flex;\n  flex-direction: row;\n}\n\n.buttons {\n  flex: 1;\n}\n\n.link {\n  cursor: pointer;\n  margin-right: 30px;\n  color: #1990FF;\n  font-weight: 500;\n  font-size: 14px;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n\n.addLink {\n  color: #1990FF;\n  opacity: 0.9;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "XMW2ZiQmE1gwKtMJIMgxz",
	"buttons": "_27w99C3tE4rvCr-qIBViz3",
	"link": "_3CGbKkMBV8WHAHuUeH5IU5",
	"addLink": "_1R2fPZ_aPLSElv8bFkn_MP"
};