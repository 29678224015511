import { merge } from 'lodash';
import baseChartConfig from './baseChartConfig';

export default merge({}, baseChartConfig, {

  chart: {
    type: 'column',
  },

  xAxis: {
    type: 'category',
    labels: {
      rotation: -60,
      style: {
        fontSize: '10px',
        fontFamily: 'Verdana, sans-serif'
      }
    }
  },

  yAxis: {
    min: 0,
    title: {
      text: 'Value (£)'
    }
  },

  tooltip: {
    pointFormat: '<b>{series.name}</b><br />Total: <b>£{point.y:,.0f}</b>'
  },

  legend: {
    enabled: false
  }

});
