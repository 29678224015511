import { connect } from 'react-redux'
import { isSubmitting } from 'redux-form'

import { FORM_NAME } from '../../Form'

import onClickCancel from './Handlers/onClickCancel'
import onClickSubmit from './Handlers/onClickSubmit'

import Component from './Component'

const mapStateToProps = (state) => ({
  submitting: isSubmitting(FORM_NAME)(state),
})

const actionCreators = {
  onClickCancel,
  onClickSubmit,
}

export default connect(mapStateToProps, actionCreators)(Component)