// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Table, Tooltip } from 'antd';
import { find } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import classNames from 'classnames';
import { getTeamUrl } from 'Common';
import styles from './Table.css';

const billingTypeColors = {
  'Application': '#AA63E3',
  'Client Valuation': '#339933',
  'Invoice': '#FCBD03',
  'Unknown': '#999',
}

type Props = {
  projects: [],
  projectPhases: [],
  pagination: {},
  fetching: boolean,
  
  onChangeTable: Function
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      projects,
      projectPhases,
      pagination,
      fetching,

      onChangeTable
    } = this.props;

    const columns = [

      { 
        title: 'Project',
        key: 'id',
        width: 100,
        sorter: true,
        fixed: 'left',
        render: (text, record) => (
          <React.Fragment>
            <NavLink to={getTeamUrl(`projects/${record.id}/view`)}>
              <div style={{ fontSize: 16 }}>{record.id}</div>
            </NavLink>
            {!!record.uplift_percentage && (
              <Tooltip title={record.uplift_notes}>
                <div style={{ color: 'red' }}>{`⇑ ${record.uplift_percentage}%`}</div>
              </Tooltip>
            )}
          </React.Fragment>
        ),
      },
      
      {
        title: 'Client / Site',
        key: 'client.name',
        width: 252,
        sorter: true,
        fixed: 'left',
        render: (text, record) => (
          <div>
            <div>
              <strong>
                { record.client_name }
              </strong>
            </div>
            <div>
              { record.client_site_name }
            </div>
            <span
              style={{
                backgroundColor: billingTypeColors[record.billing_type],
                padding: 5,
                borderRadius: 5,
                color: '#FFF',
                fontSize: 12,
                fontWeight: '500',
              }}
            >
              {record.billing_type}
            </span>
          </div>
        ),
      },

      {
        title: 'Contracts Managers',
        key: 'contracts_managers',
        width: 200,
        render: (text, record) => (
          <div>
            {record.contracts_manager ? <div>{`${record.contracts_manager.first_name} ${record.contracts_manager.last_name}`}</div> : ''}
            {record.other_contracts_manager ? <div>{`${record.other_contracts_manager.first_name} ${record.other_contracts_manager.last_name}`}</div> : ''}
          </div>
        ),
      },
      
      {
        title: 'Values',
        key: 'value_total',
        width: 240,
        sorter: true,
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div>
              Value:
              <strong>
                {` ${record.value_total < 0 ? '-' : ''}£${numeral(record.value_total * (record.value_total < 0 ? -1 : 1)).format('0,0.00')}`}
              </strong>
            </div>
            <div>&nbsp;</div>
            <div>
              Completed:
              <strong>
                {` ${record.value_completed < 0 ? '-' : ''}£${numeral(record.value_completed * (record.value_completed < 0 ? -1 : 1)).format('0,0.00')}`}
              </strong>
              {` (${record.value_total != 0 ? numeral((record.value_completed / record.value_total) * 100).format('0,0') : 0}%)`}
            </div>
            <div>
              Remaining:
              <strong>
                {` ${record.value_remaining < 0 ? '-' : ''}£${numeral(record.value_remaining * (record.value_remaining < 0 ? -1 : 1)).format('0,0.00')}`}
              </strong>
              {` (${record.value_total != 0 ? numeral((record.value_remaining / record.value_total) * 100).format('0,0') : 0}%)`}
            </div>
            <div
              className={classNames({
                [styles.positive]: Number(record.income) >= Number(record.value_completed),
                [styles.negative]: Number(record.income) < Number(record.value_completed),
              })}
            >
              Income:
              <strong>
                {` ${record.income < 0 ? '-' : ''}£${numeral(record.income * (record.income < 0 ? -1 : 1)).format('0,0.00')}`}
              </strong>
              {` (${record.value_completed != 0 ? numeral((record.income / record.value_completed) * 100).format('0,0') : 0}%)`}
            </div>
          </div>
        )
      },
      
      {
        title: 'Sales',
        key: 'sales_total',
        width: 240,
        sorter: true,
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div>
              Total:
              <strong>
                {` ${record.sales_total < 0 ? '-' : ''}£${numeral(record.sales_total * (record.sales_total < 0 ? -1 : 1)).format('0,0.00')}`}
              </strong>
            </div>
            <div>&nbsp;</div>
            <div>
              Labour:
              <strong>
                {` ${record.sales_labour < 0 ? '-' : ''}£${numeral(record.sales_labour * (record.sales_labour < 0 ? -1 : 1)).format('0,0.00')}`}
              </strong>
              {` (${record.sales_total != 0 ? numeral((record.sales_labour / record.sales_total) * 100).format('0,0') : 0}%)`}
            </div>
            <div>
              Materials:
              <strong>
                {` ${record.sales_materials < 0 ? '-' : ''}£${numeral(record.sales_materials * (record.sales_materials < 0 ? -1 : 1)).format('0,0.00')}`}
              </strong>
              {` (${record.sales_total != 0 ? numeral((record.sales_materials / record.sales_total) * 100).format('0,0') : 0}%)`}
            </div>
            <div>
              Equipment:
              <strong>
                {` ${record.sales_equipment < 0 ? '-' : ''}£${numeral(record.sales_equipment * (record.sales_equipment < 0 ? -1 : 1)).format('0,0.00')}`}
              </strong>
              {` (${record.sales_total != 0 ? numeral((record.sales_equipment / record.sales_total) * 100).format('0,0') : 0}%)`}
            </div>
          </div>
        )
      },

      {
        title: 'P&L',
        key: 'profit_and_loss',
        width: 180,
        sorter: true,
        className: styles.numericColumn,
        render: (text, record) => (
          <div
            className={classNames({
              [styles.positive]: Number(record.profit_and_loss) > 0,
              [styles.negative]: Number(record.profit_and_loss) < 0,
            })}
          >
            <div style={{ fontSize: 17 }}>
              {` ${record.profit_and_loss < 0 ? '-' : ''}£${numeral(record.profit_and_loss * (record.profit_and_loss < 0 ? -1 : 1)).format('0,0.00')}`}
            </div>
            <div>
              {` (${record.value_completed != 0 ? numeral((record.profit_and_loss / record.value_completed) * 100).format('0,0.[00]') : 0}%)`}
            </div>
          </div>
        )
      },

      {
        title: 'Details',
        key: 'details',
        width: 280,
        render: (text, record) => {
          const now = moment()
          const lastValuationDate = record.last_valuation_date ? moment(record.last_valuation_date) : null
          const projectPhase = find(projectPhases, row => row.key === record.phase_key)
          const completedAt = record.completed_at ? moment(record.completed_at) : null
          return (
            <div>
              <div>
                Last Valuation:
                {` `}
                <strong>
                  {!!lastValuationDate && (now.diff(lastValuationDate, 'days') < 90 ? `${now.diff(lastValuationDate, 'days')} days ago` : moment(record.last_valuation_date).fromNow())}
                </strong>
              </div>
              <div>
                Phase:
                {` `}
                <strong>
                  {!!projectPhase && projectPhase.title}
                </strong>
              </div>
              <div>
                Archived:
                {` `}
                <strong>
                  {!!completedAt && (now.diff(completedAt, 'days') < 90 ? `${now.diff(completedAt, 'days')} days ago` : moment(record.completed_at).fromNow())}
                </strong>
              </div>
              <div>&nbsp;</div>
              <div>
                Updated:
                <strong>
                  {` ${record.profit_and_loss_set_by_user.full_name} ${moment(record.profit_and_loss_set_at).fromNow()}`}
                </strong>
              </div>
            </div>
          )
        }
      },

    ];

    const scroll = { x: columns.reduce((total, column) => total + column.width, 0) };

    return (
      <Table
        dataSource={projects}
        columns={columns} 
        rowKey={record => record.id}
        scroll={scroll}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          ...pagination,
          style: { float: 'none', textAlign: 'center' }
        }}
      />
    )
  };
}
