import { createAction, handleActions } from 'redux-actions'

const openProjectEditorModal = createAction('SCREENS/ORDER_VIEW/OPEN_PROJECT_EDITOR_MODAL')
const closeProjectEditorModal = createAction('SCREENS/ORDER_VIEW/CLOSE_PROJECT_EDITOR_MODAL')

const defaultState = {
  show: false,
  project: null,
}

const reducer = handleActions(
  {
    [openProjectEditorModal]: (state, { payload }) => ({
      show: true,
      project: payload.project,
    }),
    [closeProjectEditorModal]: () => defaultState,
  },
  defaultState,
)

export { openProjectEditorModal, closeProjectEditorModal, reducer }
