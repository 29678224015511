import { Modal } from 'antd';
import createApplication from '../../../Services/createApplication';

const { confirm } = Modal;

export default () => async (dispatch) => {

  confirm({
    title: `Create Application`,
    content: 'Are you sure you want to create an Application for this Valuation?',
    okText: 'Yes, Create it',
    width: 550,
    onOk() {
      
      dispatch(createApplication());
      
    }
  });

}