import { getPlanner } from '../../../HttpRequests/planner'
import { setData } from '../State/data'
import { startFetching, finishFetching } from '../State/fetching'
import getParams from '../Utils/getParams'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { controls } = getState().screens.Planner

  const params = getParams(controls)

  dispatch(startFetching())

  try {

    const response = await getPlanner({ params })

    dispatch(setData(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
