import { connect } from 'react-redux'
import ControlsModal from './ControlsModal'

import onChangeControls from './Handlers/onChangeControls'

const mapStateToProps = (state) => ({
  users: state.core.users,
  projectStatuses: state.core.projectStatuses,
  projectPhases: state.core.projectPhases,
  controls: state.screens.Planner.controls,
})

const actionCreators = {
  onChangeControls,
}

export default connect(mapStateToProps, actionCreators)(ControlsModal)
