import React from 'react'
import { Breadcrumb, Divider } from 'antd'

const { Item } = Breadcrumb

export default () => (

  <React.Fragment>

    <Breadcrumb separator='»'>

      <Item>
        Home
      </Item>

      <Item>
        Timesheets
      </Item>

    </Breadcrumb>
      
    <Divider />

  </React.Fragment>

)
