import { connect } from 'react-redux'
import Tabs from './Tabs'

import onChangeTab from './Handlers/onChangeTab'

const mapStateToProps = (state) => ({
  activeTab: state.screens.SubProject.activeTab,
})

const actionCreators = {
  onChangeTab,
}

export default connect(mapStateToProps, actionCreators)(Tabs)
