import immutable from 'immutable';
import { message } from 'antd'
import { loadEstimate } from '../State/estimate';
import { updateEstimate } from '../../../HttpRequests/estimates';
import handleException from '../../../Core/Helpers/handleException';

export default (field, value) => async (dispatch, getState) => {

  try {

    // update local state

    let estimate = immutable.fromJS(getState().screens.EstimateView.estimate);

    estimate = estimate.set(field, value);

    estimate = estimate.toJS();

    dispatch(loadEstimate(estimate));

    // update on server

    const { id } = estimate;

    const data = {
      [field]: value || null
    };

    await updateEstimate({ id, data });

    message.success('Estimate Updated.');

  } catch (error) {

    handleException(error);

  }
}