import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu, Button } from 'antd'
import { CaretDownFilled, CheckCircleFilled, MinusCircleOutlined, ToolOutlined } from '@ant-design/icons'

const Spacer = () => <div style={{ width: 8 }} />

const Actions = props => {

  const {
    selected,
    onClickAutoAdjust,
    onClickStatus,
  } = props

  if (!selected.length) return false

  return (

    <div style={{ display: 'flex' }}>

      <Dropdown
        trigger={['click']}
        overlay={(
          <Menu onClick={({ key }) => onClickAutoAdjust(key)}>
            <Menu.Item key='30-min'>
              Down to nearest half hour
            </Menu.Item>
            <Menu.Item key='10-hours'>
              Adjust to 10 Hours
            </Menu.Item>
          </Menu>
        )}
      >
        <Button size='small' icon={<ToolOutlined />}>Auto-Adjust</Button>
      </Dropdown>

      <Spacer />

      <Dropdown
        trigger={['click']}
        overlay={(
          <Menu onClick={({ key }) => onClickStatus(key)}>
            <Menu.Item key='pending' icon={<MinusCircleOutlined style={{ color: '#999999' }} />}>
              Pending
            </Menu.Item>
            <Menu.Item key='approved' icon={<CheckCircleFilled style={{ color: 'orange' }} />}>
              Approved
            </Menu.Item>
            <Menu.Item key='paid' icon={<CheckCircleFilled style={{ color: 'limegreen' }} />}>
              Paid
            </Menu.Item>
          </Menu>
        )}
      >
        <Button type='primary' size='small' icon={<CaretDownFilled />}>Update Status</Button>
      </Dropdown>

    </div>

  )

}

Actions.propTypes = {
  selected: PropTypes.array.isRequired,
  onClickAutoAdjust: PropTypes.func.isRequired,
  onClickStatus: PropTypes.func.isRequired,
}

export default Actions
