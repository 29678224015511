import { connect } from 'react-redux';
import Component from './Component';

import onChangeRowSelection from './Handlers/onChangeRowSelection';

const mapStateToProps = (state) => ({
  estimate: state.modals.SelectEstimateOptionals.estimate,
  selectedRowKeys: state.modals.SelectEstimateOptionals.selectedRowKeys
});

const actionCreators = {
  onChangeRowSelection
};

export default connect(mapStateToProps, actionCreators)(Component);
