import { fromJS } from 'immutable';
import { loadOrders } from '../State/orders'

export default ({ orderIndex, orderGroupIndex, orderItemIndex }) => (dispatch, getState) => {

  let orders = fromJS(getState().screens.ProjectView.TargetCosts.orders);

  const orderItem = orders.getIn([
    orderIndex,
    'order_groups',
    orderGroupIndex,
    'order_items',
    orderItemIndex,
  ]).toJS();

  let targetCostAmount = (Number(orderItem.total) * Number(orderItem.target_cost_percentage)) / 100;
  
  targetCostAmount = Number(targetCostAmount.toFixed(2));

  orders = orders.updateIn(
    [orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex],
    map => map.merge(fromJS({
      target_cost_amount: targetCostAmount.toFixed(2),
    }))
  );

  orders = orders.toJS();

  dispatch(loadOrders(orders));

}