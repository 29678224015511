import { connect } from 'react-redux'
import Subbies from './Subbies'

import componentDidMount from './Handlers/componentDidMount'

const mapStateToProps = (state) => ({
  activeTab: state.screens.Subbies.activeTab,
})

const actionCreators = {
  componentDidMount,
}

export default connect(mapStateToProps, actionCreators)(Subbies)
