import { setSelectedOrderItemIds } from '../../../State/selectedOrderItemIds';
import removeOrderItems from '../../../Services/removeOrderItems';

export default () => async (dispatch, getState) => {

  const { selectedOrderItemIds } = getState().screens.OrderView;

  dispatch(removeOrderItems(selectedOrderItemIds));
  
  dispatch(setSelectedOrderItemIds([]))

}