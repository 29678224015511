import core from '../Core/reducer';
import screens from '../Screens/reducer';
import modals from '../Modals/reducer';
import modules from '../Modules/reducer';

export default {
  core,
  screens,
  modals,
  modules,
};