import { combineReducers } from "redux"

import { reducer as fetching } from "./State/fetching"
import { reducer as projects } from "./State/projects"
import { reducer as pagination } from "./State/pagination"
import { reducer as sorting } from "./State/sorting"
import { reducer as filters } from "./State/filters"
import { reducer as searchTerm } from "./State/searchTerm"
import { reducer as ramsRecordModal } from "./State/ramsRecordModal"

export default combineReducers({
  fetching,
  projects,
  pagination,
  sorting,
  filters,
  searchTerm,
  ramsRecordModal,
})
