import { message } from 'antd'
import { createSubcontractor } from '../../../HttpRequests/subcontractors';
import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors';

import handleException from '../../../Core/Helpers/handleException';

export default (values) => async (dispatch) => {

  try {
    
    const data = {
      ...values,
      user: undefined,
      co_vehicle: undefined,
      deactivated_at: values.deactivated_at || null,
    };

    const response = await createSubcontractor({ data });

    const subcontractor = response.data.data;

    message.success('Subcontractor Created');

    await dispatch(fetchSubcontractors({ force: true }));

    return subcontractor;
    
  }  catch(error) {
    
    handleException(error);

    return false;
  }

}
