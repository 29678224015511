// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';
import type { EstimateItem } from '../types';

export type AddEstimateItemParams = {
  estimateGroupIndex: number,
  estimateItemIndex?: number
}

export default (params: AddEstimateItemParams) => (dispatch: Function, getState: Function) => {
  
  let estimate = fromJS(getState().screens.EstimateView.estimate);

  const estimateItem: EstimateItem = {
    id: null,
    estimate_group_id: estimate.getIn(['estimate_groups', params.estimateGroupIndex, 'id']),
    type: 'none',
    description: '',
    flag: null,
    quantity: '0.00',
    unit: 'm2',
    rate: '0.00',
    subtotal: '0.00',
    discount: '0.00',
    total: '0.00',
    line_number: null,
    order_number: null,
    notes: null
  }
  
  estimate = estimate.updateIn(
    ['estimate_groups', params.estimateGroupIndex, 'estimate_items'],
    (list) => params.estimateItemIndex !== undefined ? list.insert(params.estimateItemIndex + 1, estimateItem) : list.push(estimateItem)
  );

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}