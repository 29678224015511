// @flow

import * as React from 'react';
import { Input } from 'antd';

import type { UpdateOrderItemFieldParams } from '../../../types';

const { TextArea } = Input;

type Props = {
  editable: boolean,
  orderGroupIndex: number,
  orderItemIndex: number,
  note: null|string,
  onChangeOrderItemField: (params: UpdateOrderItemFieldParams) => void,
}

export default class extends React.PureComponent<Props> {

  onChangeOrderItemField = async (field: string, value: string) => {

    const {
      orderGroupIndex,
      orderItemIndex,
      onChangeOrderItemField
    } = this.props;

    await onChangeOrderItemField({
      orderGroupIndex,
      orderItemIndex,
      field,
      value
    });

  }

  render () {

    const {
      editable,
      note
    } = this.props;

    return (

      <div style={{ minWidth: 320, maxWidth: 320 }}>

        {editable && (
          <TextArea
            value={note}
            onChange={(event) => this.onChangeOrderItemField('notes', event.target.value)}
          />
        )}
        
        {!editable && (
          <div>
            {note}
          </div>
        )}

      </div>
      
    )
  }
}