import { connect } from 'react-redux'
import SubOrderGroup from './SubOrderGroup'

import onUpdateField from './Handlers/onUpdateField'

const mapStateToProps = () => ({
})

const actionCreators = {
  onUpdateField,
}

export default connect(mapStateToProps, actionCreators)(SubOrderGroup)
