import { Modal } from 'antd'
import mute from '../../../Services/mute'
import unmute from '../../../Services/unmute'

export default (key, project) => async (dispatch) => {

  switch (key) {

    default:
      break

    case 'MUTE':

      Modal.confirm({
        title: `Mute Project`,
        content: 'Are you sure want to mute RAMS notifications for this project?',
        okText: 'Yes, Mute it',
        width: 530,
        onOk: () => dispatch(mute(project.id))
      })

      break
    
    case 'UNMUTE':

      dispatch(unmute(project.id))

      break

  }

}