import { createAction, handleActions } from 'redux-actions'

const openSubOrderModal = createAction('SCREENS/SUBCONTRACTOR_VIEW/SUB_ORDERS/OPEN_SUB_ORDER_MODAL')
const closeSubOrderModal = createAction('SCREENS/SUBCONTRACTOR_VIEW/SUB_ORDERS/CLOSE_SUB_ORDER_MODAL')

const defaultState = {
  show: false,
  subcontractorId: null,
}

const reducer = handleActions(
  {
    [openSubOrderModal]: (state, { payload }) => ({
      ...payload,
      show: true,
    }),
    [closeSubOrderModal]: () => defaultState,
  },
  defaultState,
)

export { openSubOrderModal, closeSubOrderModal, reducer }
