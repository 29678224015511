import { combineReducers } from 'redux';

import { reducer as subcontractor } from './State/subcontractor';
import { reducer as activeTab } from './State/activeTab';
import { reducer as showSubcontractorModal } from './State/showSubcontractorModal';

import SubOrders from './Components/SubOrders/reducer'

export default combineReducers({
  subcontractor,
  activeTab,
  showSubcontractorModal,

  SubOrders,
})
