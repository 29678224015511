import { createAction, handleActions } from 'redux-actions'

const setSelectedEstimateIds = createAction('SCREENS/PROJECT_VIEW/SET_SELECTED_ESTIMATE_IDS')
const resetSelectedEstimateIds = createAction('SCREENS/PROJECT_VIEW/RESET_SELECTED_ESTIMATE_IDS')

const defaultState = []

const reducer = handleActions(
  {
    [setSelectedEstimateIds]: (state, { payload }) => payload,
    [resetSelectedEstimateIds]: () => defaultState,
  },
  defaultState,
)

export { setSelectedEstimateIds, resetSelectedEstimateIds, reducer }
