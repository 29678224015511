import { deleteProjectFiles } from '../../../HttpRequests/files';

import handleException from '../../../Core/Helpers/handleException';
import fetchProject from './fetchProject';
import fetchDirectory from './fetchDirectory';

export default () => async (dispatch, getState) => {

  const { selectedItemPath, checkedItemPaths } = getState().screens.ProjectView;

  try {

    const data = {
      paths: checkedItemPaths.length > 0 ? checkedItemPaths : [selectedItemPath]
    }
    
    await deleteProjectFiles({ data });

    dispatch(fetchProject());
    dispatch(fetchDirectory());
    
  } catch(error) {

    handleException(error);

  }

}