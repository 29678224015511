import { createAction, handleActions } from 'redux-actions';

const loadSubcontractors = createAction("SCREENS/SUBBIES/SUBCONTRACTORS/LOAD_SUBCONTRACTORS");

const defaultState = [];

const reducer = handleActions(
  {
    [loadSubcontractors]: (state, action) => action.payload
  },
  defaultState
);

export { loadSubcontractors, reducer };