// @flow

import { getProps } from '../props';
import create from '../Services/create';

export default async (values: Function, dispatch: Function) => {

  const { onClose, didCreate } = getProps();

  const estimate = await dispatch(create(values));

  onClose();
  
  if (didCreate) didCreate(estimate);


}