
import { createAction, handleActions } from 'redux-actions';

const setSelectedOrderGroupKeys = createAction("SCREENS/VALUATION_VIEW/SET_SELECTED_ORDER_GROUP_KEYS");

const defaultState = null;

const reducer = handleActions(
  {
    [setSelectedOrderGroupKeys]: (state, action) => action.payload
  },
  defaultState
);

export { setSelectedOrderGroupKeys, reducer };