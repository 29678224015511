import { createAction, handleActions } from 'redux-actions'

const setSubProjects = createAction('SCREENS/SUB_PROJECT/OVERVIEW/SET_SUB_PROJECTS')
const resetSubProjects = createAction('SCREENS/SUB_PROJECT/OVERVIEW/RESET_SUB_PROJECTS')

const defaultState = []

const reducer = handleActions(
  {
    [setSubProjects]: (state, { payload }) => payload,
    [resetSubProjects]: () => defaultState,
  },
  defaultState,
)

export { setSubProjects, resetSubProjects, reducer }
