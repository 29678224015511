import { connect } from 'react-redux'
import Header from './Header'

import onChangeTab from './Handlers/onChangeTab'

const mapStateToProps = (state) => ({
  activeTab: state.screens.Subbies.activeTab,
})

const actionCreators = {
  onChangeTab,
}

export default connect(mapStateToProps, actionCreators)(Header)
