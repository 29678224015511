import { createSelector } from 'reselect'
import { find, orderBy, uniq } from 'lodash'

const selectSubOrders = state => state.screens.SubProject.subcontractor.sub_orders
const selectProjectStatusKey = state => state.screens.SubProject.filters.projectStatusKey

export default createSelector(
  selectSubOrders,
  selectProjectStatusKey,
  (subOrders, projectStatusKey) => {

    let projects = subOrders.map(row => row.project)

    if (projectStatusKey) projects = projects.filter(row => row.status_key === projectStatusKey)

    const projectIds = uniq(projects.map(row => row.id))

    return orderBy(projectIds.map(projectId => find(projects, row => row.id === projectId)), 'id')

  },
)
