import { get } from 'lodash'

const getUserIdForInput = () => {

  const user = get(window, 'state.user')

  const currentTeam = get(window, 'state.currentTeam')

  if (user.team_id !== currentTeam.id) return null

  return user.id

}

export default getUserIdForInput
