import { connect } from 'react-redux';

import onSelect from './Handlers/onSelect';

import Component from './Component';

const mapStateToProps = (state) => ({
  itemDescriptions: state.core.itemDescriptions
});

const actionCreators = {
  onSelect
};

export default connect(mapStateToProps, actionCreators)(Component);