import immutable from 'immutable';
import { message } from 'antd'
import { loadProjects } from '../State/projects';
import { updateProject } from '../../../HttpRequests/projects';
import handleException from '../../../Core/Helpers/handleException';

export default (id, field, value) => async (dispatch, getState) => {

  try {
    
    let projects = immutable.fromJS(getState().screens.Projects.projects);

    const projectEntry = projects.findEntry(item => item.get('id') === id);

    const [ projectIndex ] = projectEntry;

    projects = projects.setIn([projectIndex, field], value);

    projects = projects.toJS();

    dispatch(loadProjects(projects));

    const data = {
      [field]: value
    } 
  
    await updateProject({ id, data });

    message.success('Project Updated');

  } catch (error) {

    handleException(error);
    
  }
}