import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import styles from './styles.css'

const Header = props => {

  const {
    filters,
    onChangeFilter,
  } = props

  return (

    <div className={styles.wrapper}>

      <div className={styles.left}>

        <Select
          value={filters.completionStatuses}
          onChange={value => onChangeFilter('completionStatuses', value)}
          mode='multiple'
          showSearch
          optionFilterProp='children'
          placeholder='All Statuses'
          style={{ width: 200 }}
        >
          <Select.OptGroup label='Sub Completion Status'>
            {['Pending', 'Approved', 'Paid'].map(completionStatus => (
              <Select.Option key={completionStatus} value={completionStatus}>
                {completionStatus}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </Select>

      </div>

    </div>

  )

}

Header.propTypes = {
  filters: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
}

export default Header
