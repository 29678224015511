import { getNewProjectsWithOrderReport } from '../../../HttpRequests/dashboard';
import handleException from '../../../Core/Helpers/handleException';

import { loadData } from '../State/newProjectsWithOrderReport';

export default () => async (dispatch, getState) => {

  try {

    const params = {};

    const { days } = getState().screens.Dashboard.newProjectsWithOrderReport;

    if (days) {
      params.days = days;
    }
    
    const response = await getNewProjectsWithOrderReport({ params });

    dispatch(loadData(response.data.data));

  } catch(error) {
    handleException(error);

  }
}