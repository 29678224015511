import { connect } from 'react-redux'
import UploadModal from './UploadModal'

import onUpload from './Handlers/onUpload'

const mapStateToProps = () => ({
})

const actionCreators = {
  onUpload,
}

export default connect(mapStateToProps, actionCreators)(UploadModal)
