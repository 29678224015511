import { createAction, handleActions } from 'redux-actions';

const loadValuationTotals = createAction("SCREENS/VALUATIONS/LOAD_VALUATION_TOTALS");

const defaultState = {
  complete_total: 0
}

const reducer = handleActions(
  {
    [loadValuationTotals]: (state, action) => action.payload
  },
  defaultState
);

export { loadValuationTotals, reducer };