import { message } from 'antd'
import { updateTimesheet } from '../../../HttpRequests/timesheets'

const formatManualDeductionValue = (value) => {
  if (value === 'auto') {
    return null
  }
  if (value === 'none') {
    return 0
  }
  if (value === '30m') {
    return 0.5
  }
  if (value === '1h') {
    return 1
  }
  return null
}

export default (values) => async () => {

  const { id } = values

  const data = {
    subcontractor_id: values.subcontractor_id,
    date: values.date,
    manual_deduction: !values.day_rate ? formatManualDeductionValue(values.manual_deduction) : null,
    hourly_rate: !values.day_rate ? values.hourly_rate : null,
    total_cost: values.day_rate ? values.total_cost : undefined,
    notes: values.notes || null,
  }

  const response = await updateTimesheet({ id, data })

  const timesheet = response.data

  message.success('Timesheet updated')

  return timesheet

}
