import { push } from 'connected-react-router';
import { getTeamUrl } from 'Common';
import handleException from '../../../Core/Helpers/handleException';
import { deleteProject } from '../../../HttpRequests/projects';


export default () => async (dispatch, getState) => {

  const { id } = getState().screens.ProjectView.project;
  
  try {

    await deleteProject({ id });
    
    dispatch(push(getTeamUrl('projects')));
    
  } catch(error) {

    handleException(error);

  }
  
}