import { createSelector } from 'reselect';
import { assign } from 'lodash';

const selectOrder = state => state.screens.OrderView.order;

export default createSelector(
  selectOrder,
  (order) => {

    if (!order) return [];

    let incrementer = 0;

    const items = [];

    if (order.order_caveats.length > 0) {

      items.push({
        type: 'Header',
        height: 60
      });

      order.order_caveats.forEach((orderCaveat, orderCaveatIndex) => {
        
        incrementer += 1;
        const  lineNumber = incrementer;

        items.push({
          type: 'Item',
          height: 50,
          orderCaveat: assign({}, orderCaveat, { line_number: lineNumber }),
          orderCaveatIndex
        });

      })

      items.push({
        type: 'Footer',
        height: 60
      });

    }

    if (order.order_caveats.length === 0) {

      items.push({
        type: 'Placeholder',
        height: 100
      });

    }

    return items;

  }
)