import { Modal } from 'antd'
import deleteItems from '../../../Services/deleteItems'

export default () => (dispatch, getState) => {

  const { selectedItemPath, checkedItemPaths } = getState().modules.FileManager

  const paths = checkedItemPaths.length > 0 ? checkedItemPaths : [selectedItemPath]

  Modal.confirm({
    title: 'Delete Items',
    content: 'Are you sure you want to delete the selected items?',
    okText: 'Yes, Delete',
    onOk: () => dispatch(deleteItems(paths)),
  })

}
