import moment from 'moment';
import { getTeamUrl } from 'Common';

export default (options) => async (dispatch, getState) => {

  const type = options.key;

  const { sorting, searchTerm, filters } = getState().screens.Orders;

  const params = {};

  if (sorting.columnKey) {
    params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
  }

  if (searchTerm) {
    params.search = searchTerm;
  }

  if (filters.client_id.length > 0) {
    params['filter[client_id]'] = filters.client_id.join(',');
  }

  if (filters.extra_works !== 'all') {
    params['filter[extra_works]'] = filters.extra_works;
  }

  if (Array.isArray(filters.date_between) && filters.date_between.length === 2) {
    params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join();
  }

  let url = `orders/export?type=${type}`;

  Object.keys(params).forEach(key => {
    url += `&${key}=${params[key]}`
  })

  window.location = getTeamUrl(url, 'exports');

}