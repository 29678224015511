exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1ykrKbEh6ONsg5T6_inwEr {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n._1bpm0mOqqTyj66KN3saLY3 {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n._1FC16UQMCN0Hb_1_kkHhO6 {\n  width: 26px;\n  height: 26px;\n  line-height: 26px;\n  border-radius: 13px;\n  text-align: center;\n  font-size: 10px;\n  font-weight: 500;\n  display: inline-block;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Modules/ValuationServicesPopover/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uBAAuB;EACvB,SAAS;CACV;;AAED;EACE,cAAc;EACd,oBAAoB;EACpB,SAAS;CACV;;AAED;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;CACvB","file":"styles.css","sourcesContent":[".serviceTypesWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.serviceTypeWrapper {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.serviceTypeIcon {\n  width: 26px;\n  height: 26px;\n  line-height: 26px;\n  border-radius: 13px;\n  text-align: center;\n  font-size: 10px;\n  font-weight: 500;\n  display: inline-block;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"serviceTypesWrapper": "_1ykrKbEh6ONsg5T6_inwEr",
	"serviceTypeWrapper": "_1bpm0mOqqTyj66KN3saLY3",
	"serviceTypeIcon": "_1FC16UQMCN0Hb_1_kkHhO6"
};