import { closeEditorModal } from '../State/editorModal'
import fetchDirectory from '../Services/fetchDirectory'

export default (reload = false) => (dispatch) => {

  dispatch(closeEditorModal())

  if (reload) {
    dispatch(fetchDirectory())
  }
  
}
