import getParams from '../Utils/getParams'
import { getSubOrders } from '../../../../../HttpRequests/subOrders'
import { setSubOrders } from '../State/subOrders'
import { startFetching, finishFetching } from '../State/fetching'
import { setPagination } from '../State/pagination'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const state = getState()

  const { pagination } = state.screens.Subbies.SubOrders
  
  const params = getParams(state)

  params.include = [
    'subcontractor',
    'project.client_site.client',
    'user',
  ].join(',')

  params['page[number]'] = pagination.pageNumber
  params['page[size]'] = pagination.pageSize

  dispatch(startFetching())

  try {

    const response = await getSubOrders({ params })

    dispatch(setSubOrders(response.data.rows))

    dispatch(setPagination({
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
      total: response.data.pagination.total,
    }))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
