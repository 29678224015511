import { formValueSelector } from 'redux-form';
import { FORM_NAME } from '../Form';

export default () => (dispatch, getState) => {

  const caveatIds = formValueSelector(FORM_NAME)(getState(), 'caveat_ids');

  const { caveats } = getState().core;
  
  return caveats.filter(caveat => caveatIds.includes(caveat.id)).map(caveat => ({ content: caveat.content }));

}