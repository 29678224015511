// @flow
import { getTeamUrl } from 'Common';

export default ({ version }) => async (dispatch: Function, getState: Function) => {

  const { id } = getState().screens.EstimateView.estimate;

  let url = `estimates/${id}/pdf?`;

  if (version === 'internal') url += `&version=internal`

  window.open(getTeamUrl(url, 'exports'));

}