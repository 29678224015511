export default (values) => {

  const errors = {};

  if (!values.project_id) errors.project_id = true;
  if (!values.client_contact_id) errors.client_contact_id = true;
  if (!values.date) errors.date = true;
  if (!values.terms_and_conditions_type) errors.terms_and_conditions_type = true;
  if (values.discount_percentage === null || values.discount_percentage === '') errors.discount_percentage = true;
  if (values.uplift_percentage === null || values.uplift_percentage === '') errors.uplift_percentage = true;
  if (!values.vat_percentage) errors.vat_percentage = true;

  if (values.id) {
    if (values.extra_works === undefined || values.extra_works === null) errors.extra_works = true;
  }

  return errors;
  
}