import { connect } from 'react-redux';
import Component from './Component';

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import componentDidUpdate from './Handlers/componentDidUpdate';
import didUpdateValuation from './Handlers/didUpdateValuation';
import onCloseValuationModal from './Handlers/onCloseValuationModal';
import onCloseEmailModal from './Handlers/onCloseEmailModal';
import onCloseValuationCompletionModal from './Handlers/onCloseValuationCompletionModal';
import didUpdateValuationCompletion from './Handlers/didUpdateValuationCompletion';
import onCloseValuationHistoryModal from './Handlers/onCloseValuationHistoryModal';
import didSelectValuation from './Handlers/didSelectValuation';
import onCloseCreateInvoiceModal from './Handlers/onCloseCreateInvoiceModal';
import onCloseProjectCompleteModal from './Handlers/onCloseProjectCompleteModal';
import didCreateInvoice from './Handlers/didCreateInvoice';
import didSendEmail from './Handlers/didSendEmail';
import didCompleteProject from './Handlers/didCompleteProject';
import didUpdateClientSite from './Handlers/didUpdateClientSite';
import didUpdateClientContact from './Handlers/didUpdateClientContact';
import onCloseClientSiteModal from './Handlers/onCloseClientSiteModal';
import onCloseClientContactModal from './Handlers/onCloseClientContactModal';
import onCloseProjectEditorModal from './Handlers/onCloseProjectEditorModal';
import onCloseEditOrderItemModal from './Handlers/onCloseEditOrderItemModal';
import didUpdateOrderItem from './Handlers/didUpdateOrderItem';


const mapStateToProps = (state) => ({
  valuation: state.screens.ValuationView.valuation,
  fetching: state.screens.ValuationView.fetching,
  showValuationModal: state.screens.ValuationView.showValuationModal,
  showEmailModal: state.screens.ValuationView.showEmailModal,
  showValuationCompletionModal: state.screens.ValuationView.showValuationCompletionModal,
  showValuationHistoryModal: state.screens.ValuationView.showValuationHistoryModal,
  showCreateInvoiceModal: state.screens.ValuationView.showCreateInvoiceModal,
  showProjectCompleteModal: state.screens.ValuationView.showProjectCompleteModal,
  showClientSiteModal: state.screens.ValuationView.showClientSiteModal,
  showClientContactModal: state.screens.ValuationView.showClientContactModal,
  projectEditorModal: state.screens.ValuationView.projectEditorModal,
  showEditOrderItemModal: state.screens.ValuationView.showEditOrderItemModal,
  selectedOrderItemKeys:  state.screens.ValuationView.selectedOrderItemKeys
})

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  componentDidUpdate,
  didUpdateValuation,
  onCloseValuationModal,
  onCloseEmailModal,
  didUpdateValuationCompletion,
  onCloseValuationCompletionModal,
  onCloseValuationHistoryModal,
  didSelectValuation,
  onCloseCreateInvoiceModal,
  onCloseProjectCompleteModal,
  didCreateInvoice,
  didSendEmail,
  didCompleteProject,
  onCloseClientSiteModal,
  onCloseClientContactModal,
  didUpdateClientSite,
  didUpdateClientContact,
  onCloseProjectEditorModal,
  onCloseEditOrderItemModal,
  didUpdateOrderItem
};

export default connect(mapStateToProps, actionCreators)(Component);