import fetchYearOverviewReport from '../Services/fetchYearOverviewReport';
import fetchEstimatesByClientReport from '../Services/fetchEstimatesByClientReport';
import fetchLastThreeMonthsReport from '../Services/fetchLastThreeMonthsReport';
import fetchTopClientsReport from '../Services/fetchTopClientsReport';
import fetchServicesBreakdownReport from '../Services/fetchServicesBreakdownReport';
import fetchNewProjectsWithOrderReport from '../Services/fetchNewProjectsWithOrderReport';
import fetchClients from '../../../Core/Services/fetchClients';

export default () => async (dispatch) => {

  dispatch(fetchYearOverviewReport());
  dispatch(fetchEstimatesByClientReport());
  dispatch(fetchLastThreeMonthsReport());
  dispatch(fetchTopClientsReport());
  dispatch(fetchServicesBreakdownReport());
  dispatch(fetchNewProjectsWithOrderReport());
  dispatch(fetchClients());

}