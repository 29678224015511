import { Modal } from 'antd'
import { getProps } from '../props'
import deleteClientContact from '../Services/delete'

export default () => (dispatch) => {

  const { clientContact } = getProps()

  Modal.confirm({
    title: `Delete "${clientContact.full_name}"`,
    content: 'Are you sure to delete this contact?',
    okText: 'Yes',
    width: 400,
    onOk: () => dispatch(deleteClientContact()),
  })

}
