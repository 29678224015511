import { connect } from 'react-redux'
import SubValuations from './SubValuations'

import componentDidMount from './Handlers/componentDidMount'
import onCloseSubValuationModal from './Handlers/onCloseSubValuationModal'
import onCloseNotesModal from './Handlers/onCloseNotesModal'

const mapStateToProps = (state) => ({
  subValuationModal: state.screens.Subbies.SubValuations.subValuationModal,
  notesModal: state.screens.Subbies.SubValuations.notesModal,
})

const actionCreators = {
  componentDidMount,
  onCloseSubValuationModal,
  onCloseNotesModal,
}

export default connect(mapStateToProps, actionCreators)(SubValuations)
