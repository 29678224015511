import { connect } from 'react-redux'
import SubOrderGroupPlaceholder from './SubOrderGroupPlaceholder'

import onAddItem from './Handlers/onAddItem'

const mapStateToProps = (state) => ({
  editing: state.screens.SubOrder.editing,
})

const actionCreators = {
  onAddItem,
}

export default connect(mapStateToProps, actionCreators)(SubOrderGroupPlaceholder)
