import { message } from 'antd';
import _ from 'lodash';
import { setUploading } from '../State/uploading'

export default (info) => async (dispatch) => {

  const { status } = info.file;

  const uploading = _.find(info.fileList, { status: 'uploading'});
  
  if ( uploading ) {

    dispatch(setUploading(true));

  } else {

    dispatch(setUploading(false));

  }

  if (status === 'done') {

    message.success(`${info.file.name} file uploaded successfully.`);

  } else if (status === 'error') {

    message.error(`${info.file.name} file upload failed.`);

  }

}