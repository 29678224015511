import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import styles from './styles.css'

const Header = props => {

  const {
    search,
    onChangeSearch,
    onSearch,
  } = props

  return (

    <div className={styles.wrapper}>

      <div className={styles.left}>

        <Input.Search
          value={search}
          onChange={e => onChangeSearch(e.target.value)}
          onSearch={onSearch}
          placeholder='Search...'
          style={{ width: 250 }}
        />

      </div>

    </div>

  )

}

Header.propTypes = {
  search: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default Header
