import { createAction, handleActions } from 'redux-actions';

const loadClientSiteForEdit = createAction("SCREENS/CLIENT_VIEW/LOAD_CLIENT_SITE_FOR_EDIT");
const clearClientSiteForEdit = createAction("SCREENS/CLIENT_VIEW/CLEAR_CLIENT_SITE_FOR_EDIT");

const defaultState = null;

const reducer = handleActions(
  {
    [loadClientSiteForEdit]: (state, action) => action.payload,
    [clearClientSiteForEdit]: () => null
  },
  defaultState
);

export { loadClientSiteForEdit, clearClientSiteForEdit, reducer };