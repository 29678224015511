import { getFormValues, change } from 'redux-form';
import { getClient } from '../../../HttpRequests/clients';
import { loadClient } from '../State/client';
import { FORM_NAME } from '../Form';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const values = getFormValues(FORM_NAME)(getState());
  
  try {

    const id = values.client_site ? values.client_site.client_id : values.client_id;

    const params = {};
    
    params.include = [
      'client_contacts',
      'client_sites'
    ].join();

    const response = await getClient({ id, params });

    dispatch(loadClient(response.data.data));

    dispatch(change(FORM_NAME, 'billing_type', response.data.data.billing_type))

  } catch (error) {

    handleException(error);

  }
}