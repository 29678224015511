import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import screenProps from '../../screenProps'
import SubInvoiceModal from '../../../../Modals/SubInvoice'

import Header from './Components/Header'
import Table from './Components/Table'

const SubInvoices = props => {

  const {
    filters,
    subInvoiceModal,
    componentDidMount,
    onCloseSubInvoiceModal,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [filters])

  return (
    
    <React.Fragment>

      <Header />

      <Table />

      {subInvoiceModal.show && (
        <SubInvoiceModal
          {...subInvoiceModal}
          subcontractorId={Number(screenProps().match.params.id)}
          onClose={onCloseSubInvoiceModal}
        />
      )}

    </React.Fragment>

  )

}

SubInvoices.propTypes = {
  filters: PropTypes.object.isRequired,
  subInvoiceModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  onCloseSubInvoiceModal: PropTypes.func.isRequired,
}

export default SubInvoices
