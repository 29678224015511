import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import screenProps from '../../screenProps'
import SubValuationModal from '../../../../Modals/SubValuation'

import Header from './Components/Header'
import Table from './Components/Table'

const SubValuations = props => {

  const {
    filters,
    subValuationModal,
    componentDidMount,
    onCloseSubValuationModal,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [filters])

  return (
    
    <React.Fragment>

      <Header />

      <Table />

      {subValuationModal.show && (
        <SubValuationModal
          {...subValuationModal}
          subcontractorId={Number(screenProps().match.params.id)}
          projectId={filters.projectId}
          onClose={onCloseSubValuationModal}
        />
      )}

    </React.Fragment>

  )

}

SubValuations.propTypes = {
  filters: PropTypes.object.isRequired,
  subValuationModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  onCloseSubValuationModal: PropTypes.func.isRequired,
}

export default SubValuations
