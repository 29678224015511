import React from 'react'
import PropTypes from 'prop-types'

const ClientSiteDetails = ({ clientSite }) => (
  <div role='presentation' onClick={e => e.stopPropagation()}>
    <div>{clientSite.address_line_1}</div>
    <div>{clientSite.address_line_2}</div>
    <div>{clientSite.address_line_3}</div>
    <div>{clientSite.town_or_city}</div>
    <div>{clientSite.county}</div>
    <div>{clientSite.postcode}</div>
  </div>
)

ClientSiteDetails.propTypes = {
  clientSite: PropTypes.object.isRequired,
}

export default ClientSiteDetails
