import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import selectClientContacts from './Selectors/selectClientContacts'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import didCreateClientContact from './Handlers/didCreateClientContact'
import onCloseClientContactModal from './Handlers/onCloseClientContactModal'

import { formConfig } from './Form'

import Component from './Component'

const mapStateToProps = (state) => ({
  clientContacts: selectClientContacts(state),
  clientContactModal: state.modals.RamsRecord.clientContactModal,
})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  didCreateClientContact,
  onCloseClientContactModal,
}

export default connect(mapStateToProps, actionCreators)(reduxForm(formConfig)(Component))