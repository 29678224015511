// @flow

import { getProps } from '../props';
import { getDirectory } from '../../../HttpRequests/files';
import { loadRootDirectories } from '../State/rootDirectories';
import handleException from '../../../Core/Helpers/handleException';
import { setFetchingRootDirectories } from '../State/fetchingRootDirectories';
import { setCurrentDirectoryPath } from '../State/currentDirectoryPath';
import fetchDirectory from './fetchDirectory';

export default () => async (dispatch: Function, getState: Function) => {

  dispatch(setFetchingRootDirectories(true));

  try {
    
    const projectId = getProps().match.params.id

    const params = {
      path: `Projects/${projectId}`
    }

    const response = await getDirectory({ params });

    const contents = response.data.data;

    dispatch(loadRootDirectories(contents.directories));

    if (!getState().screens.ProjectView.currentDirectoryPath && contents.directories.length > 0) {
      dispatch(setCurrentDirectoryPath(contents.directories[0].path));
      dispatch(fetchDirectory());
    }

  } catch (error) {

    handleException(error);

  }

  dispatch(setFetchingRootDirectories(false));
}