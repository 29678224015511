
import { createAction, handleActions } from 'redux-actions';

const setShowSelectEstimateOptionalsModal = createAction("MODALS/ORDER/SET_SHOW_SELECT_ESTIMATE_OPTIONALS_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowSelectEstimateOptionalsModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowSelectEstimateOptionalsModal, reducer };