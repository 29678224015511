import { message } from 'antd'
import moment from 'moment';
import { pick } from 'lodash';
import { updateValuation } from '../../../HttpRequests/valuations';
import { getProps } from '../props';

export default (values) => async () => {

  const { id } = values;
    
  const data = {
    ...pick(values, [
      'client_contact_id',
      'user_id',
      'date',
      'valuation_to_date'
    ]),
    final_confirmed_at: getProps().valuation.final && values.final_confirmed ? moment().format('YYYY-MM-DD HH:mm:ss') : null
  };

  const response = await updateValuation({ id, data });

  const valuation = response.data.data;

  message.success("Valuation Updated");

  return valuation;

}