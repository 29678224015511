import { fromJS } from 'immutable';
import { loadOrder } from '../State/order';

export default ({ orderGroupIndex, orderItemIndex }) => (dispatch, getState) => {

  let order = fromJS(getState().screens.OrderView.order);

  let { quantity, rate } = order.getIn(['order_groups', orderGroupIndex, 'order_items', orderItemIndex]).toJS();

  quantity = Number.isNaN(Number(quantity)) ? 0 :  Number(quantity);
  rate = Number.isNaN(Number(rate)) ? 0 :  Number(rate);

  quantity = quantity.toFixed(2);
  rate = rate.toFixed(2);

  order = order.setIn(['order_groups', orderGroupIndex, 'order_items', orderItemIndex, 'quantity'], quantity);
  order = order.setIn(['order_groups', orderGroupIndex, 'order_items', orderItemIndex, 'rate'], rate);

  order = order.toJS();

  dispatch(loadOrder(order));

}