import { createAction, handleActions } from 'redux-actions';

const setFetchingDirectory = createAction("SCREENS/PROJECT_VIEW/SET_FETCHING_DIRECTORY");

const defaultState = false

const reducer = handleActions(
  {
    [setFetchingDirectory]: (state, action) => action.payload
  },
  defaultState
);

export { setFetchingDirectory, reducer };