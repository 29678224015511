import { get } from 'lodash'

const isSenior = () => {
  
  const type = get(window, 'state.user.type')
  
  return type === 'Admin' || type === 'Senior'
  
}

export default isSenior
