import screenProps from '../../../screenProps'
import { getSubProjects } from '../../../../../HttpRequests/subProjects'
import { setSubProjects } from '../State/subProjects'
import { startFetching, finishFetching } from '../State/fetching'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { id } = screenProps().match.params

  const state = getState()

  const { filters } = state.screens.SubProject

  const { search, sortBy } = state.screens.SubProject.SubProjects

  const params = {
    search,
    subcontractor_ids: id,
    status_key: filters.projectStatusKey || undefined,
    project_ids: filters.projectId || undefined,
    sort_by: sortBy || 'subcontractor.asc',
  }

  dispatch(startFetching())

  try {

    const response = await getSubProjects({ params })

    dispatch(setSubProjects(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
