import { combineReducers } from 'redux';

import { reducer as subcontractors } from './State/subcontractors';
import { reducer as pagination } from './State/pagination';
import { reducer as searchTerm } from './State/searchTerms';
import { reducer as filters } from './State/filters';
import { reducer as sorting } from './State/sorting';
import { reducer as fetching } from './State/fetching';
import { reducer as showSubcontractorModal } from './State/showSubcontractorModal';

export default combineReducers({
  subcontractors,
  pagination,
  searchTerm,
  filters,
  sorting,
  fetching,
  showSubcontractorModal
})
