import { initialize } from 'redux-form';
import moment from 'moment';

import { FORM_NAME } from '../Form';

export default () => async (dispatch) => {

  dispatch(initialize(FORM_NAME, {
    date_range: `${moment().subtract(12, 'month').format('YYYY-MM-DD')},${moment().format('YYYY-MM-DD')}`
  }));

}