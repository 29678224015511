import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import Form from './Form'

export default class EditorModal extends React.PureComponent {

  static propTypes = {
    newDirectory: PropTypes.bool.isRequired,
    selectedItemType: PropTypes.string,
    componentDidMount: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    selectedItemType: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  getTitle() {
    const { newDirectory, selectedItemType } = this.props
    if (newDirectory) return 'New Folder'
    if (selectedItemType === 'directory') return 'Rename Folder'
    if (selectedItemType === 'file') return 'Rename File'
    return false
  }

  render() {

    const {
      onClickSubmit,
      onClose,
    } = this.props

    return (

      <Modal
        title={this.getTitle()}
        visible
        okText='Save'
        onOk={onClickSubmit}
        onCancel={() => onClose()}
      >

        <Form />

      </Modal>

    )

  }

}
