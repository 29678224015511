// @flow

import { setEditable } from '../../../State/editable';
import { setSelectedOrderItemIds } from '../../../State/selectedOrderItemIds';
import fetchOrder from '../../../Services/fetchOrder';

export default () => async (dispatch: Function) => {
  
  dispatch(setEditable(false))
  dispatch(setSelectedOrderItemIds([]))

  dispatch(fetchOrder())

}