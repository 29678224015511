import create from '../Services/create';

import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { onClose, didComplete } = getProps();

  await dispatch(create(values));

  onClose();

  if (didComplete) didComplete();

}