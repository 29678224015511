import { Modal } from 'antd'
import deleteUser from '../Services/delete'

export default () => (dispatch) => {

  Modal.confirm({
    title: `Are you sure to delete this user?`,
    content: 'This action cannot be undone',
    okText: 'Yes',
    width: 400,
    onOk: () => dispatch(deleteUser()),
  })

}
