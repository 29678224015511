import { connect } from 'react-redux';
import Component from './Component';

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import onClickCancel from './Handlers/onClickCancel';
import onClickSubmit from './Handlers/onClickSubmit';

const mapStateToProps = () => ({});

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  onClickCancel,
  onClickSubmit
};

export default connect(mapStateToProps, actionCreators)(Component);