import { connect } from 'react-redux'
import Header from './Header'

import onClickEdit from './Handlers/onClickEdit'
import onClickDelete from './Handlers/onClickDelete'
import onChangeStatus from './Handlers/onChangeStatus'
import onClickNotes from './Handlers/onClickNotes'

const mapStateToProps = (state) => ({
  subValuation: state.screens.SubValuation.subValuation,
})

const actionCreators = {
  onClickEdit,
  onClickDelete,
  onChangeStatus,
  onClickNotes,
}

export default connect(mapStateToProps, actionCreators)(Header)
