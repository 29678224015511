import { message } from 'antd'
import { createDirectory } from '../../../../../HttpRequests/modelFiles'
import modalProps from '../modalProps'
import handleException from '../../../../../Core/Helpers/handleException'

export default (values) => async (dispatch, getState) => {

  const { currentDirectoryPath } = getState().modules.FileManager

  const data = {
    path: `${currentDirectoryPath}/${values.name}`,
  }

  try {
    
    await createDirectory({ data })

    message.success('Folder Created')

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
