// @flow
import { flatten } from 'lodash';
import { setShowOrderModal } from '../../../State/showOrderModal';
import { setShowEmailModal } from '../../../State/showEmailModal';
import { openSubOrderModal } from '../../../State/subOrderModal';
import onDelete from './onDelete';
import onDeleteFile from './onDeleteFile';

export default (key: string) => async (dispatch: Function, getState) => {

  const { order } = getState().screens.OrderView

  switch (key) {

    default:
      break;

    case 'SEND':
      dispatch(setShowEmailModal(true));
      break;

    case 'EDIT':
      dispatch(setShowOrderModal(true));
      break;

    case 'SUB_ORDER':
      dispatch(openSubOrderModal({
        projectId: order.project_id,
        orderItemIds: flatten(order.order_groups.map(orderGroup => orderGroup.order_items.map(orderItem => orderItem.id)))
      }));
      break;

    case 'DELETE':
      dispatch(onDelete());
      break;

    case 'DELETE_FILE':
      dispatch(onDeleteFile());
      break;

  }

}