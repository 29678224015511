import { get } from 'lodash'
import { getTimesheetSubcontractorToday } from '../../../HttpRequests/timesheets'
import { setData } from '../State/data'
import { setField } from '../State/form'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  try {

    const response = await getTimesheetSubcontractorToday()

    const { data } = response

    dispatch(setData(data))

    const timesheet = get(data.timesheet_posting, 'timesheet')

    if (!timesheet) return

    dispatch(setField({ notes: timesheet.notes }))

  } catch (error) {

    handleException(error)

  }

}
