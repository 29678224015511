import { fromJS } from 'immutable';
import { loadValuation } from '../State/valuation';

export default ({ orderIndex, orderGroupIndex, orderItemIndex }) => (dispatch, getState) => {

  let valuation = fromJS(getState().screens.ValuationView.valuation);

  const orderItem = valuation.getIn(['orders',orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex]).toJS();
  const valuationItem = orderItem.valuation_item;


  // recalculate aggregate percentage based on total
  let aggregatePercentage = Number(orderItem.total) !== 0 ? ((valuationItem.aggregate_total / orderItem.total) * 100) : 100;

  // round to 6 decimal spaces
  aggregatePercentage = Number(aggregatePercentage.toFixed(6)); 

  // recalculate complete percentage based on difference since previous
  let completePercentage =  aggregatePercentage - Number(valuationItem.previous_aggregate_percentage);
  // round to 6 decimal spaces
  completePercentage = Number(completePercentage.toFixed(6)); 

  valuation = valuation.updateIn(
    ['orders',orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex,'valuation_item'],
    map => map.merge(fromJS({
      aggregate_percentage: aggregatePercentage,
      complete_percentage: completePercentage
    }))
  );

  valuation = valuation.toJS();

  dispatch(loadValuation(valuation));

}