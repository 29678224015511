import { createSelector } from 'reselect'

const selectSetup = state => state.screens.Insights.setup
const selectFilters = state => state.screens.Insights.filters

export default createSelector(
  selectSetup,
  selectFilters,
  (setup, filters) => {

    if (setup.model === 'Project') {
      if (filters.statusKey.length) return true
      if (filters.phaseKey.length) return true
      if (filters.startBetween.length) return true
      if (filters.archivedBetween.length) return true
    }

    if (setup.model === 'Estimate') {
      if (filters.clientId.length) return true
      if (filters.userId.length) return true
      if (filters.dateBetween.length) return true
    }

    if (setup.model === 'Timesheet') {
      if (filters.subcontractorId.length) return true
      if (filters.projectId.length) return true
      if (filters.contractsManagerId.length) return true
      if (filters.dateRange.length) return true
    }

    return false

  },
)
