import { createAction, handleActions } from 'redux-actions';

const loadEstimate = createAction("SCREENS/ESTIMATE_VIEW/LOAD_ESTIMATE");
const clearEstimate = createAction("SCREENS/ESTIMATE_VIEW/CLEAR_ESTIMATE");

const defaultState = null

const reducer = handleActions(
  {
    [loadEstimate]: (state, action) => action.payload,
    [clearEstimate]: () => null
  },
  defaultState
);

export { loadEstimate, clearEstimate, reducer };