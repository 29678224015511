import { connect } from 'react-redux';
import Component from './Component';

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import componentDidUpdate from './Handlers/componentDidUpdate';
import onChangeTab from './Handlers/onChangeTab';
import didUpdateEstimate from './Handlers/didUpdateEstimate';
import didCreateOrder from './Handlers/didCreateOrder';
import didSendEmail from './Handlers/didSendEmail';
import didUpdateClientSite from './Handlers/didUpdateClientSite';
import didUpdateClientContact from './Handlers/didUpdateClientContact';
import onSelectCaveats from './Handlers/onSelectCaveats';
import onSelectItemDescription from './Handlers/onSelectItemDescription';
import onCloseEstimateModal from './Handlers/onCloseEstimateModal';
import onCloseOrderModal from './Handlers/onCloseOrderModal';
import onCloseEmailModal from './Handlers/onCloseEmailModal';
import onCloseSelectCaveatsModal from './Handlers/onCloseSelectCaveatsModal';
import onCloseSelectItemDescriptionModal from './Handlers/onCloseSelectItemDescriptionModal';
import onCloseEstimateDuplicateModal from './Handlers/onCloseEstimateDuplicateModal';
import onCloseClientSiteModal from './Handlers/onCloseClientSiteModal';
import onCloseClientContactModal from './Handlers/onCloseClientContactModal';
import onCloseProjectEditorModal from './Handlers/onCloseProjectEditorModal';
import didCreateEstimateDuplicateModal from './Handlers/didCreateEstimateDuplicateModal';

const mapStateToProps = (state) => ({
  estimate: state.screens.EstimateView.estimate,
  fetching: state.screens.EstimateView.fetching,
  showEstimateModal: state.screens.EstimateView.showEstimateModal,
  showOrderModal: state.screens.EstimateView.showOrderModal,
  showEmailModal: state.screens.EstimateView.showEmailModal,
  showEstimateDuplicateModal: state.screens.EstimateView.showEstimateDuplicateModal,
  showSelectCaveatsModal: state.screens.EstimateView.showSelectCaveatsModal,
  showSelectItemDescriptionModal: state.screens.EstimateView.showSelectItemDescriptionModal,
  showClientSiteModal: state.screens.EstimateView.showClientSiteModal,
  showClientContactModal: state.screens.EstimateView.showClientContactModal,
  projectEditorModal: state.screens.EstimateView.projectEditorModal,
})

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  componentDidUpdate,
  onChangeTab,
  didUpdateEstimate,
  didCreateOrder,
  didSendEmail,
  didUpdateClientSite,
  didUpdateClientContact,
  onSelectCaveats,
  onSelectItemDescription,
  onCloseEstimateModal,
  onCloseOrderModal,
  onCloseEmailModal,
  onCloseSelectCaveatsModal,
  onCloseSelectItemDescriptionModal,
  onCloseEstimateDuplicateModal,
  onCloseClientSiteModal,
  onCloseClientContactModal,
  onCloseProjectEditorModal,
  didCreateEstimateDuplicateModal
};

export default connect(mapStateToProps, actionCreators)(Component);