import { message } from 'antd';

import { updateInvoice } from '../../../HttpRequests/invoices';
import handleException from '../../../Core/Helpers/handleException';

import { getProps } from '../props';

export default (values) => async () => {

  try {
    
    const data = { ...values }
    const { invoice } = getProps();
  
    await updateInvoice({ id: invoice.id, data });

    message.success("Invoice updated");
    
    return true;

  } catch(error) {
    
    handleException(error);

    return false;
  }
}