import { message } from 'antd';
import { updateOrderItem } from '../../../HttpRequests/orderItems';
import handleException from '../../../Core/Helpers/handleException';
import { getProps } from '../props';

export default (data) => async () => {
  
  try {

    const { orderItemId } = getProps();

    const response = await updateOrderItem({ id: orderItemId, data });
    
    message.success("Order Item Updated");

    return response.data.data;

  } catch (error) {
    
    handleException(error);

    return false;

  }
}