import { createAction, handleActions } from 'redux-actions'

const openSubValuationModal = createAction('SCREENS/SUB_PROJECTS/OPEN_SUB_VALUATION_MODAL')
const closeSubValuationModal = createAction('SCREENS/SUB_PROJECTS/CLOSE_SUB_VALUATION_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSubValuationModal]: (state, { payload }) => ({
      ...payload,
      show: true,
    }),
    [closeSubValuationModal]: () => defaultState,
  },
  defaultState,
)

export { openSubValuationModal, closeSubValuationModal, reducer }
