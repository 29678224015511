import { connect } from 'react-redux';
import Component from './Component';

import selectItemsListData from '../../Selectors/selectItemsListData';

import onClickAddOrderItem from './Handlers/onClickAddOrderItem';
import onClickAddOrderGroup from './Handlers/onClickAddOrderGroup';
import onClickRemoveOrderItem from './Handlers/onClickRemoveOrderItem';
import onClickRemoveOrderGroup from './Handlers/onClickRemoveOrderGroup';
import onClickDuplicateOrderItem from './Handlers/onClickDuplicateOrderItem';
import onClickDuplicateOrderGroup from './Handlers/onClickDuplicateOrderGroup';
import onBlurOrderItemField from './Handlers/onBlurOrderItemField';
import onChangeOrderItemField from './Handlers/onChangeOrderItemField';
import onChangeOrderGroupName from './Handlers/onChangeOrderGroupName';
import onMoveOrderItem from './Handlers/onMoveOrderItem';
import onToggleOrderItem from './Handlers/onToggleOrderItem';

const mapStateToProps = (state) => ({
  listData: selectItemsListData(state),
  serviceTypes: state.core.serviceTypes,
  editable: state.screens.OrderView.editable,
  selectedOrderItemIds: state.screens.OrderView.selectedOrderItemIds,
})

const actionCreators = {
  onClickAddOrderItem,
  onClickAddOrderGroup,
  onClickRemoveOrderItem,
  onClickRemoveOrderGroup,
  onClickDuplicateOrderItem,
  onClickDuplicateOrderGroup,
  onBlurOrderItemField,
  onChangeOrderItemField,
  onChangeOrderGroupName,
  onMoveOrderItem,
  onToggleOrderItem
};

export default connect(mapStateToProps, actionCreators)(Component);