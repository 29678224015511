import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'

const AllFiles = ({ allFiles, selectedPaths, onChangeSelectedPaths }) => (
  <Table
    rowKey='key'
    dataSource={allFiles}
    size='small'
    rowSelection={{
      selectedRowKeys: selectedPaths,
      onChange: paths => onChangeSelectedPaths(paths),
      columnWidth: 70,
    }}
    style={{
      marginBottom: -24,
    }}
  >

    <Table.Column
      key='value'
      title='Project Files'
      render={(text, { label }) => label}
    />

  </Table>
)

AllFiles.propTypes = {
  allFiles: PropTypes.array.isRequired,
  selectedPaths: PropTypes.array.isRequired,
  onChangeSelectedPaths: PropTypes.func.isRequired,
}

export default AllFiles
