import { createSelector } from 'reselect';
import { merge } from 'lodash';
import moment from 'moment';
import baseLineChartConfig from '../Misc/baseLineChartConfig';

const selectData = state => state.screens.Dashboard.yearOverviewReport.data;
const selectMode = state => state.screens.Dashboard.yearOverviewReport.mode;

function formatter () {
  return this.series.data[this.point.index].count
}

export default createSelector(
  selectData,
  selectMode,
  (data, mode) => {

    const categories = [];
    const estimates = [];
    const orders = [];
    const valuations = [];
    
    if (data) {

      if (mode === 'weeks') {
        
        data.estimates.weeks.forEach(period => {
          categories.push(moment(period.start_date).format('D MMM'));
          estimates.push({
            name: moment(period.start_date).format('D MMM'),
            y: period.value,
            count: period.count,
          });
        })

        data.orders.weeks.forEach(period => {
          orders.push({
            name: moment(period.start_date).format('D MMM'),
            y: period.value,
            count: period.count,
          });
        })

        data.valuations.weeks.forEach(period => {
          valuations.push({
            name: moment(period.start_date).format('D MMM'),
            y: period.value,
            count: period.count,
          });
        })

      } else if (mode === 'months') {

        data.estimates.months.forEach(period => {
          categories.push(moment(period.start_date).format('MMMM'));
          estimates.push({
            name: moment(period.start_date).format('MMM'),
            y: period.value,
            count: period.count,
          });
        })

        data.orders.months.forEach(period => {
          orders.push({
            name: moment(period.start_date).format('MMM'),
            y: period.value,
            count: period.count,
          });
        })

        data.valuations.months.forEach(period => {
          valuations.push({
            name: moment(period.start_date).format('MMM'),
            y: period.value,
            count: period.count,
          });
        })

      }
      
    }
    
    const config = merge({}, baseLineChartConfig, {

      chart: {
        height: 600
      },

      colors: [ '#7EB6EA', '#93EB82', '#F5A363' ],

      xAxis: {
        categories
      },

      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            formatter
          }
        }
      },

      series: [
        {
          name: 'Estimates',
          data: estimates
        },
        {
          name: 'Orders',
          data: orders
        },
        {
          name: 'Valuations',
          data: valuations
        }
      ]

    });

    return config;

  }
)
