import { closeTimesheetAdjustmentModal } from '../State/timesheetAdjustmentModal'
import fetch from '../Services/fetch'

export default (timesheetAdjustment) => (dispatch) => {

  dispatch(closeTimesheetAdjustmentModal())

  if (timesheetAdjustment) {
    dispatch(fetch())
  }

}
