import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import onClickCancel from './Handlers/onClickCancel';
import onClickSubmit from './Handlers/onClickSubmit'

import { FORM_NAME } from './Form';

const mapStateToProps = (state) => ({
  submitting: isSubmitting(FORM_NAME)(state),
  serviceTypes: state.core.serviceTypes,
});

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onClickCancel,
  onClickSubmit
};

export default connect(mapStateToProps, actionCreators)(Component);