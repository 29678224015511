// @flow

import * as React from 'react';
import { Modal } from 'antd';
import Form from './Form';
import ProjectModal from '../Project'
import ClientContactModal from '../ClientContact'

type Props = {
  estimate: ?any,
  projects: [],
  users: [],
  clientContacts: [],
  clientId: any,
  submitting: boolean,
  showNewClientContactButton: boolean,
  showProjectModal: boolean,
  showClientContactModal: boolean,

  componentWillMount: (props: any) => void,
  componentWillUnmount: () => void,
  didCreateClientContact: () => void,
  didCreateProject: () => void,
  onClickCancel: () => void,
  onClickSubmit: () => void,
  onChangeProject: () => void,
  onClickNewProject: () => void,
  onClickNewClientContact: () => void,
  onCloseProjectModal: () => void,
  onCloseClientContactModal: () => void
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      estimate,
      projects,
      users,
      clientContacts,
      clientId,
      submitting,
      showNewClientContactButton,
      showProjectModal,
      showClientContactModal,
      
      didCreateClientContact,
      didCreateProject,
      onClickCancel,
      onClickSubmit,
      onChangeProject,
      onClickNewProject,
      onClickNewClientContact,
      onCloseProjectModal,
      onCloseClientContactModal
    } = this.props;

    return (
        
      <Modal
        title={estimate ? 'Edit Estimate': 'New Estimate'}
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText={estimate ? 'Update' : 'Create'}
        width={650}
      >
        
        <Form
          estimate={estimate}
          projects={projects}
          users={users}
          clientContacts={clientContacts}
          showNewClientContactButton={showNewClientContactButton}
          onClickNewProject={onClickNewProject}
          onClickNewClientContact={onClickNewClientContact}
          onChangeProject={onChangeProject}
        />

        {showProjectModal && (
          <ProjectModal
            onClose={onCloseProjectModal}
            didCreate={didCreateProject}
            clientId={clientId}
          />
        )}

        {showClientContactModal && (
          <ClientContactModal
            onClose={onCloseClientContactModal}
            didCreate={didCreateClientContact}
            clientId={clientId}
          />
        )}

      </Modal>
    )
  }
}