import { deleteMedia } from '../../../HttpRequests/media';
import handleException from '../../../Core/Helpers/handleException';
import fetchEstimate from './fetchEstimate';

export default (media) => async (dispatch) => {

  try {

    await deleteMedia({ id: media.id });

    dispatch(fetchEstimate());

  } catch(error) {

    handleException(error);

  }
}