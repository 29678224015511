import React from 'react'
import PropTypes from 'prop-types'
import { Select, Dropdown, Menu, Button, Popover } from 'antd'
import { CaretDownOutlined, CheckCircleFilled, DeleteOutlined, EditOutlined, InfoCircleFilled, InfoCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import styles from './styles.css'

const Spacer = () => <div style={{ width: 12 }} />

const statusIcons = {
  Pending: <MinusCircleOutlined style={{ color: '#999999' }} />,
  Approved: <CheckCircleFilled style={{ color: 'orange' }} />,
  Paid: <CheckCircleFilled style={{ color: 'limegreen' }} />,
}

const Header = props => {

  const {
    subValuation,
    onChangeStatus,
    onClickEdit,
    onClickDelete,
    onClickNotes,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <div className={styles.left}>

        <h1 className={styles.header}>{subValuation.reference}</h1>

      </div>

      <div className={styles.right}>

        <Popover
          title={(
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>Notes</div>
              {!!subValuation.notes && <a role='presentation' onClick={() => onClickNotes(subValuation.id, subValuation.notes)}>Edit</a>}
            </div>
          )}
          content={subValuation.notes || (
            <div>
              <span>Click</span>
              &nbsp;
              <a role='presentation' onClick={() => onClickNotes(subValuation.id)}>here</a>
              &nbsp;
              <span>to add notes</span>
            </div>
          )}
        >
          {subValuation.notes ? (
            <InfoCircleFilled style={{ fontSize: 16, color: 'orange' }} />
          ) : (
            <InfoCircleOutlined style={{ fontSize: 16, color: '#999999' }} />
          )}
        </Popover>

        <Spacer />

        <Select
          value={subValuation.status}
          onChange={onChangeStatus}
          placeholder='All Statuses'
          style={{ width: 180 }}
        >
          <Select.OptGroup label='Status'>
            {['Pending', 'Approved', 'Paid'].map(status => (
              <Select.Option key={status} value={status}>
                {statusIcons[status]}
                {' '}
                {status}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </Select>

        <Spacer />

        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu>
              <Menu.Item key='edit' icon={<EditOutlined />} onClick={onClickEdit}>Edit Details</Menu.Item>
              <Menu.Item key='delete' icon={<DeleteOutlined />} onClick={onClickDelete}>Delete Valuation</Menu.Item>
            </Menu>
          )}
        >
          <Button type='primary'>
            Actions
            <CaretDownOutlined />
          </Button>
        </Dropdown>

      </div>

    </div>
    
  )

}

Header.propTypes = {
  subValuation: PropTypes.object.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickNotes: PropTypes.func.isRequired,
}

export default Header
