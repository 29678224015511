import { setPagination } from '../State/pagination';
import fetchEstimates from '../Services/fetchEstimates';
import fetchEstimateTotals from '../Services/fetchEstimateTotals';

export default () => async (dispatch) => {

  dispatch(setPagination({ current: 1 }));

  dispatch(fetchEstimates());

  dispatch(fetchEstimateTotals());
  
}