// @flow

import * as React from 'react';
import { Col, Input, Button, Row } from 'antd'

type Props = {
  searchTerm: string,

  onClickNewEstimate: Function,
  onChangeSearch: Function
}


export default class extends React.PureComponent<Props> {

  render() {

    const {
      searchTerm,
      onClickNewEstimate,
      onChangeSearch
    } = this.props;

    return (

      <Row style={{ marginBottom: 15 }}>

        <Col span={12}>

          <Input.Search
            style={{ width: 250 }}
            placeholder="Search"
            onChange={onChangeSearch}
            value={searchTerm}
          />

        </Col>

        <Col span={12} className="text-right">

          <Button type="primary" onClick={onClickNewEstimate}>
            New Estimate
          </Button>

        </Col>

      </Row>
    )
  }
}