import { connect } from 'react-redux'

import Component from './Component'

const mapStateToProps = (state) => ({
  invoice: state.screens.InvoiceView.invoice,
})

const actionCreators = {}

export default connect(mapStateToProps, actionCreators)(Component)