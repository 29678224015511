import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import styles from './Component.css'

export default class _Footer_ extends React.PureComponent {

  static propTypes = {
    totals: PropTypes.object.isRequired,
  }

  render () {

    const {
      totals
    } = this.props

    return (

      <div className={styles.footer}>

        <div className={styles.total}>
          <div className={styles.label}>
            Total
          </div>
          <div className={styles.value}>
            {`£${numeral(totals.total).format('0,0.00')}`}
          </div>
        </div>

      </div>

    )
  }
}