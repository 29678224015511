// @flow
import * as React from 'react';
import { Modal } from 'antd';
import Form from './Form';

type Props = {
  submitting: boolean,
  emailOptions: [],
  fileList: [],
  selectedEmailToOption: [],
  selectedEmailCcOption: [],
  selectedEmailBccOption: [],
  componentWillMount: (props: any) => void,
  componentWillUnmount: () => void,
  onClickCancel: () => void,
  onClickSubmit: () => void,
  onSearchEmailOptions: Function,
  onSelectEmailOption: Function,
  onAddFile: Function,
  onRemoveFile: Function
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      submitting,
      emailOptions,
      fileList,
      selectedEmailToOption,
      selectedEmailCcOption,
      selectedEmailBccOption,
      onClickCancel,
      onClickSubmit,
      onSearchEmailOptions,
      onSelectEmailOption,
      onAddFile,
      onRemoveFile
    } = this.props;

    return (
        
      <Modal
        title="Send Estimate"
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText={`${submitting ? 'Sending Email' : 'Send Email'}`}
        width={650}
      >
        
        <Form
          emailOptions={emailOptions}
          fileList={fileList}
          selectedEmailToOption={selectedEmailToOption}
          selectedEmailCcOption={selectedEmailCcOption}
          selectedEmailBccOption={selectedEmailBccOption}
          onSearchEmailOptions={onSearchEmailOptions}
          onSelectEmailOption={onSelectEmailOption}
          onAddFile={onAddFile}
          onRemoveFile={onRemoveFile}
        />

      </Modal>
    )
  }
}