import axios from './axios'

export const createJob = ({ data }) => axios.post('jobs', data)
export const deleteJob = ({ id }) => axios.delete(`jobs/${id}`)
export const findJob = ({ id, params } = {}) => axios.get(`jobs/${id}`, { params })
export const getJobs = ({ params } = {}) => axios.get('jobs', { params })
export const updateJob = ({ id, data }) => axios.put(`jobs/${id}`, data)

export const createJobs = ({ data }) => axios.post('jobs/many', data)
export const updateJobs = ({ data }) => axios.put(`jobs/many`, data)
