import { connect } from 'react-redux';
import Component from './Component';

import selectCaveatsListData from '../../Selectors/selectCaveatsListData';

import onClickAddEstimateCaveat from './Handlers/onClickAddEstimateCaveat';
import onClickRemoveEstimateCaveat from './Handlers/onClickRemoveEstimateCaveat';
import onClickDuplicateEstimateCaveat from './Handlers/onClickDuplicateEstimateCaveat';
import onChangeEstimateCaveatField from './Handlers/onChangeEstimateCaveatField';
import onMoveEstimateCaveat from './Handlers/onMoveEstimateCaveat';
import onClickSelectCaveatFromList from './Handlers/onClickSelectCaveatFromList';

const mapStateToProps = (state) => ({
  estimate: state.screens.EstimateView.estimate,
  listData: selectCaveatsListData(state),
  serviceTypes: state.core.serviceTypes,
  editable: state.screens.EstimateView.editable
})

const actionCreators = {
  onClickAddEstimateCaveat,
  onClickRemoveEstimateCaveat,
  onClickDuplicateEstimateCaveat,
  onChangeEstimateCaveatField,
  onMoveEstimateCaveat,
  onClickSelectCaveatFromList
};

export default connect(mapStateToProps, actionCreators)(Component);