// @flow
import * as React from 'react';
import { Modal, Button } from 'antd';
import Form from './Components/Form';

type Props = {  
  uploading: boolean,  
  componentWillMount: (props: any) => void,
  onClickCancel: () => void,
  onUpload: () => void,
  onChange: () => void
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      uploading,
      onClickCancel,
      onUpload,
      onChange
    } = this.props;

    return (
        
      <Modal
        title='Upload file'
        visible
        onCancel={onClickCancel}
        okText='Upload'
        width={650}
        keyboard={false}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={onClickCancel} loading={uploading}>Done</Button>
        ]}
      >
        
        <Form
          onUpload={onUpload}
          onChange={onChange}
        />

      </Modal>
    )
  }
}