import { getTeamUrl } from 'Common';

export default ({ key }) => () => {

  let url

  if (key === 'clients') {
    url = `clients/export`;
  }

  if (key === 'client-sites') {
    url = `client_sites/export`;
  }

  if (key === 'client-contacts') {
    url = `client_contacts/export`;
  }

  window.open(getTeamUrl(url, 'exports'));

}