import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { find } from 'lodash'
import { Popover, Select, DatePicker } from 'antd'

const styles = {
  siteVisit: {
    border: '1px solid',
    borderRadius: '3px',
    padding: '6px',
    fontSize: '12px',
    cursor: 'pointer',
  },
}

const getSiteVisitColors = estimate => {
  let color = '#444444'
  if (estimate.site_visit_status_key === 1) {
    color = '#b0b0b0'
  }
  if (estimate.site_visit_status_key === 2) {
    color = 'red'
  }
  if (estimate.site_visit_status_key === 3) {
    color = '#ff9305'
  }
  if (estimate.site_visit_status_key === 4) {
    color = 'green'
  }
  return {
    borderColor: color,
    color,
  }
}

class SiteVisitPopover extends React.PureComponent {

  state = {
    visible: false,
    siteVisitStatusKey: null,
    siteVisitUserId: null,
    siteVisitDate: null,
  }

  onVisibleChange = (visible) => {

    const {
      estimate,
      onChangeSiteVisit,
    } = this.props

    const {
      siteVisitStatusKey,
      siteVisitUserId,
      siteVisitDate,
    } = this.state

    if (visible) {
      
      this.setState({
        visible,
        siteVisitStatusKey: estimate.site_visit_status_key,
        siteVisitUserId: estimate.site_visit_user_id,
        siteVisitDate: estimate.site_visit_date ? moment(estimate.site_visit_date) : null,
      })
      
    } else {
      
      this.setState({ visible })
      
      if (
        siteVisitStatusKey !== estimate.site_visit_status_key ||
        siteVisitUserId !== estimate.site_visit_user_id ||
        (siteVisitDate && siteVisitDate.format('YYYY-MM-DD')) !== estimate.site_visit_date
      ) {
        
        onChangeSiteVisit(estimate.id, {
          site_visit_status_key: siteVisitStatusKey,
          site_visit_user_id: siteVisitUserId,
          site_visit_date: siteVisitDate && siteVisitDate.format('YYYY-MM-DD'),
        })
        
      }
      
      setTimeout(() => {
        this.setState({
          visible,
          siteVisitStatusKey: null,
          siteVisitUserId: null,
          siteVisitDate: null,
        })
      }, 300)
      
    }
  }

  render () {

    const {
      estimate,
      siteVisitStatuses,
      users,
    } = this.props

    const {
      visible,
      siteVisitStatusKey,
      siteVisitUserId,
      siteVisitDate,
    } = this.state
    
    const selectedStatus = find(siteVisitStatuses, item => item.key === estimate.site_visit_status_key)
    const selectedUser = find(users, item => item.id === estimate.site_visit_user_id)
  
    return (
  
      <Popover
        title="Site Visit"
        trigger="click"
        visible={visible}
        onVisibleChange={this.onVisibleChange}
        content={(
          <div>

            <Select
              style={{ width: 250, marginBottom: 8 }}
              allowClear
              placeholder="Status"
              value={siteVisitStatusKey || undefined}
              onChange={value => this.setState({ siteVisitStatusKey: value || null})}
            >
              <Select.OptGroup label="Status">
                {siteVisitStatuses.map(item => (
                  <Select.Option key={item.key} value={item.key}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            </Select>
            
            <br />
            
            <Select
              style={{ width: 250, marginBottom: 8 }}
              allowClear
              placeholder="Attended by"
              value={siteVisitUserId || undefined}
              onChange={value => this.setState({ siteVisitUserId: value || null})}
            >
              <Select.OptGroup label="Attended by">
                {users.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {`${item.first_name} ${item.last_name}`}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            </Select>
            
            <br />
            
            <DatePicker
              style={{ width: 250 }}
              allowClear
              placeholder="Date"
              format="DD/MM/YYYY"
              value={siteVisitDate || undefined}
              onChange={value => this.setState({ siteVisitDate: value || null})}
            />

          </div>
        )}
      >
        <div style={{ ...styles.siteVisit, ...getSiteVisitColors(estimate) }}>
          <div>{selectedStatus ? selectedStatus.title : 'None'}</div>
          <div>
            {[
              selectedUser && `${selectedUser.first_name} ${selectedUser.last_name}`,
              estimate.site_visit_date && moment(estimate.site_visit_date).format('DD/MM/YYYY')
            ].filter(x => x).join(' - ')}
          </div>
        </div>
      </Popover>
  
    )
  }
}

SiteVisitPopover.propTypes = {
  estimate: PropTypes.object.isRequired,
  siteVisitStatuses: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  onChangeSiteVisit: PropTypes.func.isRequired,
}

export default SiteVisitPopover