// @flow

import * as React from 'react';
import numeral from 'numeral';

const styles = {

  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 25,
    borderTop: '1px solid #DDD'
  },

  total: {
    marginRight: 40
  },

  label: {
    fontSize: 12,
    fontWeight: 500,
    color: '#999'
  },

  value: {
    fontSize: 15,
    fontWeight: 500,
    color: '#444'
  }

}

type Props = {
  valuationTotals: any,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      valuationTotals
    } = this.props;

    return (

      <div style={styles.footer}>

        <div style={styles.total}>
          <div style={styles.label}>
            Total Value
          </div>
          <div style={styles.value}>
            {`£${numeral(valuationTotals.complete_total).format('0,0.00')}`}
          </div>
        </div>

      </div>

    )
  }
}