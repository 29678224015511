import axios from './axios'

export const createSubValuation = ({ data }) => axios.post('sub_valuations', data)
export const deleteSubValuation = ({ id }) => axios.delete(`sub_valuations/${id}`)
export const findSubValuation = ({ id, params } = {}) => axios.get(`sub_valuations/${id}`, { params })
export const getSubValuations = ({ params } = {}) => axios.get('sub_valuations', { params })
export const updateSubValuation = ({ id, data }) => axios.put(`sub_valuations/${id}`, data)
export const updateSubValuationStatus = ({ id, data }) => axios.put(`sub_valuations/${id}/status`, data)
export const handleSubValuationItems = ({ id, data }) => axios.put(`sub_valuations/${id}/items`, data)
export const handleSubValuationItemsByMany = ({ data }) => axios.put(`sub_valuations/items_by_many`, data)
