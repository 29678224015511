import { createAction, handleActions } from 'redux-actions'

const setSubValuations = createAction('SCREENS/SUB_INVOICE/SET_SUB_VALUATIONS')
const resetSubValuations = createAction('SCREENS/SUB_INVOICE/RESET_SUB_VALUATIONS')

const defaultState = []

const reducer = handleActions(
  {
    [setSubValuations]: (state, { payload }) => payload,
    [resetSubValuations]: () => defaultState,
  },
  defaultState,
)

export { setSubValuations, resetSubValuations, reducer }
