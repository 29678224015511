import { omit } from 'lodash'
import { openSubcontractorEditorModal } from '../../../State/subcontractorEditorModal'

export default (subcontractor) => (dispatch) => {

  dispatch(openSubcontractorEditorModal({
    subcontractor: omit(subcontractor, 'periods'),
  }))

}
