// @flow

import fetchClient from '../Services/fetchClient';

import { setProps } from '../props';

export default (props: any) => async (dispatch: Function) => {

  setProps(props);

  dispatch(fetchClient());

}