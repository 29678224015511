// @flow

import * as React from 'react';
import { WindowScroller, AutoSizer } from 'react-virtualized';
import SortableList from './SortableList';
import Header from './Header';
import Item from './Item';
import Placeholder from './Placeholder';
import Footer from './Footer';

type Props = {
  estimate: any,
  listData: [],
  serviceTypes: [],
  editable: boolean,
  onClickAddEstimateCaveat: () => void,
  onClickRemoveEstimateCaveat: () => void,
  onClickDuplicateEstimateCaveat: () => void,
  onChangeEstimateCaveatField: () => void,
  onMoveEstimateCaveat: () => void,
  onClickSelectCaveatFromList: () => void
};

type RowProps = {
  index: number,
  key: any,
  style: {}
};

export default class extends React.PureComponent<Props> {

  componentDidUpdate = () => this.SortableContainer.getWrappedInstance().List.recomputeRowHeights();

  SortableContainer: any;

  renderRow = (rowProps: RowProps) => {

    const {
      index,
      key,
      style
    } = rowProps;

    const {
      estimate,
      listData,
      serviceTypes,
      editable,
      onClickAddEstimateCaveat,
      onClickRemoveEstimateCaveat,
      onClickDuplicateEstimateCaveat,
      onChangeEstimateCaveatField,
      onClickSelectCaveatFromList
    } = this.props;

    const {
      type,
      estimateCaveat,
      estimateCaveatIndex
    } = listData[index];

    if (type === 'Header') {
      return (
        <div style={style} key={key}>
          <Header
            index={index}
            editable={editable}
          />
        </div>
      )
    }

    if (type === 'Item') {
      return (
        <div style={style} key={key}>
          <Item
            index={index}
            disabled={!editable} // prevents drag when not edit mode
            editable={editable}
            estimateCaveat={estimateCaveat}
            estimateCaveatIndex={estimateCaveatIndex}
            serviceTypes={serviceTypes}
            onClickAddEstimateCaveat={onClickAddEstimateCaveat}
            onClickRemoveEstimateCaveat={onClickRemoveEstimateCaveat}
            onClickDuplicateEstimateCaveat={onClickDuplicateEstimateCaveat}
            onChangeEstimateCaveatField={onChangeEstimateCaveatField}
          />
        </div>
      )
    }

    if (type === 'Placeholder') {

      return (
        <div style={style} key={key}>
          <Placeholder
            index={index}
            editable={editable}
            onClickAddEstimateCaveat={onClickAddEstimateCaveat}
            onClickSelectCaveatFromList={onClickSelectCaveatFromList}
          />
        </div>
      )
    }

    if (type === 'Footer') {
      return (
        <div style={style} key={key}>
          <Footer
            index={index}
            estimate={estimate}
            editable={editable}
            onClickAddEstimateCaveat={onClickAddEstimateCaveat}
            onClickSelectCaveatFromList={onClickSelectCaveatFromList}
          />
        </div>
      )
    }

    return null;
  }

  render () {

    const {
      listData,
      onMoveEstimateCaveat
    } = this.props;

    return (

      <div style={{ }}>

        <WindowScroller>
          {({ height, scrollTop }) => (

            <AutoSizer disableHeight>
              {({ width }) => (

                <SortableList
                  autoHeight
                  height={height}
                  width={width}
                  scrollTop={scrollTop}
                  lockAxis="y"
                  overscanRowCount={5}
                  useDragHandle
                  rowHeight={({ index }) => listData[index].height}
                  rowRenderer={this.renderRow}
                  rowCount={listData.length}
                  ref={ref => { this.SortableContainer = ref }}
                  onSortEnd={onMoveEstimateCaveat}
                />

              )}
            </AutoSizer>
            
          )}
        </WindowScroller>

      </div>

    )
  }
  
}