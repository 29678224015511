import { fromJS } from 'immutable';
import { loadValuation } from '../State/valuation';

export type UpdateValuationItemPercentageParams = {
  orderIndex: number,
  orderGroupIndex: number,
  orderItemIndex: number,
  value: string|null
}

export default (params) => (dispatch, getState) => {

  const { orderIndex, orderGroupIndex, orderItemIndex, value } = params;

  let valuation = fromJS(getState().screens.ValuationView.valuation);

  const orderItem = valuation.getIn(['orders',orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex]).toJS();

  let aggregatePercentage = Number(value);
  aggregatePercentage = Number.isNaN(aggregatePercentage) ? 0 : aggregatePercentage; // reset to 0 if not a number
  aggregatePercentage = aggregatePercentage > 100 ? 100 : aggregatePercentage; // dont go above 100
  aggregatePercentage = aggregatePercentage < 0 ? 0 : aggregatePercentage; // dont go below 0
  aggregatePercentage = Number(aggregatePercentage.toFixed(6)); // round to 6 decimal spaces

  let completePercentage = aggregatePercentage - Number(orderItem.valuation_item.previous_aggregate_percentage);
  completePercentage = Number(completePercentage.toFixed(6)); // round to 6 decimal spaces

  const aggregateTotal = (Number(orderItem.total) * aggregatePercentage) / 100;
  const completeTotal = (Number(orderItem.total) * completePercentage) / 100;

  valuation = valuation.updateIn(
    ['orders',orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex,'valuation_item'],
    map => map.merge(fromJS({
      aggregate_percentage: value,
      aggregate_total: aggregateTotal.toFixed(2),
      complete_percentage: completePercentage,
      complete_total: completeTotal.toFixed(2),
    }))
  );

  valuation = valuation.toJS();

  dispatch(loadValuation(valuation));

}