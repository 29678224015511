// @flow

import { message } from 'antd'
import { getProps } from '../props';
import { refreshValuation } from '../../../HttpRequests/valuations';
import fetchValuation from './fetchValuation';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch: Function) => {

  try {

    const { id } = getProps().match.params;

    await refreshValuation({ id });

    await dispatch(fetchValuation());

    message.success('Valuation Refreshed.');

  } catch (error) {

    handleException(error);

  }
}