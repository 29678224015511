exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._13OGHQ1dtxeImpZ6siY5t8 {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n}\n\n._3lYXPO1C25kwSgyijsjo2u {\n  flex: 1;\n}\n\n._36GWF3SrJuYKRtrdK3jRCe {\n  height: 100%;\n  display: flex;\n  align-items: flex-end;\n  margin-bottom: 15px;\n}\n\n._2UEi11d6Em2e_889Q3BIO2 {\n  width: 75px;\n  text-align: center;\n  font-size: 12px;\n  font-weight: 500;\n  color: #999;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/OrderView/Components/Caveats/Components/Header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;CACT;;AAED;EACE,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,oBAAoB;CACrB;;AAED;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;CACb","file":"Header.css","sourcesContent":[".wrapper {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 30px;\n  display: flex;\n  align-items: center;\n}\n\n.spacer {\n  flex: 1;\n}\n\n.headings {\n  height: 100%;\n  display: flex;\n  align-items: flex-end;\n  margin-bottom: 15px;\n}\n\n.cell {\n  width: 75px;\n  text-align: center;\n  font-size: 12px;\n  font-weight: 500;\n  color: #999;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_13OGHQ1dtxeImpZ6siY5t8",
	"spacer": "_3lYXPO1C25kwSgyijsjo2u",
	"headings": "_36GWF3SrJuYKRtrdK3jRCe",
	"cell": "_2UEi11d6Em2e_889Q3BIO2"
};