// @flow
import * as React from 'react';

import Header from './Components/Header';
import Table from './Components/Table';

import SubcontractorModal from '../../../../Modals/Subcontractor'

type Props = {
  subcontractors: [],
  pagination: {},
  fetching: boolean,
  showSubcontractorModal: boolean,
  searchTerm: string,
  filters: any,

  componentDidMount: () => void,
  onChangeTable: () => void,
  onChangeSearch: () => void,
  onChangeFilter: () => void,
  onClickNewSubcontractor: () => void,
  onCloseSubcontractorModal: () => void,
  didCreateSubcontractor: () => void,
  onChangeSearch: () => void,
  onSubmitSearch: () => void,
  onClickExport: () => void
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount();

  render() {
    
    const { 
      subcontractors,
      pagination,
      fetching,
      showSubcontractorModal,
      searchTerm,
      filters,    

      onChangeTable,
      onClickNewSubcontractor,
      onCloseSubcontractorModal,
      didCreateSubcontractor,
      onChangeSearch,
      onSubmitSearch,
      onClickExport,
      onChangeFilter
    } = this.props;
    
    return (

      <React.Fragment>

        <Header
          searchTerm={searchTerm}
          filters={filters}
          onChangeSearch={onChangeSearch}
          onSubmitSearch={onSubmitSearch}
          onChangeFilter={onChangeFilter}
          onClickExport={onClickExport}
          onClickNewSubcontractor={onClickNewSubcontractor}
        />

        <Table
          subcontractors={subcontractors}
          fetching={fetching}
          pagination={pagination}
          onChangeTable={onChangeTable}
        />

        {showSubcontractorModal && (
          <SubcontractorModal
            onClose={onCloseSubcontractorModal}
            didCreate={didCreateSubcontractor}
          />
        )}
      </React.Fragment> 

    )
  }
}