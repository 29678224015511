import { get } from 'lodash'

const isBasic = () => {
  
  const type = get(window, 'state.user.type')
  
  return type === 'Admin' || type === 'Senior' || type === 'Office' || type === 'Basic'
  
}

export default isBasic
