import { combineReducers } from 'redux'

import { reducer as subValuations } from './State/subValuations'
import { reducer as fetching } from './State/fetching'
import { reducer as search } from './State/search'
import { reducer as filters } from './State/filters'
import { reducer as sorting } from './State/sorting'
import { reducer as subValuationModal } from './State/subValuationModal'

export default combineReducers({
  subValuations,
  fetching,
  search,
  filters,
  sorting,
  subValuationModal,
})
