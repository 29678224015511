import { connect } from 'react-redux'
import Items from './Items'

import selectListData from './Selectors/selectListData'

const mapStateToProps = (state) => ({
  listData: selectListData(state),
  editing: state.screens.SubValuation.editing,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Items)
