import immutable from 'immutable';
import { message } from 'antd'
import { loadValuation } from '../State/valuation';
import { updateValuation } from '../../../HttpRequests/valuations';
import handleException from '../../../Core/Helpers/handleException';

export default (field, value) => async (dispatch, getState) => {

  try {

    // update local state

    let valuation = immutable.fromJS(getState().screens.ValuationView.valuation);

    valuation = valuation.set(field, value);

    valuation = valuation.toJS();

    dispatch(loadValuation(valuation));

    // update on server

    const { id } = valuation;

    const data = {
      [field]: value || null
    };

    await updateValuation({ id, data });

    message.success('Valuation Updated.');

  } catch (error) {

    handleException(error);

  }
}