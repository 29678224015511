import { combineReducers } from 'redux'

import { reducer as users } from './State/users'
import { reducer as fetching } from './State/fetching'
import { reducer as searchTerm } from './State/searchTerm'
import { reducer as filters } from './State/filters'
import { reducer as sorting } from './State/sorting'
import { reducer as pagination } from './State/pagination'
import { reducer as userModal } from './State/userModal'

export default combineReducers({
  users,
  fetching,
  searchTerm,
  filters,
  sorting,
  pagination,
  userModal,
})
