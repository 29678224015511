// @flow
import * as React from 'react';
import { Select, Radio, Switch, DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const defaultSelectProps = {
  style: { width: 200 },
  showArrow: true,
  showSearch: true,
  allowClear: true,
  notFoundContent: null,
  defaultActiveFirstOption: false,
  optionFilterProp: 'children',
};

type Props = {
  filters: any,
  projectPhases: [],
  projectStatuses: [],
  clients: [],
  users: [],

  onChangeFilter: Function
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      filters,
      projectPhases,
      projectStatuses,
      clients,
      users,
      onChangeFilter
    } = this.props;
    
    const projectOptions = [
      { title: 'All', key: 'all'},
      ...projectStatuses
    ]

    return (

      <div style={{ display: 'flex', marginBottom: 15 }}>
        
        <Radio.Group
          value={filters.status_key}
          onChange={(event) => onChangeFilter('status_key', event.target.value)}
        >

          {projectOptions.map(option => (
            <Radio.Button
              style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
              value={option.key}
              key={option.key}
            >
              {option.title}
            </Radio.Button>
          ))}

        </Radio.Group>
        
        <div style={{ width: 10 }} />


        <Select
          {...defaultSelectProps}
          placeholder="Filter by Phase"
          value={filters.phase_key}
          onChange={(value) => onChangeFilter('phase_key', value)}
          mode="multiple"
        >
          {projectPhases.map(phase => (
            <Select.Option key={phase.key}>
              {`${phase.title}`}
            </Select.Option>
          ))}
        </Select>


        <div style={{ width: 10 }} />


        <Select
          {...Object.assign({}, defaultSelectProps)}
          placeholder="Filter by Client"
          value={filters.client_id}
          onChange={(value) => onChangeFilter('client_id', value)}
          mode="multiple"
        >
          
          {clients.map(client => (
            <Select.Option key={client.id}>
              {`${client.name}`}
            </Select.Option>
          ))}

        </Select>


        <div style={{ width: 10 }} />


        <Select
          {...Object.assign({}, defaultSelectProps)}
          placeholder="Filter by Manager"
          value={filters.contracts_manager}
          onChange={(value) => onChangeFilter('contracts_manager', value)}
          mode="multiple"
        >

          {users.map(user => (
            <Select.Option key={user.id}>
              {`${user.first_name} ${user.last_name}`}
            </Select.Option>
          ))}

        </Select>


        <div style={{ width: 10 }} />


        <RangePicker
          value={filters.start_between}
          onChange={(value) => onChangeFilter('start_between', value)}
          format="DD/MM/YYYY"
          placeholder={['Start From', 'Start To']}
        />


        <div style={{ width: 10 }} />


        <RangePicker
          value={filters.date_between}
          onChange={(value) => onChangeFilter('date_between', value)}
          format="DD/MM/YYYY"
          placeholder={['Archived From', 'Archived To']}
        />


        <div style={{ width: 10 }} />


        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            checked={filters.outstanding_income}
            onChange={(value) => onChangeFilter('outstanding_income', value)}
          />
          <div style={{ width: 5 }} />
          <span>Outstanding</span>
        </div>

      </div>

    )
  };
}