import { initialize } from 'redux-form';
import moment from 'moment';
import { getUserIdForInput } from 'Common';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { estimate, projectId } = getProps();

  if (estimate) {

    dispatch(initialize(FORM_NAME, estimate))

  } else {

    dispatch(initialize(FORM_NAME, {
      project_id: projectId || undefined,
      user_id: getUserIdForInput(),
      date: moment().format('YYYY-MM-DD'),
      terms_and_conditions_type: 1,
      discount_percentage: 0,
      uplift_percentage: 0,
      vat_percentage: '20'
    }))

  }

}