import { createAction, handleActions } from 'redux-actions'

const setFilters = createAction('SCREENS/INSIGHTS/SET_FILTERS')
const resetFilters = createAction('SCREENS/INSIGHTS/RESET_FILTERS')

const defaultState = {
  // Project
  statusKey: [],
  phaseKey: [],
  startBetween: [],
  archivedBetween: [],

  // Estimate
  clientId: [],
  userId: [],
  dateBetween: [],
  newOrExtra: 'all',
  withRevisions: true,

  // Timesheet
  subcontractorId: [],
  projectId: [],
  contractsManagerId: [],
  dateRange: [],
  hoursType: 'all',
  hoursFormat: 'time',
}

const reducer = handleActions(
  {
    [setFilters]: (state, action) => ({ ...state, ...action.payload }),
    [resetFilters]: () => defaultState,
  },
  defaultState,
)

export { setFilters, resetFilters, reducer }
