import { getProps, setProps } from '../props'
import fetch from '../Services/fetch'

export default (props) => (dispatch) => {

  const prevProps = getProps();
  
  setProps(props);

  if (prevProps.match.params.id !== props.match.params.id) {
    dispatch(fetch())
  }

}