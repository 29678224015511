import React from 'react'
import PropTypes from 'prop-types'
import { Popover, Spin, Badge } from 'antd'
import { FormatPainterOutlined, LikeOutlined, MinusOutlined, UserDeleteOutlined } from '@ant-design/icons'
import styles from './styles.css'

import JobSlots from '../JobSlots'

const getPastAndCurrentContent = data => {
  const subcontractorsWithTimesheetPostings = data.filter(subcontractor => subcontractor.available && !subcontractor.absence && subcontractor.timesheet_postings.length > 0)
  const subcontractorsWithoutTimesheetPostings = data.filter(subcontractor => subcontractor.available && !subcontractor.absence && subcontractor.timesheet_postings.length === 0)
  const subcontractorsUnavailableOrAbsent = data.filter(subcontractor => !subcontractor.available || !!subcontractor.absence)
  return (
    <div style={{ width: 680, height: 400, display: 'flex', color: '#9e9e9e', overflow: 'auto' }}>
      <div style={{ flex: 1 }}>
        <h4>{`Clocked In (${subcontractorsWithTimesheetPostings.length})`}</h4>
        {subcontractorsWithTimesheetPostings.map(subcontractor => (
          <div key={subcontractor.id}>
            <FormatPainterOutlined style={{ marginRight: 5 }} />
            {subcontractor.name}
          </div>
        ))}
      </div>
      <div style={{ flex: 1 }}>
        <h4>{`No Clock-Ins (${subcontractorsWithoutTimesheetPostings.length})`}</h4>
        {subcontractorsWithoutTimesheetPostings.map(subcontractor => (
          <div key={subcontractor.id}>
            <MinusOutlined style={{ marginRight: 5 }} />
            {subcontractor.name}
          </div>
        ))}
      </div>
      <div style={{ flex: 1 }}>
        <h4>{`Unavailable / Absent (${subcontractorsUnavailableOrAbsent.length})`}</h4>
        {subcontractorsUnavailableOrAbsent.map(subcontractor => (
          <div key={subcontractor.id}>
            <UserDeleteOutlined style={{ marginRight: 5 }} />
            {subcontractor.name}
          </div>
        ))}
      </div>
    </div>
  )
}

const getFutureContent = data => {
  const subcontractorsWithJobs = data.filter(subcontractor => subcontractor.available && !subcontractor.absence && subcontractor.jobs.length > 0)
  const subcontractorsWithoutJobs = data.filter(subcontractor => subcontractor.available && !subcontractor.absence && subcontractor.jobs.length === 0)
  const subcontractorsUnavailableOrAbsent = data.filter(subcontractor => !subcontractor.available || !!subcontractor.absence)
  return (
    <div style={{ width: 680, height: 400, display: 'flex', overflow: 'auto' }}>
      <div style={{ flex: 1 }}>
        <h4>{`No Bookings (${subcontractorsWithoutJobs.length})`}</h4>
        {subcontractorsWithoutJobs.map(subcontractor => (
          <div key={subcontractor.id}>
            <LikeOutlined style={{ color: 'green', marginRight: 5 }} />
            {subcontractor.name}
          </div>
        ))}
      </div>
      <div style={{ flex: 1, color: '#9e9e9e' }}>
        <h4>{`Booked In (${subcontractorsWithJobs.length})`}</h4>
        {subcontractorsWithJobs.map(subcontractor => (
          <div key={subcontractor.id} className={styles.bookedIn}>
            {subcontractor.is_multiple ? (
              <Badge count={subcontractor.slots} size='small' style={{ backgroundColor: '#9e9e9e', fontSize: 9, fontWeight: 500 }} />
            ) : (
              <FormatPainterOutlined style={{ marginRight: 5 }} />
            )}
            {subcontractor.name}
            <JobSlots jobs={subcontractor.jobs} />
          </div>
        ))}
      </div>
      <div style={{ flex: 1, color: '#9e9e9e' }}>
        <h4>{`Unavailable / Absent (${subcontractorsUnavailableOrAbsent.length})`}</h4>
        {subcontractorsUnavailableOrAbsent.map(subcontractor => (
          <div key={subcontractor.id}>
            <UserDeleteOutlined style={{ marginRight: 5 }} />
            {subcontractor.name}
          </div>
        ))}
      </div>
    </div>
  )
}

export default class SubcontractorPopover extends React.PureComponent {

  static propTypes = {
    period: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    fetching: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    onVisibleChange: PropTypes.func.isRequired,
  }

  render() {

    const {
      period,
      data,
      fetching,
      children,
      onVisibleChange,
      ...rest
    } = this.props

    const spin = (
      <div style={{ width: 680, textAlign: 'center' }}>
        <Spin />
      </div>
    )

    const content = period.tense === 'future' ? getFutureContent(data) : getPastAndCurrentContent(data)

    return (

      <Popover
        title={`Overview - ${period.day_of_week} ${period.date_formatted}`}
        content={fetching ? spin : content}
        onVisibleChange={visible => onVisibleChange(visible, period.date)}
        {...rest}
      >

        {children}

      </Popover>

    )

  }

}
