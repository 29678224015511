import { createAction, handleActions } from 'redux-actions';

const loadEstimates = createAction("SCREENS/ESTIMATES/LOAD_ESTIMATES");
const clearEstimates = createAction("SCREENS/ESTIMATES/CLEAR_ESTIMATES");

const defaultState = []

const reducer = handleActions(
  {
    [loadEstimates]: (state, action) => action.payload,
    [clearEstimates]: () => []
  },
  defaultState
);

export { loadEstimates, clearEstimates, reducer };