import React from 'react';

import ChartPanel from './ChartPanel';
import Chart from './Chart';

type Props = {
  config: Object
}

export default class extends React.PureComponent<Props> {
  
  render = () => (
      
    <ChartPanel
      title="Top Clients Order Intake"
      renderChart={() => this.renderChart()}
    />
      
  )

  renderChart = () => (
    <Chart config={this.props.config} />
  )

}
