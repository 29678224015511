import fetchUsers from '../Services/fetchUsers'
import { setPagination } from '../State/pagination'

export default () => (dispatch) => {

  dispatch(setPagination({ current: 1 }))

  dispatch(fetchUsers())
  
}
