import { createAction, handleActions } from 'redux-actions'

const loadSubOrders = createAction('CORE/SUB_ORDERS/LOAD_SUB_ORDERS')
const clearSubOrders = createAction('CORE/SUB_ORDERS/CLEAR_SUB_ORDERS')

const defaultState = []

const reducer = handleActions(
  {
    [loadSubOrders]: (state, action) => action.payload,
    [clearSubOrders]: () => defaultState,
  },
  defaultState,
)

export { loadSubOrders, clearSubOrders, reducer }
