import React from 'react'

const style = {
  position: 'relative',
  bottom: 1,
  fontWeight: 500,
  backgroundColor: '#fff2e8',
  fontSize: 8,
  color: '#d4380d',
  padding: 2,
  border: '1px solid #ffbb96',
  borderRadius: 3,
  marginRight: 5,
}

export default () => <span style={style}>SV</span>
