import { connect } from 'react-redux'
import Projects from './Projects'

import selectTableRows from './Selectors/selectTableRows'

import onChangeSort from './Handlers/onChangeSort'

const mapStateToProps = (state) => ({
  tableRows: selectTableRows(state),
  fetching: state.screens.SubProjects.fetching,
  sortBy: state.screens.SubProjects.sortBy,
})

const actionCreators = {
  onChangeSort,
}

export default connect(mapStateToProps, actionCreators)(Projects)
