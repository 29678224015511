// @flow
import { getTeamUrl } from 'Common';

export default () => async (dispatch: Function, getState: Function) => {

  const { id } = getState().screens.EstimateView.estimate;

  const url = `estimates/${id}/excel`;

  window.location = getTeamUrl(url, 'exports');

}