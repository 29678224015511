/* eslint-disable no-underscore-dangle */

import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';


export default () => async (dispatch) => {

  const { subOrder } = getProps();
  
  const values = {
    to: subOrder.subcontractor.email ? [subOrder.subcontractor.email] : [],
    cc: [],
    bcc: [],
    subject: `Purchase Order for ${subOrder.project_id}`,
    body: '',
    id: subOrder.id,
  };
  
  values.body += `Dear ${subOrder.subcontractor.name}\r\n\r\n`;
  values.body += `Please find attached Purchase Order for ${subOrder.project_id}.\r\n\r\n`;
  values.body += `Kind Regards\r\n\r\n`;
  values.body += `${subOrder.user.first_name} ${subOrder.user.last_name}`
  

  dispatch(initialize(FORM_NAME, values));

}