import { message } from 'antd'
import { deleteClient } from '../../../HttpRequests/clients'
import { getProps } from '../props'
import handleException from '../../../Core/Helpers/handleException'

export default () => async () => {

  const { client, onClose, didDelete } = getProps()

  try {

    await deleteClient({ id: client.id })

    message.success('Client Deleted')

    onClose(true)

    if (didDelete) didDelete()

  } catch (error) {

    handleException(error)

  }

}
