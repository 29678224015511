// @flow

import * as React from 'react';
import List from './Components/List';

type Props = {
  listData: [],
  serviceTypes: [],
  editable: boolean,
  selectedEstimateItemIds: [],
  onClickAddEstimateItem: Function,
  onClickAddEstimateGroup: Function,
  onClickRemoveEstimateItem: Function,
  onClickRemoveEstimateGroup: Function,
  onClickDuplicateEstimateItem: Function,
  onClickDuplicateEstimateGroup: Function,
  onClickInsertDescription: Function,
  onBlurEstimateItemField: Function,
  onChangeEstimateItemField: Function,
  onChangeEstimateGroupName: Function,
  onMoveEstimateItem: Function,
  onToggleEstimateItem: Function
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      listData,
      serviceTypes,
      editable,
      selectedEstimateItemIds,
      onClickAddEstimateItem,
      onClickAddEstimateGroup,
      onClickRemoveEstimateItem,
      onClickRemoveEstimateGroup,
      onClickDuplicateEstimateItem,
      onClickDuplicateEstimateGroup,
      onClickInsertDescription,
      onBlurEstimateItemField,
      onChangeEstimateItemField,
      onChangeEstimateGroupName,
      onMoveEstimateItem,
      onToggleEstimateItem
    } = this.props;

    return (

      <List
        listData={listData}
        serviceTypes={serviceTypes}
        editable={editable}
        selectedEstimateItemIds={selectedEstimateItemIds}
        onClickAddEstimateItem={onClickAddEstimateItem}
        onClickAddEstimateGroup={onClickAddEstimateGroup}
        onClickRemoveEstimateItem={onClickRemoveEstimateItem}
        onClickRemoveEstimateGroup={onClickRemoveEstimateGroup}
        onClickDuplicateEstimateItem={onClickDuplicateEstimateItem}
        onClickDuplicateEstimateGroup={onClickDuplicateEstimateGroup}
        onClickInsertDescription={onClickInsertDescription}
        onBlurEstimateItemField={onBlurEstimateItemField}
        onChangeEstimateItemField={onChangeEstimateItemField}
        onChangeEstimateGroupName={onChangeEstimateGroupName}
        onMoveEstimateItem={onMoveEstimateItem}
        onToggleEstimateItem={onToggleEstimateItem}
      />

    )
  }
}