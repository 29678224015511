import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.css'

import Toolbar from './Components/Toolbar'
import Menu from './Components/Menu'
import List from './Components/List'
import EditorModal from './Components/EditorModal'
import UploadModal from './Components/UploadModal'

export default class FileManager extends React.PureComponent {

  static propTypes = {
    editorModal: PropTypes.object.isRequired,
    uploadModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onDragEnter: PropTypes.func.isRequired,
    onCloseEditorModal: PropTypes.func.isRequired,
    onCloseUploadModal: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render() {

    const {
      editorModal,
      uploadModal,
      onDragEnter,
      onCloseEditorModal,
      onCloseUploadModal,
    } = this.props

    return (
      
      <div
        onDragEnter={onDragEnter}
        onDragOver={event => event.preventDefault()}
        onDrop={event => event.preventDefault()}
        className={styles.wrapper}
      >

        <Toolbar />

        <div className={styles.body}>

          <Menu />

          <List />

        </div>

        {editorModal.show && (
          
          <EditorModal
            {...editorModal}
            onClose={onCloseEditorModal}
          />

        )}

        {uploadModal.show && (
          
          <UploadModal
            {...uploadModal}
            onClose={onCloseUploadModal}
          />

        )}

      </div>

    )

  }

}
