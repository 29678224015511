export default (subcontractor, job) => {
  
  const conflictingJobs = subcontractor.jobs.filter(row => {
    if (row.id === job.id) return false
    switch (row.slot) {
      case 'Day':
        return job.slot !== 'Night'
      case 'AM':
      case 'PM':
        return (job.slot === 'Day' || job.slot === row.slot)
      case 'Night':
        return job.slot === 'Night'
      default:
        return true
    }
  })

  if (conflictingJobs.length > 0) return true
  
  if (job.slot !== 'Night' && subcontractor.prev_night_jobs.length) return true

  return false

}
