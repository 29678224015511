
import { getSubcontractor } from '../../../HttpRequests/subcontractors';
import { loadSubcontractor } from '../State/subcontractor';
import handleException from '../../../Core/Helpers/handleException';
import { getProps } from '../props';

export default () => async (dispatch) => {

  try {
    
    const { id } = getProps().match.params;

    const params = {
      include: 'user',
    };

    const response = await getSubcontractor({ id, params })

    dispatch(loadSubcontractor( response.data.data ));

  } catch (error) {

    handleException(error);

  }
}