import { message } from 'antd'
import modalProps from '../modalProps'
import { createSubValuation, updateSubValuation } from '../../../HttpRequests/subValuations'
import handleException from '../../../Core/Helpers/handleException'

export default (values) => async () => {

  const { subValuation } = modalProps()

  const data = {
    subcontractor_id: values.subcontractor_id,
    project_id: values.project_id,
    user_id: values.user_id,
    date: values.date,
    to_date: values.to_date,
    notes: values.notes || null,
  }

  let response

  try {

    if (subValuation) {

      response = await updateSubValuation({ id: subValuation.id, data })

      message.success(`Updated "${response.data.reference}"`)

    } else {

      response = await createSubValuation({ data })

      message.success(`Created "${response.data.reference}"`)

    }

    modalProps().onClose(response.data)

  } catch (error) {

    handleException(error)

  }

}
