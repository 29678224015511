import { message } from 'antd'
import screenProps from '../screenProps'
import { updateSubValuationStatus } from '../../../HttpRequests/subValuations'
import fetch from './fetch'
import handleException from '../../../Core/Helpers/handleException'

export default (status) => async (dispatch, getState) => {

  const { id } = screenProps().match.params

  const { subValuation } = getState().screens.SubValuation

  const data = {
    status,
  }

  try {

    await updateSubValuationStatus({ id, data })

    message.success(`Updated "${subValuation.reference}"`)

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  }

}
