// @flow
import * as React from 'react';
import Screen from '../../Components/Screen';

import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import Filters from './Components/Filters';
import Table from './Components/Table';
import Footer from './Components/Footer';

type Props = {
  projects: [],
  projectTotals: [],
  projectPhases: [],
  projectStatuses: [],
  clients: [],
  users: [],
  pagination: {},
  filters: any,
  fetching: boolean,
  searchTerm: string,
  componentDidMount: () => void,
  onChangeSearch: () => void,
  onChangeTable: () => void,
  onChangeFilter: () => void,
  onSubmitSearch: () => void,
  onUpload: () => void,
  onClickExport: () => void
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount();


  render() {

    const {
      projects,
      projectTotals,
      projectPhases,
      projectStatuses,
      clients,
      users,
      pagination,
      filters,
      fetching,
      searchTerm, 
      onChangeSearch,
      onChangeTable,
      onChangeFilter,
      onSubmitSearch,
      onUpload,
      onClickExport

    } = this.props;

    return (

      <Screen>

        <Breadcrumbs />

        <Header
          searchTerm={searchTerm}
          onUpload={onUpload}
          onClickExport={onClickExport}
          onSubmitSearch={onSubmitSearch}
          onChangeSearch={onChangeSearch}
        />

        <Filters
          filters={filters}
          projectPhases={projectPhases}
          projectStatuses={projectStatuses}
          clients={clients}
          users={users}
          onChangeFilter={onChangeFilter}
        />

        <Table
          projects={projects}
          projectPhases={projectPhases}
          fetching={fetching}
          pagination={pagination}
          onChangeTable={onChangeTable}
        />

        <Footer
          projectTotals={projectTotals}
        />

      </Screen>

    )
  };
}