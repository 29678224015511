import React from 'react';
import { Field } from 'redux-form';
import { InputNumberField } from 'FormFields';

type Props = {
  submitting: boolean,
  serviceTypes: [],
}

const labelColSpan = 7;
const wrapperColSpan = 14;

export default class extends React.PureComponent<Props> {

  render () {

    const { submitting, serviceTypes } = this.props;

    return (

      <div>

        <p>
          Use the fields below to set set all target cost percentages by service type.
        </p>

        <strong>
          Note: If you leave a field blank/empty then the relevant items wont be overwitten.
        </strong>

        <div style={{ height: 25 }} />

        {serviceTypes.map(serviceType => (

          <Field
            key={serviceType.key}
            component={InputNumberField}
            name={`type${serviceType.key}`}
            label={`${serviceType.title} %`}
            disabled={submitting}
            autoComplete="off"          
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            fieldCol={{ span: 8 }}
            max={100}
            min={0}
          />

        ))}
        
        

      </div>

    )
  }
}