import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'

import { formConfig } from './Form'

import Component from './Component'

const mapStateToProps = () => ({})

const actionCreators = {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
}

export default connect(mapStateToProps, actionCreators)(reduxForm(formConfig)(Component))