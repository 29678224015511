import { message } from 'antd'
import { importClientOutstanding } from '../../../HttpRequests/files'
import fetchClients from './fetchClients'
import handleException from '../../../Core/Helpers/handleException'

export default (fileProps) => async (dispatch) => {

  const { file } = fileProps

  const data = new FormData()

  data.append('file', file)

  try {

    await importClientOutstanding({ data })

    await dispatch(fetchClients())

    message.success('Data imported successfully')

  } catch (error) {

    handleException(error)

  }

}
