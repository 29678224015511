import { setEditing } from '../../../State/editing'
import { setSubOrderGroups } from '../../../State/subOrder'
import { resetSelectedItems } from '../../../State/selectedItems'

export default () => (dispatch, getState) => {

  dispatch(setEditing(false))

  dispatch(setSubOrderGroups(getState().screens.SubOrder.originalItems))

  dispatch(resetSelectedItems())

}
