import React from 'react'
import { Modal } from 'antd'
import { ArrowDownOutlined } from '@ant-design/icons'
import pluralize from 'pluralize'
import adjust from '../../../Services/adjust'

export default (option) => (dispatch, getState) => {

  const { selected } = getState().modules.TimesheetsDrawer

  if (option === '30-min') {
    Modal.confirm({
      title: `Auto-Adjust ${selected.length} ${pluralize('Timesheet', selected.length)}`,
      content: `This will round each timesheet's hours to the nearest half an hour. If rounded down, an adjustment will be created with that amount. Are you sure you want to continue?`,
      icon: <ArrowDownOutlined style={{ color: '#000000d9' }} />,
      okText: 'Yes',
      width: 400,
      onOk: () => dispatch(adjust(option)),
    })
  }

  if (option === '10-hours') {
    Modal.confirm({
      title: `Auto-Adjust ${selected.length} ${pluralize('Timesheet', selected.length)}`,
      content: `This will round each timesheet's hours down to 10 hours. If a timesheet is below 10 hours, no action will be taken. Are you sure you want to continue?`,
      icon: <ArrowDownOutlined style={{ color: '#000000d9' }} />,
      okText: 'Yes',
      width: 400,
      onOk: () => dispatch(adjust(option)),
    })
  }

}
