import { createAction, handleActions } from 'redux-actions'

const setCurrentDirectoryContents = createAction('MODULES/FILE_MANAGER/SET_CURRENT_DIRECTORY_CONTENTS')
const resetCurrentDirectoryContents = createAction('MODULES/FILE_MANAGER/RESET_CURRENT_DIRECTORY_CONTENTS')

const defaultState = {
  directories: [],
  files: [],
}

const reducer = handleActions(
  {
    [setCurrentDirectoryContents]: (state, action) => action.payload,
    [resetCurrentDirectoryContents]: () => defaultState,
  },
  defaultState,
)

export { setCurrentDirectoryContents, resetCurrentDirectoryContents, reducer }
