import { connect } from 'react-redux';
import Component from './Component';

import componentWillMount from './Handlers/componentWillMount';
import onClickCancel from './Handlers/onClickCancel';
import onUpload from './Handlers/onUpload';
import onChange from './Handlers/onChange';

const mapStateToProps = (state) => ({
  uploading: state.modals.Upload.uploading
});

const actionCreators = {
  componentWillMount,
  onClickCancel,
  onUpload,
  onChange
};

export default connect(mapStateToProps, actionCreators)(Component);