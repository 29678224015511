// @flow

import * as React from 'react';
import numeral from 'numeral';
import moment from 'moment';

const styles = {

  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 25,
    borderTop: '1px solid #DDD'
  },

  total: {
    marginRight: 40
  },

  label: {
    fontSize: 12,
    fontWeight: 500,
    color: '#999'
  },

  value: {
    fontSize: 15,
    fontWeight: 500,
    color: '#444'
  }

}

type Props = {
  projectTotals: any,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      projectTotals
    } = this.props;

    return (

      <div style={styles.footer}>

        <div style={styles.total}>
          <div style={styles.label}>
            {`${moment().subtract(1, "month").startOf("month").format('MMMM')} Orders`}
          </div>
          <div style={styles.value}>
            {`£${numeral(projectTotals.orders_total_last_month).format('0,0.00')}`}
          </div>
        </div>

        <div style={styles.total}>
          <div style={styles.label}>
            {`${moment().subtract(1, "month").startOf("month").format('MMMM')} Valuations`}
          </div>
          <div style={styles.value}>
            {`£${numeral(projectTotals.valuations_total_last_month).format('0,0.00')}`}
          </div>
        </div>

        <div style={styles.total}>
          <div style={styles.label}>
            {`${moment().format('MMMM')} Orders`}
          </div>
          <div style={styles.value}>
            {`£${numeral(projectTotals.orders_total_this_month).format('0,0.00')}`}
          </div>
        </div>

        <div style={styles.total}>
          <div style={styles.label}>
            {`${moment().format('MMMM')} Valuations`}
          </div>
          <div style={styles.value}>
            {`£${numeral(projectTotals.valuations_total_this_month).format('0,0.00')}`}
          </div>
        </div>

        <div style={styles.total}>
          <div style={styles.label}>
            Remaining Orders
          </div>
          <div style={styles.value}>
            {`£${numeral(projectTotals.total_orders_remaining).format('0,0.00')}`}
          </div>
        </div>
        
      </div>

    )
  }
}