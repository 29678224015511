import { login } from '../HttpRequests/auth';
import { setToken } from '../Actions/token';

export default ({ username, email, password }) => async (dispatch) => {
  
  try {
    
    // authenticate
    
    const data = { username, email, password };
    
    const loginResponse = await login({ data });
    
    // save token
    
    const { token, url } = loginResponse.data;
    
    dispatch(setToken(token));

    window.location = url
    
    
  } catch (error) {
    
    throw error;
    
  }
}
