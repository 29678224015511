import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import { take, isEqual } from 'lodash'
import { getTeamUrl } from 'Common'

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  SoundOutlined,
  UndoOutlined,
} from '@ant-design/icons';

import { Table, Popover, Dropdown, Menu, Tooltip } from 'antd';
import { NavLink } from 'react-router-dom'
import styles from './Component.css'

import ServiceTypeIcons from '../../../../Components/ServiceTypeIcons'
import NotesPopover from './Components/NotesPopover'

export default class _Table_ extends React.PureComponent {

  static propTypes = {
    projects: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    onChangeTable: PropTypes.func.isRequired,
    onClickRecord: PropTypes.func.isRequired,
    onClickActionMenuItem: PropTypes.func.isRequired,
    onChangeProjectField: PropTypes.func.isRequired,
  }

  renderExpandedRow (row) {

    const {
      onClickRecord,
    } = this.props

    const columns = [

      { 
        title: 'No.', 
        key: 'id',
        sorter: false,
        width: 100,
        render: (text, record) => (
          <a onClick={() => onClickRecord(record.id, row)} role="presentation">
            {`RAMS ${record.id}`}
          </a>
        ),
      },
      
      {
        title: 'Contact',
        key: 'client_contact_id',
        sorter: false,
        render: (text, record) => record.client_contact && record.client_contact.full_name,
      },

      { 
        title: ' ', 
        key: 'notes',
        sorter: false,
        width: 35,
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } }),
      },
      
      {
        title: 'Valid From',
        key: 'valid_from',
        width: 150,
        sorter: false,
        render: (text, record) => record.valid_from ? moment(record.valid_from).format('DD MMM YYYY') : '',
      },
      
      {
        title: 'Valid To',
        key: 'valid_to',
        width: 150,
        sorter: false,
        render: (text, record) => record.valid_to ? moment(record.valid_to).format('DD MMM YYYY') : '',
      },
      
      {
        title: 'Services',
        key: 'services',
        width: 150,
        sorter: false,
        render: (text, record) => (
          <ServiceTypeIcons keys={record.services} />
        ),
      },
      
      {
        title: 'Access Equipment',
        key: 'access_equipment',
        sorter: false,
        render: (text, record) => (
          <Tooltip title={record.access_equipment.join(', ')}>
            {`${take(record.access_equipment, 2).join(', ')} ${record.access_equipment.length > 2 ? '...' : ''}`}
          </Tooltip>
        ),
      },
      
      {
        title: 'Paints',
        key: 'paints',
        sorter: false,
        render: (text, record) => (
          <Tooltip title={record.paints.join(', ')}>
            {`${take(record.paints, 2).join(', ')} ${record.paints.length > 2 ? '...' : ''}`}
          </Tooltip>
        ),
      }

    ]

    return (
      <React.Fragment>

        {row.rams_records.length === 0 && (
          <div className={styles.noRamsRecords}>
            No RAMS Records
          </div>
        )}

        {row.rams_records.length > 0 && (
          <Table
            dataSource={row.rams_records}
            columns={columns} 
            rowKey={record => record.id}
            pagination={false}
            size="small"
          />
        )}
        
      </React.Fragment>
      
    )
  }

  render () {

    const {
      projects,
      pagination,
      fetching,
      onChangeTable,
      onClickActionMenuItem,
      onChangeProjectField,
    } = this.props

    const actionMenu = (project) => (

      <Menu onClick={({ key }) => onClickActionMenuItem(key, project)}>

        <Menu.Item key="MUTE" disabled={project.rams_muted === 1}>
          <SoundOutlined />
          {' Mute'}
        </Menu.Item>

        <Menu.Item key="UNMUTE" disabled={project.rams_muted === 0}>
          <UndoOutlined />
          {' Unmute'}
        </Menu.Item>

      </Menu>
      
    )
    
    const columns = [

      { 
        title: 'Reference',
        key: 'id',
        sorter: true,
        render: (text, record) => (
          <NavLink to={getTeamUrl(`projects/${record.id}/view?tab=rams_records`)}>
            {`Project ${record.id}`}
          </NavLink>
        ),
      },
      
      {
        title: 'Client', 
        key: 'client.name',
        sorter: true,
        render: (text, record) => (
          <div>
            <div className="ellipsis" style={{ width: 220 }}>
              <strong>
                {record.client_site.client.name}
              </strong>
            </div>
            <div className="ellipsis" style={{ width: 220 }}>
              {record.client_site.name}
            </div>
          </div>
        ),
      },
      
      {
        title: 'Project Services',
        key: 'services',
        sorter: false,
        render: (text, record) => (
          <ServiceTypeIcons keys={Object.keys(record.item_type_summary).filter(key => key !== 'O')} />
        ),
      },

      {
        title: 'Status',
        key: 'rams_status',
        sorter: false,
        render: (text, record) => {
          
          const reasons = Object.keys(record.rams_insufficient_reasons)

          if (record.rams_insufficient === 0) {
            return (
              <div className={styles.status}>
                <CheckCircleOutlined className={classNames(styles.icon, styles.sufficient)} />
                Up to date
              </div>
            );
          }

          if (isEqual(reasons, ['Future'])) {
            return (
              <div className={styles.status}>
                <ExclamationCircleOutlined className={classNames(styles.icon, styles.warning)} />
                Future
              </div>
            );
          }
          
          return (
            <div className={styles.status}>
              <CloseCircleOutlined className={classNames(styles.icon, styles.insufficient)} />
              {reasons.join(', ')}
            </div>
          );

        },
      },
      
      {
        title: 'Issues',
        key: 'rams_insufficient_reasons',
        sorter: false,
        render: (text, record) => Object.keys(record.rams_insufficient_reasons).map(reason => (
          <div key={reason}>
            {`${reason}: ${record.rams_insufficient_reasons[reason].map(service => service.title).join(', ')}`}
          </div>
        )),
      },
      
      {
        title: 'Muted',
        key: 'rams_muted',
        sorter: false,
        render: (text, record) => record.rams_muted ? (
          <div className={styles.status}>
            <SoundOutlined className={classNames(styles.icon, styles.muted)} />
            Muted
          </div>
        ) : '',
      },

      {
        title: ' ',
        key: 'rams_notes',
        width: 50,
        sorter: false,
        render: (text, record) => (
          <NotesPopover
            notes={record.rams_notes}
            onChange={(value) => onChangeProjectField(record.id, 'rams_notes', value)}
          />
        ),
        onCell: () => ({ style: { padding: 0 } }),
      },

      {
        title: ' ', 
        key: 'actions',
        width: 100,
        sorter: false,
        render: (record) => (
          <Dropdown overlay={actionMenu(record)} trigger={['click']} placement="bottomRight">
            <a>
              {'Actions '}
              <DownOutlined />
            </a>
          </Dropdown>
        ),
      },

      {
        title: ' ', 
        key: 'spacer',
        width: 0,
        sorter: false,
        render: () => ''
      },

    ]
    
    return (

      <Table
        dataSource={projects}
        columns={columns} 
        expandedRowRender={(...args) => this.renderExpandedRow(...args)}
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination,
          style: { float: 'none', textAlign: 'center' },
        }}
      />

    )
  }
}