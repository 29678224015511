
import { createAction, handleActions } from 'redux-actions';

const setDirectoryActionType = createAction("SCREENS/PROJECT_VIEW/SET_DIRECTORY_ACTION_TPE");

const defaultState = 'new';

const reducer = handleActions(
  {
    [setDirectoryActionType]: (state, action) => action.payload
  },
  defaultState
);

export { setDirectoryActionType, reducer };