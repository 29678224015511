import React from 'react';
import { Field } from 'redux-form';
import { InputNumberField } from 'FormFields';

type Props = {
  submitting: boolean
}

const labelColSpan = 5;
const wrapperColSpan = 19;

export default class extends React.PureComponent<Props> {

  render () {

    const { submitting } = this.props;

    return (

      <div>

        <p>
          Enter the overall completion of the job below t automatically update all lines.
        </p>

        <strong>
          Note: Lines that are already at a higher percentage will remain as they are.
        </strong>

        <div style={{ height: 25 }} />
        
        <Field
          component={InputNumberField}
          name="valuation_completion"
          label="Completion %"
          disabled={submitting}
          autoComplete="off"          
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 8 }}
          max={100}
          min={0}
        />

      </div>

    )
  }
}