import { fromJS } from 'immutable';
import { loadOrders } from '../State/orders'

export default ({ orderIndex, orderGroupIndex, orderItemIndex, value }) => (dispatch, getState) => {

  let orders = fromJS(getState().screens.ProjectView.TargetCosts.orders);

  const orderItem = orders.getIn([orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex]).toJS();

  let targetCostPercentage = Number(value);
  targetCostPercentage = Number.isNaN(targetCostPercentage) ? 0 : targetCostPercentage; // reset to 0 if not a number
  targetCostPercentage = targetCostPercentage > 100 ? 100 : targetCostPercentage; // dont go above 100
  targetCostPercentage = targetCostPercentage < 0 ? 0 : targetCostPercentage; // dont go below 0
  targetCostPercentage = Number(targetCostPercentage.toFixed(6)); // round to 6 decimal spaces

  let targetCostAmount = (Number(orderItem.total) * targetCostPercentage) / 100;
  targetCostAmount = Number(targetCostAmount); // round to 2 decimal spaces

  orders = orders.updateIn(
    [orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex],
    map => map.merge(fromJS({
      target_cost_percentage: value,
      target_cost_amount: targetCostAmount.toFixed(2),
    }))
  );

  orders = orders.toJS();

  dispatch(loadOrders(orders));

}