/* eslint-disable no-restricted-globals */
import React from 'react'
import PropTypes from 'prop-types'
import { AlignLeftOutlined } from '@ant-design/icons'
import { find } from 'lodash'
import numeral from 'numeral'
import classNames from 'classnames'
import styles from './styles.css'

const getNumberValue = (subOrderItem, field, textOnly) => {
  if (textOnly) return '-'
  return (subOrderItem[field] || subOrderItem[field] === 0) ? numeral(subOrderItem[field]).format('0.00') : ''
}

class SubOrderItem extends React.PureComponent {

  static propTypes = {
    serviceTypes: PropTypes.array.isRequired,
    subOrderItem: PropTypes.object.isRequired,
    subValuationItem: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
    onUpdateField: PropTypes.func.isRequired,
  }

  state = {
    aggregate_perc: undefined,
    aggregate_total: undefined,

    focusedField: null,
  }

  onBlurInputNumber(subValuationItem, field, value) {
    this.props.onUpdateField(subValuationItem.guid, field, parseFloat(value) || 0)
    this.setState({ [field]: undefined, focusedField: null })
  }

  getInputNumberValue(subValuationItem, field, textOnly, suffix = '') {
    if (textOnly) return '-'
    if (this.state[field] !== undefined) {
      return `${this.state[field]}${suffix}`
    }
    return `${numeral(subValuationItem[field]).format('0.00')}${suffix}`
  }

  render() {

    const {
      serviceTypes,
      subOrderItem,
      subValuationItem,
      editing,
    } = this.props

    const serviceType = find(serviceTypes, type => type.key === subOrderItem.type)

    const textOnly = !subOrderItem.type

    return (

      <div className={styles.wrapper}>

        <div className={styles.lineNo}>
          {subOrderItem.line_no}
        </div>

        <div className={styles.table}>

          {/* SERVICE TYPE */}
          <div className={styles.cell} style={{ width: 45 }}>
            <div style={{ opacity: '50%', cursor: 'not-allowed' }}>
              {serviceType ? (
                <div
                  className={styles.serviceTypeIcon}
                  style={{ backgroundColor: serviceType.color_light, color: serviceType.color_dark }}
                >
                  {serviceType.key}
                </div>
              ) : (
                <AlignLeftOutlined />
              )}
            </div>
          </div>

          {/* DESCRIPTION */}
          <div className={classNames(styles.cell, styles.flexCell)}>
            <textarea
              value={subOrderItem.description || ''}
              readOnly
              className={classNames(styles.textarea, styles.readOnly)}
              style={{ pointerEvents: 'all', cursor: editing ? 'not-allowed' : 'default' }}
            />
          </div>

          {/* QUANTITY */}
          <div className={styles.cell}>
            <div className={classNames(styles.outlet, styles.readOnly)}>
              {getNumberValue(subOrderItem, 'quantity', textOnly)}
            </div>
          </div>

          {/* UNIT */}
          <div className={styles.cell}>
            <div className={classNames(styles.outlet, styles.readOnly)}>
              {textOnly ? '-' : (subOrderItem.unit || '')}
            </div>
          </div>

          {/* RATE */}
          <div className={styles.cell}>
            <div className={classNames(styles.outlet, styles.readOnly)}>
              {getNumberValue(subOrderItem, 'rate', textOnly)}
            </div>
          </div>

          {/* TOTAL */}
          <div className={styles.cell}>
            <div className={classNames(styles.outlet, styles.readOnly)}>
              {getNumberValue(subOrderItem, 'total', textOnly)}
            </div>
          </div>

          {/* PREVIOUS */}
          <div className={styles.cell}>
            <div className={styles.readOnly}>
              <div className={styles.halfCell}>
                <input
                  value={textOnly ? '-' : numeral(subValuationItem.aggregate_perc - subValuationItem.complete_perc).format('0.00')}
                  readOnly
                  className={styles.input}
                  style={{ cursor: 'not-allowed' }}
                />
              </div>
              <div className={styles.halfCell}>
                <input
                  value={textOnly ? '-' : numeral(subValuationItem.aggregate_total - subValuationItem.complete_total).format('0.00')}
                  readOnly
                  className={styles.input}
                  style={{ cursor: 'not-allowed' }}
                />
              </div>
            </div>
          </div>

          {/* NEW */}
          <div className={styles.cell}>
            <div>
              <div
                className={classNames(styles.halfCell, {
                  [styles.cellFocused]: this.state.focusedField === 'aggregate_perc',
                })}
              >
                <input
                  value={`${this.getInputNumberValue(subValuationItem, 'aggregate_perc', textOnly, '%')}`}
                  onChange={e => this.setState({ aggregate_perc: e.target.value.replace('%', '') })}
                  onFocus={() => !textOnly && this.setState({ focusedField: 'aggregate_perc' })}
                  onBlur={e => this.onBlurInputNumber(subValuationItem, 'aggregate_perc', e.target.value)}
                  className={styles.input}
                />
              </div>
              <div
                className={classNames(styles.halfCell, {
                  [styles.cellFocused]: this.state.focusedField === 'aggregate_total',
                })}
              >
                <input
                  value={this.getInputNumberValue(subValuationItem, 'aggregate_total', textOnly)}
                  onChange={e => this.setState({ aggregate_total: e.target.value })}
                  onFocus={() => !textOnly && this.setState({ focusedField: 'aggregate_total' })}
                  onBlur={e => this.onBlurInputNumber(subValuationItem, 'aggregate_total', e.target.value)}
                  className={styles.input}
                />
              </div>
            </div>
          </div>

          {/* DIFF */}
          <div className={styles.cell}>
            <div className={styles.readOnly}>
              <div className={styles.halfCell}>
                <input
                  value={textOnly ? '-' : numeral(subValuationItem.complete_perc).format('0.00')}
                  readOnly
                  className={styles.input}
                  style={{ cursor: 'not-allowed' }}
                />
              </div>
              <div className={styles.halfCell}>
                <input
                  value={textOnly ? '-' : numeral(subValuationItem.complete_total).format('0.00')}
                  readOnly
                  className={styles.input}
                  style={{ cursor: 'not-allowed' }}
                />
              </div>
            </div>
          </div>

        </div>

      </div>

    )

  }

}

export default SubOrderItem
