import React from 'react'
import { Modal } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import updateStatus from '../../../Services/updateStatus'

export default (status) => (dispatch, getState) => {

  const { subValuation } = getState().screens.SubValuation

  if (status === 'Pending') {
    Modal.confirm({
      title: 'Revert to "Pending"',
      content: 'Are you sure you want to revert this valuation\'s status to "Pending"?',
      icon: <WarningFilled style={{ color: 'orange' }} />,
      okText: 'Revert',
      onOk: () => dispatch(updateStatus(status)),
    })
    return
  }

  if (status === 'Approved' && subValuation.status === 'Paid') {
    Modal.confirm({
      title: 'Revert to "Approved"',
      content: 'Are you sure you want to revert this valuation\'s status to "Approved"?',
      icon: <WarningFilled style={{ color: 'orange' }} />,
      okText: 'Revert',
      onOk: () => dispatch(updateStatus(status)),
    })
    return
  }

  dispatch(updateStatus(status))

}
