
import { createAction, handleActions } from 'redux-actions';

const setShowSetTargetCostsModal = createAction("SCREENS/VALUATION_VIEW/SET_SHOW_SET_TAREGT_COSTS_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowSetTargetCostsModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowSetTargetCostsModal, reducer };