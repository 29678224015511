import { message } from 'antd'
import { updateSubValuationStatus } from '../../../../../HttpRequests/subValuations'
import fetch from './fetch'
import handleException from '../../../../../Core/Helpers/handleException'

export default (id, status) => async (dispatch) => {

  const data = {
    status,
  }

  try {

    const response = await updateSubValuationStatus({ id, data })

    message.success(`Updated "${response.data.reference}"`)

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  }

}
