import { getOrderFileUri } from '../../../HttpRequests/orders';

import handleException from '../../../Core/Helpers/handleException';


export default () => async (dispatch, getState) => {

  try {


    const { id } = getState().screens.OrderView.order;

    const response =  await getOrderFileUri({ id });
    window.location = response.data.data;


  } catch(error) {
    handleException(error);

  }
}