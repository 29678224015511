import { message } from 'antd';

import { getProps } from '../props';
import { deleteCaveat } from '../../../HttpRequests/caveats';
import handleException from '../../../Core/Helpers/handleException';

export default () => async () => {
  
  try {
  
    const { caveatId, onClose, didDelete } = getProps();

    await deleteCaveat({ id: caveatId });
    
    message.success("Caveat Deleted");

    onClose();
    
    if (didDelete) didDelete();

  } catch (error) {
    
    handleException(error);

  }
}