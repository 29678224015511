import { createAction, handleActions } from 'redux-actions';

const loadSubcontractor = createAction("SCREENS/SUBCONTRACTOR_VIEW/LOAD_SUBCONTRACTOR");
const updateSubcontractor = createAction("SCREENS/SUBCONTRACTOR_VIEW/UPDATE_SUBCONTRACTOR");
const clearSubcontractor = createAction("SCREENS/SUBCONTRACTOR_VIEW/CLEAR_SUBCONTRACTOR");

const defaultState = null;

const reducer = handleActions(
  {
    [loadSubcontractor]: (state, action) => action.payload,
    [updateSubcontractor]: (state, action) => Object.assign({}, state, {...action.payload}),
    [clearSubcontractor]: () => null
  },
  defaultState
);

export { loadSubcontractor, updateSubcontractor, clearSubcontractor, reducer };