import { connect } from 'react-redux'
import ProjectCellShrunk from './ProjectCellShrunk'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(ProjectCellShrunk)
