export default (values) => {

  const errors = {}

  if (!values.project_id) errors.project_id = true

  if (!values.start_time) errors.start_time = true

  return errors
  
}
