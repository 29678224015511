import { without, concat, find } from 'lodash';
import { setSelectedEstimateItemIds } from '../../../State/selectedEstimateItemIds';

export default ({ estimateGroupIndex, estimateItemIndex }) => async (dispatch, getState) => {

  const { selectedEstimateItemIds: ids } = getState().screens.EstimateView;

  const match = find(ids, { estimateGroupIndex, estimateItemIndex });

  const newIds = match ? without(ids, match) : concat(ids, { estimateGroupIndex, estimateItemIndex });

  dispatch(setSelectedEstimateItemIds(newIds));
}