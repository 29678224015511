import { createAction, handleActions } from 'redux-actions'

const setCheckedItemPaths = createAction('MODULES/FILE_MANAGER/SET_CHECKED_ITEM_PATHS')
const resetCheckedItemPaths = createAction('MODULES/FILE_MANAGER/RESET_CHECKED_ITEM_PATHS')

const defaultState = []

const reducer = handleActions(
  {
    [setCheckedItemPaths]: (state, action) => action.payload,
    [resetCheckedItemPaths]: () => defaultState,
  },
  defaultState,
)

export { setCheckedItemPaths, resetCheckedItemPaths, reducer }
