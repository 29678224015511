import { getValuationFileUri } from '../../../HttpRequests/valuations';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  try {

    const { id } = getState().screens.ValuationView.valuation;

    const response =  await getValuationFileUri({ id });
    
    window.location = response.data.data;

  } catch(error) {

    handleException(error);

  }
}