// @flow

import { setSelectedItemPath } from '../State/selectedItemPath';
import { setCurrentDirectoryPath } from '../State/currentDirectoryPath';
import fetchDirectory from '../Services/fetchDirectory';
import downloadFile from '../Services/downloadFile';

export default (type: string, path: string) => async (dispatch: Function) => {

  if (type === 'directory') {

    dispatch(setCurrentDirectoryPath(path))

    dispatch(setSelectedItemPath(null));

    dispatch(fetchDirectory())

  }

  if (type === 'file') {

    dispatch(downloadFile(path));
    
  }
}