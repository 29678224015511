import { createAction, handleActions } from 'redux-actions'

const loadSubcontractors = createAction('CORE/SUBCONTRACTORS/LOAD_SUBCONTRACTORS')
const clearSubcontractors = createAction('CORE/SUBCONTRACTORS/CLEAR_SUBCONTRACTORS')

const defaultState = []

const reducer = handleActions(
  {
    [loadSubcontractors]: (state, action) => action.payload,
    [clearSubcontractors]: () => defaultState,
  },
  defaultState,
)

export { loadSubcontractors, clearSubcontractors, reducer }
