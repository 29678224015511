import { connect } from 'react-redux'
import Component from './Component'

import onChangeTable from './Handlers/onChangeTable'
import onClickRecord from './Handlers/onClickRecord'
import onClickActionMenuItem from './Handlers/onClickActionMenuItem'

const mapStateToProps = (state) => ({
  retentionPayments: state.screens.RetentionPayments.retentionPayments,
  pagination: state.screens.RetentionPayments.pagination,
  fetching: state.screens.RetentionPayments.fetching,
})

const actionCreators = {
  onChangeTable,
  onClickRecord,
  onClickActionMenuItem,
}

export default connect(mapStateToProps, actionCreators)(Component)
