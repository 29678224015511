import { message } from 'antd'
import { getProps } from '../props';
import { createClientContact } from '../../../HttpRequests/clientContacts';

export default (values) => async () => {

  const data = {
    client_id: getProps().clientId,
    ...values
  }

  const response = await createClientContact({ data });

  const clientContact = response.data.data;

  message.success('Contact Created');

  return clientContact;

}