import { connect } from 'react-redux'
import EditorModal from './EditorModal'

import componentDidMount from './Handlers/componentDidMount'
import onClickSubmit from './Handlers/onClickSubmit'

const mapStateToProps = (state) => ({
  selectedItemType: state.modules.FileManager.selectedItemType,
})

const actionCreators = {
  componentDidMount,
  onClickSubmit,
}

export default connect(mapStateToProps, actionCreators)(EditorModal)
