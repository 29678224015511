import { combineReducers } from 'redux'

import { reducer as data } from './State/data'
import { reducer as isFetching } from './State/isFetching'
import { reducer as selected } from './State/selected'
import { reducer as timesheetModal } from './State/timesheetModal'
import { reducer as timesheetAdjustmentModal } from './State/timesheetAdjustmentModal'

export default combineReducers({
  data,
  isFetching,
  selected,
  timesheetModal,
  timesheetAdjustmentModal,
})
