import { createSelector } from 'reselect';
import menuItems from '../Misc/menuItems';

const selectPath = state => state.router.location.pathname;

export default createSelector(
  selectPath,
  (path) => {

    const matches = menuItems.filter(menuItem => path.startsWith(menuItem.path));

    return matches.length > 0 ? matches[0].path : null;
    
  }
)