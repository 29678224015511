import { setMode } from '../../../State/mode'
import { resetData } from '../../../State/data'
import { resetSortBy } from '../../../State/sortBy'
import fetch from '../../../Services/fetch'

export default (value) => (dispatch) => {
  
  dispatch(setMode(value))

  dispatch(resetData())

  dispatch(resetSortBy())

  dispatch(fetch())

}
