import modalProps from '../modalProps'
import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors'
import fetchProjects from '../../../Core/Services/fetchProjects'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetchSubOrders from '../../../Core/Services/fetchSubOrders'
import initialize from '../Services/initialize'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchSubcontractors())
  dispatch(fetchProjects())
  dispatch(fetchUsers())
  dispatch(fetchSubOrders())

  dispatch(initialize())

}
