import { connect } from 'react-redux'
import Menu from './Menu'

import onClickRootDirectory from './Handlers/onClickRootDirectory'

const mapStateToProps = (state) => ({
  rootDirectories: state.modules.FileManager.rootDirectories,
  currentDirectoryPath: state.modules.FileManager.currentDirectoryPath,
})

const actionCreators = {
  onClickRootDirectory,
}

export default connect(mapStateToProps, actionCreators)(Menu)
