import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import { Table, Tag } from 'antd'
import { NavLink } from 'react-router-dom'
import { getTeamUrl } from 'Common'
import styles from './Component.css'

export default class extends React.PureComponent {

  static propTypes = {
    invoice: PropTypes.object.isRequired,
  }

  render () {

    const {
      invoice,
    } = this.props

    const column = [

      {
        title: 'Invoice',
        key: 'number',
        width: 250,
        render: (text, record) => (
          <span>
            <NavLink key={record.id} to={getTeamUrl(`invoices/${record.id}/view`)}>
              {record.title}
            </NavLink>
            {invoice.id === record.id && <Tag color="blue" style={{ marginLeft: 20 }}>Current</Tag>}
          </span>
        ),
      },

      {
        title: 'PO No',
        key: 'reference',
        width: 250,
        render: (text, record) => record.order ? record.order.reference : '--',
      },

      {
        title: 'Total',
        key: 'total',
        width: 250,
        render: (text, record) => `£${numeral(record.subtotal).format('0,0.00')}`,
      },

    ]

    return (

      <React.Fragment>
        
        <Table
          dataSource={invoice.batch.filter(row => row.id !== invoice.id)}
          columns={column}
          rowKey={record => record.id}
          pagination={false}
          bordered
          title={() => (
            <div className={styles.title}>
              Related Invoices
            </div>
          )}
        />

      </React.Fragment>

    )
  }
}