import { createSelector } from 'reselect'
import { find } from 'lodash'

const selectSubValuation = state => state.screens.SubValuation.subValuation
const selectSubOrders = state => state.screens.SubValuation.subOrders

export default createSelector(
  selectSubValuation,
  selectSubOrders,
  (subValuation, subOrders) => {

    const subOrderGroupIds = subValuation.sub_valuation_items.map(row => row.sub_order_item.sub_order_group_id)
    const subOrderItemIds = subValuation.sub_valuation_items.map(row => row.sub_order_item_id)

    const rows = []

    let lineNo = 1
    
    subOrders.forEach(subOrder => {

      rows.push({
        component: 'SubOrder',
        height: 70,
        subOrder,
      })

      subOrder.sub_order_groups.forEach(subOrderGroup => {

        if (!subOrderGroupIds.includes(subOrderGroup.id)) return

        rows.push({
          component: 'SubOrderGroup',
          height: 60,
          subOrderGroup,
        })

        subOrderGroup.sub_order_items.forEach(subOrderItem => {

          if (!subOrderItemIds.includes(subOrderItem.id)) return

          rows.push({
            component: 'SubOrderItem',
            height: 50,
            subOrderItem: {
              ...subOrderItem,
              line_no: subOrderItem.type ? lineNo : null,
            },
            subValuationItem: find(subValuation.sub_valuation_items, row => row.sub_order_item_id === subOrderItem.id),
          })

          if (subOrderItem.type) lineNo += 1

        })

      })

      rows.push({
        component: 'SubOrderFooter',
        height: 70,
        subOrder,
      })

      rows.push({
        component: 'Spacer',
        height: 20,
      })

    })

    return rows

  },
)
