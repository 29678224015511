// @flow

import * as React from 'react';
import { Col, Input, Button, Row, Radio } from 'antd'

type Props = {
  activeTab: string,
  searchTerm: string,

  onChangeTab: Function,
  onClickNewValuation: Function,
  onClickNewValuationDate: Function,
  onChangeSearch: Function
}


export default class extends React.PureComponent<Props> {

  render() {

    const {
      activeTab,
      searchTerm,
      onChangeTab,
      onClickNewValuation,
      onClickNewValuationDate,
      onChangeSearch
    } = this.props;

    const tabs = (
      <Radio.Group value={activeTab} onChange={e => onChangeTab(e.target.value)}>
        <Radio.Button value='valuations'>Valuations</Radio.Button>
        <Radio.Button value='schedule'>Schedule</Radio.Button>
      </Radio.Group>
    )

    return (

      <Row style={{ marginBottom: 15 }}>

        {activeTab === 'valuations' && (

          <React.Fragment>

            <Col span={12}>

              <Row gutter={12}>

                <Col>{tabs}</Col>

                <Col>

                  <Input.Search
                    style={{ width: 250 }}
                    placeholder="Search"
                    onChange={onChangeSearch}
                    value={searchTerm}
                  />

                </Col>

              </Row>

            </Col>

            <Col span={12} className="text-right">

              <Button type="primary" onClick={onClickNewValuation}>
                New Valuation
              </Button>

            </Col>

          </React.Fragment>

        )}

        {activeTab === 'schedule' && (

          <React.Fragment>

            <Col span={12}>{tabs}</Col>

            <Col span={12} className="text-right">

              <Button type="primary" onClick={() => onClickNewValuationDate()}>
                Add Date
              </Button>

            </Col>

          </React.Fragment>

        )}

      </Row>
    )
  }
}