import React from 'react'
import PropTypes from 'prop-types'
import { Button, DatePicker, Input, Popover, Select } from 'antd'
import { FilterOutlined, CloudDownloadOutlined, MinusCircleOutlined, CheckCircleFilled } from '@ant-design/icons'
import styles from './styles.css'

const Spacer = () => <div style={{ width: 12 }} />

const Box = props => <div className={styles.box}>{props.children}</div>

const Label = props => <div className={styles.label}>{props.text}</div>

const Header = props => {

  const {
    subcontractors,
    projects,
    users,
    search,
    filters,
    isFiltered,
    onChangeSearch,
    onSearch,
    onChangeFilter,
    onClickExport,
    onClickNew,
  } = props

  const popover = (
    <div className={styles.filters}>
      <Box>
        <Label text='Subcontractor' />
        <Select
          value={filters.subcontractor_id}
          onChange={value => onChangeFilter('subcontractor_id', value)}
          mode='multiple'
          showSearch
          optionFilterProp='children'
          placeholder='All Subcontractors'
          style={{ width: '100%' }}
        >
          {subcontractors.map(subcontractor => (
            <Select.Option key={subcontractor.id} value={subcontractor.id}>
              {subcontractor.name}
            </Select.Option>
          ))}
        </Select>
      </Box>
      <Box>
        <Label text='Project' />
        <Select
          value={filters.project_id}
          onChange={value => onChangeFilter('project_id', value)}
          mode='multiple'
          showSearch
          optionFilterProp='children'
          placeholder='All Projects'
          style={{ width: '100%' }}
        >
          {projects.map(project => (
            <Select.Option key={project.id} value={project.id}>
              {`[${project.id}] ${project.client_site.client.name} - ${project.client_site.name}`}
            </Select.Option>
          ))}
        </Select>
      </Box>
      <Box>
        <Label text='Contracts Manager' />
        <Select
          value={filters.contracts_manager_id}
          onChange={value => onChangeFilter('contracts_manager_id', value)}
          mode='multiple'
          showSearch
          optionFilterProp='children'
          placeholder='All Users'
          style={{ width: '100%' }}
        >
          {users.map(user => (
            <Select.Option key={user.id} value={user.id}>
              {user.full_name}
            </Select.Option>
          ))}
        </Select>
      </Box>
      <Box>
        <Label text='Date' />
        <DatePicker.RangePicker
          value={filters.date_range}
          onChange={value => onChangeFilter('date_range', value || [])}
          format='DD/MM/YYYY'
          placeholder={['Start Date', 'End Date']}
          style={{ width: '100%' }}
        />
      </Box>
      <Box>
        <Label text='To Date' />
        <DatePicker.RangePicker
          value={filters.to_date_range}
          onChange={value => onChangeFilter('to_date_range', value || [])}
          format='DD/MM/YYYY'
          placeholder={['Start Date', 'End Date']}
          style={{ width: '100%' }}
        />
      </Box>
      <Box>
        <Label text='Owner' />
        <Select
          value={filters.user_id}
          onChange={value => onChangeFilter('user_id', value)}
          mode='multiple'
          showSearch
          optionFilterProp='children'
          placeholder='All Users'
          style={{ width: '100%' }}
        >
          {users.map(user => (
            <Select.Option key={user.id} value={user.id}>
              {user.full_name}
            </Select.Option>
          ))}
        </Select>
      </Box>
      <Box>
        <Label text='Status' />
        <Select
          value={filters.status}
          onChange={value => onChangeFilter('status', value)}
          mode='multiple'
          dropdownMatchSelectWidth={false}
          placeholder='All Statuses'
          style={{ width: '100%' }}
        >
          <Select.Option key='Pending' value='Pending'>
            <MinusCircleOutlined style={{ fontSize: 15, color: '#999999' }} />
            {' Pending'}
          </Select.Option>
          <Select.Option key='Approved' value='Approved'>
            <CheckCircleFilled style={{ fontSize: 15, color: 'orange' }} />
            {' Approved'}
          </Select.Option>
          <Select.Option key='Paid' value='Paid'>
            <CheckCircleFilled style={{ fontSize: 15, color: 'limegreen' }} />
            {' Paid'}
          </Select.Option>
        </Select>
      </Box>
    </div>
  )

  return (

    <div className={styles.controls}>

      <div className={styles.left}>

        <Input.Search
          value={search}
          onChange={e => onChangeSearch(e.target.value)}
          onSearch={onSearch}
          placeholder='Search...'
          style={{ width: 250 }}
        />

        <Spacer />

        <Popover
          trigger={['click']}
          placement='bottom'
          content={popover}
        >
          <Button type={isFiltered && 'primary'} icon={<FilterOutlined />}>Filter</Button>
        </Popover>

      </div>

      <div className={styles.right}>

        <Button onClick={onClickExport}>
          <CloudDownloadOutlined style={{ fontSize: 16, marginTop: 3 }} />
        </Button>

        <Spacer />

        <Button type='primary' onClick={onClickNew}>New Valuation</Button>

      </div>

    </div>

  )

}

Box.propTypes = {
  children: PropTypes.node.isRequired,
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
}

Header.propTypes = {
  subcontractors: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  isFiltered: PropTypes.bool.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onClickExport: PropTypes.func.isRequired,
  onClickNew: PropTypes.func.isRequired,
}

export default Header
