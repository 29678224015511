import { message } from 'antd'
import pluralize from 'pluralize'
import { updateManyTimesheetStatuses } from '../../../HttpRequests/timesheets'
import fetch from './fetch'
import { resetSelected } from '../State/selected'
import handleException from '../../../Core/Helpers/handleException'

export default (status) => async (dispatch, getState) => {

  const { selected } = getState().modules.TimesheetsDrawer

  const ids = selected.map(key => Number(key.split('|')[1]))

  const data = {
    ids,
    status,
  }

  try {

    await updateManyTimesheetStatuses({ data })

    message.success(`Marked ${selected.length} ${pluralize('timesheet', selected.length)} as ${status}`)

    dispatch(fetch())

    dispatch(resetSelected())

  } catch (error) {

    handleException(error)

  }

}
