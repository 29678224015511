import { connect } from 'react-redux';
import Component from './Component';

import onClickActionMenuItem from './Handlers/onClickActionMenuItem';
import onUpload from './Handlers/onUpload';
import onChangeNotes from './Handlers/onChangeNotes';
import onChangeEstimateField from './Handlers/onChangeEstimateField';

const mapStateToProps = (state) => ({
  estimate: state.screens.EstimateView.estimate,
  estimateStatuses: state.core.estimateStatuses,
  estimateTenderStatuses: state.core.estimateTenderStatuses,
  estimateLatestActions: state.core.estimateLatestActions,
})

const actionCreators = {
  onClickActionMenuItem,
  onUpload,
  onChangeNotes,
  onChangeEstimateField
};

export default connect(mapStateToProps, actionCreators)(Component);