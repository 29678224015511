import { fromJS } from 'immutable';
import { loadValuation } from '../State/valuation';

export default () => (dispatch, getState) => {

  let valuation = fromJS(getState().screens.ValuationView.valuation);

  valuation.get('orders').forEach((order,orderIndex) =>
    order.get('order_groups').forEach((orderGroup,orderGroupIndex) =>
      orderGroup.get('order_items').forEach((orderItem,orderItemIndex) => {

        const valuationItem = valuation.getIn([
          'orders',orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex,'valuation_item'
        ]).toJS();

        valuation = valuation.updateIn(
          ['orders',orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex,'valuation_item'],
          map => map.merge(fromJS({
            aggregate_percentage: Number(valuationItem.aggregate_percentage),
            complete_percentage: Number(valuationItem.complete_percentage),
          }))
        );

      })
    )
  );

  valuation = valuation.toJS();

  dispatch(loadValuation(valuation));

}