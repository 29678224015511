import { setShowDirectoryModal } from '../State/showDirectoryModal';
import { setDirectoryActionType } from '../State/directoryActionType';
import onCickDelete from './onClickDelete';
import downloadFile from '../Services/downloadFile';

export default ({ key }, path) => async (dispatch) => {

  switch (key) {
    
    default:
      break;
    
    case 'RENAME_DIRECTORY':
      dispatch(setShowDirectoryModal(true));
      dispatch(setDirectoryActionType('edit'));
      break;
    
    case 'DELETE_DIRECTORY':
      dispatch(onCickDelete());
      break;
    
    case 'RENAME_FILE':
      dispatch(setShowDirectoryModal(true));
      dispatch(setDirectoryActionType('edit'));
      break;
    
    case 'DELETE_FILE':
      dispatch(onCickDelete());
      break;
    
    case 'DOWNLOAD_FILE':
      dispatch(downloadFile(path));
      break;

  }

}