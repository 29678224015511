import moment from 'moment'
import { setPeriodIndex, setDayOfWeek } from '../../../State/controls'
import fetch from '../../../Services/fetch'

export default date => (dispatch) => {

  const now = moment()

  const dateIsoWeekday = date.isoWeekday()
  const nowIsoWeekday = now.isoWeekday()

  const diff = dateIsoWeekday - nowIsoWeekday

  const startOfWeek = now.add(diff, 'days')

  let weekDiff = date.diff(startOfWeek, 'week')

  if (date > now) {
    weekDiff += 1
  }

  if (diff > 0) {
    weekDiff += 1
  }

  dispatch(setPeriodIndex(weekDiff))

  dispatch(setDayOfWeek(date.format('ddd')))

  dispatch(fetch())

}
