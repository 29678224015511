import { Modal } from 'antd'
import deleteTimesheetAdjustment from '../Services/delete'

export default () => (dispatch) => {

  Modal.confirm({
    title: `Delete Adjustment`,
    content: 'Are you sure you want to delete this adjustment?',
    okText: 'Yes',
    width: 400,
    onOk: () => dispatch(deleteTimesheetAdjustment()),
  })

}
