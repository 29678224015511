import { connect } from 'react-redux'
import FileManager from './FileManager'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onDragEnter from './Handlers/onDragEnter'
import onCloseEditorModal from './Handlers/onCloseEditorModal'
import onCloseUploadModal from './Handlers/onCloseUploadModal'

const mapStateToProps = (state) => ({
  editorModal: state.modules.FileManager.editorModal,
  uploadModal: state.modules.FileManager.uploadModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onDragEnter,
  onCloseEditorModal,
  onCloseUploadModal,
}

export default connect(mapStateToProps, actionCreators)(FileManager)
