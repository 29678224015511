import { change } from 'redux-form';
import fetchClients from '../../../Core/Services/fetchClients';
import { FORM_NAME } from '../Form';

export default (client) => async (dispatch) => {

  await dispatch(fetchClients({ force: true }));

  dispatch(change(FORM_NAME, 'client_id', client.id))
  
}