import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import Component from './Component';

import selectEstimates from './Selectors/selectEstimates';
import selectClientContacts from './Selectors/selectClientContacts';
import selectClientId from './Selectors/selectClientId';
import selectEstimateId from './Selectors/selectEstimateId';
import selectShowNewClientContactButton from './Selectors/selectShowNewClientContactButton';

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import didCreateProject from './Handlers/didCreateProject';
import didCreateClientContact from './Handlers/didCreateClientContact';
import didSelectEstimateOptionals from './Handlers/didSelectEstimateOptionals';
import onClickCancel from './Handlers/onClickCancel';
import onClickSubmit from './Handlers/onClickSubmit';
import onChangeProject from './Handlers/onChangeProject';
import onChangeEstimate from './Handlers/onChangeEstimate';
import onClickNewProject from './Handlers/onClickNewProject';
import onClickNewClientContact from './Handlers/onClickNewClientContact';
import onCloseProjectModal from './Handlers/onCloseProjectModal';
import onCloseClientContactModal from './Handlers/onCloseClientContactModal';
import onCloseSelectEstimateOptionalsModal from './Handlers/onCloseSelectEstimateOptionalsModal';

import { FORM_NAME } from './Form';

const mapStateToProps = (state) => ({
  users: state.core.users,
  projects: state.core.projects,
  estimates: selectEstimates(state),
  clientContacts: selectClientContacts(state),
  clientId: selectClientId(state),
  estimateId: selectEstimateId(state),
  submitting: isSubmitting(FORM_NAME)(state),
  showNewClientContactButton: selectShowNewClientContactButton(state),  
  showProjectModal: state.modals.Order.showProjectModal,
  showClientContactModal: state.modals.Order.showClientContactModal,
  showSelectEstimateOptionalsModal: state.modals.Order.showSelectEstimateOptionalsModal,
});

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  didCreateProject,
  didCreateClientContact,
  didSelectEstimateOptionals,
  onClickCancel,
  onClickSubmit,
  onChangeProject,
  onChangeEstimate,
  onClickNewProject,
  onClickNewClientContact,
  onCloseProjectModal,
  onCloseClientContactModal,
  onCloseSelectEstimateOptionalsModal
};

export default connect(mapStateToProps, actionCreators)(Component);