import handleException from '../../../Core/Helpers/handleException';
import { getCaveats } from '../../../HttpRequests/caveats';
import { setFetching } from '../State/fetching';
import { loadCaveats } from '../State/caveats';
import { setPagination } from '../State/pagination';

export default () => async (dispatch, getState) => {

  const { pagination, searchTerm } = getState().screens.Caveats;

  try {

    const params = {};
    
    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    if (searchTerm) {
      params.search = searchTerm;
    }
    
    params.csort =  '-id';

    dispatch(setFetching(true));

    const response = await getCaveats({ params });
    const caveats = response.data;

    dispatch(loadCaveats(caveats.data));
    
    dispatch(setPagination({
      total: caveats.pagination.total,
      pageSize: caveats.pagination.per_page,
      current: caveats.pagination.current_page
    }));

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error);

    dispatch(setFetching(false))
  }
}