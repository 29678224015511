import { message } from 'antd'
import { updateTargetCosts } from '../../../../../HttpRequests/projects';
import { setEditable } from '../State/editable';
import fetchProject from '../../../Services/fetchProject';
import fetchOrders from './fetchOrders';
import handleException from '../../../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {
  
  try {
    
    const { project, TargetCosts: { orders } } = getState().screens.ProjectView;

    const data = {
      project_id: project.id,
      total_cost: Number(project.total_cost).toFixed(2) || 0,
      order_items: [],
    };

    orders.forEach(order => order.order_groups.forEach(orderGroup => orderGroup.order_items.forEach(orderItem => {

      data.order_items.push({
        id: orderItem.id,
        target_cost_percentage: orderItem.target_cost_percentage,
        target_cost_amount: orderItem.target_cost_amount,
      })

    })))

    await updateTargetCosts({ data });

    dispatch(setEditable(false));

    message.success("Targets Updated");
  
    await dispatch(fetchProject());
  
    await dispatch(fetchOrders());

  } catch (error) {

    handleException(error);

    throw error;
    
  }

}