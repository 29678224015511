import React from 'react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import { get } from 'lodash'

const SubcontractorDrop = props => {

  const {
    children,
    onDrop,
  } = props

  const extract = (x, y) => {
    const elements = document.elementsFromPoint(x, y)
    for (let i = 0; i <= elements.length; i += 1) {
      const id = get(elements[i], 'id')
      if (id) {
        const [projectId, date] = id.split('|')
        if (projectId && date) {
          return { projectId, date }
        }
      }
    }
    return null
  }
  
  const [collectedProps, drop] = useDrop(() => ({
    accept: 'SUBCONTRACTOR',
    drop: (item, monitor) => {
      const { x, y } = monitor.getClientOffset()
      const values = extract(x, y)
      if (!values) return
      const { jobs, subcontractor } = item
      const { projectId, date } = values
      onDrop(jobs, subcontractor, projectId, date)
    },
  }))
  
  return (

    <div ref={drop}>{children}</div>
    
  )

}

SubcontractorDrop.propTypes = {
  children: PropTypes.node.isRequired,
  onDrop: PropTypes.func.isRequired,
}

export default SubcontractorDrop
