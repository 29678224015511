// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';

export type UpdateEstimateCaveatFieldParams = {
  estimateCaveatIndex: number,
  field: string,
  value: string|null
}

export default (params: UpdateEstimateCaveatFieldParams) => (dispatch: Function, getState: Function) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);

  estimate = estimate.setIn(
    ['estimate_caveats', params.estimateCaveatIndex, params.field],
    params.value
  )

  estimate = estimate.toJS();

  dispatch(loadEstimate(estimate));

}