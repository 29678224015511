import chance from 'chance'
import { setSubOrderGroups } from '../State/subOrder'

export default () => (dispatch, getState) => {

  const { subOrder } = getState().screens.SubOrder

  const newSubOrderGroups = []

  subOrder.sub_order_groups.forEach(subOrderGroup => newSubOrderGroups.push({ ...subOrderGroup }))

  const newSubOrderGroup = {
    id: undefined,
    guid: chance().guid(),
    sub_order_items: [],
  }

  newSubOrderGroups.push(newSubOrderGroup)

  dispatch(setSubOrderGroups(newSubOrderGroups))

}
