import { createAction, handleActions } from 'redux-actions'

const setSubValuation = createAction('SCREENS/SUB_VALUATION/SET_SUB_VALUATION')
const setSubValuationItems = createAction('SCREENS/SUB_VALUATION/SET_SUB_VALUATION_ITEMS')
const resetSubValuation = createAction('SCREENS/SUB_VALUATION/RESET_SUB_VALUATION')

const defaultState = null

const reducer = handleActions(
  {
    [setSubValuation]: (state, { payload }) => payload,
    [setSubValuationItems]: (state, { payload }) => ({ ...state, sub_valuation_items: payload }),
    [resetSubValuation]: () => defaultState,
  },
  defaultState,
)

export { setSubValuation, setSubValuationItems, resetSubValuation, reducer }
