// @flow
import * as React from 'react';
import { WindowScroller, AutoSizer } from 'react-virtualized';
import SortableList from './SortableList';
import GroupHeader from './GroupHeader';
import GroupItem from './GroupItem';
import GroupPlaceholder from './GroupPlaceholder';
import GroupFooter from './GroupFooter';
import Footer from './Footer';
import Placeholder from './Placeholder';

import type { MoveOrderItemParams } from '../../../types';

type Props = {
  listData: [],
  serviceTypes: [],
  editable: boolean,
  selectedOrderItemIds: [],
  onClickAddOrderItem: () => void,
  onClickAddOrderGroup: () => void,
  onClickRemoveOrderItem: () => void,
  onClickRemoveOrderGroup: () => void,
  onClickDuplicateOrderItem: () => void,
  onClickDuplicateOrderGroup: () => void,
  onBlurOrderItemField: () => void,
  onChangeOrderItemField: () => void,
  onChangeOrderGroupName: () => void,
  onMoveOrderItem: (params: MoveOrderItemParams) => void,
  onToggleOrderItem: () => void
};

type RowProps = {
  index: number,
  key: any,
  style: {}
};

export default class extends React.PureComponent<Props> {

  componentDidUpdate = () => this.SortableContainer.getWrappedInstance().List.recomputeRowHeights();

  SortableContainer: any;

  renderRow = (rowProps: RowProps) => {

    const {
      index,
      key,
      style
    } = rowProps;

    const {
      listData,
      serviceTypes,
      editable,
      selectedOrderItemIds,
      onClickAddOrderItem,
      onClickAddOrderGroup,
      onClickRemoveOrderItem,
      onClickRemoveOrderGroup,
      onClickDuplicateOrderItem,
      onClickDuplicateOrderGroup,
      onBlurOrderItemField,
      onChangeOrderItemField,
      onChangeOrderGroupName,
      onToggleOrderItem
    } = this.props;

    const {
      type,
      orderGroup,
      orderGroupIndex,
      orderItem,
      orderItemIndex
    } = listData[index];

    if (type === 'GroupHeader') {
      return (
        <div style={style} key={key}>
          <GroupHeader
            index={index}
            editable={editable}
            orderGroup={orderGroup}
            orderGroupIndex={orderGroupIndex}
            onChangeOrderGroupName={onChangeOrderGroupName}
          />
        </div>
      )
    }

    if (type === 'GroupItem') {
      return (
        <div style={style} key={key}>
          <GroupItem
            index={index}
            disabled={!editable} // prevents drag when not edit mode
            editable={editable}
            selectedOrderItemIds={selectedOrderItemIds}
            orderItem={orderItem}
            orderItemIndex={orderItemIndex}
            orderGroup={orderGroup}
            orderGroupIndex={orderGroupIndex}
            serviceTypes={serviceTypes}
            onClickAddOrderItem={onClickAddOrderItem}
            onClickRemoveOrderItem={onClickRemoveOrderItem}
            onClickDuplicateOrderItem={onClickDuplicateOrderItem}
            onChangeOrderItemField={onChangeOrderItemField}
            onBlurOrderItemField={onBlurOrderItemField}
            onToggleOrderItem={onToggleOrderItem}
          />
        </div>
      )
    }

    if (type === 'GroupPlaceholder') {
      return (
        <div style={style} key={key}>
          <GroupPlaceholder
            index={index}
            editable={editable}
            orderGroupIndex={orderGroupIndex}
            onClickAddOrderItem={onClickAddOrderItem}
          />
        </div>
      )
    }

    if (type === 'GroupFooter') {

      return (
        <div style={style} key={key}>
          <GroupFooter
            index={index}
            editable={editable}
            orderGroup={orderGroup}
            orderGroupIndex={orderGroupIndex}
            onClickAddOrderItem={onClickAddOrderItem}
            onClickDuplicateOrderGroup={onClickDuplicateOrderGroup}
            onClickRemoveOrderGroup={onClickRemoveOrderGroup}
          />
        </div>
      )
    }

    if (type === 'Footer') {

      return (
        <div style={style} key={key}>
          <Footer
            index={index}
            editable={editable}
            onClickAddOrderGroup={onClickAddOrderGroup}
          />
        </div>
      )
    }

    if (type === 'Placeholder') {

      return (
        <div style={style} key={key}>
          <Placeholder
            index={index}
            editable={editable}
            onClickAddOrderGroup={onClickAddOrderGroup}
          />
        </div>
      )
    }    

    return null;
  }

  render () {

    const {
      listData,
      onMoveOrderItem
    } = this.props;

    return (

      <div style={{ }}>

        <WindowScroller>
          {({ height, scrollTop }) => (

            <AutoSizer disableHeight>
              {({ width }) => (

                <SortableList
                  autoHeight
                  height={height}
                  width={width}
                  scrollTop={scrollTop}
                  lockAxis="y"
                  overscanRowCount={5}
                  useDragHandle
                  rowHeight={({ index }) => listData[index].height}
                  rowRenderer={this.renderRow}
                  rowCount={listData.length}
                  ref={ref => { this.SortableContainer = ref }}
                  onSortEnd={onMoveOrderItem}
                />

              )}
            </AutoSizer>
            
          )}
        </WindowScroller>

      </div>

    )
  }
  
}