// @flow

import * as React from 'react';
import { WindowScroller, AutoSizer } from 'react-virtualized';
import SortableList from './SortableList';
import Header from './Header';
import Item from './Item';
import Placeholder from './Placeholder';
import Footer from './Footer';

type Props = {
  order: any,
  listData: [],
  serviceTypes: [],
  editable: boolean,
  onClickAddOrderCaveat: () => void,
  onClickRemoveOrderCaveat: () => void,
  onClickDuplicateOrderCaveat: () => void,
  onChangeOrderCaveatField: () => void,
  onMoveOrderCaveat: () => void,
};

type RowProps = {
  index: number,
  key: any,
  style: {}
};

export default class extends React.PureComponent<Props> {

  componentDidUpdate = () => this.SortableContainer.getWrappedInstance().List.recomputeRowHeights();

  SortableContainer: any;

  renderRow = (rowProps: RowProps) => {

    const {
      index,
      key,
      style
    } = rowProps;

    const {
      order,
      listData,
      serviceTypes,
      editable,
      onClickAddOrderCaveat,
      onClickRemoveOrderCaveat,
      onClickDuplicateOrderCaveat,
      onChangeOrderCaveatField
    } = this.props;

    const {
      type,
      orderCaveat,
      orderCaveatIndex
    } = listData[index];

    if (type === 'Header') {
      return (
        <div style={style} key={key}>
          <Header
            index={index}
            editable={editable}
          />
        </div>
      )
    }

    if (type === 'Item') {
      return (
        <div style={style} key={key}>
          <Item
            index={index}
            disabled={!editable} // prevents drag when not edit mode
            editable={editable}
            orderCaveat={orderCaveat}
            orderCaveatIndex={orderCaveatIndex}
            serviceTypes={serviceTypes}
            onClickAddOrderCaveat={onClickAddOrderCaveat}
            onClickRemoveOrderCaveat={onClickRemoveOrderCaveat}
            onClickDuplicateOrderCaveat={onClickDuplicateOrderCaveat}
            onChangeOrderCaveatField={onChangeOrderCaveatField}
          />
        </div>
      )
    }

    if (type === 'Placeholder') {

      return (
        <div style={style} key={key}>
          <Placeholder
            index={index}
            editable={editable}
            onClickAddOrderCaveat={onClickAddOrderCaveat}
          />
        </div>
      )
    }

    if (type === 'Footer') {
      return (
        <div style={style} key={key}>
          <Footer
            index={index}
            order={order}
            editable={editable}
            onClickAddOrderCaveat={onClickAddOrderCaveat}
          />
        </div>
      )
    }

    return null;
  }

  render () {

    const {
      listData,
      onMoveOrderCaveat
    } = this.props;

    return (

      <div style={{ }}>

        <WindowScroller>
          {({ height, scrollTop }) => (

            <AutoSizer disableHeight>
              {({ width }) => (

                <SortableList
                  autoHeight
                  height={height}
                  width={width}
                  scrollTop={scrollTop}
                  lockAxis="y"
                  overscanRowCount={5}
                  useDragHandle
                  rowHeight={({ index }) => listData[index].height}
                  rowRenderer={this.renderRow}
                  rowCount={listData.length}
                  ref={ref => { this.SortableContainer = ref }}
                  onSortEnd={onMoveOrderCaveat}
                />

              )}
            </AutoSizer>
            
          )}
        </WindowScroller>

      </div>

    )
  }
  
}