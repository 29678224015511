import { message } from 'antd'
import screenProps from '../screenProps'
import { handleSubValuationItems } from '../../../HttpRequests/subValuations'
import fetch from './fetch'
import { setSaving } from '../State/saving'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { id } = screenProps().match.params

  const { subValuation } = getState().screens.SubValuation

  const data = subValuation.sub_valuation_items

  dispatch(setSaving(true))

  try {

    await handleSubValuationItems({ id, data })
    
    message.success(`Updated "${subValuation.reference}"`)

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setSaving(false))

  }

}
