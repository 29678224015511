// @flow

import * as React from 'react';
import styles from './GroupHeader.css';

import type {
  OrderGroup
} from '../../../types';

type Props = {
  orderGroup: OrderGroup
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      orderGroup
    } = this.props;

    return (

      <div className={styles.wrapper}>

        <div className={styles.name}>
          {orderGroup.name}
        </div>

        <div className={styles.headings}>

          <div className={styles.cell}>
            Qty
          </div>

          <div className={styles.cell}>
            Unit
          </div>

          <div className={styles.cell}>
            D/Rate
          </div>

          <div className={styles.cell}>
            Total
          </div>

          <div className={styles.cell}>
            Flag
          </div>

          <div style={{ width: 16 }} />

          <div className={styles.cell} style={{ width: 100 }}>
            Prev
          </div>

          <div className={styles.cell} style={{ width: 100 }}>
            New
          </div>

          <div className={styles.cell} style={{ width: 100 }}>
            Diff
          </div>
          
        </div>

      </div>

    )
  }
}