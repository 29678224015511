import { formValueSelector, change } from 'redux-form';
import { without } from 'lodash'
import { FORM_NAME } from '../Form';

export default (path) => (dispatch, getState) => {

  let projectFiles = formValueSelector(FORM_NAME)(getState(), 'projectFiles');

  projectFiles = without(projectFiles, path);
  
  dispatch(change(FORM_NAME, 'projectFiles', projectFiles))

}