import { connect } from 'react-redux'
import SubOrderGroup from './SubOrderGroup'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(SubOrderGroup)
