import { uploadEstimateFile } from '../../../HttpRequests/estimates';
import handleException from '../../../Core/Helpers/handleException';
import fetchEstimate from './fetchEstimate';

export default (files: FileList) => async (dispatch, getState) => {

  try {
    
    const { id } = getState().screens.EstimateView.estimate;
    
    const formdata = new FormData();
    
    formdata.append('file', files[0]);

    await uploadEstimateFile({ id, data: formdata });

    dispatch(fetchEstimate());

  } catch(error) {

    handleException(error);

  }
}