import React from 'react'
import PropTypes from 'prop-types'

const TimesheetPostingSpan = ({ timesheetPosting, nowColor, fontSize }) => (
  <div style={{ fontSize, color: '#9e9e9e' }}>
    {`${timesheetPosting.start_date_time.substr(11, 5)}`}
    {` - `}
    <span style={{ color: !timesheetPosting.end_date_time && nowColor }}>
      {`${timesheetPosting.end_date_time ? timesheetPosting.end_date_time.substr(11, 5) : 'now'}`}
    </span>
  </div>
)

TimesheetPostingSpan.propTypes = {
  timesheetPosting: PropTypes.object.isRequired,
  nowColor: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
}

TimesheetPostingSpan.defaultProps = {
  fontSize: 11,
}

export default TimesheetPostingSpan
