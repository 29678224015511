// @flow
import * as React from 'react';

export default () => {

  let info;

  if (window.__ENV__.RELEASE) {
    info = `Release: ${window.__ENV__.RELEASE}`;
  } else if (window.__ENV__.BUILD) {
    info = `Build: ${window.__ENV__.BUILD}`;
  } else {
    info = `Local Development`;
  }

  return (
    <div className="_Portal_LoginScreen_VersionInfo_footer">
      <div className="_Portal_LoginScreen_VersionInfo_tag">
        {info}
      </div>
    </div>
  )
}

