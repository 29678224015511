
import { createAction, handleActions } from 'redux-actions';

const setShowSelectItemDescriptionModal = createAction("SCREENS/ESTIMATE_VIEW/SET_SHOW_SELECT_ITEM_DESCRIPTION_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowSelectItemDescriptionModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowSelectItemDescriptionModal, reducer };