import { createAction, handleActions } from 'redux-actions'

const openSubcontractorCellModal = createAction('SCREENS/PLANNER/OPEN_SUBCONTRACTOR_CELL_MODAL')
const closeSubcontractorCellModal = createAction('SCREENS/PLANNER/CLOSE_SUBCONTRACTOR_CELL_MODAL')

const defaultState = {
  show: false,
  period: null,
  subcontractor: null,
  _jobs: [],
  _plannerCell: null,
}

const reducer = handleActions(
  {
    [openSubcontractorCellModal]: (state, action) => ({
      show: true,
      period: action.payload.period,
      subcontractor: action.payload.subcontractor,
      _jobs: action.payload.jobs,
      _plannerCell: action.payload.plannerCell,
    }),
    [closeSubcontractorCellModal]: () => defaultState,
  },
  defaultState,
)

export { openSubcontractorCellModal, closeSubcontractorCellModal, reducer }
