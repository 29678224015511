import React from 'react'
import { Modal } from 'antd'
import { CheckCircleFilled, MinusCircleOutlined } from '@ant-design/icons'
import pluralize from 'pluralize'
import updateStatus from '../../../Services/updateStatus'

export default (status) => (dispatch, getState) => {

  const { selected } = getState().modules.TimesheetsDrawer

  if (status === 'pending') {
    Modal.confirm({
      title: `Mark ${selected.length} ${pluralize('Timesheet', selected.length)} as Pending`,
      content: 'Are you sure you want to mark the selected timesheets as "Pending"?',
      icon: <MinusCircleOutlined style={{ color: '#9e9e9e' }} />,
      okText: 'Yes',
      width: 400,
      onOk: () => dispatch(updateStatus(status)),
    })
  }

  if (status === 'approved') {
    Modal.confirm({
      title: `Mark ${selected.length} ${pluralize('Timesheet', selected.length)} as Approved`,
      content: 'Are you sure you want to mark the selected timesheets as "Approved"?',
      icon: <CheckCircleFilled style={{ color: 'orange' }} />,
      okText: 'Yes',
      width: 400,
      onOk: () => dispatch(updateStatus(status)),
    })
  }

  if (status === 'paid') {
    Modal.confirm({
      title: `Mark ${selected.length} ${pluralize('Timesheet', selected.length)} as Paid`,
      content: 'Are you sure you want to mark the selected timesheets as "Paid"?',
      icon: <CheckCircleFilled style={{ color: 'limegreen' }} />,
      okText: 'Yes',
      width: 400,
      onOk: () => dispatch(updateStatus(status)),
    })
  }

}
