/* eslint-disable no-restricted-globals */
import React from 'react'
import PropTypes from 'prop-types'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import { Dropdown, Menu, Popover } from 'antd'
import { AlignLeftOutlined, CaretDownOutlined, CopyOutlined, DeleteOutlined, DragOutlined, MessageFilled, MessageOutlined, PlusOutlined } from '@ant-design/icons'
import { find, includes } from 'lodash'
import numeral from 'numeral'
import classNames from 'classnames'
import styles from './styles.css'

import SubOrderItemNotes from './Components/SubOrderItemNotes'

const Drag = SortableHandle(props => (
  <span {...props}>
    {props.children}
  </span>
))

class SubOrderItem extends React.PureComponent {

  static propTypes = {
    serviceTypes: PropTypes.array.isRequired,
    subOrderItem: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
    selectedItems: PropTypes.array.isRequired,
    onToggleItem: PropTypes.func.isRequired,
    onUpdateField: PropTypes.func.isRequired,
    onActionItem: PropTypes.func.isRequired,
  }

  state = {
    description: undefined,
    quantity: undefined,
    rate: undefined,

    focusedField: null,

    hoveredDrag: false,
    hoveredNotes: false,
    hoveredActions: false,
  }

  onBlurInputNumber(subOrderItem, field, value) {
    const $value = (value || value === 0) ? parseFloat(value) : null
    this.props.onUpdateField(subOrderItem.guid, field, $value)
    this.setState({ [field]: undefined, focusedField: null })
  }

  getInputNumberValue(subOrderItem, field, textOnly) {
    if (textOnly) return '-'
    if (this.state[field] !== undefined) {
      return this.state[field]
    }
    return (subOrderItem[field] || subOrderItem[field] === 0) ? numeral(subOrderItem[field]).format('0.00') : ''
  }

  render() {

    const {
      serviceTypes,
      subOrderItem,
      editing,
      selectedItems,
      onToggleItem,
      onUpdateField,
      onActionItem,
    } = this.props

    const serviceType = find(serviceTypes, type => type.key === subOrderItem.type)

    const textOnly = !subOrderItem.type

    return (

      <div className={styles.wrapper}>

        <Drag
          onMouseEnter={() => this.setState({ hoveredDrag: true })}
          onMouseLeave={() => this.setState({ hoveredDrag: false })}
          className={styles.drag}
          style={{ cursor: 'grab' }}
        >
          {this.state.hoveredDrag ? (
            <DragOutlined className={styles.dragIcon} />
          ) : (
            <div className={styles.lineNo}>
              {subOrderItem.line_no}
            </div>
          )}
        </Drag>

        <div className={styles.table}>

          {/* CHECKBOX */}
          {editing && (
            <div className={styles.cell} style={{ width: 30 }}>
              <input
                type='checkbox'
                checked={includes(selectedItems, subOrderItem.guid)}
                onChange={() => onToggleItem(subOrderItem.guid)}
              />
            </div>
          )}

          {/* SERVICE TYPE */}
          <div className={styles.cell} style={{ width: 45 }}>
            {serviceType ? (
              <div
                className={styles.serviceTypeIcon}
                style={{ backgroundColor: serviceType.color_light, color: serviceType.color_dark }}
              >
                {serviceType.key}
              </div>
            ) : (
              <AlignLeftOutlined />
            )}
            <select
              value={subOrderItem.type || 'text-only'}
              onChange={e => onUpdateField(subOrderItem.guid, 'type', e.target.value)}
              className={styles.select}
            >
              <optgroup label='Service Types'>
                {serviceTypes.map(row => (
                  <option key={row.key} value={row.key}>
                    {row.title}
                  </option>
                ))}
              </optgroup>
              <optgroup label='Other'>
                <option key='text-only' value='text-only'>
                  Text Only
                </option>
              </optgroup>
            </select>
          </div>

          {/* DESCRIPTION */}
          <div
            className={classNames(styles.cell, styles.flexCell, {
              [styles.cellFocused]: this.state.focusedField === 'description',
            })}
          >
            <textarea
              value={this.state.description !== undefined ? this.state.description : (subOrderItem.description || '')}
              onChange={e => this.setState({ description: e.target.value })}
              onFocus={() => this.setState({ focusedField: 'description' })}
              onBlur={e => {
                onUpdateField(subOrderItem.guid, 'description', e.target.value)
                this.setState({ description: undefined, focusedField: null })
              }}
              disabled={!editing}
              className={styles.textarea}
              style={{ pointerEvents: 'all' }}
            />
          </div>

          {/* QUANTITY */}
          <div
            className={classNames(styles.cell, {
              [styles.cellFocused]: this.state.focusedField === 'quantity',
            })}
          >
            <input
              value={this.getInputNumberValue(subOrderItem, 'quantity', textOnly)}
              onChange={e => this.setState({ quantity: e.target.value })}
              onFocus={() => !textOnly && this.setState({ focusedField: 'quantity' })}
              onBlur={e => this.onBlurInputNumber(subOrderItem, 'quantity', e.target.value)}
              className={styles.input}
            />
          </div>

          {/* UNIT */}
          <div className={styles.cell}>
            <div className={styles.outlet}>
              {textOnly ? '-' : (subOrderItem.unit || '')}
            </div>
            {!textOnly && (
              <select
                value={subOrderItem.unit || undefined}
                onChange={e => onUpdateField(subOrderItem.guid, 'unit', e.target.value)}
                className={styles.select}
              >
                {['m', 'm2', 'Item', 'Hours', 'Days', 'No.', 'PSUM', 'NR'].map(unit => (
                  <option value={unit} key={unit}>
                    {unit}
                  </option>
                ))}
              </select>
            )}
          </div>

          {/* RATE */}
          <div
            className={classNames(styles.cell, {
              [styles.cellFocused]: this.state.focusedField === 'rate',
            })}
          >
            <input
              value={this.getInputNumberValue(subOrderItem, 'rate', textOnly)}
              onChange={e => this.setState({ rate: e.target.value })}
              onFocus={() => !textOnly && this.setState({ focusedField: 'rate' })}
              onBlur={e => this.onBlurInputNumber(subOrderItem, 'rate', e.target.value)}
              className={styles.input}
            />
          </div>

          {/* TOTAL */}
          <div className={styles.cell}>
            <div className={classNames(styles.outlet, styles.readOnly)}>
              {textOnly ? '-' : numeral(subOrderItem.total).format('0.00')}
            </div>
          </div>

        </div>

        <div className={styles.actions}>

          <span
            onMouseEnter={() => this.setState({ hoveredNotes: true })}
            onMouseLeave={() => this.setState({ hoveredNotes: false })}
            style={{ pointerEvents: 'all' }}
          >
            <Popover
              title='Notes'
              trigger='click'
              content={(
                <SubOrderItemNotes
                  subOrderItem={subOrderItem}
                />
              )}
            >
              {subOrderItem.notes ? (
                <MessageFilled className={styles.notesIcon} style={{ color: '#40a9ff' }} />
              ) : editing && <MessageOutlined className={styles.notesIcon} style={{ color: this.state.hoveredNotes && '#40a9ff' }} />}
            </Popover>
          </span>

          {editing && (
            <Dropdown
              trigger={['click']}
              overlay={(
                <Menu>
                  <Menu.Item key='add' icon={<PlusOutlined />} onClick={() => onActionItem(subOrderItem.guid, 'add')}>
                    Add
                  </Menu.Item>
                  <Menu.Item key='duplicate' icon={<CopyOutlined />} onClick={() => onActionItem(subOrderItem.guid, 'duplicate')}>
                    Duplicate
                  </Menu.Item>
                  <Menu.Item key='remove' icon={<DeleteOutlined />} onClick={() => onActionItem(subOrderItem.guid, 'remove')}>
                    Remove
                  </Menu.Item>
                </Menu>
              )}
            >
              <CaretDownOutlined
                onMouseEnter={() => this.setState({ hoveredActions: true })}
                onMouseLeave={() => this.setState({ hoveredActions: false })}
                className={styles.actionsIcon}
                style={{ color: this.state.hoveredActions && '#40a9ff' }}
              />
            </Dropdown>
          )}

        </div>

      </div>

    )

  }

}

export default SortableElement(SubOrderItem)
