import { connect } from 'react-redux'
import { isSubmitting, formValueSelector } from 'redux-form'

import onChangeType from './Handlers/onChangeType'

import { FORM_NAME } from '../../Form'

import Component from './Component'

const mapStateToProps = (state) => ({
  submitting: isSubmitting(FORM_NAME)(state),
  type: formValueSelector(FORM_NAME)(state, 'type')
})

const actionCreators = {
  onChangeType
}

export default connect(mapStateToProps, actionCreators)(Component)