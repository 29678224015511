import React from 'react'
import PropTypes from 'prop-types'
import { FaSprayCan } from 'react-icons/fa'

const SprayerFilledIcon = ({ style, ...restProps }) => (
  <FaSprayCan
    {...restProps}
    style={{
      position: 'relative',
      ...style,
    }}
  />
)

SprayerFilledIcon.propTypes = {
  style: PropTypes.object,
}

SprayerFilledIcon.defaultProps = {
  style: {},
}

export default SprayerFilledIcon
