// @flow

import { setShowProjectModal } from '../State/showProjectModal';
import { setShowEstimateModal } from '../State/showEstimateModal';
import { setShowOrderModal } from '../State/showOrderModal';
import { setShowValuationModal } from '../State/showValuationModal';
import { setShowJobSheetModal } from '../State/showJobSheetModal';
import { setShowProjectCompleteModal } from '../State/showProjectCompleteModal';
import { openRamsRecordModal } from '../State/ramsRecordModal';
import { setShowEmailModal } from '../State/showEmailModal';

import onUncompleteProject from './onUncompleteProject';
import onDeleteProject from './onDeleteProject';
import downloadOrderSummary from '../Services/downloadOrderSummary';
import exportTargetCostsPdf from '../Components/TargetCosts/Services/exportTargetCostsPdf';

export default (key: string) => async (dispatch: Function) => {

  switch (key) {
    
    default:
      break;
    
    case 'EDIT':
      dispatch(setShowProjectModal(true));
      break;
    
    case 'COMPLETE':
      dispatch(setShowProjectCompleteModal(true))
      break;
    
    case 'UNCOMPLETE':
      dispatch(onUncompleteProject(true))
      break;

    case 'JOB_SHEET_PDF':
      dispatch(setShowJobSheetModal(true));
      break;

    case 'ORDER_SUMMARY_PDF':
      dispatch(downloadOrderSummary('pdf'));
      break;

    case 'ORDER_SUMMARY_EXCEL':
      dispatch(downloadOrderSummary('excel'));
      break;

    case 'TARGET_COSTS_PDF':
      dispatch(exportTargetCostsPdf())
      break;

    case 'PROJECT_INFORMATION_EMAIL':
      dispatch(setShowEmailModal(true))
      break;

    case 'CREATE_ESTIMATE':
      dispatch(setShowEstimateModal(true));
      break;
    
    case 'CREATE_ORDER':
      dispatch(setShowOrderModal(true));
      break;
    
    case 'CREATE_VALUATION':
      dispatch(setShowValuationModal(true));
      break;
    
    case 'CREATE_RAMS':
      dispatch(openRamsRecordModal());
      break;
    
    case 'DELETE':
      dispatch(onDeleteProject());
      break;

  }

}