// @flow

import * as React from 'react';
import { WindowScroller, AutoSizer } from 'react-virtualized';
import SortableList from './SortableList';
import Header from './Header';
import Item from './Item';
import Placeholder from './Placeholder';
import Footer from './Footer';

type Props = {
  estimate: any,
  listData: [],
  serviceTypes: [],
  editable: boolean,
  onClickAddEstimateOptional: () => void,
  onClickRemoveEstimateOptional: () => void,
  onClickDuplicateEstimateOptional: () => void,
  onChangeEstimateOptionalField: () => void,
  onMoveEstimateOptional: () => void,
};

type RowProps = {
  index: number,
  key: any,
  style: {}
};

export default class extends React.PureComponent<Props> {

  componentDidUpdate = () => this.SortableContainer.getWrappedInstance().List.recomputeRowHeights();

  SortableContainer: any;

  renderRow = (rowProps: RowProps) => {

    const {
      index,
      key,
      style
    } = rowProps;

    const {
      estimate,
      listData,
      serviceTypes,
      editable,
      onClickAddEstimateOptional,
      onClickRemoveEstimateOptional,
      onClickDuplicateEstimateOptional,
      onChangeEstimateOptionalField
    } = this.props;

    const {
      type,
      estimateOptional,
      estimateOptionalIndex
    } = listData[index];

    if (type === 'Header') {
      return (
        <div style={style} key={key}>
          <Header
            index={index}
            editable={editable}
          />
        </div>
      )
    }

    if (type === 'Item') {
      return (
        <div style={style} key={key}>
          <Item
            index={index}
            disabled={!editable} // prevents drag when not edit mode
            editable={editable}
            estimateOptional={estimateOptional}
            estimateOptionalIndex={estimateOptionalIndex}
            serviceTypes={serviceTypes}
            onClickAddEstimateOptional={onClickAddEstimateOptional}
            onClickRemoveEstimateOptional={onClickRemoveEstimateOptional}
            onClickDuplicateEstimateOptional={onClickDuplicateEstimateOptional}
            onChangeEstimateOptionalField={onChangeEstimateOptionalField}
          />
        </div>
      )
    }

    if (type === 'Placeholder') {

      return (
        <div style={style} key={key}>
          <Placeholder
            index={index}
            editable={editable}
            onClickAddEstimateOptional={onClickAddEstimateOptional}
          />
        </div>
      )
    }

    if (type === 'Footer') {
      return (
        <div style={style} key={key}>
          <Footer
            index={index}
            estimate={estimate}
            editable={editable}
            onClickAddEstimateOptional={onClickAddEstimateOptional}
          />
        </div>
      )
    }

    return null;
  }

  render () {

    const {
      listData,
      onMoveEstimateOptional
    } = this.props;

    return (

      <div style={{ }}>

        <WindowScroller>
          {({ height, scrollTop }) => (

            <AutoSizer disableHeight>
              {({ width }) => (

                <SortableList
                  autoHeight
                  height={height}
                  width={width}
                  scrollTop={scrollTop}
                  lockAxis="y"
                  overscanRowCount={5}
                  useDragHandle
                  rowHeight={({ index }) => listData[index].height}
                  rowRenderer={this.renderRow}
                  rowCount={listData.length}
                  ref={ref => { this.SortableContainer = ref }}
                  onSortEnd={onMoveEstimateOptional}
                />

              )}
            </AutoSizer>
            
          )}
        </WindowScroller>

      </div>

    )
  }
  
}