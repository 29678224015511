import { connect } from 'react-redux';
import Component from './Component';

import selectSelectedKey from './Selectors/selectSelectedKey';

import onSelect from './Handlers/onSelect';

const mapStateToProps = (state) => ({
  selectedKey: selectSelectedKey(state)
})

const actionCreators = {
  onSelect
};

export default connect(mapStateToProps, actionCreators)(Component);