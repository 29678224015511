import { uploadOrderMedia } from '../../../HttpRequests/orders'
import fetchOrder from './fetchOrder'
import handleException from '../../../Core/Helpers/handleException'

export default (media) => async (dispatch, getState) => {
    
  const { id } = getState().screens.OrderView.order
  
  const formData = new FormData()
  
  formData.append('file', media[0])

  try {

    await uploadOrderMedia({ id, data: formData })

    dispatch(fetchOrder())

  } catch(error) {

    handleException(error)

  }

}
