exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3yx5t-8Yp90EtLk1ku6O50 tbody tr {\n  cursor: pointer;\n}\n\n._3yx5t-8Yp90EtLk1ku6O50 .ant-table-row-level-1 {\n  background-color: #f3fbff !important;\n}\n\n._3yx5t-8Yp90EtLk1ku6O50 .ant-table-row-level-1 .ant-checkbox-wrapper {\n  visibility: hidden !important;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Modules/TimesheetsDrawer/Components/Table/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;CACjB;;AAED;EACE,qCAAqC;CACtC;;AAED;EACE,8BAA8B;CAC/B","file":"styles.css","sourcesContent":[".table tbody tr {\n  cursor: pointer;\n}\n\n.table :global(.ant-table-row-level-1) {\n  background-color: #f3fbff !important;\n}\n\n.table :global(.ant-table-row-level-1 .ant-checkbox-wrapper) {\n  visibility: hidden !important;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"table": "_3yx5t-8Yp90EtLk1ku6O50"
};