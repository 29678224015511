import { createAction, handleActions } from 'redux-actions';

const loadUsers = createAction("CORE/USERS/LOAD_USERS");
const clearUsers = createAction("CORE/USERS/CLEAR_USERS");

const defaultState = []

const reducer = handleActions(
  {
    [loadUsers]: (state, action) => action.payload,
    [clearUsers]: () => []
  },
  defaultState
);

export { loadUsers, clearUsers, reducer };