import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextAreaField } from 'FormFields';

const labelColSpan = 4;
const wrapperColSpan = 17;

export default class extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
  }

  render () {

    const { submitting } = this.props;

    return (
      
      <React.Fragment>

        <Field
          name="description"
          label="Description"
          disabled={submitting}
          component={TextAreaField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          style={{ height: 200 }}
          required
        />

      </React.Fragment>

    )
  }
}