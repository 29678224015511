// @flow
import * as React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import styles from './GroupHeader.css';

import type {
  EstimateGroup,
  UpdateEstimateGroupNameParams
} from '../../../types';

type Props = {
  editable: boolean,
  estimateGroupIndex: number,
  estimateGroup: EstimateGroup,
  onChangeEstimateGroupName: (params: UpdateEstimateGroupNameParams) => void
}

class Component extends React.PureComponent<Props> {

  render () {

    const {
      editable,
      estimateGroupIndex,
      estimateGroup,
      onChangeEstimateGroupName
    } = this.props;

    return (

      <div className={styles.wrapper}>

        <input
          className={styles.name}
          value={estimateGroup.name || ''}
          onChange={(event) => onChangeEstimateGroupName({ estimateGroupIndex, name: event.target.value })}
          disabled={!editable}
          placeholder="Untitled Group"
        />

        {estimateGroup.estimate_items.length > 0 && (

          <div className={styles.headings}>

            <div className={styles.cell}>
              Qty
            </div>

            <div className={styles.cell}>
              Unit
            </div>

            <div className={styles.cell}>
              Rate
            </div>

            <div className={styles.cell}>
              Subtotal
            </div>

            <div className={styles.cell}>
              Discount
            </div>

            <div className={styles.cell}>
              Total
            </div>

            <div className={styles.cell}>
              Flag
            </div>
            
          </div>

        )}

      </div>

    )
  }
}

export default SortableElement(Component);