import { message } from 'antd'
import { omit } from '../../../HttpRequests/planner'
import fetch from './fetch'
import handleException from '../../../Core/Helpers/handleException'

export default (projectId) => async (dispatch) => {

  try {

    await omit({ projectId })

    message.success(`Unsubscribed from project "${projectId}"`)

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  }

}
