import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Row, Col, Tag } from 'antd'
import numeral from 'numeral'
import { getTeamUrl } from 'Common'
import styles from './styles.css'

const Section = props => (
  <div className={styles.section}>
    {props.children}
  </div>
)

const Outlet = props => (
  <div className={styles.outlet}>
    {props.children}
  </div>
)

const Label = props => (
  <div className={styles.label}>
    {props.children}
  </div>
)

const Value = props => (
  <div className={styles.value}>
    {props.children}
  </div>
)

const Sidebar = props => {

  const {
    subcontractor,
  } = props

  const gbp = value => `${value < 0 ? '-' : ''}£${numeral(Math.abs(value)).format('0,0.00')}`

  return (
    
    <div className={styles.wrapper}>

      <Section>

        <Outlet>
          <Label>Subcontractor</Label>
          <Value>
            <NavLink to={getTeamUrl(`subcontractors/${subcontractor.id}/view`)}>
              {subcontractor.name}
            </NavLink>
          </Value>
        </Outlet>

        <Outlet>
          <Label>Marquee ID</Label>
          <Value>{subcontractor.group || '--'}</Value>
        </Outlet>

        <Outlet>
          <Label>Telephone</Label>
          <Value>{subcontractor.telephone || '--'}</Value>
        </Outlet>

        <Outlet>
          <Label>Email</Label>
          <Value>{subcontractor.email || '--'}</Value>
        </Outlet>

        <Outlet>
          <Label>Address</Label>
          <Value>
            {[
              subcontractor.address_line_1,
              subcontractor.address_line_2,
              subcontractor.address_line_3,
              subcontractor.town_or_city,
              subcontractor.county,
              subcontractor.postcode
            ].filter(line => line).join(', ') || '-'}
          </Value>
        </Outlet>

        <Outlet>
          <Label>Card Type</Label>
          <Value>
            <Tag
              color={subcontractor.card_type !== 'White' && subcontractor.card_type}
            >
              {subcontractor.card_type}
            </Tag>
          </Value>
        </Outlet>

        <Outlet>
          <Label>Status</Label>
          <Value>{subcontractor.deactivated_at ? 'Deactivated' : 'Active'}</Value>
        </Outlet>

      </Section>

      <Section>

        <Row>

          <Col span={12}>

            <Outlet>
              <Label>Total Orders</Label>
              <Value>{gbp(subcontractor.sub_orders.reduce((carry, row) => carry + row.total, 0))}</Value>
            </Outlet>

          </Col>

          <Col span={12}>

            <Outlet>
              <Label>Total Completed</Label>
              <Value>{gbp(subcontractor.sub_valuations.reduce((carry, row) => carry + row.complete_total, 0))}</Value>
            </Outlet>

          </Col>

        </Row>

      </Section>

    </div>
    
  )

}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

Outlet.propTypes = {
  children: PropTypes.node.isRequired,
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
}

Value.propTypes = {
  children: PropTypes.node.isRequired,
}

Sidebar.propTypes = {
  subcontractor: PropTypes.object.isRequired,
}

export default Sidebar
