import React from 'react'
import { DeleteOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { setFiles } from '../../../State/files'
import { pushRemovedFileId } from '../../../State/removedFileIds'

export default ({ file, fileList }) => async (dispatch) => {
   
   if (file.status === 'removed') {

      if (file.exists) {

         Modal.confirm({
            title: 'Delete File?',
            content: 'This action cannot be undone.',
            icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
            onOk: () => {
               dispatch(pushRemovedFileId(file.uid))
               dispatch(setFiles(fileList))
            },
            okType: 'danger',
            okText: 'Delete'
         })

      } else {

         dispatch(setFiles(fileList))

      }

      return
   }

   dispatch(setFiles(fileList))

}