import { connect } from 'react-redux';
import Component from './Component';

import selectValuationTotals from './Selectors/selectValuationTotals';

import onClickEdit from './Handlers/onClickEdit';
import onClickCancel from './Handlers/onClickCancel';
import onClickSave from './Handlers/onClickSave';
import onClickSetCompletion from './Handlers/onClickSetCompletion';

const mapStateToProps = (state) => ({
  valuationTotals: selectValuationTotals(state),
  valuation: state.screens.ValuationView.valuation,
  editable: state.screens.ValuationView.editable,
})

const actionCreators = {
  onClickEdit,
  onClickCancel,
  onClickSave,
  onClickSetCompletion
};

export default connect(mapStateToProps, actionCreators)(Component);