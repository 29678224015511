import React from 'react'
import { Modal, Dropdown, Menu, Button } from 'antd'
import { DeleteOutlined, FrownOutlined, SafetyOutlined, SmileOutlined } from '@ant-design/icons'
import Form from './Form'

import ResetPassword from '../ResetPassword'

type Props = {
  subcontractors: [],
  id: ?any,
  type: string,
  deactivated: boolean,
  submitting: boolean,
  resetPasswordModal: {},
  componentWillMount: (props: any) => void,
  onClickSubmit: () => void,
  onClickCancel: () => void,
  onClickDelete: () => void,
  onClickReset: () => void,
  onClickDeactivate: () => void,
  onClickReactivate: () => void,
  onCloseResetPasswordModal: () => void,
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props)

  getOptionsMenu = () => {
    const {
      deactivated,
      onClickDelete,
      onClickReset,
      onClickDeactivate,
      onClickReactivate,
    } = this.props
    return (
      <Menu
        onClick={({ key }) => {
          if (key === 'reset-password') {
            onClickReset()
          }
          if (key === 'deactivate') {
            onClickDeactivate()
          }
          if (key === 'reactivate') {
            onClickReactivate()
          }
          if (key === 'delete') {
            onClickDelete()
          }
        }}
      >
        <Menu.Item key='reset-password'><SafetyOutlined />{` Reset Password`}</Menu.Item>
        {deactivated && (
          <Menu.Item key='reactivate'><SmileOutlined />{` Reactivate`}</Menu.Item>
        )}
        {!deactivated && (
          <Menu.Item key='deactivate'><FrownOutlined />{` Deactivate`}</Menu.Item>
        )}
        <Menu.Item key='delete'><DeleteOutlined />{` Delete`}</Menu.Item>
      </Menu>
    )
  }

  render() {

    const {
      subcontractors,
      id,
      type,
      submitting,
      resetPasswordModal,
      onClickSubmit,
      onClickCancel,
      onCloseResetPasswordModal,
    } = this.props

    const footer = []

    if (id) {
      footer.push([
        <Dropdown key='options' overlay={this.getOptionsMenu()}>
          <Button style={{ float: 'left' }} loading={submitting}>
            Options
          </Button>
        </Dropdown>,
      ])
    }

    footer.push([
      <Button key='cancel' disabled={submitting} onClick={onClickCancel}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
        Save
      </Button>,
    ])

    return (
        
      <Modal
        title={id ? 'Edit User' : 'New User'}
        footer={footer}
        onCancel={onClickCancel}
        visible
      >
        
        <Form
          subcontractors={subcontractors}
          id={id}
          type={type}
          submitting={submitting}
        />

        {resetPasswordModal.show && (
          <ResetPassword
            id={id}
            onClose={onCloseResetPasswordModal}
          />
        )}

      </Modal>

    )

  }

}
