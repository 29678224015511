import { closeProjectEditorModal } from '../State/projectEditorModal'
import fetchClient from '../Services/fetchClient'

export default (reload = false) => (dispatch) => {

  dispatch(closeProjectEditorModal())

  if (reload) {
    dispatch(fetchClient())
  }

}
