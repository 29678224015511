// @flow

import { message } from 'antd'
import { getProps } from '../props';
import { reviseEstimate } from '../../../HttpRequests/estimates';
import switchEstimate from './switchEstimate';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch: Function) => {

  try {

    const { id } = getProps().match.params;

    const response = await reviseEstimate({ id });

    const estimate = response.data.data;

    dispatch(switchEstimate(estimate.id))

    message.success('Estimate Revised.');

  } catch (error) {

    handleException(error);

  }
}