import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Components/Form'

export default class UploadModal extends React.PureComponent {

  static propTypes = {
    onUpload: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  render() {

    const {
      onUpload,
      onClose,
    } = this.props

    return (

      <Modal
        title='Upload'
        visible
        onCancel={onClose}
        footer={[
          <Button key='done' onClick={onClose}>Done</Button>,
        ]}
      >
        
        <Form
          onUpload={onUpload}
        />

      </Modal>

    )

  }

}
