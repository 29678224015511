import { createAction, handleActions } from 'redux-actions'

const setSorting = createAction('SCREENS/TIMESHEETS/SET_SORTING')

const defaultState = {
  columnKey: 'date',
  descending: true,
}

const reducer = handleActions(
  {
    [setSorting]: (state, action) => ({ ...state, ...action.payload }),
  },
  defaultState,
)

export { setSorting, reducer }
