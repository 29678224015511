import { connect } from 'react-redux'
import Component from './Component'

import componentDidMount from './Handlers/componentDidMount'
import componentDidUpdate from './Handlers/componentDidUpdate'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseInvoiceModal from './Handlers/onCloseInvoiceModal'
import didUpdateInvoice from './Handlers/didUpdateInvoice'
import didSendEmail from './Handlers/didSendEmail'
import onCloseProjectEditorModal from './Handlers/onCloseProjectEditorModal'

import onCloseEmailInvoiceModal from './Handlers/onCloseEmailInvoiceModal'

const mapStateToProps = (state) => ({
  invoice: state.screens.InvoiceView.invoice,
  fetching: state.screens.InvoiceView.fetching,
  showInvoiceModal: state.screens.InvoiceView.showInvoiceModal,
  showEmailModal: state.screens.InvoiceView.showEmailModal,
  projectEditorModal: state.screens.InvoiceView.projectEditorModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  componentDidUpdate,
  onCloseInvoiceModal,
  didUpdateInvoice,
  didSendEmail,
  onCloseEmailInvoiceModal,
  onCloseProjectEditorModal,
}

export default connect(mapStateToProps, actionCreators)(Component);