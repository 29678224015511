exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._20j2Kp99lWqNXxmwPiotg4 {\n  width: 280px;\n  height: 100%;\n  border-left: 1px solid #CCC;\n  background-color: #FAFAFA;\n  position: fixed;\n  bottom: 0px;\n  right: 0px;\n  z-index: 2;\n  padding-top: 45px;\n  overflow: hidden;\n}\n\n._2K5sJcZ8MwQt98K2VkMgEj {\n  border-bottom: 1px solid #DDD;\n  padding: 25px;\n}\n\n._31cbNA1HGSh68ZmyTv2grJ {\n  margin-bottom: 15px;\n}\n\n._31cbNA1HGSh68ZmyTv2grJ:last-child {\n  margin-bottom: 0px;\n}\n\n._2yuWe2lw80e2TSqUofdYs6 {\n  font-size: 14px;\n  font-weight: 500;\n  margin-bottom: 3px;\n  color: #999;\n}\n\n._1a976aUmPQuCF0Qp4QfK8O {\n  font-size: 14px;\n  font-weight: 500;\n  color: #444;\n  line-height: 18px;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/SubValuation/Components/Sidebar/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,4BAA4B;EAC5B,0BAA0B;EAC1B,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,iBAAiB;CAClB;;AAED;EACE,8BAA8B;EAC9B,cAAc;CACf;;AAED;EACE,oBAAoB;CACrB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;CACb;;AAED;EACE,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,kBAAkB;CACnB","file":"styles.css","sourcesContent":[".wrapper {\n  width: 280px;\n  height: 100%;\n  border-left: 1px solid #CCC;\n  background-color: #FAFAFA;\n  position: fixed;\n  bottom: 0px;\n  right: 0px;\n  z-index: 2;\n  padding-top: 45px;\n  overflow: hidden;\n}\n\n.section {\n  border-bottom: 1px solid #DDD;\n  padding: 25px;\n}\n\n.outlet {\n  margin-bottom: 15px;\n}\n\n.outlet:last-child {\n  margin-bottom: 0px;\n}\n\n.label {\n  font-size: 14px;\n  font-weight: 500;\n  margin-bottom: 3px;\n  color: #999;\n}\n\n.value {\n  font-size: 14px;\n  font-weight: 500;\n  color: #444;\n  line-height: 18px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_20j2Kp99lWqNXxmwPiotg4",
	"section": "_2K5sJcZ8MwQt98K2VkMgEj",
	"outlet": "_31cbNA1HGSh68ZmyTv2grJ",
	"label": "_2yuWe2lw80e2TSqUofdYs6",
	"value": "_1a976aUmPQuCF0Qp4QfK8O"
};