import { fromJS } from 'immutable';
import { assign, cloneDeep } from 'lodash';
import { loadEstimate } from '../State/estimate';

export default (params) => (dispatch, getState) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);

  const sourceEstimateGroup = estimate.getIn(['estimate_groups', params.estimateGroupIndex]).toJS();

  const estimateGroup = assign(cloneDeep(sourceEstimateGroup), { id: null });

  estimateGroup.id = null;
  estimateGroup.estimate_items = estimateGroup.estimate_items.map(item => assign(item, { id: null }))
  
  estimate = estimate.updateIn(
    ['estimate_groups'],
    (list) => list.insert(params.estimateGroupIndex + 1, estimateGroup)
  );

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}