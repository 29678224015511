import { initialize } from 'redux-form'
import { findTimesheetPosting } from '../../../HttpRequests/timesheetPostings'
import { getProps } from '../props'
import { FORM_NAME } from '../Form'

export default () => async (dispatch) => {

  const { id } = getProps()

  if (id) {

    const response = await findTimesheetPosting({ id })

    const timesheetPosting = response.data

    const { end_date_time: endDateTime } = timesheetPosting

    dispatch(initialize(FORM_NAME, {
      id: timesheetPosting.id,
      timesheet_id: timesheetPosting.timesheet_id,
      project_id: timesheetPosting.project_id,
      start_time: timesheetPosting.start_date_time.substring(11, 19),
      end_time: endDateTime ? endDateTime.substring(11, 19) : null,
    }))

  } else {

    dispatch(initialize(FORM_NAME, {
      start_time: '07:00:00',
      end_time: '17:00:00',
    }))

  }

}
