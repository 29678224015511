import { connect } from 'react-redux';
import Component from './Component';

import selectClient from './Selectors/selectClient';

import componentDidMount from './Handlers/componentDidMount';
import componentWillUnmount from './Handlers/componentWillUnmount';

import onClickEditClient from './Handlers/onClickEditClient';
import onCloseClientModal from './Handlers/onCloseClientModal';
import didUpdateClient from './Handlers/didUpdateClient';
import didDeleteClient from './Handlers/didDeleteClient';

import onClickNewClientContact from './Handlers/onClickNewClientContact';
import onCloseClientContactModal from './Handlers/onCloseClientContactModal';
import didCreateClientContact from './Handlers/didCreateClientContact';
import didUpdateClientContact from './Handlers/didUpdateClientContact';
import didDeleteClientContact from './Handlers/didDeleteClientContact';
import onClickEditClientContact from './Handlers/onClickEditClientContact';

import onClickNewClientSite from './Handlers/onClickNewClientSite';
import onCloseClientSiteModal from './Handlers/onCloseClientSiteModal';
import didCreateClientSite from './Handlers/didCreateClientSite';
import didUpdateClientSite from './Handlers/didUpdateClientSite';
import didDeleteClientSite from './Handlers/didDeleteClientSite';
import onClickEditClientSite from './Handlers/onClickEditClientSite';

import onClickNewProject from './Handlers/onClickNewProject';
import onClickProjectRow from './Handlers/onClickProjectRow';
import onCloseProjectEditorModal from './Handlers/onCloseProjectEditorModal';

import onChangeSearch from './Handlers/onChangeSearch';
import onChangeActiveTab from './Handlers/onChangeActiveTab';

const mapStateToProps = (state) => ({
  client: selectClient(state),
  showClientModal: state.screens.ClientView.showClientModal,
  showClientContactModal: state.screens.ClientView.showClientContactModal,
  showClientSiteModal: state.screens.ClientView.showClientSiteModal,
  projectEditorModal: state.screens.ClientView.projectEditorModal,
  clientContactForEdit: state.screens.ClientView.clientContactForEdit,
  searchTerm: state.screens.ClientView.searchTerm,
  clientSiteForEdit: state.screens.ClientView.clientSiteForEdit,
  clientProjectForEdit: state.screens.ClientView.clientProjectForEdit
});

const actionCreators = {
  componentDidMount,
  onClickEditClient,
  onCloseClientModal,
  onChangeSearch,
  onChangeActiveTab,
  didUpdateClient,
  didDeleteClient,
  onClickNewClientContact,
  onCloseClientContactModal,
  didCreateClientContact,
  didUpdateClientContact,
  didDeleteClientContact,
  onClickEditClientContact,
  onClickNewClientSite,
  onCloseClientSiteModal,
  didCreateClientSite,
  didUpdateClientSite,
  didDeleteClientSite,
  onClickEditClientSite,
  onClickNewProject,
  onClickProjectRow,
  onCloseProjectEditorModal,
  componentWillUnmount
};

export default connect(mapStateToProps, actionCreators)(Component);