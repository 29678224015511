import { connect } from 'react-redux';
import Component from './Component';

import onClickExportStatistics from './Handlers/onClickExportStatistics';
import onClickExportHistory from './Handlers/onClickExportHistory';

const mapStateToProps = () => ({});

const actionCreators = {
  onClickExportStatistics,
  onClickExportHistory,
};

export default connect(mapStateToProps, actionCreators)(Component);
