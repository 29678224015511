exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._5yjRkdbqVNg8pAfKDvnzA {\n  font-size: 16px;\n  font-weight: 500;\n  color: black;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/InvoiceView/Components/Related/Component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;CACd","file":"Component.css","sourcesContent":[".title {\n  font-size: 16px;\n  font-weight: 500;\n  color: black;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"title": "_5yjRkdbqVNg8pAfKDvnzA"
};