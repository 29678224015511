import { createAction, handleActions } from 'redux-actions';

const setMoving = createAction("MODALS/MOVE_FILES/SET_MOVING");

const defaultState = false

const reducer = handleActions(
  {
    [setMoving]: (state, action) => action.payload
  },
  defaultState
);

export { setMoving, reducer };