export default {

  chart: {
    height: 350
  },

  title: {
    text: null
  },

  series: [],

  credits: {  
    enabled:false
  }

}
