import { createAction, handleActions } from 'redux-actions'

const setSearch = createAction('SCREENS/SUB_VALUATIONS/SET_SEARCH')
const resetSearch = createAction('SCREENS/SUB_VALUATIONS/RESET_SEARCH')

const defaultState = ''

const reducer = handleActions(
  {
    [setSearch]: (state, { payload }) => payload,
    [resetSearch]: () => defaultState,
  },
  defaultState,
)

export { setSearch, resetSearch, reducer }
