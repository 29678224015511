exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1gflN8ef-uJoGgiIuohCcn {\n  color: #C60302;\n  font-weight: bold;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/RetentionPayments/Components/Table/Component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;CACnB","file":"Component.css","sourcesContent":[".overdue {\n  color: #C60302;\n  font-weight: bold;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"overdue": "_1gflN8ef-uJoGgiIuohCcn"
};