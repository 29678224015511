import { createAction, handleActions } from 'redux-actions';

const loadEmail = createAction("MODALS/VIEW_EMAIL/LOAD_EMAIL");
const clearEmail = createAction("MODALS/VIEW_EMAIL/CLEAR_EMAIL");

const defaultState = null

const reducer = handleActions(
  {
    [loadEmail]: (state, action) => action.payload,
    [clearEmail]: () => null
  },
  defaultState
);

export { loadEmail, clearEmail, reducer };