import moduleProps from '../moduleProps'
import { resetData } from '../State/data'
import { resetSelected } from '../State/selected'

export default () => (dispatch) => {

  moduleProps.reset()

  dispatch(resetData())
  dispatch(resetSelected())

}
