import { message } from 'antd';
import { renameDirectory } from '../../../HttpRequests/files';
import { getProps } from '../props';


export default (values) => async () => {

  const { currentDirectoryPath } = getProps();
  
  const data = {
    current_path: `${currentDirectoryPath}/${values.from}`,
    new_path: `${currentDirectoryPath}/${values.folder}`
  }

  const folder = await renameDirectory({ data });

  message.success("Directory Renamed");
  
  return folder;

}