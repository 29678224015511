import { connect } from 'react-redux';
import Component from './Component';

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import componentDidUpdate from './Handlers/componentDidUpdate';
import onChangeTab from './Handlers/onChangeTab';
import didUpdateOrder from './Handlers/didUpdateOrder';
import didSendEmail from './Handlers/didSendEmail';
import didUpdateClientContact from './Handlers/didUpdateClientContact';
import didUpdateClientSite from './Handlers/didUpdateClientSite';
import onCloseOrderModal from './Handlers/onCloseOrderModal';
import onCloseEmailModal from './Handlers/onCloseEmailModal';
import onCloseClientContactModal from './Handlers/onCloseClientContactModal';
import onCloseClientSiteModal from './Handlers/onCloseClientSiteModal';
import onCloseProjectEditorModal from './Handlers/onCloseProjectEditorModal';
import onCloseSubOrderModal from './Handlers/onCloseSubOrderModal';

const mapStateToProps = (state) => ({
  order: state.screens.OrderView.order,
  showOrderModal: state.screens.OrderView.showOrderModal,
  showEmailModal: state.screens.OrderView.showEmailModal,
  showOrderDuplicateModal: state.screens.OrderView.showOrderDuplicateModal,
  showClientContactModal: state.screens.OrderView.showClientContactModal,
  showClientSiteModal: state.screens.OrderView.showClientSiteModal,
  projectEditorModal: state.screens.OrderView.projectEditorModal,
  subOrderModal: state.screens.OrderView.subOrderModal,
})

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  componentDidUpdate,
  onChangeTab,
  didUpdateOrder,
  didSendEmail,
  didUpdateClientContact,
  didUpdateClientSite,
  onCloseOrderModal,
  onCloseEmailModal,
  onCloseClientContactModal,
  onCloseClientSiteModal,
  onCloseProjectEditorModal,
  onCloseSubOrderModal,
};

export default connect(mapStateToProps, actionCreators)(Component);