import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Input, Select, DatePicker, Switch, Tag } from 'antd'

export default class ControlsModal extends React.PureComponent {

  static propTypes = {
    users: PropTypes.array.isRequired,
    projectStatuses: PropTypes.array.isRequired,
    projectPhases: PropTypes.array.isRequired,
    controls: PropTypes.object.isRequired,
    onChangeControls: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  state = {
    reload: false,
  }

  render() {

    const {
      users,
      projectStatuses,
      projectPhases,
      controls,
      onChangeControls,
      onClose,
    } = this.props

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
      },
    }

    return (
        
      <Modal
        title='All Filters'
        visible
        width={450}
        okText='Apply'
        onOk={() => onClose(this.state.reload)}
        onCancel={() => onClose(this.state.reload)}
        cancelButtonProps={{ style: { display: 'none' } }}
        maskClosable={!this.state.reload}
        closable={!this.state.reload}
      >
        
        <Form colon={false}>

          {controls.yAxis === 'projects' && (

            <React.Fragment>

              <Form.Item label='Search' {...formItemLayout}>
                <Input.Search
                  value={controls.projectSearch}
                  onChange={e => {
                    onChangeControls('projectSearch', e.target.value)
                    this.setState({ reload: true })
                  }}
                  style={{ width: 235 }}
                />
              </Form.Item>

              <Form.Item label='Manager' {...formItemLayout}>
                <Select
                  value={controls.contractsManagerId}
                  onChange={value => {
                    onChangeControls('contractsManagerId', value)
                    this.setState({ reload: true })
                  }}
                  mode='multiple'
                  showSearch
                  optionFilterProp='children'
                  allowClear
                  style={{ width: 235 }}
                >
                  <Select.Option key='null' value='null'>
                    <div style={{ fontStyle: 'italic' }}>No Manager</div>
                  </Select.Option>
                  {users.map(user => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.full_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label='Status' {...formItemLayout}>
                <Select
                  value={controls.statusKey}
                  onChange={value => {
                    onChangeControls('statusKey', value)
                    this.setState({ reload: true })
                  }}
                  mode='multiple'
                  style={{ width: 235 }}
                >
                  {projectStatuses.map(projectStatus => (
                    <Select.Option key={projectStatus.key} value={projectStatus.key}>
                      {projectStatus.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label='Phase' {...formItemLayout}>
                <Select
                  value={controls.phaseKey}
                  onChange={value => {
                    onChangeControls('phaseKey', value)
                    this.setState({ reload: true })
                  }}
                  mode='multiple'
                  allowClear
                  style={{ width: 235 }}
                >
                  {projectPhases.map(projectPhase => (
                    <Select.Option key={projectPhase.key} value={projectPhase.key}>
                      {projectPhase.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label='Start Date' {...formItemLayout}>
                <DatePicker.RangePicker
                  value={controls.startDateBetween}
                  onChange={value => {
                    onChangeControls('startDateBetween', value || [])
                    this.setState({ reload: true })
                  }}
                  style={{ width: 235 }}
                />
              </Form.Item>

            </React.Fragment>

          )}

          {controls.yAxis === 'subcontractors' && (

            <React.Fragment>

              <Form.Item label='Search' {...formItemLayout}>
                <Input.Search
                  value={controls.subcontractorSearch}
                  onChange={e => {
                    onChangeControls('subcontractorSearch', e.target.value)
                    this.setState({ reload: true })
                  }}
                  style={{ width: 235 }}
                />
              </Form.Item>

              <Form.Item label='Card Type' {...formItemLayout}>
                <Select
                  value={controls.cardType}
                  onChange={value => {
                    onChangeControls('cardType', value)
                    this.setState({ reload: true })
                  }}
                  mode='multiple'
                  showSearch
                  optionFilterProp='children'
                  allowClear
                  style={{ width: 235 }}
                >
                  <Select.OptGroup label='Card Type'>
                    {['Red', 'Blue', 'Green', 'White', 'Gold', 'Black'].map(cardType => (
                      <Select.Option key={cardType} value={cardType}>
                        <Tag color={cardType !== 'White' && cardType}>
                          {cardType}
                        </Tag>
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>
              </Form.Item>

            </React.Fragment>

          )}

          <Form.Item label='Options' {...formItemLayout}>
            <Switch
              checked={controls.hideEmpty}
              onChange={value => {
                onChangeControls('hideEmpty', value)
                this.setState({ reload: true })
              }}
            />
            <span style={{ marginLeft: 5 }}>Hide Empty</span>
          </Form.Item>

          {controls.yAxis === 'projects' && (

            <React.Fragment>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.omitted}
                  onChange={value => {
                    onChangeControls('omitted', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Unsubscribed Only</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.noFinishingSchedule}
                  onChange={value => {
                    onChangeControls('noFinishingSchedule', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>No Finishing Schedule</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.noProgramme}
                  onChange={value => {
                    onChangeControls('noProgramme', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>No Programme</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.inductionRequired}
                  onChange={value => {
                    onChangeControls('inductionRequired', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Induction Required</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.weeklyReturns}
                  onChange={value => {
                    onChangeControls('weeklyReturns', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Weekly Returns</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.termsAndConditions}
                  onChange={value => {
                    onChangeControls('termsAndConditions', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Client Terms & Conditions</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.noValuationThisMonth}
                  onChange={value => {
                    onChangeControls('noValuationThisMonth', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>No Valuation This Month</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.missingRams}
                  onChange={value => {
                    onChangeControls('missingRams', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>RAMS Missing</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.expiringRams}
                  onChange={value => {
                    onChangeControls('expiringRams', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>RAMS Expiring</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.expiredRams}
                  onChange={value => {
                    onChangeControls('expiredRams', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>RAMS Expired</span>
              </Form.Item>

            </React.Fragment>

          )}

          {((controls.yAxis === 'projects' && !controls.summary) || controls.yAxis === 'subcontractors') && (

            <React.Fragment>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.unconfirmedOrRequired}
                  onChange={value => {
                    onChangeControls('unconfirmedOrRequired', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Unconfirmed / Required</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.unattended}
                  onChange={value => {
                    onChangeControls('unattended', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Unattended</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.unavailableOrAbsent}
                  onChange={value => {
                    onChangeControls('unavailableOrAbsent', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Unavailable / Absent</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.duplicated}
                  onChange={value => {
                    onChangeControls('duplicated', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Duplicated</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.unexpected}
                  onChange={value => {
                    onChangeControls('unexpected', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Unexpected</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.attended}
                  onChange={value => {
                    onChangeControls('attended', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Attended</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.alerts}
                  onChange={value => {
                    onChangeControls('alerts', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Alerts</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.switched}
                  onChange={value => {
                    onChangeControls('switched', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Switched</span>
              </Form.Item>

              <Form.Item label=' ' {...formItemLayout}>
                <Switch
                  checked={controls.priceWork}
                  onChange={value => {
                    onChangeControls('priceWork', value)
                    this.setState({ reload: true })
                  }}
                />
                <span style={{ marginLeft: 5 }}>Price Work</span>
              </Form.Item>

            </React.Fragment>

          )}

        </Form>

      </Modal>
      
    )

  }

}
