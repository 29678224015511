import { Modal } from 'antd'
import { getProps } from '../props'
import deleteClient from '../Services/delete'

export default () => (dispatch) => {

  const { client } = getProps()

  Modal.confirm({
    title: `Delete "${client.name}"`,
    content: 'Are you sure to delete this client?',
    okText: 'Yes',
    width: 400,
    onOk: () => dispatch(deleteClient()),
  })

}
