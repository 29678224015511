import { connect } from 'react-redux'
import Item from './Item'

import onSelectItem from './Handlers/onSelectItem'
import onDoubleClickItem from './Handlers/onDoubleClickItem'
import onToggleItemCheckbox from './Handlers/onToggleItemCheckbox'
import onClickContextItem from './Handlers/onClickContextItem'

const mapStateToProps = (state) => ({
  layout: state.modules.FileManager.layout,
})

const actionCreators = {
  onSelectItem,
  onDoubleClickItem,
  onToggleItemCheckbox,
  onClickContextItem,
}

export default connect(mapStateToProps, actionCreators)(Item)
