// @flow

import * as React from 'react';
import styles from './Header.css';

export default () => (

  <div className={styles.wrapper}>

    <div className={styles.spacer} />

    <div className={styles.headings} />

  </div>

)