// @flow

import { fromJS } from 'immutable';
import { loadOrder } from '../State/order';
import type { OrderItem } from '../types';

export type AddOrderItemParams = {
  orderGroupIndex: number,
  orderItemIndex?: number
}

export default (params: AddOrderItemParams) => (dispatch: Function, getState: Function) => {
  
  let order = fromJS(getState().screens.OrderView.order);

  const orderItem: OrderItem = {
    id: null,
    order_group_id: order.getIn(['order_groups', params.orderGroupIndex, 'id']),
    type: 'D',
    description: '',
    flag: null,
    quantity: '0.00',
    unit: 'm2',
    rate: '0.00',
    subtotal: '0.00',
    discount: '0.00',
    total: '0.00',
    line_number: null,
    order_number: null,
    notes: null
  }
  
  order = order.updateIn(
    ['order_groups', params.orderGroupIndex, 'order_items'],
    (list) => params.orderItemIndex !== undefined ? list.insert(params.orderItemIndex + 1, orderItem) : list.push(orderItem)
  );

  order = order.toJS();
  
  dispatch(loadOrder(order));

}