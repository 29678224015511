// @flow
import * as React from 'react';
import { Breadcrumb, Divider } from 'antd';
import { NavLink } from 'react-router-dom';
import { getTeamUrl } from 'Common';

const { Item } = Breadcrumb;

type Props = {
  client: Object
}

export default class extends React.PureComponent<Props> {

  render() {

    const { client } = this.props;

    return (

      <React.Fragment>

        <Breadcrumb separator="»">

          <Item>
            Home
          </Item>

          <Item>

            <NavLink to={getTeamUrl('clients')}>
              Clients
            </NavLink>

          </Item>

          <Item>
            { client.name }
          </Item>

        </Breadcrumb>

        <Divider type="horizontal" />
        
      </React.Fragment>

    )
  };
}