import * as React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { Row, Col, Button, Card, Tag } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const styles = {

  detailWrapper: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    color: '#444',
    paddingBottom: 10,
    paddingTop: 10,
  },

  detailLabel: {
    width: 130,
    fontWeight: '500',
    textAlign: 'right',
    paddingRight: 15
  },

  detailData: {
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.65)'
  }

}

type Props = {
  client: Object,
  onClickEditClient: Function,
}

export default class extends React.PureComponent<Props> {

  render () {
    const { client, onClickEditClient } = this.props;

    return (
      <Row style={{ marginBottom: 20 }}>

        <Col span={12}>
        
          <h1>
            {client.name}
          </h1>

        </Col>

        <Col span={12} className="text-right">

          <Button type="default" onClick={onClickEditClient}>
            Edit Client
          </Button>

        </Col>

        <Col span={24}>

          <Card>
            
            <Row>
              
              <Col span={14}>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Name :
                  </div>

                  <div style={styles.detailData}>
                    { client.name }
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Aliases :
                  </div>

                  <div style={styles.detailData}>
                    { client.alias_name }
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Telephone :
                  </div>

                  <div style={styles.detailData}>
                    { client.telephone || '-' }
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Email :
                  </div>

                  <div style={styles.detailData}>
                    { client.email || '-' }
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Website :
                  </div>

                  <div style={styles.detailData}>
                    { client.website || '-' }
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Company No :
                  </div>

                  <div style={styles.detailData}>
                    { client.company_number || '-' }
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Pref Supplier :
                  </div>

                  <div style={styles.detailData}>
                    {client.preferred_supplier ? <CheckOutlined /> : <CloseOutlined />}
                    {client.preferred_supplier && client.preferred_supplier_perc ? ` • ${numeral(client.preferred_supplier_perc).format('0,0.[00]')}%` : ''}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Address :
                  </div>

                  <div style={styles.detailData}>
                    {[
                      client.billing_address_line_1,
                      client.billing_address_line_2,
                      client.billing_address_line_3,
                      client.billing_town_or_city,
                      client.billing_county,
                      client.billing_postcode
                    ].filter(line => line).join(', ') || '-' }
                  </div>

                </div>

              </Col>

              <Col span={10}>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Billing Type :
                  </div>

                  <div style={styles.detailData}>
                    {client.billing_type}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Risk Level :
                  </div>

                  <div style={styles.detailData}>
                    <span>
                      <Tag
                        color={({ "1": "green", "2": "orange", "3": "red", "4": "black" })[client.client_risk_level_key]}
                        style={{ cursor: 'default' }}
                      >
                        {client.client_risk_level.title}
                      </Tag>
                      <span style={{ color: '#999', fontStyle: 'italic' }}>
                        {client.client_risk_level_last_set_at && ` Set by ${client.client_risk_level_last_set_by_user.first_name} ${client.client_risk_level_last_set_by_user.last_name} on ${moment(client.client_risk_level_last_set_at).format('DD/MM/YYYY')}`}
                      </span>
                    </span>
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Credit Limit :
                  </div>

                  <div style={styles.detailData}>
                    { client.credit_limit !== null ? (
                      <span>
                        {`£${numeral(client.credit_limit).format('0,0.00')}`}
                        <span style={{ color: '#999', fontStyle: 'italic', marginLeft: 10 }}>
                          {` Set by ${client.credit_limit_last_set_by_user.first_name} ${client.credit_limit_last_set_by_user.last_name} on ${moment(client.credit_limit_last_set_at).format('DD/MM/YYYY')}`}
                        </span>
                      </span>
                    ) : '-' }
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Experian Limit :
                  </div>

                  <div style={styles.detailData}>
                    { client.aldermore_limit !== null ? (
                      <span>
                        {`£${numeral(client.aldermore_limit).format('0,0.00')}`}
                        <span style={{ color: '#999', fontStyle: 'italic', marginLeft: 10 }}>
                          {` Set by ${client.aldermore_limit_last_set_by_user.first_name} ${client.aldermore_limit_last_set_by_user.last_name} on ${moment(client.aldermore_limit_last_set_at).format('DD/MM/YYYY')}`}
                        </span>
                      </span>
                    ) : '-' }
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Outstanding :
                  </div>

                  <div style={styles.detailData}>
                    { client.value_outstanding !== null ? (
                      <span>
                        {`£${numeral(client.value_outstanding).format('0,0.00')}`}
                        <span style={{ color: '#999', fontStyle: 'italic', marginLeft: 10 }}>
                          {` Set by ${client.value_outstanding_last_set_by_user.first_name} ${client.value_outstanding_last_set_by_user.last_name} on ${moment(client.value_outstanding_last_set_at).format('DD/MM/YYYY')}`}
                        </span>
                      </span>
                    ) : '-' }
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Review Date :
                  </div>

                  <div style={styles.detailData}>
                    { client.value_outstanding_review_date !== null ? `${moment(client.value_outstanding_review_date).format('DD/MM/YYYY')}` : '-' }
                  </div>

                </div>

              </Col>

            </Row>

          </Card>

        </Col>

      </Row>
    )

  }
}