import { getSubProjects, getSubProjectsSummary } from '../../../HttpRequests/subProjects'
import { setData } from '../State/data'
import { startFetching, finishFetching } from '../State/fetching'
import handleException from '../../../Core/Helpers/handleException'

const defaultSortMap = {
  projects: 'subcontractor.asc',
  summary: 'name.asc',
}

export default () => async (dispatch, getState) => {

  const { search, filters, mode, sortBy } = getState().screens.SubProjects

  const params = {
    search,
    status_key: filters.statusKey !== 'all' ? filters.statusKey : undefined,
    sort_by: sortBy || defaultSortMap[mode],
  }

  if (filters.subcontractorIds.length) params.subcontractor_ids = filters.subcontractorIds.join(',')
  if (filters.projectIds.length) params.project_ids = filters.projectIds.join(',')
  if (filters.completionStatuses.length) params.completion_statuses = filters.completionStatuses.join(',')

  dispatch(startFetching())

  try {

    let response

    if (mode === 'projects') response = await getSubProjects({ params })

    if (mode === 'summary') response = await getSubProjectsSummary({ params })

    dispatch(setData(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
