import { createAction, handleActions } from 'redux-actions'

const setLayout = createAction('MODULES/FILE_MANAGER/SET_LAYOUT')
const resetLayout = createAction('MODULES/FILE_MANAGER/RESET_LAYOUT')

const defaultState = 'list'

const reducer = handleActions(
  {
    [setLayout]: (state, action) => action.payload,
    [resetLayout]: () => defaultState,
  },
  defaultState,
)

export { setLayout, resetLayout, reducer }
