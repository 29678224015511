exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".hriGiVkepo4XUk2qq60dl {\n  border: 1px solid #E8E8E8;\n  background-color: white;\n  margin-top: 20px;\n}\n\n._34CFj-rYtaS-qdAWlGSeAk {\n  display: flex;\n  flex-direction: row;\n  height: 645px;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/ProjectView/Components/Activity/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,wBAAwB;EACxB,iBAAiB;CAClB;;AAED;EACE,cAAc;EACd,oBAAoB;EACpB,cAAc;CACf","file":"index.css","sourcesContent":[".wrapper {\n  border: 1px solid #E8E8E8;\n  background-color: white;\n  margin-top: 20px;\n}\n\n.body {\n  display: flex;\n  flex-direction: row;\n  height: 645px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "hriGiVkepo4XUk2qq60dl",
	"body": "_34CFj-rYtaS-qdAWlGSeAk"
};