import React from 'react'
import PropTypes from 'prop-types'

import List from './Components/List'
import Footer from './Components/Footer'
import SetTargetCosts from '../../../../Modals/SetTargetCosts'

export default class _TargetCosts_ extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    listData: PropTypes.array.isRequired,
    serviceTypes: PropTypes.array.isRequired,
    editable: PropTypes.bool.isRequired,
    activeItemInputKeys: PropTypes.any,
    showSetTargetCostsModal: PropTypes.bool.isRequired,
    onClickTargetCostPercentageOutlet: PropTypes.func.isRequired,
    onClickTargetCostAmountOutlet: PropTypes.func.isRequired,
    onChangeTargetCostPercentage: PropTypes.func.isRequired,
    onChangeTargetCostAmount: PropTypes.func.isRequired,
    onBlurTargetCostPercentage: PropTypes.func.isRequired,
    onBlurTargetCostAmount: PropTypes.func.isRequired,
    onTabTargetCostField: PropTypes.func.isRequired,
    onCloseSetTargetCostsModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    activeItemInputKeys: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      listData,
      serviceTypes,
      editable,
      activeItemInputKeys,
      showSetTargetCostsModal,
      onClickTargetCostPercentageOutlet,
      onClickTargetCostAmountOutlet,
      onChangeTargetCostPercentage,
      onChangeTargetCostAmount,
      onBlurTargetCostPercentage,
      onBlurTargetCostAmount,
      onTabTargetCostField,
      onCloseSetTargetCostsModal,
    } = this.props

    return (

      <React.Fragment>

        <List
          listData={listData}
          serviceTypes={serviceTypes}
          editable={editable}
          activeItemInputKeys={activeItemInputKeys}
          onClickTargetCostPercentageOutlet={onClickTargetCostPercentageOutlet}
          onClickTargetCostAmountOutlet={onClickTargetCostAmountOutlet}
          onChangeTargetCostPercentage={onChangeTargetCostPercentage}
          onChangeTargetCostAmount={onChangeTargetCostAmount}
          onBlurTargetCostPercentage={onBlurTargetCostPercentage}
          onBlurTargetCostAmount={onBlurTargetCostAmount}
          onTabTargetCostField={onTabTargetCostField}
        />

        <Footer />

        {showSetTargetCostsModal && (
          <SetTargetCosts
            onClose={onCloseSetTargetCostsModal}
          />
        )}

      </React.Fragment>

    )
  }
}