import React from 'react'
import { Modal } from 'antd'
import { DisconnectOutlined } from '@ant-design/icons'
import detachSubValuation from '../../../../../Services/detachSubValuation'

export default (subValuation) => (dispatch) => {

  Modal.confirm({
    title: `Unlink "${subValuation.reference}"`,
    content: 'Are you sure you want to unlink this valuation from this invoice?',
    icon: <DisconnectOutlined style={{ color: '#FF4D4F' }} />,
    okType: 'danger',
    okText: 'Unlink',
    onOk: () => dispatch(detachSubValuation(subValuation)),
  })

}
