// @flow

import * as React from 'react';
import { Modal, Button} from 'antd';
import Table from './Components/Table';

type Props = {
  history: any,
  componentWillMount: (props: any) => void,
  onClickCancel: Function,
  onClickValuation: Function
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      history,
      onClickCancel,
      onClickValuation,
    } = this.props;
    
    return (

      <Modal
        title='Valuation History'
        visible
        onCancel={onClickCancel}
        footer={false}
        width={850}
      >

        <Table
          history={history}
          onClickValuation={onClickValuation}
        />

      </Modal>
    )
  }
}