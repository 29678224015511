import { createSelector } from 'reselect'

const selectSubOrder = state => state.screens.SubOrder.subOrder

export default createSelector(
  selectSubOrder,
  (subOrder) => {

    const rows = []

    let lineNo = 1

    subOrder.sub_order_groups.forEach((subOrderGroup) => {

      rows.push({
        component: 'SubOrderGroup',
        height: 60,
        subOrderGroup,
      })

      subOrderGroup.sub_order_items.forEach((subOrderItem) => {

        rows.push({
          component: 'SubOrderItem',
          height: 50,
          subOrderItem: {
            ...subOrderItem,
            line_no: subOrderItem.type ? lineNo : null,
          },
        })

        if (subOrderItem.type) lineNo += 1

      })

      if (subOrderGroup.sub_order_items.length === 0) {

        rows.push({
          component: 'SubOrderGroupPlaceholder',
          height: 50,
          subOrderGroup,
        })

      }

      rows.push({
        component: 'SubOrderGroupFooter',
        height: 60,
        subOrderGroup,
      })

    })

    if (subOrder.sub_order_groups.length > 0) {

      rows.push({
        component: 'SubOrderFooter',
        height: 90,
      })

    } else {

      rows.push({
        component: 'SubOrderPlaceholder',
        height: 100,
      })

    }

    return rows

  },
)
