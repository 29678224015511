import fetch from '../Services/fetch'
import fetchTotals from '../Services/fetchTotals'
import fetchClients from '../../../Core/Services/fetchClients'

export default () => async (dispatch) => {
  
  await dispatch(fetch())
  
  dispatch(fetchTotals())

  dispatch(fetchClients())
  
}