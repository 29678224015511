import { message } from 'antd'
import { duplicateEstimate } from '../../../HttpRequests/estimates';
import { getProps } from '../props';

export default (values) => async () => {
  
  const { estimateId } = getProps();

  const data = { ...values }

  const response = await duplicateEstimate({ id: estimateId, data });

  const estimate = response.data.data;

  message.success('Estimate Created');

  return estimate;

}