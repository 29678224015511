import { deleteItems } from '../../../HttpRequests/modelFiles'
import fetchDirectory from './fetchDirectory'
import { resetSelectedItemPath } from '../State/selectedItemPath'
import { resetCheckedItemPaths } from '../State/checkedItemPaths'
import handleException from '../../../Core/Helpers/handleException'

export default (paths) => async (dispatch) => {

  const data = {
    paths,
  }

  try {

    await deleteItems({ data })
    
    dispatch(fetchDirectory())

    dispatch(resetSelectedItemPath())

    dispatch(resetCheckedItemPaths())

  } catch (error) {

    handleException(error)

  }

}
