import * as React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { filter, first } from 'lodash';

export default class extends React.PureComponent {

  static propTypes = {
    caveats: PropTypes.array.isRequired,
    serviceTypes: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,

    onChangeTable: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired
  }

  render() {

    const {
      caveats,
      serviceTypes,
      pagination,
      fetching,

      onChangeTable,
      onClickEdit
    } = this.props;
    
    const columns = [
      {
        title: 'Type',
        dataIndex: 'type',
        width: 150,
        render: (text, record) => (
          <a onClick={() => onClickEdit(record)} role="presentation">
            { first(filter(serviceTypes, { key: text})).title }
          </a>
        )
      },
      
      {
        title: 'Content',
        dataIndex: 'content'
      }
    ]
    
    return (
      <Table
        dataSource={caveats}
        columns={columns} 
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination
        }}
      />
    )
  }
}