import { connect } from 'react-redux';
import Subcontractors from './Subcontractors';

import componentDidMount from './Handlers/componentDidMount';
import onChangeTable from './Handlers/onChangeTable';
import onChangeSearch from './Handlers/onChangeSearch';
import onSubmitSearch from './Handlers/onSubmitSearch';
import onClickExport from './Handlers/onClickExport';
import onChangeFilter from './Handlers/onChangeFilter';

import onClickNewSubcontractor from './Handlers/onClickNewSubcontractor';
import onCloseSubcontractorModal from './Handlers/onCloseSubcontractorModal';
import didCreateSubcontractor from './Handlers/didCreateSubcontractor';

const mapStateToProps = (state) => ({
  subcontractors: state.screens.Subbies.Subcontractors.subcontractors,
  searchTerm: state.screens.Subbies.Subcontractors.searchTerm,
  filters: state.screens.Subbies.Subcontractors.filters,
  pagination: state.screens.Subbies.Subcontractors.pagination,
  fetching: state.screens.Subbies.Subcontractors.fetching,
  showSubcontractorModal: state.screens.Subbies.Subcontractors.showSubcontractorModal
});

const actionCreators = {
  componentDidMount,
  onChangeTable,
  onClickNewSubcontractor,
  onCloseSubcontractorModal,
  didCreateSubcontractor,

  onChangeSearch,
  onSubmitSearch,
  onClickExport,
  onChangeFilter
};

export default connect(mapStateToProps, actionCreators)(Subcontractors);