import { getTeamUrl } from 'Common'
import screenProps from '../screenProps'

export default () => async () => {

  const { id } = screenProps().match.params

  window.open(getTeamUrl(`sub_orders/${id}/pdf`, 'exports'))

}
