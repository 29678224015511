// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { getTeamUrl } from 'Common';

import {
  CheckCircleFilled,
  CloseCircleFilled,
  EditFilled,
  ExclamationCircleFilled,
  InfoCircleOutlined,
  RocketFilled,
} from '@ant-design/icons';

import { Table, Popover, Tooltip } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import ValuationServicesPopover from '../../../Modules/ValuationServicesPopover'

const getUplift = record => {
  if (!record.project.uplift_percentage) return false
  return (
    <Tooltip title={record.project.uplift_notes}>
      <div style={{ color: 'red' }}>{`⇑ ${record.project.uplift_percentage}% Uplift`}</div>
    </Tooltip>
  )
}

type Props = {
  valuations: [],
  pagination: {},
  fetching: boolean,
  
  onChangeTable: () => void
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      valuations,
      pagination,
      fetching,

      onChangeTable
    } = this.props;

    const statusIcons = {
      '1': (
        <EditFilled style={{ fontSize: 15}} />
      ),
      '2': (
        <RocketFilled style={{ fontSize: 15, color: '#179ded'}} />
      ),
      '3': (
        <CloseCircleFilled style={{ fontSize: 15, color: 'grey' }} />
      ),
      '4': (
        <CheckCircleFilled style={{ fontSize: 15, color: '#32bd6a'}} />
      ),
      '5': (
        <CheckCircleFilled style={{ fontSize: 15, color: '#32bd6a'}} />
      )
    }
    
    const billingTypeColors = {
      'Application': '#AA63E3',
      'Client Valuation': '#339933',
      'Invoice': '#FCBD03',
      'Unknown': '#999',
    }

    const columns = [

      { 
        title: 'Reference',
        dataIndex: '',
        key: 'id',
        width: 160,
        sorter: true,
        fixed: 'left',
        render: (text, record) => (
          <div style={{ width: 128 }}>
            <div>
              <NavLink to={getTeamUrl(`valuations/${record.id}/view`)}>
                { `Valuation ${record.number} ${record.reference_suffix ? `/ ${record.reference_suffix}` : ''}` }
              </NavLink>
            </div>
            <div>
              {record.reference}
            </div>
          </div>
        ),
      },

      {
        title: 'Billing',
        dataIndex: 'project.billing_type',
        width: 130,
        fixed: 'left',
        key: 'project.billing_type',
        sorter: true,
        render: (text, record) => (
          <span style={{ backgroundColor: billingTypeColors[record.project.billing_type], padding: 5, borderRadius: 5, color: '#FFF', fontSize: 12, fontWeight: '500' }}>
            {record.project.billing_type}
          </span>
        )
      },
      
      {
        title: 'Client / Site / Project',
        dataIndex: '',
        key: 'client.name',
        width: 252,
        sorter: true,
        fixed: 'left',
        render: (text, record) => (
          <div>
            <div className="ellipsis" style={{ width: 220 }}>
              <strong>
                {record.project.client_site.client.name}
              </strong>
            </div>
            <div className="ellipsis" style={{ width: 220 }}>
              {record.project.client_site.name}
            </div>
            <div className="ellipsis" style={{ width: 220 }}>
              <strong>
                {record.project.id}
              </strong>
            </div>
          </div>
        )
      },

      {
        title: '',
        dataIndex: '',
        key: 'notes',
        width: 25,
        sorter: false,
        fixed: 'left',
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } })
      },

      {
        title: 'Date',
        dataIndex: '',
        width: 150,
        sorter: true,
        key: 'date',
        render: (text, record) => (
          <div>
            {record.date ? moment(record.date).format('DD MMM YYYY') : '--'}
          </div>
        ),
      },

      {
        title: 'Up to',
        dataIndex: '',
        width: 150,
        sorter: true,
        key: 'valuation_to_date',
        render: (text, record) => (
          <div>
            {record.valuation_to_date ? moment(record.valuation_to_date).format('DD MMM YYYY') : '--'}
          </div>
        ),
      },

      {
        title: 'Value',
        dataIndex: '',
        width: 150,
        sorter: true,
        key: 'complete_total',
        render: (text, record) => (
          <React.Fragment>
            <ValuationServicesPopover valuationId={record.id}>
              <div>
                {`${record.complete_total < 0 ? '-' : ''}£${numeral(Math.abs(record.complete_total)).format('0,0.00')}`}
                <div style={{ fontSize: 12 }}>
                  {record.valuation_id ? (
                    <div style={{ color: record.complete_total_diff < 0 ? 'red' : 'green' }}>
                      {`${record.complete_total_diff < 0 ? '-' : '+'}£${numeral(Math.abs(record.complete_total_diff)).format('0,0.00')}`}
                    </div>
                  ) : (
                    <div style={{ color: record.complete_total < 0 ? 'red' : 'green' }}>
                      {`${record.complete_total < 0 ? '-' : '+'}£${numeral(Math.abs(record.complete_total)).format('0,0.00')}`}
                    </div>
                  )}
                </div>
              </div>
            </ValuationServicesPopover>
            <div style={{ fontSize: 12 }}>
              {getUplift(record)}
            </div>
          </React.Fragment>
        )
      },

      {
        title: 'Contracts Managers',
        key: 'contracts_managers',
        width: 220,
        sorter: false,
        render: (text, record) => (
          <div>
            {record.project.contracts_manager ? <div>{`${record.project.contracts_manager.first_name} ${record.project.contracts_manager.last_name}`}</div> : ''}
            {record.project.other_contracts_manager ? <div>{`${record.project.other_contracts_manager.first_name} ${record.project.other_contracts_manager.last_name}`}</div> : ''}
          </div>
        ),
      },

      {
        title: 'Account',
        dataIndex: '',
        width: 150,
        key: 'account',
        render: (text, record) => {

          let finalAccountStatus = null;
          let finalAccountColor = null;

          if (record.final === 1) {
            if (!record.final_confirmed_at) {
              finalAccountStatus = 'Draft Final';
              finalAccountColor = '#179ded'
            } else if (record.final_confirmed_at) {
              finalAccountStatus = 'Final Account';
              finalAccountColor = '#32bd6a';
            }
          }

          return (!!finalAccountStatus && (
            <span style={{ backgroundColor: finalAccountColor, padding: 5, borderRadius: 5, color: '#FFF' }}>
              { finalAccountStatus }
            </span>
          ))

        }
      },

      {
        title: 'Status',
        dataIndex: 'status.title',
        width: 170,
        key: 'status.key',
        render: (text, record) => (
          <span>
            {statusIcons[record.status.key]}
            {` ${record.status.title}`}
          </span>
        )
      },

      {
        title: 'Payment Certificate',
        dataIndex: 'payment_certificate_uploaded',
        width: 170,
        key: 'status.key',
        render: (text, record) => (
          <React.Fragment>
            {record.payment_certificate_storage_path ? (
              <div style={{ fontWeight: 600, color: '#32bd6a' }}>
                <CheckCircleFilled style={{ marginRight: 5 }} />
                File Uploaded
              </div>
            ) : (
              <div style={{ fontWeight: 600, color: '#e25151' }}>
                <ExclamationCircleFilled style={{ marginRight: 5 }} />
                Not Uploaded
              </div>
            )}
          </React.Fragment>
        )
      }
    ];

    const scroll = { x: columns.reduce((total, column) => total + column.width, 150) };

    return (
      <Table 
        dataSource={valuations}
        columns={columns} 
        rowKey={record => record.id}
        scroll={scroll}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination,
          style: { float: 'none', textAlign: 'center' }
        }}
      />
    )
  };
}
