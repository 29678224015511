import { createAction, handleActions } from 'redux-actions'

const setSorting = createAction("SCREENS/INVOICES/SET_SORTING")

const defaultState = {
  columnKey: 'id',
  order: 'descend',
}

const reducer = handleActions(
  {
    [setSorting]: (state, action) => Object.assign({}, state, action.payload),
  },
  defaultState
)

export { setSorting, reducer }