import { getProps } from '../../../props'
import { openSubOrderModal } from '../State/subOrderModal'

export default () => (dispatch) => {

  const { id: subcontractorId } = getProps().match.params

  dispatch(openSubOrderModal({ subcontractorId: Number(subcontractorId) }))

}
