// @flow
import * as React from 'react';
import numeral from 'numeral';
import classnames from 'classnames';
import { CopyOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { SortableElement } from 'react-sortable-hoc';
import styles from './GroupFooter.css';

import type {
  OrderGroup,
  AddOrderItemParams,
  RemoveOrderGroupParams,
} from '../../../types';

type Props = {
  editable: boolean,
  orderGroup: OrderGroup,
  orderGroupIndex: number,
  onClickAddOrderItem: (params: AddOrderItemParams) => void,
  onClickDuplicateOrderGroup: (params: any) => void,
  onClickRemoveOrderGroup: (params: RemoveOrderGroupParams) => void,
}

class Component extends React.PureComponent<Props> {

  render () {

    const {
      editable,
      orderGroup,
      orderGroupIndex,
      onClickAddOrderItem,
      onClickDuplicateOrderGroup,
      onClickRemoveOrderGroup
    } = this.props;

    const groupTotal = orderGroup.order_items
      .filter(item => item.flag !== 'Omit' && item.flag !== 'By Others')
      .reduce((total, orderItem) => total + Number(orderItem.total), 0);

    return (
      <div
        className={styles.wrapper}
      >

        {editable && (

          <React.Fragment>
          
            <div
              className={classnames(styles.link, styles.addLink)}
              onClick={() => onClickAddOrderItem({ orderGroupIndex })}
              role="presentation"
            >
              <PlusOutlined />
              {' Add Item'}
            </div>

            <div
              className={classnames(styles.link)}
              onClick={() => onClickDuplicateOrderGroup({ orderGroupIndex })}
              role="presentation"
            >
              <CopyOutlined />
              {' Duplicate Group'}
            </div>

            <Popconfirm
              title="Remove this group?"
              onConfirm={() => onClickRemoveOrderGroup({ orderGroupIndex })}
              okText="Yes"
              cancelText="No"
            >
              <div
                className={classnames(styles.link, styles.removeLink)}
                role="presentation"
              >
                <DeleteOutlined />
                {' Remove Group'}
              </div>
            </Popconfirm>

          </React.Fragment>
          
        )}
        

        <div className={styles.total}>
          {`Total for Group: £${numeral(groupTotal).format('0,0.00')}`}
        </div>

      </div>
    );
  }
}

export default SortableElement(Component);