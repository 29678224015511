import moment from 'moment';
import { getProjects } from '../../../HttpRequests/projects';
import { loadProjects } from '../State/projects';
import { setFetching } from '../State/fetching';
import { setPagination } from '../State/pagination';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const { pagination, sorting, searchTerm, filters } = getState().screens.ProfitAndLoss;

  try {

    const params = {};

    params['filter[profit_and_loss]'] = 1;

    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;
    
    params.include = [
      'contracts_manager',
      'other_contracts_manager',
      'profit_and_loss_set_by_user',
    ].join()

    if (sorting.columnKey) {
      params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
    }

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (filters.status_key !== 'all') {
      params['filter[status_key]'] = filters.status_key;
    }

    if (filters.phase_key.length > 0) {
      params['filter[phase_key]'] = filters.phase_key.join(',');
    }

    if (filters.client_id.length > 0) {
      params['filter[client_id]'] = filters.client_id.join(',');
    }

    if (filters.contracts_manager.length > 0) {
      params['filter[contracts_manager]'] = filters.contracts_manager.join(',');
    }

    if (Array.isArray(filters.start_between) && filters.start_between.length === 2) {
      params['filter[start_between]'] = filters.start_between.map(date => moment(date).format('YYYY-MM-DD')).join();
    }

    if (Array.isArray(filters.date_between) && filters.date_between.length === 2) {
      params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join();
    }

    if (filters.outstanding_income) {
      params['filter[outstanding_income]'] = 1;
    }

    dispatch(setFetching(true));

    const getProjectsResponse = await getProjects({ params });

    dispatch(loadProjects(getProjectsResponse.data.data));

    dispatch(setPagination({
      total: getProjectsResponse.data.pagination.total,
      pageSize: getProjectsResponse.data.pagination.per_page,
      current: getProjectsResponse.data.pagination.current_page
    }));

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error);

  }
}