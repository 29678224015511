import { Modal } from 'antd'
import deleteSubcontractor from '../Services/delete'

export default () => (dispatch) => {

  Modal.confirm({
    title: `Are you sure to delete this subcontractor?`,
    content: 'This will also delete their user account (if they have one)',
    okText: 'Yes',
    width: 400,
    onOk: () => dispatch(deleteSubcontractor()),
  })

}
