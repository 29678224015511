import moment from 'moment';
import { omit } from 'lodash';
import { getValuationTotals } from '../../../HttpRequests/valuations';
import { loadValuationTotals } from '../State/valuationTotals';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const { searchTerm, filters } = getState().screens.Valuations;

  try {

    const params = {};

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (filters.status_key !== 'all') {
      params['filter[status_key]'] = filters.status_key;
    }
    
    if (filters.billing_type) {
      params['filter[billing_type]'] = filters.billing_type;
    }
    
    if (filters.client_id.length > 0) {
      params['filter[client_id]'] = filters.client_id.join(',');
    }
    
    if (Array.isArray(filters.date_between) && filters.date_between.length === 2)  {
      params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join();
    }

    if (filters.contracts_manager) {
      params['filter[contracts_manager]'] = filters.contracts_manager;
    }

    if (filters.payment_certificate_uploaded === 0) {
      params['filter[payment_certificate_not_uploaded]'] = 1;
    }
  
    if (filters.payment_certificate_uploaded === 1) {
      params['filter[payment_certificate_uploaded]'] = 1;
    }

    const response = await getValuationTotals({ params: omit(params, ['page[size]', 'page[number]', 'csort']) });

    dispatch(loadValuationTotals(response.data.data));

  } catch (error) {

    handleException(error);
    
  }
}