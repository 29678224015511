import { message } from 'antd'
import screenProps from '../screenProps'
import { updateSubValuation } from '../../../HttpRequests/subValuations'
import fetch from './fetch'
import handleException from '../../../Core/Helpers/handleException'

export default (notes) => async (dispatch) => {

  const { id } = screenProps().match.params

  const data = {
    notes,
  }

  try {

    const response = await updateSubValuation({ id, data })

    message.success(`Updated "${response.data.reference}"`)

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  }

}
