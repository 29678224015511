// @flow

import * as React from 'react';
import numeral from 'numeral';
import { EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import styles from './Component.css';

import type { ValuationTotals } from '../../types';

type Props = {
  valuationTotals: ValuationTotals,
  valuation: any,
  editable: boolean,
  onClickEdit: () => void,
  onClickCancel: () => void,
  onClickSave: () => void,
  onClickSetCompletion: () => void
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      valuationTotals,
      valuation,
      editable,
      onClickEdit,
      onClickCancel,
      onClickSave,
      onClickSetCompletion
    } = this.props;

    return (
      <div className={styles.wrapper}>

        <div className={styles.buttons}>

          {!editable && (
            
            <Button
              className={styles.editButton}
              onClick={onClickEdit}
              disabled={valuation.action_statuses.handle_items !== true}
            >
              <EditOutlined />
              {'Edit Valuation'}
            </Button>

          )}

          {editable && (

            <React.Fragment>

              <Button
                className={styles.saveButton}
                onClick={onClickSave}
                type="primary"
              >
                {'Save Changes'}
              </Button>

              <div style={{ width: 8 }} />

              <Popconfirm
                title="Cancel your changes?"
                onConfirm={onClickCancel}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className={styles.cancelButton}
                  type="dashed"
                >
                  {'Cancel'}
                </Button>
              </Popconfirm>

              <Button
                className={styles.competionButton}
                onClick={onClickSetCompletion}
                type="dashed"
              >
                {'Set Completion'}
              </Button>

            </React.Fragment>

          )}
            
        </div>
  
        <div className={styles.totals}>
          
          <div className={styles.total}>
            {`Previous: £${numeral(valuationTotals.previous_aggregate_total).format('0,0.00')}`}
          </div>

          <div className={styles.total}>
            {`New: £${numeral(valuationTotals.new_aggregate_total).format('0,0.00')}`}
          </div>

          <div className={styles.total}>
            {`Difference: £${numeral(valuationTotals.difference).format('0,0.00')}`}
          </div>

          <div className={styles.total}>
            {`Remaining: £${numeral(valuation.project.value_remaining).format('0,0.00')}`}
          </div>

        </div>

      </div>
    );
  }
}