import { setSorting } from '../../../State/sorting'
import { setPagination } from '../../../State/pagination'
import fetch from '../../../Services/fetch'

export default (pagination, filters, sorter) => (dispatch) => {
  
  dispatch(setSorting(sorter))

  dispatch(setPagination({
    ...pagination,
    pageNumber: pagination.current,
  }))

  dispatch(fetch())

}
