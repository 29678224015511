import { omit } from 'lodash'
import moment from 'moment'
import handleException from '../../../Core/Helpers/handleException'
import { getInvoiceTotals } from '../../../HttpRequests/invoices'
import { setFetching } from '../State/fetching'
import { loadTotals } from '../State/totals'

export default () => async (dispatch, getState) => {

  try {

    const { filters, searchTerm } = getState().screens.Invoices

    const params = {}

    if (searchTerm) {
      params.search = searchTerm
    }
    
    if (filters.type === 'INVOICES') {
      params['filter[type]'] = 'INVOICE_SINGLE,INVOICE_SPLIT'
    } else if (filters.type === 'APPLICATIONS') {
      params['filter[type]'] = 'APPLICATION'
    }
    
    if (filters.client_id.length > 0) {
      params['filter[client_id]'] = filters.client_id.join(',');
    }
    
    if (Array.isArray(filters.date_between) && filters.date_between.length === 2)  {
      params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join()
    }

    const response = await getInvoiceTotals({ params: omit(params, ['include', 'page[size]', 'page[number]', 'csort']) })

    dispatch(loadTotals(response.data.data))

  } catch (error) {

    handleException(error)

    dispatch(setFetching(false))

  }
}