import { createAction, handleActions } from 'redux-actions';

const setActiveTab = createAction("SCREENS/CLIENT_VIEW/SET_ACTIVE_TAB");
const resetActiveTab = createAction("SCREENS/CLIENT_VIEW/RESET_ACTIVE_TAB");

const defaultState = 'CONTACTS';

const reducer = handleActions(
  {
    [setActiveTab]: (state, action) => action.payload,
    [resetActiveTab]: () => defaultState
  },
  defaultState
);

export { setActiveTab, resetActiveTab, reducer };
