export default (values) => {

  const errors = {};

  if (!values.to || values.to && values.to.length === 0) errors.to = true;
  if (!values.subject) errors.subject = true;
  if (!values.body) errors.body = true;

  return errors;
  
}