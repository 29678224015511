// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';
import recalculateEstimateOptional from './recalculateEstimateOptional';
import type { EstimateOptional } from '../types';

export type UpdateEstimateOptionalFieldParams = {
  estimateOptionalIndex: number,
  field: string,
  value: string|null
}

export default (params: UpdateEstimateOptionalFieldParams) => (dispatch: Function, getState: Function) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);

  estimate = estimate.setIn(
    ['estimate_optionals', params.estimateOptionalIndex, params.field],
    params.value
  )

  if (params.field === 'type' && !params.value) {
    
    estimate = estimate.updateIn(
      ['estimate_optionals', params.estimateOptionalIndex],
      (estimateOptional: EstimateOptional) => Object.assign({}, estimateOptional, {
        quantity: '0.00',
        unit: 'm2',
        rate: '0.00',
        subtotal: '0.000',
        discount: '0.00',
        total: '0.00'
      })
    )

  }

  estimate = estimate.toJS();

  dispatch(loadEstimate(estimate));

  if (['rate', 'quantity'].includes(params.field)) {

    dispatch(recalculateEstimateOptional({
      estimateOptionalIndex: params.estimateOptionalIndex
    }))

  }

}