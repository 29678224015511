import { without, concat, find } from 'lodash';
import { setSelectedOrderItemIds } from '../../../State/selectedOrderItemIds';

export default ({ id, orderGroupIndex, orderItemIndex }) => async (dispatch, getState) => {

  const { selectedOrderItemIds: ids } = getState().screens.OrderView;

  const match = find(ids, { id, orderGroupIndex, orderItemIndex });

  const newIds = match ? without(ids, match) : concat(ids, { id, orderGroupIndex, orderItemIndex });

  dispatch(setSelectedOrderItemIds(newIds));
}