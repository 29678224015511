// @flow
import * as React from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { Table, Tag } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { getTeamUrl } from 'Common';

type Props = {
  clients: [],
  pagination: {},
  fetching: boolean,

  onChangeTable: () => void
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      clients,
      pagination,
      fetching,

      onChangeTable
    } = this.props;

    const columns = [

      { 
        title: 'Name', 
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: (text, record) => (
          <React.Fragment>
            <div style={{ display: 'flex' }}>
              <NavLink to={getTeamUrl(`clients/${record.id}/view`)}>
                {record.name}
                &nbsp;
                {record.alias_name ? `(${record.alias_name})`: ''}
              </NavLink>
              &nbsp;
              {!!record.preferred_supplier && !record.preferred_supplier_perc && <Tag icon={<CheckCircleOutlined />} color='blue'>Pref Supplier</Tag>}
              {!!record.preferred_supplier && !!record.preferred_supplier_perc && <Tag icon={<CheckCircleOutlined />} color='blue'>{`Pref Supplier • ${numeral(record.preferred_supplier_perc).format('0,0.[00]')}%`}</Tag>}
            </div>
            {!!record.company_number && <div style={{ fontSize: 13, color: '#9e9e9e' }}>{record.company_number}</div>}
          </React.Fragment>
        )
      },
      
      {
        title: 'Telephone', 
        dataIndex: 'telephone',
      },
      
      {
        title: 'Risk Level', 
        dataIndex: 'client_risk_level_key',
        key: 'client_risk_level_key',
        width: 150,
        sorter: true,
        render: (text, record) => record.client_risk_level && (
          <Tag
            color={({ "1": "green", "2": "orange", "3": "red", "4": "black" })[record.client_risk_level_key]}
            style={{ cursor: 'default' }}
          >
            {record.client_risk_level.title}
          </Tag>
        )
      },
      
      {
        title: 'Credit Limit', 
        dataIndex: 'credit_limit',
        key: 'credit_limit',
        width: 150,
        sorter: true,
        render: (text, record) => record.credit_limit !== null && `£${numeral(record.credit_limit).format('0,0.00')}`
      },
      
      {
        title: 'Experian Limit', 
        dataIndex: 'aldermore_limit',
        key: 'aldermore_limit',
        width: 150,
        sorter: true,
        render: (text, record) => record.aldermore_limit !== null && `£${numeral(record.aldermore_limit).format('0,0.00')}`
      },
      
      {
        title: 'Outstanding', 
        dataIndex: 'value_outstanding',
        key: 'value_outstanding',
        width: 150,
        sorter: true,
        render: (text, record) => record.value_outstanding !== null && `£${numeral(record.value_outstanding).format('0,0.00')}`
      },
      
      {
        title: 'Review Date', 
        dataIndex: 'value_outstanding_review_date',
        key: 'value_outstanding_review_date',
        width: 150,
        sorter: true,
        render: (text, record) => record.value_outstanding_review_date !== null && moment(record.value_outstanding_review_date).format('DD/MM/YYYY')
      },
      
      {
        title: 'Project Remaining', 
        dataIndex: 'projects_value_remaining',
        key: 'projects_value_remaining',
        width: 200,
        sorter: true,
        render: (text, record) => record.projects_value_remaining !== null && `£${numeral(record.projects_value_remaining).format('0,0.00')}`
      },

    ];

    return (
      <Table 
        dataSource={clients}
        columns={columns} 
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination
        }}
        bordered
      />
    )

  };

}