import { connect } from 'react-redux'
import Items from './Items'

import selectListData from './Selectors/selectListData'

import onMoveItem from './Handlers/onMoveItem'

const mapStateToProps = (state) => ({
  listData: selectListData(state),
  editing: state.screens.SubOrder.editing,
})

const actionCreators = {
  onMoveItem,
}

export default connect(mapStateToProps, actionCreators)(Items)
