import { without, concat } from 'lodash';
import { setCheckedItemPaths } from '../State/checkedItemPaths';

export default (path) => async (dispatch, getState) => {

  const { checkedItemPaths } = getState().screens.ProjectView;

  const paths = checkedItemPaths.includes(path) ? without(checkedItemPaths, path) : concat(checkedItemPaths, path);

  dispatch(setCheckedItemPaths(paths));

}