import { closeProjectEditorModal } from '../State/projectEditorModal'
import fetchEstimate from '../Services/fetchEstimate'

export default (reload = false) => (dispatch) => {

  dispatch(closeProjectEditorModal())

  if (reload) {
    dispatch(fetchEstimate())
  }

}
