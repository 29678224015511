// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';

export type RemoveEstimateOptionalParams = {
  estimateOptionalIndex: number
}

export default (params: RemoveEstimateOptionalParams) => (dispatch: Function, getState: Function) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);
  
  estimate = estimate.removeIn(['estimate_optionals', params.estimateOptionalIndex]);

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}