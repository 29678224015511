import { combineReducers } from 'redux'

import { reducer as data } from './State/data'
import { reducer as fetching } from './State/fetching'
import { reducer as search } from './State/search'
import { reducer as filters } from './State/filters'
import { reducer as mode } from './State/mode'
import { reducer as sortBy } from './State/sortBy'

export default combineReducers({
  data,
  fetching,
  search,
  filters,
  mode,
  sortBy,
})
