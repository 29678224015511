import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'
import Marquee from 'react-fast-marquee'

const CellAlert = ({ content, fontSize }) => (

  <Alert
    type='warning'
    banner
    message={(
      <Marquee pauseOnHover gradient={false}>
        {content}
        <span style={{ width: 10 }} />
      </Marquee>
    )}
    style={{ width: '100%', fontSize }}
  />
  
)

CellAlert.propTypes = {
  content: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
}

CellAlert.defaultProps = {
  fontSize: 13,
}

export default CellAlert
