exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3zVAZvqcblvFpE0h5ywks- { \n  display: flex;\n  margin-bottom: 15px;\n}\n\n._3oOJ-nusWSRxhx_RtbZ8t4 {\n  min-width: 70px;\n  text-align: center;\n} ", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/RamsRecords/Components/Filters/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,gBAAgB;EAChB,mBAAmB;CACpB","file":"Component.css","sourcesContent":[".wrapper { \n  display: flex;\n  margin-bottom: 15px;\n}\n\n.radio {\n  min-width: 70px;\n  text-align: center;\n} "],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3zVAZvqcblvFpE0h5ywks-",
	"radio": "_3oOJ-nusWSRxhx_RtbZ8t4"
};