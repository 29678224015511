import { openUploadModal } from '../State/uploadModal'

export default () => async (dispatch, getState) => {

  const { currentDirectoryPath } = getState().modules.FileManager

  if (currentDirectoryPath) {
    dispatch(openUploadModal())
  }

}
