import moduleProps from '../moduleProps'
import fetch from '../Services/fetch'

export default (props) => (dispatch) => {
  
  moduleProps(props)

  dispatch(fetch())

}
