import React from 'react'
import PropTypes from 'prop-types'
import { Table, Timeline, Badge, Divider } from 'antd'
import { useVT } from 'virtualizedtableforantd4'
import { flatMap } from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import styles from './styles.css'

import SubcontractorCell from '../SubcontractorCell'
import ProjectDetails from '../ProjectDetails'
import TimesheetPostingSpan from '../TimesheetPostingSpan'
import CellUnavailable from '../CellUnavailable'
import CellAbsence from '../CellAbsence'
import CellAlert from '../CellAlert'

const Line = ({ content }) => <div style={{ height: '22px', lineHeight: '22px', fontSize: 13, fontWeight: 500 }}>{content}</div>

const getProjectDetails = project => (
  <React.Fragment>
    <ProjectDetails project={project} />
    {project.timesheet_postings.map(timesheetPosting => (
      <TimesheetPostingSpan
        key={timesheetPosting.id}
        timesheetPosting={timesheetPosting}
        nowColor={project.timeline}
      />
    ))}
  </React.Fragment>
)

const SubcontractorTable = props => {

  const {
    periods,
    rows,
    fetching,
    onClickSubcontractor,
    onClickCell,
  } = props

  const [vt] = useVT(() => ({ scroll: { y: window.innerHeight - 290 } }), [])

  const columnWidth = (window.innerWidth - 120 - 300) / 7

  const today = moment().format('YYYY-MM-DD')

  return (

    <Table
      dataSource={rows}
      rowKey='id'
      bordered
      loading={fetching}
      components={vt}
      scroll={{ y: window.innerHeight - 290 }}
      pagination={false}
      className={styles.table}
      style={{
        marginTop: '-1px',
        borderBottom: !!rows.length && '1px solid #f0f0f0',
      }}
    >

      <Table.Column
        key='subcontractor'
        title={`Subcontractors (${rows.length})`}
        onCell={record => ({
          onClick: () => onClickSubcontractor(record),
          style: { height: 165, padding: 0 },
        })}
        render={(text, record) => <SubcontractorCell record={record} />}
      />

      {periods.map((period, index) => (

        <Table.Column
          key={period.date}
          title={(
            <div>
              <div>{period.day_of_week}</div>
              <div style={{ fontSize: 13, fontWeight: 400 }}>{period.date_formatted}</div>
            </div>
          )}
          width={columnWidth}
          onHeaderCell={() => ({
            className: classNames({
              [styles.today]: period.date === today,
              [styles.weekend]: period.is_weekend && period.date !== today,
            }),
          })}
          onCell={record => ({
            onClick: () => {
              const { projects } = record.periods[index]
              const jobs = flatMap(projects.map(project => project.jobs))
              onClickCell(period, record, jobs, record.periods[index].planner_cell)
            },
            style: { height: 165, padding: 0 },
          })}
          render={(text, record) => {
            const { projects, available, absence, alert } = record.periods[index]
            return (
              <div className={styles.cell}>
                <div className={styles.cellBody}>
                  {record.is_multiple ? (
                    <React.Fragment>
                      {projects.map((project, i) => (
                        <React.Fragment key={project.id}>
                          <div className={styles.multiple}>
                            <Badge
                              count={project.slots}
                              size='small'
                              style={{ backgroundColor: project.timeline, fontSize: 9, fontWeight: 500 }}
                            />
                            {getProjectDetails(project)}
                          </div>
                          {i + 1 !== projects.length && <Divider style={{ margin: '12px 0' }} />}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : (
                    <Timeline>
                      {record.periods[index].projects.map((project, i) => (
                        <Timeline.Item
                          key={project.id}
                          color={project.timeline}
                          style={{ padding: i + 1 === record.periods[index].projects.length && 0 }}
                        >
                          {getProjectDetails(project)}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  )}
                </div>
                <div>
                  {!!alert && <CellAlert content={alert} />}
                  {!available && <CellUnavailable />}
                  {available && !!absence && <CellAbsence content={absence} />}
                </div>
              </div>
            )
          }}
        />

      ))}

    </Table>

  )

}

Line.propTypes = {
  content: PropTypes.any.isRequired,
}

SubcontractorTable.propTypes = {
  periods: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onClickSubcontractor: PropTypes.func.isRequired,
  onClickCell: PropTypes.func.isRequired,
}

export default SubcontractorTable
