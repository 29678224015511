/* eslint-disable no-underscore-dangle */

import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';


export default () => async (dispatch) => {

  const { estimate } = getProps();

  const { reference, project, title, user } = estimate;
  
  const referencePrefix = estimate.reference_prefix;
  const referenceSuffix = estimate.reference_suffix;
  const contact = estimate.client_contact;

  const values = {
    to: [],
    cc: ['estimates@vividfinishes.co.uk','kelsie@vividfinishes.co.uk'],
    bcc: [],
    subject: `Estimate - [${referencePrefix} ${reference}${referenceSuffix ? ` / ${ referenceSuffix }` : ''}] ${project.client_site.name} ${title || ''}`,
    body: '',
    id: estimate.id,
    include_attachments: true,
  };
  
  if (contact.email) values.to.push(contact.email);
      
  values.body += `Dear ${estimate.client_contact.first_name}\r\n\r\n`;
  values.body += `Thank you for your enquiry\r\n\r\n`;
  values.body += `Please find attached our estimate for the ${project.client_site.name} project. Please review the estimate and if you require any additional information, please do not hesitate to contact me.\r\n\r\n`;
  values.body += `I look forward to hearing from you.\r\n\r\n`;
  values.body += `Kind Regards\r\n\r\n`;
  
  if (user) {
    
    values.body += `${user.first_name} ${user.last_name}`;

  }


  dispatch(initialize(FORM_NAME, values));

}