import React from 'react';
import { Field } from 'redux-form';
import { InputField } from 'FormFields';

const labelColSpan = 5;
const wrapperColSpan = 18;

type Props = {
  submitting: boolean,
  directoryActionType: string,
  selectedItemType: string
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      submitting,
      directoryActionType,
      selectedItemType
    } = this.props;

    return (

      <div>
        { directoryActionType === 'edit' && selectedItemType === 'file' ? (

          <Field
            required
            autoFocus
            component={InputField}
            name="filename"
            label="File"
            autoComplete="off"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            fieldCol={{ span: 22 }}
            afterCol={{ span: 2 }}
          />

        ): (

          <Field
            required
            autoFocus
            component={InputField}
            name="folder"
            label="Folder"
            autoComplete="off"
            disabled={submitting}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            fieldCol={{ span: 22 }}
            afterCol={{ span: 2 }}
          />

        )}

      </div>

    )
  }
}