import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './GroupFooter.css';

export default class extends React.PureComponent {

  static propTypes = {
    editable: PropTypes.bool.isRequired, 
    orderIndex: PropTypes.number.isRequired,
    orderGroupIndex: PropTypes.number.isRequired,
    onClickSetGroupPercentage: PropTypes.func.isRequired
  }

  render () {

    const {
      editable,
      orderIndex,
      orderGroupIndex,
      onClickSetGroupPercentage
    } = this.props;

    return (

      <div className={styles.wrapper}>

        {editable && (
          <div
            className={classnames(styles.link, styles.addLink)}
            onClick={() => onClickSetGroupPercentage({ orderIndex, orderGroupIndex })}
            role="presentation"
            style={{ marginRight: 110 }}
          >
            {'Set Group %'}
          </div>
        )}

      </div>

    )
  }
}