import chance from 'chance'
import { getInsights } from '../../../HttpRequests/insights'
import { setData } from '../State/data'
import { setFetching } from '../State/fetching'
import { setInteractionId } from '../State/interactionId'
import getParams from '../Utils/getParams'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { setup } = getState().screens.Insights

  if (!setup.field) return

  const params = getParams(getState)

  // Prevent slower API responses from overriding faster ones

  const guid = chance().guid()

  dispatch(setInteractionId(guid))

  params.interaction_id = guid

  dispatch(setFetching(true))

  try {

    const { data } = await getInsights({ params })

    if (data.interaction_id === getState().screens.Insights.interactionId) {
      dispatch(setData(data.data))
    }

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setFetching(false))

  }

}
