import { setSorting } from '../../../State/sorting'
import fetch from '../../../Services/fetch'

export default (pagination, filters, sorter) => (dispatch) => {
  
  dispatch(setSorting(sorter))

  dispatch(fetch())

}
