import React from 'react'
import { Table, Dropdown, Menu } from 'antd'
import { ArrowDownOutlined, ArrowUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import numeral from 'numeral'
import styles from './ProjectsTable.css'

const mon = value => `${value < 0 ? '-' : ''}£${numeral(Math.abs(value)).format('0,0')}`
const rat = value => `${value < 0 ? '-' : ''}${numeral(Math.abs(value)).format('0,0')}%`

const fieldSortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 120 }}>
    <Menu.ItemGroup title='Sort' />
    <Menu.Divider />
    <Menu.Item key='field.asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Ascending</Menu.Item>
    <Menu.Item key='field.desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Descending</Menu.Item>
  </Menu>
)

const valueSortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 120, float: 'right' }}>
    <Menu.ItemGroup title='Sort by' />
    <Menu.Divider />
    <Menu.SubMenu title='Value'>
      <Menu.SubMenu title='Amount (£)'>
        <Menu.Item key='value_total.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='value_total.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='value_total_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='value_total_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu title='Completed'>
      <Menu.SubMenu title='Amount (£)'>
        <Menu.Item key='value_completed.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='value_completed.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='value_completed_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='value_completed_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu title='Remaining'>
      <Menu.SubMenu title='Amount (£)'>
        <Menu.Item key='value_remaining.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='value_remaining.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='value_remaining_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='value_remaining_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu title='Income'>
      <Menu.SubMenu title='Amount (£)'>
        <Menu.Item key='income.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='income.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='income_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='income_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
  </Menu>
)

const salesSortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 120, float: 'right' }}>
    <Menu.ItemGroup title='Sort by' />
    <Menu.Divider />
    <Menu.SubMenu title='Total'>
      <Menu.SubMenu title='Amount (£)'>
        <Menu.Item key='sales_total.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='sales_total.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='sales_total_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='sales_total_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu title='Labour'>
      <Menu.SubMenu title='Amount (£)'>
        <Menu.Item key='sales_labour.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='sales_labour.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='sales_labour_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='sales_labour_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu title='Materials'>
      <Menu.SubMenu title='Amount (£)'>
        <Menu.Item key='sales_materials.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='sales_materials.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='sales_materials_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='sales_materials_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu title='Equipment'>
      <Menu.SubMenu title='Amount (£)'>
        <Menu.Item key='sales_equipment.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='sales_equipment.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='sales_equipment_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='sales_equipment_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
  </Menu>
)

const profitAndLossSortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 180, float: 'right' }}>
    <Menu.ItemGroup title='Sort by' />
    <Menu.Divider />
    <Menu.SubMenu title='P&L'>
      <Menu.SubMenu title='Amount (£)'>
        <Menu.Item key='profit_and_loss.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='profit_and_loss.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Percent (%)'>
        <Menu.Item key='profit_and_loss_ratio.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='profit_and_loss_ratio.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
  </Menu>
)

const getSortByIcon = direction => <LegacyIcon type={`arrow-${direction}`} style={{ marginRight: 5, color: 'rgba(0, 0, 0, 0.65)' }} />

type Props = {
  setup: Object,
  sortBy: String,
  data: [],
  fetching: Boolean,
  onChangeSort: Function,
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      setup,
      sortBy,
      data,
      fetching,
      onChangeSort,
    } = this.props

    const columns = [
      {
        title: (
          <Dropdown overlay={fieldSortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <div>{setup.fields[setup.model][setup.field]}</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'field',
        width: 150,
        render: (text, record) => (
          <div>
            <div style={{ fontSize: 18, fontWeight: 500 }}>
              {sortBy === 'field.asc' && getSortByIcon('up')}
              {sortBy === 'field.desc' && getSortByIcon('down')}
              {record.field}
            </div>
            {record.h2 && <div style={{ fontSize: 16, fontWeight: 500 }}>{record.h2}</div>}
            {record.h3 && <div>{record.h3}</div>}
          </div>
        ),
      },
      {
        title: (
          <Dropdown overlay={valueSortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}>
              <div>Value</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'value',
        width: 200,
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div>
              {sortBy === 'value_total.asc' && getSortByIcon('down')}
              {sortBy === 'value_total.desc' && getSortByIcon('up')}
              <strong style={{ fontSize: 16 }}>
                {mon(record.value_total)}
              </strong>
            </div>
            <div>&nbsp;</div>
            <div>
              {sortBy === 'value_completed.asc' && getSortByIcon('down')}
              {sortBy === 'value_completed.desc' && getSortByIcon('up')}
              Completed:
              <strong>
                {` ${mon(record.value_completed)}`}
              </strong>
              {` ${rat(record.value_completed_ratio)}`}
            </div>
            <div>
              {sortBy === 'value_remaining.asc' && getSortByIcon('down')}
              {sortBy === 'value_remaining.desc' && getSortByIcon('up')}
              Remaining:
              <strong>
                {` ${mon(record.value_remaining)}`}
              </strong>
              {` ${rat(record.value_remaining_ratio)}`}
            </div>
            <div style={{ color: record.income < record.value_completed ? 'red' : 'green' }}>
              {sortBy === 'income.asc' && getSortByIcon('down')}
              {sortBy === 'income.desc' && getSortByIcon('up')}
              Income:
              <strong>
                {` ${mon(record.income)}`}
              </strong>
              {` ${rat(record.income_ratio)}`}
            </div>
          </div>
        )
      },
      {
        title: (
          <Dropdown overlay={salesSortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}>
              <div>Sales</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'sales',
        width: 200,
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div>
              {sortBy === 'sales_total.asc' && getSortByIcon('down')}
              {sortBy === 'sales_total.desc' && getSortByIcon('up')}
              <strong style={{ fontSize: 16 }}>
                {mon(record.sales_total)}
              </strong>
            </div>
            <div>&nbsp;</div>
            <div>
              {sortBy === 'sales_labour.asc' && getSortByIcon('down')}
              {sortBy === 'sales_labour.desc' && getSortByIcon('up')}
              Labour:
              <strong>
                {` ${mon(record.sales_labour)}`}
              </strong>
              {` ${rat(record.sales_labour_ratio)}`}
            </div>
            <div>
              {sortBy === 'sales_materials.asc' && getSortByIcon('down')}
              {sortBy === 'sales_materials.desc' && getSortByIcon('up')}
              Materials:
              <strong>
                {` ${mon(record.sales_materials)}`}
              </strong>
              {` ${rat(record.sales_materials_ratio)}`}
            </div>
            <div>
              {sortBy === 'sales_equipment.asc' && getSortByIcon('down')}
              {sortBy === 'sales_equipment.desc' && getSortByIcon('up')}
              Equipment:
              <strong>
                {` ${mon(record.sales_equipment)}`}
              </strong>
              {` ${rat(record.sales_equipment_ratio)}`}
            </div>
          </div>
        )
      },
      {
        title: (
          <Dropdown overlay={profitAndLossSortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}>
              <div>P&L</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'profit_and_loss',
        width: 200,
        className: styles.numericColumn,
        render: (text, record) => (
          <div style={{ color: record.profit_and_loss < 0 ? 'red' : 'green' }}>
            <div>
              {sortBy === 'profit_and_loss.asc' && getSortByIcon('down')}
              {sortBy === 'profit_and_loss.desc' && getSortByIcon('up')}
              <strong style={{ fontSize: 18 }}>
                {mon(record.profit_and_loss)}
              </strong>
            </div>
            <div>
              {sortBy === 'profit_and_loss_ratio.asc' && getSortByIcon('down')}
              {sortBy === 'profit_and_loss_ratio.desc' && getSortByIcon('up')}
              <span style={{ fontSize: 16 }}>
                {rat(record.profit_and_loss_ratio)}
              </span>
            </div>
          </div>
        )
      },
    ]

    return (

      <Table
        columns={columns}
        dataSource={data}
        rowKey='key'
        loading={fetching}
      />

    )

  }

}
