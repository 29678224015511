// @flow

import { fromJS } from 'immutable';
import { loadOrder } from '../State/order';
import recalculateOrderItem from './recalculateOrderItem';
import type { OrderItem } from '../types';

export type UpdateOrderItemFieldParams = {
  orderGroupIndex: number,
  orderItemIndex: number,
  field: string,
  value: string|null
}

export default (params: UpdateOrderItemFieldParams) => (dispatch: Function, getState: Function) => {

  let order = fromJS(getState().screens.OrderView.order);

  order = order.setIn(
    ['order_groups', params.orderGroupIndex, 'order_items', params.orderItemIndex, params.field],
    params.value
  )

  if (params.field === 'type' && !params.value) {
    
    order = order.updateIn(
      ['order_groups', params.orderGroupIndex, 'order_items', params.orderItemIndex],
      (orderItem: OrderItem) => Object.assign({}, orderItem, {
        flag: null,
        quantity: '0.00',
        unit: 'm2',
        rate: '0.00',
        subtotal: '0.000',
        discount: '0.00',
        total: '0.00'
      })
    )

  }

  order = order.toJS();

  dispatch(loadOrder(order));

  if (['rate', 'quantity'].includes(params.field)) {

    dispatch(recalculateOrderItem({
      orderGroupIndex: params.orderGroupIndex,
      orderItemIndex: params.orderItemIndex
    }))

  }

}