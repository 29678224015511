import { message } from 'antd'
import { detachSubValuationFromSubInvoice } from '../../../HttpRequests/subInvoices'
import fetch from './fetch'
import handleException from '../../../Core/Helpers/handleException'

export default (subValuation) => async (dispatch, getState) => {

  const { subInvoice } = getState().screens.SubInvoice
  
  try {

    await detachSubValuationFromSubInvoice({ id: subInvoice.id, subValuationId: subValuation.id })

    message.success(`Unlinked "${subValuation.reference}"`)

    dispatch(fetch())
    
  } catch (error) {

    handleException(error)

  }
  
}
