import React from 'react';
import { Radio } from 'antd';

import ChartPanel from './ChartPanel';
import Chart from './Chart';

type Props = {
  config: Object,
  mode: string,

  onChangeMode: Function
}

export default class extends React.PureComponent<Props> {
  
  

  render = () => (
      
    <ChartPanel
      title="Year Overview"
      renderTools={() => this.renderTools()}
      renderChart={() => this.renderChart()}
    />
      
  )

  renderTools = () => {
    
    const {
      mode,
      onChangeMode
    } = this.props;
    
    return (
      
      <Radio.Group
        value={mode}
        onChange={onChangeMode}
        defaultValue="months"
        buttonStyle="outline"
        size="small"
      >
        <Radio.Button value="months" key="months">Months</Radio.Button>
        <Radio.Button value="weeks" key="weeks">Weeks</Radio.Button>

      </Radio.Group>

    )

  }

  renderChart = () => (
    <Chart config={this.props.config} />
  )

}
