import moment from 'moment'

export default (getState) => {

  const { setup, filters, searchTerm, sortBy } = getState().screens.Insights

  const params = {
    model: setup.model,
    field: setup.field,
    search: searchTerm || undefined,
    sort_by: sortBy,
  }

  // Project

  if (filters.statusKey.length > 0) {
    params.filter_status_key = filters.statusKey.join()
  }

  if (filters.phaseKey.length > 0) {
    params.filter_phase_key = filters.phaseKey.join()
  }

  if (filters.startBetween.length === 2) {
    params.filter_start_between = filters.startBetween.map(date => moment(date).format('YYYY-MM-DD')).join()
  }

  if (filters.archivedBetween.length === 2) {
    params.filter_date_between = filters.archivedBetween.map(date => moment(date).format('YYYY-MM-DD')).join()
  }

  // Estimate

  if (filters.clientId.length > 0) {
    params.filter_client_id = filters.clientId.join()
  }

  if (filters.userId.length > 0) {
    params.filter_user_id = filters.userId.join()
  }

  if (filters.dateBetween.length === 2) {
    params.filter_date_between = filters.dateBetween.map(date => moment(date).format('YYYY-MM-DD')).join()
  }

  if (filters.newOrExtra === 'new') {
    params.filter_extra_works = 0
  }

  if (filters.newOrExtra === 'extra') {
    params.filter_extra_works = 1
  }

  if (filters.withRevisions) {
    params.filter_with_revisions = 1
  }

  // Timesheet

  if (filters.subcontractorId.length > 0) {
    params.filter_subcontractor_id = filters.subcontractorId.join()
  }

  if (filters.projectId.length > 0) {
    params.filter_project_id = filters.projectId.join()
  }

  if (filters.contractsManagerId.length > 0) {
    params.filter_contracts_manager_id = filters.contractsManagerId.join()
  }

  if (filters.dateRange.length === 2) {
    params.filter_date_range = filters.dateRange.map(date => moment(date).format('YYYY-MM-DD')).join()
  }

  if (filters.hoursType === 'hourly-rate') {
    params.filter_hourly_rate = 1
  }

  if (filters.hoursType === 'day-rate') {
    params.filter_day_rate = 1
  }

  if (filters.hoursType === 'price-work') {
    params.filter_price_work = 1
  }

  return params

}
