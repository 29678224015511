import { createAction, handleActions } from 'redux-actions';

const loadCaveats = createAction('SCREENS/CAVEATS/LOAD_CAVEATS');

const defaultState = [];

const reducer = handleActions(
  {
    [loadCaveats]: (state, action) => action.payload,
  },
  defaultState
);

export { loadCaveats, reducer };