import React from 'react'
import { Modal } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import deleteSubInvoice from '../../../Services/delete'

export default () => (dispatch, getState) => {

  const { subInvoice } = getState().screens.SubInvoice

  Modal.confirm({
    title: `Delete "${subInvoice.reference}"`,
    content: (
      <React.Fragment>
        <p>Are you sure you want to delete this invoice?</p>
        <p>This will also delete the linked valuations.</p>
      </React.Fragment>
    ),
    icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
    okType: 'danger',
    okText: 'Delete',
    onOk: () => dispatch(deleteSubInvoice()),
  })

}
