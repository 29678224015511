import { deleteOrderFile } from '../../../HttpRequests/orders';

import handleException from '../../../Core/Helpers/handleException';
import fetchOrder from './fetchOrder';
import { getProps } from '../props';

export default () => async (dispatch) => {
    
  const { id } = getProps().match.params;

  try {
    
    await deleteOrderFile({ id });

    dispatch(fetchOrder());
    
  } catch(error) {

    handleException(error);

  }

}