import { connect } from 'react-redux'
import Users from './Users'

import componentDidMount from './Handlers/componentDidMount'
import onChangeSearch from './Handlers/onChangeSearch'
import onSubmitSearch from './Handlers/onSubmitSearch'
import onChangeFilter from './Handlers/onChangeFilter'
import onChangeTable from './Handlers/onChangeTable'
import onClickRow from './Handlers/onClickRow'
import onClickExport from './Handlers/onClickExport'
import onClickNewUser from './Handlers/onClickNewUser'
import onCloseUserModal from './Handlers/onCloseUserModal'
import didCreateUser from './Handlers/didCreateUser'
import didUpdateUser from './Handlers/didUpdateUser'
import didDeleteUser from './Handlers/didDeleteUser'
import didDeactivateUser from './Handlers/didDeactivateUser'
import didReactivateUser from './Handlers/didReactivateUser'

const mapStateToProps = (state) => ({
  subcontractors: state.core.subcontractors,
  projects: state.core.projects,
  users: state.screens.Users.users,
  fetching: state.screens.Users.fetching,
  searchTerm: state.screens.Users.searchTerm,
  filters: state.screens.Users.filters,
  pagination: state.screens.Users.pagination,
  userModal: state.screens.Users.userModal,
})

const actionCreators = {
  componentDidMount,
  onChangeSearch,
  onSubmitSearch,
  onChangeFilter,
  onChangeTable,
  onClickRow,
  onClickExport,
  onClickNewUser,
  onCloseUserModal,
  didCreateUser,
  didUpdateUser,
  didDeleteUser,
  didDeactivateUser,
  didReactivateUser,
}

export default connect(mapStateToProps, actionCreators)(Users)
