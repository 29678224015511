import { message } from 'antd';
import { createValuation } from '../../../HttpRequests/valuations';

export default (values) => async () => {

  const data = { ...values }

  const response = await createValuation({ data });

  const valuation = response.data.data;

  message.success('Valuation Created.');

  return valuation;

}