import { merge } from 'lodash';
import baseChartConfig from './baseChartConfig';

export default merge({}, baseChartConfig, {

  chart: {
    type: 'line',
  },

  plotOptions: {
    series: {
      label: {
          connectorAllowed: false
      }
    }
  },
  
  yAxis: {
    title: {
      text: 'Value (£)'
    }
  },

  tooltip: {
    pointFormat: '<b>£{point.y:,.0f}</b>'
  },

  legend: {
    enabled: true
  }

});
