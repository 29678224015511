import { connect } from "react-redux"

import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import onCloseRetentionPaymentModal from "./Handlers/onCloseRetentionPaymentModal"
import didUpdateRetentionPayment from "./Handlers/didUpdateRetentionPayment"

const mapStateToProps = (state) => ({
  selectedRetentionPaymentId: state.screens.RetentionPayments.selectedRetentionPaymentId,
  showRetentionPaymentModal: state.screens.RetentionPayments.showRetentionPaymentModal,
})

const actionCreators = {
  componentDidMount,
  onCloseRetentionPaymentModal,
  didUpdateRetentionPayment,
}

export default connect(mapStateToProps, actionCreators)(Component)
