import drawerProps from '../drawerProps'
import { getPlannerPeriodRangeSubcontractors } from '../../../../../HttpRequests/planner'
import { setData } from '../State/data'
import { startFetching, finishFetching } from '../State/fetching'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { periodIndex } = drawerProps()

  const params = {
    period_index: periodIndex,
  }

  dispatch(startFetching())

  try {

    const response = await getPlannerPeriodRangeSubcontractors({ params })

    dispatch(setData(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
