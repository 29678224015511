import { createAction, handleActions } from 'redux-actions'

const openAddToExistingModal = createAction('MODALS/SUB_ORDER/OPEN_ADD_TO_EXISTING_MODAL')
const closeAddToExistingModal = createAction('MODALS/SUB_ORDER/CLOSE_ADD_TO_EXISTING_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openAddToExistingModal]: (state, { payload }) => ({
      ...payload,
      show: true,
    }),
    [closeAddToExistingModal]: () => defaultState,
  },
  defaultState,
)

export { openAddToExistingModal, closeAddToExistingModal, reducer }
