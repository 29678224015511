import { message } from 'antd'
import { updateProject } from '../../../HttpRequests/projects';

export default (values) => async () => {

  const { id } = values;
    
  const data = { ...values };

  const response = await updateProject({ id, data });

  const project = response.data.data;

  message.success("Project Updated");

  return project;

}