import React from 'react'
import PropTypes from 'prop-types'
import { logout } from 'Evolve'

export default class extends React.PureComponent {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  }

  componentDidMount = () => {
    this.props.dispatch(logout())
  }

  render = () => <div />
  
}
