import pluralize from 'pluralize'
import moduleProps from '../moduleProps'
import { getDirectory } from '../../../HttpRequests/modelFiles'
import { setRootDirectories } from '../State/rootDirectories'
import { setCurrentDirectoryPath } from '../State/currentDirectoryPath'
import fetchDirectory from './fetchDirectory'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { modelType, modelId } = moduleProps()

  const { currentDirectoryPath } = getState().modules.FileManager

  const params = {
    path: `${pluralize(modelType)}/${modelId}`,
  }

  try {

    const { data: { directories } } = await getDirectory({ params })

    dispatch(setRootDirectories(directories))

    if (!currentDirectoryPath && directories.length > 0) {

      dispatch(setCurrentDirectoryPath(directories[0].path))

      dispatch(fetchDirectory())

    }

  } catch (error) {

    handleException(error)

  }

}
