export default (values) => {

  const errors = {}

  if (!values.type) errors.type = 'Type is required'

  if (!values.time) errors.time = 'Time is required'

  return errors
  
}
