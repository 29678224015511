import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { DatePickerField } from 'FormFields'

const labelColSpan = 6
const wrapperColSpan = 16

export default class extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
  }

  render() {

    const {
      submitting,
    } = this.props

    return (

      <React.Fragment>

        <Field
          name='date'
          label='Date'
          component={DatePickerField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          style={{ width: 130 }}
        />

        <Field
          name='to_date'
          label='To Date'
          component={DatePickerField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          style={{ width: 130 }}
        />

        <Field
          name='certification_date'
          label='Cert. Date'
          component={DatePickerField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          style={{ width: 130 }}
        />

      </React.Fragment>

    )

  }

}
