import { combineReducers } from 'redux';
import { reducer as yearOverviewReport } from './State/yearOverviewReport';
import { reducer as estimatesByClientReport } from './State/estimatesByClientReport';
import { reducer as lastThreeMonthsReport } from './State/lastThreeMonthsReport';
import { reducer as topClientsReport } from './State/topClientsReport';
import { reducer as servicesBreakdownReport } from './State/servicesBreakdownReport';
import { reducer as newProjectsWithOrderReport } from './State/newProjectsWithOrderReport';
import { reducer as showExportStatisticsModal} from './State/showExportStatisticsModal';
import { reducer as showExportHistoryModal} from './State/showExportHistoryModal';

export default combineReducers({
  yearOverviewReport,
  estimatesByClientReport,
  lastThreeMonthsReport,
  topClientsReport,
  servicesBreakdownReport,
  newProjectsWithOrderReport,
  showExportStatisticsModal,
  showExportHistoryModal,
})
