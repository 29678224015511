import { push } from 'connected-react-router'
import { message } from 'antd'
import { getTeamUrl } from 'Common'
import { deleteSubInvoice } from '../../../HttpRequests/subInvoices'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { subInvoice } = getState().screens.SubInvoice
  
  try {

    await deleteSubInvoice({ id: subInvoice.id })

    message.success(`Deleted "${subInvoice.reference}"`)
    
    dispatch(push(getTeamUrl(`sub_projects/${subInvoice.subcontractor_id}`)))
    
  } catch (error) {

    handleException(error)

  }
  
}
