exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1MQRGxRhtBxPy8lkKgq7XJ {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n._2YFuzk50jAya7DiIb0uIxj {\n  flex: 1;\n  display: flex;\n}\n\n._2U11W4a5GY_ykOVbkYQAYA {\n  display: flex;\n}\n\n._1rz3VQQdhVE8UIA62apssq {\n  width: 280px;\n}\n\n._8Qi0CzT95xlymdhmZVj4B {\n  margin-bottom: 12px;\n}\n\n._8Qi0CzT95xlymdhmZVj4B:last-child {\n  margin-bottom: 4px;\n}\n\n._3Q26pkWw0hvohZDQoR39W {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Subbies/Components/SubValuations/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,cAAc;CACf;;AAED;EACE,cAAc;CACf;;AAED;EACE,aAAa;CACd;;AAED;EACE,oBAAoB;CACrB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;CAClB","file":"styles.css","sourcesContent":[".controls {\n  display: flex;\n  margin-bottom: 12px;\n}\n\n.left {\n  flex: 1;\n  display: flex;\n}\n\n.right {\n  display: flex;\n}\n\n.filters {\n  width: 280px;\n}\n\n.box {\n  margin-bottom: 12px;\n}\n\n.box:last-child {\n  margin-bottom: 4px;\n}\n\n.label {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"controls": "_1MQRGxRhtBxPy8lkKgq7XJ",
	"left": "_2YFuzk50jAya7DiIb0uIxj",
	"right": "_2U11W4a5GY_ykOVbkYQAYA",
	"filters": "_1rz3VQQdhVE8UIA62apssq",
	"box": "_8Qi0CzT95xlymdhmZVj4B",
	"label": "_3Q26pkWw0hvohZDQoR39W"
};