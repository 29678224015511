import { createAction, handleActions } from 'redux-actions'

const openClientContactModal = createAction('MODALS/RAMS_RECORD/OPEN_CLIENT_CONTACT_MODAL')
const closeClientContactModal = createAction('MODALS/RAMS_RECORD/CLOSE_CLIENT_CONTACT_MODAL')

const defaultState = { show: false }

const reducer = handleActions(
  {
    [openClientContactModal]: (state, { payload }) => ({
      show: true,
      ...payload,
    }),
    [closeClientContactModal]: () => defaultState,
  },
  defaultState,
)

export { openClientContactModal, closeClientContactModal, reducer }
