import React from 'react';
import { Field } from 'redux-form';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button } from 'antd';
import { SelectField, InputField, TextAreaField } from 'FormFields';

const labelColSpan = 3;
const wrapperColSpan = 21;

type Props = {
  submitting: boolean,
  emailOptions: [],
  fileList: [],
  selectedEmailToOption: [],
  selectedEmailCcOption: [],
  selectedEmailBccOption: [],
  onSearchEmailOptions: Function,
  onSelectEmailOption: Function,
  onAddFile: Function,
  onRemoveFile: Function,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      submitting,
      emailOptions,
      fileList,
      selectedEmailToOption,
      selectedEmailCcOption,
      selectedEmailBccOption,
      onSearchEmailOptions,
      onSelectEmailOption,
      onAddFile,
      onRemoveFile,
    } = this.props;

    const emailTo = [...emailOptions, ...selectedEmailToOption];
    const emailCc = [...emailOptions, ...selectedEmailCcOption];
    const emailBcc = [...emailOptions, ...selectedEmailBccOption];

    return (
      <div>

        <Field
          required
          component={SelectField}
          name="to"
          label="To"
          mode="tags"
          autoComplete="off"
          options={emailTo}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          onSearch={onSearchEmailOptions}
          onSelect={onSelectEmailOption}
        />

        <Field
          component={SelectField}
          name="cc"
          label="Cc"
          mode="tags"
          autoComplete="off"
          options={emailCc}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          onSearch={onSearchEmailOptions}
          onSelect={onSelectEmailOption}
        />

        <Field
          component={SelectField}
          name="bcc"
          label="Bcc"
          mode="tags"
          autoComplete="off"
          options={emailBcc}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          onSearch={onSearchEmailOptions}
          onSelect={onSelectEmailOption}
        />

        <Field
          required
          component={InputField}
          name="subject"
          label="Subject"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

        <Field
          required
          component={TextAreaField}
          name="body"
          label="Body"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
          autosize={{
            minRows: 10, 
            maxRows: 17
          }}
        />

        <div style={{ marginLeft: 75, marginTop: 10, marginRight: 45 }}>

          <Upload
            fileList={fileList}
            onRemove={onRemoveFile}
            beforeUpload={onAddFile}
            disabled={submitting}
          >
            <Button disabled={submitting}>
              <UploadOutlined />
              {' Attach Files'}
            </Button>
          </Upload>

        </div>

      </div>
    );
  }
}