import { connect } from 'react-redux'
import SubOrderFooter from './SubOrderFooter'

import onAddGroup from './Handlers/onAddGroup'

const mapStateToProps = (state) => ({
  editing: state.screens.SubOrder.editing,
})

const actionCreators = {
  onAddGroup,
}

export default connect(mapStateToProps, actionCreators)(SubOrderFooter)
