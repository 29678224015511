import { combineReducers } from 'redux'

import { reducer as activeTab } from './State/activeTab'

import Subcontractors from './Components/Subcontractors/reducer'
import SubProjects from './Components/SubProjects/reducer'
import SubOrders from './Components/SubOrders/reducer'
import SubValuations from './Components/SubValuations/reducer'
import SubInvoices from './Components/SubInvoices/reducer'

export default combineReducers({
  activeTab,

  Subcontractors,
  SubProjects,
  SubOrders,
  SubValuations,
  SubInvoices,
})
