import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import styles from './styles.css'

const Header = props => {

  const {
    activeTab,
    onChangeTab,
  } = props

  return (

    <div className={styles.wrapper}>

      <div className={styles.left}>

        <h1>Subcontractors</h1>

      </div>

      <div className={styles.right}>

        <Radio.Group
          value={activeTab}
          onChange={e => onChangeTab(e.target.value)}
        >
          <Radio.Button value='subcontractors' className={styles.radioButton}>List</Radio.Button>
          <Radio.Button value='sub-projects' className={styles.radioButton}>Projects</Radio.Button>
          <Radio.Button value='sub-orders' className={styles.radioButton}>Orders</Radio.Button>
          <Radio.Button value='sub-valuations' className={styles.radioButton}>Valuations</Radio.Button>
          <Radio.Button value='sub-invoices' className={styles.radioButton}>Invoices</Radio.Button>
        </Radio.Group>

      </div>

    </div>

  )

}

Header.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
}

export default Header
