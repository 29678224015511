import React from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  PoundCircleOutlined,
  UserAddOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import styles from './styles.css'

import CellInfo from '../CellInfo'

const Line = ({ content }) => <div style={{ height: '22px', lineHeight: '22px', fontSize: 13, fontWeight: 500 }}>{content}</div>

const SubcontractorCell = ({ record }) => (
  <div className={styles.cell}>
    <div className={styles.cellBody}>
      <div style={{ flex: 1 }}>
        <div style={{ fontSize: 15, fontWeight: 600 }}>{record.name}</div>
        {!!record.telephone && <div>{record.telephone}</div>}
        <div style={{ marginTop: 2 }}>
          <Tag color={record.card_type !== 'White' && record.card_type}>
            {record.card_type}
          </Tag>
          {!!record.is_supervisor && <Tag color='volcano'>Supervisor</Tag>}
          {!!record.is_trainee && <Tag color='cyan'>Trainee</Tag>}
        </div>
      </div>
      <div style={{ width: 120, paddingLeft: 10 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <UserAddOutlined style={{ marginRight: 5 }} />
          <Line content={`${moment(record.created_at).fromNow(true)}`} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PoundCircleOutlined style={{ marginRight: 5 }} />
          <Line content={`£${record.hourly_rate}`} />
        </div>
        {record.status === 'Active' && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckOutlined style={{ marginRight: 5 }} />
            <Line content='Active' />
          </div>
        )}
        {record.status === 'Inactive' && (
          <div style={{ display: 'flex', alignItems: 'center', color: '#9e9e9e' }}>
            <CloseOutlined style={{ marginRight: 5 }} />
            <Line content='Inactive' />
          </div>
        )}
      </div>
    </div>
    {!!record.notes && <CellInfo content={record.notes} />}
  </div>
)

Line.propTypes = {
  content: PropTypes.any.isRequired,
}

SubcontractorCell.propTypes = {
  record: PropTypes.object.isRequired,
}

export default SubcontractorCell
