import fetch from '../../../Services/fetch'
import fetchTotals from '../../../Services/fetchTotals'
import { setFilters } from '../../../State/filters'
import { setPagination } from '../../../State/pagination'

export default (filter, value) => async (dispatch) => {
  
  dispatch(setFilters({ [filter]: value }))

  dispatch(setPagination({ current: 1 }))
  
  dispatch(fetch())
  
  dispatch(fetchTotals())
  
}