import { createAction, handleActions } from 'redux-actions';

const loadEstimateStatuses = createAction("CORE/ESTIMATE_STATUSES/LOAD_ESTIMATE_STATUSES");
const clearEstimatesStatuses = createAction("CORE/ESTIMATE_STATUSES/CLEAR_ESTIMATE_STATUSES");

const defaultState = []

const reducer = handleActions(
  {
    [loadEstimateStatuses]: (state, action) => action.payload,
    [clearEstimatesStatuses]: () => []
  },
  defaultState
);

export { loadEstimateStatuses, clearEstimatesStatuses, reducer };