import { combineReducers } from 'redux'

import { reducer as subInvoices } from './State/subInvoices'
import { reducer as fetching } from './State/fetching'
import { reducer as search } from './State/search'
import { reducer as sorting } from './State/sorting'
import { reducer as subInvoiceModal } from './State/subInvoiceModal'

export default combineReducers({
  subInvoices,
  fetching,
  search,
  sorting,
  subInvoiceModal,
})
