exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._23QqAJHIFFjijNPpPsOI-0 {\n  background-color: white;\n  border: 1px solid #DDD;\n  margin: 20px;\n  border-radius: 3px;\n  display: flex;\n  flex-direction: column;\n}\n\n._3MD0GZQaEdr__jTMNRMado {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 10px;\n  border-bottom: 1px solid #DDD;\n}\n\n._3C84eWWUIg68_SnyV4_9aq {\n  font-size: 15px;\n  font-weight: 600;\n  color: #444;\n  flex: 1;\n  margin-left: 5px;\n}\n\n\n._8ydf-U5IAJvetzX6kZVt9 {\n  display: flex;\n  flex-direction: row;\n  overflow: hidden;\n  padding: 10;\n  padding-top: 20;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Dashboard/Components/ChartPanel.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,uBAAuB;CACxB;;AAED;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,cAAc;EACd,8BAA8B;CAC/B;;AAED;EACE,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,QAAQ;EACR,iBAAiB;CAClB;;;AAGD;EACE,cAAc;EACd,oBAAoB;EACpB,iBAAiB;EACjB,YAAY;EACZ,gBAAgB;CACjB","file":"ChartPanel.css","sourcesContent":[".panel {\n  background-color: white;\n  border: 1px solid #DDD;\n  margin: 20px;\n  border-radius: 3px;\n  display: flex;\n  flex-direction: column;\n}\n\n.header {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 10px;\n  border-bottom: 1px solid #DDD;\n}\n\n.title {\n  font-size: 15px;\n  font-weight: 600;\n  color: #444;\n  flex: 1;\n  margin-left: 5px;\n}\n\n\n.chart {\n  display: flex;\n  flex-direction: row;\n  overflow: hidden;\n  padding: 10;\n  padding-top: 20;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"panel": "_23QqAJHIFFjijNPpPsOI-0",
	"header": "_3MD0GZQaEdr__jTMNRMado",
	"title": "_3C84eWWUIg68_SnyV4_9aq",
	"chart": "_8ydf-U5IAJvetzX6kZVt9"
};