import { createAction, handleActions } from 'redux-actions';

const setFetchingTotals = createAction("SCREENS/ESTIMATES/SET_FETCHING_TOTALS");

const defaultState = false

const reducer = handleActions(
  {
    [setFetchingTotals]: (state, action) => action.payload
  },
  defaultState
);

export { setFetchingTotals, reducer };