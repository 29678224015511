import create from '../Services/create';
import update from '../Services/update';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { onClose, didCreate, didUpdate } = getProps();

  if (!values.id) {

    const clientContact = await dispatch(create(values));

    onClose();

    if (didCreate) didCreate(clientContact);

  } else {

    const clientContact = await dispatch(update(values));

    onClose();

    if (didUpdate) didUpdate(clientContact);
    
  }


}