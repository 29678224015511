// @flow
import * as React from 'react';
import { Input, Menu, Button, Dropdown, Upload } from 'antd';
import { isSenior } from 'Common';
import { CloudDownloadOutlined, CloudUploadOutlined } from '@ant-design/icons';

const styles = {

  stack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 25
  },

  heading: {
    flex: 1,
    margin: 0
  }
}

type Props = {
  searchTerm: string,
  onChangeSearch: () => void,
  onSubmitSearch: () => void,
  onUpload: () => void,
  onClickExport: () => void,
  onClickNewClient: () => void
  
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      searchTerm,
      onClickNewClient,
      onSubmitSearch,
      onChangeSearch,
      onUpload,
      onClickExport,
    } = this.props;

    const exportMenu = (
      <Menu onClick={onClickExport}>
        <Menu.Item key="clients">Export All Clients</Menu.Item>
        <Menu.Item key="client-sites">Export All Sites</Menu.Item>
        <Menu.Item key="client-contacts">Export All Contacts</Menu.Item>
      </Menu>
    );

    return (
      <div style={styles.stack}>

        <h1 style={styles.heading}>
          Clients
        </h1>

        <Input.Search
          value={searchTerm}
          onChange={onChangeSearch}
          onSearch={onSubmitSearch}
          placeholder="Search"
          enterButton
          style={{ width: 250 }}
        />

        <div style={{ width: 10 }} />

        <Upload
          name="file"
          customRequest={onUpload}
          showUploadList={false}
        >
          <Button>
            <CloudUploadOutlined style={{ fontSize: 16, marginTop: 3 }} />
          </Button>
        </Upload>

        {isSenior() && (

          <React.Fragment>

            <div style={{ width: 10 }} />

            <Dropdown overlay={exportMenu}>
              <Button>
                <CloudDownloadOutlined style={{ fontSize: 16, marginTop: 3 }} />
              </Button>
            </Dropdown>

          </React.Fragment>

        )}

        <div style={{ width: 10 }} />

        <Button type="primary" onClick={onClickNewClient}>
          New Client
        </Button>

      </div>

    )
  }
}