import chance from 'chance'
import modalProps from '../modalProps'
import { setJobs } from '../State/jobs'
import { setPlannerCell } from '../State/plannerCell'

export default () => async (dispatch) => {

  const { _jobs, _plannerCell } = modalProps()

  dispatch(setJobs(_jobs.map(job => ({
    key: chance().guid(),
    ...job,
  }))))

  if (_plannerCell) {
    dispatch(setPlannerCell(_plannerCell))
  }

}
