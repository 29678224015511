// @flow
import * as React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import _ from 'lodash';

import defaultConfig from './Config';
import { store, persistor, history } from './store';
import ConfigContext from './Contexts/ConfigContext';
import Container from './Container';

type Props = {
  env: {},
  config: {
    reducers: []
  },
  children:? React.Node
};

export default class Portal extends React.Component<Props> {

  constructor (props: Props) {
    super(props);

    window.__ENV__ = props.env;

    const config = _.merge({}, defaultConfig, props.config);
    this.config = config;

    this.store = store;
    this.persistor = persistor;
    this.history = history;
  }

  config: any;

  store: any;

  persistor: any;
  
  history: any;


  render () {

    const { children } = this.props;

    return (

      <ConfigContext.Provider value={this.config}>
        <StoreProvider store={this.store}>
          <PersistGate persistor={this.persistor}>
            <ConnectedRouter history={this.history}>
              <Container>
                {children}
              </Container>
            </ConnectedRouter>
          </PersistGate>
        </StoreProvider>
      </ConfigContext.Provider>

    )
  }
}