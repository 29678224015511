exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2-vVRJRepdkV2YEAXOu0Xy {\n  width: 100%;\n  height: 60px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-weight: bold;\n}\n\n\n._1TE3nn9d_4MZVAuWSQ-Tsd {\n  display: flex;\n  align-items: center;\n}\n\n._1npUbi6NY_1qjw0xYd99OX {\n  margin-right: 8px;\n  font-size: 18px;\n}\n\n._2GWLknH0JE16aIAOEdC-Tx {\n  color: limegreen !important;\n}\n\n._2uFUJ_IsLHvwZ5GRdK5KcO {\n  color: #FF4D4F !important;\n}\n\n._1uritiQnnoAP-n1-WH5QWR {\n  color: orange !important;\n}\n\n._1rOQU4Y19A20LCTDpGasEJ {\n  color: #999 !important;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/RamsRecords/Components/Table/Component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,wBAAwB;EACxB,kBAAkB;CACnB;;;AAGD;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,kBAAkB;EAClB,gBAAgB;CACjB;;AAED;EACE,4BAA4B;CAC7B;;AAED;EACE,0BAA0B;CAC3B;;AAED;EACE,yBAAyB;CAC1B;;AAED;EACE,uBAAuB;CACxB","file":"Component.css","sourcesContent":[".noRamsRecords {\n  width: 100%;\n  height: 60px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-weight: bold;\n}\n\n\n.status {\n  display: flex;\n  align-items: center;\n}\n\n.icon {\n  margin-right: 8px;\n  font-size: 18px;\n}\n\n.sufficient {\n  color: limegreen !important;\n}\n\n.insufficient {\n  color: #FF4D4F !important;\n}\n\n.warning {\n  color: orange !important;\n}\n\n.muted {\n  color: #999 !important;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"noRamsRecords": "_2-vVRJRepdkV2YEAXOu0Xy",
	"status": "_1TE3nn9d_4MZVAuWSQ-Tsd",
	"icon": "_1npUbi6NY_1qjw0xYd99OX",
	"sufficient": "_2GWLknH0JE16aIAOEdC-Tx",
	"insufficient": "_2uFUJ_IsLHvwZ5GRdK5KcO",
	"warning": "_1uritiQnnoAP-n1-WH5QWR",
	"muted": "_1rOQU4Y19A20LCTDpGasEJ"
};