import { createAction, handleActions } from 'redux-actions';

const loadProject = createAction("MODALS/ORDER/LOAD_PROJECT");
const clearProject = createAction("MODALS/ORDER/CLEAR_PROJECT");

const defaultState = null;

const reducer = handleActions(
  {
    [loadProject]: (state, action) => action.payload,
    [clearProject]: () => null
  },
  defaultState
);

export { loadProject, clearProject, reducer };