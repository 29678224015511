import { getProps } from '../props'
import create from '../Services/create'
import update from '../Services/update'

export default async (values, dispatch) => {

  const { timesheetId, onClose, didCreate, didUpdate } = getProps()

  if (!values.id) {

    const timesheetPosting = await dispatch(create({
      ...values,
      timesheet_id: timesheetId,
    }))

    onClose()
    
    if (didCreate) didCreate(timesheetPosting)

  } else {

    const timesheetPosting = await dispatch(update(values))

    onClose()
    
    if (didUpdate) didUpdate(timesheetPosting)

  }

}
