import { openSubOrderModal } from '../../../State/subOrderModal'

export default () => (dispatch, getState) => {

  const { order, selectedOrderItemIds } = getState().screens.OrderView
  
  dispatch(openSubOrderModal({
    projectId: order.project_id,
    orderItemIds: selectedOrderItemIds.map(row => row.id),
  }))

}
