import { createAction, handleActions } from 'redux-actions';

const loadValuations = createAction("SCREENS/VALUATIONS/LOAD_VALUATIONS");

const defaultState = [];

const reducer = handleActions(
  {
    [loadValuations]: (state, action) => action.payload
  },
  defaultState
);

export { loadValuations, reducer };