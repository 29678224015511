import { createAction, handleActions } from 'redux-actions'

const openTimesheetAdjustmentModal = createAction('SCREENS/TIMESHEETS/OPEN_TIMESHEET_ADJUSTMENT_MODAL')
const closeTimesheetAdjustmentModal = createAction('SCREENS/TIMESHEETS/CLOSE_TIMESHEET_ADJUSTMENT_MODAL')

const defaultState = {
  show: false,
  timesheetAdjustment: null,
  timesheetId: null,
}

const reducer = handleActions(
  {
    [openTimesheetAdjustmentModal]: (state, { payload }) => ({ ...state, show: true, ...payload }),
    [closeTimesheetAdjustmentModal]: () => defaultState,
  },
  defaultState,
)

export { openTimesheetAdjustmentModal, closeTimesheetAdjustmentModal, reducer }
