import { createAction, handleActions } from 'redux-actions'

const loadInvoices = createAction('SCREENS/INVOICES/LOAD_INVOICES')

const defaultState = []

const reducer = handleActions(
  {
    [loadInvoices]: (state, action) => action.payload,
  },
  defaultState
)

export { loadInvoices, reducer }