import handleException from '../../../Core/Helpers/handleException'
import { getRetentionPayments } from '../../../HttpRequests/retentionPayments'
import { setFetching } from '../State/fetching'
import { loadRetentionPayments } from '../State/retentionPayments'
import { setPagination } from '../State/pagination'

export default () => async (dispatch, getState) => {

  try {

    const { pagination, sorting, filters, searchTerm } = getState().screens.RetentionPayments

    const params = {}

    params.include = [
      'project',
      'project.client_site',
      'project.client_site.client',
      'active_invoices',
    ].join()
    
    params['page[size]'] = pagination.pageSize
    params['page[number]'] = pagination.current

    if (searchTerm) {
      params.search = searchTerm
    }

    if (sorting.columnKey) {
      params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey
    }
    
    if (filters.status !== 'ALL') {
      params['filter[status]'] = filters.status
    }
    
    if (filters.client_id.length > 0) {
      params['filter[client_id]'] = filters.client_id.join(',')
    }

    dispatch(setFetching(true))

    const response = await getRetentionPayments({ params })

    dispatch(loadRetentionPayments(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageSize: response.data.pagination.per_page,
      current: response.data.pagination.current_page,
    }))

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error)

    dispatch(setFetching(false))

  }
}