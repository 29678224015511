import getParams from '../Utils/getParams'
import { getSubProjects, getSubProjectsSummary } from '../../../../../HttpRequests/subProjects'
import { setData } from '../State/data'
import { startFetching, finishFetching } from '../State/fetching'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const state = getState()

  const { mode } = state.screens.Subbies.SubProjects

  const params = getParams(state)

  dispatch(startFetching())

  try {

    let response

    if (mode === 'projects') response = await getSubProjects({ params })

    if (mode === 'summary') response = await getSubProjectsSummary({ params })

    dispatch(setData(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
