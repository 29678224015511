import { combineReducers } from 'redux'

import { reducer as projects } from './State/projects'
import { reducer as data } from './State/data'
import { reducer as form } from './State/form'
import { reducer as saving } from './State/saving'

export default combineReducers({
  projects,
  data,
  form,
  saving,
})
