import { createAction, handleActions } from 'redux-actions';

const setEditable = createAction("SCREENS/ORDER_VIEW/SET_EDITABLE");

const defaultState = false

const reducer = handleActions(
  {
    [setEditable]: (state, action) => action.payload
  },
  defaultState
);

export { setEditable, reducer };