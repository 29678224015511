// @flow
import React from 'react'
import { Breadcrumb } from 'antd'

export default () => (

  <React.Fragment>

    <Breadcrumb separator='»'>

      <Breadcrumb.Item>
        Home
      </Breadcrumb.Item>

      <Breadcrumb.Item>
        Insights
      </Breadcrumb.Item>

    </Breadcrumb>

  </React.Fragment>

)
