import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openUserModal = createAction('SCREENS/USERS/OPEN_USER_MODAL')
const closeUserModal = createAction('SCREENS/USERS/CLOSE_USER_MODAL')

const defaultState = {
  show: false,
  id: null,
}

const reducer = handleActions(
  {
    [openUserModal]: (state, action) => ({
      ...state,
      show: true,
      id: get(action, 'payload.id', null),
    }),
    [closeUserModal]: () => defaultState,
  },
  defaultState,
)

export { openUserModal, closeUserModal, reducer }
