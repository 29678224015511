import { createSelector } from 'reselect';

const selectOrders = state => state.screens.ProjectView.TargetCosts.orders;

export default createSelector(
  selectOrders,
  (orders) => {

    if (!orders) return [];

    const items = [];

    const typeTotals = {
      "D" : { value : 0, target_cost_amount: 0, target_cost_percentage: 0, label : "Decorating" },
      "W" : { value : 0, target_cost_amount: 0, target_cost_percentage: 0, label : "Wallpapering" },
      "S" : { value : 0, target_cost_amount: 0, target_cost_percentage: 0, label : "Spraying" },
      "T" : { value : 0, target_cost_amount: 0, target_cost_percentage: 0, label : "Tape & Jointing" },
      "F" : { value : 0, target_cost_amount: 0, target_cost_percentage: 0, label : "Flooring" },
      "I" : { value : 0, target_cost_amount: 0, target_cost_percentage: 0, label : "Intumescent" },
      "O" : { value : 0, target_cost_amount: 0, target_cost_percentage: 0, label : "Other" },
    }

    orders.forEach((order, orderIndex) => {

      items.push({
        type: 'OrderHeader',
        height: 60,
        order,
        orderIndex
      });

      order.order_groups.forEach((orderGroup, orderGroupIndex) => {

        items.push({
          type: 'GroupHeader',
          height: 70,
          order,
          orderIndex,
          orderGroup,
          orderGroupIndex
        });

        orderGroup.order_items.forEach((orderItem, orderItemIndex) => {

          items.push({
            type: 'GroupItem',
            height: 50,
            order,
            orderIndex,
            orderGroup,
            orderGroupIndex,
            orderItem,
            orderItemIndex,
          });

          if (orderItem.type && orderItem.flag !== 'Omit' && orderItem.flag !== 'By Others') {
            typeTotals[orderItem.type].value += Number(orderItem.total)
            typeTotals[orderItem.type].target_cost_amount += Number(orderItem.target_cost_amount)
          }

        });

      })

      items.push({
        type: 'OrderFooter',
        height: 80,
        order,
        orderIndex
      });

    })

    Object.keys(typeTotals).forEach(key => {
      typeTotals[key].target_cost_percentage = typeTotals[key].target_cost_amount / typeTotals[key].value * 100
    })

    items.push({
      type: 'Summary',
      height: 200,
      typeTotals
    });

    return items;

  }
)