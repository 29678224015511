import { createAction, handleActions } from 'redux-actions'

const setInteractionId = createAction('SCREENS/INSIGHTS/SET_INTERACTION_ID')
const resetInteractionId = createAction('SCREENS/INSIGHTS/RESET_INTERACTION_ID')

const defaultState = null

const reducer = handleActions(
  {
    [setInteractionId]: (state, { payload }) => payload,
    [resetInteractionId]: () => defaultState,
  },
  defaultState,
)

export { setInteractionId, resetInteractionId, reducer }
