import { createJob, createJobs, updateJobs } from '../../../../../HttpRequests/jobs'
import fetchPlanner from '../../../Services/fetch'
import fetchDrawer from '../../SubcontractorDrawer/Services/fetch'
import handleException from '../../../../../Core/Helpers/handleException'

export default (jobs, subcontractor, projectId, date) => async (dispatch, getState) => {

  const { keyDown } = getState().screens.Planner

  try {

    // Duplicate

    if (jobs.length && keyDown === 'd') {

      await createJobs({
        data: jobs.map(job => ({
          project_id: projectId,
          subcontractor_id: job.subcontractor_id,
          date,
          slot: job.slot,
        })),
      })

    }

    // Update

    if (jobs.length && keyDown !== 'd') {

      await updateJobs({
        data: jobs.map(job => ({
          id: job.id,
          project_id: projectId,
          date,
        })),
      })

    }

    // Create

    if (!jobs.length) {

      await createJob({
        data: {
          project_id: projectId,
          subcontractor_id: subcontractor.id,
          date,
        },
      })

    }
    
    dispatch(fetchPlanner())

    if (subcontractor) dispatch(fetchDrawer())
    
  } catch (error) {

    handleException(error)

    throw error

  }

}
