import { initialize } from 'redux-form'
import moment from 'moment'
import { getUser } from 'Common'
import modalProps from '../modalProps'
import { FORM_NAME } from '../Form'

export default () => (dispatch) => {
  
  const { subOrder, subcontractorId, projectId } = modalProps()

  if (subOrder) {

    dispatch(initialize(FORM_NAME, subOrder))
    
  } else {

    dispatch(initialize(FORM_NAME, {
      subcontractor_id: subcontractorId,
      project_id: projectId,
      user_id: getUser().id,
      date: moment().format('YYYY-MM-DD'),
      vat_perc: 20,
    }))

  }

}
