import { Modal } from 'antd';
import deleteEstimate from '../../../Services/deleteEstimate';

const { confirm } = Modal;

export default () => async (dispatch) => {

  confirm({
    title: `Are you sure you want to delete this Estimate?`,
    content: 'Note: This action cannot be undone',
    okText: 'Yes, Delete it',
    onOk() {
      
      dispatch(deleteEstimate());
      
    }
  });

}