import { getProps } from '../props'
import reset from '../Services/reset'

export default async (values, dispatch) => {

  const { id, onClose } = getProps()

  await dispatch(reset(id, values))

  onClose()

}
