import { getProps } from '../props';
import { updateProject } from '../../../HttpRequests/projects';

export default (values) => async () => {
  const { project } = getProps();

  const data = {
    ...values
  }

  await updateProject({ id: project.id, data });

}