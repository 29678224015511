import { Modal } from 'antd';
import deleteValuation from '../../../Services/deleteValuation';

const { confirm } = Modal;

export default () => async (dispatch) => {

  confirm({
    title: `Are you sure you want to delete this Valuation?`,
    content: 'Note: This action cannot be undone',
    okText: 'Yes, Delete it',
    onOk() {
      
      dispatch(deleteValuation());
      
    }
  });

}