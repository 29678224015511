// @flow

import { fromJS } from 'immutable';
import { assign } from 'lodash';
import { loadOrder } from '../State/order';

export type DuplicateOrderItemParams = {
  orderGroupIndex: number,
  orderItemIndex: number
}

export default (params: DuplicateOrderItemParams) => (dispatch: Function, getState: Function) => {

  let order = fromJS(getState().screens.OrderView.order);

  const sourceOrderItem = order.getIn(['order_groups', params.orderGroupIndex, 'order_items', params.orderItemIndex]).toJS();

  const orderItem = assign({}, sourceOrderItem, { id: null });
  
  order = order.updateIn(
    ['order_groups', params.orderGroupIndex, 'order_items'],
    (list) => list.insert(params.orderItemIndex + 1, orderItem)
  );

  order = order.toJS();
  
  dispatch(loadOrder(order));

}