// @flow

import * as React from 'react';
import List from './Components/List';

type Props = {
  listData: [],
  serviceTypes: [],
  editable: boolean,
  activeItemInputKeys: any,
  onClickValuationItemPercentageOutlet: Function,
  onClickValuationItemAmountOutlet: Function,
  onChangeValuationItemPercentage: Function,
  onChangeValuationItemAmount: Function,
  onBlurValuationItemPercentage: Function,
  onBlurValuationItemAmount: Function,
  onTabValuationItem: Function,
  onClickSetGroupPercentage: Function,
  onClickDescription: Function
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      listData,
      serviceTypes,
      editable,
      activeItemInputKeys,
      onClickValuationItemPercentageOutlet,
      onClickValuationItemAmountOutlet,
      onChangeValuationItemPercentage,
      onChangeValuationItemAmount,
      onBlurValuationItemPercentage,
      onBlurValuationItemAmount,
      onTabValuationItem,
      onClickSetGroupPercentage,
      onClickDescription
    } = this.props;

    return (

      <List
        listData={listData}
        serviceTypes={serviceTypes}
        editable={editable}
        activeItemInputKeys={activeItemInputKeys}
        onClickValuationItemPercentageOutlet={onClickValuationItemPercentageOutlet}
        onClickValuationItemAmountOutlet={onClickValuationItemAmountOutlet}
        onChangeValuationItemPercentage={onChangeValuationItemPercentage}
        onChangeValuationItemAmount={onChangeValuationItemAmount}
        onBlurValuationItemPercentage={onBlurValuationItemPercentage}
        onBlurValuationItemAmount={onBlurValuationItemAmount}
        onTabValuationItem={onTabValuationItem}
        onClickSetGroupPercentage={onClickSetGroupPercentage}
        onClickDescription={onClickDescription}
      />

    )
  }
}