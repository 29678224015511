import { createAction, handleActions } from 'redux-actions'

const setDuplicate = createAction('SCREENS/PLANNER/PROJECT_CELL_MODAL/SET_DUPLICATE')
const setDuplicateKey = createAction('SCREENS/PLANNER/PROJECT_CELL_MODAL/SET_DUPLICATE_KEY')
const resetDuplicate = createAction('SCREENS/PLANNER/PROJECT_CELL_MODAL/RESET_DUPLICATE')

const defaultState = {
  upToDate: null,
  incWeekends: false,
}

const reducer = handleActions(
  {
    [setDuplicate]: (state, action) => action.payload,
    [setDuplicateKey]: (state, { payload }) => ({ ...state, [payload.key]: payload.value }),
    [resetDuplicate]: () => defaultState,
  },
  defaultState,
)

export { setDuplicate, setDuplicateKey, resetDuplicate, reducer }
