import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { getTeamUrl } from 'Common'
import styles from './styles.css'

const Section = props => (
  <div className={styles.section}>
    {props.children}
  </div>
)

const Outlet = props => (
  <div className={styles.outlet}>
    {props.children}
  </div>
)

const Label = props => (
  <div className={styles.label}>
    {props.children}
  </div>
)

const Value = props => (
  <div className={styles.value}>
    {props.children}
  </div>
)

const Sidebar = props => {

  const {
    subInvoice,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <Section>

        <Outlet>
          <Label>Subcontractor</Label>
          <Value>
            <NavLink to={getTeamUrl(`subcontractors/${subInvoice.subcontractor.id}/view`)}>
              {subInvoice.subcontractor.name}
            </NavLink>
          </Value>
        </Outlet>

        <Outlet>
          <Label>Owner</Label>
          <Value>{subInvoice.user.full_name}</Value>
        </Outlet>

        <Outlet>
          <Label>Date</Label>
          <Value>{moment(subInvoice.date).format('DD MMM YYYY')}</Value>
        </Outlet>

      </Section>

    </div>
    
  )

}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

Outlet.propTypes = {
  children: PropTypes.node.isRequired,
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
}

Value.propTypes = {
  children: PropTypes.node.isRequired,
}

Sidebar.propTypes = {
  subInvoice: PropTypes.object.isRequired,
}

export default Sidebar
