// @flow
import * as React from 'react';
import { WindowScroller, AutoSizer, List } from 'react-virtualized';
import OrderHeader from './OrderHeader';
import GroupHeader from './GroupHeader';
import GroupItem from './GroupItem';
import GroupFooter from './GroupFooter';
import OrderFooter from './OrderFooter';

type Props = {
  listData: [],
  serviceTypes: [],
  editable: boolean,
  activeItemInputKeys: any,
  onClickValuationItemPercentageOutlet: () => void,
  onClickValuationItemAmountOutlet: () => void,
  onChangeValuationItemPercentage: () => void,
  onChangeValuationItemAmount: () => void,
  onBlurValuationItemPercentage: () => void,
  onBlurValuationItemAmount: () => void,
  onTabValuationItem: () => void,
  onClickSetGroupPercentage: () => void,
  onClickDescription: () => void
};

type RowProps = {
  index: number,
  key: any,
  style: {}
};

export default class extends React.PureComponent<Props> {

  componentDidUpdate = () => this.List.recomputeRowHeights();

  List: any;

  renderRow = (rowProps: RowProps) => {

    const {
      index,
      key,
      style
    } = rowProps;

    const {
      listData,
      serviceTypes,
      editable,
      activeItemInputKeys,
      onClickValuationItemPercentageOutlet,
      onClickValuationItemAmountOutlet,
      onChangeValuationItemPercentage,
      onChangeValuationItemAmount,
      onBlurValuationItemPercentage,
      onBlurValuationItemAmount,
      onTabValuationItem,
      onClickSetGroupPercentage,
      onClickDescription
    } = this.props;

    const {
      type,
      order,
      orderIndex,
      orderGroup,
      orderGroupIndex,
      orderItem,
      orderItemIndex,
      valuationItem
    } = listData[index];

    if (type === 'OrderHeader') {
      return (
        <div style={style} key={key}>
          <OrderHeader
            index={index}
            editable={editable}
            order={order}
          />
        </div>
      )
    }

    if (type === 'GroupHeader') {
      return (
        <div style={style} key={key}>
          <GroupHeader
            index={index}
            orderGroup={orderGroup}
          />
        </div>
      )
    }

    if (type === 'GroupItem') {
      return (
        <div style={style} key={key}>
          <GroupItem
            index={index}
            editable={editable}
            activeItemInputKeys={activeItemInputKeys}
            orderIndex={orderIndex}
            orderGroupIndex={orderGroupIndex}
            orderItemIndex={orderItemIndex}
            orderItem={orderItem}
            valuationItem={valuationItem}
            serviceTypes={serviceTypes}
            onClickValuationItemPercentageOutlet={onClickValuationItemPercentageOutlet}
            onClickValuationItemAmountOutlet={onClickValuationItemAmountOutlet}
            onChangeValuationItemPercentage={onChangeValuationItemPercentage}
            onChangeValuationItemAmount={onChangeValuationItemAmount}
            onBlurValuationItemPercentage={onBlurValuationItemPercentage}
            onBlurValuationItemAmount={onBlurValuationItemAmount}
            onTabValuationItem={onTabValuationItem}
            onClickDescription={onClickDescription}
          />
        </div>
      )
    }

    if (type === 'GroupFooter') {

      return (
        <div style={style} key={key}>
          <GroupFooter
            index={index}
            editable={editable}
            orderIndex={orderIndex}
            orderGroupIndex={orderGroupIndex}
            onClickSetGroupPercentage={onClickSetGroupPercentage}
          />
        </div>
      )
    }

    if (type === 'OrderFooter') {

      return (
        <div style={style} key={key}>
          <OrderFooter
            index={index}
            order={order}
          />
        </div>
      )
    }

    return null;
  }

  render () {

    const {
      listData
    } = this.props;

    return (

      <div style={{ }}>

        <WindowScroller>
          {({ height, scrollTop }) => (

            <AutoSizer disableHeight>
              {({ width }) => (

                <List
                  autoHeight
                  height={height}
                  width={width}
                  scrollTop={scrollTop}
                  overscanRowCount={5}
                  rowHeight={({ index }) => listData[index].height}
                  rowRenderer={this.renderRow}
                  rowCount={listData.length}
                  ref={ref => { this.List = ref }}
                />

              )}
            </AutoSizer>
            
          )}
        </WindowScroller>

      </div>

    )
  }
  
}