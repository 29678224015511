import React from 'react';
import { Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';

import styles from './Table.css';

type Props = {
  history: any,
  onClickValuation: Function
}

export default class extends React.PureComponent<Props> {
  
  render () {

    const { history, onClickValuation } = this.props;
    
    const columns = [
      {
        title: 'Number',
        dataIndex: "",
        render: (text, record) => (
          <span className={styles.link} onClick={() => onClickValuation(record.id)} role="presentation">
            {`Valuation ${record.number} ${record.reference_suffix ? ` / ${record.reference_suffix}`: ''}`}
          </span>
        )
      },

      {
        title: 'Reference',
        dataIndex: '',
        render: (text, record) => (
          <span>
            {record.reference}
          </span>
        )
      },

      {
        title: 'Date',
        dataIndex: '',
        render: (text, record) => (
          <span>
            {moment(record.date).format('D MMM YYYY')}
          </span>
        )
      },

      {
        title: 'Value',
        dataIndex: '',
        render: (text, record) => (
          <span>
            {`£${numeral(record.complete_total).format('0,0.00')}`}
          </span>
        )
      }
    ]
    
    return (

      <Table
        dataSource={history}
        columns={columns} 
        rowKey={record => record.id}
      />

    )
  }
}