import { createAction, handleActions } from 'redux-actions';

const setShowExportHistoryModal = createAction("SCREENS/DASHBOARD/SET_SHOW_EXPORT_HISTORY_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowExportHistoryModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowExportHistoryModal, reducer };