exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._22qRMiJVb6e0IuihG8XQ1m {\n  cursor: pointer;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n\n._22qRMiJVb6e0IuihG8XQ1m:hover {\n  color: #1990FF;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Modals/ValuationHistory/Components/Table.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,0BAA0B;KACvB,uBAAuB;MACtB,sBAAsB;UAClB,kBAAkB;CAC3B;;AAED;EACE,eAAe;CAChB","file":"Table.css","sourcesContent":[".link {\n  cursor: pointer;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n\n.link:hover {\n  color: #1990FF;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"link": "_22qRMiJVb6e0IuihG8XQ1m"
};