// @flow
import * as React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import styles from './GroupPlaceholder.css';

import type {
  AddEstimateItemParams
} from '../../../types';

type Props = {
  editable: boolean,
  estimateGroupIndex: number,
  onClickAddEstimateItem: (params: AddEstimateItemParams) => void
}

class Component extends React.PureComponent<Props> {

  render () {

    const {
      editable,
      estimateGroupIndex,
      onClickAddEstimateItem
    } = this.props;

    return (

      <div
        className={styles.wrapper}
      >

        <div className={styles.message}>
          
          {'This group is empty. '}

          {editable && (
            <span>
              <span
                className={styles.addLink}
                onClick={() => onClickAddEstimateItem({ estimateGroupIndex })}
                role="presentation"
              >
                {'Add an item'}
              </span>
              {' to get started.'}
            </span>
          )}
          
        </div>

      </div>

    )
  }
}

export default SortableElement(Component);