import { connect } from 'react-redux'
import SubcontractorName from './SubcontractorName'

import onClickName from './Handlers/onClickName'

const mapStateToProps = () => ({
})

const actionCreators = {
  onClickName,
}

export default connect(mapStateToProps, actionCreators)(SubcontractorName)
