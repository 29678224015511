import { connect } from 'react-redux';

import Component from './Component';

import componentWillMount from './Handlers/componentWillMount';
import onClickCancel from './Handlers/onClickCancel';
import onClickSubmit from './Handlers/onClickSubmit';
import onSelectTree from './Handlers/onSelectTree'

const mapStateToProps = (state) => ({
  selectedKey: state.modals.MoveFiles.selectedKey,
  moving: state.modals.MoveFiles.moving
});

const actionCreators = {
  componentWillMount,
  onClickCancel,
  onClickSubmit,
  onSelectTree
};

export default connect(mapStateToProps, actionCreators)(Component);