import { createAction, handleActions } from 'redux-actions';

const loadSiteVisitStatuses = createAction("CORE/SITE_VISIT_STATUSEES/LOAD_SITE_VISIT_STATUSES");
const clearSiteVisitStatuses = createAction("CORE/SITE_VISIT_STATUSEES/CLEAR_SITE_VISIT_STATUSES");

const defaultState = []

const reducer = handleActions(
  {
    [loadSiteVisitStatuses]: (state, action) => action.payload,
    [clearSiteVisitStatuses]: () => []
  },
  defaultState
);

export { loadSiteVisitStatuses, clearSiteVisitStatuses, reducer };