import { connect } from 'react-redux';

import Component from './Component';

const mapStateToProps = (state) => ({
  clients: state.core.clients
});

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Component);