import { setPagination } from '../State/pagination';
import fetchOrders from '../Services/fetchOrders';
import fetchOrderTotals from '../Services/fetchOrderTotals';

export default () => async (dispatch) => {

  dispatch(setPagination({ current: 1 }));

  dispatch(fetchOrders());

  dispatch(fetchOrderTotals());
  
}