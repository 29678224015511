import { isOffice } from 'Common'
import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchClient from '../Services/fetchClient';
import fetchClients from '../../../Core/Services/fetchClients';
import fetchUsers from '../../../Core/Services/fetchUsers';
import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors';
import fetchProjectPhases from '../../../Core/Services/fetchProjectPhases';

export default (props: any) => async (dispatch) => {

  setProps(props);
  
  dispatch(fetchUsers());
  
  if (isOffice()) {
    dispatch(fetchSubcontractors());
  }
  
  dispatch(fetchProjectPhases());
  
  dispatch(initialize());

  if (!props.project) {
    await dispatch(fetchClients());
  }

  if (props.project) {
    await dispatch(fetchClient());
  }

  dispatch(initialize());
  
}