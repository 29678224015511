// @flow

import * as React from 'react';
import { Modal } from 'antd';
import Form from './Form';

type Props = {
  submitting: boolean,
  componentWillMount: (props: any) => void,
  onClickCancel: Function,
  onClickSubmit: Function

}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      submitting,
      onClickCancel,
      onClickSubmit
    } = this.props;
    
    return (

      <Modal
        title='Set Completion'
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText='Update All'
        width={620}
      >

        <Form />

      </Modal>
    )
  }
}