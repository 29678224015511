
import { createAction, handleActions } from 'redux-actions';

const setShowDirectoryModal = createAction("SCREENS/PROJECT_VIEW/SET_SHOW_DIRECTORY_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowDirectoryModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowDirectoryModal, reducer };