import { connect } from 'react-redux'
import SubcontractorPopover from './SubcontractorPopover'

import onVisibleChange from './Handlers/onVisibleChange'

const mapStateToProps = (state) => ({
  data: state.screens.Planner.SubcontractorPopover.data,
  fetching: state.screens.Planner.SubcontractorPopover.fetching,
})

const actionCreators = {
  onVisibleChange,
}

export default connect(mapStateToProps, actionCreators)(SubcontractorPopover)
