import React from 'react';
import PropTypes from 'prop-types';
import styles from './Component.css';

export default class extends React.PureComponent {

  static propTypes = {
    itemDescriptions: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired
  }

  render () {

    const { itemDescriptions, onSelect } = this.props;

    return (
      
      <React.Fragment>

        {itemDescriptions.map(itemDescription => (

          <div key={itemDescription.id} className={styles.itemDescriptionWrapper} role="presentation">

            <div
              className={styles.itemDescriptionContent}
              onClick={() => onSelect(itemDescription.content)}
              role="presentation"
            >
              {itemDescription.content}
            </div>

          </div>

        ))}

      </React.Fragment>

    )
  }
}