import { connect } from 'react-redux';
import Component from './Component';


import componentDidMount from './Handlers/componentDidMount';
import onChangeTable from './Handlers/onChangeTable';
import onChangeSearch from './Handlers/onChangeSearch';
import onSubmitSearch from './Handlers/onSubmitSearch';
import onCloseValuationModal from './Handlers/onCloseValuationModal';
import didCreateValuation from './Handlers/didCreateValuation';
import onClickNewValuation from './Handlers/onClickNewValuation';
import onChangeFilter from './Handlers/onChangeFilter';
import onClickExport from './Handlers/onClickExport';

const mapStateToProps = (state) => ({
  clients: state.core.clients,
  users: state.core.users,
  valuations: state.screens.Valuations.valuations,
  valuationTotals: state.screens.Valuations.valuationTotals,
  searchTerm: state.screens.Valuations.searchTerm,
  pagination: state.screens.Valuations.pagination,
  fetching: state.screens.Valuations.fetching,
  filters: state.screens.Valuations.filters,
  showValuationModal: state.screens.Valuations.showValuationModal,
  valuationStatuses: state.core.valuationStatuses,
});

const actionCreators = {
  componentDidMount,
  onChangeTable,
  onCloseValuationModal,
  didCreateValuation,
  onClickNewValuation,
  onChangeSearch,
  onSubmitSearch,
  onChangeFilter,
  onClickExport
};

export default connect(mapStateToProps, actionCreators)(Component);