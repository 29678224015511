import { updateInvoice } from '../../../HttpRequests/invoices'
import fetch from './fetch'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {
  
  try {

    const { id } = getState().screens.InvoiceView.invoice

    const data = {
      added_to_quickbooks: 1
    }

    await updateInvoice({ id, data })

    await dispatch(fetch())
    
  } catch (error) {

    handleException(error)

  }
  
}