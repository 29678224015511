import immutable from 'immutable';
import { message } from 'antd'
import { loadEstimates } from '../State/estimates';
import { updateEstimate } from '../../../HttpRequests/estimates';
import handleException from '../../../Core/Helpers/handleException';

export default (id, field, value) => async (dispatch, getState) => {

  try {

    // update local state

    let estimates = immutable.fromJS(getState().screens.Estimates.estimates);

    const estimateEntry = estimates.findEntry(item => item.get('id') === id);

    const [ estimateIndex ] = estimateEntry;

    estimates = estimates.setIn([estimateIndex, field], value);

    estimates = estimates.toJS();

    dispatch(loadEstimates(estimates));

    // update on server

    const data = {
      [field]: value
    };

    await updateEstimate({ id, data });

    message.success('Estimate Updated.');

  } catch (error) {

    handleException(error);

  }
}