import update from '../Services/update';

import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { onClose, didUpdate } = getProps()
  
  const isUpdated = await dispatch(update(values))

  if (isUpdated) {
    
    onClose()
    
    if (didUpdate) didUpdate()
  }

}