import { setSorting } from '../State/sorting';
import { setPagination } from '../State/pagination';
import fetchOrders from '../Services/fetchOrders';

export default (pagination, filters, sorting) => async (dispatch) => {

  window.scrollTo(0, 0);

  dispatch(setPagination(pagination));
  
  dispatch(setSorting(sorting));

  await dispatch(fetchOrders());

}