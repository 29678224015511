import { createAction, handleActions } from 'redux-actions';
import { assign } from 'lodash'

const loadData = createAction('SCREENS/DASHBOARD/NEW_ORDERS_REPORT/LOAD_DATA');
const clearData = createAction('SCREENS/DASHBOARD/NEW_ORDERS_REPORT/CLEAR_DATA');
const setDays = createAction('SCREENS/DASHBOARD/NEW_ORDERS_REPORT/SET_DAYS');

const defaultState = {
  data: null,
  days: '7'
}

const reducer = handleActions(
  {
    [loadData]: (state, action) => assign({}, state, { data: action.payload }),
    [clearData]: () => assign({}, defaultState),
    [setDays]: (state, action) => assign({}, state, { days: action.payload })
  },
  defaultState
);

export { loadData, clearData, setDays, reducer };
