import React from 'react'
import PropTypes from 'prop-types'
import { Table, Dropdown, Divider } from 'antd'
import {
  CaretDownOutlined,
  ClockCircleOutlined,
  FormatPainterOutlined,
} from '@ant-design/icons'
import { useVT } from 'virtualizedtableforantd4'
import numeral from 'numeral'
import moment from 'moment'
import classNames from 'classnames'
import styles from './styles.css'

import ProjectSortMenu from '../ProjectSortMenu'
import ProjectCell from '../ProjectCell'
import SubcontractorPopover from '../SubcontractorPopover'
import SubcontractorDrop from '../SubcontractorDrop'

const formatHours = value => `${numeral(value).format('0,0')} hrs${value > 0 ? ` (${numeral(value / 9.5).format('0,0')} ds)` : ''}`

const ProjectSummaryTable = props => {

  const {
    periods,
    rows,
    fetching,
    onClickProject,
  } = props

  const [vt] = useVT(() => ({ scroll: { y: window.innerHeight - 290 } }), [])

  const columnWidth = (window.innerWidth - 120 - 400) / 7

  const today = moment().format('YYYY-MM-DD')

  return (

    <SubcontractorDrop>

      <Table
        dataSource={rows}
        rowKey='id'
        bordered
        loading={fetching}
        components={vt}
        scroll={{ y: window.innerHeight - 290 }}
        pagination={false}
        className={styles.table}
        style={{
          marginTop: '-1px',
          borderBottom: !!rows.length && '1px solid #f0f0f0',
        }}
      >

        <Table.Column
          key='project'
          title={(
            <Dropdown overlay={<ProjectSortMenu />} placement='bottomRight' trigger={['click']}>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <div>{`Projects (${rows.length})`}</div>
                <CaretDownOutlined style={{ marginLeft: 5 }} />
              </div>
            </Dropdown>
          )}
          onCell={record => ({
            onClick: () => onClickProject(record),
            style: { height: 165, padding: 0 },
          })}
          render={(text, record) => <ProjectCell record={record} />}
        />

        {periods.map((period, index) => (

          <Table.Column
            key={period.date}
            title={(
              <SubcontractorPopover period={period} placement='bottom' mouseEnterDelay={0.5}>
                <div>{period.day_of_week}</div>
                <div style={{ fontSize: 13, fontWeight: 400 }}>{period.date_formatted}</div>
              </SubcontractorPopover>
            )}
            width={columnWidth}
            onHeaderCell={() => ({
              className: classNames({
                [styles.today]: period.date === today,
                [styles.weekend]: period.is_weekend && period.date !== today,
              }),
            })}
            onCell={record => ({
              id: `${record.id}|${period.date}`,
              style: { verticalAlign: 'top' },
            })}
            render={(text, record) => (
              <div>
                <h3 className={styles.heading}>Day</h3>
                <div className={styles.detail}>
                  <ClockCircleOutlined className={styles.icon} style={{ color: 'green' }} />
                  <div>{formatHours(record.periods[index].hours_worked)}</div>
                </div>
                <div style={{ height: 5 }} />
                <div className={styles.detail}>
                  <FormatPainterOutlined className={styles.icon} style={{ color: 'orange' }} />
                  <div>{`${record.periods[index].timesheet_postings} postings`}</div>
                </div>
                <Divider style={{ margin: '12px 0' }} />
                <h3 className={styles.heading}>Accumulative</h3>
                <div className={styles.detail}>
                  <ClockCircleOutlined className={styles.icon} style={{ color: 'green' }} />
                  <div>{formatHours(record.periods[index].hours_worked_accum)}</div>
                </div>
                <div style={{ height: 5 }} />
                <div className={styles.detail}>
                  <FormatPainterOutlined className={styles.icon} style={{ color: 'orange' }} />
                  <div>{`${record.periods[index].timesheet_postings_accum} postings`}</div>
                </div>
              </div>
            )}
          />

        ))}

      </Table>

    </SubcontractorDrop>

  )

}

ProjectSummaryTable.propTypes = {
  periods: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onClickProject: PropTypes.func.isRequired,
}

export default ProjectSummaryTable
