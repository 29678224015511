// @flow

import * as React from 'react';
import Screen from '../../Components/Screen';
import Initializing from './Components/Initializing';
import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import Items from './Components/Items';
import Footer from './Components/Footer';
import Sidebar from './Components/Sidebar';
import ValuationModal from '../../Modals/Valuation'
import EmailValuation from '../../Modals/EmailValuation'
import ValuationCompletionModal from '../../Modals/ValuationCompletion';
import ValuationHistoryModal from '../../Modals/ValuationHistory';
import CreateInvoiceModal from '../../Modals/CreateInvoiceForValuation';
import ProjectCompleteModal from '../../Modals/ProjectComplete';
import ClientSiteModal from '../../Modals/ClientSite';
import ClientContactModal from '../../Modals/ClientContact';
import ProjectEditorModal from '../../Modals/Project';
import EditOrderItemModal from '../../Modals/EditOrderItem';

type Props = {
  valuation: any,
  fetching: boolean,
  showValuationModal: boolean,
  showEmailModal: boolean,
  showValuationCompletionModal: boolean,
  showValuationHistoryModal: boolean,
  showCreateInvoiceModal: boolean,
  showProjectCompleteModal: boolean,
  showClientSiteModal: boolean,
  showClientContactModal: boolean,
  projectEditorModal: Object,
  showEditOrderItemModal: boolean,
  selectedOrderItemKeys: any,

  componentWillMount: (props: any) => void,
  componentWillUnmount: () => void,
  componentDidUpdate: (props: any) => void,
  
  didUpdateValuation: Function,
  didUpdateClientSite: Function,
  didUpdateClientContact: Function,
  onCloseValuationModal: Function,
  onCloseEmailModal: Function,
  onCloseValuationCompletionModal: Function,
  onCloseValuationHistoryModal: Function,
  onCloseCreateInvoiceModal: Function,
  onCloseProjectCompleteModal: Function,
  didUpdateValuation: Function,
  didUpdateValuationCompletion: Function,
  didSelectValuation: Function,
  onCloseClientSiteModal: Function,
  onCloseClientContactModal: Function,
  onCloseProjectEditorModal: Function,
  didCreateInvoice: Function,
  didSendEmail: Function,
  didCompleteProject: Function,
  onCloseEditOrderItemModal: Function,

  didUpdateValuation: Function,
  didSendEmail: Function,
  didUpdateValuationCompletion: Function,
  didSelectValuation: Function,
  didCreateInvoice: Function,
  didUpdateOrderItem: Function
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentDidUpdate = () => this.props.componentDidUpdate(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      valuation,
      fetching,
      showValuationModal,
      showEmailModal,
      showValuationCompletionModal,
      showValuationHistoryModal,
      showCreateInvoiceModal,
      showProjectCompleteModal,
      onCloseValuationModal,
      onCloseEmailModal,
      showClientSiteModal,
      showClientContactModal,
      projectEditorModal,
      onCloseValuationCompletionModal,
      onCloseValuationHistoryModal,
      onCloseCreateInvoiceModal,
      onCloseProjectCompleteModal,
      didUpdateValuation,
      didUpdateValuationCompletion,
      didSelectValuation,
      didCreateInvoice,
      didSendEmail,
      didCompleteProject,
      onCloseClientSiteModal,
      onCloseClientContactModal,
      onCloseProjectEditorModal,
      didUpdateClientSite,
      didUpdateClientContact,
      showEditOrderItemModal,
      selectedOrderItemKeys,
      onCloseEditOrderItemModal,
      didUpdateOrderItem
    } = this.props;

    if (!valuation) return <Initializing />

    return (

      <Screen style={{ marginRight: 280, paddingRight: 30 }}>
          
        <Breadcrumbs />

        <Header />

        <Items />

        <Footer />

        <Sidebar />

        {showValuationModal && (
          <ValuationModal
            valuation={valuation}
            onClose={onCloseValuationModal}
            didUpdate={didUpdateValuation}
          />
        )}

        {showEmailModal && (
          <EmailValuation
            valuation={valuation}
            onClose={onCloseEmailModal}
            didSend={didSendEmail}
          />
        )}

        {showValuationCompletionModal && (
          <ValuationCompletionModal
            onClose={onCloseValuationCompletionModal}
            didUpdate={didUpdateValuationCompletion}
          />
        )}

        {showValuationHistoryModal && (

          <ValuationHistoryModal
            history={valuation.project.valuations}
            onClose={onCloseValuationHistoryModal}
            didSelectValuation={didSelectValuation}
          />

        )}
        
        {showCreateInvoiceModal && (
          <CreateInvoiceModal
            onClose={onCloseCreateInvoiceModal}
            didCreate={didCreateInvoice}
            id={valuation.id}
          />
        )}

        {showProjectCompleteModal && (
          <ProjectCompleteModal
            projectId={valuation.project_id}
            onClose={onCloseProjectCompleteModal}
            didComplete={didCompleteProject}
          />
        )}

        {showClientSiteModal && (
          <ClientSiteModal
            onClose={onCloseClientSiteModal}
            didUpdate={didUpdateClientSite}
            clientSite={valuation.project.client_site}
          />
        )}

        {showClientContactModal && (
          <ClientContactModal
            onClose={onCloseClientContactModal}
            didUpdate={didUpdateClientContact}
            clientContact={valuation.client_contact}
          />
        )}

        {projectEditorModal.show && (
          <ProjectEditorModal
            {...projectEditorModal}
            onClose={onCloseProjectEditorModal}
          />
        )}
        
        {showEditOrderItemModal && (
          <EditOrderItemModal
            onClose={onCloseEditOrderItemModal}
            didUpdate={didUpdateOrderItem}
            orderItemId={selectedOrderItemKeys.orderItemId}
          />
        )}

      </Screen>

    )
  }
}