exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1V5z9cjW4kH5jSCcWXa0Gx {\n  display: flex;\n  justify-content: flex-end;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Modals/CreateInvoiceForValuation/Components/Footer/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B;CAC3B","file":"Component.css","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"footer": "_1V5z9cjW4kH5jSCcWXa0Gx"
};