import { connect } from 'react-redux';
import Component from './Component';

import componentWillMount from './Handlers/componentWillMount';
import onClickCancel from './Handlers/onClickCancel';
import onClickValuation from './Handlers/onClickValuation';

const mapStateToProps = () => ({});

const actionCreators = {
  componentWillMount,
  onClickCancel,
  onClickValuation
};

export default connect(mapStateToProps, actionCreators)(Component);