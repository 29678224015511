import { setFilter } from '../../../State/filters'
import fetch from '../../../Services/fetch'

export default (field, value) => (dispatch) => {
  
  dispatch(setFilter({ field, value }))

  dispatch(fetch())

}
