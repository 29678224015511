import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import Screen from '../../Components/Screen'

import Initializing from './Components/Initializing'
import Breadcrumbs from './Components/Breadcrumbs'
import Header from './Components/Header'
import Tabs from './Components/Tabs'
import Items from './Components/Items'
import Footer from './Components/Footer'
import Sidebar from './Components/Sidebar'
import SubInvoiceModal from '../../Modals/SubInvoice'
import SubValuationModal from '../../Modals/SubValuation'

const SubInvoice = props => {

  const {
    subInvoice,
    activeTab,
    subInvoiceModal,
    subValuationModal,
    componentDidMount,
    componentWillUnmount,
    onCloseSubInvoiceModal,
    onCloseSubValuationModal,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  if (!subInvoice) return <Initializing />

  return (

    <Screen style={{ marginRight: 280, paddingRight: 30 }}>

      <Breadcrumbs subInvoice={subInvoice} />

      <Divider />

      <div style={{ fontSize: 16, color: '#999', marginBottom: 8 }}>Sub Invoice</div>

      <Header />

      <Tabs />

      {activeTab === 'ITEMS' && <Items />}

      <Footer />

      <Sidebar />

      {subInvoiceModal.show && (
        <SubInvoiceModal
          {...subInvoiceModal}
          subInvoice={subInvoice}
          onClose={onCloseSubInvoiceModal}
        />
      )}

      {subValuationModal.show && (
        <SubValuationModal
          {...subValuationModal}
          onClose={onCloseSubValuationModal}
        />
      )}
      
    </Screen>
    
  )

}

SubInvoice.propTypes = {
  subInvoice: PropTypes.object,
  activeTab: PropTypes.string.isRequired,
  subInvoiceModal: PropTypes.object.isRequired,
  subValuationModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onCloseSubInvoiceModal: PropTypes.func.isRequired,
  onCloseSubValuationModal: PropTypes.func.isRequired,
}

SubInvoice.defaultProps = {
  subInvoice: null,
}

export default SubInvoice
