import { resetProjects } from '../State/projects'
import { resetData } from '../State/data'
import { resetForm } from '../State/form'

export default () => (dispatch) => {

  dispatch(resetProjects())
  dispatch(resetData())
  dispatch(resetForm())

}
