import { padStart } from 'lodash'
import moment from 'moment'
import { openTimesheetAdjustmentModal } from '../State/timesheetAdjustmentModal'

const pad = num => padStart(num, 2, '0')

export default (value, timesheet) => (dispatch) => {

  const startDateTime = moment(value)
  const openDateTime = moment(`${timesheet.date} 07:00:00`)

  const duration = moment.duration(openDateTime.diff(startDateTime))

  // eslint-disable-next-line no-underscore-dangle
  const { hours, minutes } = duration._data

  const initialValues = {
    type: startDateTime < openDateTime ? 'reduction' : 'addition',
    time: `${pad(Math.abs(hours))}:${pad(Math.abs(minutes))}:00`,
    reason: startDateTime < openDateTime ? 'Clocked in too early (before 7am)' : 'Clocked in too late (after 7am)',
  }

  dispatch(openTimesheetAdjustmentModal({ initialValues, timesheetId: timesheet.id }))
  
}
