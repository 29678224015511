import { push } from 'connected-react-router'
import { message } from 'antd'
import { getTeamUrl } from 'Common'
import { deleteSubOrder } from '../../../HttpRequests/subOrders'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { subOrder } = getState().screens.SubOrder
  
  try {

    await deleteSubOrder({ id: subOrder.id })

    message.success(`Deleted "${subOrder.reference}"`)
    
    dispatch(push(getTeamUrl(`sub_projects/${subOrder.subcontractor_id}`)))
    
  } catch (error) {

    handleException(error)

  }
  
}
