import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { clientSite } = getProps();

  if (getProps().clientSite) {

    dispatch(initialize(FORM_NAME, clientSite));

  }

}