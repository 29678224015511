import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchCaveats from "../../../Core/Services/fetchCaveats";

export default (props) => (dispatch) => {

  setProps(props);
  dispatch(initialize());
  
  dispatch(fetchCaveats());
}