import { createAction, handleActions } from 'redux-actions'

const openTimesheetModal = createAction('MODULES/TIMESHEETS_DRAWER/OPEN_TIMESHEET_MODAL')
const closeTimesheetModal = createAction('MODULES/TIMESHEETS_DRAWER/CLOSE_TIMESHEET_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openTimesheetModal]: (state, { payload }) => ({ ...payload, show: true }),
    [closeTimesheetModal]: () => defaultState,
  },
  defaultState,
)

export { openTimesheetModal, closeTimesheetModal, reducer }
