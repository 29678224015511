import { message } from 'antd'
import { createOrder } from '../../../HttpRequests/orders';

export default (values) => async () => {

  const data = { ...values }

  const response = await createOrder({ data });

  const order = response.data.data;

  message.success('Order Created');

  return order;

}