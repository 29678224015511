/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import styles from './Component.css';
import moment from 'moment';

// eslint-disable-next-line react/prop-types
const InfoBlock = ({ label, children }) => (
  <div className={styles.infoBlock}>
    <div className={styles.infoBlockLabel}>
      {label}
    </div>
    <div className={styles.infoBlockDetail}>
      {children}
    </div>
  </div>
)

export default class extends React.PureComponent {

  static propTypes = {
    componentWillMount: PropTypes.func.isRequired,
    email: PropTypes.object,
    onClickCancel: PropTypes.func.isRequired
  }

  static defaultProps = {
    email: null
  }

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      email,
      onClickCancel
    } = this.props;

    return (
        
      <Modal
        visible  
        title="Email"
        onCancel={onClickCancel}
        footer={null}
        width={700}
      >
        
        {email && (

          <div>
            
            <InfoBlock label="To:">
              {email.to}
            </InfoBlock>

            {email.cc && (
              <InfoBlock label="Cc:">
                {email.cc}
              </InfoBlock>
            )}

            {email.bcc && (
              <InfoBlock label="Bcc:">
                {email.bcc}
              </InfoBlock>
            )}

            <InfoBlock label="Subject:">
              {email.subject}
            </InfoBlock>

            <InfoBlock label="Sent By:">
              {`${email.sent_by_user.first_name} ${email.sent_by_user.last_name}`}
            </InfoBlock>

            <div className={styles.body}>
              {email.body.split('\n').map((item, key) => (
                <span key={key}>
                  {item}
                  <br />
                </span>
              ))}
            </div>
            
            {email.sent_at && (
            <div className={styles.sentAt}>
              {`Email sent at ${moment(email.sent_at).format('HH:mm [on] ddd DD/MM/YYYY')}`}
            </div>
            )}

          </div>

        )}

      </Modal>
    )
  }
}

