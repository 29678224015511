import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'
import { UserDeleteOutlined } from '@ant-design/icons'

const CellAbsence = ({ content, fontSize }) => (

  <Alert
    type='info'
    icon={<UserDeleteOutlined style={{ color: 'white' }} />}
    banner
    message={<span style={{ color: 'white' }}>{content}</span>}
    style={{ width: '100%', backgroundColor: '#3c3b3b', fontSize, }}
  />
  
)

CellAbsence.propTypes = {
  content: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
}

CellAbsence.defaultProps = {
  fontSize: 13,
}

export default CellAbsence
