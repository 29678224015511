import { connect } from 'react-redux';
import Component from './Component';

import onClickActionMenuItem from './Handlers/onClickActionMenuItem';
import onChangeNotes from './Handlers/onChangeNotes';

const mapStateToProps = (state) => ({
  order: state.screens.OrderView.order,
})

const actionCreators = {
  onClickActionMenuItem,
  onChangeNotes
};

export default connect(mapStateToProps, actionCreators)(Component);