import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors'
import fetchProjects from '../../../Core/Services/fetchProjects'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetch from '../Services/fetch'

export default () => (dispatch) => {

  dispatch(fetchSubcontractors())
  dispatch(fetchProjects())
  dispatch(fetchUsers())

  dispatch(fetch())

}
