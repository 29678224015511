// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Table, Popover } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import { find, sortBy, reverse } from 'lodash';
import { getTeamUrl } from 'Common';
import TableCellSelect from '../../../../../Components/TableCellSelect';
import ServiceTypeIcons from '../../../../../Components/ServiceTypeIcons';

type Props = {
  project: Object,
  estimateStatuses: [],
  estimateTenderStatuses: [],
  estimateLatestActions: [],
  users: [],
  onChangeEstimateField: (index: any, field: any, value: any) => void,
  onChangeSelectedRows: Function,
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      project,
      estimateStatuses,
      estimateTenderStatuses,
      estimateLatestActions,
      users,
      onChangeEstimateField,
      onChangeSelectedRows,
    } = this.props;

    const columns = [

      {
        title: 'No',
        key: 'reference',
        width: 155,
        fixed: 'left',
        render: (text, record) => (
          <NavLink key={record.id} to={getTeamUrl(`estimates/${record.id}/view`)}>
            {record.full_reference}
          </NavLink>
        ),
      },

      {
        title: 'Client / Site',
        key: 'client.name',
        width: 252,
        fixed: 'left',
        render: () => (
          <div>
            
            <div className="ellipsis">
              <strong>
                {project.client_site.client.name}
              </strong>
            </div>

            <div className="ellipsis">
              {project.client_site.name}
            </div>

          </div>
        ),
      },

      {
        title: ' ',
        key: 'notes',
        width: 25,
        sorter: false,
        fixed: 'left',
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } })
      },

      {
        title: 'Title',
        key: 'title',
        width: 252,
        sorter: false,
        render: (text, record) => (
          <div>
            <div className="ellipsis" style={{ width: 220 }}>
              {record.title}
            </div>
            <div className="ellipsis" style={{ width: 220, color: '#999' }}>
              {project.title}
            </div>
          </div>
        ),
      },

      {
        title: 'Date',
        key: 'date',
        width: 120,
        render: (text, record) => (
          <div>
            {record.date ? moment(record.date).format('DD MMM YYYY') : '--'}
          </div>
        ),
      },
      
      {
        title: 'Due Date',
        key: 'due_date',
        width: 120,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.due_date ? moment(record.due_date).format('DD MMM YYYY') : '--'}
          </div>
        ),
      },
      
      {
        title: 'Follow Up',
        key: 'follow_up_date',
        width: 120,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.follow_up_date ? moment(record.follow_up_date).format('DD MMM YYYY') : '--'}
          </div>
        ),
      },

      {
        title: 'Total',
        key: 'total',
        width: 120,
        render: (text, record) => (
          <div>
            {`£ ${numeral(record.total).format('0,0.00')}`}
          </div>
        ),
      },
      
      {
        title: 'New / Extra',
        key: 'extra_works',
        width: 140,
        render: (text, record) => (
          <div>
            <LegacyIcon type={record.extra_works ? 'plus' : 'star'} style={{ color: record.extra_works ? 'limegreen' : '#1990FF' }} />
            {record.extra_works ? ' Extra Works' : ' New Works'}
          </div>
        ),
      },

      {
        title: 'Status',
        key: 'status_key',
        width: 160,
        render: (text, record) => {

          const options = [
            { value: null, label: 'No Status' },
            ...estimateStatuses.map(item => ({ value: item.key, label: item.title }))
          ];

          const current = find(estimateStatuses, item => String(item.key) === String(record.status_key));

          const color = current && current.color;

          return (
            <TableCellSelect
              type="outlined"
              color={color}
              options={options}
              value={record.status_key}
              onChange={(value) => onChangeEstimateField(record.id, 'status_key', value)}
            />
          )
        }
      },

      {
        title: 'Tender',
        key: 'tender_status_key',
        width: 200,
        render: (text, record) => {

          const options = [
            ...estimateTenderStatuses.map(item => ({ value: item.key, label: item.title }))
          ];

          return (
            <TableCellSelect
              type="default"
              options={options}
              value={record.tender_status_key}
              onChange={(value) => onChangeEstimateField(record.id, 'tender_status_key', value)}
            />
          )
        }
      },

      {
        title: 'Sales Contact',
        key: 'assigned_to_user_id',
        width: 180,
        render: (text, record) => {

          const options = [
            { value: null, label: 'Unassigned' },
            ...users.map(item => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }))
          ];

          return (
            <TableCellSelect
              type="default"
              options={options}
              value={record.assigned_to_user_id}
              onChange={(value) => onChangeEstimateField(record.id, 'assigned_to_user_id', value)}
            />
          )
        }
      },

      {
        title: 'Other Sales Contact',
        key: 'assigned_to_other_user_id',
        width: 180,
        render: (text, record) => {

          const options = [
            { value: null, label: 'Unassigned' },
            ...users.map(item => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }))
          ];

          return (
            <TableCellSelect
              type="default"
              options={options}
              value={record.assigned_to_other_user_id}
              onChange={(value) => onChangeEstimateField(record.id, 'assigned_to_other_user_id', value)}
            />
          )
        }
      },

      {
        title: 'Latest Action',
        key: 'latest_action_key',
        width: 200,
        render: (text, record) => {

          const options = [
            ...estimateLatestActions.map(item => ({ value: item.key, label: item.title }))
          ];

          return (
            <TableCellSelect
              type="default"
              options={options}
              value={record.latest_action_key}
              onChange={(value) => onChangeEstimateField(record.id, 'latest_action_key', value)}
            />
          )
        }
      },

      {
        title: 'Services',
        key: 'item_type_summary',
        width: 0,
        sorter: false,
        render: (text, record) => (
          <ServiceTypeIcons keys={record.item_type_summary} />
        ),
      }

    ];

    const scroll = { x: columns.reduce((total, column) => total + column.width, 150) };

    const estimates = reverse(sortBy(project.estimates, ['date']))

    return (
      <Table
        dataSource={estimates}
        columns={columns}
        rowKey={record => record.id}
        rowSelection={{
          onChange: ids => onChangeSelectedRows(ids),
        }}
        scroll={scroll}
      />
    )
  }
}