import { createAction, handleActions } from 'redux-actions'

const setCurrentDirectoryPath = createAction('MODULES/FILE_MANAGER/SET_CURRENT_DIRECTORY_PATH')
const resetCurrentDirectoryPath = createAction('MODULES/FILE_MANAGER/RESET_CURRENT_DIRECTORY_PATH')

const defaultState = null

const reducer = handleActions(
  {
    [setCurrentDirectoryPath]: (state, action) => action.payload,
    [resetCurrentDirectoryPath]: () => defaultState,
  },
  defaultState,
)

export { setCurrentDirectoryPath, resetCurrentDirectoryPath, reducer }
