// @flow
import * as React from 'react';
import { Modal } from 'antd';
import Form from './Components/Form';

type Props = {
  moving: boolean,  
  componentWillMount: (props: any) => void,
  onClickCancel: () => void,
  onClickSubmit: () => void,
  onSelectTree: Function
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      moving,
      onClickCancel,
      onClickSubmit,
      onSelectTree
    } = this.props;

    return (
        
      <Modal
        title="Move file"
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={moving}
        okText={`${moving ? 'Moving file' : 'Move file'}`}
        width={650}
      >
        
        <Form
          onSelectTree={onSelectTree}
        />

      </Modal>
    )
  }
}