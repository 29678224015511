import { connect } from 'react-redux'
import Table from './Table'

import selectTableRows from './Selectors/selectTableRows'

import onToggle from './Handlers/onToggle'
import onClickRow from './Handlers/onClickRow'
import onClickNewTimesheetAdjustment from './Handlers/onClickNewTimesheetAdjustment'
import onClickTimesheetAdjustment from './Handlers/onClickTimesheetAdjustment'

const mapStateToProps = (state) => ({
  tableRows: selectTableRows(state),
  isFetching: state.modules.TimesheetsDrawer.isFetching,
  selected: state.modules.TimesheetsDrawer.selected,
})

const actionCreators = {
  onToggle,
  onClickRow,
  onClickNewTimesheetAdjustment,
  onClickTimesheetAdjustment,
}

export default connect(mapStateToProps, actionCreators)(Table)
