import immutable from 'immutable';
import { message } from 'antd'
import { loadProject } from '../State/project';
import { updateProject } from '../../../HttpRequests/projects';
import handleException from '../../../Core/Helpers/handleException';

export default (field, value) => async (dispatch, getState) => {

  try {

    // update local state

    let project = immutable.fromJS(getState().screens.ProjectView.project);

    project = project.set(field, value);

    project = project.toJS();

    dispatch(loadProject(project));

    // update on server

    const { id } = project;

    const data = {
      [field]: value || null
    };

    await updateProject({ id, data });

    message.success('Project Updated.');

  } catch (error) {

    handleException(error);

  }
}