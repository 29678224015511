// @flow

import { fromJS } from 'immutable';
import { assign } from 'lodash';
import { loadEstimate } from '../State/estimate';

export type DuplicateEstimateCaveatParams = {
  estimateCaveatIndex: number
}

export default (params: DuplicateEstimateCaveatParams) => (dispatch: Function, getState: Function) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);

  const sourceEstimateCaveat = estimate.getIn(['estimate_caveats', params.estimateCaveatIndex]).toJS();

  const estimateCaveat = assign({}, sourceEstimateCaveat, { id: null });
  
  estimate = estimate.updateIn(
    ['estimate_caveats'],
    (list) => list.insert(params.estimateCaveatIndex + 1, estimateCaveat)
  );

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}