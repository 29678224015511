// @flow
import * as React from 'react';
import classnames from 'classnames';
import { find } from 'lodash';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';

import {
  AlignLeftOutlined,
  CaretDownOutlined,
  CopyOutlined,
  DeleteOutlined,
  DragOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Menu, Dropdown, Tooltip, Popover } from 'antd';
import styles from './GroupItem.css';
import PopoverNotes from './PopoverNotes';

import type {
  OrderItem,
  AddOrderItemParams,
  UpdateOrderItemFieldParams,
  RemoveOrderItemParams,
  DuplicateOrderItemParams
} from '../../../types';

type Props = {
  editable: boolean,
  orderGroupIndex: number,
  orderItemIndex: number,
  orderItem: OrderItem,
  serviceTypes: [],
  selectedOrderItemIds: [],
  onBlurOrderItemField: (params: any) => void,
  onChangeOrderItemField: (params: UpdateOrderItemFieldParams) => void,
  onClickAddOrderItem: (params: AddOrderItemParams) => void,
  onClickRemoveOrderItem: (params: RemoveOrderItemParams) => void,
  onClickDuplicateOrderItem: (params: DuplicateOrderItemParams) => void,
  onToggleOrderItem: () => void
}

type State = {
  hoveredDragArea: boolean,
  hoveredNotesButton: boolean,
  hoveredActionButton: boolean,
  focusedField: null|string
}

class Component extends React.PureComponent<Props,State> {

  state = {
    hoveredDragArea: false,
    hoveredNotesButton: false,
    hoveredActionButton: false
  }

  onChangeOrderItemField = async (field: string, value: string) => {

    const {
      orderGroupIndex,
      orderItemIndex,
      onChangeOrderItemField
    } = this.props;
    
    await onChangeOrderItemField({
      orderGroupIndex,
      orderItemIndex,
      field,
      value
    })
  }

  onBlurOrderItemField = async (field: string) => {

    const {
      orderGroupIndex,
      orderItemIndex,
      onBlurOrderItemField
    } = this.props;
    
    await onBlurOrderItemField({
      orderGroupIndex,
      orderItemIndex,
      field
    })
  }

  onClickMenu = ({ key }: any) => {

    const {
      orderGroupIndex,
      orderItemIndex,
      onClickRemoveOrderItem,
      onClickAddOrderItem,
      onClickDuplicateOrderItem
    } = this.props;

    if (key === 'remove') onClickRemoveOrderItem({ orderGroupIndex, orderItemIndex });
    
    if (key === 'add') onClickAddOrderItem({ orderGroupIndex, orderItemIndex });

    if (key === 'duplicate') onClickDuplicateOrderItem({ orderGroupIndex, orderItemIndex });

  }

  render () {

    const {
      editable,
      orderItem,
      serviceTypes,
      selectedOrderItemIds,
      orderGroupIndex,
      orderItemIndex,
      onChangeOrderItemField,
      onToggleOrderItem
    } = this.props;

    const DragArea = SortableHandle(props => (
      <span {...props}>
        {props.children}
      </span>
    ));

    const ActionMenu = (

      <Menu onClick={this.onClickMenu}>

        <Menu.Item key="add">
          <PlusOutlined />
          {` Add`}
        </Menu.Item>

        <Menu.Item key="duplicate">
          <CopyOutlined />
          {` Duplicate`}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="remove">
          <DeleteOutlined />
          {` Remove`}
        </Menu.Item>

      </Menu>

    )

    const serviceType = find(serviceTypes, type => type.key === orderItem.type);

    const textOnly = !orderItem.type

    const showLineNumber = !textOnly && (!editable || !this.state.hoveredDragArea);

    const showDragIcon = editable && this.state.hoveredDragArea;

    return (
      <div className={styles.wrapper}>

        <DragArea
          className={styles.dragArea}
          style={{ cursor: editable ? 'grab' : 'default' }}
          onMouseEnter={() => editable && this.setState({ hoveredDragArea: true })}
          onMouseLeave={() => this.setState({ hoveredDragArea: false })}
        >

          {showLineNumber && (
            <div className={styles.lineNumber}>
              {orderItem.line_number}
            </div>
          )}

          {showDragIcon && (
            <DragOutlined className={styles.dragIcon} />
          )}

        </DragArea>

        <div className={styles.table}>

          <div className={classnames(styles.cell)} style={{ width: 30 }}>
            <input
              type="checkbox"
              checked={!!find(selectedOrderItemIds, { orderGroupIndex, orderItemIndex })}
              onChange={() => onToggleOrderItem({ id: orderItem.id, orderGroupIndex, orderItemIndex })}
            />
          </div>

          <div className={classnames(styles.cell, { [styles.cellFocused] : this.state.focusedField === 'type' })} style={{ width: 45 }}>
              
            <Tooltip title={serviceType && serviceType.title} placement="right">
            
              <div>

                {serviceType && (
                  <div
                    className={styles.serviceTypeIcon}
                    style={{ backgroundColor: serviceType.color_light, color: serviceType.color_dark }}
                  >
                    {serviceType.key}
                  </div>
                )}

                {textOnly && (
                  <AlignLeftOutlined className={styles.textOnlyIcon} />
                )}

                {editable && (
                  <select
                    className={styles.select}
                    value={orderItem.type || undefined}
                    onChange={event => this.onChangeOrderItemField('type', event.target.value || null)}
                    onFocus={() => this.setState({ focusedField: 'type' })}
                    onBlur={() => this.setState({ focusedField: null })}
                    disabled={!editable}
                  >
                    <optgroup label="Service Types">
                      {serviceTypes.map(option => (
                        <option value={option.key} key={option.key}>
                          {option.title}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Other">
                      <option value="" key="text-only">
                        {"Text Only"}
                      </option>
                    </optgroup>
                  </select>
                )}

              </div>

            </Tooltip>

          </div>

          <div className={classnames(styles.cell, styles.flexCell, { [styles.cellFocused] : this.state.focusedField === 'description' })}>
            <textarea
              className={styles.textarea}
              value={orderItem.description || ''}
              onChange={event => this.onChangeOrderItemField('description', event.target.value)}
              onFocus={() => this.setState({ focusedField: 'description' })}
              onBlur={() => this.setState({ focusedField: null })}
              disabled={!editable}
            />
          </div>

          <div className={classnames(styles.cell, { [styles.cellFocused] : this.state.focusedField === 'quantity' })}>
            <input
              className={styles.input}
              value={!textOnly ? (orderItem.quantity || '') : '-'}
              onChange={event => this.onChangeOrderItemField('quantity', event.target.value)}
              onFocus={event => {
                event.target.select();
                this.setState({ focusedField: 'quantity' });
              }}
              onBlur={() => {
                this.onBlurOrderItemField('quantity');
                this.setState({ focusedField: null })
              }}
              disabled={!editable || textOnly}
            />
          </div>

          <div className={classnames(styles.cell, { [styles.cellFocused] : this.state.focusedField === 'unit' })}>
            <div className={styles.outlet}>
              {!textOnly ? orderItem.unit : '-'}
            </div>
            <select
              className={styles.select}
              value={orderItem.unit}
              onChange={event => this.onChangeOrderItemField('unit', event.target.value)}
              onFocus={() => this.setState({ focusedField: 'unit' })}
              onBlur={() => this.setState({ focusedField: null })}
              disabled={!editable || textOnly}
            >
              {['m','m2','Item','Hours','Days','No.','PSUM','NR'].map(option => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className={classnames(styles.cell, { [styles.cellFocused] : this.state.focusedField === 'rate' })}>
            <input
              className={styles.input}
              value={!textOnly ? (orderItem.rate || '') : '-'}
              onChange={event => this.onChangeOrderItemField('rate', event.target.value)}
              onFocus={event => {
                event.target.select();
                this.setState({ focusedField: 'rate' });
              }}
              onBlur={() => {
                this.onBlurOrderItemField('rate');
                this.setState({ focusedField: null })
              }}
              disabled={!editable || textOnly}
            />
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet, styles.readonly)}>
              {!textOnly ? orderItem.subtotal : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet, styles.readonly)}>
              {!textOnly ? orderItem.discount : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet, styles.readonly)}>
              {!textOnly ? orderItem.total : '-'}
            </div>
          </div>

          <div className={classnames(styles.cell, { [styles.cellFocused] : this.state.focusedField === 'flag' })}>
            <div className={styles.outlet}>
              {!textOnly ? orderItem.flag : '-'}
            </div>
            <select
              className={styles.select}
              value={orderItem.flag || undefined}
              onChange={event => this.onChangeOrderItemField('flag', event.target.value || null)}
              onFocus={() => this.setState({ focusedField: 'flag' })}
              onBlur={() => this.setState({ focusedField: null })}
              disabled={!editable || textOnly}
            >
              {[undefined, 'By Others', 'Omit', 'Included'].map((option) => (
                <option value={option} key={option || 'null'}>
                  {option}
                </option>
              ))}
            </select>
          </div>

        </div>
      
        <div className={styles.actionArea}>

          <span
            onMouseEnter={() => this.setState({ hoveredNotesButton: true })}
            onMouseLeave={() => this.setState({ hoveredNotesButton: false })}
          >
            <Popover
              title="Notes"
              trigger="click"
              content={(
                <PopoverNotes
                  onChangeOrderItemField={onChangeOrderItemField}
                  orderGroupIndex={orderGroupIndex}
                  orderItemIndex={orderItemIndex}
                  editable={editable}
                  note={orderItem.notes}
                />
              )}            
              style={{ minWidth: 320, maxWidth: 320 }}
            >

              {(orderItem.notes || editable) && (
                <LegacyIcon
                  type="message"
                  theme={orderItem.notes ? 'filled' : 'outlined'}
                  style={{ color: orderItem.notes ? '#40a9ff' : ''}}
                  className={classnames(styles.notesButton, { [styles.notesButtonHovered]: this.state.hoveredNotesButton })}
                />
              )}

            </Popover>
          </span>

          {editable && (
            <span
              onMouseEnter={() => this.setState({ hoveredActionButton: true })}
              onMouseLeave={() => this.setState({ hoveredActionButton: false })}
            >
              <Dropdown overlay={ActionMenu} trigger={['click']}>
                <CaretDownOutlined
                  className={classnames(styles.actionButton, { [styles.actionButtonHovered]: this.state.hoveredActionButton })} />
              </Dropdown>
            </span>
          )}
          
        </div>

      </div>
    );
  }
}

export default SortableElement(Component);