import { connect } from 'react-redux'
import { isSubmitting } from 'redux-form'
import TimesheetPosting from './TimesheetPosting'

import { FORM_NAME } from './Form'

import componentWillMount from './Handlers/componentWillMount'
import onClickSubmit from './Handlers/onClickSubmit'
import onClickCancel from './Handlers/onClickCancel'
import onClickDelete from './Handlers/onClickDelete'

const mapStateToProps = (state) => ({
  projects: state.core.projects,
  submitting: isSubmitting(FORM_NAME)(state),
})

const actionCreators = {
  componentWillMount,
  onClickSubmit,
  onClickCancel,
  onClickDelete,
}

export default connect(mapStateToProps, actionCreators)(TimesheetPosting)
