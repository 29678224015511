import { createAction, handleActions } from 'redux-actions';

const loadProjects = createAction("SCREENS/PROFIT_AND_LOSS/LOAD_PROFIT_AND_LOSS");

const defaultState = [];

const reducer = handleActions(
  {
    [loadProjects]: (state, action) => action.payload
  },
  defaultState
);

export { loadProjects, reducer };