import { createAction, handleActions } from 'redux-actions'

const setTimesheets = createAction('SCREENS/TIMESHEETS/SET_TIMESHEETS')

const defaultState = []

const reducer = handleActions(
  {
    [setTimesheets]: (state, action) => action.payload,
  },
  defaultState,
)

export { setTimesheets, reducer }
