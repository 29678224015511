import { setType, setDateRange, clearData } from '../State/estimatesByClientReport'
import fetchEstimatesByClientReport from '../Services/fetchEstimatesByClientReport'

export default (filter, value) => async (dispatch) => {

  dispatch(clearData())

  if (filter === 'type') {
    dispatch(setType(value))
  }

  if (filter === 'dateRange') {
    dispatch(setDateRange(value))
  }

  dispatch(fetchEstimatesByClientReport())

}
