import { createAction, handleActions } from 'redux-actions'

const openControlsModal = createAction('SCREENS/PLANNER/OPEN_CONTROLS_MODAL')
const closeControlsModal = createAction('SCREENS/PLANNER/CLOSE_CONTROLS_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openControlsModal]: (state) => ({
      ...state,
      show: true,
    }),
    [closeControlsModal]: () => defaultState,
  },
  defaultState,
)

export { openControlsModal, closeControlsModal, reducer }
