import { createAction, handleActions } from 'redux-actions'

const setSortBy = createAction('SCREENS/SUBBIES/SUB_PROJECTS/SET_SORT_BY')
const resetSortBy = createAction('SCREENS/SUBBIES/SUB_PROJECTS/RESET_SORT_BY')

const defaultState = null

const reducer = handleActions(
  {
    [setSortBy]: (state, { payload }) => payload !== state ? payload : defaultState,
    [resetSortBy]: () => defaultState,
  },
  defaultState,
)

export { setSortBy, resetSortBy, reducer }
