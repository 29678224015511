import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchClientRiskLevels from '../../../Core/Services/fetchClientRiskLevels';

export default (props) => (dispatch) => {

  setProps(props);

  dispatch(initialize());
  dispatch(fetchClientRiskLevels());
  
}