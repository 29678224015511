import { message } from 'antd'
import { importProfitAndLoss } from '../../../HttpRequests/files'
import fetchProjects from './fetchProjects'

export default (fileProps) => async (dispatch) => {

  const { file } = fileProps
  
  try {
  
    const data = new FormData()

    data.append('file', file)

    await importProfitAndLoss({ data })
    
    dispatch(fetchProjects())

    message.success('Data imported successfully')

  } catch(error) {

    throw error

  }

}
