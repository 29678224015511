import * as React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

export default class extends React.PureComponent {

  static propTypes = {
    itemDescriptions: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,

    onChangeTable: PropTypes.func.isRequired,
    onClickEdit: PropTypes.func.isRequired
  }

  render() {

    const {
      itemDescriptions,
      pagination,
      fetching,
      onChangeTable,
      onClickEdit
    } = this.props;
    
    const columns = [
      {
        title: 'Content',
        dataIndex: 'content',
        render: (text, record) => (
          <a onClick={() => onClickEdit(record)} role="presentation">
            { record.content }
          </a>
        )
      }
    ]
    
    return (
      <Table
        dataSource={itemDescriptions}
        columns={columns} 
        rowKey={record => record.id}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination
        }}
      />
    )
  }
}