import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from "antd";
import styles from './Component.css';


export default class extends React.PureComponent {

  static propTypes = {
    caveats: PropTypes.array.isRequired,
    caveatIds: PropTypes.array.isRequired,
    onToggleCaveat: PropTypes.func.isRequired
  }

  render () {

    const { caveats, caveatIds, onToggleCaveat } = this.props;

    return (
      
      <React.Fragment>

        {caveats.map(caveat => (

          <div key={caveat.id} className={styles.caveatWrapper} role="presentation">

            <Checkbox checked={caveatIds.includes(caveat.id)} onChange={() => onToggleCaveat(caveat.id)} />

            <div
              className={styles.caveatType}
              onClick={() => onToggleCaveat(caveat.id)}
              role="presentation"
            >
              {caveat.type_title}
            </div>

            <div
              className={styles.caveatContent}
              onClick={() => onToggleCaveat(caveat.id)}
              role="presentation"
            >
              {caveat.content}
            </div>

          </div>

        ))}

      </React.Fragment>

    )
  }
}