import { createSelector } from 'reselect';
import selectActiveTab from './selectActiveTab';

const selectProject = state => state.screens.ProjectView.project;
const selectSearchTerm = state => state.screens.ProjectView.searchTerm;

export default createSelector(
  selectProject,
  selectSearchTerm,
  selectActiveTab,
  (project, searchTerm, activeTab) => {
    

    if (activeTab === 'estimates' && searchTerm) {

      const estimatesList = [];
      
      project.estimates.forEach(estimate => {
        
        const search  = searchTerm.toLowerCase();
        const reference = estimate.full_reference.toString().toLowerCase();
        const title = estimate.title ? estimate.title.toLowerCase() : '';
        const contact = estimate.project ? estimate.project.client_name.toLowerCase() : '';
        const site = estimate.project ? estimate.project.client_site_name.toLowerCase() : '';

        if (reference.includes(search) || title.includes(search) || contact.includes(search) || site.includes(search)) {
          estimatesList.push(estimate);
        }
      });
      

      return Object.assign({}, project, {

        estimates: project ? estimatesList : []

      });
    }

    if (activeTab === "orders" && searchTerm) {

      const ordersList = [];
      
      project.orders.forEach(order => {

        const search = searchTerm.toLowerCase();
        const reference = order.reference ? order.reference.toLowerCase() : '';
        const projectNo = order.client_project_number ? order.client_project_number.toString().toLowerCase() : '';
        const contact = order.project ? order.project.client_name.toLowerCase() : '';
        const site = order.project ? order.project.client_site_name.toLowerCase() : '';

        if (reference.includes(search) || projectNo.includes(search) || contact.includes(search) || site.includes(search)) {
          ordersList.push(order);
        }

      });

      return Object.assign({}, project, {

        orders: project ? ordersList : []

      });
    }

    if (activeTab === 'valuations' && searchTerm) {
      
      const valuationList = [];

      project.valuations.forEach(valuation => {

        const search = searchTerm.toLowerCase();
        const reference = valuation.reference.toString();
        const contact = valuation.project ? valuation.project.client_name.toLowerCase() : '';
        const site = valuation.project ? valuation.project.client_site_name.toLowerCase() : '';

        if (reference.includes(search) || contact.includes(search) || site.includes(search)) {
          valuationList.push(valuation);
        }

      });

      return Object.assign({}, project, {

        valuations: project ? valuationList : []

      });
    }

    if (activeTab === 'invoices' && searchTerm) {
      
      const invoiceList = [];

      project.invoices.forEach(invoice => {

        const search = searchTerm.toLowerCase();
        const reference = invoice.id.toString();
        const contact = invoice.project ? invoice.project.client_name.toLowerCase() : '';
        const site = invoice.project ? invoice.project.client_site_name.toLowerCase() : '';

        if (reference.includes(search) || contact.includes(search) || site.includes(search)) {
          invoiceList.push(invoice);
        }

      });

      return Object.assign({}, project, {

        invoices: project ? invoiceList : []

      });
    }

    return project;

  }
)