import { createAction, handleActions } from 'redux-actions'

const openSubcontractorDrawer = createAction('SCREENS/PLANNER/OPEN_SUBCONTRACTOR_DRAWER')
const closeSubcontractorDrawer = createAction('SCREENS/PLANNER/CLOSE_SUBCONTRACTOR_DRAWER')

const defaultState = {
  show: false,
  periodIndex: null,
}

const reducer = handleActions(
  {
    [openSubcontractorDrawer]: (state, { payload }) => ({
      ...state,
      show: true,
      periodIndex: payload.periodIndex,
      search: payload.search,
    }),
    [closeSubcontractorDrawer]: () => defaultState,
  },
  defaultState,
)

export { openSubcontractorDrawer, closeSubcontractorDrawer, reducer }
