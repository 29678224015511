import { createAction, handleActions } from 'redux-actions'

const openTimesheetsDrawer = createAction('SCREENS/INSIGHTS/OPEN_TIMESHEETS_DRAWER')
const closeTimesheetsDrawer = createAction('SCREENS/INSIGHTS/CLOSE_TIMESHEETS_DRAWER')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openTimesheetsDrawer]: (state, { payload }) => ({
      ...payload,
      show: true,
    }),
    [closeTimesheetsDrawer]: () => defaultState,
  },
  defaultState,
)

export { openTimesheetsDrawer, closeTimesheetsDrawer, reducer }
