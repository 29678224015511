// @flow

import fetchUsers from '../../../Core/Services/fetchUsers';
import fetchSubcontractor from '../Services/fetchSubcontractor';

import { setProps } from '../props';

export default (props: any) => async (dispatch: Function) => {

  setProps(props);

  dispatch(fetchUsers());
  dispatch(fetchSubcontractor());

}