import { closeProjectEditorModal } from '../State/projectEditorModal'
import fetchOrder from '../Services/fetchOrder'

export default (reload = false) => (dispatch) => {

  dispatch(closeProjectEditorModal())

  if (reload) {
    dispatch(fetchOrder())
  }

}
