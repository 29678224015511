import fetchValuations from '../Services/fetchValuations';
import fetchValuationTotals from '../Services/fetchValuationTotals';
import fetchServiceTypes from '../../../Core/Services/fetchServiceTypes';
import fetchUsers from '../../../Core/Services/fetchUsers';
import fetchProjects from '../../../Core/Services/fetchProjects';
import fetchClients from '../../../Core/Services/fetchClients';
import fetchValuationStatuses from '../../../Core/Services/fetchValuationStatuses';

export default () => async (dispatch) => {

  await dispatch(fetchValuations());

  dispatch(fetchValuationTotals());
  
  dispatch(fetchServiceTypes());
  dispatch(fetchUsers());
  dispatch(fetchProjects());
  dispatch(fetchClients());
  dispatch(fetchValuationStatuses());
  
}