// @flow

import * as React from 'react';
import styles from './Header.css';

export default () => (

  <div className={styles.wrapper}>

    <div className={styles.spacer} />

    <div className={styles.headings}>

      <div className={styles.cell}>
        Qty
      </div>

      <div className={styles.cell}>
        Unit
      </div>

      <div className={styles.cell}>
        Rate
      </div>

      <div className={styles.cell}>
        Subtotal
      </div>

      <div className={styles.cell}>
        Discount
      </div>

      <div className={styles.cell}>
        Total
      </div>
      
    </div>

  </div>

)