// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { Tooltip } from 'antd';
import styles from './ServiceTypeIcons.css';

type Props = {
  serviceTypes: [],
  keys: any
}

const ServiceTypeIcons = (props: Props) => {

  const {
    serviceTypes,
    keys
  } = props;

  return (

    <div className={styles.wrapper}>

      {keys.map(key => {

        const serviceType = find(serviceTypes, type => type.key === key);

        if (!serviceType) return null;

        return (

          <Tooltip key={key} title={serviceType.title} placement="bottom">

            <div
              className={styles.icon}
              style={{ backgroundColor: serviceType.color_light, color: serviceType.color_dark }}
            >
              {key}
            </div>

          </Tooltip>

        )
      })}

    </div>
    
  )
}

const mapStateToProps = (state) => ({
  serviceTypes: state.core.serviceTypes
})

export default connect(mapStateToProps)(ServiceTypeIcons);