import { combineReducers } from 'redux';

import { reducer as estimate } from './State/estimate';
import { reducer as fetching } from './State/fetching';
import { reducer as editable } from './State/editable';
import { reducer as showEstimateModal } from './State/showEstimateModal';
import { reducer as showOrderModal } from './State/showOrderModal';
import { reducer as showEmailModal } from './State/showEmailModal';
import { reducer as showEstimateDuplicateModal } from './State/showEstimateDuplicateModal';
import { reducer as showSelectCaveatsModal } from './State/showSelectCaveatsModal';
import { reducer as showSelectItemDescriptionModal } from './State/showSelectItemDescriptionModal';
import { reducer as showClientSiteModal } from './State/showClientSiteModal';
import { reducer as showClientContactModal } from './State/showClientContactModal';
import { reducer as projectEditorModal } from './State/projectEditorModal';
import { reducer as selectedEstimateItemIds } from './State/selectedEstimateItemIds';
import { reducer as currentEstimateItem } from './State/currentEstimateItem';

export default combineReducers({
  estimate,
  fetching,
  editable,
  showEstimateModal,
  showOrderModal,
  showEmailModal,
  showEstimateDuplicateModal,
  showSelectCaveatsModal,
  showSelectItemDescriptionModal,
  showClientSiteModal,
  showClientContactModal,
  projectEditorModal,
  selectedEstimateItemIds,
  currentEstimateItem,
})
