import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import Form from './Form'

const SubValuation = props => {

  const {
    subcontractors,
    projects,
    users,
    subValuation,
    submitting,
    componentDidMount,
    onClickSubmit,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
  }, [])

  return (
    
    <Modal
      title={subValuation ? 'Valuation' : 'New Valuation'}
      visible
      okText={subValuation ? 'Update' : 'Create'}
      onOk={() => onClickSubmit()}
      onCancel={() => onClose()}
      confirmLoading={submitting}
      maskClosable={false}
    >

      <Form
        subcontractors={subcontractors}
        projects={projects}
        users={users}
      />

    </Modal>

  )

}

SubValuation.propTypes = {
  subcontractors: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  subValuation: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

SubValuation.defaultProps = {
  subValuation: null,
}

export default SubValuation
