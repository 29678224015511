import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

import Form from './Form'

const SubInvoice = props => {

  const {
    subcontractors,
    users,
    subInvoice,
    submitting,
    componentDidMount,
    onClickSubmit,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
  }, [])

  return (
    
    <Modal
      title={subInvoice ? 'Invoice' : 'New Invoice'}
      visible
      okText={subInvoice ? 'Update' : 'Create'}
      onOk={() => onClickSubmit()}
      onCancel={() => onClose()}
      confirmLoading={submitting}
      maskClosable={false}
    >

      <Form
        subcontractors={subcontractors}
        users={users}
      />

    </Modal>

  )

}

SubInvoice.propTypes = {
  subcontractors: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  subInvoice: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

SubInvoice.defaultProps = {
  subInvoice: null,
}

export default SubInvoice
