import {
  incPeriodIndex,
  decPeriodIndex,
  resetPeriodIndex,
  setFilter,
} from '../../../State/controls'
import { resetData } from '../../../State/data'
import fetch from '../../../Services/fetch'

export default (key, value) => (dispatch) => {

  switch (key) {

    case 'inc-period-index':
      dispatch(incPeriodIndex())
      dispatch(fetch())
      break

    case 'dec-period-index':
      dispatch(decPeriodIndex())
      dispatch(fetch())
      break

    case 'reset-period-index':
      dispatch(resetPeriodIndex())
      dispatch(fetch())
      break

    case 'yAxis':
    case 'summary':
      dispatch(setFilter({ key, value }))
      dispatch(resetData())
      dispatch(fetch())
      break

    case 'projectSearch':
    case 'subcontractorSearch':
    case 'shrink':
      dispatch(setFilter({ key, value }))
      break

    default:
      dispatch(setFilter({ key, value }))
      dispatch(fetch())
      break

  }

}
