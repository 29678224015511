exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2wKkUAKjHghi7tddn8-pgY {\n  width: 250px;\n  height: 100%;\n  overflow: scroll;\n}\n\n._3KQLN32Xy8VVHiUjea9gBE {\n  margin-top: 0px !important;\n  margin-bottom: 0px !important;\n}\n\n._3N5AT-fQ1lKzFUnBiQ8TgB {\n  font-size: 20px;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/ProjectView/Components/FileManager/Components/Menu.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,iBAAiB;CAClB;;AAED;EACE,2BAA2B;EAC3B,8BAA8B;CAC/B;;AAED;EACE,gBAAgB;CACjB","file":"Menu.css","sourcesContent":[".wrapper {\n  width: 250px;\n  height: 100%;\n  overflow: scroll;\n}\n\n.menuItem {\n  margin-top: 0px !important;\n  margin-bottom: 0px !important;\n}\n\n.folderIcon {\n  font-size: 20px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_2wKkUAKjHghi7tddn8-pgY",
	"menuItem": "_3KQLN32Xy8VVHiUjea9gBE",
	"folderIcon": "_3N5AT-fQ1lKzFUnBiQ8TgB"
};