import { createAction, handleActions } from 'redux-actions';

const loadEstimate = createAction("MODALS/SELECT_ESTIMATE_OPTIONALS/LOAD_ESTIMATE");
const clearEstimate = createAction("MODALS/SELECT_ESTIMATE_OPTIONALS/CLEAR_ESTIMATE");

const defaultState = null;

const reducer = handleActions(
  {
    [loadEstimate]: (state, action) => action.payload,
    [clearEstimate]: () => null
  },
  defaultState
);

export { loadEstimate, clearEstimate, reducer };