// @flow

import * as React from 'react';
import List from './Components/List';

type Props = {
  estimate: any,
  listData: [],
  serviceTypes: [],
  editable: boolean,
  onClickAddEstimateOptional: Function,
  onClickRemoveEstimateOptional: Function,
  onClickDuplicateEstimateOptional: Function,
  onChangeEstimateOptionalField: Function,
  onMoveEstimateOptional: Function,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      estimate,
      listData,
      serviceTypes,
      editable,
      onClickAddEstimateOptional,
      onClickRemoveEstimateOptional,
      onClickDuplicateEstimateOptional,
      onChangeEstimateOptionalField,
      onMoveEstimateOptional
    } = this.props;

    return (

      <React.Fragment>

        <List
          estimate={estimate}
          listData={listData}
          serviceTypes={serviceTypes}
          editable={editable}
          onClickAddEstimateOptional={onClickAddEstimateOptional}
          onClickRemoveEstimateOptional={onClickRemoveEstimateOptional}
          onClickDuplicateEstimateOptional={onClickDuplicateEstimateOptional}
          onChangeEstimateOptionalField={onChangeEstimateOptionalField}
          onMoveEstimateOptional={onMoveEstimateOptional}
        />

      </React.Fragment>

    )
  }
}