import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Table as TableProvider, Select, Progress, Divider, Popover } from 'antd'
import { CheckCircleFilled, MinusCircleOutlined } from '@ant-design/icons'
import numeral from 'numeral'
import moment from 'moment'
import { getTeamUrl } from 'Common'
import styles from './styles.css'

const Detail = ({ label, value }) => (
  <div style={{ display: 'flex' }}>
    <div style={{ width: 100, fontWeight: 500 }}>{label}</div>
    <div style={{ width: 100, textAlign: 'right' }}>{value}</div>
  </div>
)

const Table = props => {

  const {
    subValuations,
    fetching,
    onChangeTable,
    onChangeStatus,
  } = props

  return (
    
    <TableProvider
      dataSource={subValuations}
      rowKey='id'
      loading={fetching}
      onChange={onChangeTable}
    >

      <TableProvider.Column
        title='Reference'
        key='project_id'
        sorter
        render={(text, record) => (
          <NavLink to={getTeamUrl(`sub_valuations/${record.id}`)}>
            <div style={{ fontSize: 15 }}>{record.reference}</div>
          </NavLink>
        )}
      />

      <TableProvider.Column
        title='Project'
        key='project_id'
        sorter
        render={(text, { project, last_valuation: lastValuation }) => (
          <Popover
            content={(
              <div>
                <h4 style={{ textDecoration: 'underline' }}>Last Client Valuation</h4>
                <Detail label='Date' value={lastValuation ? moment(lastValuation.date).format('Do MMM YYYY') : 'N/A'} />
                <Detail label='Value' value={lastValuation ? `£${numeral(lastValuation.complete_total).format('0,0.00')}` : 'N/A'} />
                <Divider />
                <h4 style={{ textDecoration: 'underline' }}>Project Totals</h4>
                <Detail label='Value' value={`£${numeral(project.value_total).format('0,0.00')}`} />
                <Detail label='Completed' value={`£${numeral(project.value_completed).format('0,0.00')}`} />
                <Detail label='Remaining' value={`£${numeral(project.value_remaining).format('0,0.00')}`} />
              </div>
            )}
          >
            <div>
              <span style={{ fontSize: 15, fontWeight: 600 }}>{project.id}</span>
              <span style={{ fontSize: 15, fontWeight: 400 }}>{` ${project.client_site.client.name}`}</span>
            </div>
            <div style={{ color: '#999999' }}>{project.client_site.name}</div>
          </Popover>
        )}
      />

      <TableProvider.Column
        title='Date'
        key='date'
        sorter
        render={(text, record) => moment(record.date).format('Do MMM YYYY')}
      />

      <TableProvider.Column
        title='To Date'
        key='to_date'
        sorter
        render={(text, record) => moment(record.to_date).format('Do MMM YYYY')}
      />

      <TableProvider.Column
        title='Owner'
        key='user.name'
        sorter
        render={(text, record) => record.user.full_name}
      />

      <TableProvider.Column
        title='Status'
        key='status'
        render={(text, record) => (
          <Select
            value={record.status}
            onChange={value => onChangeStatus(record.id, record.status, value)}
            dropdownMatchSelectWidth={false}
            className={styles.antInput}
          >
            <Select.Option key='Pending' value='Pending'>
              <MinusCircleOutlined style={{ fontSize: 15, color: '#999999' }} />
              {' Pending'}
            </Select.Option>
            <Select.Option key='Approved' value='Approved'>
              <CheckCircleFilled style={{ fontSize: 15, color: 'orange' }} />
              {' Approved'}
            </Select.Option>
            <Select.Option key='Paid' value='Paid'>
              <CheckCircleFilled style={{ fontSize: 15, color: 'limegreen' }} />
              {' Paid'}
            </Select.Option>
          </Select>
        )}
      />

      <TableProvider.Column
        title='Complete'
        key='complete_total'
        sorter
        onHeaderCell={() => ({
          style: { textAlign: 'right' },
        })}
        onCell={() => ({
          style: { textAlign: 'right' },
        })}
        render={(text, record) => (
          <Popover
            content={(
              <div>
                <h4 style={{ textDecoration: 'underline' }}>Subcontractor Totals</h4>
                <Detail label='Value' value={`£${numeral(record.sub_project.value_total).format('0,0.00')}`} />
                <Detail label='Completed' value={`£${numeral(record.sub_project.value_completed).format('0,0.00')}`} />
                <Detail label='Remaining' value={`£${numeral(record.sub_project.value_remaining).format('0,0.00')}`} />
              </div>
            )}
          >
            <div>
              <div style={{ fontSize: 15, fontWeight: 500 }}>
                {`${record.complete_total < 0 ? '-' : ''}£${numeral(Math.abs(record.complete_total)).format('0,0.00')}`}
              </div>
              <Progress
                percent={numeral(record.aggregate_perc).format('0,0')}
                status={record.aggregate_perc > 100 && 'exception'}
              />
            </div>
          </Popover>
        )}
      />

    </TableProvider>

  )

}

Detail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

Table.propTypes = {
  subValuations: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onChangeTable: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
}

export default Table
