
import { createAction, handleActions } from 'redux-actions';

const setShowSelectCaveatsModal = createAction("SCREENS/ESTIMATE_VIEW/SET_SHOW_SELECT_CAVEATS_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowSelectCaveatsModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowSelectCaveatsModal, reducer };