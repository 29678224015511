import { initialize } from 'redux-form'
import { getRetentionPayment } from '../../../HttpRequests/retentionPayments'
import { FORM_NAME } from '../Form'
import _props from '../props'

export default () => async (dispatch) => {

  const { id } = _props.get()
  
  if (id) {

    const response = await getRetentionPayment({ id })

    const retentionPayment = response.data.data

    dispatch(initialize(FORM_NAME, retentionPayment))
    
  } else {

    dispatch(initialize(FORM_NAME, { }))

  }

}