import { connect } from 'react-redux';
import Component from './Component';

import onClickDownloadFile from './Handlers/onClickDownloadFile';
import onDropOrderFile from './Handlers/onDropOrderFile';
import onClickEditClientSite from './Handlers/onClickEditClientSite'
import onClickEditClientContact from './Handlers/onClickEditClientContact'
import onClickProject from './Handlers/onClickProject'

const mapStateToProps = (state) => ({
  valuation: state.screens.ValuationView.valuation,
  editable: state.screens.ValuationView.editable,
})

const actionCreators = {
  onClickDownloadFile,
  onDropOrderFile,
  onClickEditClientSite,
  onClickEditClientContact,
  onClickProject,
};

export default connect(mapStateToProps, actionCreators)(Component);