// @flow

import { clearOrder } from '../State/order';
import { setEditable } from '../State/editable';
import { setSelectedOrderItemIds } from '../State/selectedOrderItemIds';

export default () => async (dispatch: Function) => {

  dispatch(clearOrder())
  dispatch(setEditable(false))
  dispatch(setSelectedOrderItemIds([]))

  }