import { createSelector } from 'reselect';
import { merge } from 'lodash';
import moment from 'moment';
import baseLineChartConfig from '../Misc/baseLineChartConfig';

const selectData = state => state.screens.Dashboard.topClientsReport.data;

export default createSelector(
  selectData,
  (data) => {

    const categories = [];
    const series = [];
    
    if (data) {

      data.forEach((client, i) => {

        const set = {
          name: client.name,
          data: []
        }

        client.months.forEach(month => {
          if (i === 0) categories.push(moment(month.start_date).format('MMM'));
          set.data.push(month.value);
        })

        series.push(set);

      })

    }
    
    const config = merge({}, baseLineChartConfig, {

      xAxis: {
        categories
      },

      yAxis: {
        min: 0
      },

      series

    });

    return config;

  }
)
