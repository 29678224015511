// @flow

import { fromJS } from 'immutable';
import { assign } from 'lodash';
import { loadEstimate } from '../State/estimate';

export type DuplicateEstimateOptionalParams = {
  estimateOptionalIndex: number
}

export default (params: DuplicateEstimateOptionalParams) => (dispatch: Function, getState: Function) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);

  const sourceEstimateOptional = estimate.getIn(['estimate_optionals', params.estimateOptionalIndex]).toJS();

  const estimateOptional = assign({}, sourceEstimateOptional, { id: null });
  
  estimate = estimate.updateIn(
    ['estimate_optionals'],
    (list) => list.insert(params.estimateOptionalIndex + 1, estimateOptional)
  );

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}