// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';
import type { EstimateOptional } from '../types';

export type AddEstimateOptionalParams = {
  estimateOptionalIndex?: number
}

export default (params: AddEstimateOptionalParams) => (dispatch: Function, getState: Function) => {
  
  let estimate = fromJS(getState().screens.EstimateView.estimate);

  const estimateOptional: EstimateOptional = {
    id: null,
    estimate_id: estimate.get('id'),
    type: 'D',
    description: '',
    quantity: '0.00',
    unit: 'm2',
    rate: '0.00',
    subtotal: '0.00',
    discount: '0.00',
    total: '0.00',
    line_number: null,
    order_number: null
  }
  
  estimate = estimate.updateIn(
    ['estimate_optionals'],
    (list) => params.estimateOptionalIndex !== undefined ? list.insert(params.estimateOptionalIndex + 1, estimateOptional) : list.push(estimateOptional)
  );

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}