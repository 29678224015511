import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from 'antd'
import { orderBy } from 'lodash'
import moment from 'moment'
import styles from './styles.css'

const TimesheetAdjustmentsPopover = props => {

  const {
    timesheet,
    tableRows,
    children,
    onClickNew,
    onClickRow,
  } = props

  if (tableRows.length === 0) return children

  return (
    <Popover
      title={(
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>Adjustments</div>
          <a role='presentation' onClick={() => onClickNew(timesheet)}>New</a>
        </div>
      )}
      content={(
        <table className={styles.table}>
          <thead>
            <tr>
              <th>By</th>
              <th className={styles.num}>Amount</th>
              <th>Reason</th>
              <th>When</th>
            </tr>
          </thead>
          <tbody>
            {orderBy(tableRows, 'updated_at', 'desc').map(timesheetAdjustment => (
              <tr
                key={timesheetAdjustment.id}
                onClick={e => {
                  e.stopPropagation()
                  onClickRow(timesheetAdjustment)
                }}
                className={styles.row}
              >
                <td>{timesheetAdjustment.user.full_name}</td>
                <td className={styles.num}>{timesheetAdjustment.hours_formatted}</td>
                <td>{timesheetAdjustment.reason}</td>
                <td>{moment(timesheetAdjustment.updated_at).fromNow()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    >
      {children}
    </Popover>
  )

}

TimesheetAdjustmentsPopover.propTypes = {
  timesheet: PropTypes.object.isRequired,
  tableRows: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  onClickNew: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
}

export default TimesheetAdjustmentsPopover
