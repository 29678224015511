import { change } from 'redux-form';
import fetchClient from '../Services/fetchClient';
import { FORM_NAME } from '../Form';

export default (clientSite) => async (dispatch) => {

  await dispatch(fetchClient());

  dispatch(change(FORM_NAME, 'client_site_id', clientSite.id))
  
}