import { createAction, handleActions } from 'redux-actions'

const setRootDirectories = createAction('MODULES/FILE_MANAGER/SET_ROOT_DIRECTORIES')
const resetRootDirectories = createAction('MODULES/FILE_MANAGER/RESET_ROOT_DIRECTORIES')

const defaultState = []

const reducer = handleActions(
  {
    [setRootDirectories]: (state, action) => action.payload,
    [resetRootDirectories]: () => defaultState,
  },
  defaultState,
)

export { setRootDirectories, resetRootDirectories, reducer }
