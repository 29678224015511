import { connect } from 'react-redux';
import Component from './Component';

import selectEstimateTotals from './Selectors/selectEstimateTotals';

import onClickEdit from './Handlers/onClickEdit';
import onClickCancel from './Handlers/onClickCancel';
import onClickSave from './Handlers/onClickSave';
import onClickDeleteItems from './Handlers/onClickDeleteItems';

const mapStateToProps = (state) => ({
  estimateTotals: selectEstimateTotals(state),
  estimate: state.screens.EstimateView.estimate,
  editable: state.screens.EstimateView.editable,
  selectedEstimateItemIds: state.screens.EstimateView.selectedEstimateItemIds,
})

const actionCreators = {
  onClickEdit,
  onClickCancel,
  onClickSave,
  onClickDeleteItems
};

export default connect(mapStateToProps, actionCreators)(Component);