import { combineReducers } from 'redux'

import { reducer as setup } from './State/setup'
import { reducer as filters } from './State/filters'
import { reducer as searchTerm } from './State/searchTerm'
import { reducer as sortBy } from './State/sortBy'
import { reducer as data } from './State/data'
import { reducer as fetching } from './State/fetching'
import { reducer as interactionId } from './State/interactionId'
import { reducer as timesheetsDrawer } from './State/timesheetsDrawer'

export default combineReducers({
  setup,
  filters,
  searchTerm,
  sortBy,
  data,
  fetching,
  interactionId,
  timesheetsDrawer,
})
