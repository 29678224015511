import { createSelector } from 'reselect';
import { assign } from 'lodash';

const selectOrder = state => state.screens.OrderView.order;

export default createSelector(
  selectOrder,
  (order) => {

    if (!order) return [];

    let incrementer = 0;

    const items = [];

    order.order_groups.forEach((orderGroup, orderGroupIndex) => {

      items.push({
        type: 'GroupHeader',
        height: 60,
        orderGroup,
        orderGroupIndex
      });

      orderGroup.order_items.forEach((orderItem, orderItemIndex) => {
        
        let lineNumber = null;

        if (orderItem.type) {
          incrementer += 1;
          lineNumber = incrementer;
        }

        items.push({
          type: 'GroupItem',
          height: 50,
          orderGroup,
          orderGroupIndex,
          orderItem: assign({}, orderItem, { line_number: lineNumber }),
          orderItemIndex
        });

      })

      if (orderGroup.order_items.length === 0) {

        items.push({
          type: 'GroupPlaceholder',
          height: 50,
          orderGroup,
          orderGroupIndex
        });

      }

      items.push({
        type: 'GroupFooter',
        height: 60,
        orderGroup,
        orderGroupIndex
      });

    })

    if (order.order_groups.length > 0) {

      items.push({
        type: 'Footer',
        height: 90
      });

    } else {
      
      items.push({
        type: 'Placeholder',
        height: 100
      });

    }

    return items;

  }
)