import React from 'react'
import { Field } from 'redux-form'
import { SelectField, DatePickerField, InputNumberField, TextAreaField, CheckboxField } from 'FormFields'
import { List, Avatar, Divider, Tag } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { orderBy, every } from 'lodash'
import moment from 'moment'
import styles from './Form.css'

const labelColSpan = 6
const wrapperColSpan = 18

type Props = {
  subcontractors: [],
  id: ?any,
  dayRate: ?any,
  timesheetPostings: ?any,
  submitting: boolean,
  onClickRow: () => void,
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      subcontractors,
      id,
      dayRate,
      timesheetPostings,
      submitting,
      onClickRow,
    } = this.props

    const allPriceWork = timesheetPostings.length ? every(timesheetPostings, row => !!row.price_work) : false

    return (

      <div>

        <Field
          component={SelectField}
          name='subcontractor_id'
          label='Subcontractor'
          options={subcontractors.map(subcontractor => ({
            label: subcontractor.name,
            value: subcontractor.id,
          }))}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />

        <Field
          component={DatePickerField}
          name='date'
          label='Date'
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 10 }}
        />

        {!!id && !allPriceWork && (

          <React.Fragment>

            <Field
              component={CheckboxField}
              name='day_rate'
              label=' '
              text='Day Rate'
              disabled={submitting}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              colon={false}
            />

            {!dayRate && (

              <React.Fragment>

                <Field
                  component={InputNumberField}
                  name='hourly_rate'
                  label='Hourly Rate'
                  precision={2}
                  disabled={submitting}
                  labelCol={{ span: labelColSpan }}
                  wrapperCol={{ span: wrapperColSpan }}
                  fieldCol={{ span: 10 }}
                />

                <Field
                  component={SelectField}
                  name='manual_deduction'
                  label='Deduction'
                  options={[
                    { label: 'Auto', value: 'auto' },
                    { label: 'None', value: 'none' },
                    { label: '30m', value: '30m' },
                    { label: '1h', value: '1h' },
                  ]}
                  disabled={submitting}
                  labelCol={{ span: labelColSpan }}
                  wrapperCol={{ span: wrapperColSpan }}
                  fieldCol={{ span: 10 }}
                />

              </React.Fragment>

            )}

            {dayRate && (

              <Field
                component={InputNumberField}
                name='total_cost'
                label='Day Rate'
                precision={2}
                disabled={submitting}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: wrapperColSpan }}
                fieldCol={{ span: 10 }}
              />

            )}

          </React.Fragment>

        )}

        {!!id && (

          <React.Fragment>

            <Field
              component={TextAreaField}
              name='notes'
              label='Notes'
              rows={3}
              disabled={submitting}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 14 }}
            />

            {!!timesheetPostings.length && (

              <React.Fragment>

                <Divider />

                <div style={{ paddingLeft: 25, paddingRight: 25 }}>

                  {orderBy(timesheetPostings, 'start_date_time').map((timesheetPosting, index) => {

                    return (

                      <div
                        role='presentation'
                        key={timesheetPosting.id}
                        onClick={() => onClickRow(timesheetPosting.id)}
                        style={{ cursor: 'pointer' }}
                      >

                        <List.Item
                          className={styles.listItem}
                        >

                          <List.Item.Meta
                            avatar={(
                              <Avatar
                                style={{ backgroundColor: timesheetPosting.end_date_time ? '#87d068' : 'orange' }}
                              >
                                {timesheetPosting.hours_formatted}
                              </Avatar>
                            )}
                            title={(
                              <React.Fragment>
                                <span style={{ fontSize: 15, fontWeight: 600 }}>{timesheetPosting.project.id}</span>
                                <span style={{ fontSize: 15, fontWeight: 400 }}>{` ${timesheetPosting.project.client_site.client.name}`}</span>
                                {!!timesheetPosting.price_work && <Tag color='gold' style={{ fontWeight: 400, marginLeft: 5 }}>Price Work</Tag>}
                              </React.Fragment>
                            )}
                            description={timesheetPosting.project.client_site.name}
                          />

                          <div>
                            <div>
                              <ArrowRightOutlined />
                              {` `}
                              {moment(timesheetPosting.start_date_time).format('HH:mm')}
                            </div>
                            {!!timesheetPosting.end_date_time && (
                              <div>
                                <ArrowLeftOutlined />
                                {` `}
                                {moment(timesheetPosting.end_date_time).format('HH:mm')}
                              </div>
                            )}
                          </div>

                        </List.Item>

                        {(index + 1) < timesheetPostings.length && <br />}

                      </div>

                    )

                  })}

                </div>

              </React.Fragment>

            )}

          </React.Fragment>

        )}
        
      </div>

    )

  }

}
