// @flow

import * as React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { isEqual } from 'lodash';
import classnames from 'classnames';
import { getTeamUrl } from 'Common';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Row, Col, Popover, Tag } from 'antd';
import { NavLink } from 'react-router-dom';
import ServiceTypeIcons from '../../../../Components/ServiceTypeIcons';
import styles from './Component.css';

const Section = (props) => (
  <div className={styles.section} {...props}>
    {props.children}
  </div>
)

const Outlet = (props) => (
  <div className={styles.outlet} {...props}>
    {props.children}
  </div>
)

const OutletLabel = (props) => (
  <div className={styles.outletLabel} {...props}>
    {props.children}
  </div>
)

const OutletData = (props) => (
  <div className={styles.outletData} {...props}>
    {props.children}
  </div>
)

type Props = {
  project: any,
  onClickEditClientSite: Function,
  onClickEditClientContact: Function
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      project,
      onClickEditClientSite,
      onClickEditClientContact
    } = this.props;
    
    const ramsInsufficientReasons = Object.keys(project.rams_insufficient_reasons)

    return (
      <div className={styles.wrapper}>

        <div className={styles.innerWrapper}>

          <Section>

            <Outlet>
              <OutletLabel>Project Title</OutletLabel>
              <OutletData>
                {project.title || '--'}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Client</OutletLabel>
              <OutletData>
                <NavLink to={getTeamUrl(`clients/${project.client_site.client.id}/view`)}>
                  {project.client_site.client.name}
                </NavLink>
                &nbsp;
                {!!project.client_site.client.preferred_supplier && !project.client_site.client.preferred_supplier_perc && <Tag icon={<CheckCircleOutlined />} color='blue'>Pref Supplier</Tag>}
                {!!project.client_site.client.preferred_supplier && !!project.client_site.client.preferred_supplier_perc && <Tag icon={<CheckCircleOutlined />} color='blue'>{`Pref Supplier • ${numeral(project.client_site.client.preferred_supplier_perc).format('0,0.[00]')}%`}</Tag>}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Risk Level</OutletLabel>
              <OutletData>
                <Tag
                  color={({ "1": "green", "2": "orange", "3": "red", "4": "black" })[project.client_site.client.client_risk_level_key]}
                  style={{ cursor: 'default' }}
                >
                  {project.client_site.client.client_risk_level.title}
                </Tag>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Site</OutletLabel>
              <OutletData>
                {[
                  project.client_site.name,
                  project.client_site.address_line_1,
                  project.client_site.address_line_2,
                  project.client_site.address_line_3,
                  project.client_site.town_or_city,
                  project.client_site.county,
                  project.client_site.postcode
                ].filter(line => line).join(', ')}
                {' '}
                <EditFilled onClick={onClickEditClientSite} className={styles.editIcon} />
              </OutletData>
            </Outlet>
            
            <Outlet>
              <OutletLabel>Contact</OutletLabel>
              {project.client_contact ? (
                <OutletData>                
                  <Popover
                    trigger="hover"
                    content={(
                      <div>
                        <div style={{minWidth: 150}}>
                          <strong>Tel: </strong>
                          {project.client_contact.telephone ? project.client_contact.telephone : ''}
                        </div>
                        <div style={{minWidth: 150}}>
                          <strong>Email: </strong>
                          {project.client_contact.email ? project.client_contact.email : ''}
                        </div>
                      </div>
                    )}
                  >
                    {`${project.client_contact.first_name || ''} ${project.client_contact.last_name || ''} `}
                  </Popover>
                  {' '}
                  <EditFilled onClick={onClickEditClientContact} className={styles.editIcon} />
                </OutletData>
              ): (
                <OutletData>
                  TBC
                </OutletData>
              )}
            </Outlet>

            <Outlet>
              <OutletLabel>Contracts Manager</OutletLabel>
              <OutletData>
                <div>
                  {project.contracts_manager && (
                    <div>
                      {`${project.contracts_manager.first_name} ${project.contracts_manager.last_name}`}
                    </div>
                  )}
                  {project.other_contracts_manager && (
                    <div>
                      {`${project.other_contracts_manager.first_name} ${project.other_contracts_manager.last_name}`}
                    </div>
                  )}
                  {(!project.contracts_manager && !project.other_contracts_manager) && (
                    '--'
                  )}
                </div>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Supervisor</OutletLabel>
              <OutletData>
                <div>
                  {!!project.supervisor && (
                    <div>
                      {project.supervisor.name}
                    </div>
                  )}
                  {!project.supervisor && (
                    '--'
                  )}
                </div>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Uplift</OutletLabel>
              <OutletData>
                {project.uplift_percentage ? (
                  <span style={{ color: 'red' }}>
                    {`${numeral(project.uplift_percentage).format('0,0.[00]')}%`}
                    {`${project.uplift_notes ? ` • ${project.uplift_notes}` : ''}`}
                  </span>
                ) : '--'}
              </OutletData>
            </Outlet>

          </Section>

          <Section>

            <Row>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Status</OutletLabel>
                  <OutletData>
                    {project.status.title}
                  </OutletData>
                </Outlet>

                <div />

              </Col>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Phase</OutletLabel>
                  <OutletData>
                    {project.phase.title}
                  </OutletData>
                </Outlet>

                <div />

              </Col>

            </Row>

            <Row>
                
              <Col span={12}>
                
                <Outlet>
                  <OutletLabel>Start Date</OutletLabel>
                  <OutletData>
                    {project.start_date ? moment(project.start_date).format('DD MMM YYYY') : '--'}
                  </OutletData>
                </Outlet>

              </Col>

              <Col span={12}>
                
                <Outlet>
                  <OutletLabel>Handover</OutletLabel>
                  <OutletData>
                    {project.handover_date ? moment(project.handover_date).format('DD MMM YYYY') : '--'}
                  </OutletData>
                </Outlet>

              </Col>

            </Row>

          </Section>

          <Section>
              
            <Row>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Total Orders</OutletLabel>
                  <OutletData>
                    {`£ ${numeral(project.value_total).format('0,0.00')}`}
                  </OutletData>
                </Outlet>
                
                <div />

              </Col>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Billing Type</OutletLabel>
                  <OutletData>
                    {project.billing_type}
                  </OutletData>
                </Outlet>

                <div />

              </Col>

            </Row>

            <Row>
                
              <Col span={12}>
                
                <Outlet>
                  <OutletLabel>Completed</OutletLabel>
                  <OutletData>
                    {`£ ${numeral(project.value_completed).format('0,0.00')}`}
                  </OutletData>
                </Outlet>

              </Col>

              <Col span={12}>
                
                <Outlet>
                  <OutletLabel>Remaining</OutletLabel>
                  <OutletData>
                    {`£ ${numeral(project.value_remaining).format('0,0.00')}`}
                  </OutletData>
                </Outlet>

              </Col>

            </Row>

          </Section>

          <Section>

            <Outlet>
              <OutletLabel>New Works</OutletLabel>
              <OutletData>
                {`£${numeral(project.value_completed_new_works).format('0,0.00')} / £${numeral(project.value_total_new_works).format('0,0.00')}`}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Extra Works</OutletLabel>
              <OutletData>
                {`£${numeral(project.value_completed_extra_works).format('0,0.00')} / £${numeral(project.value_total_extra_works).format('0,0.00')}`}
              </OutletData>
            </Outlet>

          </Section>
          
          <Section>

            <Outlet>
              <OutletLabel>Files Checklist</OutletLabel>
            </Outlet>
            
            <Outlet>
              <OutletData>
                <LegacyIcon
                  type={project.file_method_statement === 1 ? 'check-circle': 'close-circle'}
                  theme={project.file_method_statement === 1 ? 'filled': 'outlined'}
                  className={classnames(styles.outletIcon, {[styles.outletIconActive]: project.file_method_statement === 1 })}
                />
                Method Statement
              </OutletData>
            </Outlet>
            
            <Outlet>
              <OutletData>
                <LegacyIcon
                  type={project.file_risk_assessment === 1 ? 'check-circle': 'close-circle'}
                  theme={project.file_risk_assessment === 1 ? 'filled': 'outlined'}
                  className={classnames(styles.outletIcon, {[styles.outletIconActive]: project.file_risk_assessment === 1 })}
                />
                Risk Assessment
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletData>
                <LegacyIcon 
                  type={project.file_o_and_m === 1 ? 'check-circle': 'close-circle'}
                  theme={project.file_o_and_m === 1 ? 'filled': 'outlined'}
                  className={classnames(styles.outletIcon, {[styles.outletIconActive]: project.file_o_and_m === 1 })}
                />
                O & M
              </OutletData>
            </Outlet>
            
            <Outlet>
              <OutletData>
                <LegacyIcon
                  type={project.file_finishing_schedule === 1 ? 'check-circle': 'close-circle'}
                  theme={project.file_finishing_schedule === 1 ? 'filled': 'outlined'}
                  className={classnames(styles.outletIcon, {[styles.outletIconActive]: project.file_finishing_schedule === 1 })}
                />
                Finishing Schedule
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletData>
                <LegacyIcon
                  type={project.file_programme === 1 ? 'check-circle': 'close-circle'}
                  theme={project.file_programme === 1 ? 'filled': 'outlined'}
                  className={classnames(styles.outletIcon, {[styles.outletIconActive]: project.file_programme === 1 })}
                />
                Programme
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletData>
                <LegacyIcon
                  type={project.file_drawings === 1 ? 'check-circle': 'close-circle'}
                  theme={project.file_drawings === 1 ? 'filled': 'outlined'}
                  className={classnames(styles.outletIcon, {[styles.outletIconActive]: project.file_drawings === 1 })}
                />
                Drawings
              </OutletData>
            </Outlet>

          </Section>
          
          <Section>

            <Outlet>
              <OutletLabel>Project Services</OutletLabel>
              <OutletData>
                <ServiceTypeIcons keys={Object.keys(project.item_type_summary).filter(key => key !== 'O')} />
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>RAMS Status</OutletLabel>
              <OutletData>
                {(project.rams_insufficient === 0) && (
                  <div className={styles.ramsStatus}>
                    <CheckCircleOutlined className={classnames(styles.ramsStatusIcon, styles.sufficient)} />
                    Up to date
                  </div>
                )}
                {(project.rams_insufficient === 1 && isEqual(ramsInsufficientReasons, ['Future'])) && (
                  <div className={styles.ramsStatus}>
                    <ExclamationCircleOutlined className={classnames(styles.ramsStatusIcon, styles.warning)} />
                    Future
                  </div>
                )}
                {(project.rams_insufficient === 1 && !isEqual(ramsInsufficientReasons, ['Future'])) && (
                  <div className={styles.ramsStatus}>
                    <CloseCircleOutlined className={classnames(styles.ramsStatusIcon, styles.insufficient)} />
                    {ramsInsufficientReasons.join(', ')}
                  </div>
                )}
              </OutletData>
            </Outlet>
            
            {ramsInsufficientReasons.length > 0 && (
            <Outlet>
              <OutletLabel>RAMS Issues</OutletLabel>
              <OutletData>
                {ramsInsufficientReasons.map(reason => (
                  <div key={reason}>
                    {`- ${project.rams_insufficient_reasons[reason].map(service => service.title).join(', ')} ${reason}`}
                  </div>
                ))}
              </OutletData>
            </Outlet>
            )}

            {project.rams_notes && (
            <Outlet>
              <OutletLabel>RAMS Notes</OutletLabel>
              <OutletData>
                {project.rams_notes}
              </OutletData>
            </Outlet>
            )}
                    
          </Section>

        </div>

      </div>
    );
  }
}