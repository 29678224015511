import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Header from './Components/Header'
import Table from './Components/Table'

const SubProjects = props => {

  const {
    filters,
    componentDidMount,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [filters])

  return (
    
    <React.Fragment>

      <Header />

      <Table />

    </React.Fragment>

  )

}

SubProjects.propTypes = {
  filters: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
}

export default SubProjects
