import { change } from 'redux-form';
import fetchProject from '../Services/fetchProject';
import { FORM_NAME } from '../Form';

export default (project) => async (dispatch) => {

  dispatch(change(FORM_NAME, 'project_id', project.id))

  dispatch(fetchProject());
  
}