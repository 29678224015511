import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Table as TableProvider } from 'antd'
import numeral from 'numeral'
import moment from 'moment'
import { getTeamUrl } from 'Common'

const Table = props => {

  const {
    subInvoices,
    fetching,
    pagination,
    onChangeTable,
  } = props

  return (
    
    <TableProvider
      dataSource={subInvoices}
      rowKey='id'
      loading={fetching}
      onChange={onChangeTable}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        current: pagination.pageNumber,
        pageSize: pagination.pageSize,
        total: pagination.total,
      }}
    >

      <TableProvider.Column
        title='Reference'
        key='reference'
        sorter
        render={(text, record) => (
          <NavLink to={getTeamUrl(`sub_invoices/${record.id}`)}>
            <div style={{ fontSize: 15 }}>{record.reference}</div>
          </NavLink>
        )}
      />

      <TableProvider.Column
        title='Subcontractor'
        key='subcontractor.name'
        sorter
        render={(text, record) => record.subcontractor.name}
      />

      <TableProvider.Column
        title='Date'
        key='date'
        sorter
        render={(text, record) => moment(record.date).format('Do MMM YYYY')}
      />

      <TableProvider.Column
        title='Owner'
        key='user.name'
        sorter
        render={(text, record) => record.user.full_name}
      />

      <TableProvider.Column
        title='Total'
        key='total'
        sorter
        onHeaderCell={() => ({
          style: { textAlign: 'right' },
        })}
        onCell={() => ({
          style: { textAlign: 'right' },
        })}
        render={(text, record) => `£${numeral(record.total).format('0,0.00')}`}
      />

    </TableProvider>

  )

}

Table.propTypes = {
  subInvoices: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  onChangeTable: PropTypes.func.isRequired,
}

export default Table
