import { createAction, handleActions } from 'redux-actions';

const loadClients = createAction("CORE/CLIENTS/LOAD_CLIENTS");
const clearClients = createAction("CORE/CLIENTS/CLEAR_CLIENTS");

const defaultState = []

const reducer = handleActions(
  {
    [loadClients]: (state, action) => action.payload,
    [clearClients]: () => []
  },
  defaultState
);

export { loadClients, clearClients, reducer };