exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3Fen9qgc4vS_f-WIWTwqky {\n  display: flex;\n  margin-bottom: 15px;\n}\n\n._1QHNGsjpP-FDx4OLgdbqec {\n  flex: 1;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/SubcontractorView/Components/SubOrders/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,QAAQ;CACT","file":"styles.css","sourcesContent":[".header {\n  display: flex;\n  margin-bottom: 15px;\n}\n\n.headerLeft {\n  flex: 1;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"header": "_3Fen9qgc4vS_f-WIWTwqky",
	"headerLeft": "_1QHNGsjpP-FDx4OLgdbqec"
};