import fetchProjectStatuses from '../../../Core/Services/fetchProjectStatuses'
import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors'
import fetchProjects from '../../../Core/Services/fetchProjects'
import fetch from '../Services/fetch'

export default () => (dispatch) => {

  dispatch(fetchProjectStatuses())
  dispatch(fetchSubcontractors())
  dispatch(fetchProjects())

  dispatch(fetch())

}
