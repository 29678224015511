// @flow
import * as React from 'react';
import classnames from 'classnames';
import { find, isEqual } from 'lodash';
import { AlignLeftOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styles from './GroupItem.css';

type Props = {
  editable: boolean,
  activeItemInputKeys: any,
  orderIndex: number,
  orderGroupIndex: number,
  orderItemIndex: number,
  orderItem: any,
  serviceTypes: [],
  onClickTargetCostPercentageOutlet: () => void,
  onClickTargetCostAmountOutlet: () => void,
  onChangeTargetCostPercentage: (params: any) => void,
  onChangeTargetCostAmount: () => void,
  onBlurTargetCostPercentage: (params: any) => void,
  onBlurTargetCostAmount: () => void,
  onTabTargetCostField: () => void,
}

export default class extends React.PureComponent<Props> {

  componentDidUpdate (prevProps) {

    const {
      activeItemInputKeys: prevActiveItemInputKeys
    } = prevProps;

    const {
      activeItemInputKeys,
      orderItem
    } = this.props;
    
    if (!activeItemInputKeys) return;
    if (isEqual(prevActiveItemInputKeys, activeItemInputKeys)) return;
    if (activeItemInputKeys.orderItemId !== orderItem.id) return;

    setTimeout(() => {

      if (activeItemInputKeys.input === 'amount') {
        if (this.AmountInput) {
          this.AmountInput.focus()
          this.AmountInput.select()
        }
      }

      if (activeItemInputKeys.input === 'percentage') {
        if (this.PercentageInput) {
          this.PercentageInput.focus()
          this.PercentageInput.select()
        }
      }

    },100);

  }

  onClickTargetCostPercentageOutlet = async () => {

    const {
      editable,
      orderItem,
      onClickTargetCostPercentageOutlet,
    } = this.props;

    if (!editable) return;

    await onClickTargetCostPercentageOutlet({
      orderItemId: orderItem.id
    })

  }

  onClickTargetCostAmountOutlet = async () => {

    const {
      editable,
      orderItem,
      onClickTargetCostAmountOutlet,
    } = this.props;

    if (!editable) return;

    await onClickTargetCostAmountOutlet({
      orderItemId: orderItem.id
    })

  }

  onChangeTargetCostPercentage = async (value: string) => {

    const {
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      onChangeTargetCostPercentage
    } = this.props;
    
    await onChangeTargetCostPercentage({
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      value
    })
  }

  onChangeTargetCostAmount = async (value: string) => {

    const {
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      onChangeTargetCostAmount
    } = this.props;
    
    await onChangeTargetCostAmount({
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      value
    })
  }

  onBlurTargetCostPercentage = async () => {

    const {
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      onBlurTargetCostPercentage
    } = this.props;
    
    await onBlurTargetCostPercentage({
      orderIndex,
      orderGroupIndex,
      orderItemIndex
    })
  }

  onBlurTargetCostAmount = async () => {

    const {
      orderIndex,
      orderGroupIndex,
      orderItemIndex,
      onBlurTargetCostAmount
    } = this.props;
    
    await onBlurTargetCostAmount({
      orderIndex,
      orderGroupIndex,
      orderItemIndex
    })
  }

  render () {

    const {
      editable,
      activeItemInputKeys,
      serviceTypes,
      orderItem,
      onTabTargetCostField
    } = this.props;

    const serviceType = find(serviceTypes, type => type.key === orderItem.type);

    const textOnly = !orderItem.type
    const excluded = orderItem.flag === 'Omit' || orderItem.flag === 'By Others';

    const showLineNumber = !textOnly;
    const showInputs = !textOnly && !excluded;

    let percentageInputActive = false;
    let amountInputActive = false;

    if (activeItemInputKeys && activeItemInputKeys.orderItemId === orderItem.id) {
      if (activeItemInputKeys.input === 'percentage') percentageInputActive = true;
      if (activeItemInputKeys.input === 'amount') amountInputActive = true;
    }

    return (
      <div className={styles.wrapper}>
        
        <div className={styles.lineNumberArea}>

          {showLineNumber && (
            <div className={styles.lineNumber}>
              {orderItem.line_number}
            </div>
          )}

        </div>

        <div className={classnames(styles.table, styles.flexTable)}>

          <div className={styles.cell} style={{ width: 45 }}>
              
            <Tooltip title={serviceType && serviceType.title} placement="right">
            
              <div>

                {serviceType && (
                  <div
                    className={styles.serviceTypeIcon}
                    style={{ backgroundColor: serviceType.color_light, color: serviceType.color_dark }}
                  >
                    {serviceType.key}
                  </div>
                )}

                {textOnly && (
                  <AlignLeftOutlined className={styles.textOnlyIcon} />
                )}

              </div>

            </Tooltip>

          </div>

          <div className={classnames(styles.cell, styles.flexCell)}>
            <div
              className={classnames(styles.outlet, styles.description)}
              role="presentation"
            >
              {orderItem.description}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet)}>
              {!textOnly ? orderItem.quantity : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet)}>
              {!textOnly ? orderItem.unit : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet)}>
              {!textOnly ? orderItem.discounted_rate : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet)}>
              {!textOnly ? orderItem.total : '-'}
            </div>
          </div>

          <div className={styles.cell}>
            <div className={classnames(styles.outlet)}>
              {!textOnly ? orderItem.flag : '-'}
            </div>
          </div>

        </div>

        <div style={{ width: 16 }} />

        <div className={styles.table}>

          <div className={classnames(styles.cell, styles.splitCell)} style={{ width: 100 }}>

            {showInputs && (

              <React.Fragment>

                <div className={styles.splitCellUpper}>
                  
                  {!percentageInputActive && (
                    <div
                      className={classnames(styles.outlet, { [styles.outletClickable]: editable })}
                      onClick={() => this.onClickTargetCostPercentageOutlet()}
                      role="presentation"
                    >
                      {`${Number(Number(orderItem.target_cost_percentage).toFixed(2))}%`}
                    </div>
                  )}

                  {percentageInputActive && (
                    <input
                      value={orderItem.target_cost_percentage}
                      onChange={(event) => this.onChangeTargetCostPercentage(event.target.value)}
                      onBlur={(event) => this.onBlurTargetCostPercentage(event)}
                      onKeyDown={event => {
                        if (event.which === 9) onTabTargetCostField()
                      }}
                      className={styles.numberInput}
                      disabled={!editable}
                      ref={ref => { this.PercentageInput = ref}}
                    />
                  )}

                </div>

                <div className={styles.splitCellLower}>
                  
                  {!amountInputActive && (
                    <div
                      className={classnames(styles.outlet, { [styles.outletClickable]: editable })}
                      onClick={() => this.onClickTargetCostAmountOutlet()}
                      role="presentation"
                    >
                      {`£${orderItem.target_cost_amount}`}
                    </div>
                  )}

                  {amountInputActive && (
                    <input
                      value={orderItem.target_cost_amount}
                      onChange={event => this.onChangeTargetCostAmount(event.target.value)}
                      onBlur={() => this.onBlurTargetCostAmount()}
                      onKeyDown={event => {
                        if (event.which === 9) onTabTargetCostField()
                      }}
                      className={styles.numberInput}
                      disabled={!editable}
                      ref={ref => { this.AmountInput = ref}}
                    />
                  )}

                </div>
              
              </React.Fragment>

            )}

          </div>

        </div>

      </div>
    );
  }
}