import { message } from 'antd'
import { createEstimate } from '../../../HttpRequests/estimates';

export default (values) => async () => {

  const data = { ...values }

  const response = await createEstimate({ data });

  const estimate = response.data.data;

  message.success('Estimate Created');

  return estimate;

}