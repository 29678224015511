import { combineReducers } from 'redux';

import { reducer as emailSearch } from './State/emailSearch';
import { reducer as fileList } from './State/fileList';
import { reducer as allFiles } from './State/allFiles';
import { reducer as selectedPaths } from './State/selectedPaths';


export default combineReducers({
  emailSearch,
  fileList,
  allFiles,
  selectedPaths,
})
