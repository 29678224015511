import { setCurrentDirectoryPath } from '../../../State/currentDirectoryPath'
import fetchDirectory from '../../../Services/fetchDirectory'
import { resetSelectedItemPath } from '../../../State/selectedItemPath'
import { resetSelectedItemType } from '../../../State/selectedItemType'
import { resetCheckedItemPaths } from '../../../State/checkedItemPaths'

export default (path) => (dispatch) => {

  dispatch(setCurrentDirectoryPath(path))

  dispatch(fetchDirectory())

  dispatch(resetSelectedItemPath())

  dispatch(resetSelectedItemType())

  dispatch(resetCheckedItemPaths())

}
