import { uploadFile } from '../../../../../HttpRequests/modelFiles'
import fetchDirectory from '../../../Services/fetchDirectory'
import handleException from '../../../../../Core/Helpers/handleException'

export default (fileProps) => async (dispatch, getState) => {

  const { file, onProgress, onSuccess, onError } = fileProps

  const { currentDirectoryPath } = getState().modules.FileManager

  const data = new FormData()

  data.append('file', file)
  data.append('path', currentDirectoryPath)
  data.append('name', file.name)

  const config = {
    onUploadProgress: progress => {
      const uploaded = Math.floor((progress.loaded * 100) / progress.total)
      onProgress({ percent: uploaded })
    }
  }

  try {

    await uploadFile({ data, config })

    dispatch(fetchDirectory())

    onSuccess(null, file)

  } catch (error) {

    onError('Upload Error', file)

    handleException(error)

  }

}
