exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2IeR1eLyffFzWriXzUHMag {\n  width: 200px;\n  height: 100%;\n  border-left: 1px solid #CCC;\n  background-color: #FAFAFA;\n  position: fixed;\n  bottom: 0px;\n  right: 0px;\n  z-index: 2;\n  padding-top: 45px;\n  overflow: hidden;\n}\n\n._1kd_bhZhL-tlJzFD61Zkzv {\n  padding-top: 30px !important;\n  padding-bottom: 30px !important;\n  min-height: 100% !important;\n}\n\n.WePpNC240BbfyA3_QHFyE {\n  margin-top: 0px !important;\n  margin-bottom: 0px !important;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Components/SettingsMenu/Component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,4BAA4B;EAC5B,0BAA0B;EAC1B,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,iBAAiB;CAClB;;AAED;EACE,6BAA6B;EAC7B,gCAAgC;EAChC,4BAA4B;CAC7B;;AAED;EACE,2BAA2B;EAC3B,8BAA8B;CAC/B","file":"Component.css","sourcesContent":[".wrapper {\n  width: 200px;\n  height: 100%;\n  border-left: 1px solid #CCC;\n  background-color: #FAFAFA;\n  position: fixed;\n  bottom: 0px;\n  right: 0px;\n  z-index: 2;\n  padding-top: 45px;\n  overflow: hidden;\n}\n\n.menu {\n  padding-top: 30px !important;\n  padding-bottom: 30px !important;\n  min-height: 100% !important;\n}\n\n.menuItem {\n  margin-top: 0px !important;\n  margin-bottom: 0px !important;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_2IeR1eLyffFzWriXzUHMag",
	"menu": "_1kd_bhZhL-tlJzFD61Zkzv",
	"menuItem": "WePpNC240BbfyA3_QHFyE"
};