// @flow

import { setCurrentDirectoryPath } from '../State/currentDirectoryPath';
import fetchDirectory from '../Services/fetchDirectory';
import { setSelectedItemPath } from '../State/selectedItemPath';
import { clearCheckedItemPaths } from '../State/checkedItemPaths';

export default (path: string) => async (dispatch: Function) => {

  dispatch(setCurrentDirectoryPath(path));
  
  dispatch(fetchDirectory());

  dispatch(setSelectedItemPath(''));

  dispatch(clearCheckedItemPaths())

}