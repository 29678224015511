import { message } from 'antd'
import { reactivateUser } from '../../../HttpRequests/users'
import { getProps } from '../props'
import initialize from './initialize'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { id, didReactivate } = getProps()

  try {

    await reactivateUser({ id })

    message.success('User Reactivated')

    dispatch(initialize())

    if (didReactivate) didReactivate()

  } catch (error) {

    handleException(error)

  }

}
