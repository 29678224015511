import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import { find } from 'lodash';

import { FORM_NAME } from '../Form';

import selectEmailOption from './selectEmailOptions';

const selectValues = state => getFormValues(FORM_NAME)(state);

const selectCore = state => state.core;

const emailOptions = state => selectEmailOption(state);

export default createSelector(
  selectValues,
  selectCore,
  emailOptions,
  (values, core, options) =>  {
    
    const { globalContacts } = core;
    
    const selectedEmail = [];
    
    if (values && values.bcc.length > 0) {
      
      values.bcc.forEach(item => {
        
        const checkGlobal = find(globalContacts, { email: item });
        const checkOptions = find(options, { value: item });

        if (checkGlobal && !checkOptions) {
          selectedEmail.push({
            value: checkGlobal.email,
            label: `${checkGlobal.name} <${checkGlobal.email}>`
          });
        }

      })
            
    }

    return selectedEmail;
  }
)