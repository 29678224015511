import { omit } from 'lodash'
import handleException from '../../../Core/Helpers/handleException'
import { getRetentionPaymentTotals } from '../../../HttpRequests/retentionPayments'
import { setFetching } from '../State/fetching'
import { loadTotals } from '../State/totals'

export default () => async (dispatch, getState) => {

  try {

    const { filters, searchTerm } = getState().screens.RetentionPayments

    const params = {}

    if (searchTerm) {
      params.search = searchTerm
    }
    
    if (filters.status !== 'ALL') {
      params['filter[status]'] = filters.status
    }
    
    if (filters.client_id.length > 0) {
      params['filter[client_id]'] = filters.client_id.join(',')
    }

    const response = await getRetentionPaymentTotals({ params: omit(params, ['include', 'page[size]', 'page[number]', 'csort']) })

    dispatch(loadTotals(response.data.data))

  } catch (error) {

    handleException(error)

    dispatch(setFetching(false))

  }
}