
import { createAction, handleActions } from 'redux-actions';

const setSelectedEstimateItemIds = createAction("SCREENS/ESTIMATE_VIEW/SET_SELECTED_ESTIMATE_ITEM_IDS");

const defaultState = [];

const reducer = handleActions(
  {
    [setSelectedEstimateItemIds]: (state, action) => action.payload
  },
  defaultState
);

export { setSelectedEstimateItemIds, reducer };