// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';

export type RemoveEstimateItemParams = {
  estimateGroupIndex: number,
  estimateItemIndex: number
}

export default (params: RemoveEstimateItemParams) => (dispatch: Function, getState: Function) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);
  
  estimate = estimate.removeIn(['estimate_groups', params.estimateGroupIndex, 'estimate_items', params.estimateItemIndex]);

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}