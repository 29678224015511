// @flow

import { clearProject } from '../State/project';
import { setFileMode } from '../State/filemode';
import { clearRootDirectories } from '../State/rootDirectories';
import { clearSearchTerm } from '../State/searchTerm';
import { clearSelectedItemType } from '../State/selectedItemType';
import { clearSelectedItemPath } from '../State/selectedItemPath';
import { setDirectoryActionType } from '../State/directoryActionType';
import { clearCurrentDirectoryPath } from '../State/currentDirectoryPath';
import { clearCurrentDirectoryContents } from '../State/currentDirectoryContents';
import { clearCheckedItemPaths } from '../State/checkedItemPaths';
import { resetSelectedOrderIds } from '../State/selectedOrderIds';
import { resetActiveTab } from '../Components/Valuations/State/activeTab';

export default () => async (dispatch: Function) => {

  dispatch(clearProject())
  dispatch(setFileMode('list'));
  dispatch(clearRootDirectories());
  dispatch(clearSearchTerm());
  dispatch(clearSelectedItemType());
  dispatch(clearSelectedItemPath());
  dispatch(setDirectoryActionType('new'));
  dispatch(clearCurrentDirectoryPath());
  dispatch(clearCurrentDirectoryContents());
  dispatch(clearCheckedItemPaths());
  dispatch(resetSelectedOrderIds());

  dispatch(resetActiveTab());

}