import { message } from 'antd'
import modalProps from '../modalProps'
import { createTimesheetAdjustment, updateTimesheetAdjustment } from '../../../HttpRequests/timesheetAdjustments'
import handleException from '../../../Core/Helpers/handleException'

const convertTimeToHours = time => {
  const [hours, minutes, seconds] = time.split(':')
  return Number(hours) + (Number(minutes) / 60) + (Number(seconds) / 3600)
}

export default (values) => async () => {

  const { timesheetAdjustment, timesheetId } = modalProps()

  let hours = convertTimeToHours(values.time)

  if (values.type === 'reduction') {
    hours *= -1
  }

  const data = {
    hours,
    reason: values.reason,
  }

  try {

    if (timesheetAdjustment) {

      await updateTimesheetAdjustment({ id: timesheetAdjustment.id, data })

      message.success('Adjustment updated')

    } else {

      data.timesheet_id = timesheetId

      await createTimesheetAdjustment({ data })

      message.success('Adjustment created')

    }

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
