import { combineReducers } from "redux";

import { reducer as caveats } from "./State/caveats";
import { reducer as pagination } from "./State/pagination";
import { reducer as searchTerm } from "./State/searchTerm";
import { reducer as fetching } from "./State/fetching";
import { reducer as showCaveatModal } from "./State/showCaveatModal";
import { reducer as selectedCaveatId } from "./State/selectedCaveatId";

export default combineReducers({
  caveats,
  pagination,
  searchTerm,
  fetching,
  showCaveatModal,
  selectedCaveatId
});
