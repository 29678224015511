import { closeSubcontractorEditorModal } from '../State/subcontractorEditorModal'
import fetch from '../Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closeSubcontractorEditorModal())

  if (reload) {
    dispatch(fetch())
  }

}
