import { createAction, handleActions } from 'redux-actions';

const setUploading = createAction("MODALS/UPLOAD/SET_UPLOADING");

const defaultState = false;

const reducer = handleActions(
  {
    [setUploading]: (state, action) => action.payload,
  },
  defaultState
);

export { setUploading, reducer };