import modalProps from '../modalProps'
import fetchProjects from '../../../../../Core/Services/fetchProjects'
import initialize from '../Services/initialize'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchProjects())
  
  dispatch(initialize())
  
}
