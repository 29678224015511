import { closeAddToExistingModal } from '../State/addToExistingModal'
import addToExisting from '../Services/addToExisting'

export default (subOrderId = undefined) => (dispatch) => {

  dispatch(closeAddToExistingModal())

  if (subOrderId) {
    dispatch(addToExisting(subOrderId))
  }

}
