import screenProps from '../screenProps'
import fetchProjectStatuses from '../../../Core/Services/fetchProjectStatuses'
import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors'
import fetchProjects from '../../../Core/Services/fetchProjects'
import fetchUsers from '../../../Core/Services/fetchUsers'
import fetch from '../Services/fetch'

export default (props) => (dispatch) => {

  screenProps(props)

  dispatch(fetchProjectStatuses())
  dispatch(fetchSubcontractors())
  dispatch(fetchProjects())
  dispatch(fetchUsers())

  dispatch(fetch())

}
