import { closeSubcontractorCellModal } from '../State/subcontractorCellModal'
import fetch from '../Services/fetch'

export default (reload = false) => (dispatch) => {

  dispatch(closeSubcontractorCellModal())

  if (reload) {
    dispatch(fetch())
  }

}
