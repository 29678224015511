import modalProps from '../modalProps'
import initialize from '../Services/initialize'

export default (props) => (dispatch) => {

  modalProps(props)
  
  dispatch(initialize())
  
}
