exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3PU6wX4wJvH9qK_MXmVTAc {\n  display: flex;\n  justify-content: flex-end;\n}\n\n._1PMi7zkHENXyvy9_2zd0by {\n  margin-right: auto;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Modals/ItemDescription/Components/Footer/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B;CAC3B;;AAED;EACE,mBAAmB;CACpB","file":"Component.css","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.deleteButton {\n  margin-right: auto;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"footer": "_3PU6wX4wJvH9qK_MXmVTAc",
	"deleteButton": "_1PMi7zkHENXyvy9_2zd0by"
};