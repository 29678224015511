import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import ClientContactModal from '../ClientContact'

import Fields from './Components/Fields'
import Footer from './Components/Footer'

export default class RamsRecord extends React.PureComponent {

  static propTypes = {
    project: PropTypes.object.isRequired,
    clientContacts: PropTypes.array.isRequired,
    clientContactModal: PropTypes.object.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    componentDidUpdate: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    didCreateClientContact: PropTypes.func.isRequired,
    onCloseClientContactModal: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentDidUpdate = (prevProps) => this.props.componentDidUpdate(this.props, prevProps)

  componentWillUnmount = () => this.props.componentWillUnmount()

  render () {

    const {
      project,
      clientContacts,
      clientContactModal,
      didCreateClientContact,
      onCloseClientContactModal,
      onClose,
    } = this.props

    return (
        
      <Modal
        visible  
        title="RAMS"        
        onCancel={onClose}
        footer={<Footer />}
        width={650}
      >
        
        <Fields clientContacts={clientContacts} />

        {clientContactModal.show && (
          <ClientContactModal
            clientId={project.client_site.client_id}
            didCreate={didCreateClientContact}
            onClose={onCloseClientContactModal}
          />
        )}

      </Modal>
      
    )
  }
}