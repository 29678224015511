import { connect } from 'react-redux';
import Component from './Component';

import onClickEditClientSite from './Handlers/onClickEditClientSite'
import onClickEditClientContact from './Handlers/onClickEditClientContact'
import onClickProject from './Handlers/onClickProject'
import onDropFile from './Handlers/onDropFile'
import onDeleteFile from './Handlers/onDeleteFile'

const mapStateToProps = (state) => ({
  estimate: state.screens.EstimateView.estimate
})

const actionCreators = {
  onClickEditClientSite,
  onClickEditClientContact,
  onClickProject,
  onDropFile,
  onDeleteFile,
};

export default connect(mapStateToProps, actionCreators)(Component);