exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3PGPRqGAEFiIwtoGwwjvOF {\n  height: 100%;\n  padding-top: 20px;\n  margin-left: 30px;\n  margin-right: 65px;\n  display: flex;\n  align-items: center;\n}\n\n._3J4BmoZh3455qTnHmR89xj {\n  margin: 0px;\n  flex: 1;\n  font-size: 18px;\n  font-weight: 600;\n  text-decoration: underline;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/ProjectView/Components/TargetCosts/Components/OrderHeader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,YAAY;EACZ,QAAQ;EACR,gBAAgB;EAChB,iBAAiB;EACjB,2BAA2B;CAC5B","file":"OrderHeader.css","sourcesContent":[".wrapper {\n  height: 100%;\n  padding-top: 20px;\n  margin-left: 30px;\n  margin-right: 65px;\n  display: flex;\n  align-items: center;\n}\n\n.name {\n  margin: 0px;\n  flex: 1;\n  font-size: 18px;\n  font-weight: 600;\n  text-decoration: underline;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_3PGPRqGAEFiIwtoGwwjvOF",
	"name": "_3J4BmoZh3455qTnHmR89xj"
};