// @flow

import { getDirectory } from '../../../HttpRequests/files';
import { loadCurrentDirectoryContents } from '../State/currentDirectoryContents';
import handleException from '../../../Core/Helpers/handleException';
import { setFetchingDirectory } from '../State/fetchingDirectory';

export default () => async (dispatch: Function, getState: Function) => {
  
  dispatch(setFetchingDirectory(true));

  try {

    const { currentDirectoryPath } = getState().screens.ProjectView;

    if (!currentDirectoryPath) return;

    const params = {
      path: currentDirectoryPath
    }

    const response = await getDirectory({ params });

    const contents = response.data.data;

    dispatch(loadCurrentDirectoryContents(contents));

  } catch (error) {

    handleException(error);

  }

  dispatch(setFetchingDirectory(false));
}