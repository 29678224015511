import { createAction, handleActions } from 'redux-actions';

const setFileList = createAction("MODALS/EMAIL_ORDER_ACKNOWLEDGEMENT/SET_FILE_LIST");

const defaultState = [];

const reducer = handleActions(
  {
    [setFileList]: (state, action) => action.payload
  },
  defaultState
);

export { setFileList, reducer };