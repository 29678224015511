import { connect } from 'react-redux'
import SubOrderFooter from './SubOrderFooter'

const mapStateToProps = (state) => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(SubOrderFooter)
