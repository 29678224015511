import { initialize } from 'redux-form';
import moment from 'moment';
import { getUserIdForInput } from 'Common';
import { FORM_NAME } from '../Form';

export default () => async (dispatch) => {

  dispatch(initialize(FORM_NAME, {
    user_id: getUserIdForInput(),
    date: moment().format('YYYY-MM-DD')
  }))

}