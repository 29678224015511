// @flow

import { getProject } from '../../../HttpRequests/projects';
import { loadProject } from '../State/project';
import { setFetching } from '../State/fetching';
import { getProps } from '../props';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch: Function) => {
  
  try {
    
    const { id } = getProps().match.params;

    const params = {};
    
    params.include = [
      'client_site',
      'client_site.client.client_contacts',
      'client_contact',
      'contracts_manager',
      'other_contracts_manager',
      'supervisor',
      'estimates.project',
      'orders.project',
      'valuation_dates',
      'valuations.project',
      'valuations.active_invoices',
      'invoices.project',
      'invoices.valuation',
      'invoices.retention_payment',
      'retention_payments',
      'retention_payments.active_invoices',
      'rams_records.client_contact',
    ].join();
    
    params.append = [
      'audit_trail',
      'status',
      'phase',
      'action_statuses',
      'updatable_fields'
    ].join();

    dispatch(setFetching(true));

    const response = await getProject({ id, params });

    const project = response.data.data;

    dispatch(loadProject(project));

    dispatch(setFetching(false));

  } catch (error) {

    handleException(error);

  }
}