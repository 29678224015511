import { createAction, handleActions } from 'redux-actions';

const setEmailSearch = createAction("MODALS/EMAIL_SUB_ORDER/SET_EMAIL_SEARCH");

const defaultState = null;

const reducer = handleActions(
  {
    [setEmailSearch]: (state, action) => action.payload
  },
  defaultState
);

export { setEmailSearch, reducer };