import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import Fields from './Components/Fields';
import Footer from './Components/Footer';

export default class extends React.PureComponent{

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
    orderItemId: PropTypes.number,
    componentWillMount: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired
  }

  static defaultProps = {
    orderItemId: null
  }

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      submitting,
      orderItemId,
      onClickCancel
    } = this.props;

    return (
        
      <Modal
        visible  
        title={`${orderItemId ? 'Order Item': 'New Order Item'}`}        
        onCancel={onClickCancel}
        footer={<Footer submitting={submitting} orderItemId={orderItemId} />}
        width={700}
      >
        
        <Fields submitting={submitting} />

      </Modal>
    )
  }
}