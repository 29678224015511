
import { createAction, handleActions } from 'redux-actions';

const setSelectedOrderItemIds = createAction("SCREENS/ORDER_VIEW/SET_SELECTED_ORDER_ITEM_IDS");

const defaultState = [];

const reducer = handleActions(
  {
    [setSelectedOrderItemIds]: (state, action) => action.payload
  },
  defaultState
);

export { setSelectedOrderItemIds, reducer };