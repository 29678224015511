import * as React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import menuItems from './Misc/menuItems';
import styles from './Component.css';

export default class extends React.PureComponent {

  static propTypes = {
    selectedKey: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired
  }

  render () {

    const {
      selectedKey,
      onSelect
    } = this.props;

    return (

      <div className={styles.wrapper}>

        <Menu
          selectedKeys={[selectedKey]}
          className={styles.menu}
          onSelect={(params) => onSelect(params.key)}
        >

          {menuItems.map(item => (
            <Menu.Item key={item.path} className={styles.menuItem}>
              {item.name}
            </Menu.Item>
          ))}

        </Menu>

      </div>
      
    )
  }
}