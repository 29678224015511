import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import {
  CaretDownOutlined,
  CheckCircleFilled,
  CloudDownloadOutlined,
  EditOutlined,
  ExportOutlined,
  MailOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Divider, Dropdown, Menu, Button } from 'antd';
import { getTeamUrl } from 'Common'
import NotesPopover from './Components/NotesPopover'
import styles from './Component.css'

export default class _Header_ extends React.PureComponent {

  static propTypes = {
    invoice: PropTypes.object.isRequired,
    onClickActionMenuItem: PropTypes.func.isRequired,
    onChangeNotes: PropTypes.func.isRequired,
  }

  render () {

    const {
      invoice,
      onClickActionMenuItem,
      onChangeNotes,
    } = this.props

    const exportMenu = (

      <Menu onClick={onClickActionMenuItem}>

        <Menu.Item key="SEND">
          <MailOutlined />
          {' Send via Email'}
        </Menu.Item>

        <Menu.Item key="DOWNLOAD">
          <CloudDownloadOutlined />
          {' Download PDF'}
        </Menu.Item>

        <Menu.Item key="EXPORT">
          <ExportOutlined />
          {' Export to Quickbooks'}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="EDIT">
          <EditOutlined />
          {' Edit Details'}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="UNDO">
          <UndoOutlined />
          {invoice.type === 'APPLICATION' ? ' Undo Application' : ' Undo Invoice'}
        </Menu.Item>

      </Menu>

    )

    return (
      <div className={styles.wrapper}>

        <div className={styles.breadcrumbs}>

          <Breadcrumb separator="»">

            <Breadcrumb.Item>
              Home
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              <NavLink to={getTeamUrl("invoices")}>
                Invoices
              </NavLink>
            </Breadcrumb.Item>

            <Breadcrumb.Item>
              {invoice.reference}
            </Breadcrumb.Item>

          </Breadcrumb>

        </div>
            
        <Divider />

        <div className={styles.header}>

          <div className={styles.title}>
            {invoice.title}
          </div>

          {!!invoice.added_to_quickbooks && (
            <div className={styles.addedToQuickbooks}>
              <CheckCircleFilled className={styles.addedToQuickbooksIcon} />
              {' Added to Quickbooks'}
            </div>
          )}

          <NotesPopover
            notes={invoice.notes}
            onChangeNotes={onChangeNotes}
          />

          <div style={{ width: 16 }} />
              
          <Dropdown overlay={exportMenu} trigger={['click']}>
            <Button type="primary">
              {'Actions '}
              <CaretDownOutlined />
            </Button>
          </Dropdown>

        </div>

      </div>
    );
  }
}