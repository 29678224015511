import { connect } from 'react-redux'
import SubOrders from './SubOrders'

import componentDidMount from './Handlers/componentDidMount'
import onCloseSubOrderModal from './Handlers/onCloseSubOrderModal'

const mapStateToProps = (state) => ({
  subOrderModal: state.screens.Subbies.SubOrders.subOrderModal,
})

const actionCreators = {
  componentDidMount,
  onCloseSubOrderModal,
}

export default connect(mapStateToProps, actionCreators)(SubOrders)
