const createStaticRef = () => {

  let ref

  const staticRef = newRef => {

    if (newRef !== undefined) {
      ref = newRef
    }

    return ref

  }

  staticRef.reset = () => {
    ref = undefined
  }

  return staticRef

}

export default createStaticRef()
