// @flow

import * as React from 'react';
import { Breadcrumb, Divider } from 'antd';
import { NavLink } from 'react-router-dom';
import { getTeamUrl } from 'Common';

type Props = {
  project: any
}

export default (props: Props) => {

  const { project } = props;

  return (

    <React.Fragment>

      <Breadcrumb separator="»">

        <Breadcrumb.Item>
          Home
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <NavLink to={getTeamUrl("projects")}>
            Projects
          </NavLink>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          {project.id}
        </Breadcrumb.Item>

      </Breadcrumb>
        
      <Divider />

    </React.Fragment>
    
  )
}