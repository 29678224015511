import { connect } from 'react-redux'
import Header from './Header'

import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'
import onChangeFilter from './Handlers/onChangeFilter'
import onClickNew from './Handlers/onClickNew'

const mapStateToProps = (state) => ({
  search: state.screens.SubProject.SubValuations.search,
  filters: state.screens.SubProject.SubValuations.filters,
})

const actionCreators = {
  onChangeSearch,
  onSearch,
  onChangeFilter,
  onClickNew,
}

export default connect(mapStateToProps, actionCreators)(Header)
