import { setCurrentDirectoryPath } from '../../../State/currentDirectoryPath'
import { resetSelectedItemPath } from '../../../State/selectedItemPath'
import fetchDirectory from '../../../Services/fetchDirectory'
import downloadFile from '../../../Services/downloadFile'

export default (path, type) => (dispatch) => {

  if (type === 'directory') {

    dispatch(setCurrentDirectoryPath(path))

    dispatch(resetSelectedItemPath())

    dispatch(fetchDirectory())

  }

  if (type === 'file') {

    dispatch(downloadFile())
    
  }

}
