import { createAction, handleActions } from 'redux-actions'

const setSelected = createAction('MODULES/TIMESHEETS_DRAWER/SET_SELECTED')
const resetSelected = createAction('MODULES/TIMESHEETS_DRAWER/RESET_SELECTED')

const defaultState = []

const reducer = handleActions(
  {
    [setSelected]: (state, { payload }) => payload,
    [resetSelected]: () => defaultState,
  },
  defaultState,
)

export { setSelected, resetSelected, reducer }
