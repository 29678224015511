import React from "react"
import PropTypes from "prop-types"
import Screen from '../../Components/Screen'
import Header from "./Components/Header"
import Filters from "./Components/Filters"
import Table from "./Components/Table"
import Footer from "./Components/Footer"
import RetentionPaymentModal from '../../Modals/RetentionPayment'

export default class _RetentionPayments_ extends React.PureComponent {

  static propTypes = {
    componentDidMount: PropTypes.func.isRequired,
    selectedRetentionPaymentId: PropTypes.number,
    showRetentionPaymentModal: PropTypes.bool.isRequired,
    onCloseRetentionPaymentModal: PropTypes.func.isRequired,
    didUpdateRetentionPayment: PropTypes.func.isRequired,
  }

  static defaultProps = {
    selectedRetentionPaymentId: null
  }

  componentDidMount = () => this.props.componentDidMount()

  render () {

    const {
      selectedRetentionPaymentId,
      showRetentionPaymentModal,
      onCloseRetentionPaymentModal,
      didUpdateRetentionPayment,
    } = this.props

    return (

      <Screen>

        <Header />

        <Filters />

        <Table />

        <Footer />

        {showRetentionPaymentModal && (
          <RetentionPaymentModal
            id={selectedRetentionPaymentId}
            onClose={onCloseRetentionPaymentModal}
            didUpdate={didUpdateRetentionPayment}
          />
        )}

      </Screen>

    )
  }
}
