import { connect } from 'react-redux'
import Estimates from './Estimates'

import onChangeSelectedRows from './Handlers/onChangeSelectedRows'

const mapStateToProps = () => ({
})

const actionCreators = {
  onChangeSelectedRows,
}

export default connect(mapStateToProps, actionCreators)(Estimates)
