import React from 'react'
import PropTypes from 'prop-types'
import { Button, Progress, Popconfirm } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import numeral from 'numeral'
import styles from './styles.css'

const Spacer = () => <div style={{ width: 12 }} />

const Total = props => (
  <div className={styles.total}>
    {`${props.label}: ${props.value < 0 ? '-' : ''}£${numeral(Math.abs(props.value)).format('0,0.00')}`}
  </div>
)

const Footer = props => {

  const {
    editing,
    saving,
    totals,
    onClickEdit,
    onClickSave,
    onClickCancel,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <div className={styles.buttons}>

        {editing ? (

          <React.Fragment>

            <Button type='primary' onClick={onClickSave} loading={saving}>
              Save Changes
            </Button>

            <Spacer />

            <Popconfirm
              title='Cancel your changes?'
              okText='Yes'
              cancelText='No'
              onConfirm={onClickCancel}
            >
              <Button>
                Cancel
              </Button>
            </Popconfirm>

          </React.Fragment>

        ) : (

          <Button onClick={onClickEdit}>
            <EditOutlined />
            Edit Valuation
          </Button>

        )}

      </div>

      <div className={styles.totals}>

        <Total label='Previous' value={totals.previous} />

        <Total label='New' value={totals.new} />

        <Total label='Difference' value={totals.difference} />

        <div className={styles.total}>
          Progress:
          {' '}
          <Progress
            percent={numeral(totals.progress).format('0,0')}
            status={totals.progress > 100 && 'exception'}
            style={{ width: 150, marginBottom: 1 }}
          />
        </div>

      </div>

    </div>
    
  )

}

Total.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}

Footer.propTypes = {
  editing: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  totals: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
}

export default Footer
