import { createAction, handleActions } from 'redux-actions'
import moment from 'moment'

const setFilters = createAction('SCREENS/TIMESHEETS/SET_FILTERS')

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week
  },
})

const defaultState = {
  date_range: [moment().startOf('week'), moment().endOf('week')],
}

const reducer = handleActions(
  {
    [setFilters]: (state, action) => ({ ...state, ...action.payload }),
  },
  defaultState,
)

export { setFilters, reducer }
