import { createAction, handleActions } from 'redux-actions';

const setFilters = createAction("SCREENS/PROFIT_AND_LOSS/SET_FILTERS");

const defaultState = {
  status_key: 'all',
  phase_key: [],
  client_id: [],
  contracts_manager: [],
  start_between: undefined,
  date_between: undefined,
  outstanding_income: false
};

const reducer = handleActions(
  {
    [setFilters]: (state, action) => Object.assign({}, state, action.payload)
  },
  defaultState
);

export { setFilters, reducer };