import { getTeamUrl } from 'Common'

export default () => (dispatch, getState) => {

  const state = getState()

  const { searchTerm, filters, sorting } = state.screens.Timesheets

  const params = {}

  if (searchTerm) params.search = searchTerm

  if (Array.isArray(filters.date_range) && filters.date_range.length === 2) {
    params['filter[date_range]'] = filters.date_range.map(date => date.format('YYYY-MM-DD')).join()
  }

  if (filters.subcontractor_id) params['filter[subcontractor_id]'] = filters.subcontractor_id

  if (filters.project_id) params['filter[project_id]'] = filters.project_id

  params.sort = sorting.columnKey ? (sorting.descending ? '-' : '') + sorting.columnKey : 'date'

  let url = `timesheets/export?`

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.location = getTeamUrl(url, 'exports')

}
