import { createAction, handleActions } from 'redux-actions'

const setUsers = createAction('SCREENS/USERS/SET_USERS')

const defaultState = []

const reducer = handleActions(
  {
    [setUsers]: (state, action) => action.payload,
  },
  defaultState,
)

export { setUsers, reducer }
