
import { createAction, handleActions } from 'redux-actions';

const setShowMoveFilesModal = createAction("SCREENS/PROJECT_VIEW/SET_SHOW_MOVE_FILE_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowMoveFilesModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowMoveFilesModal, reducer };