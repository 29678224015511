import { fromJS } from 'immutable';
import { assign, cloneDeep } from 'lodash';
import { loadOrder } from '../State/order';

export default (params) => (dispatch, getState) => {

  let order = fromJS(getState().screens.OrderView.order);

  const sourceOrderGroup = order.getIn(['order_groups', params.orderGroupIndex]).toJS();

  const orderGroup = assign(cloneDeep(sourceOrderGroup), { id: null });

  orderGroup.id = null;
  orderGroup.order_items = orderGroup.order_items.map(item => assign(item, { id: null }))
  
  order = order.updateIn(
    ['order_groups'],
    (list) => list.insert(params.orderGroupIndex + 1, orderGroup)
  );

  order = order.toJS();
  
  dispatch(loadOrder(order));

}