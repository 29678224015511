import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer, List as ListProvider, Collection } from 'react-virtualized'
import classNames from 'classnames'
import styles from './styles.css'

import Item from '../Item'

export default class List extends React.PureComponent {

  static propTypes = {
    layout: PropTypes.string.isRequired,
    listItems: PropTypes.array.isRequired,
    selectedItemPath: PropTypes.string,
    checkedItemPaths: PropTypes.array.isRequired,
  }

  static defaultProps = {
    selectedItemPath: null,
  }

  componentDidUpdate = () => {
    if (this.List) this.List.forceUpdateGrid()
  }

  List: any

  Collection: any

  renderItem = rowProps => {

    const { key, index, style } = rowProps

    const { listItems, selectedItemPath, checkedItemPaths } = this.props

    const item = listItems[index]

    return (
      <Item
        key={key}
        item={item}
        selected={item.path === selectedItemPath}
        checked={checkedItemPaths.includes(item.path)}
        style={style}
      />
    )

  }

  render() {

    const {
      layout,
      listItems,
    } = this.props

    return (

      <div className={styles.wrapper}>

        {layout === 'list' && (

          <div className={styles.headings}>

            <div className={classNames(styles.col, styles.nameCol)}>
              Name
            </div>

            <div className={classNames(styles.col, styles.timestampCol)}>
              Last Modified
            </div>

          </div>

        )}
        
        <AutoSizer>

          {({ width, height }) => {

            if (layout === 'list') return (

              <ListProvider
                width={width}
                height={height}
                rowHeight={40}
                rowCount={listItems.length}
                rowRenderer={this.renderItem}
                ref={ref => { this.List = ref }}
              />

            )

            if (layout === 'grid') {

              const cellSizeAndPositionGetter = ({ index }) => {

                const colCount = Math.floor(width / 150)

                const cellWidth = (width / colCount)
                const cellHeight = 150

                const colNumber = (index % colCount)
                const cellX = (cellWidth * colNumber)

                const rowNumber = Math.floor(index / colCount)
                const cellY = (cellHeight * rowNumber)

                return {
                  width: cellWidth,
                  height: cellHeight,
                  x: cellX,
                  y: cellY,
                }

              }

              return (

                <Collection
                  width={width}
                  height={height}
                  cellCount={listItems.length}
                  cellRenderer={this.renderItem}
                  cellSizeAndPositionGetter={cellSizeAndPositionGetter}
                  ref={ref => { this.Collection = ref }}
                />

              )

            }

            return false

          }}

        </AutoSizer>

      </div>

    )

  }

}
