import React from 'react';
import { sortBy } from 'lodash';
import { Field } from 'redux-form';
import { SelectField, DatePickerField, CheckboxField } from 'FormFields';

type Props = {
  clientContacts: [],
  users: [],
  submitting: boolean,
}

const labelColSpan = 5;
const wrapperColSpan = 18;

export default class extends React.PureComponent<Props> {

  render () {

    const {
      submitting,
      clientContacts,
      users
    } = this.props;


    const clientContactOptions = sortBy(clientContacts.map(contact => ({
      value: contact.id,
      label: `${contact.first_name} ${contact.last_name ? contact.last_name : ''}`
    })), ['label']);

    const userOptions = users.map(item => ({
      value: item.id,
      label: `${item.first_name} ${item.last_name}`
    }));

    return (

      <div>

        <Field
          required
          component={SelectField}
          name="client_contact_id"
          label="Contact"
          autoComplete="off"
          placeholder="Select Contact"
          options={clientContactOptions}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
        />

        <Field
          required
          component={SelectField}
          name="user_id"
          label="Owner"
          autoComplete="off"
          placeholder="Select Owner"
          options={userOptions}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
        />

        <Field
          required
          component={DatePickerField}
          name="date"
          label="Date"
          autoComplete="off"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />

        <Field
          component={CheckboxField}
          name="added_to_quickbooks"
          label=" "
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          text="Added to Quickbooks"
          colon={false}
        />

      </div>

    )
  }
}