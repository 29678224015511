import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'antd'
import { FormatPainterFilled } from '@ant-design/icons'
import SprayerFilledIcon from '../../SprayerFilledIcon'

const SubcontractorIcon = ({ subcontractor }) => {

  if (subcontractor.is_multiple) return (
    <Badge
      count={subcontractor.slots}
      size='small'
      style={{ backgroundColor: subcontractor.icon, fontSize: 9, fontWeight: 500 }}
    />
  )

  if (subcontractor.type === 'Sprayer') return (
    <SprayerFilledIcon
      color={subcontractor.icon}
      style={{ top: 2, marginLeft: 1, marginRight: 5 }}
    />
  )

  return (
    <FormatPainterFilled
      style={{ color: subcontractor.icon, marginRight: 5 }}
    />
  )

}

SubcontractorIcon.propTypes = {
  subcontractor: PropTypes.object.isRequired,
}

export default SubcontractorIcon
