import { closeTimesheetAdjustmentModal } from '../State/timesheetAdjustmentModal'
import fetchTimesheets from '../Services/fetchTimesheets'

export default (reload) => (dispatch) => {

  dispatch(closeTimesheetAdjustmentModal())

  if (reload) {
    dispatch(fetchTimesheets())
  }

}
