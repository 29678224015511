import { connect } from 'react-redux'
import Overview from './Overview'

import selectOverview from './Selectors/selectOverview'

import componentDidMount from './Handlers/componentDidMount'

const mapStateToProps = (state) => ({
  overview: selectOverview(state),
  fetching: state.screens.SubProject.Overview.fetching,
})

const actionCreators = {
  componentDidMount,
}

export default connect(mapStateToProps, actionCreators)(Overview)
