import { createAction, handleActions } from 'redux-actions'

const openUploadModal = createAction('MODULES/FILE_MANAGER/OPEN_UPLOAD_MODAL')
const closeUploadModal = createAction('MODULES/FILE_MANAGER/CLOSE_UPLOAD_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openUploadModal]: (state) => ({
      ...state,
      show: true,
    }),
    [closeUploadModal]: () => defaultState,
  },
  defaultState,
)

export { openUploadModal, closeUploadModal, reducer }
