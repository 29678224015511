import { message } from 'antd'
import { updateClient } from '../../../HttpRequests/clients';

import handleException from '../../../Core/Helpers/handleException';

export default (values) => async () => {
  
  try {
    const data = {...values};

    const response = await updateClient({ id: values.id, data });
    const client = response.data.data;

    message.success("Client Updated.");

    return client;

  }  catch(error) {
    
    handleException(error);

    return false;
  }

}