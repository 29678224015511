import { connect } from 'react-redux'
import SubOrderGroupFooter from './SubOrderGroupFooter'

import onAddItem from './Handlers/onAddItem'
import onDuplicateGroup from './Handlers/onDuplicateGroup'
import onRemoveGroup from './Handlers/onRemoveGroup'

const mapStateToProps = (state) => ({
  editing: state.screens.SubOrder.editing,
})

const actionCreators = {
  onAddItem,
  onDuplicateGroup,
  onRemoveGroup,
}

export default connect(mapStateToProps, actionCreators)(SubOrderGroupFooter)
