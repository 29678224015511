import { createSelector } from 'reselect'

const selectModel = state => state.screens.Insights.setup.model

export default createSelector(
  selectModel,
  (model) => {
    if (model === 'Timesheet') return true
    return false
  },
)
