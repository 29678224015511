import { message } from 'antd'
import { createClient } from '../../../HttpRequests/clients';
import fetchClients from '../../../Core/Services/fetchClients';

import handleException from '../../../Core/Helpers/handleException';

export default (values) => async (dispatch) => {

  try {
    const data = { ...values }

    const response = await createClient({ data });

    const client = response.data.data;

    message.success('Client Created');

    await dispatch(fetchClients({ force: true }));

    return client;
    
  }  catch(error) {
    
    handleException(error);

    return false;
  }

}
