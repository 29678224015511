import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import Screen from '../../Components/Screen'

import Header from './Components/Header'
import Subcontractors from './Components/Subcontractors'
import SubProjects from './Components/SubProjects'
import SubOrders from './Components/SubOrders'
import SubValuations from './Components/SubValuations'
import SubInvoices from './Components/SubInvoices'

const Subbies = props => {

  const {
    activeTab,
    componentDidMount,
  } = props

  useEffect(() => {
    componentDidMount(props)
  }, [])

  return (

    <Screen>

      <Header />

      <Divider />

      {activeTab === 'subcontractors' && <Subcontractors />}

      {activeTab === 'sub-projects' && <SubProjects />}

      {activeTab === 'sub-orders' && <SubOrders />}

      {activeTab === 'sub-valuations' && <SubValuations />}

      {activeTab === 'sub-invoices' && <SubInvoices />}

    </Screen>

  )

}

Subbies.propTypes = {
  activeTab: PropTypes.string.isRequired,
  componentDidMount: PropTypes.func.isRequired,
}

export default Subbies
