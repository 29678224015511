// @flow

import { fromJS } from 'immutable';
import { loadValuation } from '../State/valuation';

export type UpdateOrderItemDescriptionParams = {
  orderIndex: number,
  orderGroupIndex: number,
  orderItemIndex: number,
  description: string|null
}

export default (params: UpdateOrderItemDescriptionParams) => (dispatch: Function, getState: Function) => {

  let valuation = fromJS(getState().screens.ValuationView.valuation);

  valuation = valuation.setIn(
    [
      'orders',
      params.orderIndex,
      'order_groups',
      params.orderGroupIndex,
      'order_items',
      params.orderItemIndex,
      'description'
    ],
    params.description
  );

  valuation = valuation.toJS();

  dispatch(loadValuation(valuation));

}