import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import numeral from 'numeral'
import { NavLink } from 'react-router-dom'
import { DownOutlined, EyeOutlined, InfoCircleOutlined, PlusOutlined, UndoOutlined } from '@ant-design/icons';
import { Table, Popover, Dropdown, Menu, Input } from 'antd';
import { getTeamUrl } from 'Common';
import styles from './Component.css';

export default class _RetentionPayments_ extends React.PureComponent {

  static propTypes = {
    project: PropTypes.object.isRequired,
    searchTerm: PropTypes.string.isRequired,
    onChangeSearch: PropTypes.func.isRequired,
    onClickRecord: PropTypes.func.isRequired,
    onClickActionMenuItem: PropTypes.func.isRequired,
  }

  render () {

    const {
      project,
      searchTerm,
      onChangeSearch,
      onClickRecord,
      onClickActionMenuItem,
    } = this.props

    const actionMenu = (retentionPayment) => (

      <Menu onClick={({ key }) => onClickActionMenuItem(key, retentionPayment)}>

        <Menu.Item key="CREATE_INVOICE" disabled={retentionPayment.active_invoices.length > 0}>
          <PlusOutlined />
          {' Create Invoice'}
        </Menu.Item>

        <Menu.Item key="VIEW_INVOICE" disabled={retentionPayment.active_invoices.length === 0}>
          <EyeOutlined />
          {' View Invoice'}
        </Menu.Item>

        <Menu.Item key="UNDO_INVOICE" disabled={retentionPayment.active_invoices.length === 0}>
          <UndoOutlined />
          {' Undo Invoice'}
        </Menu.Item>

      </Menu>
      
    )

    const columns = [

      { 
        title: 'Number', 
        key: 'number',
        sorter: false,
        width: 155,
        render: (text, record) => (
          <a onClick={() => onClickRecord(record.id)} role="presentation">
            {`Retention ${record.id}`}
          </a>
        ),
      },

      { 
        title: ' ', 
        key: 'notes',
        sorter: false,
        width: 35,
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } }),
      },

      {
        title: 'Total',
        key: 'total',
        width: 130,
        sorter: false,
        render: (text, record) => record.total ? `£${numeral(record.total).format('0,0.00')}` : '',
      },
      
      {
        title: 'Split',
        key: 'percentage',
        width: 170,
        sorter: false,
        render: (text, record) => `${record.percentage}% [${record.sequence.split(',')[0]} of ${record.sequence.split(',')[1]}]`,
      },
      
      {
        title: 'Due Date',
        key: 'due_date',
        width: 150,
        sorter: false,
        render: (text, record) => record.due_date ? moment(record.due_date).format('DD MMM YYYY') : '',
      },
      
      {
        title: 'Status',
        key: 'status',
        width: 200,
        sorter: false,
        render: (text, record) => (
          <React.Fragment>
            
            {record.active_invoices.length > 0 && `Invoiced ${moment(record.active_invoices[0].date).fromNow()}`}

            {(record.active_invoices.length === 0 && record.due_date < moment().format('YYYY-MM-DD')) && (
              <span className={styles.overdue}>
                {`Due ${moment(record.due_date).fromNow()}`}
              </span>
            )}

            {(record.active_invoices.length === 0 && record.due_date >= moment().format('YYYY-MM-DD')) && (
              <span>
                {`Due ${moment(record.due_date).fromNow()}`}
              </span>
            )}

          </React.Fragment>
        ),
      },

      {
        title: 'Invoice', 
        key: 'invoice',
        width: 150,
        sorter: false,
        render: (text, record) => (
          <React.Fragment>

            {record.active_invoices.length > 0 && (
              <NavLink key={record.active_invoices[0].id} to={getTeamUrl(`invoices/${record.active_invoices[0].id}/view`)}>
                {record.active_invoices[0].title}
              </NavLink>
            )}

            {!record.active_invoices.length === 0 && "--"}

          </React.Fragment>
        ),
      },

      {
        title: ' ', 
        key: 'actions',
        width: 100,
        sorter: false,
        render: (record) => (
          <Dropdown overlay={actionMenu(record)} trigger={['click']} placement="bottomRight">
            <a>
              {'Actions '}
              <DownOutlined />
            </a>
          </Dropdown>
        ),
      },

      {
        title: ' ', 
        key: 'spacer',
        width: 0,
        sorter: false,
        render: () => ''
      },

    ]

    return (

      <React.Fragment>

        <div style={{ marginBottom: 15 }}>

          <Input.Search
            style={{ width: 250 }}
            placeholder="Search"
            onChange={onChangeSearch}
            value={searchTerm}
          />

        </div>

        <Table 
          dataSource={project.retention_payments}
          rowKey={record => record.id}
          columns={columns}
          pagination={false}
        />

      </React.Fragment>
      
    )
  }
}