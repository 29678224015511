import modalProps from '../modalProps'
import { updatePlannerProjectCell } from '../../../../../HttpRequests/planner'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { period, project } = modalProps()

  const { jobs, plannerCell, duplicate } = getState().screens.Planner.ProjectCellModal

  const { upToDate, incWeekends } = duplicate

  const data = {
    project_id: project.id,
    date: period.date,
    jobs: jobs.filter(job => !!job.subcontractor_id),
    planner_cell: plannerCell,
    up_to_date: upToDate ? upToDate.format('YYYY-MM-DD') : undefined,
    inc_weekends: incWeekends,
  }

  if (!plannerCell.required && !plannerCell.alert) {
    data.planner_cell = null
  }

  try {

    await updatePlannerProjectCell({ data })

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

    throw error

  }

}
