// @flow
import * as React from 'react';
import moment from 'moment';
import styles from './OrderHeader.css';

import type {
  
} from '../../../types';

type Props = {
  order: any
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      order
    } = this.props;

    return (

      <div className={styles.wrapper}>

        <div className={styles.name}>
          {'Purchase Order: '}
          {order.reference}
          {` - ${moment(order.date).format('DD/MM/YYYY')}`}
        </div>

      </div>

    )
  }
}