import { connect } from "react-redux";

import Component from "./Component";

import componentDidMount from "./Handlers/componentDidMount";
import onCloseItemDescriptionModal from "./Handlers/onCloseItemDescriptionModal";
import didCreateItemDescription from "./Handlers/didCreateItemDescription";
import didUpdateItemDescription from "./Handlers/didUpdateItemDescription";
import didDeleteItemDescription from "./Handlers/didDeleteItemDescription";

const mapStateToProps = state => ({
  selectedItemDescriptionId: state.screens.ItemDescriptions.selectedItemDescriptionId,
  showItemDescriptionModal: state.screens.ItemDescriptions.showItemDescriptionModal
});

const actionCreators = {
  componentDidMount,
  onCloseItemDescriptionModal,
  didCreateItemDescription,
  didUpdateItemDescription,
  didDeleteItemDescription
};

export default connect(
  mapStateToProps,
  actionCreators
)(Component);
