import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { getTeamUrl } from 'Common'
import styles from './styles.css'

class SubOrder extends React.PureComponent {

  static propTypes = {
    subOrder: PropTypes.object.isRequired,
  }

  render() {

    const {
      subOrder,
    } = this.props

    return (

      <div className={styles.wrapper}>
        
        <div className={styles.reference}>
          <NavLink to={getTeamUrl(`sub_orders/${subOrder.id}`)} style={{ color: '#000000d9' }}>
            {subOrder.reference}
          </NavLink>
        </div>

      </div>

    )

  }

}

export default SubOrder
