import { createAction, handleActions } from 'redux-actions'

const setSubInvoice = createAction('SCREENS/SUB_INVOICE/SET_SUB_INVOICE')
const resetSubInvoice = createAction('SCREENS/SUB_INVOICE/RESET_SUB_INVOICE')

const defaultState = null

const reducer = handleActions(
  {
    [setSubInvoice]: (state, { payload }) => payload,
    [resetSubInvoice]: () => defaultState,
  },
  defaultState,
)

export { setSubInvoice, resetSubInvoice, reducer }
