// @flow

import { fromJS } from 'immutable';
import { loadOrder } from '../State/order';
import type { OrderCaveat } from '../types';

export type AddOrderCaveatParams = {
  orderCaveatIndex?: number
}

export default (params: AddOrderCaveatParams) => (dispatch: Function, getState: Function) => {
  
  let order = fromJS(getState().screens.OrderView.order);

  const orderCaveat: OrderCaveat = {
    id: null,
    order_id: order.get('id'),
    content: '',
    line_number: null,
    order_number: null
  }
  
  order = order.updateIn(
    ['order_caveats'],
    (list) => params.orderCaveatIndex !== undefined ? list.insert(params.orderCaveatIndex + 1, orderCaveat) : list.push(orderCaveat)
  );

  order = order.toJS();
  
  dispatch(loadOrder(order));

}