import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import Component from './Component';

import selectClientContacts from './Selectors/selectClientContacts';
import selectShowNewClientContactButton from './Selectors/selectShowNewClientContactButton';
import selectClientId from './Selectors/selectClientId';

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import onClickCancel from './Handlers/onClickCancel';
import onClickSubmit from './Handlers/onClickSubmit';

import onClickNewClientContact from './Handlers/onClickNewClientContact';
import onCloseClientContactModal from './Handlers/onCloseClientContactModal';
import didCreateClientContact from './Handlers/didCreateClientContact';

import onChangeProject from './Handlers/onChangeProject';
import { FORM_NAME } from './Form';

const mapStateToProps = (state) => ({
  submitting: isSubmitting(FORM_NAME)(state),
  users: state.core.users,
  projects: state.core.projects,
  showClientContactModal: state.modals.Valuation.showClientContactModal,
  clientId: selectClientId(state),
  clientContacts: selectClientContacts(state),
  showNewClientContactButton: selectShowNewClientContactButton(state),  
});

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  onClickCancel,
  onClickSubmit,
  onChangeProject,
  onClickNewClientContact,
  onCloseClientContactModal,
  didCreateClientContact
};

export default connect(mapStateToProps, actionCreators)(Component);