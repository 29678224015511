import { combineReducers } from 'redux';

import { reducer as order } from './State/order';
import { reducer as editable } from './State/editable';
import { reducer as showOrderModal } from './State/showOrderModal';
import { reducer as showEmailModal } from './State/showEmailModal';
import { reducer as showClientSiteModal } from './State/showClientSiteModal';
import { reducer as showClientContactModal } from './State/showClientContactModal';
import { reducer as projectEditorModal } from './State/projectEditorModal';
import { reducer as selectedOrderItemIds } from './State/selectedOrderItemIds';
import { reducer as subOrderModal } from './State/subOrderModal';

export default combineReducers({
  order,
  editable,
  showOrderModal,
  showEmailModal,
  showClientSiteModal,
  showClientContactModal,
  projectEditorModal,
  selectedOrderItemIds,
  subOrderModal,
})
