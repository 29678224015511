import { connect } from 'react-redux'
import SubInvoice from './SubInvoice'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onCloseSubInvoiceModal from './Handlers/onCloseSubInvoiceModal'
import onCloseSubValuationModal from './Handlers/onCloseSubValuationModal'

const mapStateToProps = (state) => ({
  subInvoice: state.screens.SubInvoice.subInvoice,
  activeTab: state.screens.SubInvoice.activeTab,
  subInvoiceModal: state.screens.SubInvoice.subInvoiceModal,
  subValuationModal: state.screens.SubInvoice.subValuationModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onCloseSubInvoiceModal,
  onCloseSubValuationModal,
}

export default connect(mapStateToProps, actionCreators)(SubInvoice)
