import { connect } from 'react-redux'
import SubProjects from './SubProjects'

import componentDidMount from './Handlers/componentDidMount'
import onCloseSubOrderModal from './Handlers/onCloseSubOrderModal'
import onCloseSubValuationModal from './Handlers/onCloseSubValuationModal'

const mapStateToProps = (state) => ({
  mode: state.screens.SubProjects.mode,
  subOrderModal: state.screens.SubProjects.subOrderModal,
  subValuationModal: state.screens.SubProjects.subValuationModal,
})

const actionCreators = {
  componentDidMount,
  onCloseSubOrderModal,
  onCloseSubValuationModal,
}

export default connect(mapStateToProps, actionCreators)(SubProjects)
