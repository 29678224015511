import { createAction, handleActions } from 'redux-actions'

const openSubcontractorEditorModal = createAction('SCREENS/PLANNER/OPEN_SUBCONTRACTOR_EDITOR_MODAL')
const closeSubcontractorEditorModal = createAction('SCREENS/PLANNER/CLOSE_SUBCONTRACTOR_EDITOR_MODAL')

const defaultState = {
  show: false,
  subcontractor: null,
}

const reducer = handleActions(
  {
    [openSubcontractorEditorModal]: (state, { payload }) => ({
      show: true,
      subcontractor: payload.subcontractor,
    }),
    [closeSubcontractorEditorModal]: () => defaultState,
  },
  defaultState,
)

export { openSubcontractorEditorModal, closeSubcontractorEditorModal, reducer }
