import updateOrderItemDescription from '../Services/updateOrderItemDescription';

export default (orderItem) => async (dispatch, getState) => {

  const { selectedOrderItemKeys } = getState().screens.ValuationView;

  dispatch(updateOrderItemDescription({
    orderIndex: selectedOrderItemKeys.orderIndex,
    orderGroupIndex: selectedOrderItemKeys.orderGroupIndex,
    orderItemIndex: selectedOrderItemKeys.orderItemIndex,
    description: orderItem.description
  }))
  
}