export default (values) => {

  const errors = {}

  if (!values.first_name) errors.first_name = true

  if (!values.last_name) errors.last_name = true

  if (!values.type) errors.type = true

  if (values.type === 'Subcontractor') {

    if (!values.subcontractor_id) errors.subcontractor_id = true

  }

  if (!values.email) errors.email = true

  if (!values.id) {

    if (!values.password) errors.password = true

    if (!values.password_confirm) errors.password_confirm = true

    if (values.password_confirm !== values.password) errors.password_confirm = true

  }

  return errors
  
}
