// @flow

import * as React from 'react';
import { sortBy } from 'lodash';
import { Field } from 'redux-form';
import { Divider } from 'antd';
import { SelectField, DatePickerField } from 'FormFields';
import IconButton from '../../../Components/IconButton';

const labelColSpan = 5;
const wrapperColSpan = 18;

type Props = {
  projects: [],
  users: [],
  clientContacts: [],
  showNewClientContactButton: boolean,
  submitting: boolean,
  onChangeProject: Function,
  onClickNewProject: Function,
  onClickNewClientContact: Function
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      projects,
      users,
      clientContacts,
      showNewClientContactButton,
      submitting,
      onChangeProject,
      onClickNewProject,
      onClickNewClientContact
    } = this.props;

    const projectOptions = projects.map(item => ({
      value: item.id,
      label: `[${item.id}] ${item.client_site.client.name} - ${item.client_site.name}`
    }));

    const clientContactOptions = sortBy(clientContacts.map(item => ({
      value: item.id,
      label: `${item.first_name || ''} ${item.last_name || ''}`
    })), ['label']);

    const userOptions = users.map(item => ({
      value: item.id,
      label: `${item.first_name} ${item.last_name}`
    }));

    const NewProjectButton = () => (
      <IconButton
        type="plus-circle"
        tooltip="New Project"
        onClick={onClickNewProject}
        style={{ marginBottom: -4 }}
      />
    );

    const NewClientContactButton = () => (
      <IconButton
        type="plus-circle"
        tooltip="New Contact"
        onClick={onClickNewClientContact}
        style={{ marginBottom: -4 }}
      />
    );

    return (

      <div>

        <Field
          required
          component={SelectField}
          name="project_id"
          label="Project"
          placeholder="Select Project"
          disabled={submitting}
          options={projectOptions}
          onChange={onChangeProject}
          renderAfter={() => <NewProjectButton />}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

        <Divider />

        <Field
          required
          component={SelectField}
          name="client_contact_id"
          label="Contact"
          placeholder="Select contact"
          disabled={submitting}
          options={clientContactOptions}
          renderAfter={() => showNewClientContactButton && <NewClientContactButton />}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}
        />

        <Field
          component={SelectField}
          name="user_id"
          label="Owner"
          placeholder="Select user"
          disabled={submitting}
          options={userOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />
  
        <Field
          required
          component={DatePickerField}
          name="date"
          label="Date"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />
            
        <Field
          component={DatePickerField}
          name="due_date"
          label="Due"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />
            
        <Field
          component={DatePickerField}
          name="follow_up_date"
          label="Follow Up"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />
        
      </div>

    )
  }
}