// @flow
import * as React from 'react';
import { Modal } from 'antd';
import Form from './Form';
import ClientContactModal from '../ClientContact'

type Props = {
  valuation: ?any,
  projects: [],
  users: [],
  clientContacts: [],
  clientId: any,
  submitting: boolean,
  showClientContactModal: boolean,
  showNewClientContactButton: boolean,

  componentWillMount: (props: any) => void,
  componentWillUnmount: () => void,
  onChangeProject: () => void, 
  onClickCancel: () => void,
  onClickSubmit: () => void,
  onClickNewClientContact: () => void,
  onCloseClientContactModal: () => void,
  didCreateClientContact: () => void
  
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      valuation,
      projects,
      users,
      clientContacts,
      clientId,
      submitting,
      showClientContactModal,
      showNewClientContactButton,

      onChangeProject,
      onClickCancel,
      onClickSubmit,
      onClickNewClientContact,
      onCloseClientContactModal,
      didCreateClientContact
    } = this.props;

    return (
        
      <Modal
        title={valuation ? 'Edit Valuation': 'New Valuation'}
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText={valuation ? 'Update': 'Create'}
        width={650}
      >
        
        <Form
          valuation={valuation}
          projects={projects}
          users={users}
          clientContacts={clientContacts}
          showNewClientContactButton={showNewClientContactButton}
          onChangeProject={onChangeProject}
          onClickNewClientContact={onClickNewClientContact}
        />

        {showClientContactModal && (
          <ClientContactModal
            onClose={onCloseClientContactModal}
            didCreate={didCreateClientContact}
            clientId={clientId}
          />
        )}

      </Modal>
    )
  }
}