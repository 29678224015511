// @flow
import * as React from 'react';
import { Select, Radio, Switch } from 'antd';

const defaultSelectProps = {
  style: { width: 230 },
  showArrow: true,
  showSearch: true,
  allowClear: true,
  notFoundContent: null,
  defaultActiveFirstOption: false,
  optionFilterProp: 'children',
};

type Props = {
  filters: any,
  projectPhases: [],
  projectStatuses: [],
  clients: [],
  users: [],
  subcontractors: [],

  onChangeFilter: Function
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      filters,
      projectPhases,
      projectStatuses,
      clients,
      users,
      subcontractors,
      onChangeFilter
    } = this.props;
    
    const projectOptions = [
      { title: 'All', key: 'all' },
      ...projectStatuses
    ]

    return (

      <React.Fragment>

        <div style={{ display: 'flex', marginBottom: 15 }}>
          
          <Radio.Group
            value={filters.status_key}
            onChange={(event) => onChangeFilter('status_key', event.target.value)}
          >

            {projectOptions.map(option => (
              <Radio.Button
                style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
                value={option.key}
                key={option.key}
              >
                {option.title}
              </Radio.Button>
            ))}

          </Radio.Group>
          
          <div style={{ width: 10 }} />


          <Select
            {...defaultSelectProps}
            placeholder="Filter by Phase"
            value={filters.phase_key}
            onChange={(value) => onChangeFilter('phase_key', value)}
            mode="multiple"
          >
            {projectPhases.map(phase => (
              <Select.Option key={phase.key}>
                {`${phase.title}`}
              </Select.Option>
            ))}
          </Select>


          <div style={{ width: 10 }} />


          <Select
            {...Object.assign({}, defaultSelectProps)}
            placeholder="Filter by Client"
            value={filters.client_id}
            onChange={(value) => onChangeFilter('client_id', value)}
            mode="multiple"
          >
            
            {clients.map(client => (
              <Select.Option key={client.id}>
                {`${client.name}`}
              </Select.Option>
            ))}

          </Select>


          <div style={{ width: 10 }} />


          <Select
            {...Object.assign({}, defaultSelectProps)}
            placeholder="Filter by Contracts Manager"
            value={filters.contracts_manager}
            onChange={(value) => onChangeFilter('contracts_manager', value)}
            mode="multiple"
          >

            {users.map(user => (
              <Select.Option key={user.id}>
                {`${user.first_name} ${user.last_name}`}
              </Select.Option>
            ))}

          </Select>


          <div style={{ width: 10 }} />


          <Select
            {...Object.assign({}, defaultSelectProps)}
            placeholder="Filter by Supervisor"
            value={filters.supervisor_id}
            onChange={(value) => onChangeFilter('supervisor_id', value)}
            mode="multiple"
          >

            {subcontractors.map(subcontractor => (
              <Select.Option key={subcontractor.id}>
                {subcontractor.name}
              </Select.Option>
            ))}

          </Select>

        </div>

        

        <div style={{ display: 'flex', marginBottom: 15 }}>

          <Select
            placeholder="Filter by Order Acknowledgment"
            value={filters.order_ack_sent}
            onChange={(value) => onChangeFilter('order_ack_sent', value)}
            allowClear
            style={{ width: 230 }}
          >
            <Select.Option key={0}>Not Sent</Select.Option>
            <Select.Option key={1}>Sent</Select.Option>
          </Select>


          <div style={{ width: 10 }} />


          <Select
            placeholder="Filter by Project Info"
            value={filters.project_info_sent}
            onChange={(value) => onChangeFilter('project_info_sent', value)}
            allowClear
            style={{ width: 230 }}
          >
            <Select.Option key={0}>Not Sent</Select.Option>
            <Select.Option key={1}>Sent</Select.Option>
          </Select>


          <div style={{ width: 10 }} />
          

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Switch
              checked={filters.no_valuation_this_month}
              onChange={(value) => onChangeFilter('no_valuation_this_month', value)}
            />
            <div style={{ width: 5 }} />
            <span>No Val This Month</span>
          </div>

        </div>

      </React.Fragment>

    )
  };
}