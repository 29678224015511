import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Drawer, Input, Table, Popover, Timeline, Badge, Tag, Divider } from 'antd'
import { useDragLayer } from 'react-dnd'
import moment from 'moment'
import classNames from 'classnames'
import styles from './styles.css'

import SubcontractorDrag from '../SubcontractorDrag'
import ProjectDetails from '../ProjectDetails'
import TimesheetPostingSpan from '../TimesheetPostingSpan'

const getProjectDetails = project => (
  <React.Fragment>
    <ProjectDetails project={project} />
    {project.timesheet_postings.map(timesheetPosting => (
      <TimesheetPostingSpan
        key={timesheetPosting.id}
        timesheetPosting={timesheetPosting}
        nowColor={project.timeline}
      />
    ))}
  </React.Fragment>
)

const SubcontractorDrawer = props => {

  const {
    search,
    dateRange,
    periods,
    rows,
    fetching,
    componentDidMount,
    componentWillUnmount,
    onClose,
  } = props

  const inputRef = useRef(null)

  const [$search, setSearch] = useState(search)

  useEffect(() => {
    componentDidMount(props)
    return () => componentWillUnmount()
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef.current])

  const { isDragging } = useDragLayer(monitor => ({
    isDragging: !!monitor.isDragging(),
  }))

  const today = moment().format('YYYY-MM-DD')

  return (

    <Drawer
      title={(
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>{dateRange}</div>
          <Input.Search
            value={$search}
            onChange={e => setSearch(e.target.value)}
            ref={inputRef}
            style={{ width: 250 }}
          />
        </div>
      )}
      visible={!isDragging}
      width={620}
      height={320}
      closable={false}
      onClose={() => onClose()}
      bodyStyle={{ padding: 0 }}
    >

      <Table
        dataSource={$search ? rows.filter(row => row.name.toLowerCase().includes($search.toLowerCase())) : rows}
        rowKey='id'
        bordered
        size='small'
        loading={fetching}
        scroll={{ y: window.innerHeight - 95 }}
        pagination={false}
        className={styles.table}
        style={{ marginTop: '-1px' }}
      >

        <Table.Column
          key='subcontractor'
          title={`Subcontractors (${rows.length})`}
          render={(text, record) => (
            <SubcontractorDrag subcontractor={record}>
              <div style={{ cursor: 'pointer' }}>
                <Badge color={record.card_type} />
                {record.name}
                {!!record.is_supervisor && <Tag color='volcano' style={{ marginLeft: 8 }}>Supervisor</Tag>}
                {!!record.is_trainee && <Tag color='cyan' style={{ marginLeft: 8 }}>Trainee</Tag>}
              </div>
            </SubcontractorDrag>
          )}
        />

        {periods.map((period, index) => (

          <Table.Column
            key={period.date}
            title={period.day_of_week.substr(0, 1)}
            width={40}
            onHeaderCell={() => ({
              className: classNames(styles.header, {
                [styles.today]: period.date === today,
                [styles.weekend]: period.is_weekend && period.date !== today,
              }),
            })}
            onCell={record => ({
              style: { backgroundColor: (!record.is_multiple && record.periods[index].background) || '#dddddd', padding: 0 },
            })}
            render={(text, record) => {
              const { projects, slots, available, absence } = record.periods[index]
              const style = {
                width: 40,
                height: 40,
                lineHeight: '40px',
                textAlign: 'center',
                fontWeight: 500,
                color: '#ffffff',
              }
              if (record.is_multiple) {
                style.borderRadius = '50%'
                style.backgroundColor = record.periods[index].background
              }
              if (available && !absence && !projects.length) return (
                <div style={style}>0</div>
              )
              if ((!available || absence) && !projects.length) return (
                <Popover
                  title={record.name}
                  placement='left'
                  content={!available ? 'Unavailable' : absence}
                >
                  <div style={style}>{!available ? 'U' : 'A'}</div>
                </Popover>
              )
              return (
                <Popover
                  title={record.name}
                  placement='left'
                  content={record.is_multiple ? (
                    <React.Fragment>
                      {projects.map((project, i) => (
                        <React.Fragment key={project.id}>
                          <div className={styles.multiple}>
                            <Badge
                              count={project.slots}
                              size='small'
                              style={{ backgroundColor: project.timeline, fontSize: 9, fontWeight: 500 }}
                            />
                            {getProjectDetails(project)}
                          </div>
                          {i + 1 !== projects.length && <Divider style={{ margin: '12px 0' }} />}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ) : (
                    <Timeline>
                      {projects.map((project, i) => (
                        <Timeline.Item
                          key={project.id}
                          color={project.timeline}
                          style={{ padding: i + 1 === projects.length && 0 }}
                        >
                          {getProjectDetails(project)}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  )}
                >
                  <div style={style}>{slots}</div>
                </Popover>
              )
            }}
          />

        ))}

      </Table>

    </Drawer>

  )

}

SubcontractorDrawer.propTypes = {
  search: PropTypes.string,
  dateRange: PropTypes.string.isRequired,
  periods: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

SubcontractorDrawer.defaultProps = {
  search: '',
}

export default SubcontractorDrawer
