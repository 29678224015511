import fetch from '../Services/fetch'
import { setSetup } from '../State/setup'
import { resetFilters } from '../State/filters'
import { resetSearchTerm } from '../State/searchTerm'
import { resetSortBy } from '../State/sortBy'
import { resetData } from '../State/data'

export default (key, value) => async (dispatch) => {

  dispatch(resetData())

  if (key === 'model') {

    dispatch(setSetup({ model: value, field: undefined }))

    dispatch(resetFilters())

    dispatch(resetSearchTerm())

    dispatch(resetSortBy())

  } else if (key === 'field') {

    dispatch(setSetup({ field: value }))

    dispatch(resetSearchTerm())

    dispatch(resetSortBy())

    dispatch(fetch())

  }
  
}
