import { createAction, handleActions } from 'redux-actions'

const openSubInvoiceModal = createAction('SCREENS/SUB_PROJECT/SUB_INVOICES/OPEN_SUB_INVOICE_MODAL')
const closeSubInvoiceModal = createAction('SCREENS/SUB_PROJECT/SUB_INVOICES/CLOSE_SUB_INVOICE_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openSubInvoiceModal]: (state, { payload }) => ({
      ...payload,
      show: true,
    }),
    [closeSubInvoiceModal]: () => defaultState,
  },
  defaultState,
)

export { openSubInvoiceModal, closeSubInvoiceModal, reducer }
