import { connect } from 'react-redux';
import Component from './Component';

import selectOptionalsListData from '../../Selectors/selectOptionalsListData';

import onClickAddEstimateOptional from './Handlers/onClickAddEstimateOptional';
import onClickRemoveEstimateOptional from './Handlers/onClickRemoveEstimateOptional';
import onClickDuplicateEstimateOptional from './Handlers/onClickDuplicateEstimateOptional';
import onChangeEstimateOptionalField from './Handlers/onChangeEstimateOptionalField';
import onMoveEstimateOptional from './Handlers/onMoveEstimateOptional';

const mapStateToProps = (state) => ({
  estimate: state.screens.EstimateView.estimate,
  listData: selectOptionalsListData(state),
  serviceTypes: state.core.serviceTypes,
  editable: state.screens.EstimateView.editable
})

const actionCreators = {
  onClickAddEstimateOptional,
  onClickRemoveEstimateOptional,
  onClickDuplicateEstimateOptional,
  onChangeEstimateOptionalField,
  onMoveEstimateOptional
};

export default connect(mapStateToProps, actionCreators)(Component);