
import { createAction, handleActions } from 'redux-actions';

const setShowValuationHistoryModal = createAction("SCREENS/VALUATION_VIEW/SET_SHOW_VALUATION_HISTORY_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowValuationHistoryModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowValuationHistoryModal, reducer };