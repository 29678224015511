import { combineReducers } from 'redux'

import { reducer as subOrders } from './State/subOrders'
import { reducer as fetching } from './State/fetching'
import { reducer as search } from './State/search'
import { reducer as filters } from './State/filters'
import { reducer as sorting } from './State/sorting'
import { reducer as pagination } from './State/pagination'
import { reducer as subOrderModal } from './State/subOrderModal'

export default combineReducers({
  subOrders,
  fetching,
  search,
  filters,
  sorting,
  pagination,
  subOrderModal,
})
