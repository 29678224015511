import moment from 'moment';
import { omit } from 'lodash';
import { getOrderTotals } from '../../../HttpRequests/orders'
import { loadOrderTotals } from '../State/orderTotals';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const { searchTerm, filters } = getState().screens.Orders;

  try {

    const params = {};

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (filters.client_id.length > 0) {
      params['filter[client_id]'] = filters.client_id.join(',');
    }

    if (filters.extra_works !== 'all') {
      params['filter[extra_works]'] = filters.extra_works;
    }
    
    if (Array.isArray(filters.date_between) && filters.date_between.length === 2)  {
      params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join();
    }
    
    const response = await getOrderTotals({ params: omit(params, [ 'page[size]', 'page[number]', 'csort' ]) });
    
    dispatch(loadOrderTotals(response.data.data));

  } catch (error) {

    handleException(error);

  }
}