// @flow
import * as React from 'react';
import { Tabs } from 'antd';
import Screen from '../../Components/Screen';

import Initializing from './Components/Initializing';
import ClientModal from '../../Modals/Client';
import ClientContactModal from '../../Modals/ClientContact';
import ClientSiteModal from '../../Modals/ClientSite';
import ProjectEditorModal from '../../Modals/Project';

import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import ContactsTable from './Components/ContactsTable';
import SitesTable from './Components/SitesTable';
import ProjectTable from './Components/ProjectsTable';

type Props = {
  client: null|Object,
  clientContactForEdit: null|Object,
  clientSiteForEdit: null|Object,
  showClientModal: boolean,
  showClientContactModal: boolean,
  showClientSiteModal: boolean,
  projectEditorModal: Object,
  searchTerm: string,

  componentDidMount: (props: any) => void,
  componentWillUnmount: () => void,
  onClickEditClient: () => void,
  onChangeSearch: () => void,
  onChangeActiveTab: () => void,
  onCloseClientModal: () => void,
  didUpdateClient: () => void,
  didDeleteClient: () => void,
  onClickNewClientContact: () => void,
  onCloseClientContactModal: () => void,
  didCreateClientContact: () => void,
  didUpdateClientContact: () => void,
  didDeleteClientContact: () => void,
  onClickEditClientContact: () => void,
  onClickNewClientSite: () => void,
  onCloseClientSiteModal: () => void,
  didCreateClientSite: () => void,
  didUpdateClientSite: () => void,
  didDeleteClientSite: () => void,
  onClickEditClientSite: () => void,
  onClickNewProject: () => void,
  onClickProjectRow: () => void,
  onCloseProjectEditorModal: () => void,
}

export default class extends React.PureComponent<Props> {
  
  componentDidMount = () => this.props.componentDidMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render() {
    const {
      client,
      clientContactForEdit,
      clientSiteForEdit,
      showClientModal,
      showClientContactModal,
      showClientSiteModal,
      projectEditorModal,
      searchTerm,

      onClickEditClient,
      onChangeSearch,
      onChangeActiveTab,
      onCloseClientModal,
      didUpdateClient,
      didDeleteClient,
      onClickNewClientContact,
      onCloseClientContactModal,
      didCreateClientContact,
      didUpdateClientContact,
      didDeleteClientContact,
      onClickEditClientContact,
      onClickNewClientSite,
      onCloseClientSiteModal,
      didCreateClientSite,
      didUpdateClientSite,
      didDeleteClientSite,
      onClickEditClientSite,
      onClickNewProject,
      onClickProjectRow,
      onCloseProjectEditorModal
    } = this.props;

    const { TabPane } = Tabs;

    if (!client) return <Initializing />

    return (

      <Screen>
        
        <Breadcrumbs
          client={client}
        />

        <Header
          client={client}
          onClickEditClient={onClickEditClient}
        />

        <Tabs
          defaultActiveKey="CONTACTS"
          className="p-t-10"
          onChange={onChangeActiveTab}
          animated={false}
        >
          <TabPane tab="CONTACTS" key="CONTACTS">
            
            <ContactsTable
              client={client}
              searchTerm={searchTerm}
              onChangeSearch={onChangeSearch}
              onClickNewClientContact={onClickNewClientContact}
              onClickEditClientContact={onClickEditClientContact}
            />

          </TabPane>

          <TabPane tab="SITES" key="SITES">

            <SitesTable
              client={client}
              searchTerm={searchTerm}
              onChangeSearch={onChangeSearch}
              onClickNewClientSite={onClickNewClientSite}
              onClickEditClientSite={onClickEditClientSite}
            />

          </TabPane>

          <TabPane tab="PROJECTS" key="PROJECTS">
            
            <ProjectTable
              client={client}
              searchTerm={searchTerm}
              onChangeSearch={onChangeSearch}
              onClickNewProject={onClickNewProject}
              onClickProjectRow={onClickProjectRow}
            />

          </TabPane>

        </Tabs>

        {showClientModal && (
          <ClientModal
            onClose={onCloseClientModal}
            didUpdate={didUpdateClient}
            didDelete={didDeleteClient}
            client={client}
          />
        )}
        
        {showClientContactModal && (
          <ClientContactModal
            onClose={onCloseClientContactModal}
            didCreate={didCreateClientContact}
            didUpdate={didUpdateClientContact}
            didDelete={didDeleteClientContact}
            clientId={client.id}
            clientContact={clientContactForEdit}
          />
        )}

        {showClientSiteModal && (
          <ClientSiteModal
            onClose={onCloseClientSiteModal}
            didCreate={didCreateClientSite}
            didUpdate={didUpdateClientSite}
            didDelete={didDeleteClientSite}
            clientId={client.id}
            clientSite={clientSiteForEdit}
          />
        )}

        {projectEditorModal.show && (
          <ProjectEditorModal
            {...projectEditorModal}
            onClose={onCloseProjectEditorModal}
          />
        )}

      </Screen>

    )
  };
}