// @flow

import * as React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Popover, Input, Button } from 'antd';
import styles from './NotesPopover.css';

const { TextArea } = Input;

type Props = {
  notes: null|string,
  onChangeNotes: Function
}

type State = {
  visible: boolean
}

export default class extends React.PureComponent<Props, State> {

  state = {
    visible: false
  }

  onVisibleChange = (visible: boolean) => this.setState({ visible });

  onClickCancel = () => this.setState({ visible: false })

  onClickSave = async () => {
    
    const { onChangeNotes } = this.props;
    
    const { value } = this.TextArea.resizableTextArea.textArea

    await onChangeNotes(value);

    this.setState({ visible: false });
  }

  TextArea: any;

  render () {
    
    const { notes } = this.props;

    const Content = () => (

      <div style={{ width: 320 }}>

        <TextArea
          defaultValue={notes}
          ref={(node)=> { this.TextArea = node }}
          style={{ height: 100, marginBottom: 16 }}
        />
        
        <Button
          style={{ marginRight: 10}}
          type="primary"
          size="small"
          onClick={this.onClickSave}
        >
          Save
        </Button>

        <Button
          size="small"
          onClick={this.onClickCancel}
        >
          Cancel
        </Button>

      </div>

    )
    
    return (
      <Popover
        title="Notes"
        trigger="click"
        placement="left"
        visible={this.state.visible}
        onVisibleChange={visible => this.setState({ visible })}
        content={<Content />}            
        style={{ width: 320 }}
      >        
        
        <LegacyIcon
          type="message"
          theme={notes ? 'filled' : 'outlined'}
          style={{ color: notes ? '#40a9ff' : '' }}
          className={styles.notesButton}
        />
        
      </Popover>
    );
  };
}