// @flow

import { setEditable } from '../../../State/editable';
import fetchValuation from '../../../Services/fetchValuation';

export default () => async (dispatch: Function) => {
  
  dispatch(setEditable(false))

  dispatch(fetchValuation())

}