import { connect } from 'react-redux';
import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';
import onChangeTable from './Handlers/onChangeTable';
import onChangeSearch from './Handlers/onChangeSearch';
import onSubmitSearch from './Handlers/onSubmitSearch';
import onClickExport from './Handlers/onClickExport';
import onChangeProjectField from './Handlers/onChangeProjectField';
import onChangeFilter from './Handlers/onChangeFilter';
import onClickNewProject from './Handlers/onClickNewProject';

import onCloseProjectModal from './Handlers/onCloseProjectModal';
import didCreateProject from './Handlers/didCreateProject';

const mapStateToProps = (state) => ({
  projects: state.screens.Projects.projects,
  projectTotals: state.screens.Projects.projectTotals,
  searchTerm: state.screens.Projects.searchTerm,
  pagination: state.screens.Projects.pagination,
  fetching: state.screens.Projects.fetching,
  showProjectModal: state.screens.Projects.showProjectModal,
  projectPhases: state.core.projectPhases,
  projectStatuses: state.core.projectStatuses,
  clients: state.core.clients,
  clientContacts: state.core.clientContacts,
  users: state.core.users,
  subcontractors: state.core.subcontractors,
  filters: state.screens.Projects.filters,
});

const actionCreators = {
  componentDidMount,
  onChangeSearch,
  onChangeProjectField,
  onChangeTable,
  onChangeFilter,
  onClickNewProject,
  onSubmitSearch,
  onClickExport,
  onCloseProjectModal,
  didCreateProject
};

export default connect(mapStateToProps, actionCreators)(Component);