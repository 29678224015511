exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1dNQaeXEHVJQ0lDDqIKru6 tbody tr {\n  cursor: pointer;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Users/Components/Table.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;CACjB","file":"Table.css","sourcesContent":[".table tbody tr {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"table": "_1dNQaeXEHVJQ0lDDqIKru6"
};