import React from 'react'
import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'

const SubcontractorDrag = props => {

  const {
    jobs,
    subcontractor,
    children,
  } = props

  const [collected, drag, dragPreview] = useDrag(() => ({
    type: 'SUBCONTRACTOR',
    item: { jobs, subcontractor },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  }), [jobs])

  return collected.isDragging ? (

    <div ref={dragPreview}>{children}</div>

  ) : (

    <div ref={drag}>{children}</div>

  )

}

SubcontractorDrag.propTypes = {
  jobs: PropTypes.array,
  subcontractor: PropTypes.object,
  children: PropTypes.node.isRequired,
}

SubcontractorDrag.defaultProps = {
  jobs: [],
  subcontractor: null,
}

export default SubcontractorDrag
