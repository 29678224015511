import { createAction, handleActions } from 'redux-actions';

const loadValuation = createAction("SCREENS/VALUATION_VIEW/LOAD_VALUATION");
const clearValuation = createAction("SCREENS/VALUATION_VIEW/CLEAR_VALUATION");

const defaultState = null

const reducer = handleActions(
  {
    [loadValuation]: (state, action) => action.payload,
    [clearValuation]: () => null
  },
  defaultState
);

export { loadValuation, clearValuation, reducer };