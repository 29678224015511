import { message } from 'antd';
import { getProps } from '../props';

export default () => (dispatch, getState) => {

  const { uploading } = getState().modals.Upload;
  const { onClose } = getProps();

  if (!uploading) {

    onClose();
    
  } else {

    message.warning('Upload is currently in progress');

  }

}