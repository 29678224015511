// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { CheckCircleFilled, ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Table, Popover } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import classnames from 'classnames';
import { getTeamUrl } from 'Common';
import styles from './Table.css';

type Props = {
  orders: [],
  pagination: {},
  fetching: boolean,

  onChangeTable: () => void  
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      orders,
      pagination,
      fetching,

      onChangeTable
    } = this.props;

    const columns = [
      { 
        title: 'Reference', 
        dataIndex: '',
        key: 'reference',
        width: 155,
        sorter: true,
        render: (text, record) => (
          <NavLink to={getTeamUrl(`orders/${record.id}/view`)}>
            { record.reference}
          </NavLink>
        )
      },
      
      {
        title: 'Client / Site / Project', 
        dataIndex: '',
        key: 'client.name',
        width: 252,
        sorter: true,
        render: (text, record) => (

          <div>

            <div className="ellipsis">
              <strong>
                {record.project.client_site.client.name}
              </strong>
            </div>

            <div className="ellipsis">
              {record.project.client_site.name}
            </div>

            <div className="ellipsis">
              <strong>
                {record.project.id}
              </strong>
            </div>
            
          </div>

        )
      },

      { 
        title: ' ', 
        dataIndex: '',
        key: 'notes',
        width: 25,
        sorter: false,
        render: (text, record) => record.notes && (
          <Popover
            title="Notes"
            trigger="hover"
            content={(
              <div style={{ maxWidth: 300 }}>
                {record.notes}
              </div>
            )}
            style={{ maxWidth: 300 }}
          >
            <InfoCircleOutlined style={{ color: '#1890FF', fontSize: 18 }} />
          </Popover>
        ),
        onCell: () => ({ style: { padding: 0 } })
      },

      {
        title: 'Date', 
        dataIndex: '',
        key: 'date',
        width: 150,
        sorter: true,
        render: (text, record) => (
          <div>
            {record.date ? moment(record.date).format('DD MMM YYYY') : '--'}
          </div>
        )
      },

      {
        title: 'Total', 
        dataIndex: '',
        key: 'total',
        width: 150,
        sorter: true,
        render: (text, record) => (
          <div>
            {`£ ${numeral(record.total).format('0,0.00')}`}
          </div>
        )
      },

      {
        title: 'Target', 
        dataIndex: '',
        key: 'target_total',
        width: 150,
        sorter: true,
        render: (text, record) => (
          <div className={classnames({ [styles.offTarget]: record.total !== record.target_total })}>
            {`£ ${numeral(record.target_total).format('0,0.00')}`}
          </div>
        )
      },
      
      {
        title: 'New / Extra',
        key: 'extra_works',
        width: 140,
        sorter: true,
        render: (text, record) => (
          <div>
            <LegacyIcon type={record.extra_works ? 'plus' : 'star'} style={{ color: record.extra_works ? 'limegreen' : '#1990FF' }} />
            {record.extra_works ? ' Extra Works' : ' New Works'}
          </div>
        ),
      },

      {
        title: 'Status',
        dataIndex: '',
        key: 'order_file_storage_path',
        width: 150,
        sorter: true,
        render: (text, record) => (                  
          <React.Fragment>

            {record.order_file_storage_path ? (
              <React.Fragment>
              
                <CheckCircleFilled style={{ color: '#14be71', fontSize: 15, paddingRight: 5}} />
                {'PO Uploaded'}

              </React.Fragment>

            ): (
              <React.Fragment>

                <ExclamationCircleFilled style={{ color: '#e84754', fontSize: 15, paddingRight: 5}} />
                {'PO Missing'}

              </React.Fragment>
            )}


          </React.Fragment>
        )        
      }

    ];

    const scroll = { x: columns.reduce((total, column) => total + column.width, 150) };

    return (
      <Table 
        dataSource={orders}
        columns={columns} 
        rowKey={record => record.id}
        scroll={scroll}
        loading={fetching}
        onChange={onChangeTable}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50'],
          ...pagination,
          style: { float: 'none', textAlign: 'center' }
        }}
      />
    )
  };
}