import { createAction, handleActions } from 'redux-actions'

const setShowInvoiceModal = createAction("SCREENS/INVOICE_VIEW/SET_SHOW_INVOICE_MODAL")

const defaultState = false

const reducer = handleActions(
  {
    [setShowInvoiceModal]: (state, action) => action.payload,
  },
  defaultState
)

export { setShowInvoiceModal, reducer }