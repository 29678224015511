// @flow

import * as React from 'react';
import { Modal } from 'antd';
import Form from './Form';

type Props = {
  submitting: boolean,
  serviceTypes: [],
  componentDidMount: (props: any) => void,
  componentWillUnmount: (props: any) => void,
  onClickCancel: Function,
  onClickSubmit: Function
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount(this.props)

  componentWillUnmount = () => this.props.componentWillUnmount(this.props)

  render () {

    const {
      submitting,
      serviceTypes,
      onClickCancel,
      onClickSubmit
    } = this.props;
    
    return (

      <Modal
        title='Set Target Costs'
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText='Apply'
        width={620}
      >

        <Form serviceTypes={serviceTypes} />

      </Modal>
    )
  }
}