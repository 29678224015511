import { combineReducers } from 'redux';

import { reducer as project } from './State/project';
import { reducer as showProjectModal } from './State/showProjectModal';
import { reducer as showClientContactModal } from './State/showClientContactModal';

export default combineReducers({
  project,
  showProjectModal,
  showClientContactModal
})
