import { message } from 'antd'
import modalProps from '../modalProps'
import { deleteValuationDate } from '../../../HttpRequests/valuationDates'
import handleException from '../../../Core/Helpers/handleException'

export default () => async () => {

  const { valuationDate } = modalProps()

  try {

    await deleteValuationDate({ id: valuationDate.id })

    message.success('Schedule updated')

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
