import create from '../Services/create';
import update from '../Services/update';

import { getProps } from '../props';

export default async (values, dispatch) => {
    
  const { onClose, didCreate, didUpdate } = getProps();

  if (!values.id) {

    const clientSite = await dispatch(create(values));
    
    onClose();

    if (didCreate) didCreate(clientSite);

  } else {
    
    const clientSite = await dispatch(update(values));
    
    onClose();

    if (didUpdate) didUpdate(clientSite);
  }


}