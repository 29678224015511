import { combineReducers } from "redux"

import { reducer as fetching } from "./State/fetching"
import { reducer as invoices } from "./State/invoices"
import { reducer as totals } from "./State/totals"
import { reducer as pagination } from "./State/pagination"
import { reducer as sorting } from "./State/sorting"
import { reducer as filters } from "./State/filters"
import { reducer as searchTerm } from "./State/searchTerm"

export default combineReducers({
  fetching,
  invoices,
  totals,
  pagination,
  sorting,
  filters,
  searchTerm,
})
