
import { createAction, handleActions } from 'redux-actions';

const setShowEstimateDuplicateModal = createAction("SCREENS/ESTIMATE_VIEW/SET_SHOW_ESTIMATE_DUPLICATE_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowEstimateDuplicateModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowEstimateDuplicateModal, reducer };