import update from '../Services/update';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { onClose, didUpdate } = getProps();

  const orderItem = await dispatch(update(values));

  if (orderItem) {
    
    didUpdate(orderItem);

    onClose();

  }

};
