import { createAction, handleActions } from 'redux-actions'

const setSortBy = createAction('SCREENS/INSIGHTS/SET_SORT_BY')
const resetSortBy = createAction('SCREENS/INSIGHTS/RESET_SORT_BY')

const defaultState = null

const reducer = handleActions(
  {
    [setSortBy]: (state, action) => action.payload,
    [resetSortBy]: () => defaultState,
  },
  defaultState,
)

export { setSortBy, resetSortBy, reducer }
