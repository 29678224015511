import { createAction, handleActions } from 'redux-actions'

const setPagination = createAction('SCREENS/TIMESHEETS/SET_PAGINATION')

const defaultState = {
  pageNumber: 1,
  pageSize: 10,
  total: 0,
}

const reducer = handleActions(
  {
    [setPagination]: (state, action) => ({ ...state, ...action.payload }),
  },
  defaultState,
)

export { setPagination, reducer }
