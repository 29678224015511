import { connect } from 'react-redux';
import Component from './Component';

import onClickDownloadFile from './Handlers/onClickDownloadFile'
import onDropOrderFile from './Handlers/onDropOrderFile'
import onClickEditClientSite from './Handlers/onClickEditClientSite'
import onClickEditClientContact from './Handlers/onClickEditClientContact'
import onClickProject from './Handlers/onClickProject'
import onDropMedia from './Handlers/onDropMedia'
import onDeleteMedia from './Handlers/onDeleteMedia'

const mapStateToProps = (state) => ({
  order: state.screens.OrderView.order
})

const actionCreators = {
  onClickDownloadFile,
  onDropOrderFile,
  onClickEditClientSite,
  onClickEditClientContact,
  onClickProject,
  onDropMedia,
  onDeleteMedia,
};

export default connect(mapStateToProps, actionCreators)(Component);