import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'

import Form from './Form'
import AddToExistingModal from './Components/AddToExistingModal'

const SubOrder = props => {

  const {
    subcontractors,
    projects,
    users,
    subOrders,
    subOrder,
    projectId,
    orderItemIds,
    submitting,
    addToExistingModal,
    componentDidMount,
    onClickSubmit,
    onClickAddToExisting,
    onCloseAddToExistingModal,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
  }, [])

  const footer = []

  if (orderItemIds) footer.push(
    <Button key='existing' type='dashed' onClick={onClickAddToExisting} style={{ float: 'left' }}>Add to Existing</Button>,
  )

  footer.push([
    <Button key='cancel' onClick={() => onClose()}>Cancel</Button>,
    <Button key='submit' type='primary' loading={submitting} onClick={onClickSubmit}>{subOrder ? 'Update' : 'Create'}</Button>,
  ])

  return (
    
    <Modal
      title={subOrder ? 'Order' : 'New Order'}
      visible
      onCancel={() => onClose()}
      maskClosable={false}
      footer={footer}
    >

      <Form
        subcontractors={subcontractors}
        projects={projects}
        users={users}
      />

      {addToExistingModal.show && (
        <AddToExistingModal
          {...addToExistingModal}
          subcontractors={subcontractors}
          projects={projects}
          subOrders={subOrders}
          projectId={projectId}
          onClose={onCloseAddToExistingModal}
        />
      )}

    </Modal>

  )

}

SubOrder.propTypes = {
  subcontractors: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  subOrders: PropTypes.array.isRequired,
  subOrder: PropTypes.object,
  projectId: PropTypes.number,
  orderItemIds: PropTypes.array,
  submitting: PropTypes.bool.isRequired,
  addToExistingModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  onClickSubmit: PropTypes.func.isRequired,
  onClickAddToExisting: PropTypes.func.isRequired,
  onCloseAddToExistingModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

SubOrder.defaultProps = {
  subOrder: null,
  projectId: null,
  orderItemIds: null,
}

export default SubOrder
