import { getProps } from '../../../props'
import { getSubOrders } from '../../../../../HttpRequests/subOrders'
import { setSubOrders } from '../State/subOrders'
import { setFetching } from '../State/fetching'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { id: subcontractorId } = getProps().match.params

  const { search, sorting } = getState().screens.SubcontractorView.SubOrders

  const params = {
    search,
    'filter[subcontractor_id]': subcontractorId,
    sort: `${sorting.descending ? '-' : ''}${sorting.columnKey}`,
    include: [
      'project.client_site.client',
      'user',
    ].join(','),
  }

  dispatch(setFetching(true))

  try {

    const response = await getSubOrders({ params })

    dispatch(setSubOrders(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setFetching(false))

  }

}
