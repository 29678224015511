import { change } from 'redux-form';
import { FORM_NAME } from '../Form';
import promptForEstimateOptionals from '../Services/promptForEstimateOptionals';

export default (event, value) => (dispatch) => {

  dispatch(change(FORM_NAME, 'estimate_optional_ids', []))
  
  if (value) {
    dispatch(promptForEstimateOptionals(value))
  }
  
}