import { getProps } from '../props';
import { getEstimate } from '../../../HttpRequests/estimates';
import { loadEstimate } from '../State/estimate';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch) => {

  try {

    const { estimateId } = getProps();

    const params = {
      include: 'estimate_optionals'
    }

    const response = await getEstimate({ id: estimateId, params });

    dispatch(loadEstimate(response.data.data))

  } catch (error) {

    handleException(error);
    
  }

}