import { message } from 'antd'
import { invoiceRetentionPayment } from '../../../../../HttpRequests/retentionPayments'
import fetchProject from '../../../Services/fetchProject'
import handleException from '../../../../../Core/Helpers/handleException'

export default (id) => async (dispatch) => {

  try {

    await invoiceRetentionPayment({ id })

    await dispatch(fetchProject())

    message.success('Invoiced')
    
  } catch (error) {

    handleException(error)

  }
  
}