import updateNotes from '../Services/updateNotes'
import { closeNotesModal } from '../State/notesModal'

export default (notes) => async (dispatch, getState) => {

  const { id } = getState().screens.Subbies.SubValuations.notesModal

  if (notes !== undefined) {
    await dispatch(updateNotes(id, notes))
  }

  dispatch(closeNotesModal())

}
