import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Select } from 'antd'

const defaultSelectProps = {
  showArrow: true,
  showSearch: true,
  allowClear: true,
  notFoundContent: null,
  defaultActiveFirstOption: true,
  optionFilterProp: 'children',
  style: { width: 300 },
}

export default class extends React.PureComponent {

  static propTypes = {
    subcontractors: PropTypes.array.isRequired,
    projects: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
  }

  render() {

    const {
      subcontractors,
      projects,
      filters,
      onChangeFilter,
    } = this.props

    return (

      <div style={{ display: 'flex', marginBottom: 15 }}>

        <DatePicker.RangePicker
          value={filters.date_range}
          onChange={(value) => onChangeFilter('date_range', value)}
          format='DD/MM/YYYY'
        />

        <div style={{ width: 10 }} />

        <Select
          {...Object.assign({}, defaultSelectProps)}
          value={filters.subcontractor_id}
          onChange={(value) => onChangeFilter('subcontractor_id', value)}
          placeholder='Filter by Subcontractor'
        >
          {subcontractors.map(subcontractor => (
            <Select.Option key={subcontractor.id}>
              {`${subcontractor.name}`}
            </Select.Option>
          ))}
        </Select>

        <div style={{ width: 10 }} />

        <Select
          {...Object.assign({}, defaultSelectProps)}
          value={filters.project_id}
          onChange={(value) => onChangeFilter('project_id', value)}
          placeholder='Filter by Project'
        >
          {projects.map(project => (
            <Select.Option key={project.id}>
              {`[${project.id}] ${project.client_site.client.name} - ${project.client_site.name}`}
            </Select.Option>
          ))}
        </Select>

      </div>

    )

  }

}
