import React from 'react'
import PropTypes from 'prop-types'
import { Tabs as TabsProvider } from 'antd'

const Tabs = props => {

  const {
    activeTab,
    onChangeTab,
  } = props

  return (

    <TabsProvider activeKey={activeTab} onChange={onChangeTab} type='card'>
      <TabsProvider.TabPane key='ITEMS' tab='Items' />
    </TabsProvider>
    
  )

}

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onChangeTab: PropTypes.func.isRequired,
}

export default Tabs
