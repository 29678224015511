export default (values) => {

  const errors = {}

  if (!values.subcontractor_id) errors.subcontractor_id = 'Subcontractor is required'

  if (!values.date) errors.date = 'Date is required'

  if (!values.id) return errors

  if (!values.day_rate) {
    if (!values.manual_deduction) errors.manual_deduction = 'Deduction is required'
    if (!values.hourly_rate) errors.hourly_rate = 'Hourly rate is required'
  }

  if (values.day_rate) {
    if (!values.total_cost) errors.total_cost = 'Day rate is required'
  }

  return errors
  
}
