import React from 'react';
import { sortBy, range, padStart } from 'lodash';
import { Field } from 'redux-form';
import { SelectField, DatePickerField, InputField, InputNumberField, CheckboxField, TextAreaField } from 'FormFields';
import moment from 'moment'
import { isOffice } from 'Common'
import IconButton from '../../../Components/IconButton';

const labelColSpan = 7;
const wrapperColSpan = 16;

type Props = {
  project: ?any,
  planner: ?any,
  clients: [],
  users: [],
  subcontractors: [],
  projectPhases: [],
  clientSites: [],
  clientContacts: [],
  showNewClientSiteButton: boolean,
  showNewClientContactButton: boolean,
  submitting: boolean,
  onChangeClient: () => void,
  onClickNewClient: () => void,
  onClickNewClientSite: () => void,
  onClickNewClientContact: () => void
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      project,
      planner,
      clients,
      users,
      subcontractors,
      projectPhases,
      clientSites,
      clientContacts,
      showNewClientContactButton,
      showNewClientSiteButton,
      submitting,
      onChangeClient,
      onClickNewClient,
      onClickNewClientSite,
      onClickNewClientContact
    } = this.props;

    const clientOptions = clients.map(item => ({
      value: item.id,
      label: `${item.name}`
    }));

    const clientSiteOptions = clientSites.map(item => ({
      value: item.id,
      label: `${item.name}`
    }));

    const clientContactOptions = [{ value: null, label: 'TBC' }].concat(sortBy(clientContacts.map(item => ({
      value: item.id,
      label: `${item.first_name || ''} ${item.last_name || ''}`
    })), ['label']));

    const userOptions = users.map(item => ({
      value: item.id,
      label: `${item.first_name} ${item.last_name}`
    }));

    const subcontractorOptions = subcontractors.filter(item => !!project || !item.deactivated_at).map(item => ({
      value: item.id,
      label: item.name
    }));

    const projectPhaseOptions = projectPhases.map(item => ({
      value: item.key,
      label: item.title
    }));

    const NewClientButton = () => (
      <IconButton
        type="plus-circle"
        tooltip="New Client"
        onClick={onClickNewClient}
        style={{ marginBottom: -4 }}
      />
    );

    const NewClientSiteButton = () => (
      <IconButton
        type="plus-circle"
        tooltip="New Site"
        onClick={onClickNewClientSite}
        style={{ marginBottom: -4 }}
      />
    );

    const NewClientContactButton = () => (
      <IconButton
        type="plus-circle"
        tooltip="New Contact"
        onClick={onClickNewClientContact}
        style={{ marginBottom: -4 }}
      />
    );

    return (

      <div>

        <Field
          component={InputField}
          name="title"
          label="Project Title"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
        />

        {!project && (

          <React.Fragment>
          
            <Field
              required
              component={SelectField}
              name="client_id"
              label="Client"
              disabled={submitting}
              options={clientOptions}
              onChange={onChangeClient}
              renderAfter={() => <NewClientButton />}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />
                
            <Field
              required
              component={SelectField}
              name="client_site_id"
              label="Site"
              disabled={submitting}
              options={clientSiteOptions}
              renderAfter={() => showNewClientSiteButton && <NewClientSiteButton />}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />

          </React.Fragment>
        )}
            
        <Field
          component={SelectField}
          name="client_contact_id"
          label="Contact"
          disabled={submitting}
          options={clientContactOptions}
          renderAfter={() => showNewClientContactButton && <NewClientContactButton />}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />
            
        <Field
          component={DatePickerField}
          name="start_date"
          label="Start Date"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
        />
            
        <Field
          component={DatePickerField}
          name="handover_date"
          label="Handover Date"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
        />
        
        <Field
          component={SelectField}
          name="contracts_manager_id"
          label="Contracts Manager"
          disabled={submitting}
          options={userOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

        <Field
          component={SelectField}
          name="other_contracts_manager_id"
          label="Other Manager"
          disabled={submitting}
          options={userOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

        {isOffice() && (

          <Field
            component={SelectField}
            name="supervisor_id"
            label="Supervisor"
            disabled={submitting}
            options={subcontractorOptions}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            fieldCol={{ span: 22 }}
            afterCol={{ span: 2 }}
          />

        )}

        <Field
          component={SelectField}
          name="billing_type"
          label="Billing Type"
          disabled={submitting}
          options={[
            { value: "Application", label: "Application" },
            { value: "Client Valuation", label: "Client Valuation" },
            { value: "Invoice", label: "Invoice" },
            { value: "Unknown", label: "Unknown" },
          ]}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

        <Field
          component={InputNumberField}
          name="uplift_percentage"
          label="Uplift %"
          disabled={submitting}
          min={0}
          max={100}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />
            
        <Field
          component={InputField}
          name="uplift_notes"
          label="Uplift Notes"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
        />

        <Field
          component={SelectField}
          name="valuation_day"
          label="Valuation Date"
          disabled={submitting}
          options={[{
            value: null,
            label: 'N/A',
          }].concat(range(1,32).map(n => ({
            value: n,
            label: moment(`2020-01-${padStart(String(n), 2, '0')}`).format('Do'),
          })))}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

        {!!project && (

          <Field
            component={SelectField}
            name="phase_key"
            label="Phase"
            disabled={submitting}
            options={projectPhaseOptions}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            fieldCol={{ span: 22 }}
            afterCol={{ span: 2 }}
          />

        )}

        {!!planner && (

          <React.Fragment>

            <Field
              component={CheckboxField}
              name="induction_required"
              label="Flags"
              text="Induction Required"
              disabled={submitting}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
            />

            <Field
              component={CheckboxField}
              name="weekly_returns"
              label=" "
              text="Weekly Returns"
              disabled={submitting}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              colon={false}
            />

            <Field
              component={CheckboxField}
              name="terms_and_conditions"
              label=" "
              text="Terms & Conditions"
              disabled={submitting}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              colon={false}
            />

            <Field
              component={TextAreaField}
              name="planner_notes"
              label="Planner Notes"
              disabled={submitting}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
            />

          </React.Fragment>

        )}

      </div>

    )
  }
}