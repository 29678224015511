import { getProps } from '../props';
import { getEmail } from '../../../HttpRequests/emails';
import { loadEmail } from '../State/email';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch) => {

  try {

    const { emailId } = getProps();

    const params = {
      include: 'sent_by_user'
    };

    const response = await getEmail({ id: emailId, params });

    const email = response.data.data;

    dispatch(loadEmail(email));
    
  } catch (error) {

    handleException(error);

  }

}