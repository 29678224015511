import { combineReducers } from 'redux'

import { reducer as subProjects } from './State/subProjects'
import { reducer as fetching } from './State/fetching'
import { reducer as search } from './State/search'
import { reducer as sortBy } from './State/sortBy'

export default combineReducers({
  subProjects,
  fetching,
  search,
  sortBy,
})
