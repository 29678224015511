import { createAction, handleActions } from 'redux-actions'

const startFetching = createAction('SCREENS/SUB_PROJECTS/START_FETCHING')
const finishFetching = createAction('SCREENS/SUB_PROJECTS/FINISH_FETCHING')

const defaultState = false

const reducer = handleActions(
  {
    [startFetching]: () => true,
    [finishFetching]: () => false,
  },
  defaultState,
)

export { startFetching, finishFetching, reducer }
