import { closeValuationDateModal } from '../State/valuationDateModal'
import fetch from '../Services/fetchProject'

export default (reload = false) => (dispatch) => {

  dispatch(closeValuationDateModal())

  if (reload) {
    dispatch(fetch())
  }

}
