// @flow

import { Modal } from 'antd'
import { push } from 'connected-react-router'
import { getTeamUrl } from 'Common'
import { setShowValuationModal } from '../../../State/showValuationModal';
import reviseValuation from '../../../Services/reviseValuation';
import refreshValuation from '../../../Services/refreshValuation';
import downloadPDF from '../../../Services/downloadPDF';
import downloadExcel from '../../../Services/downloadExcel';
import uninvoiceValuation from '../../../Services/uninvoiceValuation';
import { setShowEmailModal } from '../../../State/showEmailModal';
import { setShowValuationHistoryModal } from '../../../State/showValuationHistoryModal';
import { setShowCreateInvoiceModal } from '../../../State/showCreateInvoiceModal';
import onCreateApplication from './onCreateApplication';
import onDelete from './onDelete';
import onDeleteFile from './onDeleteFile';

export default (key: string) => async (dispatch: Function, getState: Function) => {

  switch (key) {

    default:
      break;

    case 'SEND':
      dispatch(setShowEmailModal(true));
      break;

    case 'CLIENT_PDF':
      dispatch(downloadPDF({ version: 'client' }));
      break;

    case 'INTERNAL_PDF':
      dispatch(downloadPDF({ version: 'internal' }));
      break;
    
    case 'EXCEL':
      dispatch(downloadExcel());
      break;

    case 'HISTORY':
      dispatch(setShowValuationHistoryModal(true));
      break;

    case 'EDIT':
      dispatch(setShowValuationModal(true));
      break;
    
    case 'REVISE':
      dispatch(reviseValuation());
      break;
    
    case 'REFRESH':
      dispatch(refreshValuation());
      break;
    
    case 'CREATE_INVOICE':
      dispatch(setShowCreateInvoiceModal(true))
      break;
    
    case 'CREATE_APPLICATION':
      dispatch(onCreateApplication())
      break;
    
    case 'VIEW_INVOICE': {
      const invoices = getState().screens.ValuationView.valuation.active_invoices;
      if (invoices.length > 0) dispatch(push(getTeamUrl(`invoices/${invoices[0].id}/view`)))
      break;
    }
    
    case 'VIEW_APPLICATION': {
      const invoices = getState().screens.ValuationView.valuation.active_invoices;
      if (invoices.length > 0) dispatch(push(getTeamUrl(`invoices/${invoices[0].id}/view`)))
      break;
    }
    
    case 'UNDO_INVOICE':

      Modal.confirm({
        title: `Undo Invoice`,
        content: 'Are you sure want to undo the invoice for this valuation?',
        okText: 'Yes, Undo it',
        width: 550,
        onOk: () => dispatch(uninvoiceValuation())
      })

      break
    
    case 'UNDO_APPLICATION':
      
      Modal.confirm({
        title: `Undo Application`,
        content: 'Are you sure want to undo the application for this valuation?',
        okText: 'Yes, Undo it',
        width: 550,
        onOk: () => dispatch(uninvoiceValuation())
      })

      break
    
    case 'DELETE':
      dispatch(onDelete());
      break;   

    case 'DELETE_FILE':
      dispatch(onDeleteFile());
      break;

  }

}