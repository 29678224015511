import { createAction, handleActions } from 'redux-actions';

const loadOrder = createAction("SCREENS/ORDER_VIEW/LOAD_ORDER");
const clearOrder = createAction("SCREENS/ORDER_VIEW/CLEAR_ORDER");

const defaultState = null

const reducer = handleActions(
  {
    [loadOrder]: (state, action) => action.payload,
    [clearOrder]: () => null
  },
  defaultState
);

export { loadOrder, clearOrder, reducer };