import { createAction, handleActions } from 'redux-actions'

const setSaving = createAction('SCREENS/SUB_INVOICE/SET_SAVING')
const resetSaving = createAction('SCREENS/SUB_INVOICE/RESET_SAVING')

const defaultState = false

const reducer = handleActions(
  {
    [setSaving]: (state, { payload }) => payload,
    [resetSaving]: () => defaultState,
  },
  defaultState,
)

export { setSaving, resetSaving, reducer }
