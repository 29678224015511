import axios from './axios';

export const getDirectory = ({ params } = {}) => axios.get(`/files/projects/get_directory`, { params });
export const allFiles = ({ id } = {}) => axios.get(`/files/projects/${id}/all_files`);
export const getFileUri = ({ params } = {}) => axios.get(`/files/projects/get_file_uri`, { params });
export const createDirectory = ({ data } = {}) => axios.post(`/files/projects/create_directory`, data);
export const uploadFile = ({ data, config } = {}) => axios.post(`/files/projects/upload_file`, data, config);
export const renameDirectory = ({ data } = {}) => axios.put(`/files/projects/rename_directory`, data);
export const renameFile = ({ data } = {}) => axios.put(`/files/projects/rename_file`, data);
export const deleteDirectory = ({ params } = {}) => axios.delete(`/files/projects/delete_directory`, { params });
export const deleteProjectFiles = ({ data } = {}) => axios.post(`/files/delete_project_files`, data);
export const importEstimateItems = ({ id, data } = {}) => axios.post(`/files/estimates/${id}/import_estimate_items`, data);
export const importProfitAndLoss = ({ data } = {}) => axios.post(`/files/projects/import_profit_and_loss`, data);
export const importClientOutstanding = ({ data } = {}) => axios.post(`/files/clients/import_client_outstanding`, data);