import { connect } from 'react-redux';
import Component from './Component';

import selectOrderTotals from './Selectors/selectOrderTotals';

import onClickEdit from './Handlers/onClickEdit';
import onClickCancel from './Handlers/onClickCancel';
import onClickSave from './Handlers/onClickSave';
import onClickDeleteItems from './Handlers/onClickDeleteItems';
import onClickSubOrder from './Handlers/onClickSubOrder';

const mapStateToProps = (state) => ({
  orderTotals: selectOrderTotals(state),
  order: state.screens.OrderView.order,
  editable: state.screens.OrderView.editable,
  selectedOrderItemIds: state.screens.OrderView.selectedOrderItemIds,
})

const actionCreators = {
  onClickEdit,
  onClickCancel,
  onClickSave,
  onClickDeleteItems,
  onClickSubOrder,
};

export default connect(mapStateToProps, actionCreators)(Component);