import fetchOrders from '../Services/fetchOrders';
import fetchOrderTotals from '../Services/fetchOrderTotals';
import fetchUsers from '../../../Core/Services/fetchUsers';
import fetchProjects from '../../../Core/Services/fetchProjects';
import fetchClients from '../../../Core/Services/fetchClients';

export default () => async (dispatch) => {

  await dispatch(fetchOrders());

  dispatch(fetchOrderTotals());
  
  dispatch(fetchUsers());
  dispatch(fetchProjects());
  dispatch(fetchClients());
  
}