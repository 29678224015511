import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'

const CellInfo = ({ content, fontSize }) => (

  <Alert
    type='info'
    banner
    message={content}
    style={{ width: '100%', fontSize }}
  />
  
)

CellInfo.propTypes = {
  content: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
}

CellInfo.defaultProps = {
  fontSize: 13,
}

export default CellInfo
