import { setSorting } from '../State/sorting';
import { setPagination } from '../State/pagination';
import fetchProjects from '../Services/fetchProjects';

export default (pagination, filters, sorting) => async (dispatch) => {

  window.scrollTo(0, 0);

  dispatch(setPagination(pagination));
  
  dispatch(setSorting(sorting));

  await dispatch(fetchProjects());

}