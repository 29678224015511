// @flow

import { setEditable } from '../../../State/editable';
import { setSelectedEstimateItemIds } from '../../../State/selectedEstimateItemIds';
import fetchEstimate from '../../../Services/fetchEstimate';

export default () => async (dispatch: Function) => {
  
  dispatch(setEditable(false))
  dispatch(setSelectedEstimateItemIds([]))

  dispatch(fetchEstimate())

}