import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

type Props = {
  onUpload: () => void,
  onChange: () => void
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      onUpload,
      onChange
    } = this.props;

    const uploadprops = {
      name: 'file',
      multiple: true,
      customRequest: onUpload,
      onChange
    }

    return (
      <div>

        <Upload.Dragger {...uploadprops}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">Select files to be uploaded</p>
        </Upload.Dragger>

      </div>
    );
  }
}