import { change, untouch } from 'redux-form';
import { FORM_NAME } from '../Form';
import fetchClient from '../Services/fetchClient';
import { clearClient } from '../State/client';

export default (event, value) => (dispatch) => {

  dispatch(change(FORM_NAME, 'client_site_id', null))
  dispatch(change(FORM_NAME, 'client_contact_id', null))
  dispatch(untouch(FORM_NAME, 'client_site_id'));
  dispatch(untouch(FORM_NAME, 'client_contact_id'));

  if (value) {
    // delay fetch, so that state can update with new project_id 
    setTimeout(() => dispatch(fetchClient()));
  } else {
    dispatch(clearClient());
  }
  
}