import { createAction, handleActions } from 'redux-actions';

const setShowCaveatModal = createAction("SCREENS/CAVEATS/SET_SHOW_CAVEAT_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowCaveatModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowCaveatModal, reducer };