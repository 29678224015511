import { setPagination } from '../State/pagination';
import fetchValuations from '../Services/fetchValuations';
import fetchValuationTotals from '../Services/fetchValuationTotals';

export default () => async (dispatch) => {

  dispatch(setPagination({ current: 1 }));

  dispatch(fetchValuations());

  dispatch(fetchValuationTotals());
  
}