import { resetSubOrder } from '../State/subOrder'
import { resetOriginalItems } from '../State/originalItems'
import { resetEditing } from '../State/editing'
import { resetSelectedItems } from '../State/selectedItems'

export default () => (dispatch) => {

  dispatch(resetSubOrder())
  dispatch(resetOriginalItems())
  dispatch(resetEditing())
  dispatch(resetSelectedItems())

}
