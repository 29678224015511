import fetch from '../Services/fetch'
import { setSortBy } from '../State/sortBy'

export default (sortBy) => async (dispatch) => {

  dispatch(setSortBy(sortBy))

  dispatch(fetch())
  
}
