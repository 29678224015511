import { closeSubValuationModal } from '../State/subValuationModal'
import fetch from '../Services/fetch'

export default (subValuation) => (dispatch) => {

  dispatch(closeSubValuationModal())

  if (subValuation) {
    dispatch(fetch())
  }

}
