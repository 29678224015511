import { clearClient } from '../State/client';
import { resetActiveTab } from '../State/activeTab';
import { clearClientContactForEdit } from '../State/clientConactForEdit';
import { clearClientSiteForEdit } from '../State/clientSiteForEdit';
import { clearSearchTerm } from '../State/searchTerm';

export default () => async (dispatch) => {

  dispatch(clearClient());
  dispatch(resetActiveTab());
  dispatch(clearClientContactForEdit());
  dispatch(clearClientSiteForEdit());
  dispatch(clearSearchTerm());

}