import { getEstimatesByClientReport } from '../../../HttpRequests/dashboard'
import { loadData } from '../State/estimatesByClientReport'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getSate) => {

  const { type, dateRange } = getSate().screens.Dashboard.estimatesByClientReport

  const params = {
    'filter[type]': type,
    'filter[date_range]': dateRange,
  }

  try {

    const response = await getEstimatesByClientReport({ params })

    dispatch(loadData(response.data))

  } catch(error) {

    handleException(error)

  }

}
