import moment from 'moment';
import { getSiteVisitStatuses } from '../../HttpRequests/siteVisitStatuses';
import { loadSiteVisitStatuses } from '../State/siteVisitStatuses';

let lastGetTimestamp = null;

const cacheTTL = 60000;

export default ({ force } = {}) => async (dispatch) => {

  try {

    if (!force && lastGetTimestamp) {

      const now = Number(moment().format('x'));

      if (now < (lastGetTimestamp + cacheTTL)) return;
    }
      
    lastGetTimestamp = Number(moment().format('x'));

    const response = await getSiteVisitStatuses();

    dispatch(loadSiteVisitStatuses(response.data.data));

  } catch (error) {
    
    throw error;

  }
}