import { find } from 'lodash'
import { updateJob } from '../State/jobs'

export default (key, field, value) => (dispatch, getState) => {

  const data = { [field]: value }

  if (field === 'subcontractor_id') {

    const { subcontractors } = getState().screens.Planner.ProjectCellModal

    const subcontractor = find(subcontractors, row => row.id === value)

    data.subcontractor = subcontractor

    if (!subcontractor.is_multiple) data.quantity = 1

  }
  
  dispatch(updateJob({ key, data }))
  
}
