import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'

const SubOrderItemNotes = props => {

  const {
    subOrderItem,
    editing,
    onUpdateField,
  } = props

  const [notes, setNotes] = useState(undefined)

  return (

    <div style={{ minWidth: 320, maxWidth: 320 }}>

      {editing ? (

        <Input.TextArea
          value={notes !== undefined ? notes : subOrderItem.notes}
          onChange={e => setNotes(e.target.value)}
          onBlur={e => {
            onUpdateField(subOrderItem.guid, 'notes', e.target.value)
            setNotes(undefined)
          }}
        />

      ) : subOrderItem.notes}

    </div>

  )

}

SubOrderItemNotes.propTypes = {
  subOrderItem: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  onUpdateField: PropTypes.func.isRequired,
}

export default SubOrderItemNotes
