import { openTimesheetModal } from '../State/timesheetModal'
import fetchTimesheets from '../Services/fetchTimesheets'

export default (timesheet) => (dispatch) => {

  dispatch(openTimesheetModal({ id: timesheet.id }))

  dispatch(fetchTimesheets())

}
