// @flow
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Table, Row, Col, Button, Input} from 'antd';

type Props = {
  client: Object,
  searchTerm: string,

  onChangeSearch: Function,
  onClickNewClientContact: Function,
  onClickEditClientContact: Function
  
}

export default class extends React.PureComponent<Props> {

  render() {
    const {
      client,
      searchTerm,
      onChangeSearch,
      onClickNewClientContact,
      onClickEditClientContact
    } = this.props;

    const columns = [
      { 
        title: 'Name', 
        dataIndex: '',
        render: (text, record) => (
          <NavLink to="#" onClick={() => onClickEditClientContact(record)}>
            { record.first_name+' '.concat(record.last_name || '') }
          </NavLink>
        )
      }, {
        title: 'Job Title', 
        dataIndex: 'relationship'
      }, {
        title: 'Telephone', 
        dataIndex: 'telephone'
      }, {
        title: 'Email', 
        dataIndex: 'email'
      }
    ];

    return (
      <React.Fragment>

        <Row>

          <Col span={12}>

            <Input.Search
              style={{ width: 250 }}
              placeholder="Search"
              onChange={onChangeSearch}
              value={searchTerm}
            />

          </Col>

          <Col span={12} className="text-right">

            <Button type="primary" onClick={onClickNewClientContact}>
              New Contact
            </Button>

          </Col>

        </Row>

        <Row>

          <Col span={24} style={{ marginTop: 15 }}>

            <Table dataSource={client.client_contacts} columns={columns} rowKey={record => record.id} />

          </Col>

        </Row>
  
      </React.Fragment>

      

    )
  };
}