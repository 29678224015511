import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { DatePickerField, TextAreaField } from 'FormFields'

const labelColSpan = 6
const wrapperColSpan = 16

export default class _Fields_ extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool,
  }

  static defaultProps = {
    submitting: null,
  }

  render () {

    const {
      submitting
    } = this.props

    return (
      
      <React.Fragment>

        <Field
          name="due_date"
          label="Due Date"
          disabled={submitting}
          component={DatePickerField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
        />

        <Field
          name="notes"
          label="Notes"
          disabled={submitting}
          component={TextAreaField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

      </React.Fragment>

    )
  }
}