import { change, untouch } from 'redux-form';
import { last } from 'lodash';
import { FORM_NAME } from '../Form';
import fetchProject from '../Services/fetchProject';
import { clearProject } from '../State/project';

export default (event, value) => (dispatch, getState) => {

  dispatch(change(FORM_NAME, 'client_contact_id', null))
  dispatch(untouch(FORM_NAME, 'client_contact_id'));

  if (value) {
    
    // delay fetch, so that state can update with new project_id 

    setTimeout(async () => {
      
      await dispatch(fetchProject())

      const { project } = getState().modals.Valuation;
      const clientContactId = project && project.valuations.length > 0 ? last(project.valuations).client_contact_id : null;
      dispatch(change(FORM_NAME, 'client_contact_id', clientContactId))

    });

  } else {

    dispatch(clearProject());

  }
  
}