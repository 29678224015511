import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Input, Button, Table } from 'antd'
import numeral from 'numeral'
import moment from 'moment'
import { getTeamUrl } from 'Common'
import styles from './styles.css'

import SubOrderModal from '../../../../Modals/SubOrder'

const SubOrders = props => {

  const {
    subOrders,
    fetching,
    search,
    subOrderModal,
    componentDidMount,
    onChangeSearch,
    onSearch,
    onClickNew,
    onChangeTable,
    onCloseSubOrderModal,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [])

  return (
    
    <React.Fragment>

      <div className={styles.header}>

        <div className={styles.headerLeft}>

          <Input.Search
            value={search}
            onChange={e => onChangeSearch(e.target.value)}
            onSearch={onSearch}
            placeholder='Search...'
            style={{ width: 250 }}
          />

        </div>

        <Button type='primary' onClick={onClickNew}>New Order</Button>

      </div>

      <Table
        dataSource={subOrders}
        rowKey='id'
        onChange={onChangeTable}
        loading={fetching}
      >

        <Table.Column
          title='Reference'
          key='reference'
          sorter
          render={(text, record) => (
            <NavLink to={getTeamUrl(`sub_orders/${record.id}`)}>
              <div style={{ fontSize: 15 }}>{record.reference}</div>
            </NavLink>
          )}
        />

        <Table.Column
          title='Project'
          key='project_id'
          sorter
          render={(text, record) => (
            <React.Fragment>
              <div>
                <span style={{ fontSize: 15, fontWeight: 600 }}>{record.project.id}</span>
                <span style={{ fontSize: 15, fontWeight: 400 }}>{` ${record.project.client_site.client.name}`}</span>
              </div>
              <div style={{ color: '#999999' }}>{record.project.client_site.name}</div>
            </React.Fragment>
          )}
        />

        <Table.Column
          title='Date'
          key='date'
          sorter
          render={(text, record) => moment(record.date).format('Do MMM YYYY')}
        />

        <Table.Column
          title='Owner'
          key='user.name'
          sorter
          render={(text, record) => record.user.full_name}
        />

        <Table.Column
          title='Total'
          key='total'
          sorter
          onHeaderCell={() => ({
            style: { textAlign: 'right' },
          })}
          onCell={() => ({
            style: { textAlign: 'right' },
          })}
          render={(text, record) => `£${numeral(record.total).format('0,0.00')}`}
        />

      </Table>

      {subOrderModal.show && (
        <SubOrderModal
          {...subOrderModal}
          onClose={onCloseSubOrderModal}
        />
      )}

    </React.Fragment>

  )

}

SubOrders.propTypes = {
  subOrders: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  search: PropTypes.string.isRequired,
  subOrderModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClickNew: PropTypes.func.isRequired,
  onChangeTable: PropTypes.func.isRequired,
  onCloseSubOrderModal: PropTypes.func.isRequired,
}

export default SubOrders
