// @flow
import * as React from 'react';
import { Modal, Spin } from 'antd';
import Form from './Form';

type Props = {
  initializing: boolean,
  submitting: boolean,
  emailOptions: [],
  selectedEmailToOption: [],
  selectedEmailCcOption: [],
  selectedEmailBccOption: [],
  projectFiles: [],
  fileList: [],
  componentWillMount: (props: any) => void,
  componentWillUnmount: () => void,
  onClickCancel: () => void,
  onClickSubmit: () => void,
  onSearchEmailOptions: Function,
  onSelectEmailOption: Function,
  onAddFile: Function,
  onRemoveFile: Function,
  onRemoveProjectFile: Function,
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      initializing,
      submitting,
      emailOptions,
      selectedEmailToOption,
      selectedEmailCcOption,
      selectedEmailBccOption,
      projectFiles,
      fileList,
      onClickCancel,
      onClickSubmit,
      onSearchEmailOptions,
      onSelectEmailOption,
      onAddFile,
      onRemoveFile,
      onRemoveProjectFile,
    } = this.props;

    return (
        
      <Modal
        title="Email Project Info"
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText={`${submitting ? 'Sending Email' : 'Send Email'}`}
        width={650}
      >
        
        <Spin spinning={initializing}>
        
          <Form
            emailOptions={emailOptions}
            selectedEmailToOption={selectedEmailToOption}
            selectedEmailCcOption={selectedEmailCcOption}
            selectedEmailBccOption={selectedEmailBccOption}
            projectFiles={projectFiles}
            fileList={fileList}
            onSearchEmailOptions={onSearchEmailOptions}
            onSelectEmailOption={onSelectEmailOption}
            onAddFile={onAddFile}
            onRemoveFile={onRemoveFile}
            onRemoveProjectFile={onRemoveProjectFile}
          />

        </Spin>

      </Modal>
    )
  }
}