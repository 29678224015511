// @flow

import * as React from 'react';
import { FolderFilled } from '@ant-design/icons';
import { Menu } from 'antd';
import styles from './Menu.css';

const transformDirectory = name => {
  const map = {
    'Method Statement': 'Health & Safety',
    'Risk Assessment': 'Quality Control',
    'Planswift Take Off': 'Grayscales',
  }
  return map[name] || name
}

type Props = {
  rootDirectories: [],
  currentDirectoryPath: null|string,
  onSelectRootDirectory: (path: string) => void,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      rootDirectories,
      currentDirectoryPath,
      onSelectRootDirectory
    } = this.props;

    const selectedKeys = currentDirectoryPath && (
      rootDirectories
        .filter(directory => currentDirectoryPath.startsWith(directory.path))
        .map(directory => directory.path)
    )

    return (
      <div className={styles.wrapper}>

        <Menu
          selectedKeys={selectedKeys}
          style={{ paddingTop: 30, paddingBottom: 30, minHeight: '100%' }}
          onSelect={(params) => onSelectRootDirectory(params.key)}
        >

          {rootDirectories.map(directory => (
            <Menu.Item key={directory.path} className={styles.menuItem}>
              <FolderFilled className={styles.folderIcon} />
              {` ${transformDirectory(directory.name)}`}
            </Menu.Item>
          ))}

        </Menu>

      </div>
    );
  }
}