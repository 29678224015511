import { createAction, handleActions } from 'redux-actions';
import { assign } from 'lodash';

const loadData = createAction('SCREENS/DASHBOARD/LAST_THREE_MONTHS_REPORT/LOAD_DATA');
const clearData = createAction('SCREENS/DASHBOARD/LAST_THREE_MONTHS_REPORT/CLEAR_DATA');
const setClientId = createAction('SCREENS/DASHBOARD/LAST_THREE_MONTHS_REPORT/SET_CUSTOMER_ID');

const defaultState = {
  data: null,
  clientId: undefined
}

const reducer = handleActions(
  {
    [loadData]: (state, action) => assign({}, state, { data: action.payload }),
    [clearData]: () => assign({}, defaultState),
    [setClientId]: (state, action) => assign({}, state, { clientId: action.payload })
  },
  defaultState
);

export { loadData, clearData, setClientId, reducer };
