import { createAction, handleActions } from 'redux-actions'

const setSelectedOrderIds = createAction('SCREENS/PROJECT_VIEW/SET_SELECTED_ORDER_IDS')
const resetSelectedOrderIds = createAction('SCREENS/PROJECT_VIEW/RESET_SELECTED_ORDER_IDS')

const defaultState = []

const reducer = handleActions(
  {
    [setSelectedOrderIds]: (state, { payload }) => payload,
    [resetSelectedOrderIds]: () => defaultState,
  },
  defaultState,
)

export { setSelectedOrderIds, resetSelectedOrderIds, reducer }
