import React from 'react'
import PropTypes from 'prop-types'
import { sumBy } from 'lodash'
import numeral from 'numeral'
import styles from './styles.css'

class SubValuationFooter extends React.PureComponent {

  static propTypes = {
    subValuation: PropTypes.object.isRequired,
  }

  render() {

    const {
      subValuation,
    } = this.props

    const subValuationCompleteTotal = sumBy(subValuation.sub_valuation_items, 'complete_total')

    return (

      <div className={styles.wrapper}>

        <div className={styles.total}>
          {`Valuation Total: ${subValuationCompleteTotal < 0 ? '-' : ''}£${numeral(Math.abs(subValuationCompleteTotal)).format('0,0.00')}`}
        </div>

      </div>

    )

  }

}

export default SubValuationFooter
