import { setIsStarting } from '../Actions/status';
import setupSentry from '../Services/setupSentry';
import setupAxios from '../Services/setupAxios';

export default () => async (dispatch) => {
  
  dispatch(setIsStarting(true));

  dispatch(setupSentry());

  dispatch(setupAxios());
  
  setTimeout(() => dispatch(setIsStarting(false)), 1000);
  
}