import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SubOrderModal from '../../../../Modals/SubOrder'

import Header from './Components/Header'
import Table from './Components/Table'

const SubOrders = props => {

  const {
    subOrderModal,
    componentDidMount,
    onCloseSubOrderModal,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [])

  return (
    
    <React.Fragment>

      <Header />

      <Table />

      {subOrderModal.show && (
        <SubOrderModal
          {...subOrderModal}
          onClose={onCloseSubOrderModal}
        />
      )}

    </React.Fragment>

  )

}

SubOrders.propTypes = {
  subOrderModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  onCloseSubOrderModal: PropTypes.func.isRequired,
}

export default SubOrders
