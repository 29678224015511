// @flow
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown.js';
import classnames from 'classnames';

import styles from './Chart.css';

ReactHighcharts.Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
})

drilldown(Highcharts);


type Props = {
  config: any,
  chartMode: any,

  handleClickChartModeToggle: Function
}

export default class extends React.PureComponent<Props> {
  
  render () {

    const chartModes = [
      { key: 'months', title: 'Months' },
      { key: 'weeks', title: 'Weeks' },
    ];

    const {
      handleClickChartModeToggle
    } = this.props;

    return (
      
      <div className={styles.panel}>
      
        <ReactHighcharts
          ref="chart"
          config={this.props.config}
          isPureConfig
        />

        <div className={styles.toggle}>
          {chartModes.map(mode => {
            // const selected = (mode.key === this.props.chartMode ? true : false);
            return (
              <div
                key={mode.key}
                className={classnames(styles.item, { [styles.itemSelected]: mode.key === this.props.chartMode })}
                onClick={() => handleClickChartModeToggle(mode.key)}
                role="presentation"
              >
                {mode.title}
              </div>
            )
          })}
          
        </div>
      
      </div>
        
    );
  }
}
