import fetchDirectory from '../Services/fetchDirectory';
import { setCurrentDirectoryPath } from '../State/currentDirectoryPath';
import { clearCheckedItemPaths } from '../State/checkedItemPaths';

export default (index) => async (dispatch, getState) => {
    
  const { currentDirectoryPath } = getState().screens.ProjectView;

  const newCurrentPath = currentDirectoryPath.split('/').slice(0, index + 1).join('/');
  
  dispatch(setCurrentDirectoryPath(newCurrentPath));

  dispatch(clearCheckedItemPaths())

  dispatch(fetchDirectory());

}