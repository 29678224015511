import React from 'react'
import { Modal } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import deleteSubValuation from '../../../Services/delete'

export default () => (dispatch, getState) => {

  const { subValuation } = getState().screens.SubValuation

  Modal.confirm({
    title: `Delete "${subValuation.reference}"`,
    content: 'Are you sure you want to delete this valuation?',
    icon: <DeleteOutlined style={{ color: '#FF4D4F' }} />,
    okType: 'danger',
    okText: 'Delete',
    onOk: () => dispatch(deleteSubValuation()),
  })

}
