import { connect } from 'react-redux'
import { isSubmitting } from 'redux-form'
import ResetPassword from './ResetPassword'

import { FORM_NAME } from './Form'

import componentWillMount from './Handlers/componentWillMount'
import onClickSubmit from './Handlers/onClickSubmit'
import onClickCancel from './Handlers/onClickCancel'

const mapStateToProps = (state) => ({
  submitting: isSubmitting(FORM_NAME)(state),
})

const actionCreators = {
  componentWillMount,
  onClickSubmit,
  onClickCancel,
}

export default connect(mapStateToProps, actionCreators)(ResetPassword)
