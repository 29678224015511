import { setShowSetTargetCostsModal } from '../State/showSetTargetCostsModal';
import { setSelectedOrderGroupKeys } from '../State/selectedOrderGroupKeys';
import setTargetCostPercentages from '../Services/setTargetCostPercentages';

export default (percentages) => async (dispatch) => {

  dispatch(setShowSetTargetCostsModal(false));
  dispatch(setSelectedOrderGroupKeys(null));

  if (percentages) {
    dispatch(setTargetCostPercentages(percentages))
  }
  
}