import axios from 'axios'
import { getTeamUrl } from 'Common'
import { store } from 'Evolve/store'

const request = axios.create({
  baseURL: getTeamUrl(null, 'api'),
})

request.interceptors.request.use(config => ({
  ...config,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${store.getState().token}`,
  },
}))

export default request
