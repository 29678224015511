import { createSelector } from 'reselect'

const selectSubProjects = state => state.screens.SubProject.SubProjects.subProjects

export default createSelector(
  selectSubProjects,
  (subProjects) => subProjects.map(subProject => ({
    ...subProject,
    key: `${subProject.subcontractor.id}|${subProject.project.id}`,
  })),
)
