import { message } from 'antd'
import { getProps } from '../props';
import { updateClientSite } from '../../../HttpRequests/clientSites';

export default (values) => async () => {

  const data = {
    client_id: getProps().clientId,
    ...values
  }

  const response = await updateClientSite({ id: values.id, data });

  const clientSite = response.data.data;

  message.success('Site Updated.');

  return clientSite;

}