
import { createAction, handleActions } from 'redux-actions';

const setShowClientContactModal = createAction("MODALS/CLIENT/SET_SHOW_CLIENT_CONTACT_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowClientContactModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowClientContactModal, reducer };