import { createAction, handleActions } from 'redux-actions';

const setSelectedKey = createAction("MODALS/MOVE_FILES/SET_SELECTED_KEY");

const defaultState = null;

const reducer = handleActions(
  {
    [setSelectedKey]: (state, action) => action.payload,
  },
  defaultState
);

export { setSelectedKey, reducer };