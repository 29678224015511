// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';
import recalculateEstimateItem from './recalculateEstimateItem';
import type { EstimateItem } from '../types';

export type UpdateEstimateItemFieldParams = {
  estimateGroupIndex: number,
  estimateItemIndex: number,
  field: string,
  value: string|null
}

export default (params: UpdateEstimateItemFieldParams) => (dispatch: Function, getState: Function) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);

  estimate = estimate.setIn(
    ['estimate_groups', params.estimateGroupIndex, 'estimate_items', params.estimateItemIndex, params.field],
    params.value
  )

  if (params.field === 'type' && !params.value) {
    
    estimate = estimate.updateIn(
      ['estimate_groups', params.estimateGroupIndex, 'estimate_items', params.estimateItemIndex],
      (estimateItem: EstimateItem) => Object.assign({}, estimateItem, {
        flag: null,
        quantity: '0.00',
        unit: 'm2',
        rate: '0.00',
        subtotal: '0.000',
        discount: '0.00',
        total: '0.00'
      })
    )

  }

  estimate = estimate.toJS();

  dispatch(loadEstimate(estimate));

  if (['rate', 'quantity'].includes(params.field)) {

    dispatch(recalculateEstimateItem({
      estimateGroupIndex: params.estimateGroupIndex,
      estimateItemIndex: params.estimateItemIndex
    }))

  }

}