import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import Component from './Component';

import selectEmailOptions from './Selectors/selectEmailOptions';
import selectSelectedEmailToOption from './Selectors/selectSelectedEmailToOption';
import selectSelectedEmailCcOption from './Selectors/selectSelectedEmailCcOption';
import selectSelectedEmailBccOption from './Selectors/selectSelectedEmailBccOption';
import selectProjectFiles from './Selectors/selectProjectFiles';

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import onClickCancel from './Handlers/onClickCancel';
import onClickSubmit from './Handlers/onClickSubmit';
import onSearchEmailOptions from './Handlers/onSearchEmailOptions';
import onSelectEmailOption from './Handlers/onSelectEmailOption';
import onAddFile from './Handlers/onAddFile';
import onRemoveFile from './Handlers/onRemoveFile';
import onRemoveProjectFile from './Handlers/onRemoveProjectFile';

import { FORM_NAME } from './Form';

const mapStateToProps = (state) => ({
  initializing: state.modals.EmailProjectInformation.initializing,
  submitting: isSubmitting(FORM_NAME)(state),
  emailOptions: selectEmailOptions(state),
  selectedEmailToOption: selectSelectedEmailToOption(state),
  selectedEmailCcOption: selectSelectedEmailCcOption(state),
  selectedEmailBccOption: selectSelectedEmailBccOption(state),
  projectFiles: selectProjectFiles(state),
  fileList: state.modals.EmailProjectInformation.fileList,
});

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  onClickCancel,
  onClickSubmit,
  onSearchEmailOptions,
  onSelectEmailOption,
  onAddFile,
  onRemoveFile,
  onRemoveProjectFile,
};

export default connect(mapStateToProps, actionCreators)(Component);