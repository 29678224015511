import { connect } from 'react-redux'
import ProjectCellModal from './ProjectCellModal'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onChangeActiveTab from './Handlers/onChangeActiveTab'
import onAddJob from './Handlers/onAddJob'
import onUpdateJob from './Handlers/onUpdateJob'
import onRemoveJob from './Handlers/onRemoveJob'
import onUpdatePlannerCell from './Handlers/onUpdatePlannerCell'
import onChangeDuplicateKey from './Handlers/onChangeDuplicateKey'
import onSave from './Handlers/onSave'

const mapStateToProps = (state) => ({
  activeTab: state.screens.Planner.ProjectCellModal.activeTab,
  subcontractors: state.screens.Planner.ProjectCellModal.subcontractors,
  jobs: state.screens.Planner.ProjectCellModal.jobs,
  plannerCell: state.screens.Planner.ProjectCellModal.plannerCell,
  duplicate: state.screens.Planner.ProjectCellModal.duplicate,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onChangeActiveTab,
  onAddJob,
  onUpdateJob,
  onRemoveJob,
  onUpdatePlannerCell,
  onChangeDuplicateKey,
  onSave,
}

export default connect(mapStateToProps, actionCreators)(ProjectCellModal)
