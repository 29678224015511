// @flow

import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchProjects from '../../../Core/Services/fetchProjects';

export default (props: any) => async (dispatch: Function) => {

  setProps(props);

  await dispatch(fetchProjects());

  dispatch(initialize());
  
}