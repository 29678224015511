import { connect } from 'react-redux'
import Component from './Component'

import onClickProject from './Handlers/onClickProject'

const mapStateToProps = (state) => ({
  invoice: state.screens.InvoiceView.invoice,
})

const actionCreators = {
  onClickProject,
}

export default connect(mapStateToProps, actionCreators)(Component)