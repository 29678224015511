import { connect } from 'react-redux'
import Header from './Header'

import onChangeFilter from './Handlers/onChangeFilter'

const mapStateToProps = (state) => ({
  filters: state.screens.SubProject.Overview.filters,
})

const actionCreators = {
  onChangeFilter,
}

export default connect(mapStateToProps, actionCreators)(Header)
