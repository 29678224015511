import sendEmail from '../Services/sendEmail';

import { getProps } from '../props';

export default async (values, dispatch) => {
    
  const { onClose, didSend } = getProps();

  const isSent = await dispatch(sendEmail(values));

  if (isSent) {

    onClose();

    if (didSend) didSend();
    
  }

}