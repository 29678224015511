import React from 'react'
import { Select, DatePicker, Switch, Radio, Tooltip, Popover, Button } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import styles from './Filters.css'

const defaultSelectProps = {
  style: { width: 200 },
  showArrow: true,
  showSearch: true,
  allowClear: true,
  notFoundContent: null,
  defaultActiveFirstOption: false,
  optionFilterProp: 'children',
}

type Props = {
  projectStatuses: [],
  projectPhases: [],
  clients: [],
  users: [],
  subcontractors: [],
  projects: [],
  setup: Object,
  filters: Object,
  isFiltered: Boolean,
  onChangeSetup: Function,
  onChangeFilter: Function,
}

const Spacer = () => <div style={{ width: 12 }} />

const Box = props => <div className={styles.box}>{props.children}</div>

const Label = props => <div className={styles.label}>{props.text}</div>

export default class extends React.PureComponent<Props> {

  render() {

    const {
      projectStatuses,
      projectPhases,
      clients,
      users,
      subcontractors,
      projects,
      setup,
      filters,
      isFiltered,
      onChangeSetup,
      onChangeFilter,
    } = this.props

    const popover = (
      <div className={styles.popover}>
        {setup.model === 'Project' && (
          <React.Fragment>
            <Box>
              <Label text='Status' />
              <Select
                {...defaultSelectProps}
                placeholder='All Statuses'
                value={filters.statusKey}
                onChange={(value) => onChangeFilter('statusKey', value)}
                mode='multiple'
                dropdownMatchSelectWidth={false}
                style={{ width: '100%' }}
              >
                {projectStatuses.map(projectStatus => (
                  <Select.Option key={projectStatus.key}>
                    {`${projectStatus.title}`}
                  </Select.Option>
                ))}
              </Select>
            </Box>
            <Box>
              <Label text='Phase' />
              <Select
                {...defaultSelectProps}
                placeholder='All Phases'
                value={filters.phaseKey}
                onChange={(value) => onChangeFilter('phaseKey', value)}
                mode='multiple'
                dropdownMatchSelectWidth={false}
                style={{ width: '100%' }}
              >
                {projectPhases.map(projectPhase => (
                  <Select.Option key={projectPhase.key}>
                    {`${projectPhase.title}`}
                  </Select.Option>
                ))}
              </Select>
            </Box>
            <Box>
              <Label text='Started Between' />
              <DatePicker.RangePicker
                value={filters.startBetween}
                onChange={(value) => onChangeFilter('startBetween', value || [])}
                format="DD/MM/YYYY"
                style={{ width: '100%' }}
              />
            </Box>
            <Box>
              <Label text='Archived Between' />
              <DatePicker.RangePicker
                value={filters.archivedBetween}
                onChange={(value) => onChangeFilter('archivedBetween', value || [])}
                format="DD/MM/YYYY"
                style={{ width: '100%' }}
              />
            </Box>
          </React.Fragment>
        )}
        {setup.model === 'Estimate' && (
          <React.Fragment>
            <Box>
              <Label text='Client' />
              <Select
                {...defaultSelectProps}
                placeholder='All Clients'
                value={filters.clientId}
                onChange={(value) => onChangeFilter('clientId', value)}
                mode='multiple'
                dropdownMatchSelectWidth={false}
                style={{ width: '100%' }}
              >
                {clients.map(client => (
                  <Select.Option key={client.id}>
                    {`${client.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Box>
            <Box>
              <Label text='Owner' />
              <Select
                {...defaultSelectProps}
                placeholder='All Owners'
                value={filters.userId}
                onChange={(value) => onChangeFilter('userId', value)}
                mode='multiple'
                style={{ width: '100%' }}
              >
                {users.map(user => (
                  <Select.Option key={user.id}>
                    {`${user.full_name}`}
                  </Select.Option>
                ))}
              </Select>
            </Box>
            <Box>
              <Label text='Date Range' />
              <DatePicker.RangePicker
                value={filters.dateBetween}
                onChange={(value) => onChangeFilter('dateBetween', value || [])}
                format='DD/MM/YYYY'
                style={{ width: '100%' }}
              />
            </Box>
          </React.Fragment>
        )}
        {setup.model === 'Timesheet' && (
          <React.Fragment>
            <Box>
              <Label text='Subcontractor' />
              <Select
                {...defaultSelectProps}
                placeholder='All Subcontractors'
                value={filters.subcontractorId}
                onChange={(value) => onChangeFilter('subcontractorId', value)}
                mode='multiple'
                dropdownMatchSelectWidth={false}
                style={{ width: '100%' }}
              >
                {subcontractors.map(subcontractor => (
                  <Select.Option key={subcontractor.id}>
                    {`${subcontractor.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Box>
            <Box>
              <Label text='Project' />
              <Select
                {...defaultSelectProps}
                placeholder='All Projects'
                value={filters.projectId}
                onChange={(value) => onChangeFilter('projectId', value)}
                mode='multiple'
                style={{ width: '100%' }}
              >
                {projects.map(project => (
                  <Select.Option key={project.id}>
                    {`[${project.id}] ${project.client_site.client.name} - ${project.client_site.name}`}
                  </Select.Option>
                ))}
              </Select>
            </Box>
            <Box>
              <Label text='Contracts Manager' />
              <Select
                {...defaultSelectProps}
                placeholder='All Users'
                value={filters.contractsManagerId}
                onChange={(value) => onChangeFilter('contractsManagerId', value)}
                mode='multiple'
                style={{ width: '100%' }}
              >
                {users.map(user => (
                  <Select.Option key={user.id}>
                    {`${user.full_name}`}
                  </Select.Option>
                ))}
              </Select>
            </Box>
            <Box>
              <Label text='Date Range' />
              <DatePicker.RangePicker
                value={filters.dateRange}
                onChange={(value) => onChangeFilter('dateRange', value || [])}
                format='DD/MM/YYYY'
                style={{ width: '100%' }}
              />
            </Box>
          </React.Fragment>
        )}
      </div>
    )

    return (

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>

        <div style={{ display: 'flex' }}>

          <Select
            value={setup.model}
            onChange={(value) => onChangeSetup('model', value)}
            allowClear={false}
            style={{ width: 200 }}
          >
            {setup.models.map(model => (
              <Select.Option key={model.value}>
                {model.label}
              </Select.Option>
            ))}
          </Select>

          <div style={{ width: 10 }} />

          <Select
            placeholder='Group by'
            value={setup.field ? `By ${setup.fields[setup.model][setup.field]}` : undefined}
            onChange={(value) => onChangeSetup('field', value)}
            style={{ width: 200 }}
          >
            {Object.keys(setup.fields[setup.model]).map(key => (
              <Select.Option key={key}>
                {setup.fields[setup.model][key]}
              </Select.Option>
            ))}
          </Select>

          {setup.model === 'Estimate' && (

            <React.Fragment>

              <div style={{ width: 10 }} />

              <Radio.Group
                value={filters.newOrExtra}
                onChange={(event) => onChangeFilter('newOrExtra', event.target.value)}
              >
                <Radio.Button key='all' value='all' style={{ minWidth: 60, textAlign: 'center' }}>All</Radio.Button>
                <Radio.Button key='new' value='new' style={{ minWidth: 60, textAlign: 'center' }}>New</Radio.Button>
                <Radio.Button key='extra' value='extra' style={{ minWidth: 60, textAlign: 'center' }}>Extra</Radio.Button>
              </Radio.Group>

              <div style={{ width: 10 }} />
              
              <div style={{ display: 'flex', marginTop: 5 }}>
                <Switch
                  checked={filters.withRevisions}
                  onChange={(value) => onChangeFilter('withRevisions', value)}
                />
                <div style={{ width: 5 }} />
                <span>With revisions</span>
              </div>

            </React.Fragment>
            
          )}

          {setup.model === 'Timesheet' && (

            <React.Fragment>

              <div style={{ width: 10 }} />

              <Radio.Group
                value={filters.hoursType}
                onChange={(event) => onChangeFilter('hoursType', event.target.value)}
              >
                <Radio.Button key='all' value='all'>All</Radio.Button>
                <Tooltip title='Hourly Rate'>
                  <Radio.Button key='hourly-rate' value='hourly-rate'>HR</Radio.Button>
                </Tooltip>
                <Tooltip title='Day Rate'>
                  <Radio.Button key='day-rate' value='day-rate'>DR</Radio.Button>
                </Tooltip>
                <Tooltip title='Price Work'>
                  <Radio.Button key='price-work' value='price-work'>PW</Radio.Button>
                </Tooltip>
              </Radio.Group>

              <div style={{ width: 10 }} />

              <Radio.Group
                value={filters.hoursFormat}
                onChange={(event) => onChangeFilter('hoursFormat', event.target.value)}
              >
                <Radio.Button key='time' value='time'>Time</Radio.Button>
                <Radio.Button key='decimal' value='decimal'>Decimal</Radio.Button>
              </Radio.Group>

            </React.Fragment>
            
          )}

          {!!setup.field && (
            <React.Fragment>

              <div style={{ width: 10 }} />

              <Popover
                trigger={['click']}
                placement='bottom'
                content={popover}
              >
                <Button type={isFiltered && 'primary'} icon={<FilterOutlined />}>Filter</Button>
              </Popover>
              
            </React.Fragment>
          )}

        </div>

      </div>

    )

  }

}
