// flow

import * as React from 'react';
import { Field } from 'redux-form';
import { DatePickerField, InputNumberField } from 'FormFields';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';

import styles from './InputFields.css';

type Props = {
  fields: any,
  meta: {
    submitting: boolean
  }
}

const labelColSpan = 5;
const wrapperColSpan = 18;

export default class extends React.PureComponent<Props> {

  render() {
    const { fields, meta } = this.props;
    const { submitting } = meta; 

    return (
      <div>
        <div>
          {fields.map((retentionItem, index) => (

            <div key={String(index)} style={{position:'relative'}}>
              <h4>
                {`Retention ${index + 1}`}
              </h4>

              <Field
                component={DatePickerField}
                name={`${retentionItem}.due_date`}
                controlId="due_date"
                label="Date Due"
                disabled={submitting}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: wrapperColSpan }}
                fieldCol={{ span: 14 }}
              />

              <Field
                component={InputNumberField}
                name={`${retentionItem}.percentage`}
                controlId="percentage"
                label="Percentage %"
                formatter={(value) => value.toString().replace(/[^0-9.]/g, "")}
                disabled={submitting}
                labelCol={{ span: labelColSpan }}
                wrapperCol={{ span: wrapperColSpan }}
                fieldCol={{ span: 14 }}
              />

              {fields.length > 1 && (
                
                <div className={styles.removeContainer}>
                  
                  <span onClick={() =>  fields.remove(index)} className={styles.link} role="presentation">
                    {'Remove Retention'}
                  </span>

                </div>

              )}
              <Divider type="horizontal" />
            </div>

            ))}
        </div>
        
        <div>
          <Button icon={<PlusOutlined />} onClick={() => fields.push({})}>
            Add Retention
          </Button>
        </div>
      </div>
    );
  };
}