
import { createAction, handleActions } from 'redux-actions';

const setShowViewEmailModal = createAction("SCREENS/PROJECT_VIEW/SET_SHOW_VIEW_EMAIL_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowViewEmailModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowViewEmailModal, reducer };