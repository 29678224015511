// @flow

import * as React from 'react';
import { List } from 'react-virtualized';
import { SortableContainer } from 'react-sortable-hoc';

type Props = {}

class Component extends React.PureComponent<Props> {

  List: any;
  
  render = () => <List ref={ref => { this.List = ref}} {...this.props} />
  
}

export default SortableContainer(Component, { withRef: true })