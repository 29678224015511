import { push } from 'connected-react-router';
import { getTeamUrl } from 'Common';
import handleException from '../../../Core/Helpers/handleException';
import { deleteOrder } from '../../../HttpRequests/orders';

export default () => async (dispatch, getState) => {

  const { order } = getState().screens.OrderView;
  
  try {

    await deleteOrder({ id: order.id });
    
    dispatch(push(getTeamUrl(`projects/${order.project_id}/view?tab=orders`)));
    
  } catch(error) {

    handleException(error);

  }
  
}