import { message } from 'antd'
import { updateEstimate } from '../../../HttpRequests/estimates';

export default (values) => async () => {

  const { id } = values;
    
  const data = { ...values };

  const response = await updateEstimate({ id, data });

  const estimate = response.data.data;

  message.success("Estimate Updated");

  return estimate;

}