import { concat, without } from 'lodash'
import { setCheckedItemPaths } from '../../../State/checkedItemPaths'

export default (path) => (dispatch, getState) => {

  const { checkedItemPaths } = getState().modules.FileManager

  dispatch(setCheckedItemPaths(
    checkedItemPaths.includes(path) ? without(checkedItemPaths, path) : concat(checkedItemPaths, path)
  ))

}
