import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styles from './Component.css';

export default class extends React.PureComponent {

  static propTypes = {
    onClickCancel: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired
  }

  render () {

    const {
      onClickCancel,
      onClickSubmit
    } = this.props;

    return (
        
      <div className={styles.footer}>

        <Button type="default" onClick={onClickCancel}>
          Cancel
        </Button>

        <Button type="primary" onClick={onClickSubmit}>
          Export
        </Button>

      </div>

    )
  }
}