import React from 'react'
import Screen from '../../Components/Screen'

import Breadcrumbs from './Components/Breadcrumbs'
import Header from './Components/Header'
import Filters from './Components/Filters'
import Table from './Components/Table'

import Timesheet from '../../Modals/Timesheet'
import TimesheetAdjustment from '../../Modals/TimesheetAdjustment'

type Props = {
  subcontractors: [],
  projects: [],
  timesheets: [],
  fetching: boolean,
  searchTerm: string,
  filters: {},
  pagination: {},
  timesheetModal: boolean,
  timesheetAdjustmentModal: boolean,
  componentDidMount: () => void,
  onChangeSearch: () => void,
  onSubmitSearch: () => void,
  onChangeFilter: () => void,
  onChangeTable: () => void,
  onClickRow: () => void,
  onClickExport: () => void,
  onClickNewTimesheet: () => void,
  onCloseTimesheetModal: () => void,
  didCreateTimesheet: () => void,
  didUpdateTimesheet: () => void,
  didDeleteTimesheet: () => void,
  onClickStartDateTime: () => void,
  onClickEndDateTime: () => void,
  onClickCreateTimesheetAdjustment: () => void,
  onClickTimesheetAdjustment: () => void,
  onCloseTimesheetAdjustmentModal: () => void,
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount()

  render() {
    
    const {
      subcontractors,
      projects,
      timesheets,
      fetching,
      searchTerm,
      filters,
      pagination,
      timesheetModal,
      timesheetAdjustmentModal,
      onChangeSearch,
      onSubmitSearch,
      onChangeFilter,
      onChangeTable,
      onClickRow,
      onClickExport,
      onClickNewTimesheet,
      onCloseTimesheetModal,
      didCreateTimesheet,
      didUpdateTimesheet,
      didDeleteTimesheet,
      onClickStartDateTime,
      onClickEndDateTime,
      onClickCreateTimesheetAdjustment,
      onClickTimesheetAdjustment,
      onCloseTimesheetAdjustmentModal,
    } = this.props
    
    return (

      <Screen>

        <Breadcrumbs />

        <Header
          searchTerm={searchTerm}
          onChangeSearch={onChangeSearch}
          onSubmitSearch={onSubmitSearch}
          onClickExport={onClickExport}
          onClickNewTimesheet={onClickNewTimesheet}
        />

        <Filters
          subcontractors={subcontractors}
          projects={projects}
          filters={filters}
          onChangeFilter={onChangeFilter}
        />

        <Table
          timesheets={timesheets}
          fetching={fetching}
          pagination={pagination}
          onChangeTable={onChangeTable}
          onClickRow={onClickRow}
          onClickStartDateTime={onClickStartDateTime}
          onClickEndDateTime={onClickEndDateTime}
          onClickCreateTimesheetAdjustment={onClickCreateTimesheetAdjustment}
          onClickTimesheetAdjustment={onClickTimesheetAdjustment}
        />

        {timesheetModal.show && (
          <Timesheet
            id={timesheetModal.id}
            onClose={onCloseTimesheetModal}
            didCreate={didCreateTimesheet}
            didUpdate={didUpdateTimesheet}
            didDelete={didDeleteTimesheet}
          />
        )}

        {timesheetAdjustmentModal.show && (
          <TimesheetAdjustment
            {...timesheetAdjustmentModal}
            onClose={onCloseTimesheetAdjustmentModal}
          />
        )}

      </Screen>

    )

  }

}
