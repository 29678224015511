import React from 'react';
import { sortBy } from 'lodash';
import { Field } from 'redux-form';
import { Divider } from 'antd';
import { SelectField, DatePickerField, SwitchField } from 'FormFields';

import IconButton from '../../../Components/IconButton';

const labelColSpan = 5;
const wrapperColSpan = 18;

type Props = {
  valuation: ?any,
  projects: [],
  users: [],
  clientContacts: [],
  submitting: boolean,
  showNewClientContactButton: boolean,

  onChangeProject: () => void,
  onClickNewClientContact: () => void
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      valuation,
      projects,
      users,
      clientContacts,
      submitting,
      showNewClientContactButton,

      onChangeProject,
      onClickNewClientContact
    } = this.props;

    const projectOptions = projects.map(item => ({
      value: item.id,
      label: `[${item.id}] ${item.client_site.client.name} - ${item.client_site.name}`
    }));

    const clientContactOptions = sortBy(clientContacts.map(item => ({
      value: item.id,
      label: `${item.first_name || ''} ${item.last_name || ''}`
    })), ['label']);

    const userOptions = users.map(item => ({
      value: item.id,
      label: `${item.first_name} ${item.last_name}`
    }));

    const NewClientContactButton = () => (
      <IconButton
        type="plus-circle"
        tooltip="New Contact"
        onClick={onClickNewClientContact}
        style={{ marginBottom: -4 }}
      />
    );

    return (

      <div>

        {!valuation && (

          <React.Fragment>

            <Field
              required
              component={SelectField}
              name="project_id"
              label="Project"
              placeholder="Select Project"
              disabled={submitting}
              options={projectOptions}
              onChange={onChangeProject}
              labelCol={{ span: labelColSpan }}
              wrapperCol={{ span: wrapperColSpan }}
              fieldCol={{ span: 22 }}
              afterCol={{ span: 2 }}
            />

            <Divider />

          </React.Fragment>

        )}

        <Field
          required
          component={SelectField}
          name="client_contact_id"
          label="Contact"
          placeholder="Select contact"
          disabled={submitting}
          options={clientContactOptions}
          renderAfter={() => showNewClientContactButton && <NewClientContactButton />}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
          afterCol={{ span: 2 }}
        />

        <Field
          required
          component={SelectField}
          name="user_id"
          label="Owner"
          placeholder="Select user"
          disabled={submitting}
          options={userOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />

        <Field
          required
          component={DatePickerField}
          name="date"
          label="Date"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />

        <Field
          component={DatePickerField}
          name="valuation_to_date"
          label="Valuation up to"
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 14 }}
        />

        {!valuation && (

          <Field
            component={SwitchField}
            name="fill_remaining"
            label="Auto Fill"
            disabled={submitting}
            renderAfter={()=> (
              <span>
                Fill remaining 100%
              </span>
            )}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            fieldCol={{ span: 3 }}
          />

        )}

        {(!!valuation && !!valuation.final) && (

          <Field
            component={SelectField}
            name="final_confirmed"
            label="Final Account"
            disabled={submitting}
            options={[
              { value: 0, label: "Draft Final" },
              { value: 1, label: "Final Account" }
            ]}
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            fieldCol={{ span: 10 }}
            allowClear={false}
          />

        )}

      </div>

    )
  }
}