import { message } from 'antd'
import { deleteClientSite } from '../../../HttpRequests/clientSites'
import { getProps } from '../props'
import handleException from '../../../Core/Helpers/handleException'

export default () => async () => {

  const { clientSite, onClose, didDelete } = getProps()

  try {

    await deleteClientSite({ id: clientSite.id })

    message.success('Site Deleted')

    onClose(true)

    if (didDelete) didDelete()

  } catch (error) {

    handleException(error)

  }

}
