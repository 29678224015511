import { combineReducers } from 'redux'

import { reducer as subInvoice } from './State/subInvoice'
import { reducer as subValuations } from './State/subValuations'
import { reducer as originalItems } from './State/originalItems'
import { reducer as subOrders } from './State/subOrders'
import { reducer as activeTab } from './State/activeTab'
import { reducer as editing } from './State/editing'
import { reducer as saving } from './State/saving'
import { reducer as subInvoiceModal } from './State/subInvoiceModal'
import { reducer as subValuationModal } from './State/subValuationModal'

export default combineReducers({
  subInvoice,
  subValuations,
  originalItems,
  subOrders,
  activeTab,
  editing,
  saving,
  subInvoiceModal,
  subValuationModal,
})
