
import { getClient } from '../../../HttpRequests/clients';
import { loadClient } from '../State/client';
import handleException from '../../../Core/Helpers/handleException';
import { getProps } from '../props';

export default () => async (dispatch) => {

  try {
    
    const { id } = getProps().match.params;

    const params = {};

    params.include = [
      'client_contacts',
      'client_sites.projects',
      'projects',
      'credit_limit_last_set_by_user',
      'aldermore_limit_last_set_by_user',
      'value_outstanding_last_set_by_user',
      'client_risk_level_last_set_by_user',
    ].join();

    const response = await getClient({ id, params })

    dispatch(loadClient( response.data.data ));

  } catch (error) {

    handleException(error);

  }
}