import { createAction, handleActions } from 'redux-actions'

const setForm = createAction('SCREENS/TIMESHEET/SET_FORM')
const setField = createAction('SCREENS/TIMESHEET/SET_FIELD')
const resetForm = createAction('SCREENS/TIMESHEET/RESET_FORM')

const defaultState = {
  projectId: undefined,
  notes: undefined,
}

const reducer = handleActions(
  {
    [setForm]: (state, action) => action.payload,
    [setField]: (state, action) => ({ ...state, ...action.payload }),
    [resetForm]: () => defaultState,
  },
  defaultState,
)

export { setForm, setField, resetForm, reducer }
