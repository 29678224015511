import fetchProjects from '../Services/fetchProjects'; 
import fetchProjectTotals from '../Services/fetchProjectTotals'; 
import fetchProjectPhases from '../../../Core/Services/fetchProjectPhases';
import fetchProjectStatuses from '../../../Core/Services/fetchProjectStatuses';
import fetchClients from '../../../Core/Services/fetchClients';
import fetchClientContacts from '../../../Core/Services/fetchClientContacts';
import fetchUsers from '../../../Core/Services/fetchUsers'; 
import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors'; 

export default () => async (dispatch) => {

  await dispatch(fetchProjects());

  dispatch(fetchProjectTotals());
  
  dispatch(fetchProjectPhases());
  dispatch(fetchProjectStatuses());
  dispatch(fetchClients());
  dispatch(fetchClientContacts());
  dispatch(fetchUsers());
  dispatch(fetchSubcontractors());
  
}