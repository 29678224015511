import { combineReducers } from "redux";

import { reducer as itemDescriptions } from "./State/itemDescriptions";
import { reducer as pagination } from "./State/pagination";
import { reducer as searchTerm } from "./State/searchTerm";
import { reducer as fetching } from "./State/fetching";
import { reducer as showItemDescriptionModal } from "./State/showItemDescriptionModal";
import { reducer as selectedItemDescriptionId } from "./State/selectedItemDescriptionId";

export default combineReducers({
  itemDescriptions,
  pagination,
  searchTerm,
  fetching,
  showItemDescriptionModal,
  selectedItemDescriptionId
});
