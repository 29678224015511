import { message } from 'antd';

import { getProps } from '../props';
import { deleteItemDescription } from '../../../HttpRequests/itemDescriptions';
import handleException from '../../../Core/Helpers/handleException';

export default () => async () => {
  
  try {
  
    const { itemDescriptionId, onClose, didDelete } = getProps();

    await deleteItemDescription({ id: itemDescriptionId });
    
    message.success("Item Description Deleted");

    onClose();
    
    if (didDelete) didDelete();

  } catch (error) {
    
    handleException(error);

  }
}