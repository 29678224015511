import { createAction, handleActions } from 'redux-actions';

const setShowItemDescriptionModal = createAction("SCREENS/ITEM_DESCRIPTIONS/SET_SHOW_ITEM_DESCRIPTION_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowItemDescriptionModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowItemDescriptionModal, reducer };