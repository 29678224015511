import { createSelector } from 'reselect'
import { first, last } from 'lodash'

const selectPeriods = state => state.screens.Planner.data.periods

export default createSelector(
  selectPeriods,
  (periods) => {
    
    if (!periods.length) return ''

    return `${first(periods).date_formatted} - ${last(periods).date_formatted}`

  },
)
