import { Modal } from 'antd';

export default (project) => async () => {

  if (project.client_site.client.client_risk_level_key === 3) {

    Modal.warning({
      title: `High Credit Risk`,
      content: `'${project.client_site.client.name}' currently has a high credit risk level`,
      width: 450,
      okText: 'Okay'
    });

  }

  if (project.client_site.client.client_risk_level_key === 4) {

    Modal.warning({
      title: `High Credit Risk`,
      content: `'${project.client_site.client.name}' currently has a black credit risk level`,
      width: 450,
      okText: 'Okay'
    });

  }

}