import { message } from 'antd'
import { handleSubValuationItemsByMany } from '../../../HttpRequests/subValuations'
import fetch from './fetch'
import { setSaving } from '../State/saving'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { subInvoice, subValuations } = getState().screens.SubInvoice

  const data = subValuations

  dispatch(setSaving(true))

  try {

    await handleSubValuationItemsByMany({ data })
    
    message.success(`Updated "${subInvoice.reference}"`)

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setSaving(false))

  }

}
