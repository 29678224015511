import { connect } from 'react-redux';
import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';
import onChangeTable from './Handlers/onChangeTable';
import onChangeSearch from './Handlers/onChangeSearch';
import onSubmitSearch from './Handlers/onSubmitSearch';
import onUpload from './Handlers/onUpload';
import onClickExport from './Handlers/onClickExport';
import onChangeFilter from './Handlers/onChangeFilter';

const mapStateToProps = (state) => ({
  projects: state.screens.ProfitAndLoss.projects,
  projectTotals: state.screens.ProfitAndLoss.projectTotals,
  searchTerm: state.screens.ProfitAndLoss.searchTerm,
  pagination: state.screens.ProfitAndLoss.pagination,
  fetching: state.screens.ProfitAndLoss.fetching,
  projectPhases: state.core.projectPhases,
  projectStatuses: state.core.projectStatuses.filter(projectStatus => projectStatus.title !== 'Not Started'),
  clients: state.core.clients,
  users: state.core.users,
  filters: state.screens.ProfitAndLoss.filters,
});

const actionCreators = {
  componentDidMount,
  onChangeSearch,
  onChangeTable,
  onChangeFilter,
  onSubmitSearch,
  onUpload,
  onClickExport
};

export default connect(mapStateToProps, actionCreators)(Component);