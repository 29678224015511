import { createAction, handleActions } from 'redux-actions'

const setFilters = createAction('SCREENS/SUB_PROJECT/SET_FILTERS')
const setFilter = createAction('SCREENS/SUB_PROJECT/SET_FILTER')
const resetFilters = createAction('SCREENS/SUB_PROJECT/RESET_FILTERS')

const defaultState = {
  projectStatusKey: 2,
  projectId: null,
}

const reducer = handleActions(
  {
    [setFilters]: (state, { payload }) => payload,
    [setFilter]: (state, { payload }) => {
      const newState = {
        ...state,
        [payload.field]: payload.value,
      }
      if (payload.field === 'projectStatusKey') {
        newState.projectId = null
      }
      return newState
    },
    [resetFilters]: () => defaultState,
  },
  defaultState,
)

export { setFilters, setFilter, resetFilters, reducer }
