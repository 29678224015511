import { change } from 'redux-form';
import fetchProject from '../Services/fetchProject';
import { FORM_NAME } from '../Form';

export default (project) => async (dispatch) => {

  await dispatch(fetchProject());

  dispatch(change(FORM_NAME, 'project_id', project.id))
  
}