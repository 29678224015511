import { createAction, handleActions } from 'redux-actions'

const openProjectCellModal = createAction('SCREENS/PLANNER/OPEN_PROJECT_CELL_MODAL')
const closeProjectCellModal = createAction('SCREENS/PLANNER/CLOSE_PROJECT_CELL_MODAL')

const defaultState = {
  show: false,
  period: null,
  project: null,
  _jobs: [],
  _plannerCell: [],
}

const reducer = handleActions(
  {
    [openProjectCellModal]: (state, action) => ({
      show: true,
      period: action.payload.period,
      project: action.payload.project,
      _jobs: action.payload.jobs,
      _plannerCell: action.payload.plannerCell,
    }),
    [closeProjectCellModal]: () => defaultState,
  },
  defaultState,
)

export { openProjectCellModal, closeProjectCellModal, reducer }
