import selectListData from '../Components/Items/Selectors/selectListData'
import { setSubOrderGroups } from '../State/subOrder'

const getRows = (oldIndex, newIndex, state) => {

  const listData = selectListData(state)

  let nomRow
  let repRow

  listData.forEach((row, index) => {

    if (index === oldIndex) nomRow = row

    if (index === newIndex) repRow = row

  })

  return { nomRow, repRow }

}

export default (oldIndex, newIndex) => (dispatch, getState) => {

  const state = getState()

  const { nomRow, repRow } = getRows(oldIndex, newIndex, state)

  if (repRow.component === 'SubOrderFooter') return

  const { subOrder } = state.screens.SubOrder

  const newSubOrderGroups = []

  subOrder.sub_order_groups.forEach(subOrderGroup => {

    const newSubOrderItems = []

    if (repRow.component === 'SubOrderGroup' && subOrderGroup.guid === repRow.subOrderGroup.guid) {
      newSubOrderItems.push({ ...nomRow.subOrderItem })
    }

    subOrderGroup.sub_order_items.forEach(subOrderItem => {
      
      if (subOrderItem.guid === nomRow.subOrderItem.guid) return

      if (repRow.component === 'SubOrderItem' && subOrderItem.guid === repRow.subOrderItem.guid) {

        if (oldIndex < newIndex) { // Moved forward, thus replaced item is shifted backward
          newSubOrderItems.push({ ...subOrderItem })
        }

        newSubOrderItems.push({ ...nomRow.subOrderItem })

        if (oldIndex > newIndex) { // Moved backward, thus replaced item is shifted forward
          newSubOrderItems.push({ ...subOrderItem })
        }

        return

      }

      newSubOrderItems.push({ ...subOrderItem })

    })

    if (['SubOrderGroupFooter', 'SubOrderGroupPlaceholder'].includes(repRow.component) && subOrderGroup.guid === repRow.subOrderGroup.guid) {
      newSubOrderItems.push({ ...nomRow.subOrderItem })
    }

    newSubOrderGroups.push({
      ...subOrderGroup,
      sub_order_items: newSubOrderItems,
    })

  })

  dispatch(setSubOrderGroups(newSubOrderGroups))

}
