import { combineReducers } from "redux"

import { reducer as fetching } from "./State/fetching"
import { reducer as retentionPayments } from "./State/retentionPayments"
import { reducer as totals } from "./State/totals"
import { reducer as pagination } from "./State/pagination"
import { reducer as sorting } from "./State/sorting"
import { reducer as filters } from "./State/filters"
import { reducer as searchTerm } from "./State/searchTerm"
import { reducer as selectedRetentionPaymentId } from "./State/selectedRetentionPaymentId"
import { reducer as showRetentionPaymentModal } from "./State/showRetentionPaymentModal"

export default combineReducers({
  fetching,
  retentionPayments,
  totals,
  pagination,
  sorting,
  filters,
  searchTerm,
  selectedRetentionPaymentId,
  showRetentionPaymentModal,
})
