import { createAction, handleActions } from 'redux-actions';

const loadClientContactForEdit = createAction("SCREENS/CLIENT_VIEW/LOAD_CLIENT_CONTACT_FOR_EDIT");
const clearClientContactForEdit = createAction("SCREENS/CLIENT_VIEW/CLEAR_CLIENT_CONTACT_FOR_EDIT");

const defaultState = null;

const reducer = handleActions(
  {
    [loadClientContactForEdit]: (state, action) => action.payload,
    [clearClientContactForEdit]: () => null
  },
  defaultState
);

export { loadClientContactForEdit, clearClientContactForEdit, reducer };