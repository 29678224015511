// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';
import addEstimateItem from './addEstimateItem';
import type { EstimateGroup } from '../types';

export default () => (dispatch: Function, getState: Function) => {
  
  let estimate = fromJS(getState().screens.EstimateView.estimate);

  const estimateGroup: EstimateGroup = {
    id: null,
    estimate_id: estimate.get('id'),
    name: null,
    estimate_items: []
  }
  
  estimate = estimate.update('estimate_groups', (list) => list.push(estimateGroup));

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

  dispatch(addEstimateItem({
    estimateGroupIndex: (estimate.estimate_groups.length-1)
  }))

}