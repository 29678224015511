import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Drawer } from'antd'
import TimesheetModal from '../../Modals/Timesheet'
import TimesheetAdjustmentModal from '../../Modals/TimesheetAdjustment'

import Actions from './Components/Actions'
import Table from './Components/Table'

const TimesheetsDrawer = props => {

  const {
    title,
    subtitle,
    timesheetModal,
    timesheetAdjustmentModal,
    componentDidMount,
    componentWillUnmount,
    onCloseTimesheetModal,
    onCloseTimesheetAdjustmentModal,
    onClose,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  return (

    <Drawer
      title={(
        <div style={{ height: 24, display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <div>{title}</div>
            &nbsp;
            {!!subtitle && <div style={{ color: '#9e9e9e' }}>{subtitle}</div>}
          </div>
          <Actions />
        </div>
      )}
      visible
      height={400}
      placement='bottom'
      closable={false}
      onClose={onClose}
      bodyStyle={{ padding: 0 }}
    >
      
      <Table />

      {timesheetModal.show && (
        <TimesheetModal
          {...timesheetModal}
          didUpdate={onCloseTimesheetModal}
          onClose={onCloseTimesheetModal}
        />
      )}

      {timesheetAdjustmentModal.show && (
        <TimesheetAdjustmentModal
          {...timesheetAdjustmentModal}
          onClose={onCloseTimesheetAdjustmentModal}
        />
      )}

    </Drawer>

  )

}

TimesheetsDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  timesheetModal: PropTypes.object.isRequired,
  timesheetAdjustmentModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onCloseTimesheetModal: PropTypes.func.isRequired,
  onCloseTimesheetAdjustmentModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

TimesheetsDrawer.defaultProps = {
  subtitle: null,
}

export default TimesheetsDrawer
