// @flow

import * as React from 'react';
import classnames from 'classnames';
import { AutoSizer, List, Collection } from 'react-virtualized';
import styles from './List.css';
import Item from './Item';

type Props = {
  mode: 'list'|'grid',
  listItems: [],
  selectedItemPath: null|string,
  checkedItemPaths: [],
  onSelectItem: Function,
  onDoubleClickItem: Function,
  onToggleItemCheckbox: Function,
  onClickFileContextMenuItem: Function
}

type RenderItemParams = {
  index: number,
  key: any,
  style: {},
};

export default class extends React.PureComponent<Props> {

  componentDidUpdate = () => {
    if (this.List) this.List.forceUpdateGrid();
    if (this.Collection) this.Collection.recomputeCellSizesAndPositions();
  }

  onResize = () => {
    if (this.Collection) this.Collection.recomputeCellSizesAndPositions();
  }

  List: any;

  Collection: any;

  renderItem = (rowProps: RenderItemParams) => {

    const {
      index,
      key,
      style
    } = rowProps;

    const {
      mode,
      selectedItemPath,
      checkedItemPaths,
      listItems,
      onSelectItem,
      onDoubleClickItem,
      onToggleItemCheckbox,
      onClickFileContextMenuItem
    } = this.props;

    const item = listItems[index];

    const selected = item.path === selectedItemPath;

    const checked = checkedItemPaths.includes(item.path)

    return (
      <Item
        key={key}
        style={style}
        mode={mode}
        item={item}
        selected={selected}
        checked={checked}
        onSelectItem={onSelectItem}
        onDoubleClickItem={onDoubleClickItem}
        onToggleItemCheckbox={onToggleItemCheckbox}
        onClickFileContextMenuItem={onClickFileContextMenuItem}
      />
    )
  
  }

  render () {

    const {
      mode,
      listItems
    } = this.props;

    return (

      <div className={styles.wrapper}>

        {mode === 'list' && (
          
          <div className={styles.headings}>

            <div className={classnames(styles.col, styles.nameCol)}>
              Name
            </div>

            <div className={classnames(styles.col, styles.timestampCol)}>
              Last Modified
            </div>

          </div>

        )}

        <AutoSizer
          onResize={this.onResize}
        >
          {({ width, height }) => {
            
            if (mode === 'list') return (

              <List
                width={width}
                height={height}
                rowHeight={40}
                rowRenderer={this.renderItem}
                rowCount={listItems.length}
                ref={ref => { this.List = ref }}
              />

            )
            
            if (mode === 'grid') {
              
              const cellSizeAndPositionGetter = ({ index }) => {

                const colCount = Math.floor(width / 150);

                const cellWidth = width / colCount;
                const cellHeight = 150;

                const colNumber = (index % colCount);
                const cellX = cellWidth * colNumber;

                const rowNumber = Math.floor(index / colCount);
                const cellY = cellHeight * rowNumber;

                return {
                  width: cellWidth,
                  height: cellHeight,
                  x: cellX,
                  y: cellY
                }
              }

              return (
                <Collection
                  width={width}
                  height={height}
                  cellCount={listItems.length}
                  cellRenderer={this.renderItem}
                  cellSizeAndPositionGetter={cellSizeAndPositionGetter}
                  ref={ref => { this.Collection = ref }}
                />
              )
            }

          }}
        </AutoSizer>

      </div>
    )
  }
}