import { getProps } from '../props';

export default () => (dispatch, getState) => {

  const { selectedRowKeys } = getState().modals.SelectEstimateOptionals;
  
  const { onClose, didSelect } = getProps();

  didSelect(selectedRowKeys);
  onClose();

}