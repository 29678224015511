import { setSelectedEstimateItemIds } from '../../../State/selectedEstimateItemIds';
import removeEstimateItems from '../../../Services/removeEstimateItems';

export default () => async (dispatch, getState) => {

  const { selectedEstimateItemIds } = getState().screens.EstimateView;

  dispatch(removeEstimateItems(selectedEstimateItemIds));
  
  dispatch(setSelectedEstimateItemIds([]))

}