/* eslint-disable no-underscore-dangle */

import { initialize } from 'redux-form';
import moment from 'moment'
import { FORM_NAME } from '../Form';

export default () => async (dispatch) => {

  dispatch(initialize(FORM_NAME, {
    retentionItems: [
      {
        percentage: 50,
        due_date: moment().format('YYYY-MM-DD')
      },
      {
        percentage: 50,
        due_date: moment().add(12, 'months').format('YYYY-MM-DD')
      },
    ]
  }));

}