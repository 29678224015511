import { push } from 'connected-react-router'
import { getTeamUrl } from 'Common'
import { closeSubInvoiceModal } from '../State/subInvoiceModal'

export default (subInvoice) => (dispatch) => {

  dispatch(closeSubInvoiceModal())

  if (!subInvoice) return

  dispatch(push(getTeamUrl(`sub_invoices/${subInvoice.id}`)))

}
