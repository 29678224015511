import { fromJS } from 'immutable';
import { loadValuation } from '../State/valuation';

export type UpdateValuationItemAmountParams = {
  orderIndex: number,
  orderGroupIndex: number,
  orderItemIndex: number,
  value: string|null
}

export default (params) => (dispatch, getState) => {

  const { orderIndex, orderGroupIndex, orderItemIndex, value } = params;

  let valuation = fromJS(getState().screens.ValuationView.valuation);

  const orderItem = valuation.getIn(['orders',orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex]).toJS();

  const total = Number(orderItem.total);

  let aggregateTotal = Number(value);
  aggregateTotal = Number.isNaN(aggregateTotal) ? 0 : aggregateTotal; // reset to 0 if not a number
  if (total >= 0) aggregateTotal = aggregateTotal > Number(orderItem.total) ? Number(orderItem.total) : aggregateTotal; // dont go above order item total
  if (total >= 0) aggregateTotal = aggregateTotal < 0 ? 0 : aggregateTotal; // dont go below 0
  if (total < 0) aggregateTotal = aggregateTotal < Number(orderItem.total) ? Number(orderItem.total) : aggregateTotal; // dont go below order item total
  if (total < 0) aggregateTotal = aggregateTotal > 0 ? 0 : aggregateTotal; // dont go above 0
  aggregateTotal = Number(aggregateTotal.toFixed(2)); // round to 2 decimal spaces

  let completeTotal = aggregateTotal - Number(orderItem.valuation_item.previous_aggregate_total);
  completeTotal = Number(completeTotal.toFixed(2)); // round to 2 decimal spaces

  let aggregatePercentage = total !== 0 ? ((aggregateTotal / total) * 100) : 100;
  aggregatePercentage = Number(aggregatePercentage.toFixed(6)); // round to 6 decimal spaces

  let completePercentage = total !== 0 ? ((completeTotal / total) * 100) : 100;
  completePercentage = Number(completePercentage.toFixed(6)); // round to 6 decimal spaces

  valuation = valuation.updateIn(
    ['orders',orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex,'valuation_item'],
    map => map.merge(fromJS({
      aggregate_percentage: aggregatePercentage,
      aggregate_total: value,
      complete_percentage: completePercentage,
      complete_total: completeTotal.toFixed(2),
    }))
  );

  valuation = valuation.toJS();

  dispatch(loadValuation(valuation));

}