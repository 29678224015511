import { createAction, handleActions } from 'redux-actions';

const setAllFiles = createAction("MODALS/EMAIL_VALUATION/SET_ALL_FILES");
const resetAllFiles = createAction("MODALS/EMAIL_VALUATION/RESET_ALL_FILES");

const defaultState = [];

const reducer = handleActions(
  {
    [setAllFiles]: (state, action) => action.payload,
    [resetAllFiles]: () => defaultState,
  },
  defaultState
);

export { setAllFiles, resetAllFiles, reducer };