import { connect } from 'react-redux'
import SubInvoices from './SubInvoices'

import componentDidMount from './Handlers/componentDidMount'
import onCloseSubInvoiceModal from './Handlers/onCloseSubInvoiceModal'

const mapStateToProps = (state) => ({
  subInvoiceModal: state.screens.SubProject.SubInvoices.subInvoiceModal,
})

const actionCreators = {
  componentDidMount,
  onCloseSubInvoiceModal,
}

export default connect(mapStateToProps, actionCreators)(SubInvoices)
