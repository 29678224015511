import { createSelector } from 'reselect';
import { merge } from 'lodash';
import basePieChartConfig from '../Misc/basePieChartConfig';

const selectData = state => state.screens.Dashboard.servicesBreakdownReport.data;

export default createSelector(
  selectData,
  (data) => {

    const categories = [];
    const estimates = [];
    const orders = [];
    const valuations = [];

    if (data) {
        
      data.estimates.forEach(type => {
        categories.push(type.name);
        estimates.push({
          name: type.name,
          y: type.value
        });
      })

      data.orders.forEach(type => {
        orders.push({
          name: type.name,
          y: type.value
        });
      })

      data.valuations.forEach(type => {
        valuations.push({
          name: type.name,
          y: type.value
        });
      })

    }
    
    const configEstimates = merge({}, basePieChartConfig, {

      title: {
        text: 'Estimates',
        margin: 0,
        style: { 
          fontSize: 14
        }
      },

      xAxis: {
        categories
      },

      series: [
        {
          name: 'Estimates',
          data: estimates
        }
      ]

    });
    
    const configOrders = merge({}, basePieChartConfig, {

      title: {
        text: 'Orders',
        margin: 0,
        style: { 
          fontSize: 14
        }
      },

      xAxis: {
        categories
      },

      series: [
        {
          name: 'Orders',
          data: orders
        }
      ]

    });
    
    const configValuations = merge({}, basePieChartConfig, {

      title: {
        text: 'Valuations',
        margin: 0,
        style: { 
          fontSize: 14
        }
      },

      xAxis: {
        categories
      },

      series: [
        {
          name: 'Valuations',
          data: valuations
        }
      ]

    });

    return [ configEstimates, configOrders, configValuations ];

  }
)
