import immutable from 'immutable'
import { message } from 'antd'
import { loadInvoice } from '../State/invoice'
import { updateInvoice } from '../../../HttpRequests/invoices'
import handleException from '../../../Core/Helpers/handleException'

export default (field, value) => async (dispatch, getState) => {

  try {

    // update local state

    let invoice = immutable.fromJS(getState().screens.InvoiceView.invoice)

    invoice = invoice.set(field, value)

    invoice = invoice.toJS()

    dispatch(loadInvoice(invoice))

    // update on server

    const { id } = invoice

    const data = {
      [field]: value || null
    }

    await updateInvoice({ id, data })

    message.success('Invoice Updated')

  } catch (error) {

    handleException(error)

  }
}