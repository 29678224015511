// @flow

import { setProps } from '../props';

import fetchOrder from '../Services/fetchOrder';

import fetchServiceTypes from '../../../Core/Services/fetchServiceTypes';
import fetchUsers from '../../../Core/Services/fetchUsers';

export default (props: any) => async (dispatch: Function) => {

  setProps(props);

  dispatch(fetchOrder());

  dispatch(fetchServiceTypes());
  dispatch(fetchUsers());

}