import { createAction, handleActions } from 'redux-actions';

const loadCurrentDirectoryContents = createAction("SCREENS/PROJECT_VIEW/LOAD_CURRENT_DIRECTORY_CONTENTS");
const clearCurrentDirectoryContents = createAction("SCREENS/PROJECT_VIEW/CLEAR_CURRENT_DIRECTORY_CONTENTS");

const defaultState = {
  directories: [],
  files: []
}

const reducer = handleActions(
  {
    [loadCurrentDirectoryContents]: (state, action) => action.payload,
    [clearCurrentDirectoryContents]: () => Object.assign({}, defaultState)
  },
  defaultState
);

export { loadCurrentDirectoryContents, clearCurrentDirectoryContents, reducer };