import React from 'react'
import { Screen } from 'Evolve'
import { Alert } from 'antd'

export default props => (

  <Screen {...props}>

    {window.env.APP_ENV === 'staging' && (

      <Alert
        type='warning'
        showIcon
        closable
        message='You are using the staging site'
        style={{ marginBottom: 30 }}
      />

    )}

    {props.children}

  </Screen>

)
