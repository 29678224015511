import { initialize } from 'redux-form';

import { getCaveat } from '../../../HttpRequests/caveats';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { caveatId } = getProps();
  
  if (caveatId) {

    const response = await getCaveat({ id: caveatId });

    const caveat = response.data.data;

    dispatch(initialize(FORM_NAME, caveat))
    
  } else {

    dispatch(initialize(FORM_NAME, { }))

  }

}