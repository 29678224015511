import { createAction, handleActions } from 'redux-actions'

const setClientContacts = createAction('MODALS/RAMS_RECORD/SET_CLIENT_CONTACTS')
const addClientContact = createAction('MODALS/RAMS_RECORD/ADD_CLIENT_CONTACT')
const resetClientContacts = createAction('MODALS/RAMS_RECORD/RESET_CLIENT_CONTACTS')

const defaultState = []

const reducer = handleActions(
  {
    [setClientContacts]: (state, { payload }) => payload,
    [addClientContact]: (state, { payload }) => [...state, payload],
    [resetClientContacts]: () => defaultState,
  },
  defaultState,
)

export { setClientContacts, addClientContact, resetClientContacts, reducer }
