import { connect } from 'react-redux';
import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';
import componentWillUnmount from './Handlers/componentWillUnmount';

import onClickExportRateHistory from './Handlers/onClickExportRateHistory';
import onClickEditSubcontractor from './Handlers/onClickEditSubcontractor';
import onCloseSubcontractorModal from './Handlers/onCloseSubcontractorModal';
import didUpdateSubcontractor from './Handlers/didUpdateSubcontractor';
import didDeleteSubcontractor from './Handlers/didDeleteSubcontractor';

import onChangeActiveTab from './Handlers/onChangeActiveTab';

const mapStateToProps = (state) => ({
  users: state.core.users,
  subcontractor: state.screens.SubcontractorView.subcontractor,
  showSubcontractorModal: state.screens.SubcontractorView.showSubcontractorModal,
});

const actionCreators = {
  componentDidMount,
  onClickExportRateHistory,
  onClickEditSubcontractor,
  onCloseSubcontractorModal,
  onChangeActiveTab,
  didUpdateSubcontractor,
  didDeleteSubcontractor,
  componentWillUnmount
};

export default connect(mapStateToProps, actionCreators)(Component);