exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._25GLIhfXDEPwNNiq8eOTO {\n  width: 280px;\n}\n\n._1VRlymCtHT55kG1DFErbzn {\n  margin-bottom: 12px;\n}\n\n._1VRlymCtHT55kG1DFErbzn:last-child {\n  margin-bottom: 4px;\n}\n\n._3RWsWrBCCMGh8_0EJA60rU {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Insights/Components/Filters.css"],"names":[],"mappings":"AAAA;EACE,aAAa;CACd;;AAED;EACE,oBAAoB;CACrB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;CAClB","file":"Filters.css","sourcesContent":[".popover {\n  width: 280px;\n}\n\n.box {\n  margin-bottom: 12px;\n}\n\n.box:last-child {\n  margin-bottom: 4px;\n}\n\n.label {\n  display: block;\n  margin-bottom: 4px;\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"popover": "_25GLIhfXDEPwNNiq8eOTO",
	"box": "_1VRlymCtHT55kG1DFErbzn",
	"label": "_3RWsWrBCCMGh8_0EJA60rU"
};