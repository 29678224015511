import React from 'react'
import { createSelector } from 'reselect'
import { CheckCircleFilled } from '@ant-design/icons'
import moment from 'moment'

const selectSubValuation = state => state.screens.SubValuation.subValuation

export default createSelector(
  selectSubValuation,
  (subValuation) => {

    const statusLogs = []

    if (subValuation.approved_at) {
      statusLogs.push({
        key: 'approved_at',
        label: 'Approved',
        value: (
          <React.Fragment>
            <CheckCircleFilled style={{ fontSize: 15, color: 'orange' }} />
            {' '}
            {`${moment(subValuation.approved_at).format('DD MMM YYYY')} by ${subValuation.approved_by.full_name}`}
          </React.Fragment>
        ),
      })
    }

    if (subValuation.paid_at) {
      statusLogs.push({
        key: 'paid_at',
        label: 'Paid',
        value: (
          <React.Fragment>
            <CheckCircleFilled style={{ fontSize: 15, color: 'limegreen' }} />
            {' '}
            {`${moment(subValuation.paid_at).format('DD MMM YYYY')} by ${subValuation.paid_by.full_name}`}
          </React.Fragment>
        ),
      })
    }
    
    return statusLogs

  },
)
