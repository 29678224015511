import { createAction, handleActions } from 'redux-actions'
import { get } from 'lodash'

const openEditorModal = createAction('MODULES/FILE_MANAGER/OPEN_EDITOR_MODAL')
const closeEditorModal = createAction('MODULES/FILE_MANAGER/CLOSE_EDITOR_MODAL')

const defaultState = {
  show: false,
  newDirectory: false,
}

const reducer = handleActions(
  {
    [openEditorModal]: (state, action) => ({
      ...state,
      show: true,
      newDirectory: get(action.payload, 'newDirectory', false),
    }),
    [closeEditorModal]: () => defaultState,
  },
  defaultState,
)

export { openEditorModal, closeEditorModal, reducer }
