import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchGlobalContacts from '../../../Core/Services/fetchGlobalContacts';

export default (props: any) => async (dispatch) => {

  setProps(props);
  
  await dispatch(fetchGlobalContacts());

  dispatch(initialize());
  
}