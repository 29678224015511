import React from 'react'
import { Field } from 'redux-form'
import { InputField } from 'FormFields'

const labelColSpan = 6
const wrapperColSpan = 14

type Props = {
  submitting: boolean,
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      submitting,
    } = this.props

    return (

      <div>

        <Field
          component={InputField}
          name='password'
          label='Password'
          type='password'
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        <Field
          component={InputField}
          name='password_confirm'
          label='Confirm'
          type='password'
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

      </div>

    )

  }

}
