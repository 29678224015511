import { message } from 'antd'
import _props from '../props'
import { invoiceValuation } from '../../../HttpRequests/valuations'
import handleException from '../../../Core/Helpers/handleException'

export default (values) => async () => {
  
  try {

    const { id, onClose, didCreate } = _props.get()

    const data = values;

    const response = await invoiceValuation({ id, data })

    const invoice = response.data.data

    message.success("Invoice Created")

    onClose()

    if (didCreate) didCreate(invoice)

  } catch (error) {
    
    handleException(error)

  }
}