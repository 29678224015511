// @flow

import { getValuation } from '../../../HttpRequests/valuations';
import { loadValuation } from '../State/valuation';
import { setFetching } from '../State/fetching';
import { getProps } from '../props';
import prepareValuationItemPercentages from './prepareValuationItemPercentages';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch: Function) => {
  
  try {
    
    const { id } = getProps().match.params;

    const params = {};
    
    params.include = [
      'project',
      'project.client_site',
      'project.client_site.client',
      'client_contact',
      'user',
      'project.valuations',
      'active_invoices',
    ].join();

    params.append = [
      'orders',
      'full_reference',
      'action_statuses',
      'updatable_fields',
    ].join();

    dispatch(setFetching(true));

    const response = await getValuation({ id, params });

    const valuation = response.data.data;

    dispatch(loadValuation(valuation));

    dispatch(prepareValuationItemPercentages());

    dispatch(setFetching(false));

  } catch (error) {

    handleException(error);

  }
}
