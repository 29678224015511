import { combineReducers } from 'redux';

import { reducer as estimateStatuses } from './State/estimateStatuses';
import { reducer as estimateTenderStatuses } from './State/estimateTenderStatuses';
import { reducer as estimateLatestActions } from './State/estimateLatestActions';
import { reducer as siteVisitStatuses } from './State/siteVisitStatuses';
import { reducer as projectPhases } from './State/projectPhases';
import { reducer as projectStatuses } from './State/projectStatuses';
import { reducer as serviceTypes } from './State/serviceTypes';
import { reducer as users } from './State/users';
import { reducer as clients } from './State/clients';
import { reducer as subcontractors } from './State/subcontractors';
import { reducer as clientContacts } from './State/clientContacts';
import { reducer as projects } from './State/projects';
import { reducer as valuationStatuses } from './State/valuationStatuses';
import { reducer as subOrders } from './State/subOrders';
import { reducer as globalContacts } from './State/globalContacts';
import { reducer as caveats } from './State/caveats';
import { reducer as clientRiskLevels } from './State/clientRiskLevels';
import { reducer as itemDescriptions } from './State/itemDescriptions';

export default combineReducers({
  estimateStatuses,
  estimateTenderStatuses,
  estimateLatestActions,
  siteVisitStatuses,
  projectPhases,
  projectStatuses,
  serviceTypes,
  users,
  clients,
  subcontractors,
  clientContacts,
  projects,
  valuationStatuses,
  subOrders,
  globalContacts,
  caveats,
  clientRiskLevels,
  itemDescriptions
})
