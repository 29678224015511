// @flow

import { fromJS } from 'immutable';
import { arrayMove } from 'react-sortable-hoc';
import { assign } from 'lodash';
import selectItemsListData from '../Selectors/selectItemsListData';
import { loadOrder } from '../State/order';

export type MoveOrderItemParams = {
  oldIndex: number,
  newIndex: number
}

export default (params: MoveOrderItemParams) => (dispatch: Function, getState: Function) => {


  // get immutable copy of the order
  let order = fromJS(getState().screens.OrderView.order);

  // select the mapped list items
  let listData = selectItemsListData(getState());



  // dont't carry on if index hasnt moved
  if (params.oldIndex === params.newIndex) return;

  // don't allow it to be dropped at the beginning (before the first header)
  if (params.newIndex === 0) return;

  // don't allow it to be dropped at the end (after the last footer)
  if (params.newIndex === (listData.length-1)) return;
  


  // get the old orderItem, orderGroupIndex, orderItemIndex
  const {
    orderItem: oldOrderItem,
    orderGroupIndex: oldOrderGroupIndex,
    orderItemIndex: oldOrderItemIndex
  } = listData[params.oldIndex];

  // prepare the new orderItem, orderGroupIndex, orderItemIndex

  listData = arrayMove(listData, params.oldIndex, params.newIndex);

  const listItemBefore = listData[params.newIndex - 1];

  if (listItemBefore.type === 'GroupFooter') return; // don't allow it to be dropped between footer & header

  const newOrderGroupIndex = listItemBefore.orderGroupIndex
  const newOrderItemIndex = listItemBefore.orderItem ? (listItemBefore.orderItemIndex + 1) : 0;
  const newOrderItem = assign({}, oldOrderItem, { order_group_id: listItemBefore.orderGroup.id })



  // make the changes

  if (oldOrderItemIndex < newOrderItemIndex) {

    // insert the orderItem in the new position
    order = order.updateIn(
      ['order_groups', newOrderGroupIndex, 'order_items'],
      (list) => list.insert(newOrderItemIndex, newOrderItem)
    );

    // remove the orderItem from the old position
    order = order.removeIn(['order_groups', oldOrderGroupIndex, 'order_items', oldOrderItemIndex]);

  } else {

    // remove the orderItem from the old position
    order = order.removeIn(['order_groups', oldOrderGroupIndex, 'order_items', oldOrderItemIndex]);

    // insert the orderItem in the new position
    order = order.updateIn(
      ['order_groups', newOrderGroupIndex, 'order_items'],
      (list) => list.insert(newOrderItemIndex, newOrderItem)
    );

  }


  
  // save
  
  order = order.toJS();
  
  dispatch(loadOrder(order));

}