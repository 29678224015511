import * as React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import styles from './Component.css'

export default class _Footer_ extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool,
    onClickCancel: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitting: null,
  }

  render () {

    const {
      submitting,
      onClickCancel,
      onClickSubmit,
    } = this.props

    return (
        
      <div className={styles.footer}>
      
        <Button
          type="default"
          disabled={submitting}
          onClick={onClickCancel}
          style={{ width: 80 }}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          loading={submitting}
          onClick={onClickSubmit}
          style={{ width: 80 }}
        >
          Save
        </Button>

      </div>

    )
  }
}