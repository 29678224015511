import { message } from 'antd'
import { renameDirectory } from '../../../../../HttpRequests/modelFiles'
import { resetSelectedItemPath } from '../../../State/selectedItemPath'
import modalProps from '../modalProps'
import handleException from '../../../../../Core/Helpers/handleException'

export default (values) => async (dispatch, getState) => {

  const { currentDirectoryPath } = getState().modules.FileManager
  
  const data = {
    current_path: `${currentDirectoryPath}/${values.current}`,
    new_path: `${currentDirectoryPath}/${values.name}`,
  }

  try {

    await renameDirectory({ data })

    message.success('Folder Renamed')

    dispatch(resetSelectedItemPath())

    modalProps().onClose(true)

  } catch (error) {

    handleException(error)

  }

}
