exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2i43Wu3tUtxbTpf4BUu-fl {\n  height: 30px;\n  padding: 5px;\n  padding-left: 8px;\n  padding-right: 8px;\n  line-height: 20px;\n  background-color: #C60302;\n  color: #FFF;\n  font-size: 14px;\n  font-weight: 600;\n  border-radius: 5px;\n  overflow: hidden;\n  display: inline-block;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Orders/Components/Table.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,0BAA0B;EAC1B,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,iBAAiB;EACjB,sBAAsB;CACvB","file":"Table.css","sourcesContent":[".offTarget {\n  height: 30px;\n  padding: 5px;\n  padding-left: 8px;\n  padding-right: 8px;\n  line-height: 20px;\n  background-color: #C60302;\n  color: #FFF;\n  font-size: 14px;\n  font-weight: 600;\n  border-radius: 5px;\n  overflow: hidden;\n  display: inline-block;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"offTarget": "_2i43Wu3tUtxbTpf4BUu-fl"
};