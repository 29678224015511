// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';

export type RecalculateEstimateOptionalParams = {
  estimateOptionalIndex: number
}

export default (params: RecalculateEstimateOptionalParams) => (dispatch: Function, getState: Function) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);

  let { quantity, rate } = estimate.getIn(['estimate_optionals', params.estimateOptionalIndex]).toJS();
  const discountPercentage = estimate.get('discount_percentage');

  quantity = Number.isNaN(Number(quantity)) ? 0 :  Number(quantity);
  rate = Number.isNaN(Number(rate)) ? 0 :  Number(rate);

  let subtotal = quantity * rate;
  let discount = (subtotal / 100) * Number(discountPercentage);
  let total = subtotal - discount;

  subtotal = subtotal.toFixed(2);
  discount = discount.toFixed(2);
  total = total.toFixed(2);

  estimate = estimate.setIn(['estimate_optionals', params.estimateOptionalIndex, 'subtotal'], subtotal);
  estimate = estimate.setIn(['estimate_optionals', params.estimateOptionalIndex, 'discount'], discount);
  estimate = estimate.setIn(['estimate_optionals', params.estimateOptionalIndex, 'total'], total);

  estimate = estimate.toJS();

  dispatch(loadEstimate(estimate));

}