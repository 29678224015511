import { combineReducers } from 'redux'

import { reducer as timesheets } from './State/timesheets'
import { reducer as fetching } from './State/fetching'
import { reducer as searchTerm } from './State/searchTerm'
import { reducer as filters } from './State/filters'
import { reducer as sorting } from './State/sorting'
import { reducer as pagination } from './State/pagination'
import { reducer as timesheetModal } from './State/timesheetModal'
import { reducer as timesheetAdjustmentModal } from './State/timesheetAdjustmentModal'

export default combineReducers({
  timesheets,
  fetching,
  searchTerm,
  filters,
  sorting,
  pagination,
  timesheetModal,
  timesheetAdjustmentModal,
})
