import * as React from 'react';
import { find } from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import { CheckOutlined, CloseOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Row, Col, Button, Card, Avatar, Tooltip, Tag, Dropdown, Menu } from 'antd';
import { expiries, getExpiryColor } from '../../../Config/expiries';

const Spacer = () => <div style={{ width: 12 }} />

const styles = {

  detailWrapper: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 14,
    color: '#444',
    paddingBottom: 10,
    paddingTop: 10,
  },

  detailLabel: {
    width: 150,
    fontWeight: '500',
    textAlign: 'right',
    paddingRight: 15
  },

  detailData: {
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.65)'
  }

}

type Props = {
  users: [],
  subcontractor: Object,
  onClickExportRateHistory: Function,
  onClickEditSubcontractor: Function,
}

export default class extends React.PureComponent<Props> {

  render () {

    const { users, subcontractor, onClickExportRateHistory, onClickEditSubcontractor } = this.props;

    const getTrackedColumnStatus = (trackedColumn) => {
      const trackedColumnLastSetAt = subcontractor.tracked_columns[`${trackedColumn}_last_set_at`]
      const trackedColumnLastSetByUserId = subcontractor.tracked_columns[`${trackedColumn}_last_set_by_user_id`]
      if (!trackedColumnLastSetAt || !trackedColumnLastSetByUserId) return ''
      const trackedColumnLastSetByUser = find(users, row => row.id === trackedColumnLastSetByUserId)
      if (!trackedColumnLastSetByUser) return ''
      const { first_name: firstName, last_name: lastName } = trackedColumnLastSetByUser
      return `Set by ${firstName} ${lastName} on ${moment(trackedColumnLastSetAt).format('DD/MM/YYYY')}`
    }

    return (
      <Row style={{ marginBottom: 20 }}>

        <Col span={12}>
        
          <h1>
            {subcontractor.name}
          </h1>

        </Col>

        <Col span={12}>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

            <Dropdown
              overlay={(
                <Menu onClick={onClickExportRateHistory}>
                  <Menu.Item key='xlsx'>Rate History</Menu.Item>
                </Menu>
              )}
            >
              <Button>
                <CloudDownloadOutlined style={{ fontSize: 16, marginTop: 3 }} />
              </Button>
            </Dropdown>

            <Spacer />

            <Button type="default" onClick={onClickEditSubcontractor}>
              Edit Subcontractor
            </Button>

          </div>

        </Col>

        <Col span={24}>

          <Card>
            
            <Row>
              
              <Col span={14}>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Name :
                  </div>

                  <div style={styles.detailData}>
                    {subcontractor.name}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Marquee ID :
                  </div>

                  <div style={styles.detailData}>
                    {subcontractor.group || '-'}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Type :
                  </div>

                  <div style={styles.detailData}>
                    {subcontractor.type || '-'}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Telephone :
                  </div>

                  <div style={styles.detailData}>
                    {subcontractor.telephone || '-'}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Email :
                  </div>

                  <div style={styles.detailData}>
                    {subcontractor.email || '-'}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    DOB :
                  </div>

                  <div style={styles.detailData}>
                    {subcontractor.date_of_birth ? moment(subcontractor.date_of_birth).format('DD/MM/YYYY') : '-'}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Team :
                  </div>

                  <div style={styles.detailData}>
                    {subcontractor.is_multiple ? <CheckOutlined /> : <CloseOutlined />}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Ltd Company :
                  </div>

                  <div style={styles.detailData}>
                    {subcontractor.is_ltd_company ? <CheckOutlined /> : <CloseOutlined />}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Drives :
                  </div>

                  <div style={styles.detailData}>
                    <span>
                      {subcontractor.drives ? <CheckOutlined /> : <CloseOutlined />}
                      <span style={{ color: '#999', fontStyle: 'italic', marginLeft: 10 }}>
                        {` ${getTrackedColumnStatus('drives')}`}
                      </span>
                    </span>
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Company Vehicle :
                  </div>

                  <div style={styles.detailData}>
                    <span>
                      {subcontractor.co_vehicle_reg ? <CheckOutlined /> : <CloseOutlined />}
                      {!!subcontractor.co_vehicle_reg && <span style={{ marginLeft: 10 }}>{subcontractor.co_vehicle_reg}</span>}
                      <span style={{ color: '#999', fontStyle: 'italic', marginLeft: 10 }}>
                        {` ${getTrackedColumnStatus('co_vehicle_reg')}`}
                      </span>
                    </span>
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Address :
                  </div>

                  <div style={styles.detailData}>
                    {[
                      subcontractor.address_line_1,
                      subcontractor.address_line_2,
                      subcontractor.address_line_3,
                      subcontractor.town_or_city,
                      subcontractor.county,
                      subcontractor.postcode
                    ].filter(line => line).join(', ') || '-'}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    RTW :
                  </div>

                  <div style={styles.detailData}>
                    <span>
                      {subcontractor.right_to_work_proof ? <CheckOutlined /> : <CloseOutlined />}
                      {!!subcontractor.right_to_work_proof && <span style={{ marginLeft: 10 }}>{subcontractor.right_to_work_proof}</span>}
                      <span style={{ color: '#999', fontStyle: 'italic', marginLeft: 10 }}>
                        {` ${getTrackedColumnStatus('right_to_work_proof')}`}
                      </span>
                    </span>
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Status :
                  </div>

                  <div style={styles.detailData}>
                    {subcontractor.deactivated_at ? 'Deactivated' : 'Active'}
                    {!!subcontractor.deactivated_at && (
                      <span style={{ color: '#999', fontStyle: 'italic', marginLeft: 10 }}>
                        {moment(subcontractor.deactivated_at).fromNow()}
                      </span>
                    )}
                  </div>

                </div>

              </Col>

              <Col span={10}>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Hourly Rate :
                  </div>

                  <div style={styles.detailData}>
                    <span>
                      {subcontractor.hourly_rate ? `£${numeral(subcontractor.hourly_rate).format('0,0.00')}` : '-'}
                      <span style={{ color: '#999', fontStyle: 'italic', marginLeft: 10 }}>
                        {` ${getTrackedColumnStatus('hourly_rate')}`}
                      </span>
                    </span>
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Day Rate :
                  </div>

                  <div style={styles.detailData}>
                    <span>
                      {subcontractor.day_rate ? `£${numeral(subcontractor.day_rate).format('0,0.00')}` : '-'}
                      <span style={{ color: '#999', fontStyle: 'italic', marginLeft: 10 }}>
                        {` ${getTrackedColumnStatus('day_rate')}`}
                      </span>
                    </span>
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Card Type :
                  </div>

                  <div style={styles.detailData}>
                    <span>
                      <Tag
                        color={subcontractor.card_type !== 'White' && subcontractor.card_type}
                      >
                        {subcontractor.card_type}
                      </Tag>
                      <span style={{ color: '#999', fontStyle: 'italic', marginLeft: 10 }}>
                        {` ${getTrackedColumnStatus('card_type')}`}
                      </span>
                    </span>
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Liability Insurance :
                  </div>

                  <div style={styles.detailData}>
                    {subcontractor.liability_insurance !== null ? `£${numeral(subcontractor.liability_insurance).format('0,0.00')}` : '-'}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Supervisor :
                  </div>

                  <div style={styles.detailData}>
                    {subcontractor.is_supervisor ? <CheckOutlined /> : <CloseOutlined />}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={styles.detailLabel}>
                    Trainee :
                  </div>

                  <div style={styles.detailData}>
                    {subcontractor.is_trainee ? <CheckOutlined /> : <CloseOutlined />}
                  </div>

                </div>

                <div style={styles.detailWrapper}>

                  <div style={{ ...styles.detailLabel, marginTop: 5 }}>
                    Expiries :
                  </div>

                  <div style={styles.detailData}>
                    {Object.keys(expiries).map(key => (
                      <Tooltip
                        key={key}
                        title={(
                          <div>
                            <div>{expiries[key].title}</div>
                            {!!subcontractor[key] && `${moment(subcontractor[key]).format('DD/MM/YYYY')} - ${moment(subcontractor[key]).fromNow()}`}
                          </div>
                        )}
                      >
                        <Avatar
                          style={{
                            backgroundColor: getExpiryColor(subcontractor[key]),
                            marginLeft: 2,
                            marginRight: 2,
                          }}
                        >
                          {expiries[key].abbrev}
                        </Avatar>
                      </Tooltip>
                    ))}
                  </div>

                </div>

              </Col>

            </Row>

          </Card>

        </Col>

      </Row>
    );

  }
}