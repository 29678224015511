import axios from './axios'

export const getPlanner = ({ params } = {}) => axios.get('planner', { params })
export const getPlannerPeriodSubcontractors = ({ params } = {}) => axios.get('planner/period_subcontractors', { params })
export const getPlannerPeriodRangeSubcontractors = ({ params } = {}) => axios.get('planner/period_range_subcontractors', { params })

export const updatePlannerProjectCell = ({ data } = {}) => axios.put('planner/project_cell', data)
export const updatePlannerSubcontractorCell = ({ data } = {}) => axios.put('planner/subcontractor_cell', data)

export const omit = ({ projectId } = {}) => axios.post(`planner/${projectId}`)
export const unomit = ({ projectId } = {}) => axios.delete(`planner/${projectId}`)
