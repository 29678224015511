// @flow

import * as React from 'react';
import classnames from 'classnames';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';

import {
  CaretDownOutlined,
  CopyOutlined,
  DeleteOutlined,
  DragOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { Menu, Dropdown } from 'antd';
import styles from './Item.css';

import type {
  OrderCaveat,
  UpdateOrderCaveatFieldParams,
  AddOrderCaveatParams,
  RemoveOrderCaveatParams,
  DuplicateOrderCaveatParams
} from '../../../types';

type Props = {
  editable: boolean,
  orderCaveatIndex: number,
  orderCaveat: OrderCaveat,
  onChangeOrderCaveatField: (params: UpdateOrderCaveatFieldParams) => void,
  onClickAddOrderCaveat: (params: AddOrderCaveatParams) => void,
  onClickRemoveOrderCaveat: (params: RemoveOrderCaveatParams) => void,
  onClickDuplicateOrderCaveat: (params: DuplicateOrderCaveatParams) => void,
}

type State = {
  hoveredDragArea: boolean,
  hoveredActionArea: boolean
}

class Component extends React.PureComponent<Props,State> {

  state = {
    hoveredDragArea: false,
    hoveredActionArea: false
  }

  onChangeOrderCaveatField = async (field: string, value: string) => {

    const {
      orderCaveatIndex,
      onChangeOrderCaveatField
    } = this.props;
    
    await onChangeOrderCaveatField({
      orderCaveatIndex,
      field,
      value
    })
  }

  onClickMenu = ({ key }: any) => {

    const {
      orderCaveatIndex,
      onClickRemoveOrderCaveat,
      onClickAddOrderCaveat,
      onClickDuplicateOrderCaveat
    } = this.props;

    if (key === 'remove') onClickRemoveOrderCaveat({ orderCaveatIndex });
    
    if (key === 'add') onClickAddOrderCaveat({ orderCaveatIndex });

    if (key === 'duplicate') onClickDuplicateOrderCaveat({ orderCaveatIndex });

  }

  render () {

    const {
      editable,
      orderCaveat
    } = this.props;

    const DragArea = SortableHandle(props => (
      <span {...props}>
        {props.children}
      </span>
    ));

    const ActionMenu = (

      <Menu onClick={this.onClickMenu}>

        <Menu.Item key="add">
          <PlusOutlined />
          {` Add`}
        </Menu.Item>

        <Menu.Item key="duplicate">
          <CopyOutlined />
          {` Duplicate`}
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="remove">
          <DeleteOutlined />
          {` Remove`}
        </Menu.Item>

      </Menu>

    )

    const showLineNumber = (!editable || !this.state.hoveredDragArea);

    const showDragIcon = editable && this.state.hoveredDragArea;

    return (
      <div className={styles.wrapper}>

        <DragArea
          className={styles.dragArea}
          style={{ cursor: editable ? 'grab' : 'default' }}
          onMouseEnter={() => editable && this.setState({ hoveredDragArea: true })}
          onMouseLeave={() => this.setState({ hoveredDragArea: false })}
        >

          {showLineNumber && (
            <div className={styles.lineNumber}>
              {orderCaveat.line_number}
            </div>
          )}

          {showDragIcon && (
            <DragOutlined className={styles.dragIcon} />
          )}

        </DragArea>

        <div className={styles.table}>

          <div className={classnames(styles.cell, styles.flexCell)}>
            <textarea
              className={styles.textarea}
              value={orderCaveat.content || ''}
              onChange={event => this.onChangeOrderCaveatField('content', event.target.value)}
              disabled={!editable}
            />
          </div>

        </div>
      
        <div
          className={classnames(styles.actionArea, { [styles.actionAreaHovered]: this.state.hoveredActionArea })}
          onMouseEnter={() => this.setState({ hoveredActionArea: true })}
          onMouseLeave={() => this.setState({ hoveredActionArea: false })}
        >

          {editable && (
            <Dropdown overlay={ActionMenu} trigger={['click']}>
              <CaretDownOutlined className={styles.actionButton} />
            </Dropdown>
          )}
          
        </div>

      </div>
    );
  }
}

export default SortableElement(Component);