import { first } from 'lodash';
import { setActiveItemInputKeys } from '../../../State/activeItemInputKeys';

export default () => async (dispatch, getState) => {

  const { valuation, activeItemInputKeys } = getState().screens.ValuationView;

  const valuationItemIds = [];

  valuation.orders.forEach((order) =>
    order.order_groups.forEach((orderGroup) =>
      orderGroup.order_items.forEach((orderItem) => 
        valuationItemIds.push(orderItem.valuation_item.id)
      )
    )
  )

  if (!activeItemInputKeys) return;

  if (activeItemInputKeys.input === 'percentage') {

    dispatch(setActiveItemInputKeys({
      valuationItemId: activeItemInputKeys.valuationItemId,
      input: 'amount'
    }));

  } else {

    const currentIndex = valuationItemIds.indexOf(activeItemInputKeys.valuationItemId);

    let valuationItemId = null;

    if (currentIndex === (valuationItemIds.length-1)) {
      valuationItemId = first(valuationItemIds);
    } else {
      valuationItemId = valuationItemIds[currentIndex+1];
    }

    dispatch(setActiveItemInputKeys({
      valuationItemId,
      input: 'percentage'
    }));

  }
  

}