import { setSubValuationItems } from '../State/subValuation'

const modValues = (field, value, subValuationItem) => {

  const { sub_order_item: subOrderItem } = subValuationItem

  const values = {}

  if (field === 'aggregate_perc') {
    values.complete_perc = subValuationItem.complete_perc + (value - subValuationItem.aggregate_perc)
    values.complete_total = (subOrderItem.total / 100) * values.complete_perc
    values.aggregate_total = (subOrderItem.total / 100) * value
  }

  if (field === 'aggregate_total') {
    values.complete_total = subValuationItem.complete_total + (value - subValuationItem.aggregate_total)
    values.complete_perc = (values.complete_total / subOrderItem.total) * 100
    values.aggregate_perc = (value / subOrderItem.total) * 100
  }

  return values

}

export default (guid, field, value) => (dispatch, getState) => {

  const { subValuation } = getState().screens.SubValuation

  const newSubValuationItems = []

  subValuation.sub_valuation_items.forEach(subValuationItem => {

    if (subValuationItem.guid !== guid) {
      newSubValuationItems.push({ ...subValuationItem })
    } else {
      newSubValuationItems.push({
        ...subValuationItem,
        [field]: value,
        ...modValues(field, value, subValuationItem),
      })
    }

  })

  dispatch(setSubValuationItems(newSubValuationItems))

}
