const defaultSortMap = {
  projects: 'subcontractor.asc',
  summary: 'name.asc',
}

export default (state) => {

  const { search, filters, mode, sortBy } = state.screens.Subbies.SubProjects

  const params = {
    search,
    status_key: filters.statusKey !== 'all' ? filters.statusKey : undefined,
    sort_by: sortBy || defaultSortMap[mode],
  }

  if (filters.subcontractorIds.length) params.subcontractor_ids = filters.subcontractorIds.join(',')
  if (filters.projectIds.length) params.project_ids = filters.projectIds.join(',')
  if (filters.contractsManagerIds.length) params.contracts_manager_ids = filters.contractsManagerIds.join(',')
  if (filters.completionStatuses.length) params.completion_statuses = filters.completionStatuses.join(',')
  if (filters.hideComplete) params.hide_complete = 1

  return params

}
