import { connect } from 'react-redux';

import Component from './Component';

const mapStateToProps = (state) => ({
  serviceTypes: state.core.serviceTypes
});

const actionCreators = {};

export default connect(mapStateToProps, actionCreators)(Component);