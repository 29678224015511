import { getProps } from '../props';

export default async (values) => {
  
  const { onClose, didUpdate } = getProps();
  
  if (didUpdate) didUpdate(values.valuation_completion);
  
  onClose();

}