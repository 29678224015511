import { connect } from 'react-redux';
import Component from './Component';

import selectConfigForYearOverviewReport from './Selectors/selectConfigForYearOverviewReport';
import selectConfigForEstimatesByClientReport from './Selectors/selectConfigForEstimatesByClientReport';
import selectConfigForLastThreeMonthsReport from './Selectors/selectConfigForLastThreeMonthsReport';
import selectConfigForTopClientsReport from './Selectors/selectConfigForTopClientsReport';
import selectConfigForServicesBreakdownReport from './Selectors/selectConfigForServicesBreakdownReport';
import selectNewProjectsWithOrderReport from './Selectors/selectNewProjectsWithOrderReport';

import componentWillMount from './Handlers/componentWillMount';
import onChangeEstimatesByClientReportFilter from './Handlers/onChangeEstimatesByClientReportFilter';
import onChangeMode from './Handlers/onChangeMode';
import onChangeClientId from './Handlers/onChangeClientId';
import onChangeDateRange from './Handlers/onChangeDateRange';
import onChangeDays from './Handlers/onChangeDays';

import onCloseExportStatisticsModal from './Handlers/onCloseExportStatisticsModal';
import onCloseExportHistoryModal from './Handlers/onCloseExportHistoryModal';

const mapStateToProps = (state) => ({
  configForYearOverviewReport: selectConfigForYearOverviewReport(state),
  configForEstimatesByClientReport: selectConfigForEstimatesByClientReport(state),
  configForLastThreeMonthsReport: selectConfigForLastThreeMonthsReport(state),
  configForTopClientsReport: selectConfigForTopClientsReport(state),
  configForServicesBreakdownReport: selectConfigForServicesBreakdownReport(state),
  newProjectsWithOrderReport: selectNewProjectsWithOrderReport(state),
  estimatesByClientReportType: state.screens.Dashboard.estimatesByClientReport.type,
  estimatesByClientReportDateRange: state.screens.Dashboard.estimatesByClientReport.dateRange,
  clients: state.core.clients,
  mode: state.screens.Dashboard.yearOverviewReport.mode,
  clientId: state.screens.Dashboard.lastThreeMonthsReport.clientId,
  dateRange: state.screens.Dashboard.servicesBreakdownReport.dateRange,
  days: state.screens.Dashboard.newProjectsWithOrderReport.days,
  showExportStatisticsModal: state.screens.Dashboard.showExportStatisticsModal,
  showExportHistoryModal: state.screens.Dashboard.showExportHistoryModal,
})

const actionCreators = {
  componentWillMount,
  onChangeEstimatesByClientReportFilter,
  onChangeMode,
  onChangeClientId,
  onChangeDateRange,
  onChangeDays,
  onCloseExportStatisticsModal,
  onCloseExportHistoryModal,
}

export default connect(mapStateToProps, actionCreators)(Component);