import { message } from 'antd'
import { createTimesheetPosting } from '../../../HttpRequests/timesheetPostings'

export default (values) => async () => {

  const data = {
    timesheet_id: values.timesheet_id,
    project_id: values.project_id,
    start_time: values.start_time,
    end_time: values.end_time,
  }

  const response = await createTimesheetPosting({ data })

  const timesheetPosting = response.data

  message.success('Posting Created')

  return timesheetPosting

}
