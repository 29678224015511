import { createAction, handleActions } from 'redux-actions';

const setSelectedItemType = createAction("SCREENS/PROJECT_VIEW/SET_SELECTED_ITEM_TYPE");
const clearSelectedItemType = createAction("SCREENS/PROJECT_VIEW/CLEAR_SELECTED_ITEM_TYPE");

const defaultState = null;

const reducer = handleActions(
  {
    [setSelectedItemType]: (state, action) => action.payload,
    [clearSelectedItemType]: () => null
  },
  defaultState
);

export { setSelectedItemType, clearSelectedItemType, reducer };