import axios from './axios'

export const createSubOrder = ({ data }) => axios.post('sub_orders', data)
export const deleteSubOrder = ({ id }) => axios.delete(`sub_orders/${id}`)
export const findSubOrder = ({ id, params } = {}) => axios.get(`sub_orders/${id}`, { params })
export const getSubOrders = ({ params } = {}) => axios.get('sub_orders', { params })
export const updateSubOrder = ({ id, data }) => axios.put(`sub_orders/${id}`, data)
export const handleSubOrderGroups = ({ id, data }) => axios.put(`sub_orders/${id}/groups`, data)
export const addOrderItemsToSubOrder = ({ id, data }) => axios.post(`sub_orders/${id}/order_items`, data)
export const uploadSubOrderMedia = ({ id, data } = {}) => axios.post(`/sub_orders/${id}/media`, data)
export const emailSubOrder = ({ id, data }) => axios.post(`sub_orders/${id}/send_email`, data);
