// @flow

import { setProps } from '../props';

import fetchValuation from '../Services/fetchValuation';

import fetchValuationStatuses from '../../../Core/Services/fetchValuationStatuses';
import fetchServiceTypes from '../../../Core/Services/fetchServiceTypes';
import fetchUsers from '../../../Core/Services/fetchUsers';

export default (props: any) => async (dispatch: Function) => {

  setProps(props);

  dispatch(fetchValuation());

  dispatch(fetchValuationStatuses());
  dispatch(fetchServiceTypes());
  dispatch(fetchUsers());

}