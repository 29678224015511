// @flow

import { getProps } from '../props';
import create from '../Services/create';
import update from '../Services/update';

export default async (values: Function, dispatch: Function) => {
  
  const { onClose, didCreate, didUpdate } = getProps();

  if (!values.id) {

    const order = await dispatch(create(values));
    
    onClose();

    if (didCreate) didCreate(order)
    
  } else {

    const order = await dispatch(update(values));
    
    onClose();

    if (didUpdate) didUpdate(order)

  }
  
}