import handleException from '../../../Core/Helpers/handleException'
import { getProjects } from '../../../HttpRequests/projects'
import { setFetching } from '../State/fetching'
import { loadProjects } from '../State/projects'
import { setPagination } from '../State/pagination'

export default () => async (dispatch, getState) => {

  try {

    const { pagination, sorting, filters, searchTerm } = getState().screens.RamsRecords

    const params = {}

    params.include = [
      'client_site',
      'client_site.client.client_contacts',
      'rams_records.client_contact',
    ].join()
    
    params['page[size]'] = pagination.pageSize
    params['page[number]'] = pagination.current

    params['filter[rams_required]'] = 1

    if (searchTerm) {
      params.search = searchTerm
    }

    if (sorting.columnKey) {
      params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey
    }
    
    if (filters.status === 'INSUFFICIENT') {
      params['filter[rams_insufficient]'] = 1
      params['filter[rams_muted]'] = 0
    }
    
    if (filters.status === 'MUTED') {
      params['filter[rams_insufficient]'] = 1
      params['filter[rams_muted]'] = 1
    }
    
    if (filters.status === 'OK') {
      params['filter[rams_insufficient]'] = 0
    }
    
    if (filters.client_id.length > 0) {
      params['filter[client_id]'] = filters.client_id.join(',')
    }

    dispatch(setFetching(true))

    const response = await getProjects({ params })

    dispatch(loadProjects(response.data.data))
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageSize: response.data.pagination.per_page,
      current: response.data.pagination.current_page,
    }))

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error)

    dispatch(setFetching(false))

  }
}