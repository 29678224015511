import React from 'react';
import { FieldArray } from 'redux-form';

import InputFields from './InputFields';

export default () => (
  <div style={{padding:10}}>

    <FieldArray name="retentionItems" component={InputFields} />

  </div>
)