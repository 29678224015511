import React from 'react'
import { Modal, Button } from 'antd'
import Form from './Form'

import TimesheetPosting from '../TimesheetPosting'

type Props = {
  subcontractors: [],
  id: ?any,
  dayRate: ?any,
  timesheetPostings: ?any,
  submitting: boolean,
  timesheetPostingModal: {},
  componentWillMount: (props: any) => void,
  onClickSubmit: () => void,
  onClickCancel: () => void,
  onClickDelete: () => void,
  onClickRow: () => void,
  onClickNewTimesheetPosting: () => void,
  onCloseTimesheetPostingModal: () => void,
  didCreateTimesheetPosting: () => void,
  didUpdateTimesheetPosting: () => void,
  didDeleteTimesheetPosting: () => void,
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props)

  render() {

    const {
      subcontractors,
      id,
      dayRate,
      timesheetPostings,
      submitting,
      timesheetPostingModal,
      onClickSubmit,
      onClickCancel,
      onClickDelete,
      onClickRow,
      onClickNewTimesheetPosting,
      onCloseTimesheetPostingModal,
      didCreateTimesheetPosting,
      didUpdateTimesheetPosting,
      didDeleteTimesheetPosting,
    } = this.props

    const footer = []

    if (id) {
      footer.push([
        <Button key='delete' style={{ float: 'left' }} type='danger' loading={submitting} onClick={onClickDelete}>
          Delete
        </Button>,
        <Button key='add' style={{ float: 'left' }} onClick={onClickNewTimesheetPosting}>
          Add Posting
        </Button>,
      ])
    }

    footer.push([
      <Button key='cancel' disabled={submitting} onClick={onClickCancel}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
        Save
      </Button>,
    ])

    return (
        
      <Modal
        title={id ? 'Edit Timesheet' : 'New Timesheet'}
        footer={footer}
        onCancel={onClickCancel}
        width={650}
        visible
      >
        
        <Form
          subcontractors={subcontractors}
          id={id}
          dayRate={dayRate}
          timesheetPostings={timesheetPostings}
          submitting={submitting}
          onClickRow={onClickRow}
        />

        {timesheetPostingModal.show && (
          <TimesheetPosting
            id={timesheetPostingModal.id}
            timesheetId={id}
            onClose={onCloseTimesheetPostingModal}
            didCreate={didCreateTimesheetPosting}
            didUpdate={didUpdateTimesheetPosting}
            didDelete={didDeleteTimesheetPosting}
          />
        )}

      </Modal>

    )

  }

}
