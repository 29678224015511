import React from 'react'
import { Modal, Button } from 'antd'
import Form from './Form'

type Props = {
  projects: [],
  id: ?any,
  submitting: boolean,
  componentWillMount: (props: any) => void,
  onClickSubmit: () => void,
  onClickCancel: () => void,
  onClickDelete: () => void,
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props)

  render() {

    const {
      projects,
      id,
      submitting,
      onClickSubmit,
      onClickCancel,
      onClickDelete,
    } = this.props

    const footer = []

    if (id) {
      footer.push([
        <Button key='delete' style={{ float: 'left' }} type='danger' loading={submitting} onClick={onClickDelete}>
          Delete
        </Button>,
      ])
    }

    footer.push([
      <Button key='cancel' disabled={submitting} onClick={onClickCancel}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
        Save
      </Button>,
    ])

    return (
        
      <Modal
        title={id ? 'Edit Posting' : 'New Posting'}
        footer={footer}
        onCancel={onClickCancel}
        width={650}
        visible
      >
        
        <Form
          projects={projects}
          id={id}
          submitting={submitting}
        />

      </Modal>

    )

  }

}
