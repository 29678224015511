// @flow
import * as React from 'react';
import { WindowScroller, AutoSizer } from 'react-virtualized';
import SortableList from './SortableList';
import GroupHeader from './GroupHeader';
import GroupItem from './GroupItem';
import GroupPlaceholder from './GroupPlaceholder';
import GroupFooter from './GroupFooter';
import Footer from './Footer';
import Placeholder from './Placeholder';

import type { MoveEstimateItemParams } from '../../../types';

type Props = {
  listData: [],
  serviceTypes: [],
  editable: boolean,
  selectedEstimateItemIds: [],
  onClickAddEstimateItem: () => void,
  onClickAddEstimateGroup: () => void,
  onClickRemoveEstimateItem: () => void,
  onClickRemoveEstimateGroup: () => void,
  onClickDuplicateEstimateItem: () => void,
  onClickDuplicateEstimateGroup: () => void,
  onClickInsertDescription: () => void,
  onBlurEstimateItemField: () => void,
  onChangeEstimateItemField: () => void,
  onChangeEstimateGroupName: () => void,
  onMoveEstimateItem: (params: MoveEstimateItemParams) => void,
  onToggleEstimateItem: () => void
};

type RowProps = {
  index: number,
  key: any,
  style: {}
};

export default class extends React.PureComponent<Props> {

  componentDidUpdate = () => this.SortableContainer.getWrappedInstance().List.recomputeRowHeights();

  SortableContainer: any;

  renderRow = (rowProps: RowProps) => {

    const {
      index,
      key,
      style
    } = rowProps;

    const {
      listData,
      serviceTypes,
      editable,
      selectedEstimateItemIds,
      onClickAddEstimateItem,
      onClickAddEstimateGroup,
      onClickRemoveEstimateItem,
      onClickRemoveEstimateGroup,
      onClickDuplicateEstimateItem,
      onClickDuplicateEstimateGroup,
      onClickInsertDescription,
      onBlurEstimateItemField,
      onChangeEstimateItemField,
      onChangeEstimateGroupName,
      onToggleEstimateItem
    } = this.props;

    const {
      type,
      estimateGroup,
      estimateGroupIndex,
      estimateItem,
      estimateItemIndex
    } = listData[index];

    if (type === 'GroupHeader') {
      return (
        <div style={style} key={key}>
          <GroupHeader
            index={index}
            editable={editable}
            estimateGroup={estimateGroup}
            estimateGroupIndex={estimateGroupIndex}
            onChangeEstimateGroupName={onChangeEstimateGroupName}
          />
        </div>
      )
    }

    if (type === 'GroupItem') {
      return (
        <div style={style} key={key}>
          <GroupItem
            index={index}
            disabled={!editable} // prevents drag when not edit mode
            editable={editable}
            selectedEstimateItemIds={selectedEstimateItemIds}
            estimateItem={estimateItem}
            estimateItemIndex={estimateItemIndex}
            estimateGroup={estimateGroup}
            estimateGroupIndex={estimateGroupIndex}
            serviceTypes={serviceTypes}
            onClickAddEstimateItem={onClickAddEstimateItem}
            onClickRemoveEstimateItem={onClickRemoveEstimateItem}
            onClickDuplicateEstimateItem={onClickDuplicateEstimateItem}
            onClickInsertDescription={onClickInsertDescription}
            onChangeEstimateItemField={onChangeEstimateItemField}
            onBlurEstimateItemField={onBlurEstimateItemField}
            onToggleEstimateItem={onToggleEstimateItem}
          />
        </div>
      )
    }

    if (type === 'GroupPlaceholder') {
      return (
        <div style={style} key={key}>
          <GroupPlaceholder
            index={index}
            editable={editable}
            estimateGroupIndex={estimateGroupIndex}
            onClickAddEstimateItem={onClickAddEstimateItem}
          />
        </div>
      )
    }

    if (type === 'GroupFooter') {

      return (
        <div style={style} key={key}>
          <GroupFooter
            index={index}
            editable={editable}
            estimateGroup={estimateGroup}
            estimateGroupIndex={estimateGroupIndex}
            onClickAddEstimateItem={onClickAddEstimateItem}
            onClickDuplicateEstimateGroup={onClickDuplicateEstimateGroup}
            onClickRemoveEstimateGroup={onClickRemoveEstimateGroup}
          />
        </div>
      )
    }

    if (type === 'Footer') {

      return (
        <div style={style} key={key}>
          <Footer
            index={index}
            editable={editable}
            onClickAddEstimateGroup={onClickAddEstimateGroup}
          />
        </div>
      )
    }

    if (type === 'Placeholder') {

      return (
        <div style={style} key={key}>
          <Placeholder
            index={index}
            editable={editable}
            onClickAddEstimateGroup={onClickAddEstimateGroup}
          />
        </div>
      )
    }    

    return null;
  }

  render () {

    const {
      listData,
      onMoveEstimateItem
    } = this.props;

    return (

      <div style={{ }}>

        <WindowScroller>
          {({ height, scrollTop }) => (

            <AutoSizer disableHeight>
              {({ width }) => (

                <SortableList
                  autoHeight
                  height={height}
                  width={width}
                  scrollTop={scrollTop}
                  lockAxis="y"
                  overscanRowCount={5}
                  useDragHandle
                  rowHeight={({ index }) => listData[index].height}
                  rowRenderer={this.renderRow}
                  rowCount={listData.length}
                  ref={ref => { this.SortableContainer = ref }}
                  onSortEnd={onMoveEstimateItem}
                />

              )}
            </AutoSizer>
            
          )}
        </WindowScroller>

      </div>

    )
  }
  
}