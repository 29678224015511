import { connect } from "react-redux"

import Component from "./Component"

import componentDidMount from "./Handlers/componentDidMount"
import onCloseRamsRecordModal from "./Handlers/onCloseRamsRecordModal"

const mapStateToProps = (state) => ({
  ramsRecordModal: state.screens.RamsRecords.ramsRecordModal,
})

const actionCreators = {
  componentDidMount,
  onCloseRamsRecordModal,
}

export default connect(mapStateToProps, actionCreators)(Component)
