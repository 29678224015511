import screenProps from '../screenProps'
import { findSubValuation } from '../../../HttpRequests/subValuations'
import { getSubOrders } from '../../../HttpRequests/subOrders'
import { setSubValuation } from '../State/subValuation'
import { setOriginalItems } from '../State/originalItems'
import { setSubOrders } from '../State/subOrders'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { id } = screenProps().match.params

  const params = {
    include: [
      'project.client_site.client',
      'subcontractor',
      'user',
      'approved_by',
      'paid_by',
      'sub_valuation_items.sub_order_item.sub_order_group',
    ].join(','),
    append: 'reference',
  }

  try {

    const findSubValuationResponse = await findSubValuation({ id, params })

    const subValuation = findSubValuationResponse.data

    const subOrderIds = subValuation.sub_valuation_items.map(row => row.sub_order_item.sub_order_group.sub_order_id)

    const getSubOrdersResponse = await getSubOrders({
      params: {
        'filter[id]': subOrderIds.length ? subOrderIds.join(',') : 0,
        sort: 'date',
        include: 'sub_order_groups.sub_order_items',
      },
    })

    dispatch(setSubOrders(getSubOrdersResponse.data))

    dispatch(setSubValuation(subValuation)) // set last

    dispatch(setOriginalItems(subValuation.sub_valuation_items))

  } catch (error) {

    handleException(error)

  }

}
