/* eslint-disable no-underscore-dangle */

import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';


export default () => async (dispatch) => {

  const { invoice } = getProps();
  

  const { user } = invoice;
  const contact = invoice.client_contact;
  
  const values = {
    to: [],
    cc: ['invoices@vividfinishes.co.uk'],
    bcc: [],
    subject: `Vivid - Invoice ${invoice.id}`,
    body: '',
    id: invoice.id,
  };

  if (contact.email) values.to.push(contact.email);
  
  values.body += `Dear ${contact.first_name}\r\n\r\n`;
  values.body += `Please find attached our invoice.\r\n\r\n`;
  values.body += `Kind Regards\r\n\r\n`;
  values.body += `${user.first_name} ${user.last_name}`
  

  dispatch(initialize(FORM_NAME, values));

}