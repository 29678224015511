import { push } from 'connected-react-router'
import { getTeamUrl } from 'Common'
import { closeSubValuationModal } from '../State/subValuationModal'

export default (subValuation) => (dispatch) => {

  dispatch(closeSubValuationModal())

  if (!subValuation) return

  dispatch(push(getTeamUrl(`sub_valuations/${subValuation.id}`)))

}
