import { push } from 'connected-react-router';
import { getTeamUrl } from 'Common';
import handleException from '../../../Core/Helpers/handleException';
import { deleteValuation } from '../../../HttpRequests/valuations';

export default () => async (dispatch, getState) => {

  const { valuation } = getState().screens.ValuationView;
  
  try {

    await deleteValuation({ id: valuation.id });
    
    dispatch(push(getTeamUrl(`projects/${valuation.project_id}/view?tab=valuations`)));
    
  } catch(error) {

    handleException(error);

  }
  
}