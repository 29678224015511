import { combineReducers } from 'redux'

import { reducer as layout } from './State/layout'
import { reducer as rootDirectories } from './State/rootDirectories'
import { reducer as currentDirectoryPath } from './State/currentDirectoryPath'
import { reducer as currentDirectoryContents } from './State/currentDirectoryContents'
import { reducer as selectedItemPath } from './State/selectedItemPath'
import { reducer as selectedItemType } from './State/selectedItemType'
import { reducer as checkedItemPaths } from './State/checkedItemPaths'
import { reducer as editorModal } from './State/editorModal'
import { reducer as uploadModal } from './State/uploadModal'

export default combineReducers({
  layout,
  rootDirectories,
  currentDirectoryPath,
  currentDirectoryContents,
  selectedItemPath,
  selectedItemType,
  checkedItemPaths,
  editorModal,
  uploadModal,
})
