import { createAction, handleActions } from 'redux-actions';

const loadProjects = createAction("CORE/PROJECTS/LOAD_PROJECTS");
const clearProjects = createAction("CORE/PROJECTS/CLEAR_PROJECTS");

const defaultState = []

const reducer = handleActions(
  {
    [loadProjects]: (state, action) => action.payload,
    [clearProjects]: () => []
  },
  defaultState
);

export { loadProjects, clearProjects, reducer };