import { uploadSubOrderMedia } from '../../../HttpRequests/subOrders'
import fetch from './fetch'
import handleException from '../../../Core/Helpers/handleException'

export default (media) => async (dispatch, getState) => {
    
  const { id } = getState().screens.SubOrder.subOrder
  
  const formData = new FormData()
  
  formData.append('file', media[0])

  try {

    await uploadSubOrderMedia({ id, data: formData })

    dispatch(fetch())

  } catch(error) {

    handleException(error)

  }

}
