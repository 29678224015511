import { Modal } from 'antd'
import { getTeamUrl } from 'Common'
import markAsAddedToQuickbooks from './markAsAddedToQuickbooks'

export default () => (dispatch, getState) => {

  const { invoice } = getState().screens.InvoiceView

  window.location = getTeamUrl(`invoices/${invoice.id}/export`, 'exports')
  
  if (!invoice.added_to_quickbooks) {

    Modal.confirm({
      title: `Added to Quickbooks?`,
      content: `Would you like to mark the invoice as added to Quickbooks?`,
      okText: 'Confirm',
      onOk: () => dispatch(markAsAddedToQuickbooks()),
      width: 550
    })

  }

}