import { resetActiveTab } from '../State/activeTab'
import { resetJobs } from '../State/jobs'
import { resetPlannerCell } from '../State/plannerCell'
import { resetDuplicate } from '../State/duplicate'

export default () => (dispatch) => {

  dispatch(resetActiveTab())
  dispatch(resetJobs())
  dispatch(resetPlannerCell())
  dispatch(resetDuplicate())

}
