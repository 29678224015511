// @flow
import * as React from 'react';
import numeral from 'numeral';
import classnames from 'classnames';
import { PlusOutlined } from '@ant-design/icons';
import { SortableElement } from 'react-sortable-hoc';
import styles from './Footer.css';

import type {
  Estimate,
  AddEstimateOptionalParams
} from '../../../types';

type Props = {
  editable: boolean,
  estimate: Estimate,
  onClickAddEstimateOptional: (params: AddEstimateOptionalParams) => void
}

class Component extends React.PureComponent<Props> {

  render () {

    const {
      editable,
      estimate,
      onClickAddEstimateOptional,
    } = this.props;

    const totalOptionals = estimate.estimate_optionals.reduce((total, estimateOptional) => total + Number(estimateOptional.total), 0)

    return (
      <div
        className={styles.wrapper}
      >

        {editable && (

          <React.Fragment>
          
            <div
              className={classnames(styles.link, styles.addLink)}
              onClick={() => onClickAddEstimateOptional({})}
              role="presentation"
            >
              <PlusOutlined />
              {' Add Optional'}
            </div>

          </React.Fragment>
          
        )}
        

        <div className={styles.total}>
          {`Total Optionals: £${numeral(totalOptionals).format('0,0.00')}`}
        </div>

      </div>
    );
  }
}

export default SortableElement(Component);