import { combineReducers } from 'redux';

import { reducer as valuation } from './State/valuation';
import { reducer as fetching } from './State/fetching';
import { reducer as editable } from './State/editable';
import { reducer as showValuationModal } from './State/showValuationModal';
import { reducer as showEmailModal } from './State/showEmailModal';
import { reducer as showValuationCompletionModal } from './State/showValuationCompletionModal';
import { reducer as showValuationHistoryModal } from './State/showValuationHistoryModal';
import { reducer as showCreateInvoiceModal } from './State/showCreateInvoiceModal';
import { reducer as showProjectCompleteModal } from './State/showProjectCompleteModal';
import { reducer as showClientContactModal } from './State/showClientContactModal';
import { reducer as showClientSiteModal } from './State/showClientSiteModal';
import { reducer as projectEditorModal } from './State/projectEditorModal';
import { reducer as showEditOrderItemModal } from './State/showEditOrderItemModal';
import { reducer as selectedOrderGroupKeys } from './State/selectedOrderGroupKeys';
import { reducer as selectedOrderItemKeys } from './State/selectedOrderItemKeys';
import { reducer as activeItemInputKeys } from './State/activeItemInputKeys';

export default combineReducers({
  valuation,
  fetching,
  editable,
  showValuationModal,
  showEmailModal,
  showValuationCompletionModal,
  showValuationHistoryModal,
  showCreateInvoiceModal,
  showProjectCompleteModal,
  showClientContactModal,
  showClientSiteModal,
  projectEditorModal,
  showEditOrderItemModal,
  selectedOrderGroupKeys,
  selectedOrderItemKeys,
  activeItemInputKeys
})
