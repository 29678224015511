import { getSubcontractors } from '../../../../../HttpRequests/subcontractors';
import { setFetching } from '../State/fetching';
import { loadSubcontractors } from '../State/subcontractors'; 
import { setPagination } from '../State/pagination';
import handleException from '../../../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const { searchTerm, filters, sorting, pagination } = getState().screens.Subbies.Subcontractors;

  try {

    const params = {};

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (filters.status !== 'All') {
      params['filter[status]'] = filters.status;
    }

    if (filters.expiry) {
      if (filters.document) {
        params['filter[expiry]'] = `${filters.expiry},${filters.document}`;
      } else {
        params['filter[expiry]'] = filters.expiry;
      }
    }

    if (filters.hasRightToWork !== null) {
      params['filter[has_right_to_work]'] = filters.hasRightToWork;
    }

    if (sorting.columnKey) {
      params.sort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
    } else {
      params.sort = 'name';
    }

    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    dispatch(setFetching(true));

    const response = await getSubcontractors({ params });

    dispatch(loadSubcontractors(response.data.data));
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageSize: response.data.pagination.per_page,
      current: response.data.pagination.current_page
    }));

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error);
  }
}