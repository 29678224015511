import { setPagination } from '../State/pagination';
import fetchProjects from '../Services/fetchProjects';
import fetchProjectTotals from '../Services/fetchProjectTotals';

export default () => async (dispatch) => {

  dispatch(setPagination({ current: 1 }));

  dispatch(fetchProjects());

  dispatch(fetchProjectTotals());
  
}