import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import Screen from '../../Components/Screen'

import Initializing from './Components/Initializing'
import Breadcrumbs from './Components/Breadcrumbs'
import Header from './Components/Header'
import Tabs from './Components/Tabs'
import Overview from './Components/Overview'
import SubProjects from './Components/SubProjects'
import SubOrders from './Components/SubOrders'
import SubValuations from './Components/SubValuations'
import SubInvoices from './Components/SubInvoices'
import Sidebar from './Components/Sidebar'

const SubProject = props => {

  const {
    subcontractor,
    filters,
    activeTab,
    componentDidMount,
    componentWillUnmount,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  if (!subcontractor) return <Initializing />

  return (

    <Screen style={{ marginRight: 280, paddingRight: 30 }}>

      <Breadcrumbs subcontractor={subcontractor} />

      <Divider />

      <Header />

      <Tabs />

      {activeTab === 'OVERVIEW' && <Overview filters={filters} />}

      {activeTab === 'SUB-PROJECTS' && <SubProjects filters={filters} />}

      {activeTab === 'SUB-ORDERS' && <SubOrders filters={filters} />}

      {activeTab === 'SUB-VALUATIONS' && <SubValuations filters={filters} />}

      {activeTab === 'SUB-INVOICES' && <SubInvoices filters={filters} />}

      <Sidebar />
      
    </Screen>
    
  )

}

SubProject.propTypes = {
  subcontractor: PropTypes.object,
  filters: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
}

SubProject.defaultProps = {
  subcontractor: null,
}

export default SubProject
