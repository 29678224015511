import { connect } from 'react-redux'
import SubOrders from './SubOrders'

import componentDidMount from './Handlers/componentDidMount'
import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'
import onClickNew from './Handlers/onClickNew'
import onChangeTable from './Handlers/onChangeTable'
import onCloseSubOrderModal from './Handlers/onCloseSubOrderModal'

const mapStateToProps = (state) => ({
  subOrders: state.screens.SubcontractorView.SubOrders.subOrders,
  fetching: state.screens.SubcontractorView.SubOrders.fetching,
  search: state.screens.SubcontractorView.SubOrders.search,
  subOrderModal: state.screens.SubcontractorView.SubOrders.subOrderModal,
})

const actionCreators = {
  componentDidMount,
  onChangeSearch,
  onSearch,
  onClickNew,
  onChangeTable,
  onCloseSubOrderModal,
}

export default connect(mapStateToProps, actionCreators)(SubOrders)
