import React from 'react'
import { List } from 'react-virtualized'
import { SortableContainer } from 'react-sortable-hoc'

class SortableList extends React.PureComponent {

  List

  render = () => <List ref={ref => { this.List = ref }} {...this.props} />

}

export default SortableContainer(SortableList, { withRef: true })
