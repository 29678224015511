import { createAction, handleActions } from 'redux-actions'

const setSorting = createAction('SCREENS/SUB_ORDERS/SET_SORTING')
const resetSorting = createAction('SCREENS/SUB_ORDERS/RESET_SORTING')

const defaultState = {
  columnKey: null,
  order: null,
}

const reducer = handleActions(
  {
    [setSorting]: (state, { payload }) => payload,
    [resetSorting]: () => defaultState,
  },
  defaultState,
)

export { setSorting, resetSorting, reducer }
