import { createSelector } from 'reselect';

import type { EstimateTotals } from '../../../types';

const selectEstimate = state => state.screens.EstimateView.estimate;

export default createSelector(
  selectEstimate,
  (estimate) => {

    const estimateTotals: EstimateTotals = {
      subtotal: 0,
      discount: 0,
      total: 0
    }

    if (estimate) {

      estimate.estimate_groups.forEach((estimateGroup) => estimateGroup.estimate_items
        .filter((estimateItem) => estimateItem.flag !== 'Omit' && estimateItem.flag !== 'By Others')
        .forEach((estimateItem) => {

          estimateTotals.subtotal += Number(estimateItem.subtotal);
          estimateTotals.discount += Number(estimateItem.discount);
          estimateTotals.total += Number(estimateItem.total);

        })
      );

    }

    return estimateTotals;

  }
)