import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu, Checkbox } from 'antd'
import { CloudDownloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { last } from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import styles from './styles.css'

export default class Item extends React.PureComponent {

  static propTypes = {
    item: PropTypes.object.isRequired,
    selected: PropTypes.bool.isRequired,
    checked: PropTypes.bool.isRequired,
    style: PropTypes.object.isRequired,
    layout: PropTypes.string.isRequired,
    onSelectItem: PropTypes.func.isRequired,
    onDoubleClickItem: PropTypes.func.isRequired,
    onToggleItemCheckbox: PropTypes.func.isRequired,
    onClickContextItem: PropTypes.func.isRequired,
  }

  getIconTypeAndColor() {

    const { item } = this.props

    let iconType = 'folder'
    let iconColor = '#444'

    if (item.type === 'directory') return { iconType, iconColor }

    const extension = last(item.name.toLowerCase().split('.'))

    switch (extension) {

      case 'doc':
      case 'docx':
        iconType = 'file-word'
        iconColor = '#2C5791'
        break

      case 'ppt':
        iconType = 'file-ppt'
        iconColor = '#C05535'
        break

      case 'xls':
      case 'xlsx':
      case 'xlsm':
      case 'csv':
        iconType = 'file-excel'
        iconColor = '#396A45'
        break

      case 'pdf':
        iconType = 'file-pdf'
        iconColor = '#D4241C'
        break

      case 'png':
      case 'jpg':
      case 'jpeg':
        iconType = 'picture'
        iconColor = '#1990FF'
        break

      default:
        iconType = 'file'
        iconColor = '#444'
        break

    }

    return { iconType, iconColor }

  }

  getMenu() {

    const { item, onClickContextItem } = this.props

    return (

      <Menu onClick={({ key }) => onClickContextItem(key, item.path)}>

        {item.type === 'directory' && (

          <Menu.Item key='RENAME_DIRECTORY'>
            <EditOutlined />
            {` Rename Directory`}
          </Menu.Item>

        )}

        {item.type === 'directory' && (

          <Menu.Item key='DELETE_DIRECTORY'>
            <DeleteOutlined />
            {` Delete Directory`}
          </Menu.Item>

        )}

        {item.type === 'file' && (

          <Menu.Item key='DOWNLOAD_FILE'>
            <CloudDownloadOutlined />
            {` Download File`}
          </Menu.Item>

        )}

        {item.type === 'file' && (

          <Menu.Item key='RENAME_FILE'>
            <EditOutlined />
            {` Rename File`}
          </Menu.Item>

        )}

        {item.type === 'file' && (

          <Menu.Item key='DELETE_FILE'>
            <DeleteOutlined />
            {` Delete File`}
          </Menu.Item>

        )}

      </Menu>

    )

  }

  renderAsRow() {

    const {
      item,
      selected,
      checked,
      style,
      onSelectItem,
      onDoubleClickItem,
      onToggleItemCheckbox,
    } = this.props

    const { iconType, iconColor } = this.getIconTypeAndColor()

    return (

      <Dropdown overlay={this.getMenu()} trigger={['contextMenu']}>
        
        <div
          role='presentation'
          onClick={() => onSelectItem(item.path, item.type)}
          onContextMenu={() => onSelectItem(item.path, item.type)}
          onDoubleClick={() => onDoubleClickItem(item.path, item.type)}
          className={classNames(styles.row, { [styles.rowSelected]: selected })}
          style={style}
        >

          <div className={classNames(styles.col, styles.checkCol, { [styles.colSelected]: selected })}>

            <Checkbox
              checked={checked}
              onClick={() => onToggleItemCheckbox(item.path)}
            />

          </div>

          <div className={classNames(styles.col, styles.nameCol, { [styles.colSelected]: selected })}>

            <LegacyIcon type={iconType} theme='filled' className={styles.colIcon} style={{ color: selected ? 'white' : iconColor }} />
            {item.name}

          </div>

          <div className={classNames(styles.col, styles.timestampCol, { [styles.colSelected]: selected })}>

            {item.type === 'directory' && '--'}
            {item.type === 'file' && moment(item.last_modified).format('DD/MM/YYYY [at] HH:mm:ss')}

          </div>

        </div>

      </Dropdown>

    )

  }

  renderAsIcon() {

    const {
      item,
      selected,
      style,
      onSelectItem,
      onDoubleClickItem,
    } = this.props

    const { iconType, iconColor } = this.getIconTypeAndColor()

    return (

      <div
        role='presentation'
        onClick={() => onSelectItem(item.path, item.type)}
        onContextMenu={() => onSelectItem(item.path, item.type)}
        onDoubleClick={() => onDoubleClickItem(item.path, item.type)}
        className={styles.iconContainer}
        style={style}
      >

        <Dropdown overlay={this.getMenu()} trigger={['contextMenu']}>

          <span>

            <div className={classNames(styles.iconWrapper, { [styles.iconWrapperSelected]: selected })}>
              <LegacyIcon type={iconType} theme='filled' className={styles.gridIcon} style={{ color: iconColor }} />
            </div>

            <div className={classNames(styles.name, styles.ellipsis, { [styles.nameSelected]: selected })}>
              {item.name}
            </div>

          </span>

        </Dropdown>

      </div>

    )

  }

  render() {

    const { layout } = this.props

    if (layout === 'list') return this.renderAsRow()

    if (layout === 'grid') return this.renderAsIcon()

    return null

  }

}
