import axios from './axios';

export const getValuations = ({ params } = {}) => axios.get(`valuations`, { params });
export const getValuation = ({id, params }) => axios.get(`valuations/${id}`, { params });
export const createValuation = ({ data }) => axios.post('valuations', data);
export const updateValuation = ({ id, data }) => axios.put(`valuations/${id}`, data);
export const deleteValuation = ({ id }) => axios.delete(`valuations/${id}`);
export const reviseValuation = ({ id }) => axios.post(`valuations/${id}/revise`);
export const refreshValuation = ({ id }) => axios.put(`valuations/${id}/refresh`);
export const updateValuationItems = ({ id, data }) => axios.put(`valuations/${id}/items`, data);
export const emailValuation = ({ id, data }) => axios.post(`valuations/${id}/send_email`, data);
export const invoiceValuation = ({ id, data }) => axios.post(`valuations/${id}/invoice`, data);
export const uninvoiceValuation = ({ id, data }) => axios.post(`valuations/${id}/uninvoice`, data);
export const getValuationTotals = ({ params } = {}) => axios.get(`valuations/totals`, { params });
export const uploadValuationFile = ({ id, data, config } = {}) => axios.post(`/valuations/${id}/files/upload_file`, data, config);
export const deleteValuationFile = ({ id } = {}) => axios.delete(`/valuations/${id}/files/delete_file`);
export const getValuationFileUri = ({id }) => axios.get(`valuations/${id}/files/get_file_uri`);