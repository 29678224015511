import { setSelectedItemPath } from '../../../State/selectedItemPath'
import { setSelectedItemType } from '../../../State/selectedItemType'

export default (path, type) => (dispatch) => {

  dispatch(setSelectedItemPath(path))

  dispatch(setSelectedItemType(type))

}
