import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import Form from './Form'

export default class extends React.PureComponent {

  static propTypes = {
    timesheetAdjustment: PropTypes.object,
    submitting: PropTypes.bool.isRequired,
    componentDidMount: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
  }
  
  static defaultProps = {
    timesheetAdjustment: null,
  }

  componentDidMount = () => this.props.componentDidMount(this.props)

  render() {

    const {
      timesheetAdjustment,
      submitting,
      onClickSubmit,
      onClickCancel,
      onClickDelete,
    } = this.props

    const footer = []

    if (timesheetAdjustment) {
      footer.push([
        <Button key='delete' type='danger' loading={submitting} onClick={() => onClickDelete()} style={{ float: 'left' }}>
          Delete
        </Button>,
      ])
    }

    footer.push([
      <Button key='cancel' disabled={submitting} onClick={() => onClickCancel()}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={() => onClickSubmit()}>
        Save
      </Button>,
    ])

    return (

      <Modal
        title={`${timesheetAdjustment ? 'Adjustment' : 'New Adjustment'}`}
        visible
        footer={footer}
        onCancel={() => onClickCancel()}
      >

        <Form />

      </Modal>

    )

  }

}
