exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".HxHSNx_wqZlQrC6KqI5lY {\n  display: flex;\n  justify-content: space-between;\n}\n\n._1Nc5FNJuxsBZTxmLa_bckZ {\n  display: flex;\n  align-items: center;\n}\n\n._3mJaqbJtEgGBW9V1ZOwz4U {\n  display: flex;\n  align-items: center;\n}\n\n._3U7ixAf-ks3uAmTz96LlN5 {\n  font-size: 16px;\n  cursor: pointer;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Planner/Components/Controls/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,+BAA+B;CAChC;;AAED;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,gBAAgB;EAChB,gBAAgB;CACjB","file":"styles.css","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: space-between;\n}\n\n.left {\n  display: flex;\n  align-items: center;\n}\n\n.right {\n  display: flex;\n  align-items: center;\n}\n\n.icon {\n  font-size: 16px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "HxHSNx_wqZlQrC6KqI5lY",
	"left": "_1Nc5FNJuxsBZTxmLa_bckZ",
	"right": "_3mJaqbJtEgGBW9V1ZOwz4U",
	"icon": "_3U7ixAf-ks3uAmTz96LlN5"
};