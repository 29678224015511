import exportHistory from '../Services/exportHistory';

import { getProps } from '../props';

export default async (values, dispatch) => {

  const { onClose } = getProps();
  
  await dispatch(exportHistory(values));

  onClose();

};
