import immutable from 'immutable';
import { message } from 'antd'
import { updateStimateState } from '../State/project';
import { updateEstimate } from '../../../HttpRequests/estimates';
import handleException from '../../../Core/Helpers/handleException';

export default (id, field, value) => async (dispatch, getState) => {

  try {
  
    // update local state

    let estimates = immutable.fromJS(getState().screens.ProjectView.project.estimates);

    const estimateEntry = estimates.findEntry(item => item.get('id') === id);

    const [ estimateIndex ] = estimateEntry;

    estimates = estimates.setIn([estimateIndex, field], value);

    estimates = estimates.toJS();
    
    dispatch(updateStimateState(estimates));

    // update on server

    const data = {
      [field]: value
    };

    await updateEstimate({ id, data });

    message.success('Estimate Updated.');

  } catch (error) {

    handleException(error);

  }
}