import { connect } from "react-redux";

import Component from "./Component";

import componentDidMount from "./Handlers/componentDidMount";
import onCloseCaveatModal from "./Handlers/onCloseCaveatModal";
import didCreateCaveat from "./Handlers/didCreateCaveat";
import didUpdateCaveat from "./Handlers/didUpdateCaveat";
import didDeleteCaveat from "./Handlers/didDeleteCaveat";

const mapStateToProps = state => ({
  selectedCaveatId: state.screens.Caveats.selectedCaveatId,
  showCaveatModal: state.screens.Caveats.showCaveatModal
});

const actionCreators = {
  componentDidMount,
  onCloseCaveatModal,
  didCreateCaveat,
  didUpdateCaveat,
  didDeleteCaveat
};

export default connect(
  mapStateToProps,
  actionCreators
)(Component);
