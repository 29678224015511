import { createAction, handleActions } from 'redux-actions'

const setSubOrder = createAction('SCREENS/SUB_ORDER/SET_SUB_ORDER')
const setSubOrderGroups = createAction('SCREENS/SUB_ORDER/SET_SUB_ORDER_GROUPS')
const resetSubOrder = createAction('SCREENS/SUB_ORDER/RESET_SUB_ORDER')

const defaultState = null

const reducer = handleActions(
  {
    [setSubOrder]: (state, { payload }) => payload,
    [setSubOrderGroups]: (state, { payload }) => ({ ...state, sub_order_groups: payload }),
    [resetSubOrder]: () => defaultState,
  },
  defaultState,
)

export { setSubOrder, setSubOrderGroups, resetSubOrder, reducer }
