import { createAction, handleActions } from 'redux-actions';

const loadClientRiskLevels = createAction("CORE/RISK_LEVELS/LOAD_CLIENT_RISK_LEVELS");
const clearClientRiskLevels = createAction("CORE/RISK_LEVELS/CLEAR_CLIENT_RISK_LEVELS");

const defaultState = []

const reducer = handleActions(
  {
    [loadClientRiskLevels]: (state, action) => action.payload,
    [clearClientRiskLevels]: () => []
  },
  defaultState
);

export { loadClientRiskLevels, clearClientRiskLevels, reducer };