// @flow

import { push } from 'connected-react-router';
import { getTeamUrl } from 'Common';
import fetchValuation from './fetchValuation';
import { setEditable } from '../State/editable';

export default (id: number) => async (dispatch: Function) => {

    dispatch(setEditable(false));

    dispatch(push(getTeamUrl(`valuations/${id}/view`)))

    await dispatch(fetchValuation());

}