import { initialize } from 'redux-form';
import moment from 'moment';
import { last } from 'lodash';
import { getUserIdForInput } from 'Common';
import { getProps } from '../props';
import { FORM_NAME } from '../Form';

export default () => async (dispatch, getState) => {

  const { projectId, valuation } = getProps();
  
  if (valuation) {

    dispatch(initialize(FORM_NAME, {
      ...valuation,
      final_confirmed: (valuation.final && valuation.final_confirmed_at) ? 1 : 0
    }));
    
  } else {

    const { project } = getState().modals.Valuation;
    
    dispatch(initialize(FORM_NAME, {
      project_id: projectId || undefined,
      user_id: getUserIdForInput(),
      date: moment().format('YYYY-MM-DD'),
      client_contact_id: project && project.valuations.length > 0 ? last(project.valuations).client_contact_id : null
    }));
  }

  

}