import { incPeriodIndex, decPeriodIndex } from '../State/controls'
import fetch from '../Services/fetch'

export default (direction) => (dispatch) => {

  if (direction === 'left') dispatch(decPeriodIndex())
  if (direction === 'right') dispatch(incPeriodIndex())

  dispatch(fetch())

}
