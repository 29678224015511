import { createSelector } from 'reselect';

const selectClient = state => state.screens.ClientView.client;
const selectSearchTerm = state => state.screens.ClientView.searchTerm;
const selectActiveTab = state => state.screens.ClientView.activeTab;

export default createSelector(
  selectClient,
  selectSearchTerm,
  selectActiveTab,
  (client, searchTerm, activeTab) => {
    
    if (activeTab === "CONTACTS" && searchTerm) {
      
      const clientContacts = [];

      client.client_contacts.forEach(clientContact => {
        
        const search = searchTerm.toLowerCase();

        const name = clientContact.first_name.toLowerCase() + ' '.concat((clientContact.last_name || '').toLowerCase());
        const job = clientContact.relationship ? clientContact.relationship.toLowerCase() : '';
        const email = clientContact.email ? clientContact.email.toLowerCase() : '';

        if (name.includes(search) || job.includes(search) || email.includes(search)) {
          clientContacts.push(clientContact);  
        }

      });

      return Object.assign({}, client, {
        
        client_contacts: client ? clientContacts  : []

      });
    }

    if (activeTab === "SITES" && searchTerm) {
      return Object.assign({}, client, {
        
        client_sites: client ? client.client_sites.filter(clientSites => clientSites.name.toLowerCase().includes(searchTerm.toLowerCase())) : []

      });
    }

    if (activeTab === "PROJECTS" && searchTerm) {
      
      const projects = [];

      client.projects.forEach(project => {
        const search = searchTerm.toLowerCase();

        const title = (project.title ? project.title : '').toLowerCase();
        const siteName = project.client_site_name.toLowerCase();

        if (title.includes(search) || siteName.includes(search)) {
          projects.push(project);
        }

      });

      return Object.assign({}, client, {
        
        projects: client ? projects : []

      });
    }
    
    return client;

  }
)