import { createAction, handleActions } from 'redux-actions';

const loadClientContacts = createAction("SCREENS/CLIENT_CONTACTS/LOAD_CLIENT_CONTACTS");
const clearClientContacts = createAction("SCREENS/CLIENT_CONTACTS/CLEAR_CLIENT_CONTACTS");

const defaultState = []

const reducer = handleActions(
  {
    [loadClientContacts]: (state, action) => action.payload,
    [clearClientContacts]: () => []
  },
  defaultState
);

export { loadClientContacts, clearClientContacts, reducer };