import { closeSubOrderModal } from '../State/subOrderModal'
import fetch from '../Services/fetch'

export default (subOrder) => (dispatch) => {

  dispatch(closeSubOrderModal())

  if (subOrder) {
    dispatch(fetch())
  }

}
