import { message } from 'antd'
import screenProps from '../screenProps'
import { handleSubOrderGroups } from '../../../HttpRequests/subOrders'
import fetch from './fetch'
import { setSaving } from '../State/saving'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { id } = screenProps().match.params

  const { subOrder } = getState().screens.SubOrder

  const data = subOrder.sub_order_groups.map(subOrderGroup => ({
    ...subOrderGroup,
    name: subOrderGroup.name || null,
    sub_order_items: subOrderGroup.sub_order_items.map(subOrderItem => ({
      ...subOrderItem,
      type: subOrderItem.type || null,
      description: subOrderItem.description || null,
      quantity: subOrderItem.quantity || null,
      unit: subOrderItem.unit || null,
      rate: subOrderItem.rate || null,
      notes: subOrderItem.notes || null,
    }))
  }))

  dispatch(setSaving(true))

  try {

    await handleSubOrderGroups({ id, data })
    
    message.success(`Updated "${subOrder.reference}"`)

    dispatch(fetch())

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(setSaving(false))

  }

}
