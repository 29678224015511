import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Tooltip } from 'antd';

type Props = {
  type: string,
  tooltip: string|null,
  style: any,
  onClick: () => void
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      type,
      tooltip,
      style,
      onClick
    } = this.props;

    return (
      <React.Fragment>

        {(!!tooltip) && (
          
          <Tooltip title={tooltip}>
            <LegacyIcon
              type={type}
              theme="outlined"
              style={Object.assign({}, { fontSize: 24, color: '#1890ff', cursor: 'pointer' }, style)}
              onClick={onClick}
            />
          </Tooltip>

        )}

        {(!tooltip) && (
          
          <LegacyIcon
            type={type}
            theme="outlined"
            style={Object.assign({}, { fontSize: 24, color: '#1890ff', cursor: 'pointer' }, style)}
            onClick={onClick}
          />

        )}
      
      </React.Fragment>
    );
  }
}