import { createAction, handleActions } from 'redux-actions';

const setSelectedItemPath = createAction("SCREENS/PROJECT_VIEW/SET_SELECTED_ITEM_PATH");
const clearSelectedItemPath = createAction("SCREENS/PROJECT_VIEW/CLEAR_SELECTED_ITEM_PATH");

const defaultState = null;

const reducer = handleActions(
  {
    [setSelectedItemPath]: (state, action) => action.payload,
    [clearSelectedItemPath]: () => null
  },
  defaultState
);

export { setSelectedItemPath, clearSelectedItemPath, reducer };