import { connect } from 'react-redux'
import { isSubmitting } from 'redux-form'
import ValuationDate from './ValuationDate'

import { FORM_NAME } from './Form'

import componentDidMount from './Handlers/componentDidMount'
import onClickSubmit from './Handlers/onClickSubmit'
import onClickCancel from './Handlers/onClickCancel'
import onClickDelete from './Handlers/onClickDelete'

const mapStateToProps = (state) => ({
  submitting: isSubmitting(FORM_NAME)(state),
})

const actionCreators = {
  componentDidMount,
  onClickSubmit,
  onClickCancel,
  onClickDelete,
}

export default connect(mapStateToProps, actionCreators)(ValuationDate)
