import { connect } from 'react-redux'
import Component from './Component'

import onClickNew from './Handlers/onClickNew'
import onClickRecord from './Handlers/onClickRecord'

const mapStateToProps = () => ({})

const actionCreators = {
  onClickNew,
  onClickRecord,
}

export default connect(mapStateToProps, actionCreators)(Component)
