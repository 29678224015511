import { getProjects } from '../../../HttpRequests/projects'
import { setProjects } from '../State/projects'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const params = {
    'filter[status_key]': 2,
    pagination: 0,
  }

  try {

    const response = await getProjects({ params })

    dispatch(setProjects(response.data.data))

  } catch (error) {

    handleException(error)

  }

}
