import React from 'react';
import { Field } from 'redux-form';
import { InputField } from 'FormFields';

const labelColSpan = 5;
const wrapperColSpan = 18;

type Props = {
  submitting: boolean
}

export default class extends React.PureComponent<Props> {

  render () {

    const { submitting } = this.props;

    return (

      <div>

        <Field
          component={InputField}
          name="first_name"
          label="First Name"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

        <Field
          component={InputField}
          name="last_name"
          label="Last Name"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

        <Field
          component={InputField}
          name="relationship"
          label="Job Title"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

        <Field
          component={InputField}
          name="telephone"
          label="Tel"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

        <Field
          component={InputField}
          name="email"
          label="Email"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

      </div>

    )
  }
}