// @flow

import { clearValuation } from '../State/valuation';
import { setEditable } from '../State/editable';

export default () => async (dispatch: Function) => {

  dispatch(clearValuation())
  dispatch(setEditable(false))

  }