// @flow
import { getFileUri } from '../../../HttpRequests/files';
import handleException from '../../../Core/Helpers/handleException';

export default (path: string) => async () => {

  try {
    
    const params = { path };

    const response = await getFileUri({ params });

    window.open(response.data.data);

  } catch (error) {

    handleException(error);

  }

}
