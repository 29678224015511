// @flow
import * as React from 'react';
import Screen from '../../Components/Screen';

import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import Filters from './Components/Filters';
import Table from './Components/Table';
import Footer from './Components/Footer';
import OrderModal from '../../Modals/Order';

type Props = {
  orders: [],
  orderTotals: [],
  pagination: {},
  fetching: boolean,
  showOrderModal: boolean,
  searchTerm: string,
  clients: [],
  filters: any,
  componentDidMount: Function,
  onChangeTable: Function,
  onChangeSearch: Function,
  onClickNewOrder: Function,
  onCloseOrderModal: Function,
  didCreateOrder: Function,
  onClickExport: Function,
  onSubmitSearch: Function,
  onChangeFilter: Function
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount();


  render() {

    const {
      orders,
      orderTotals,
      pagination,
      fetching,
      showOrderModal,
      searchTerm, 
      clients,
      filters,
      onChangeTable,
      onChangeSearch,
      onClickNewOrder,
      onCloseOrderModal,
      didCreateOrder,
      onClickExport,
      onSubmitSearch,
      onChangeFilter

    } = this.props;

    return (

      <Screen>

        <Breadcrumbs />

        <Header
          searchTerm={searchTerm}
          onSubmitSearch={onSubmitSearch}
          onChangeSearch={onChangeSearch}
          onClickNewOrder={onClickNewOrder}
          onClickExport={onClickExport}
        />

        <Filters
          filters={filters}
          clients={clients}
          onChangeFilter={onChangeFilter}
        />

        <Table
          orders={orders}
          fetching={fetching}
          pagination={pagination}
          onChangeTable={onChangeTable}
        />

        <Footer
          orderTotals={orderTotals}
        />

        {showOrderModal && (
          
          <OrderModal
            onClose={onCloseOrderModal}
            didCreate={didCreateOrder}
          />
          
        )}

      </Screen>

    )
  };
}