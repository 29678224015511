import { createAction, handleActions } from 'redux-actions'

const openValuationDateModal = createAction('SCREENS/PROJECT_VIEW/OPEN_VALUATION_DATE_MODAL')
const closeValuationDateModal = createAction('SCREENS/PROJECT_VIEW/CLOSE_VALUATION_DATE_MODAL')

const defaultState = {
  show: false,
  valuationDate: null,
  project: null,
}

const reducer = handleActions(
  {
    [openValuationDateModal]: (state, { payload }) => ({
      show: true,
      valuationDate: payload.valuationDate,
      project: payload.project,
    }),
    [closeValuationDateModal]: () => defaultState,
  },
  defaultState,
)

export { openValuationDateModal, closeValuationDateModal, reducer }
