import removeSubOrderItems from '../../../Services/removeSubOrderItems'
import { resetSelectedItems } from '../../../State/selectedItems'

export default () => (dispatch) => {

  dispatch(removeSubOrderItems())

  dispatch(resetSelectedItems())

}
