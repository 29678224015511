import { formValueSelector, change } from 'redux-form';
import { without, concat } from 'lodash';
import { FORM_NAME } from '../../../Form';

export default (id) => (dispatch, getState) => {

  const caveatIds = formValueSelector(FORM_NAME)(getState(), 'caveat_ids');

  dispatch(change(FORM_NAME, 'caveat_ids', caveatIds.includes(id) ? without(caveatIds, id) : concat(caveatIds, id)));

}