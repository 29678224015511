import { getDirectory } from '../../../HttpRequests/modelFiles'
import { setCurrentDirectoryContents } from '../State/currentDirectoryContents'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { currentDirectoryPath } = getState().modules.FileManager

  const params = {
    path: currentDirectoryPath,
  }

  try {

    const { data } = await getDirectory({ params })

    dispatch(setCurrentDirectoryContents(data))

  } catch (error) {

    handleException(error)

  }

}
