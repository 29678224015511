import { reduxForm } from 'redux-form'
import Form from '../Components/Form'

import validate from './validate'
import onSubmit from './onSubmit'
import onSubmitFail from './onSubmitFail'

export const FORM_NAME = 'SubOrder'

const formConfig = {
  form: FORM_NAME,
  validate,
  onSubmit,
  onSubmitFail,
}

export default reduxForm(formConfig)(Form)
