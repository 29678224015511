import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { UploadOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Upload, Button } from 'antd'
import { DatePickerField, TextAreaField, SelectField } from 'FormFields'
import IconButton from '../../../../Components/IconButton'

import paints from './Misc/paints'
import accessEquipment from './Misc/accessEquipment'

const labelColSpan = 6
const wrapperColSpan = 16

export default class _Fields_ extends React.PureComponent {

  static propTypes = {
    clientContacts: PropTypes.array.isRequired,
    serviceTypes: PropTypes.array.isRequired,
    files: PropTypes.array.isRequired,
    submitting: PropTypes.bool,
    onBeforeUpload: PropTypes.func.isRequired,
    onChangeFiles: PropTypes.func.isRequired,
    onClickNewClientContact: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitting: null,
  }

  render () {

    const {
      clientContacts,
      serviceTypes,
      files,
      submitting,
      onBeforeUpload,
      onChangeFiles,
      onClickNewClientContact,
    } = this.props

    return (
      <React.Fragment>

        <Form.Item
          label="Files"
          required
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        >

          <Upload
            mulitple
            fileList={files}
            beforeUpload={onBeforeUpload}
            onChange={onChangeFiles}
            disabled={submitting}
          >

            <Button disabled={submitting}>
              <UploadOutlined /> Select Files
            </Button>

          </Upload>

        </Form.Item>

        <br />
            
        <Field
          name="client_contact_id"
          label="Contact"
          disabled={submitting}
          component={SelectField}
          options={clientContacts.map(({ id, full_name: fullName }) => ({ label: fullName, value: id }))}
          renderAfter={() => (
            <IconButton
              type="plus-circle"
              tooltip="New Contact"
              onClick={onClickNewClientContact}
              style={{ marginBottom: -4 }}
            />
          )}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 22 }}
          afterCol={{ span: 2 }}
        />

        <br />

        <Field
          name="valid_from"
          label="Valid From"
          disabled={submitting}
          component={DatePickerField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan / 2 }}
          required
        />

        <Field
          name="valid_to"
          label="Valid To"
          disabled={submitting}
          component={DatePickerField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan / 2 }}
          required
        />

        <br />

        <Field
          name="services"
          label="Services"
          disabled={submitting}
          component={SelectField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          required
          mode="multiple"
          options={serviceTypes.filter(serviceType => serviceType.key !== 'O').map(serviceType => ({
            value: serviceType.key,
            label: serviceType.title,
          }))}
        />

        <Field
          name="access_equipment"
          label="Access Equipment"
          disabled={submitting}
          component={SelectField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          mode="tags"
          options={accessEquipment.sort().map(item => ({
            value: item,
            label: item,
          }))}
          tokenSeparators={[',']}
        />

        <Field
          name="paints"
          label="Paints"
          disabled={submitting}
          component={SelectField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          mode="tags"
          options={paints.sort().map(item => ({
            value: item,
            label: item,
          }))}
          tokenSeparators={[',']}
        />

        <br />

        <Field
          name="notes"
          label="Notes"
          disabled={submitting}
          component={TextAreaField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          style={{ height: 80 }}
        />

      </React.Fragment>
    );
  }
}