
import { createAction, handleActions } from 'redux-actions';

const setFileMode = createAction("SCREENS/PROJECT_VIEW/SET_FILE_MODE");

const defaultState = 'list';

const reducer = handleActions(
  {
    [setFileMode]: (state, action) => action.payload
  },
  defaultState
);

export { setFileMode, reducer };