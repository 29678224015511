import React from 'react'
import PropTypes from 'prop-types'
import { SortableElement } from 'react-sortable-hoc'
import { PlusOutlined } from '@ant-design/icons'
import classnames from 'classnames'
import styles from './styles.css'

class SubOrderFooter extends React.PureComponent {

  static propTypes = {
    editing: PropTypes.bool.isRequired,
    onAddGroup: PropTypes.func.isRequired,
  }

  render() {

    const {
      editing,
      onAddGroup,
    } = this.props

    if (!editing) return <div className={styles.wrapper} />

    return (

      <div className={styles.wrapper} style={{ borderTop: '1px solid #DDD' }}>

        <div
          role='presentation'
          onClick={onAddGroup}
          className={classnames(styles.link, styles.addLink)}
        >
          <PlusOutlined />
          {' Add Group'}
        </div>

      </div>

    )

  }

}

export default SortableElement(SubOrderFooter)
