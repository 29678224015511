import { closeProjectEditorModal } from '../State/projectEditorModal'
import fetchValuation from '../Services/fetchValuation'

export default (reload = false) => (dispatch) => {

  dispatch(closeProjectEditorModal())

  if (reload) {
    dispatch(fetchValuation())
  }

}
