import { createAction, handleActions } from 'redux-actions'

const setPlannerCell = createAction('SCREENS/PLANNER/SUBCONTRACTOR_CELL_MODAL/SET_PLANNER_CELL')
const setPlannerCellField = createAction('SCREENS/PLANNER/SUBCONTRACTOR_CELL_MODAL/SET_PLANNER_CELL_FIELD')
const resetPlannerCell = createAction('SCREENS/PLANNER/SUBCONTRACTOR_CELL_MODAL/RESET_PLANNER_CELL')

const defaultState = {
  absence: null,
  alert: null,
}

const reducer = handleActions(
  {
    [setPlannerCell]: (state, action) => action.payload,
    [setPlannerCellField]: (state, { payload }) => ({ ...state, [payload.key]: payload.value }),
    [resetPlannerCell]: () => defaultState,
  },
  defaultState,
)

export { setPlannerCell, setPlannerCellField, resetPlannerCell, reducer }
