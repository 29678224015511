import { connect } from 'react-redux'
import TimesheetPosting from './TimesheetPosting'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'
import onChangeField from './Handlers/onChangeField'
import onBlurNotes from './Handlers/onBlurNotes'
import onClockIn from './Handlers/onClockIn'
import onClockOut from './Handlers/onClockOut'
import onSwitchProject from './Handlers/onSwitchProject'

const mapStateToProps = (state) => ({
  projects: state.screens.TimesheetPosting.projects,
  data: state.screens.TimesheetPosting.data,
  form: state.screens.TimesheetPosting.form,
  saving: state.screens.TimesheetPosting.saving,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onChangeField,
  onBlurNotes,
  onClockIn,
  onClockOut,
  onSwitchProject,
}

export default connect(mapStateToProps, actionCreators)(TimesheetPosting)
