
import { createAction, handleActions } from 'redux-actions';

const setSelectedOrderItemKeys = createAction("SCREENS/VALUATION_VIEW/SET_SELECTED_ORDER_ITEM_KEYS");

const defaultState = null;

const reducer = handleActions(
  {
    [setSelectedOrderItemKeys]: (state, action) => action.payload
  },
  defaultState
);

export { setSelectedOrderItemKeys, reducer };