// @flow

import { getProps } from '../props';
import create from '../Services/create';
import update from '../Services/update';

export default async (values: Function, dispatch: Function) => {

  const { onClose, didCreate, didUpdate } = getProps();

  if (!values.id) {

    const project = await dispatch(create(values));

    onClose(true);
    
    if (didCreate) didCreate(project);

  } else {

    const project = await dispatch(update(values));

    onClose(true);
    
    if (didUpdate) didUpdate(project);

  }

}