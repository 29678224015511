import { createAction, handleActions } from 'redux-actions'

const setSelectedRetentionPaymentId = createAction("SCREENS/PROJECT_VIEW/SET_SELECTED_RETENTION_PAYMENT_ID")

const defaultState = false

const reducer = handleActions(
  {
    [setSelectedRetentionPaymentId]: (state, action) => action.payload,
  },
  defaultState
)

export { setSelectedRetentionPaymentId, reducer }