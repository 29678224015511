import React from 'react'
import Screen from '../../Components/Screen'

import Breadcrumbs from './Components/Breadcrumbs'
import Header from './Components/Header'
import Filters from './Components/Filters'
import Table from './Components/Table'

import User from '../../Modals/User'

type Props = {
  users: [],
  fetching: boolean,
  searchTerm: string,
  filters: {},
  pagination: {},
  userModal: boolean,
  componentDidMount: () => void,
  onChangeSearch: () => void,
  onSubmitSearch: () => void,
  onChangeFilter: () => void,
  onChangeTable: () => void,
  onClickRow: () => void,
  onClickExport: () => void,
  onClickNewUser: () => void,
  onCloseUserModal: () => void,
  didCreateUser: () => void,
  didUpdateUser: () => void,
  didDeleteUser: () => void,
  didDeactivateUser: () => void,
  didReactivateUser: () => void,
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount()

  render() {
    
    const {
      users,
      fetching,
      searchTerm,
      filters,
      pagination,
      userModal,
      onChangeSearch,
      onSubmitSearch,
      onChangeFilter,
      onChangeTable,
      onClickRow,
      onClickExport,
      onClickNewUser,
      onCloseUserModal,
      didCreateUser,
      didUpdateUser,
      didDeleteUser,
      didDeactivateUser,
      didReactivateUser,
    } = this.props
    
    return (

      <Screen>

        <Breadcrumbs />

        <Header
          searchTerm={searchTerm}
          onChangeSearch={onChangeSearch}
          onSubmitSearch={onSubmitSearch}
          onClickExport={onClickExport}
          onClickNewUser={onClickNewUser}
        />

        <Filters
          filters={filters}
          onChangeFilter={onChangeFilter}
        />

        <Table
          users={users}
          fetching={fetching}
          pagination={pagination}
          onChangeTable={onChangeTable}
          onClickRow={onClickRow}
        />

        {userModal.show && (
          <User
            id={userModal.id}
            onClose={onCloseUserModal}
            didCreate={didCreateUser}
            didUpdate={didUpdateUser}
            didDelete={didDeleteUser}
            didDeactivate={didDeactivateUser}
            didReactivate={didReactivateUser}
          />
        )}

      </Screen> 

    )

  }

}
