import { createSelector } from 'reselect'
import { sumBy } from 'lodash'

const selectSubValuations = state => state.screens.SubInvoice.subValuations

export default createSelector(
  selectSubValuations,
  (subValuations) => {

    const total = subValuations.reduce((carry, subValuation) => {
      return carry + (sumBy(subValuation.sub_valuation_items, 'complete_total') || 0)
    }, 0)

    return { total }

  },
)
