import { createSelector } from 'reselect';

const selectCurrentDirectoryContents = state => state.screens.ProjectView.currentDirectoryContents;

export default createSelector(
  selectCurrentDirectoryContents,
  (currentDirectoryContents) => {

    const items = [];

    currentDirectoryContents.directories.forEach((directory) => {
      
      items.push({
        type: 'directory',
        ...directory
      })

    });

    currentDirectoryContents.files.forEach((file) => {
      
      items.push({
        type: 'file',
        ...file
      })

    });

    return items;

  }
)