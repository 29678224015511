// @flow
import * as React from 'react';

type Props = {
  children: React.Node,
  theme: any
};

export default ({ children, theme }: Props) => (

  <div
    className="_Portal_LoginScreen_FormPanel_panel"
    style={{ backgroundColor: theme.formPanelColor}}
  >
    {children}
  </div>

);