import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb, Dropdown, Menu, Radio, Button, Divider, Input, Popover, Select } from 'antd'
import { FilterOutlined, PlusOutlined } from '@ant-design/icons'
import styles from './styles.css'

const Spacer = () => <div style={{ width: 12 }} />

const Box = props => <div className={styles.box}>{props.children}</div>

const Label = props => <div className={styles.label}>{props.text}</div>

const Header = props => {

  const {
    projectStatuses,
    subcontractors,
    projects,
    search,
    filters,
    isFiltered,
    mode,
    onChangeSearch,
    onSearch,
    onChangeFilter,
    onChangeMode,
    onClickNewSubOrder,
    onClickNewSubValuation,
  } = props

  const popover = (
    <div className={styles.filters}>
      <Box>
        <Label text='Subcontractor' />
        <Select
          value={filters.subcontractorIds}
          onChange={value => onChangeFilter('subcontractorIds', value)}
          mode='multiple'
          showSearch
          optionFilterProp='children'
          placeholder='All Subcontractors'
          style={{ width: '100%' }}
        >
          {subcontractors.map(subcontractor => (
            <Select.Option key={subcontractor.id} value={subcontractor.id}>
              {subcontractor.name}
            </Select.Option>
          ))}
        </Select>
      </Box>
      <Box>
        <Label text='Project' />
        <Select
          value={filters.projectIds}
          onChange={value => onChangeFilter('projectIds', value)}
          mode='multiple'
          showSearch
          optionFilterProp='children'
          placeholder='All Projects'
          style={{ width: '100%' }}
        >
          {projects.map(project => (
            <Select.Option key={project.id} value={project.id}>
              {`[${project.id}] ${project.client_site.client.name} - ${project.client_site.name}`}
            </Select.Option>
          ))}
        </Select>
      </Box>
      <Box>
        <Label text='Sub Completion Status' />
        <Select
          value={filters.completionStatuses}
          onChange={value => onChangeFilter('completionStatuses', value)}
          mode='multiple'
          showSearch
          optionFilterProp='children'
          placeholder='All Statuses'
          style={{ width: '100%' }}
        >
          {['Pending', 'Approved', 'Paid'].map(completionStatus => (
            <Select.Option key={completionStatus} value={completionStatus}>
              {completionStatus}
            </Select.Option>
          ))}
        </Select>
      </Box>
    </div>
  )

  return (
    
    <React.Fragment>

      <Breadcrumb separator='»'>

        <Breadcrumb.Item>
          Home
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          Subcontractor Projects
        </Breadcrumb.Item>

      </Breadcrumb>

      <Divider />

      <h1>Subcontractor Projects</h1>

      <div className={styles.controls}>

        <div className={styles.left}>

          <Input.Search
            value={search}
            onChange={e => onChangeSearch(e.target.value)}
            onSearch={onSearch}
            placeholder='Search...'
            style={{ width: 250 }}
          />

          <Spacer />

          <Radio.Group
            value={filters.statusKey}
            onChange={e => onChangeFilter('statusKey', e.target.value)}
          >
            <Radio.Button value='all' className={styles.radioButton}>All</Radio.Button>
            {projectStatuses.map(projectStatus => (
              <Radio.Button key={projectStatus.key} value={projectStatus.key} className={styles.radioButton}>
                {projectStatus.title}
              </Radio.Button>
            ))}
          </Radio.Group>

          <Spacer />

          <Popover
            trigger={['click']}
            placement='bottom'
            content={popover}
          >
            <Button type={isFiltered && 'primary'} icon={<FilterOutlined />}>Filter</Button>
          </Popover>

        </div>

        <div className={styles.right}>

          <Radio.Group
            value={mode}
            onChange={e => onChangeMode(e.target.value)}
          >
            <Radio.Button value='projects' className={styles.radioButton}>By Project</Radio.Button>
            <Radio.Button value='summary' className={styles.radioButton}>All Projects</Radio.Button>
          </Radio.Group>

          <Spacer />

          <Dropdown
            trigger='click'
            overlay={(
              <Menu>
                <Menu.Item key='sub-order' onClick={onClickNewSubOrder}>Order</Menu.Item>
                <Menu.Item key='sub-valuation' onClick={onClickNewSubValuation}>Valuation</Menu.Item>
              </Menu>
            )}
          >
            <Button type='primary' icon={<PlusOutlined />}>New</Button>
          </Dropdown>
          
        </div>

      </div>

    </React.Fragment>

  )

}

Box.propTypes = {
  children: PropTypes.node.isRequired,
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
}

Header.propTypes = {
  projectStatuses: PropTypes.array.isRequired,
  subcontractors: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  isFiltered: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeMode: PropTypes.func.isRequired,
  onClickNewSubOrder: PropTypes.func.isRequired,
  onClickNewSubValuation: PropTypes.func.isRequired,
}

export default Header
