// @flow

import * as React from 'react';
import { Button, Modal, Popconfirm } from 'antd';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import { getUser, getTeamUrl } from 'Common';

import {
  CheckCircleFilled,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  EditFilled,
  ExclamationCircleFilled,
  PaperClipOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Popover, Tag, Row, Col } from 'antd';
import classnames from 'classnames'
import styles from './Component.css';

const Section = (props) => (
  <div className={styles.section} {...props}>
    {props.children}
  </div>
)

const Outlet = (props) => (
  <div className={styles.outlet} {...props}>
    {props.children}
  </div>
)

const OutletLabel = (props) => (
  <div className={styles.outletLabel} {...props}>
    {props.children}
  </div>
)

const OutletData = (props) => (
  <div className={styles.outletData} {...props}>
    {props.children}
  </div>
)

type Props = {
  order: any,
  onClickDownloadFile: Function,
  onDropOrderFile: Function,
  onClickEditClientSite: Function,
  onClickEditClientContact: Function,
  onClickProject: Function,
  onDropMedia: Function,
  onDeleteMedia: Function,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      order,
      onClickDownloadFile,
      onDropOrderFile,
      onClickEditClientSite,
      onClickEditClientContact,
      onClickProject,
      onDropMedia,
      onDeleteMedia,
    } = this.props;

    const userIsBasic = getUser().type === 'Basic'

    return (
      <div className={styles.wrapper}>

        <div className={styles.innerWrapper}>


          <Section>

            <Outlet>
              <OutletLabel>Client</OutletLabel>
              <OutletData>
                <NavLink to={getTeamUrl(`clients/${order.project.client_site.client.id}/view`)}>
                  {order.project.client_site.client.name}
                </NavLink>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Risk Level</OutletLabel>
              <OutletData>
                <Tag
                  color={({ "1": "green", "2": "orange", "3": "red", "4": "black" })[order.project.client_site.client.client_risk_level_key]}
                  style={{ cursor: 'default' }}
                >
                  {order.project.client_site.client.client_risk_level.title}
                </Tag>
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Project</OutletLabel>
              <OutletData>
                {userIsBasic ? (
                  <a role='presentation' onClick={() => onClickProject(order.project)}>
                    {order.project.id}
                    {order.project.title && ` » ${order.project.title}`}
                  </a>
                ) : (
                  <NavLink to={getTeamUrl(`projects/${order.project.id}/view`)}>
                    {order.project.id}
                    {order.project.title && ` » ${order.project.title}`}
                  </NavLink>
                )}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Site</OutletLabel>
              <OutletData>
                {[
                  order.project.client_site.name,
                  order.project.client_site.address_line_1,
                  order.project.client_site.address_line_2,
                  order.project.client_site.address_line_3,
                  order.project.client_site.town_or_city,
                  order.project.client_site.county,
                  order.project.client_site.postcode
                ].filter(line => line).join(', ')}
                {' '}
                <EditFilled onClick={onClickEditClientSite} className={styles.editIcon} />
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Contact</OutletLabel>
              <OutletData>
                <Popover
                  trigger="hover"
                  content={(
                    <div>
                      <div style={{minWidth: 150}}>
                        <strong>Tel: </strong>
                        {order.client_contact.telephone ? order.client_contact.telephone : ''}
                      </div>
                      <div style={{minWidth: 150}}>
                        <strong>Email: </strong>
                        {order.client_contact.email ? order.client_contact.email : ''}
                      </div>
                    </div>
                  )}
                >
                  {`${order.client_contact.first_name || ''} ${order.client_contact.last_name || ''} `}
                </Popover>
                {' '}
                <EditFilled onClick={onClickEditClientContact} className={styles.editIcon} />
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Client Project No</OutletLabel>
              <OutletData>
                {order.client_project_no || '--'}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>New / Extra</OutletLabel>
              <OutletData>
                <LegacyIcon type={order.extra_works ? 'plus' : 'star'} style={{ color: order.extra_works ? 'limegreen' : '#1990FF' }} />
                {order.extra_works ? ' Extra Works' : ' New Works'}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Estimate</OutletLabel>
              <OutletData>
                {!!order.estimate && (
                  <NavLink to={getTeamUrl(`estimates/${order.estimate.id}/view`)}>
                    {order.estimate.full_reference}
                  </NavLink>
                )}
                {!order.estimate && '--'}
              </OutletData>
            </Outlet>

          </Section>

        
          <Section>
            
            <Outlet>
              <OutletLabel>Owner</OutletLabel>
              <OutletData>
                {`${order.user.first_name || ''} ${order.user.last_name || ''}`}
              </OutletData>
            </Outlet>

            <Outlet>
              <OutletLabel>Date</OutletLabel>
              <OutletData>
                {order.date ? moment(order.date).format('DD MMM YYYY') : '--'}
              </OutletData>
            </Outlet>

          </Section>

          <Section>

            <Row>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Discount</OutletLabel>
                  <OutletData>
                    {order.discount_percentage > 0 ? `${order.discount_percentage}%` : '--'}
                  </OutletData>
                </Outlet>

              </Col>

              <Col span={12}>

                <Outlet>
                  <OutletLabel>Uplift</OutletLabel>
                  <OutletData style={order.uplift_percentage > 0 ? { color: 'red' } : {}}>
                    {order.uplift_percentage > 0 ? `${order.uplift_percentage}%` : '--'}
                  </OutletData>
                </Outlet>

              </Col>

            </Row>

          </Section>

          <Section>

            <Dropzone
              className={classnames(styles.fileDropzone, {[styles.isUploaded]: order.order_file_storage_path !== null } )}
              activeClassName={styles.dragActive}
              disablePreview
              disableClick={order.order_file_storage_path !== null}
              multiple={false}
              onDrop={acceptedFiles => { onDropOrderFile(acceptedFiles) }}
              disabled={userIsBasic}
            >

              <div
                role='presentation'
                onClick={() => {
                  if (userIsBasic) {
                    Modal.info({
                      title: 'Upgrade Required',
                      content: 'You will need to upgrade your account in order to upload & download files.'
                    })
                  }
                }}
              >

                <div className={classnames(styles.state, styles.fileMissing)}>
                  <ExclamationCircleFilled style={{ marginRight: 5 }} />
                  PO Missing
                </div>

                <div className={classnames(styles.state, styles.fileUploaded)}>
                  <CheckCircleFilled style={{ marginRight: 5 }} />
                  PO Uploaded
                </div>

                <div className={classnames(styles.state, styles.fileDownload)} onClick={() => !userIsBasic && onClickDownloadFile()} role="presentation">
                  <CloudDownloadOutlined style={{ marginRight: 5 }} />
                  Download PO
                </div>

                <div className={classnames(styles.state, styles.fileDrag)}>
                  <CloudUploadOutlined style={{ marginRight: 5 }} />
                  Upload PO
                </div>

                <div className={classnames(styles.state, styles.fileSelect)}>
                  <CloudUploadOutlined style={{ marginRight: 5 }} />
                  Upload PO
                </div>

              </div>

            </Dropzone>
          </Section>

          <Section>

            <Outlet>
              <OutletLabel>Attachments</OutletLabel>
              <OutletData>

                {order.media.map(file => (

                  <div style={{ marginBottom: 4, marginTop: 4, display: 'flex' }} key={file.id}>
                    <PaperClipOutlined style={{ marginTop: 2 }} />
                    <div style={{ flex: 1, marginLeft: 4, marginRight: 4 }}>
                      <a href={file.url} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                    </div>
                    <Popconfirm onConfirm={() => onDeleteMedia(file)} title="Delete attachment?" placement="topRight">
                      <DeleteOutlined style={{ marginTop: 2 }} className={styles.deleteFileButton} />
                    </Popconfirm>
                  </div>

                ))}

                {order.media.length === 0 && (
                  <div style={{ marginBottom: 4, marginTop: 4 }}>No files</div>
                )}

                <Dropzone
                  disablePreview
                  multiple={false}
                  onDrop={acceptedFiles => onDropMedia(acceptedFiles)}
                  style={{ width: 'auto', height: 'auto', border: 'none' }}
                >
                  <Button block style={{ marginTop: 8 }}>
                    Upload
                  </Button>
                </Dropzone>

              </OutletData>
            </Outlet>

          </Section>


        </div>

      </div>
    );
  }
}