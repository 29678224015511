// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';
import type { EstimateCaveat } from '../types';

export type AddEstimateCaveatParams = {
  estimateCaveatIndex?: number
}

export default (params: AddEstimateCaveatParams) => (dispatch: Function, getState: Function) => {
  
  let estimate = fromJS(getState().screens.EstimateView.estimate);

  const estimateCaveat: EstimateCaveat = {
    id: null,
    estimate_id: estimate.get('id'),
    content: '',
    line_number: null,
    order_number: null
  }
  
  estimate = estimate.updateIn(
    ['estimate_caveats'],
    (list) => params.estimateCaveatIndex !== undefined ? list.insert(params.estimateCaveatIndex + 1, estimateCaveat) : list.push(estimateCaveat)
  );

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}