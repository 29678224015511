import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors'
import fetchUsers from '../Services/fetchUsers'

export default () => (dispatch) => {

  dispatch(fetchSubcontractors())

  dispatch(fetchUsers())
  
}
