import initialize from '../Services/initialize'
import fetchTimesheets from '../../../Screens/Timesheets/Services/fetchTimesheets'

export default () => (dispatch) => {
  
  dispatch(initialize())
  
  dispatch(fetchTimesheets())
  
}
