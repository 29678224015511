import React from 'react'
import PropTypes from 'prop-types'
import { Table, Dropdown, Tooltip, Badge } from 'antd'
import {
  CaretDownOutlined,
  PoundOutlined,
  RetweetOutlined,
} from '@ant-design/icons'
import { useVT } from 'virtualizedtableforantd4'
import { flatMap } from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import styles from './styles.css'

import ProjectSortMenu from '../ProjectSortMenu'
import ProjectCell from '../ProjectCell'
import SubcontractorPopover from '../SubcontractorPopover'
import SubcontractorDrop from '../SubcontractorDrop'
import SubcontractorDrag from '../SubcontractorDrag'
import SubcontractorIcon from './Components/SubcontractorIcon'
import SupervisorIcon from '../SupervisorIcon'
import TraineeIcon from '../TraineeIcon'
import SubcontractorName from '../SubcontractorName'
import JobSlots from '../JobSlots'
import TimesheetPostingSpan from '../TimesheetPostingSpan'
import CellRequired from '../CellRequired'
import CellAlert from '../CellAlert'

const ProjectTable = props => {

  const {
    periods,
    rows,
    fetching,
    onClickProject,
    onClickCell,
  } = props

  const [vt] = useVT(() => ({ scroll: { y: window.innerHeight - 290 } }), [])

  const columnWidth = (window.innerWidth - 120 - 400) / 7

  const today = moment().format('YYYY-MM-DD')

  return (

    <SubcontractorDrop>

      <Table
        dataSource={rows}
        rowKey='id'
        bordered
        loading={fetching}
        components={vt}
        scroll={{ y: window.innerHeight - 290 }}
        pagination={false}
        className={styles.table}
        style={{
          marginTop: '-1px',
          borderBottom: !!rows.length && '1px solid #f0f0f0',
        }}
      >

        <Table.Column
          key='project'
          title={(
            <Dropdown overlay={<ProjectSortMenu />} placement='bottomRight' trigger={['click']}>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <div>{`Projects (${rows.length})`}</div>
                <CaretDownOutlined style={{ marginLeft: 5 }} />
              </div>
            </Dropdown>
          )}
          onCell={record => ({
            onClick: () => onClickProject(record),
            style: { height: 165, padding: 0 },
          })}
          render={(text, record) => <ProjectCell record={record} />}
        />

        {periods.map((period, index) => (

          <Table.Column
            key={period.date}
            title={(
              <SubcontractorPopover period={period} placement='bottom' mouseEnterDelay={0.5}>
                <div>{period.day_of_week}</div>
                <div style={{ fontSize: 13, fontWeight: 400 }}>{period.date_formatted}</div>
              </SubcontractorPopover>
            )}
            width={columnWidth}
            onHeaderCell={() => ({
              className: classNames({
                [styles.today]: period.date === today,
                [styles.weekend]: period.is_weekend && period.date !== today,
              }),
            })}
            onCell={record => ({
              id: `${record.id}|${period.date}`,
              onClick: () => {
                const { subcontractors } = record.periods[index]
                const jobs = flatMap(subcontractors.map(subcontractor => subcontractor.jobs))
                onClickCell(period, record, jobs, record.periods[index].planner_cell)
              },
              style: { height: 165, padding: 0 },
            })}
            render={(text, record) => {
              const {
                valuation_date: valuationDate,
                subcontractors,
                confirmed_slots: confirmedSlots,
                required,
                alert,
              } = record.periods[index]
              const cell = (
                <div className={styles.cell}>
                  <div className={styles.cellBody}>
                    {subcontractors.map(subcontractor => (
                      <React.Fragment key={subcontractor.id}>
                        {subcontractor.jobs.length ? (
                          <SubcontractorDrag jobs={subcontractor.jobs}>
                            <div>
                              {subcontractor.switched && <RetweetOutlined style={{ marginRight: 5 }} />}
                              <SubcontractorIcon subcontractor={subcontractor} />
                              {!!subcontractor.is_supervisor && <SupervisorIcon />}
                              {!!subcontractor.is_trainee && <TraineeIcon />}
                              <SubcontractorName subcontractor={subcontractor} />
                              <JobSlots jobs={subcontractor.jobs} />
                            </div>
                          </SubcontractorDrag>
                        ) : (
                          <div>
                            {subcontractor.switched && <RetweetOutlined style={{ marginRight: 5 }} />}
                            <SubcontractorIcon subcontractor={subcontractor} />
                            {!!subcontractor.is_supervisor && <SupervisorIcon />}
                            {!!subcontractor.is_trainee && <TraineeIcon />}
                            <SubcontractorName subcontractor={subcontractor} />
                          </div>
                        )}
                        {subcontractor.timesheet_postings.map(timesheetPosting => (
                          <TimesheetPostingSpan
                            key={timesheetPosting.id}
                            timesheetPosting={timesheetPosting}
                            nowColor={subcontractor.icon}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </div>
                  <div>
                    {!!alert && <CellAlert content={alert} />}
                    {!!required && <CellRequired required={required} confirmedSlots={confirmedSlots} />}
                  </div>
                </div>
              )
              return (
                <React.Fragment>
                  {valuationDate ? (
                    <Badge.Ribbon
                      text={(
                        <Tooltip
                          title={(
                            <React.Fragment>
                              <div>Valuation Day</div>
                              <div>{`To: ${moment(valuationDate.to_date).format('ddd Do MMM YYYY') }`}</div>
                            </React.Fragment>
                          )}
                        >
                          <PoundOutlined />
                        </Tooltip>
                      )}
                    >
                      {cell}
                    </Badge.Ribbon>
                  ) : cell}
                </React.Fragment>
              )
            }}
          />

        ))}

      </Table>

    </SubcontractorDrop>

  )

}

ProjectTable.propTypes = {
  periods: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onClickProject: PropTypes.func.isRequired,
  onClickCell: PropTypes.func.isRequired,
}

export default ProjectTable
