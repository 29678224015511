import React from 'react'
import { Field } from 'redux-form'
import { SelectField, TimePickerField } from 'FormFields'

const labelColSpan = 6
const wrapperColSpan = 18

type Props = {
  projects: [],
  id: ?any,
  submitting: boolean,
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      projects,
      id,
      submitting,
    } = this.props

    return (

      <div>

        <Field
          component={SelectField}
          name='project_id'
          label='Project'
          disabled={submitting}
          options={projects.filter(project => !!id || project.status_key === 2).map(project => ({
            value: project.id,
            label: `[${project.id}] ${project.client_site.client.name} - ${project.client_site.name}`,
          }))}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 20 }}
        />

        <Field
          component={TimePickerField}
          name='start_time'
          label='Start'
          secondStep={60}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 10 }}
        />

        <Field
          component={TimePickerField}
          name='end_time'
          label='End'
          secondStep={60}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 10 }}
        />
        
      </div>

    )

  }

}
