import { combineReducers } from 'redux';

import { reducer as client } from './State/client';
import { reducer as searchTerm } from './State/searchTerm';
import { reducer as activeTab } from './State/activeTab';
import { reducer as showClientModal } from './State/showClientModal';
import { reducer as showClientContactModal } from './State/showClientContactModal';
import { reducer as projectEditorModal } from './State/projectEditorModal';
import { reducer as clientContactForEdit } from './State/clientConactForEdit';
import { reducer as showClientSiteModal } from './State/showClientSiteModal';
import { reducer as clientSiteForEdit } from './State/clientSiteForEdit';

export default combineReducers({
  client,
  activeTab,
  searchTerm,
  showClientModal,
  showClientContactModal,
  projectEditorModal,
  clientContactForEdit,
  showClientSiteModal,
  clientSiteForEdit
})
