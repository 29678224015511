import { Modal } from 'antd';

export default (estimate, delay = 0) => async () => {

  if (estimate.uplift_percentage > 0) {

    setTimeout(() => {

      Modal.warning({
        title: `Price Uplift`,
        content: `Note: This estimate has an uplift of ${estimate.uplift_percentage}%`,
        width: 450,
        okText: 'Okay'
      });

    }, delay);

  }

}