import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import Table from './Components/Table';

export default class extends React.PureComponent {

  static propTypes = {
    componentWillMount: PropTypes.func.isRequired,
    componentWillUnmount: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired
  }

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      onClickCancel,
      onClickSubmit
    } = this.props;

    return (
        
      <Modal
        visible  
        title="Select Optionals" 
        onCancel={onClickCancel}
        onOk={onClickSubmit}
        width={1000}
      >
        
        <Table />

      </Modal>
      
    )
  }
}