import { message } from 'antd'
import { deleteProject } from '../../../HttpRequests/projects'
import { getProps } from '../props'
import handleException from '../../../Core/Helpers/handleException'

export default () => async () => {

  const { project, onClose, didDelete } = getProps()

  try {

    await deleteProject({ id: project.id })

    message.success('Project Deleted')

    onClose(true)

    if (didDelete) didDelete()

  } catch (error) {

    handleException(error)

  }

}
