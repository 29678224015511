import { combineReducers } from 'redux';

import { reducer as emailSearch } from './State/emailSearch';
import { reducer as fileList } from './State/fileList';
import { reducer as initializing } from './State/initializing';


export default combineReducers({
  emailSearch,
  fileList,
  initializing,
})
