import React from 'react'
import { Field } from 'redux-form'
import { InputField } from 'FormFields'

export default () => (

  <Field
    component={InputField}
    name='name'
    label='Name'
    autoFocus
    autoComplete='off'
    labelCol={{ span: 5 }}
    wrapperCol={{ span: 18 }}
    fieldCol={{ span: 22 }}
    afterCol={{ span: 2 }}
  />

)
