import { connect } from 'react-redux'
import SubcontractorDrop from './SubcontractorDrop'

import onDrop from './Handlers/onDrop'

const mapStateToProps = () => ({
})

const actionCreators = {
  onDrop,
}

export default connect(mapStateToProps, actionCreators)(SubcontractorDrop)
