import immutable from 'immutable';
import { message } from 'antd'
import { loadEstimates } from '../State/estimates';
import { updateEstimate } from '../../../HttpRequests/estimates';
import handleException from '../../../Core/Helpers/handleException';

export default (id, fields) => async (dispatch, getState) => {

  try {

    // update local state

    let estimates = immutable.fromJS(getState().screens.Estimates.estimates);

    const estimateEntry = estimates.findEntry(item => item.get('id') === id);

    const [ estimateIndex ] = estimateEntry;
    
    Object.keys(fields).forEach(key => {
      estimates = estimates.setIn([estimateIndex, key], fields[key]);
    })

    estimates = estimates.toJS();

    dispatch(loadEstimates(estimates));

    // update on server

    await updateEstimate({ id, data: fields });

    message.success('Estimate Updated.');

  } catch (error) {

    handleException(error);

  }
}