import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { FORM_NAME } from '../../Form';

import onToggleCaveat from './Handlers/onToggleCaveat';

import Component from './Component';

const mapStateToProps = (state) => ({
  caveats: state.core.caveats,
  caveatIds: formValueSelector(FORM_NAME)(state, 'caveat_ids')
});

const actionCreators = {
  onToggleCaveat
};

export default connect(mapStateToProps, actionCreators)(Component);