
import { createAction, handleActions } from 'redux-actions';

const setActiveItemInputKeys = createAction("SCREENS/VALUATION_VIEW/SET_ACTIVE_INPUT_ITEM_KEYS");

const defaultState = null;

const reducer = handleActions(
  {
    [setActiveItemInputKeys]: (state, action) => action.payload
  },
  defaultState
);

export { setActiveItemInputKeys, reducer };