import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';

export default ({ estimateGroupIndex, estimateItemIndex }) => (dispatch, getState) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);

  let { quantity, rate } = estimate.getIn(['estimate_groups', estimateGroupIndex, 'estimate_items', estimateItemIndex]).toJS();

  quantity = Number.isNaN(Number(quantity)) ? 0 :  Number(quantity);
  rate = Number.isNaN(Number(rate)) ? 0 :  Number(rate);

  quantity = quantity.toFixed(2);
  rate = rate.toFixed(2);

  estimate = estimate.setIn(['estimate_groups', estimateGroupIndex, 'estimate_items', estimateItemIndex, 'quantity'], quantity);
  estimate = estimate.setIn(['estimate_groups', estimateGroupIndex, 'estimate_items', estimateItemIndex, 'rate'], rate);

  estimate = estimate.toJS();

  dispatch(loadEstimate(estimate));

}