import { setFileList } from '../State/fileList';

export default (file) => (dispatch, getState) => {
  
  const { fileList } = getState().modals.EmailEstimate;

  const newFileList = [ ...fileList, file ];

  dispatch(setFileList(newFileList));

  return false;

}