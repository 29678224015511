import { connect } from 'react-redux'
import Sidebar from './Sidebar'

const mapStateToProps = (state) => ({
  subcontractor: state.screens.SubProject.subcontractor,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Sidebar)
