exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".AprbjiKsSRJnjdxghI-0Y {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n._2c3zL-kU8YowmS4ZJhmYRp {\n  display: flex;\n  width: 100%;\n  padding: 5px 0px;\n}\n\n.F-5L-H5plahnAxgruOQrr {\n  font-weight: 500;\n  width: 20%;\n}\n\n._3O0Zq0iCrK1HXZy0rsLM-r {\n  width: 80%;\n}\n\n._2HnOiyzDD0v_BDRnzN_-Hw {\n  color: orange;\n}\n\n.LTqtGNX49vFAnL7HkxcHQ {\n  color: grey;\n}\n\n._1BitoBJVPjkyeH-DCe2INt {\n  margin-right: 5px;\n}\n\n._3hZZEDu_cqUJy7rai8fLhg {\n  margin-right: 5px;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/TimesheetPosting/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uBAAuB;EACvB,oBAAoB;CACrB;;AAED;EACE,cAAc;EACd,YAAY;EACZ,iBAAiB;CAClB;;AAED;EACE,iBAAiB;EACjB,WAAW;CACZ;;AAED;EACE,WAAW;CACZ;;AAED;EACE,cAAc;CACf;;AAED;EACE,YAAY;CACb;;AAED;EACE,kBAAkB;CACnB;;AAED;EACE,kBAAkB;CACnB","file":"styles.css","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.info {\n  display: flex;\n  width: 100%;\n  padding: 5px 0px;\n}\n\n.label {\n  font-weight: 500;\n  width: 20%;\n}\n\n.value {\n  width: 80%;\n}\n\n.clockedIn {\n  color: orange;\n}\n\n.clockedOut {\n  color: grey;\n}\n\n.clockedInIcon {\n  margin-right: 5px;\n}\n\n.clockedOutIcon {\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "AprbjiKsSRJnjdxghI-0Y",
	"info": "_2c3zL-kU8YowmS4ZJhmYRp",
	"label": "F-5L-H5plahnAxgruOQrr",
	"value": "_3O0Zq0iCrK1HXZy0rsLM-r",
	"clockedIn": "_2HnOiyzDD0v_BDRnzN_-Hw",
	"clockedOut": "LTqtGNX49vFAnL7HkxcHQ",
	"clockedInIcon": "_1BitoBJVPjkyeH-DCe2INt",
	"clockedOutIcon": "_3hZZEDu_cqUJy7rai8fLhg"
};