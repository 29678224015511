import { createAction, handleActions } from 'redux-actions'

const setSelectedItemType = createAction('MODULES/FILE_MANAGER/SET_SELECTED_ITEM_TYPE')
const resetSelectedItemType = createAction('MODULES/FILE_MANAGER/RESET_SELECTED_ITEM_TYPE')

const defaultState = null

const reducer = handleActions(
  {
    [setSelectedItemType]: (state, action) => action.payload,
    [resetSelectedItemType]: () => defaultState,
  },
  defaultState,
)

export { setSelectedItemType, resetSelectedItemType, reducer }
