import { change, untouch } from 'redux-form';
import { FORM_NAME } from '../Form';
import fetchProject from '../Services/fetchProject';
import { clearProject } from '../State/project';

export default (event, value) => (dispatch) => {

  dispatch(change(FORM_NAME, 'estimate_id', null))
  dispatch(change(FORM_NAME, 'estimate_optional_ids', []))
  dispatch(untouch(FORM_NAME, 'estimate_id'));
  
  dispatch(change(FORM_NAME, 'client_contact_id', null))
  dispatch(untouch(FORM_NAME, 'client_contact_id'));

  if (value) {
    
    // delay fetch, so that state can update with new project_id 
    setTimeout(() => dispatch(fetchProject()));

  } else {

    dispatch(clearProject());

  }
  
}