// @flow

import { setProps } from '../props';

import fetchEstimate from '../Services/fetchEstimate';

import fetchEstimateStatuses from '../../../Core/Services/fetchEstimateStatuses';
import fetchEstimateTenderStatuses from '../../../Core/Services/fetchEstimateTenderStatuses';
import fetchEstimateLatestActions from '../../../Core/Services/fetchEstimateLatestActions';
import fetchServiceTypes from '../../../Core/Services/fetchServiceTypes';
import fetchUsers from '../../../Core/Services/fetchUsers';

export default (props: any) => async (dispatch: Function) => {

  setProps(props);

  dispatch(fetchEstimate());

  dispatch(fetchEstimateStatuses());
  dispatch(fetchEstimateTenderStatuses());
  dispatch(fetchEstimateLatestActions());
  dispatch(fetchServiceTypes());
  dispatch(fetchUsers());

}