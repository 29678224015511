import { Modal } from 'antd'
import { push } from 'connected-react-router'
import { getTeamUrl } from 'Common'
import invoice from '../Services/invoice'
import uninvoice from '../Services/uninvoice'

export default (key, retentionPayment) => async (dispatch) => {

  switch (key) {

    default:
      break

    case 'CREATE_INVOICE':

      Modal.confirm({
        title: `Create Invoice`,
        content: 'Are you sure want to create an invoice for this retention payment?',
        okText: 'Yes, Create it',
        width: 530,
        onOk: () => dispatch(invoice(retentionPayment.id))
      })

      break
    
    case 'VIEW_INVOICE': {
      const invoices = retentionPayment.active_invoices
      if (invoices.length > 0) dispatch(push(getTeamUrl(`invoices/${invoices[0].id}/view`)))
      break
    }
    
    case 'UNDO_INVOICE':

      Modal.confirm({
        title: `Undo Invoice`,
        content: 'Are you sure want to undo the invoice for this retention payment?',
        okText: 'Yes, Undo it',
        width: 530,
        onOk: () => dispatch(uninvoice(retentionPayment.id))
      })

      break

  }

}