// @flow
import * as React from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Input, Menu, Button, Dropdown } from 'antd';
import { isSenior } from 'Common';

const styles = {

  stack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 25
  },

  heading: {
    flex: 1,
    margin: 0
  }
}

type Props = {
  searchTerm: string,
  onChangeSearch: Function,
  onSubmitSearch: Function,
  onClickNewOrder: Function,
  onClickExport: Function
  
}

export default class extends React.PureComponent<Props> {

  render () {
    const {
      searchTerm,
      onClickNewOrder,
      onSubmitSearch,
      onChangeSearch,
      onClickExport
    } = this.props;
    
    const exportMenu = (
      <Menu onClick={onClickExport}>
        <Menu.Item key="excel">Export to Excel</Menu.Item>
        <Menu.Item key="csv">Export to CSV</Menu.Item>
      </Menu>
    );

    return (
      <div style={styles.stack}>

        <h1 style={styles.heading}>
          Orders
        </h1>

        <Input.Search
          value={searchTerm}
          onChange={onChangeSearch}
          onSearch={onSubmitSearch}
          placeholder="Search"
          enterButton
          style={{ width: 250 }}
        />

        {isSenior() && (

          <React.Fragment>
          
            <div style={{ width: 10 }} />

            <Dropdown overlay={exportMenu}>
              <Button>
                <CloudDownloadOutlined style={{ fontSize: 16, marginTop: 3 }} />
              </Button>
            </Dropdown>
          
          </React.Fragment>
          
        )}
        

        <div style={{ width: 10 }} />

        <Button type="primary" onClick={onClickNewOrder}>
          New Order
        </Button>

      </div>
    );
  }
}