exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".d6miV7cXb2XKPxm7qezd8 {\n  display: flex;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n._3uJZZfxk7LjcJKn7CrtJtv {\n  flex: 1;\n}\n\n._10_4XyKJpWDj9313jB1Hlo {\n  display: flex;\n  align-items: center;\n}\n\n._1im0ClBuBmzkHv5llEVnYp {\n  margin: 0px;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/SubValuation/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;CACT;;AAED;EACE,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,YAAY;CACb","file":"styles.css","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.left {\n  flex: 1;\n}\n\n.right {\n  display: flex;\n  align-items: center;\n}\n\n.header {\n  margin: 0px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "d6miV7cXb2XKPxm7qezd8",
	"left": "_3uJZZfxk7LjcJKn7CrtJtv",
	"right": "_10_4XyKJpWDj9313jB1Hlo",
	"header": "_1im0ClBuBmzkHv5llEVnYp"
};