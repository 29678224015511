import { connect } from 'react-redux'
import { get } from 'lodash'
import Controls from './Controls'

import selectFilterApplied from './Selectors/selectFilterApplied'
import selectDateRange from '../../Selectors/selectDateRange'

import onChangeControls from './Handlers/onChangeControls'
import onSubmitSearch from './Handlers/onSubmitSearch'
import onClickAllFilters from './Handlers/onClickAllFilters'
import onClickSubcontractors from './Handlers/onClickSubcontractors'
import onClickExport from './Handlers/onClickExport'
import onSelectDate from './Handlers/onSelectDate'

const mapStateToProps = (state) => ({
  users: state.core.users,
  projectStatuses: state.core.projectStatuses,
  projectPhases: state.core.projectPhases,
  controls: state.screens.Planner.controls,
  filterApplied: selectFilterApplied(state),
  dateRange: selectDateRange(state),
  startDate: get(state.screens.Planner.data.periods, '[0].date'),
})

const actionCreators = {
  onChangeControls,
  onSubmitSearch,
  onClickAllFilters,
  onClickSubcontractors,
  onClickExport,
  onSelectDate,
}

export default connect(mapStateToProps, actionCreators)(Controls)
