import { connect } from 'react-redux'
import ProjectTable from './ProjectTable'

import onClickProject from './Handlers/onClickProject'
import onClickCell from './Handlers/onClickCell'

const mapStateToProps = (state) => ({
  periods: state.screens.Planner.data.periods,
  rows: state.screens.Planner.data.rows,
  fetching: state.screens.Planner.fetching,
})

const actionCreators = {
  onClickProject,
  onClickCell,
}

export default connect(mapStateToProps, actionCreators)(ProjectTable)
