import raven from 'raven-js';

export default () => () => {

  const { APP_ENV, RELEASE, BUILD, SENTRY_DSN } = window.__ENV__;

  if (APP_ENV !== 'local') {

    raven.config(SENTRY_DSN, {
      release: RELEASE,
      environment: APP_ENV,
      tags: { build: BUILD },
      ignoreErrors: ['ResizeObserver loop limit exceeded']
    }).install();

  }

}