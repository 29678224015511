import { connect } from 'react-redux'

import Component from './Component'

import onClickRecord from './Handlers/onClickRecord'
import onClickActionMenuItem from './Handlers/onClickActionMenuItem'

const mapStateToProps = () => ({})

const actionCreators = {
  onClickRecord,
  onClickActionMenuItem,
}

export default connect(mapStateToProps, actionCreators)(Component)
