import { connect } from 'react-redux'
import Component from './Component'

import onChangeSearch from './Handlers/onChangeSearch'
import onSubmitSearch from './Handlers/onSubmitSearch'
import onClickExport from './Handlers/onClickExport'

const mapStateToProps = (state) => ({
  searchTerm: state.screens.RamsRecords.searchTerm,
})

const actionCreators = {
  onChangeSearch,
  onSubmitSearch,
  onClickExport,
}

export default connect(mapStateToProps, actionCreators)(Component)
