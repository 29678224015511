import * as React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { Table } from 'antd';

export default class extends React.PureComponent {

  static propTypes = {
    estimate: PropTypes.object,
    selectedRowKeys: PropTypes.array.isRequired,
    onChangeRowSelection: PropTypes.func.isRequired
  }

  static defaultProps = {
    estimate: null
  }

  render () {

    const {
      estimate,
      selectedRowKeys,
      onChangeRowSelection
    } = this.props;
    
    const columns = [

      { 
        title: 'Description', 
        dataIndex: 'description',
        render: (text, record) => record.description
      },

      { 
        title: 'Qty', 
        dataIndex: 'quantity',
        width: 70,
        render: (text, record) => record.quantity
      },

      { 
        title: 'Unit', 
        dataIndex: 'unit',
        width: 70,
        render: (text, record) => record.unit
      },

      { 
        title: 'Rate', 
        dataIndex: 'rate',
        width: 100,
        render: (text, record) => `£${numeral(record.rate).format('0,0.00')}`
      },

      { 
        title: 'Subtotal', 
        dataIndex: 'subtotal',
        width: 100,
        render: (text, record) => `£${numeral(record.subtotal).format('0,0.00')}`
      },

      { 
        title: 'Discount', 
        dataIndex: 'discount',
        width: 100,
        render: (text, record) => `£${numeral(record.discount).format('0,0.00')}`
      },

      { 
        title: 'Total', 
        dataIndex: 'total',
        width: 100,
        render: (text, record) => `£${numeral(record.total).format('0,0.00')}`
      },

    ]
    
    return (

      <Table
        dataSource={estimate ? estimate.estimate_optionals : []}
        columns={columns} 
        rowKey={record => record.id}
        loading={!estimate}
        pagination={false}
        size="small"
        rowSelection={{
          selectedRowKeys,
          onChange: onChangeRowSelection
        }}
        bordered={false}
      />

    )
  }
}