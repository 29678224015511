import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { project } = getProps();

  if (project) {

    dispatch(initialize(FORM_NAME, project))

  } else {

    dispatch(initialize(FORM_NAME, { }))

  }

}