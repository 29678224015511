import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {
  
  const { subcontractor } = getProps();

  if (getProps().subcontractor) {

    dispatch(initialize(FORM_NAME, {
      ...subcontractor,
      co_vehicle: !!subcontractor.co_vehicle_reg,
    }))
    
  } else {

    dispatch(initialize(FORM_NAME, {
      is_multiple: false,
      is_ltd_company: false,
      drives: false,
      co_vehicle: false,
      is_supervisor: false,
      is_trainee: false,
      availability: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    }))

  }

}