import { Modal } from 'antd';
import { getProject } from '../../../HttpRequests/projects';
import { setShowProjectCompleteModal } from '../State/showProjectCompleteModal';

export default () => async (dispatch, getState) => {

  const { valuation } = getState().screens.ValuationView;

  const id = valuation.project_id;

  const params = {};
  
  params.append = [
    'action_statuses'
  ].join();

  const response = await getProject({ id, params });

  const project = response.data.data;
  
  if (project.action_statuses.complete !== true) return;

  Modal.confirm({
    title: `Complete this Project?`,
    content: 'The valuation is now at 100%. Would you like to mark the project as complete?',
    okText: 'Mark as Complete',
    width: 500,
    onOk: () => {
      setTimeout(() => dispatch(setShowProjectCompleteModal(true)), 500);
    }
  });

}