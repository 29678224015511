import { createAction, handleActions } from 'redux-actions'

const setSubcontractors = createAction('SCREENS/PLANNER/PROJECT_SUBCONTRACTORS_MODAL/SET_SUBCONTRACTORS')
const resetSubcontractors = createAction('SCREENS/PLANNER/PROJECT_SUBCONTRACTORS_MODAL/RESET_SUBCONTRACTORS')

const defaultState = []

const reducer = handleActions(
  {
    [setSubcontractors]: (state, action) => action.payload,
    [resetSubcontractors]: () => defaultState,
  },
  defaultState,
)

export { setSubcontractors, resetSubcontractors, reducer }
