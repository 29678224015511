import { connect } from 'react-redux'
import Header from './Header'

import selectIsFiltered from './Selectors/selectIsFiltered'

import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'
import onChangeFilter from './Handlers/onChangeFilter'
import onClickNew from './Handlers/onClickNew'

const mapStateToProps = (state) => ({
  subcontractors: state.core.subcontractors,
  projects: state.core.projects,
  users: state.core.users,
  search: state.screens.SubValuations.search,
  filters: state.screens.SubValuations.filters,
  isFiltered: selectIsFiltered(state),
})

const actionCreators = {
  onChangeSearch,
  onSearch,
  onChangeFilter,
  onClickNew,
}

export default connect(mapStateToProps, actionCreators)(Header)
