import { resetSubValuation } from '../State/subValuation'
import { resetOriginalItems } from '../State/originalItems'
import { resetEditing } from '../State/editing'

export default () => (dispatch) => {

  dispatch(resetSubValuation())
  dispatch(resetOriginalItems())
  dispatch(resetEditing())

}
