// @flow
import * as React from 'react';

type Props = {
  theme: any
};

export default ({ theme }: Props) => (
  
  <div
    className="_Portal_LoginScreen_BrandingPanel_panel"
    style={{ backgroundColor: theme.brandingPanelColor}}
  >
    {(!!theme.brandingPanelLogo) && (
      <img src={theme.brandingPanelLogo} />
    )}
  </div>

);

