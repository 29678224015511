import immutable from 'immutable';
import { uploadValuationFile } from '../../../HttpRequests/valuations';
import handleException from '../../../Core/Helpers/handleException';
import { loadValuation } from '../State/valuation';

export default (files: FileList) => async (dispatch, getState) => {

  try {
    const { id } = getState().screens.ValuationView.valuation;
    
    const formdata = new FormData();
    formdata.append('file', files[0]);
    formdata.append('name', files[0].name);
    

    await uploadValuationFile({ id, data: formdata, config: {
    
      onUploadProgress: (progress) => {
        
        /* const uploaded =  */Math.floor((progress.loaded * 100) / progress.total);

      }
      
    }});

    let valuation = immutable.fromJS(getState().screens.ValuationView.valuation);
    valuation = valuation.set('payment_certificate_storage_path', files[0].name);

    valuation = valuation.toJS();

    dispatch(loadValuation(valuation));

  } catch(error) {

    handleException(error);

  }
}