import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Screen from '../../Components/Screen'
import SubValuationModal from '../../Modals/SubValuation'

import Header from './Components/Header'
import Table from './Components/Table'

const SubValuations = props => {

  const {
    subValuationModal,
    componentDidMount,
    onCloseSubValuationModal,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [])

  return (
    
    <Screen>

      <Header />

      <Table />

      {subValuationModal.show && (
        <SubValuationModal
          {...subValuationModal}
          onClose={onCloseSubValuationModal}
        />
      )}

    </Screen>

  )

}

SubValuations.propTypes = {
  subValuationModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  onCloseSubValuationModal: PropTypes.func.isRequired,
}

export default SubValuations
