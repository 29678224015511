import { getServicesBreakdownReport } from '../../../HttpRequests/dashboard';
import handleException from '../../../Core/Helpers/handleException';

import { loadData } from '../State/servicesBreakdownReport';

export default () => async (dispatch, getState) => {

  try {
    const params = {};

    const { dateRange } = getState().screens.Dashboard.servicesBreakdownReport;
    
    if (dateRange.length === 2) {
      params['filter[date_between]'] = `${dateRange[0].format('YYYY-MM-DD')}, ${dateRange[1].format('YYYY-MM-DD')}`  
    }

    const response = await getServicesBreakdownReport({ params });

    dispatch(loadData(response.data.data));

  } catch(error) {
    handleException(error);

  }
}