import { setFileList } from '../State/fileList';

export default (file) => (dispatch, getState) => {
  
  const { fileList } = getState().modals.EmailInvoice;

  const index = fileList.indexOf(file);
  const newFileList = fileList.slice();
  newFileList.splice(index, 1);

  dispatch(setFileList(newFileList));

}