/* eslint-disable no-underscore-dangle */

import { initialize } from 'redux-form';
import moment from 'moment';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';
import { allFiles } from '../../../HttpRequests/files';
import { setAllFiles } from '../State/allFiles';


export default () => async (dispatch) => {

  const { valuation } = getProps();
  
  const { project, user } = valuation;
  const contact = valuation.client_contact;
  
  const values = {
    to: [],
    cc: ['valuations@vividfinishes.co.uk'],
    bcc: [],
    subject: `${project.client_site.name} - Vivid Valuation`,
    body: '',
    id: valuation.id,
  };

  if (contact.email) values.to.push(contact.email);
  
  values.body += `Dear ${contact.first_name}\r\n\r\n`;
  values.body += `Please see attached our latest valuation for the ${project.client_site.name} project.\r\n\r\n`;
  if (valuation.valuation_to_date) values.body += `Please note this valuation is for works carried out up to ${moment(valuation.valuation_to_date).format('DD/MM/YYYY')}.\r\n\r\n`;
  values.body += `If you require any additional information, please do not hesitate to contact me directly.\r\n\r\n`;
  values.body += `Kind Regards\r\n\r\n`;
  values.body += `${user.first_name} ${user.last_name}`
  

  dispatch(initialize(FORM_NAME, values));

  //

  const response = await allFiles({ id: valuation.project_id });

  dispatch(setAllFiles(response.data.filter(filePath => filePath.includes('Purchase Orders') || filePath.includes('Other'))));

}