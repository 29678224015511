import { connect } from 'react-redux'
import Component from './Component'

import onClickEdit from './Handlers/onClickEdit'
import onClickCancel from './Handlers/onClickCancel'
import onClickSave from './Handlers/onClickSave'
import onClickSetByService from './Handlers/onClickSetByService'
import onClickExport from './Handlers/onClickExport'

const mapStateToProps = (state) => ({
  editable: state.screens.ProjectView.TargetCosts.editable,
})

const actionCreators = {
  onClickEdit,
  onClickCancel,
  onClickSave,
  onClickSetByService,
  onClickExport,
}

export default connect(mapStateToProps, actionCreators)(Component)