import { connect } from 'react-redux'
import SubcontractorDrag from './SubcontractorDrag'

const mapStateToProps = () => ({
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(SubcontractorDrag)
