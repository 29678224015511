import { connect } from 'react-redux';
import Component from './Component';

import selectItemsListData from '../../Selectors/selectItemsListData';

import onClickAddEstimateItem from './Handlers/onClickAddEstimateItem';
import onClickAddEstimateGroup from './Handlers/onClickAddEstimateGroup';
import onClickRemoveEstimateItem from './Handlers/onClickRemoveEstimateItem';
import onClickRemoveEstimateGroup from './Handlers/onClickRemoveEstimateGroup';
import onClickDuplicateEstimateItem from './Handlers/onClickDuplicateEstimateItem';
import onClickDuplicateEstimateGroup from './Handlers/onClickDuplicateEstimateGroup';
import onClickInsertDescription from './Handlers/onClickInsertDescription';
import onBlurEstimateItemField from './Handlers/onBlurEstimateItemField';
import onChangeEstimateItemField from './Handlers/onChangeEstimateItemField';
import onChangeEstimateGroupName from './Handlers/onChangeEstimateGroupName';
import onMoveEstimateItem from './Handlers/onMoveEstimateItem';
import onToggleEstimateItem from './Handlers/onToggleEstimateItem';


const mapStateToProps = (state) => ({
  listData: selectItemsListData(state),
  serviceTypes: state.core.serviceTypes,
  editable: state.screens.EstimateView.editable,
  selectedEstimateItemIds: state.screens.EstimateView.selectedEstimateItemIds,
})

const actionCreators = {
  onClickAddEstimateItem,
  onClickAddEstimateGroup,
  onClickRemoveEstimateItem,
  onClickRemoveEstimateGroup,
  onClickDuplicateEstimateItem,
  onClickDuplicateEstimateGroup,
  onClickInsertDescription,
  onBlurEstimateItemField,
  onChangeEstimateItemField,
  onChangeEstimateGroupName,
  onMoveEstimateItem,
  onToggleEstimateItem
};

export default connect(mapStateToProps, actionCreators)(Component);