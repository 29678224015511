import { combineReducers } from 'redux';

import { reducer as clients } from './State/clients';
import { reducer as pagination } from './State/pagination';
import { reducer as searchTerm } from './State/searchTerms';
import { reducer as filters } from './State/filters';
import { reducer as sorting } from './State/sorting';
import { reducer as fetching } from './State/fetching';
import { reducer as showClientModal } from './State/showClientModal';

export default combineReducers({
  clients,
  pagination,
  searchTerm,
  filters,
  sorting,
  fetching,
  showClientModal
})
