exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2dIi_pIytIo1e8TOPQBU0a th, td {\n  padding: 4px 16px;\n}\n\n._2dIi_pIytIo1e8TOPQBU0a th:first-child, td:first-child {\n  padding-left: 0px;\n}\n\n._2dIi_pIytIo1e8TOPQBU0a th:last-child, td:last-child {\n  padding-right: 0px;\n}\n\n._3JYa7J0byCOnPHhE5zLsAc {\n  text-align: right !important;\n}\n\n._11sIw8OhXxHsdcyEGGbBD:hover {\n  background-color: #f5f3f3;\n  cursor: pointer;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Timesheets/Components/TimesheetAdjustmentsPopover.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;CACnB;;AAED;EACE,kBAAkB;CACnB;;AAED;EACE,mBAAmB;CACpB;;AAED;EACE,6BAA6B;CAC9B;;AAED;EACE,0BAA0B;EAC1B,gBAAgB;CACjB","file":"TimesheetAdjustmentsPopover.css","sourcesContent":[".table th, td {\n  padding: 4px 16px;\n}\n\n.table th:first-child, td:first-child {\n  padding-left: 0px;\n}\n\n.table th:last-child, td:last-child {\n  padding-right: 0px;\n}\n\n.num {\n  text-align: right !important;\n}\n\n.row:hover {\n  background-color: #f5f3f3;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"table": "_2dIi_pIytIo1e8TOPQBU0a",
	"num": "_3JYa7J0byCOnPHhE5zLsAc",
	"row": "_11sIw8OhXxHsdcyEGGbBD"
};