import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { last, drop, dropRight } from 'lodash'

import { FORM_NAME } from '../Form';

const selectProjectFiles = state => formValueSelector(FORM_NAME)(state, 'projectFiles');

export default createSelector(
  selectProjectFiles,
  (projectFiles) => projectFiles ? projectFiles.map(path => ({
    path,
    filename: last(path.split('/')),
    folder: dropRight(drop(path.split('/'), 2)).join(' / '),
  })) : []
)