// @flow

import * as React from 'react';

import Toolbar from './Components/Toolbar';
import Table from './Components/Table';
import Schedule from './Components/Schedule';

type Props = {
  activeTab: string,
  searchTerm: string,
  project: {},


  onChangeTab: Function,
  onClickNewValuation: Function,
  onClickNewValuationDate: Function,
  onClickValuationDate: Function,
  onChangeSearch: Function
}

export default class extends React.PureComponent<Props> {

  render () {
    
    const {
      activeTab,
      searchTerm,
      project,
      onChangeTab,
      onClickNewValuation,
      onClickNewValuationDate,
      onClickValuationDate,
      onChangeSearch
    } = this.props;

    return (
      <React.Fragment>

        <Toolbar
          activeTab={activeTab}
          searchTerm={searchTerm}
          onChangeTab={onChangeTab}
          onClickNewValuation={onClickNewValuation}
          onClickNewValuationDate={onClickNewValuationDate}
          onChangeSearch={onChangeSearch}
        />

        {activeTab === 'valuations' && <Table project={project} />}

        {activeTab === 'schedule' && <Schedule project={project} onClickValuationDate={onClickValuationDate} />}

      </React.Fragment>
    )
  }
}