import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import moment from 'moment'
import styles from './Component.css'

const Outlet = (props) => (
  <div className={styles.outlet} {...props}>
    {props.children}
  </div>
)

const OutletLabel = (props) => (
  <div className={styles.outletLabel} {...props}>
    {props.children}
  </div>
)

const OutletData = (props) => (
  <div className={styles.outletData} {...props}>
    {props.children}
  </div>
)

// eslint-disable-next-line react/prop-types
const ItemLine = ({ description, amount }) => (
  <div className={styles.itemLine}>
    <div className={styles.itemLineDescription}>
      {description}
    </div>
    <div className={styles.itemLineAmount}>
      {`£${numeral(amount).format('0,0.00')}`}
    </div>
  </div>
)

// eslint-disable-next-line react/prop-types
const TotalLine = ({ description, amount }) => (
  <div className={styles.totalLine}>
    <div className={styles.totalLineDescription}>
      {description}
    </div>
    <div className={styles.totalLineAmount}>
      {`£${numeral(amount).format('0,0.00')}`}
    </div>
  </div>
)

export default class _Details_ extends React.PureComponent {

  static propTypes = {
    invoice: PropTypes.object.isRequired,
  }

  render () {

    const {
      invoice,
    } = this.props

    return (

      <div className={styles.wrapper}>

        <div className={styles.info}>

          <Outlet>
            <OutletLabel>Date</OutletLabel>
            <OutletData>
              {moment(invoice.date).format('DD/MM/YYYY')}
            </OutletData>
          </Outlet>

          <Outlet>
            <OutletLabel>Due</OutletLabel>
            <OutletData>
              {moment(invoice.due_date).format('DD/MM/YYYY')}
            </OutletData>
          </Outlet>

          <Outlet>
            <OutletLabel>Project No</OutletLabel>
            <OutletData>
              {invoice.project.id}
            </OutletData>
          </Outlet>

          <Outlet>
            <OutletLabel>PO No</OutletLabel>
            <OutletData>
              {invoice.order ? invoice.order.reference : '--'}
            </OutletData>
          </Outlet>

        </div>

        <div className={styles.itemLines}>

          {invoice.invoice_items.map(invoiceItem => (

            <ItemLine
              key={invoiceItem.id}
              description={invoiceItem.description}
              amount={invoiceItem.amount}
            />

          ))}

        </div>

        <div className={styles.totalLines}>

          <TotalLine
            description="Subtotal"
            amount={invoice.subtotal}
          />

          <TotalLine
            description={`VAT @ ${invoice.vat_percentage}%`}
            amount={invoice.vat}
          />

          <TotalLine
            description="Total"
            amount={invoice.total}
          />

        </div>

      </div>

    )
  }
}