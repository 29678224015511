import { createAction, handleActions } from 'redux-actions';

const loadOrderTotals = createAction("SCREENS/ORDERS/LOAD_ORDER_TOTALS");

const defaultState = {
  total: 0
}

const reducer = handleActions(
  {
    [loadOrderTotals]: (state, action) => action.payload
  },
  defaultState
);

export { loadOrderTotals, reducer };