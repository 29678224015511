import moment from 'moment';
import { getOrders } from '../../../HttpRequests/orders'
import { setFetching } from '../State/fetching';
import { loadOrders } from '../State/orders';
import { setPagination } from '../State/pagination';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const { pagination, sorting, searchTerm, filters } = getState().screens.Orders;

  try {

    const params = {};
    params.include = [
      'client_contact',
      'project',
      'project.client_site',
      'project.client_site.client',
      /* 'order_files' */
    ].join();

    params['page[size]'] = pagination.pageSize;
    params['page[number]'] = pagination.current;

    if (sorting.columnKey) {
      params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
    }

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (filters.client_id.length > 0) {
      params['filter[client_id]'] = filters.client_id.join(',');
    }

    if (filters.extra_works !== 'all') {
      params['filter[extra_works]'] = filters.extra_works;
    }
    
    if (Array.isArray(filters.date_between) && filters.date_between.length === 2)  {
      params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join();
    }

    dispatch(setFetching(true));

    const response = await getOrders({ params });

    dispatch(loadOrders(response.data.data));
    
    dispatch(setPagination({
      total: response.data.pagination.total,
      pageSize: response.data.pagination.per_page,
      current: response.data.pagination.current_page
    }));

    dispatch(setFetching(false))

  } catch (error) {

    handleException(error);

  }
}