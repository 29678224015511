import { Modal } from 'antd'
import { openEditorModal } from '../../../State/editorModal'
import deleteItems from '../../../Services/deleteItems'
import downloadFile from '../../../Services/downloadFile'

const onClickDelete = () => (dispatch, getState) => {

  const { selectedItemPath } = getState().modules.FileManager

  Modal.confirm({
    title: 'Delete Item',
    content: 'Are you sure you want to delete this item?',
    okText: 'Yes, Delete',
    onOk: () => dispatch(deleteItems([selectedItemPath])),
  })

}

export default (key) => (dispatch) => {

  switch (key) {
    
    case 'RENAME_DIRECTORY':
      dispatch(openEditorModal())
      break
    
    case 'DELETE_DIRECTORY':
      dispatch(onClickDelete())
      break

    case 'DOWNLOAD_FILE':
      dispatch(downloadFile())
      break
    
    case 'RENAME_FILE':
      dispatch(openEditorModal())
      break
    
    case 'DELETE_FILE':
      dispatch(onClickDelete())
      break

    default:
      break

  }

}
