import { setProjectSortBy } from '../../../State/controls'
import fetch from '../../../Services/fetch'

export default (key) => (dispatch) => {

  dispatch(setProjectSortBy(key))

  dispatch(fetch())

}
