import createDirectory from '../Services/createDirectory'
import renameDirectory from '../Services/renameDirectory'
import renameFile from '../Services/renameFile'

export default async (values, dispatch) => {

  const { action } = values

  if (action === 'create_directory') {
    dispatch(createDirectory(values))
  }

  if (action === 'rename_directory') {
    dispatch(renameDirectory(values))
  }

  if (action === 'rename_file') {
    dispatch(renameFile(values))
  }

}
