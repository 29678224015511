import { connect } from 'react-redux'
import { formValueSelector, isSubmitting } from 'redux-form'
import Timesheet from './Timesheet'

import { FORM_NAME } from './Form'

import componentWillMount from './Handlers/componentWillMount'
import onClickSubmit from './Handlers/onClickSubmit'
import onClickCancel from './Handlers/onClickCancel'
import onClickDelete from './Handlers/onClickDelete'
import onClickRow from './Handlers/onClickRow'
import onClickNewTimesheetPosting from './Handlers/onClickNewTimesheetPosting'
import onCloseTimesheetPostingModal from './Handlers/onCloseTimesheetPostingModal'
import didCreateTimesheetPosting from './Handlers/didCreateTimesheetPosting'
import didUpdateTimesheetPosting from './Handlers/didUpdateTimesheetPosting'
import didDeleteTimesheetPosting from './Handlers/didDeleteTimesheetPosting'

const mapStateToProps = (state) => ({
  subcontractors: state.core.subcontractors,
  dayRate: formValueSelector(FORM_NAME)(state, 'day_rate') || false,
  timesheetPostings: formValueSelector(FORM_NAME)(state, 'timesheet_postings') || [],
  submitting: isSubmitting(FORM_NAME)(state),
  timesheetPostingModal: state.modals.Timesheet.timesheetPostingModal,
})

const actionCreators = {
  componentWillMount,
  onClickSubmit,
  onClickCancel,
  onClickDelete,
  onClickRow,
  onClickNewTimesheetPosting,
  onCloseTimesheetPostingModal,
  didCreateTimesheetPosting,
  didUpdateTimesheetPosting,
  didDeleteTimesheetPosting,
}

export default connect(mapStateToProps, actionCreators)(Timesheet)
