import exportStatistics from '../Services/exportStatistics';

import { getProps } from '../props';

export default async (values, dispatch) => {

  const { onClose } = getProps();
  
  await dispatch(exportStatistics(values));

  onClose();

};
