import { connect } from 'react-redux'
import Header from './Header'

import selectProjects from './Selectors/selectProjects'

import onChangeFilter from './Handlers/onChangeFilter'

const mapStateToProps = (state) => ({
  projectStatuses: state.core.projectStatuses,
  subcontractor: state.screens.SubProject.subcontractor,
  projects: selectProjects(state),
  filters: state.screens.SubProject.filters,
})

const actionCreators = {
  onChangeFilter,
}

export default connect(mapStateToProps, actionCreators)(Header)
