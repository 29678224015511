import React from 'react'
import { Menu, Dropdown, Input, Button } from 'antd'
import { CloudDownloadOutlined } from '@ant-design/icons'
import pluralize from 'pluralize'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 25,
  },
  heading: {
    flex: 1,
    margin: 0,
  },
}

type Props = {
  setup: Object,
  searchTerm: String,
  data: [],
  onChangeSearch: Function,
  onSubmitSearch: Function,
  onClickExport: Function,
}

export default class extends React.PureComponent<Props> {

  render() {

    const {
      setup,
      searchTerm,
      data,
      onChangeSearch,
      onSubmitSearch,
      onClickExport,
    } = this.props

    const exportMenu = (
      <Menu onClick={onClickExport}>
        <Menu.Item key='excel'>Export to Excel</Menu.Item>
        <Menu.Item key='csv'>Export to CSV</Menu.Item>
      </Menu>
    )

    return (

      <div style={styles.wrapper}>

        <h1 style={styles.heading}>
          Insights
        </h1>

        {!!setup.field && (

          <div>

            <Input.Search
              value={searchTerm}
              onChange={onChangeSearch}
              onSearch={onSubmitSearch}
              placeholder={`Search ${pluralize(setup.fields[setup.model][setup.field])}`}
              enterButton
              style={{ width: 250 }}
            />

          </div>

        )}

        {!!setup.field && !!data.length && <div style={{ width: 10 }} />}

        {!!data.length && (

          <Dropdown overlay={exportMenu}>
            <Button>
              <CloudDownloadOutlined style={{ fontSize: 16, marginTop: 3 }} />
            </Button>
          </Dropdown>

        )}

      </div>
      
    )

  }

}
