/* eslint-disable no-nested-ternary */
import { initialize } from 'redux-form'
import { findUser } from '../../../HttpRequests/users'
import { getProps } from '../props'
import { FORM_NAME } from '../Form'

export default () => async (dispatch) => {

  const { id } = getProps()

  const params = {
    include: 'subcontractor',
    append: 'status',
  }

  if (id) {

    const response = await findUser({ id, params })

    const user = response.data

    dispatch(initialize(FORM_NAME, {
      ...user,
      subcontractor_id: user.subcontractor ? user.subcontractor.id : undefined,
    }))

  } else {

    dispatch(initialize(FORM_NAME, {
      //
    }))

  }

}
