import chance from 'chance'
import { setSubOrderGroups } from '../State/subOrder'

export default (guid, action) => (dispatch, getState) => {

  const { subOrder } = getState().screens.SubOrder

  const newSubOrderGroups = []

  subOrder.sub_order_groups.forEach(subOrderGroup => {

    // Have they removed the sub order group?
    if (subOrderGroup.guid === guid && action === 'remove') {
      return
    }

    const newSubOrderItems = []

    subOrderGroup.sub_order_items.forEach(subOrderItem => {

      if (subOrderItem.guid !== guid) {
        newSubOrderItems.push({ ...subOrderItem })
        return
      }

      if (action === 'add') {
        newSubOrderItems.push({ ...subOrderItem })
        newSubOrderItems.push({
          guid: chance().guid(),
          unit: 'm',
        })
      }

      if (action === 'duplicate') {
        newSubOrderItems.push({ ...subOrderItem })
        newSubOrderItems.push({
          ...subOrderItem,
          id: undefined,
          guid: chance().guid(),
        })
      }

      if (action === 'remove') {
        // We just simply omit it
      }

    })

    // Have they added via sub order group footer or placeholder?
    if (subOrderGroup.guid === guid && action === 'add') {
      newSubOrderItems.push({
        guid: chance().guid(),
        unit: 'm',
      })
    }

    newSubOrderGroups.push({
      ...subOrderGroup,
      sub_order_items: newSubOrderItems,
    })
    
  })

  dispatch(setSubOrderGroups(newSubOrderGroups))

}
