// @flow

import { fromJS } from 'immutable';
import { loadOrder } from '../State/order';
import addOrderItem from './addOrderItem';
import type { OrderGroup } from '../types';

export default () => (dispatch: Function, getState: Function) => {
  
  let order = fromJS(getState().screens.OrderView.order);

  const orderGroup: OrderGroup = {
    id: null,
    order_id: order.get('id'),
    name: null,
    order_items: []
  }
  
  order = order.update('order_groups', (list) => list.push(orderGroup));

  order = order.toJS();
  
  dispatch(loadOrder(order));

  dispatch(addOrderItem({
    orderGroupIndex: (order.order_groups.length-1)
  }))

}