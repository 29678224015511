import { createAction, handleActions } from 'redux-actions';

const setPagination = createAction("SCREENS/VALUATIONS/SET_PAGINATION");

const defaultState = {
  current: 1,
  pageSize: 10,
  total: 0
};

const reducer = handleActions(
  {
    [setPagination]: (state, action) => Object.assign({}, state, action.payload)
  },
  defaultState
);

export { setPagination, reducer };