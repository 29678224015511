import saveItems from '../../../Services/saveItems'
import { resetEditing } from '../../../State/editing'

export default () => async (dispatch) => {

  await dispatch(saveItems())

  dispatch(resetEditing())

}
