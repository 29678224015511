import { connect } from 'react-redux'
import Sidebar from './Sidebar'

import selectStatusLogs from './Selectors/selectStatusLogs'

const mapStateToProps = (state) => ({
  subValuation: state.screens.SubValuation.subValuation,
  statusLogs: selectStatusLogs(state),
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(Sidebar)
