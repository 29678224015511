import React from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Select } from 'antd'
import styles from './styles.css'

const Spacer = () => <div style={{ width: 12 }} />

const Header = props => {

  const {
    search,
    filters,
    onChangeSearch,
    onSearch,
    onChangeFilter,
    onClickNew,
  } = props

  return (

    <div className={styles.wrapper}>

      <div className={styles.left}>

        <Input.Search
          value={search}
          onChange={e => onChangeSearch(e.target.value)}
          onSearch={onSearch}
          placeholder='Search...'
          style={{ width: 250 }}
        />

        <Spacer />

        <Select
          value={filters.completionStatuses}
          onChange={value => onChangeFilter('completionStatuses', value)}
          mode='multiple'
          showSearch
          optionFilterProp='children'
          placeholder='All Statuses'
          style={{ width: 200 }}
        >
          <Select.OptGroup label='Status'>
            {['Pending', 'Approved', 'Paid'].map(completionStatus => (
              <Select.Option key={completionStatus} value={completionStatus}>
                {completionStatus}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </Select>

      </div>

      <div className={styles.right}>

        <Button type='primary' onClick={onClickNew}>New Valuation</Button>

      </div>

    </div>

  )

}

Header.propTypes = {
  search: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  onChangeSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onClickNew: PropTypes.func.isRequired,
}

export default Header
