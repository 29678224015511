// @flow

import { push } from 'connected-react-router';
import { getTeamUrl } from 'Common';
import fetchEstimate from './fetchEstimate';
import { setEditable } from '../State/editable';

export default (id: number) => async (dispatch: Function) => {

  dispatch(setEditable(false));

  dispatch(push(getTeamUrl(`estimates/${id}/view`)));

  await dispatch(fetchEstimate());

}