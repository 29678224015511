// @flow

import { getProps } from '../props';
import create from '../Services/create';
import update from '../Services/update';

export default async (values: Function, dispatch: Function) => {

  const { onClose, didCreate, didUpdate } = getProps();

  if (!values.id) {

    const valuation = await dispatch(create(values));

    onClose();
    
    if (didCreate) didCreate(valuation);

  } else {

    const valuation = await dispatch(update(values));

    onClose();
    
    if (didUpdate) didUpdate(valuation);

  }

}