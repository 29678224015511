// @flow

import { fromJS } from 'immutable';
import { assign } from 'lodash';
import { loadEstimate } from '../State/estimate';

export type DuplicateEstimateItemParams = {
  estimateGroupIndex: number,
  estimateItemIndex: number
}

export default (params: DuplicateEstimateItemParams) => (dispatch: Function, getState: Function) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);

  const sourceEstimateItem = estimate.getIn(['estimate_groups', params.estimateGroupIndex, 'estimate_items', params.estimateItemIndex]).toJS();

  const estimateItem = assign({}, sourceEstimateItem, { id: null });
  
  estimate = estimate.updateIn(
    ['estimate_groups', params.estimateGroupIndex, 'estimate_items'],
    (list) => list.insert(params.estimateItemIndex + 1, estimateItem)
  );

  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}