// @flow

import * as React from 'react';
import classnames from 'classnames';
import { CaretDownFilled } from '@ant-design/icons';
import styles from './TableCellSelect.css';

type Props = {
  options: any,
  value: any,
  type?: 'default'|'outlined',
  color?: any,
  onChange: (value: any) => void,
  disabled?: any
}

const TableCellSelect = (props: Props) => {

  const {
    options,
    value,
    type,
    color,
    onChange,
    disabled
  } = props;

  const _value = value === null ? 'null' : value;

  const _onChange = (event) => {
    let value = event.target.value === 'null' ? null : event.target.value;
    return onChange(value);
  };

  return (
    <div
      className={classnames( styles.wrapper, {[styles.wrapperOutlined]: type === 'outlined'})}
      style={type === 'outlined' ? { borderColor: color } : {}}
    >

      {!disabled && (
        <CaretDownFilled className={styles.caret} style={type === 'outlined' ? { color } : {}} />
      )}

      <select
        className={classnames( styles.select, {[styles.selectOutlined]: type === 'outlined'})}
        value={_value}
        onChange={_onChange}
        style={type === 'outlined' ? { color } : {}}
        disabled={disabled}
      >

        {options.map(option => (
          <option
            value={option.value === null ? 'null' : option.value}
            key={option.value === null ? 'null' : option.value}
          >
            {option.label}
          </option>
        ))}

      </select>

    </div>
  );
}

TableCellSelect.defaultProps = {
  type: 'default',
  color: '#444'
}

export default TableCellSelect;