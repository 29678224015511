import { combineReducers } from 'redux';

import { reducer as projects } from './State/projects';
import { reducer as projectTotals } from './State/projectTotals';
import { reducer as pagination } from './State/pagination';
import { reducer as searchTerm } from './State/searchTerms';
import { reducer as fetching } from './State/fetching';
import { reducer as filters } from './State/filters';
import { reducer as sorting } from './State/sorting';

export default combineReducers({

  projects,
  projectTotals,
  pagination,
  searchTerm,
  fetching,
  filters,
  sorting
  
})
