import { createAction, handleActions } from 'redux-actions';

const setSelectedEmailId = createAction("SCREENS/PROJECT_VIEW/SET_SELECTED_EMAIL_ID");

const defaultState = null;

const reducer = handleActions(
  {
    [setSelectedEmailId]: (state, action) => action.payload
  },
  defaultState
);

export { setSelectedEmailId, reducer };