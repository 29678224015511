// @flow

import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchProjects from '../../../Core/Services/fetchProjects';
import fetchProject from '../Services/fetchProject';
import alertIfUpliftOnEstimate from '../Services/alertIfUpliftOnEstimate';
import promptForEstimateOptionals from '../Services/promptForEstimateOptionals';

export default (props: any) => async (dispatch: Function) => {

  setProps(props);

  const { isCreatingFromEstimate , estimate, order, projectId } = props;

  if (isCreatingFromEstimate && estimate.id) {

    dispatch(alertIfUpliftOnEstimate(estimate, 500));

    dispatch(promptForEstimateOptionals(estimate.id, 500));

  }

  dispatch(initialize());

  await dispatch(fetchProjects());

  if (order || projectId) {
    await dispatch(fetchProject());
  }

  dispatch(initialize());
  
}