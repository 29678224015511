import React from 'react'
import PropTypes from 'prop-types'
import { Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

export default class Form extends React.PureComponent {

  static propTypes = {
    onUpload: PropTypes.func.isRequired,
  }

  render() {

    const {
      onUpload,
    } = this.props

    const uploadprops = {
      name: 'file',
      multiple: true,
      customRequest: onUpload,
    }
    
    return (

      <div>

        <Upload.Dragger {...uploadprops}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>Click or drag file to this area to upload</p>
          <p className='ant-upload-hint'>Select files to be uploaded</p>
        </Upload.Dragger>

      </div>

    )

  }

}
