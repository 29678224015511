import { createAction, handleActions } from 'redux-actions'

const openTimesheetAdjustmentModal = createAction('MODULES/TIMESHEETS_DRAWER/OPEN_TIMESHEET_ADJUSTMENT_MODAL')
const closeTimesheetAdjustmentModal = createAction('MODULES/TIMESHEETS_DRAWER/CLOSE_TIMESHEET_ADJUSTMENT_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openTimesheetAdjustmentModal]: (state, { payload }) => ({ ...payload, show: true }),
    [closeTimesheetAdjustmentModal]: () => defaultState,
  },
  defaultState,
)

export { openTimesheetAdjustmentModal, closeTimesheetAdjustmentModal, reducer }
