import { uploadFile } from '../../../HttpRequests/files';
import { getProps } from '../props';

export default (fileProps) => async () => {

  const { file, onSuccess, onError, onProgress } = fileProps;
  
  try {
    
    const { currentDirectoryPath, didUpload } = getProps();
  
    const data = new FormData();
    data.append('file', file);
    data.append('path', currentDirectoryPath);
    data.append('name', fileProps.file.name);

    const config = {
      onUploadProgress: (progress) => {
        const uploaded = Math.floor((progress.loaded * 100) / progress.total);
        onProgress({ percent: uploaded })
      }
    }

    const uploadedFile = await uploadFile({ data, config });

    onSuccess(null, file);

    if (didUpload) didUpload()
    
    return uploadedFile;

  } catch(error) {

    onError('Upload error', file);

    throw error;

  }
}