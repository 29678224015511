import { createAction, handleActions } from 'redux-actions';

const loadValuationStatuses = createAction("CORE/VALUATION_STATUSES/LOAD_VALUATION_STATUSES");
const clearValuationStatuses = createAction("CORE/VALUATION_STATUSES/CLEAR_VALUATION_STATUSES");

const defaultState = []

const reducer = handleActions(
  {
    [loadValuationStatuses]: (state, action) => action.payload,
    [clearValuationStatuses]: () => []
  },
  defaultState
);

export { loadValuationStatuses, clearValuationStatuses, reducer };