import { message } from 'antd'
import { uninvoiceRetentionPayment } from '../../../HttpRequests/retentionPayments'
import fetch from './fetch'
import handleException from '../../../Core/Helpers/handleException'

export default (id) => async (dispatch) => {

  try {

    await uninvoiceRetentionPayment({ id })

    await dispatch(fetch())

    message.success('Undone Invoice')
    
  } catch (error) {

    handleException(error)

  }
  
}