// @flow

import { fromJS } from 'immutable';
import { arrayMove } from 'react-sortable-hoc';
import { clone } from 'lodash';
import selectCaveatsListData from '../Selectors/selectCaveatsListData';
import { loadOrder } from '../State/order';

export type MoveOrderCaveatParams = {
  oldIndex: number,
  newIndex: number
}

export default (params: MoveOrderCaveatParams) => (dispatch: Function, getState: Function) => {


  // get immutable copy of the order
  let order = fromJS(getState().screens.OrderView.order);

  // select the mapped list items
  let listData = selectCaveatsListData(getState());



  // dont't carry on if index hasnt moved
  if (params.oldIndex === params.newIndex) return;

  // don't allow it to be dropped at the beginning (before the first header)
  if (params.newIndex === 0) return;

  // don't allow it to be dropped at the end (after the last footer)
  if (params.newIndex === (listData.length-1)) return;
  


  // get the old orderCaveat, orderCaveatIndex
  const {
    orderCaveat: oldOrderCaveat,
    orderCaveatIndex: oldOrderCaveatIndex
  } = listData[params.oldIndex];

  // prepare the new orderCaveat, orderCaveatIndex

  listData = arrayMove(listData, params.oldIndex, params.newIndex);

  const listItemBefore = listData[params.newIndex - 1];

  if (listItemBefore.type === 'Footer') return; // don't allow it to be dropped between footer & header

  const newOrderCaveatIndex = listItemBefore.orderCaveat ? (listItemBefore.orderCaveatIndex + 1) : 0;
  const newOrderCaveat = clone(oldOrderCaveat)



  // make the changes

  if (oldOrderCaveatIndex < newOrderCaveatIndex) {

    // insert the orderCaveat in the new position
    order = order.updateIn(
      ['order_caveats'],
      (list) => list.insert(newOrderCaveatIndex, newOrderCaveat)
    );

    // remove the orderCaveat from the old position
    order = order.removeIn(['order_caveats', oldOrderCaveatIndex]);

  } else {

    // remove the orderCaveat from the old position
    order = order.removeIn(['order_caveats', oldOrderCaveatIndex]);

    // insert the orderCaveat in the new position
    order = order.updateIn(
      ['order_caveats'],
      (list) => list.insert(newOrderCaveatIndex, newOrderCaveat)
    );

  }


  
  // save
  
  order = order.toJS();
  
  dispatch(loadOrder(order));

}