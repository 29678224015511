import screenProps from '../../../screenProps'
import { getSubValuations } from '../../../../../HttpRequests/subValuations'
import { setSubValuations } from '../State/subValuations'
import { startFetching, finishFetching } from '../State/fetching'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { id } = screenProps().match.params

  const state = getState()

  const { filters: projectFilters } = state.screens.SubProject

  const { search, filters, sorting } = state.screens.SubProject.SubValuations

  const params = {
    search,
    'filter[subcontractor_id]': id,
    'filter[project_status_key]': projectFilters.projectStatusKey || undefined,
    'filter[project_id]': projectFilters.projectId || undefined,
    sort: sorting.order ? `${sorting.order === 'descend' ? '-' : ''}${sorting.columnKey}` : '-date',
    include: [
      'project.client_site.client',
      'user',
    ].join(','),
    append: 'reference,last_valuation,sub_project',
  }

  if (filters.completionStatuses.length) params['filter[status]'] = filters.completionStatuses.join(',')

  dispatch(startFetching())

  try {

    const response = await getSubValuations({ params })

    dispatch(setSubValuations(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
