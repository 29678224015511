import { createAction, handleActions } from 'redux-actions'
import { includes, without, concat } from 'lodash'

const selectItem = createAction('SCREENS/SUB_ORDER/SELECT_ITEM')
const deselectItem = createAction('SCREENS/SUB_ORDER/DESELECT_ITEM')
const toggleItem = createAction('SCREENS/SUB_ORDER/TOGGLE_ITEM')
const setSelectedItems = createAction('SCREENS/SUB_ORDER/SET_SELECTED_ITEMS')
const resetSelectedItems = createAction('SCREENS/SUB_ORDER/RESET_SELECTED_ITEMS')

const defaultState = []

const reducer = handleActions(
  {
    [selectItem]: (state, { payload }) => !includes(state, payload) ? concat(state, payload) : state,
    [deselectItem]: (state, { payload }) => includes(state, payload) ? without(state, payload) : state,
    [toggleItem]: (state, { payload }) => includes(state, payload) ? without(state, payload) : concat(state, payload),
    [setSelectedItems]: (state, { payload }) => payload,
    [resetSelectedItems]: () => defaultState,
  },
  defaultState,
)

export { selectItem, deselectItem, toggleItem, setSelectedItems, resetSelectedItems, reducer }
