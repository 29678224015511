/* eslint-disable no-underscore-dangle */

export default function createPropsHelper () {

  let _props = {};

  const get = () => _props

  const set = (props) => {
    _props = props
  }

  const clear = () => ({})

  return { get, set, clear }

}