import { createSelector } from 'reselect';

import type { OrderTotals } from '../../../types';

const selectOrder = state => state.screens.OrderView.order;

export default createSelector(
  selectOrder,
  (order) => {

    const orderTotals: OrderTotals = {
      target: Number(order.target_total),
      subtotal: 0,
      discount: 0,
      total: 0,
      onTarget: false
    }

    if (order) {

      order.order_groups.forEach((orderGroup) => orderGroup.order_items
        .filter((orderItem) => orderItem.flag !== 'Omit' && orderItem.flag !== 'By Others')
        .forEach((orderItem) => {

          orderTotals.subtotal += Number(orderItem.subtotal);
          orderTotals.discount += Number(orderItem.discount);
          orderTotals.total += Number(orderItem.total);

        })
      );

    }

    orderTotals.target = orderTotals.target.toFixed(2);
    orderTotals.subtotal = orderTotals.subtotal.toFixed(2);
    orderTotals.discount = orderTotals.discount.toFixed(2);
    orderTotals.total = orderTotals.total.toFixed(2);

    orderTotals.onTarget = (orderTotals.total === orderTotals.target);
    
    return orderTotals;

  }
)