// @flow
import * as React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import styles from './GroupPlaceholder.css';

import type {
  AddOrderItemParams
} from '../../../types';

type Props = {
  editable: boolean,
  orderGroupIndex: number,
  onClickAddOrderItem: (params: AddOrderItemParams) => void
}

class Component extends React.PureComponent<Props> {

  render () {

    const {
      editable,
      orderGroupIndex,
      onClickAddOrderItem
    } = this.props;

    return (

      <div
        className={styles.wrapper}
      >

        <div className={styles.message}>
          
          {'This group is empty. '}

          {editable && (
            <span>
              <span
                className={styles.addLink}
                onClick={() => onClickAddOrderItem({ orderGroupIndex })}
                role="presentation"
              >
                {'Add an item'}
              </span>
              {' to get started.'}
            </span>
          )}
          
        </div>

      </div>

    )
  }
}

export default SortableElement(Component);