import { getTeamUrl } from 'Common'
import getParams from '../Utils/getParams'

export default () => (dispatch, getState) => {

  const state = getState()

  const params = getParams(state)

  let url = 'sub_invoices/export?'

  Object.keys(params).forEach(key => {
    if (params[key] !== undefined) url += `&${key}=${params[key]}`
  })

  window.open(getTeamUrl(url, 'exports'))

}
