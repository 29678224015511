exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1Ba9Jo6GNZXRBr60OJTAyG {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  border-bottom: 1px solid #DDD;\n  margin-top: -1px;\n}\n\n._2_jFAUpz9JlNxZ8P57Nu_5 {\n  padding: 10px;\n  padding-left: 20px;\n  padding-right: 20px;\n  flex: 1;\n}\n\n._2_jFAUpz9JlNxZ8P57Nu_5:hover {\n  cursor: pointer;\n  background-color: #E6F7FF;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Modals/SelectItemDescription/Components/List/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,gBAAgB;EAChB,8BAA8B;EAC9B,iBAAiB;CAClB;;AAED;EACE,cAAc;EACd,mBAAmB;EACnB,oBAAoB;EACpB,QAAQ;CACT;;AAED;EACE,gBAAgB;EAChB,0BAA0B;CAC3B","file":"Component.css","sourcesContent":[".itemDescriptionWrapper {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  border-bottom: 1px solid #DDD;\n  margin-top: -1px;\n}\n\n.itemDescriptionContent {\n  padding: 10px;\n  padding-left: 20px;\n  padding-right: 20px;\n  flex: 1;\n}\n\n.itemDescriptionContent:hover {\n  cursor: pointer;\n  background-color: #E6F7FF;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"itemDescriptionWrapper": "_1Ba9Jo6GNZXRBr60OJTAyG",
	"itemDescriptionContent": "_2_jFAUpz9JlNxZ8P57Nu_5"
};