import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import List from './Components/List';

export default class extends React.PureComponent {

  static propTypes = {
    componentWillMount: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired
  }

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      onClickCancel,
      onClickSubmit
    } = this.props;

    return (
        
      <Modal
        visible  
        title="Select Caveats" 
        onCancel={onClickCancel}
        onOk={onClickSubmit}
        width={700}
      >
        
        <List />

      </Modal>
      
    )
  }
}