import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Screen from '../../Components/Screen'
import SubOrderModal from '../../Modals/SubOrder'
import SubValuationModal from '../../Modals/SubValuation'

import Header from './Components/Header'
import Projects from './Components/Projects'
import Summary from './Components/Summary'

const SubProjects = props => {

  const {
    mode,
    subOrderModal,
    subValuationModal,
    componentDidMount,
    onCloseSubOrderModal,
    onCloseSubValuationModal,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [])

  return (
    
    <Screen>

      <Header />

      {mode === 'projects' && <Projects />}

      {mode === 'summary' && <Summary />}

      {subOrderModal.show && (
        <SubOrderModal
          {...subOrderModal}
          onClose={onCloseSubOrderModal}
        />
      )}

      {subValuationModal.show && (
        <SubValuationModal
          {...subValuationModal}
          onClose={onCloseSubValuationModal}
        />
      )}

    </Screen>

  )

}

SubProjects.propTypes = {
  mode: PropTypes.string.isRequired,
  subOrderModal: PropTypes.object.isRequired,
  subValuationModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  onCloseSubOrderModal: PropTypes.func.isRequired,
  onCloseSubValuationModal: PropTypes.func.isRequired,
}

export default SubProjects
