exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1aFgAYIj5YfyifpGLubXER {\n  position: relative;\n  flex: 1;\n}\n\n._2zWQSeZeNuZMQgWH-gT-5K {\n  position: absolute;\n  right: 50px;\n  top: 25px;\n  display: flex;\n  width: 110px;\n  height: 25px;\n  border: 1px solid #EB7231;\n  border-radius: 3px;\n  overflow: hidden;\n  cursor: pointer;\n  display: none;\n}\n\n.cbt2Zanr4A7CBfIP2SXn4 {\n  flex: 1;\n  color: #EB7231;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  font-size: 10px;\n}\n\n._2v9d30rR7Rv5r72tuiOfvy {\n  background-color: #EB7231;\n  color: white;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Dashboard/Components/Chart.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,QAAQ;CACT;;AAED;EACE,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,cAAc;EACd,aAAa;EACb,aAAa;EACb,0BAA0B;EAC1B,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;CACf;;AAED;EACE,QAAQ;EACR,eAAe;EACf,cAAc;EACd,oBAAoB;EACpB,wBAAwB;EACxB,mBAAmB;EACnB,gBAAgB;CACjB;;AAED;EACE,0BAA0B;EAC1B,aAAa;CACd","file":"Chart.css","sourcesContent":[".panel {\n  position: relative;\n  flex: 1;\n}\n\n.toggle {\n  position: absolute;\n  right: 50px;\n  top: 25px;\n  display: flex;\n  width: 110px;\n  height: 25px;\n  border: 1px solid #EB7231;\n  border-radius: 3px;\n  overflow: hidden;\n  cursor: pointer;\n  display: none;\n}\n\n.item {\n  flex: 1;\n  color: #EB7231;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  font-size: 10px;\n}\n\n.itemSelected {\n  background-color: #EB7231;\n  color: white;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"panel": "_1aFgAYIj5YfyifpGLubXER",
	"toggle": "_2zWQSeZeNuZMQgWH-gT-5K",
	"item": "cbt2Zanr4A7CBfIP2SXn4",
	"itemSelected": "_2v9d30rR7Rv5r72tuiOfvy"
};