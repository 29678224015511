import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Divider } from 'antd'
import { SelectField, TextAreaField, TimePickerField } from 'FormFields'

const labelColSpan = 6;
const wrapperColSpan = 16;

export default class extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool.isRequired,
  }

  render() {

    const {
      submitting,
    } = this.props

    return (

      <React.Fragment>

        <Field
          name='type'
          label='Type'
          component={SelectField}
          options={[
            { label: 'Addition', value: 'addition' },
            { label: 'Reduction', value: 'reduction' },
          ]}
          allowClear={false}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 10 }}
        />

        <Field
          name='time'
          label='Time'
          component={TimePickerField}
          secondStep={60}
          showNow={false}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          fieldCol={{ span: 10 }}
        />

        <Divider />

        <Field
          name='reason'
          label='Reason'
          component={TextAreaField}
          disabled={submitting}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

      </React.Fragment>

    )

  }

}
