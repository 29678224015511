import { createSelector } from 'reselect'

const selectCurrentDirectoryContents = state => state.modules.FileManager.currentDirectoryContents

export default createSelector(
  selectCurrentDirectoryContents,
  (currentDirectoryContents) => [
    ...currentDirectoryContents.directories.map(directory => ({
      type: 'directory',
      ...directory,
    })),
    ...currentDirectoryContents.files.map(file => ({
      type: 'file',
      ...file,
    })),
  ],
)
