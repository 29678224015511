import screenProps from '../../../screenProps'
import { getSubProjects } from '../../../../../HttpRequests/subProjects'
import { setSubProjects } from '../State/subProjects'
import { startFetching, finishFetching } from '../State/fetching'
import handleException from '../../../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { id } = screenProps().match.params

  const { filters: projectFilters } = getState().screens.SubProject

  const { filters } = getState().screens.SubProject.Overview

  const params = {
    subcontractor_ids: id,
    status_key: projectFilters.projectStatusKey || undefined,
    project_ids: projectFilters.projectId || undefined,
  }

  if (filters.completionStatuses.length) params.completion_statuses = filters.completionStatuses.join(',')

  dispatch(startFetching())

  try {

    const response = await getSubProjects({ params })

    dispatch(setSubProjects(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
