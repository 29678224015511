import { createAction, handleActions } from 'redux-actions'

const setSubOrders = createAction('SCREENS/SUB_INVOICE/SET_SUB_ORDERS')
const resetSubOrders = createAction('SCREENS/SUB_INVOICE/RESET_SUB_ORDERS')

const defaultState = []

const reducer = handleActions(
  {
    [setSubOrders]: (state, { payload }) => payload,
    [resetSubOrders]: () => defaultState,
  },
  defaultState,
)

export { setSubOrders, resetSubOrders, reducer }
