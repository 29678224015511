import React from 'react'
import PropTypes from 'prop-types'
import { SortableElement } from 'react-sortable-hoc'
import styles from './styles.css'

class SubOrderGroup extends React.PureComponent {

  static propTypes = {
    subOrderGroup: PropTypes.object.isRequired,
    onUpdateField: PropTypes.func.isRequired,
  }

  state = {
    name: undefined,
  }

  render() {

    const {
      subOrderGroup,
      onUpdateField,
    } = this.props

    return (

      <div className={styles.wrapper}>

        <input
          value={this.state.name !== undefined ? this.state.name : (subOrderGroup.name || '')}
          onChange={e => this.setState({ name: e.target.value })}
          onBlur={e => {
            onUpdateField(subOrderGroup.guid, 'name', e.target.value)
            this.setState({ name: undefined })
          }}
          placeholder='Untitled Group'
          className={styles.name}
        />

        <div className={styles.headings}>

          <div className={styles.cell}>
            Qty
          </div>

          <div className={styles.cell}>
            Unit
          </div>

          <div className={styles.cell}>
            Rate
          </div>

          <div className={styles.cell}>
            Total
          </div>

        </div>

      </div>

    )

  }

}

export default SortableElement(SubOrderGroup)
