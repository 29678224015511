import { createAction, handleActions } from 'redux-actions';

const loadClient = createAction("MODALS/CLIENT/LOAD_CLIENT");
const clearClient = createAction("MODALS/CLIENT/CLEAR_CLIENT");

const defaultState = null;

const reducer = handleActions(
  {
    [loadClient]: (state, action) => action.payload,
    [clearClient]: () => null
  },
  defaultState
);

export { loadClient, clearClient, reducer };