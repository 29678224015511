// @flow
import * as React from 'react';
import _ from 'lodash';
import moment from 'moment'
import { EyeFilled } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Avatar, Tooltip } from 'antd';
import styles from './Item.css';

type Props = {
  style: any,
  item: any,
  onClickViewEmail: Function
}


export default class extends React.PureComponent<Props> {

  getUserName = (log: Object) => log.causer.first_name;
  
  getFolderName = (type: any) =>  _.replace(type,new RegExp("_","g")," ").replace(/\b\w/g, l => l.toUpperCase())

  prepareActivityLog = (log: Object) => {
    
    switch (log.log_name) {

      default:
        return {
          iconType: '',
          description: ''
        }
      // Project

      case 'CREATE_PROJECT':
        return {
          iconType: 'create',
          description: (
            <span>
              {`${this.getUserName(log)} created the project`}
            </span>
          )
        }

      case 'UPDATE_PROJECT':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated the project`}              
            </span>
          )
        }

      case 'DELETE_PROJECT':
        return {
          iconType: 'delete',
          description: (
            <span>
              {`${this.getUserName(log)} deleted the project`}
            </span>
          )
        }

      case 'COMPLETE_PROJECT':
        return {
          iconType: 'complete',
          description: (
            <span>
              {`${this.getUserName(log)} completed the project`}
            </span>
          )
        }

      case 'UNCOMPLETE_PROJECT':
        return {
          iconType: 'uncomplete',
          description: (
            <span>
              {`${this.getUserName(log)} uncompleted the project`}
            </span>
          )
        }

      case 'UPDATE_PROJECT_NOTES':
        return {
          iconType: 'notes',
          description: (
            <span>
              {`${this.getUserName(log)} updated the notes on the project`}
            </span>
          )
        }

      case 'UPDATE_PROJECT_PHASE':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} changed the Project Phase to ${log.properties.phase_title}`}
            </span>
          )
        }

      case 'UPDATE_PROJECT_TARGET_COSTS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated the project's target costs`}
            </span>
          )
        }

      case 'SEND_PROJECT_INFORMATION_EMAIL':
        return {
          iconType: 'send',
          description: (
            <span>
              {`${this.getUserName(log)} emailed project information`}
              {!!log.properties.email_id && (
                <span className={styles.link} onClick={() => this.props.onClickViewEmail(log.properties.email_id)} role="presentation">
                  <Tooltip title="View Email">
                    <EyeFilled />
                  </Tooltip>
                </span>
              )}
            </span>
          )
        };
      
      

      // Project File

      case 'CREATE_PROJECT_FILE':
        return {
          iconType: 'create',
          description: (
            <span>
              {`${this.getUserName(log)} uploaded file `}
              <b>
                {log.properties.name}
              </b>
            </span>
          )
        }

      case 'MOVE_PROJECT_FILES': // DELETED?
        return {
          iconType: 'move',
          description: (
            <span>
              {`${this.getUserName(log)} moved `}
              <b>
                {log.properties.name}
              </b>
              {' from '}
              <b>
                {this.getFolderName(log.properties.from_type)}
              </b>
              {' to '}
              <b>
                {this.getFolderName(log.properties.to_type)}
              </b>
            </span>
          )
        }

      case 'RENAME_PROJECT_FILE':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} renamed file `}
              <b>
                {log.properties.from}
              </b>
              {' to '}
              <b>
                {log.properties.to}
              </b>
            </span>
          )
        }

      case 'DELETE_PROJECT_FILE':
        return {
          iconType: 'delete',
          description: (
            <span>
              {`${this.getUserName(log)} deleted file `}
              <b>
                {log.properties.name}
              </b>
            </span>
          )
        }

      case 'CREATE_PROJECT_FILE_DIRECTORY':
        return {
          iconType: 'create',
          description: (
            <span>
              {`${this.getUserName(log)} created folder `}
              <b>
                {log.properties.name}
              </b>
            </span>
          )
        }

      case 'RENAME_PROJECT_FILE_DIRECTORY':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} renamed folder `}
              <b>
                {log.properties.from}
              </b>
              {' to '}
              <b>
                {log.properties.to}
              </b>
            </span>
          )
        }

      case 'DELETE_PROJECT_FILE_DIRECTORY':
        return {
          iconType: 'delete',
          description: (
            <span>
              {`${this.getUserName(log)} deleted folder `}
              <b>
                {log.properties.name}
              </b>
            </span>
          )
        }
      
      

      // Estimate

      case 'CREATE_ESTIMATE':
        return {
          iconType: 'create',
          description: (
            <span>
              {`${this.getUserName(log)} created estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'UPDATE_ESTIMATE':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'DELETE_ESTIMATE':
        return {
          iconType: 'delete',
          description: (
            <span>
              {`${this.getUserName(log)} deleted estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'HANDLE_ESTIMATE_ITEMS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated the items on estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'HANDLE_ESTIMATE_OPTIONALS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated optionals items on estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'HANDLE_ESTIMATE_CAVEATS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated the caveats on estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'DUPLICATE_ESTIMATE':
        return {
          iconType: 'duplicate',
          description: (
            <span>
              {`${this.getUserName(log)} duplicated estimate ${log.properties.full_reference_original} to ${log.properties.full_reference_copy}`}
            </span>
          )
        }

      case 'REVISE_ESTIMATE':
        return {
          iconType: 'revise',
          description: (
            <span>
              {`${this.getUserName(log)} revised estimate ${log.properties.full_reference_original} to ${log.properties.full_reference_copy}`}
            </span>
          )
        }

      case 'SEND_ESTIMATE_EMAIL':
        return {
          iconType: 'send',
          description: (
            <span>
              {`${this.getUserName(log)} emailed estimate ${log.properties.full_reference}`}
              {!!log.properties.email_id && (
                <span className={styles.link} onClick={() => this.props.onClickViewEmail(log.properties.email_id)} role="presentation">
                  <Tooltip title="View Email">
                    <EyeFilled />
                  </Tooltip>
                </span>
              )}
            </span>
          )
        };

      case 'UPDATE_ESTIMATE_NOTES':
        return {
          iconType: 'notes',
          description: (
            <span>
              {`${this.getUserName(log)} updates the notes on estimate ${log.properties.full_reference}`}              
            </span>
          )
        }

      case 'UPDATE_ESTIMATE_STATUS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} marked Estimate ${log.properties.full_reference} as ${log.properties.status_title}`}              
            </span>
          )
        }

      case 'UPDATE_ESTIMATE_LATEST_ACTION':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated the Latest Action of Estimate ${log.properties.full_reference} to ${log.properties.latest_action_title}`}
            </span>
          )
        }

      case 'UPDATE_ESTIMATE_TENDER_STATUS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} marked Estimate ${log.properties.full_reference} as ${log.properties.tender_status_title}`}
            </span>
          )
        }
      
      

      // Order

      case 'CREATE_ORDER':
        return {
          iconType: 'create',
          description: (
            <span>
              {`${this.getUserName(log)} created order ${log.properties.reference}`}
            </span>
          )
        }

      case 'UPDATE_ORDER':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log) } updated order ${log.properties.reference}`}
            </span>
          )
        }

      case 'DELETE_ORDER':
        return {
          iconType: 'delete',
          description: (
            <span>
              {`${this.getUserName(log)} deleted order ${log.properties.reference}`}
            </span>
          )
        }

      case 'HANDLE_ORDER_ITEMS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated the items on order ${log.properties.reference}`}
            </span>
          )
        }

      case 'HANDLE_ORDER_CAVEATS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated the items on order ${log.properties.reference}`}
            </span>
          )
        }

      case 'UPDATE_ORDER_NOTES':
        return {
          iconType: 'notes',
          description: (
            <span>
              {`${this.getUserName(log) } updated the notes on order ${log.properties.reference}`}
            </span>
          )
        }

      case 'SEND_ORDER_ACKNOWLEDGEMENT_EMAIL':
        return {
          iconType: 'send',
          description: (
            <span>
              {`${this.getUserName(log)} emailed order acknowledgement ${log.properties.reference}`}
              {!!log.properties.email_id && (
                <span className={styles.link} onClick={() => this.props.onClickViewEmail(log.properties.email_id)} role="presentation">
                  <Tooltip title="View Email">
                    <EyeFilled />
                  </Tooltip>
                </span>
              )}
            </span>
          )
        };

      // Order File

      case 'CREATE_ORDER_FILE':
        return {
          iconType: 'create',
          description: (
            <span>
              {`${this.getUserName(log)} uploaded PO File to order ${log.properties.reference}`}
            </span>
          )
        }

      case 'DELETE_ORDER_FILE':
        return {
          iconType: 'delete',
          description: (
            <span>
              {`${this.getUserName(log)} deleted the PO File from order ${log.properties.reference}`}
            </span>
          )
        }

      // Valuation

      case 'CREATE_VALUATION':
        return {
          iconType: 'create',
          description: (
            <span>
              {`${this.getUserName(log)} created valuation ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'UPDATE_VALUATION':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated valuation ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'DELETE_VALUATION':
        return {
          iconType: 'delete',
          description: (
            <span>
              {`${this.getUserName(log)} deleted valuation ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'UPDATE_VALUATION_ITEMS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated the items on valuation ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'REVISE_VALUATION':
        return {
          iconType: 'revise',
          description: (
            <span>
              {`${this.getUserName(log)} revised valuation ${log.properties.full_reference_original} to ${log.properties.full_reference_copy}`}
            </span>
          )
        }

      case 'REFRESH_VALUATION':
        return {
          iconType: 'refresh',
          description: (
            <span>
              {`${this.getUserName(log)} refreshed valuation ${log.properties.full_reference}`}              
            </span>
          )
        }

      case 'INVOICE_VALUATION':
        return {
          iconType: 'invoice',
          description: (
            <span>
              {`${this.getUserName(log)} invoiced valuation ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'UNINVOICE_VALUATION':
        return {
          iconType: 'invoice',
          description: (
            <span>
              {`${this.getUserName(log)} undone invoice for valuation ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'SEND_VALUATION_EMAIL':
        return {
          iconType: 'send',
          description: (
            <span>
              {`${this.getUserName(log)} emailed valuation ${log.properties.full_reference}`}
              {!!log.properties.email_id && (
                <span className={styles.link} onClick={() => this.props.onClickViewEmail(log.properties.email_id)} role="presentation">
                  <Tooltip title="View Email">
                    <EyeFilled />
                  </Tooltip>
                </span>
              )}
            </span>
          )
        };

      case 'UPDATE_VALUATION_NOTES':
        return {
          iconType: 'notes',
          description: (
            <span>
              {`${this.getUserName(log)} updates the notes on valuation ${log.properties.full_reference}`}              
            </span>
          )
        }

      case 'UPDATE_VALUATION_STATUS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} marked Valuation ${log.properties.full_reference} as ${log.properties.status_title}`}
            </span>
          )
        }

      // Valuation File

      case 'CREATE_VALUATION_FILE':
        return {
          iconType: 'create',
          description: (
            <span>
              {`${this.getUserName(log)} uploaded Payment Certificate File to order ${log.properties.reference}`}
            </span>
          )
        }

      case 'DELETE_VALUATION_FILE':
        return {
          iconType: 'delete',
          description: (
            <span>
              {`${this.getUserName(log)} deleted the Payment Certificate File from order ${log.properties.reference}`}
            </span>
          )
        }

      // Retention

      case 'UPDATE_RETENTION_PAYMENT':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated retention ${log.properties.retention_payment_id}`}
            </span>
          )
        }

      case 'INVOICE_RETENTION_PAYMENT':
        return {
          iconType: 'invoice',
          description: (
            <span>
              {`${this.getUserName(log)} invoiced retention ${log.properties.retention_payment_id}`}
            </span>)
        }

      case 'UNINVOICE_RETENTION_PAYMENT':
        return {
          iconType: 'uninvoice',
          description: (
            <span>
              {`${this.getUserName(log)} undone invoice for retention ${log.properties.retention_payment_id}`}
            </span>)
        }

      case 'DELETE_RETENTION_PAYMENT':
        return {
          iconType: 'delete',
          description: (
            <span>
              {`${this.getUserName(log)} deleted retention ${log.properties.retention_payment_id}`}
            </span>
          )
        }

      // Invoice

      case 'UPDATE_INVOICE':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated invoice ${log.properties.reference}`}
            </span>
          )
        }

      case 'DELETE_INVOICE':
        return {
          iconType: 'delete',
          description: (
            <span>
              {`${ this.getUserName(log)} deleted invoice ${log.properties.reference}`}
            </span>
          )
        }

      case 'SEND_INVOICE_EMAIL':
        return {
          iconType: 'send',
          description: (
            <span>
              {`${ this.getUserName(log)} emailed invoice ${ log.properties.reference }`}
              {!!log.properties.email_id && (
                <span className={styles.link} onClick={() => this.props.onClickViewEmail(log.properties.email_id)} role="presentation">
                  <Tooltip title="View Email">
                    <EyeFilled />
                  </Tooltip>
                </span>
              )}
            </span>
          )
        };

    }

  }

  render() {

    const { style, item } = this.props;

    const type = {
      'create': { backgroundColor: '#3979D9', icon: 'plus' },
      'delete': { backgroundColor: '#D0021B', icon: 'delete' },
      'update': { backgroundColor: '#F5A623', icon: 'edit' },
      'complete': { backgroundColor: '#76C323', icon: 'check' },
      'uncomplete': { backgroundColor: 'grey', icon: 'check' },
      'send': { backgroundColor: '#8713C0', icon: 'mail' },
      'move': { backgroundColor: '#F5A623', icon: 'share-alt' },
      'duplicate': { backgroundColor: '#3979D9', icon: 'copy' },
      'revise': { backgroundColor: '#F5A623', icon: 'edit' },
      'refresh': { backgroundColor: '#F5A623', icon: 'reload' },
      'invoice': { backgroundColor: '#76C323', icon: 'pound' },
      'uninvoice': { backgroundColor: '#D0021B', icon: 'undo' },
      'notes': { backgroundColor: '#40A9FF', icon: 'message' },
    };

    const log: Object = this.prepareActivityLog(item);

    const iconStyle = {
      backgroundColor: type[log.iconType].backgroundColor,
      verticalAlign: 'middle'
    }

    return (
      <div className={styles.row} style={style} role="presentation">

        <div className={styles.col}>
        
          <Avatar style={iconStyle} icon={<LegacyIcon type={type[log.iconType].icon} />} />
          
          <div className={styles.textWrapper}>

            <div className={styles.timeText}>
              {moment(item.created_at).format('DD/MM/YYYY [at] HH:mm:ss')}
            </div>

            <div>
              {log.description}
            </div>

          </div>

        </div>

      </div>
    );
  };
}