import { connect } from 'react-redux'
import Table from './Table'

import onChangeTable from './Handlers/onChangeTable'

const mapStateToProps = (state) => ({
  subInvoices: state.screens.SubProject.SubInvoices.subInvoices,
  fetching: state.screens.SubProject.SubInvoices.fetching,
})

const actionCreators = {
  onChangeTable,
}

export default connect(mapStateToProps, actionCreators)(Table)
