import React from 'react'
import PropTypes from 'prop-types'
import { SortableElement } from 'react-sortable-hoc'
import { Popconfirm } from 'antd'
import { CopyOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { sumBy } from 'lodash'
import numeral from 'numeral'
import classnames from 'classnames'
import styles from './styles.css'

class SubOrderGroupFooter extends React.PureComponent {

  static propTypes = {
    subOrderGroup: PropTypes.object.isRequired,
    editing: PropTypes.bool.isRequired,
    onAddItem: PropTypes.func.isRequired,
    onDuplicateGroup: PropTypes.func.isRequired,
    onRemoveGroup: PropTypes.func.isRequired,
  }

  render() {

    const {
      subOrderGroup,
      editing,
      onAddItem,
      onDuplicateGroup,
      onRemoveGroup,
    } = this.props

    const subOrderGroupTotal = sumBy(subOrderGroup.sub_order_items, 'total')

    return (

      <div className={styles.wrapper}>

        {editing && (

          <React.Fragment>

            <div
              role='presentation'
              onClick={() => onAddItem(subOrderGroup.guid)}
              className={classnames(styles.link, styles.addLink)}
            >
              <PlusOutlined />
              {' Add Item'}
            </div>

            <div
              role='presentation'
              onClick={() => onDuplicateGroup(subOrderGroup.guid)}
              className={classnames(styles.link)}
            >
              <CopyOutlined />
              {' Duplicate Group'}
            </div>

            <Popconfirm
              title='Remove this group?'
              okText='Yes'
              cancelText='No'
              onConfirm={() => onRemoveGroup(subOrderGroup.guid)}
            >
              <div
                role='presentation'
                className={classnames(styles.link)}
              >
                <DeleteOutlined />
                {' Remove Group'}
              </div>
            </Popconfirm>

          </React.Fragment>

        )}

        <div className={styles.total}>
          {`Group Total: ${subOrderGroupTotal < 0 ? '-' : ''}£${numeral(Math.abs(subOrderGroupTotal)).format('0,0.00')}`}
        </div>

      </div>

    )

  }

}

export default SortableElement(SubOrderGroupFooter)
