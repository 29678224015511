import { message } from 'antd'
import modalProps from '../modalProps'
import { addOrderItemsToSubOrder } from '../../../HttpRequests/subOrders'
import handleException from '../../../Core/Helpers/handleException'

export default (subOrderId) => async () => {

  const { orderItemIds } = modalProps()

  try {

    const response = await addOrderItemsToSubOrder({ id: subOrderId, data: orderItemIds })

    message.success(`Added to "${response.data.reference}"`)

    modalProps().onClose(response.data)

  } catch (error) {

    handleException(error)

  }

}
