import { createAction, handleActions } from 'redux-actions'

const setMode = createAction('SCREENS/SUB_PROJECTS/SET_MODE')
const resetMode = createAction('SCREENS/SUB_PROJECTS/RESET_MODE')

const defaultState = 'projects'

const reducer = handleActions(
  {
    [setMode]: (state, { payload }) => payload,
    [resetMode]: () => defaultState,
  },
  defaultState,
)

export { setMode, resetMode, reducer }
