exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2S_ocRJHEFeDzgxSdn1FeN label {\n  width: 240px;\n  text-align: center;\n}\n\n._18eXjTiNwOlIEAWX9egYMA {\n  color: #1990FF;\n  cursor: pointer;\n  font-size: 12px;\n}\n\n.AxKeFfcIsl2-QbYQjrA4l {\n  color: #999;\n  transition: color 0.3s;\n  cursor: pointer;\n}\n\n.AxKeFfcIsl2-QbYQjrA4l:hover {\n  color: red;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Modals/EmailProjectInformation/Components/Form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;CACpB;;AAED;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;CACjB;;AAED;EACE,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;CACjB;;AAED;EACE,WAAW;CACZ","file":"Form.css","sourcesContent":[".typeTab label {\n  width: 240px;\n  text-align: center;\n}\n\n.downloadFileButton {\n  color: #1990FF;\n  cursor: pointer;\n  font-size: 12px;\n}\n\n.removeFileButton {\n  color: #999;\n  transition: color 0.3s;\n  cursor: pointer;\n}\n\n.removeFileButton:hover {\n  color: red;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"typeTab": "_2S_ocRJHEFeDzgxSdn1FeN",
	"downloadFileButton": "_18eXjTiNwOlIEAWX9egYMA",
	"removeFileButton": "AxKeFfcIsl2-QbYQjrA4l"
};