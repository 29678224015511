import createFolder from '../Services/createFolder';
import renameFile from '../Services/renameFile';
import renameDirectory from '../Services/renameDirectory';

import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { onClose, didCreate, didRename, directoryActionType, selectedItemType } = getProps();

  if (directoryActionType === 'edit') {

    if (selectedItemType === 'file') {
      
      const file = await dispatch(renameFile(values));

      onClose();

      if (didRename) didRename(file);

    } else {
      
      const folder = await dispatch(renameDirectory(values));

      onClose();

      if (didRename) didRename(folder);
      
    }
    
  } else {
    
    const folder = await dispatch(createFolder(values));
    
    onClose();

    if (didCreate) didCreate(folder);

  }  

}