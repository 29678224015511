import { combineReducers } from 'redux';

import { reducer as fetching } from './State/fetching';
import { reducer as fetchingTotals } from './State/fetchingTotals';
import { reducer as estimates } from './State/estimates';
import { reducer as estimateTotals } from './State/estimateTotals';
import { reducer as clientContacts } from './State/clientContacts';
import { reducer as searchTerm } from './State/searchTerm';
import { reducer as filters } from './State/filters';
import { reducer as pagination } from './State/pagination';
import { reducer as sorting } from './State/sorting';
import { reducer as showEstimateModal } from './State/showEstimateModal';

export default combineReducers({
  fetching,
  fetchingTotals,
  estimates,
  estimateTotals,
  clientContacts,
  searchTerm,
  filters,
  pagination,
  sorting,
  showEstimateModal
})
