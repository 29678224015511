import { message } from 'antd'
import handleException from '../../../Core/Helpers/handleException';
import { uninvoiceValuation } from '../../../HttpRequests/valuations';
import fetchValuation from './fetchValuation';

export default () => async (dispatch, getState) => {

  const { valuation } = getState().screens.ValuationView;
  
  try {

    await uninvoiceValuation({ id: valuation.id });

    await dispatch(fetchValuation());

    message.success('Undone');
    
  } catch(error) {

    handleException(error);

  }
  
}