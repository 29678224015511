import React from 'react'
import PropTypes from 'prop-types'
import { Table as TableProvider, Tag, Tooltip } from 'antd'
import { CheckCircleFilled, ClockCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import TimesheetAdjustmentsPopover from '../../../TimesheetAdjustmentsPopover'
import styles from './styles.css'

const Table = props => {

  const {
    tableRows,
    isFetching,
    selected,
    onToggle,
    onClickRow,
    onClickNewTimesheetAdjustment,
    onClickTimesheetAdjustment,
  } = props

  return (

    <TableProvider
      dataSource={tableRows}
      rowKey='key'
      size='small'
      loading={isFetching}
      rowSelection={{
        selectedRowKeys: selected,
        onChange: keys => onToggle(keys),
      }}
      onRow={(record) => ({
        onClick: () => onClickRow(record),
      })}
      pagination={false}
      className={styles.table}
    >

      <TableProvider.Column
        key='subcontractor.name'
        title={tableRows.some(record => !!record.children) ? (
          <div style={{ paddingLeft: 24 }}>Subcontractor</div>
        ) : 'Subcontractor'}
        render={(text, record) => !!record.subcontractor && <div style={{ fontWeight: 500 }}>{record.subcontractor.name}</div>}
      />

      <TableProvider.Column
        key='date'
        title='Date'
        width={130}
        render={(text, record) => !!record.date && moment(record.date).format('Do MMM YYYY')}
      />

      <TableProvider.Column
        key='project'
        title='Project'
        render={(text, record) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {record.project ? (
              <React.Fragment>
                <div style={{ fontWeight: 500 }}>{record.project.id}</div>
                &nbsp;
                <div style={{ color: '#9e9e9e' }}>
                  {`${record.project.client_site.client.name} - ${record.project.client_site.name}`}
                </div>
                &nbsp;
                {!!record.price_work && <Tag color='gold'>PW</Tag>}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <ClockCircleOutlined />
                &nbsp;
                {`${record.timesheet_postings.length} Postings`}
              </React.Fragment>
            )}
          </div>
        )}
      />

      <TableProvider.Column
        key='span'
        title='Span'
        width={130}
        render={(text, record) => record.span}
      />

      <TableProvider.Column
        key='hours'
        title='Hours'
        width={95}
        onHeaderCell={() => ({ style: { textAlign: 'right' } })}
        onCell={() => ({ style: { textAlign: 'right' } })}
        render={(text, record) => record.hours}
      />

      <TableProvider.Column
        key='deduction'
        title='Deduction'
        width={95}
        onHeaderCell={() => ({ style: { textAlign: 'right' } })}
        onCell={() => ({ style: { textAlign: 'right', color: 'orange' } })}
        render={(text, record) => record.deduction}
      />

      <TableProvider.Column
        key='adjustments'
        title='Adjustments'
        width={95}
        onHeaderCell={() => ({ style: { textAlign: 'right' } })}
        onCell={() => ({ style: { textAlign: 'right', color: '#9e9e9e' } })}
        render={(text, record) => record.timesheet_adjustments ? (
          <TimesheetAdjustmentsPopover
            timesheet={record}
            tableRows={record.timesheet_adjustments}
            onClickNew={onClickNewTimesheetAdjustment}
            onClickRow={onClickTimesheetAdjustment}
          >
            {record.adjustments}
          </TimesheetAdjustmentsPopover>
        ) : record.adjustments}
      />

      <TableProvider.Column
        key='hours_worked'
        title='Total'
        width={95}
        onHeaderCell={() => ({ style: { textAlign: 'right' } })}
        onCell={() => ({ style: { textAlign: 'right' } })}
        render={(text, record) => record.hours_worked}
      />

      <TableProvider.Column
        key='total_cost'
        title='Cost'
        width={95}
        onHeaderCell={() => ({ style: { textAlign: 'right' } })}
        onCell={() => ({ style: { textAlign: 'right' } })}
        render={(text, record) => record.total_cost}
      />

      <TableProvider.Column
        key='status'
        title='Status'
        width={130}
        render={(text, record) => {
          if (record.status === 'Pending') {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <MinusCircleOutlined style={{ color: '#9e9e9e' }} />
                &nbsp;
                Pending
              </div>
            )
          }
          if (record.status === 'Approved') {
            return (
              <Tooltip title={`By ${record.approved_by_user.full_name} at ${moment(record.approved_at).format('DD/MM/YYYY HH:mm')}`}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckCircleFilled style={{ color: 'orange' }} />
                  &nbsp;
                  Approved
                </div>
              </Tooltip>
            )
          }
          if (record.status === 'Paid') {
            return (
              <Tooltip title={`By ${record.paid_by_user.full_name} at ${moment(record.paid_at).format('DD/MM/YYYY HH:mm')}`}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckCircleFilled style={{ color: 'limegreen' }} />
                  &nbsp;
                  Paid
                </div>
              </Tooltip>
            )
          }
        }}
      />

    </TableProvider>

  )

}

Table.propTypes = {
  tableRows: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  selected: PropTypes.array.isRequired,
  onToggle: PropTypes.func.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onClickNewTimesheetAdjustment: PropTypes.func.isRequired,
  onClickTimesheetAdjustment: PropTypes.func.isRequired,
}

export default Table
