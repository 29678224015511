import { fromJS } from 'immutable';
import { loadValuation } from '../State/valuation';

export default ({ orderIndex, orderGroupIndex, orderItemIndex }) => (dispatch, getState) => {

  let valuation = fromJS(getState().screens.ValuationView.valuation);

  const valuationItem = valuation.getIn(['orders',orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex,'valuation_item']).toJS();

  const aggregateTotal =  (Number(valuationItem.complete_total) + Number(valuationItem.previous_aggregate_total)).toFixed(2);

  valuation = valuation.updateIn(
    ['orders',orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex,'valuation_item'],
    map => map.merge(fromJS({
      aggregate_total: aggregateTotal
    }))
  );

  valuation = valuation.toJS();

  dispatch(loadValuation(valuation));

}