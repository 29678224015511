import fetch from '../Services/fetch'
import { resetData } from '../State/data'

export default (visible, date) => (dispatch) => {

  if (visible) {
    dispatch(fetch(date))
  }

  if (!visible) {
    dispatch(resetData())
  }

}
