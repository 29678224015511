// @flow

import { fromJS } from 'immutable';
import { arrayMove } from 'react-sortable-hoc';
import { assign } from 'lodash';
import selectItemsListData from '../Selectors/selectItemsListData';
import { loadEstimate } from '../State/estimate';

export type MoveEstimateItemParams = {
  oldIndex: number,
  newIndex: number
}

export default (params: MoveEstimateItemParams) => (dispatch: Function, getState: Function) => {


  // get immutable copy of the estimate
  let estimate = fromJS(getState().screens.EstimateView.estimate);

  // select the mapped list items
  let listData = selectItemsListData(getState());



  // dont't carry on if index hasnt moved
  if (params.oldIndex === params.newIndex) return;

  // don't allow it to be dropped at the beginning (before the first header)
  if (params.newIndex === 0) return;

  // don't allow it to be dropped at the end (after the last footer)
  if (params.newIndex === (listData.length-1)) return;
  


  // get the old estimateItem, estimateGroupIndex, estimateItemIndex
  const {
    estimateItem: oldEstimateItem,
    estimateGroupIndex: oldEstimateGroupIndex,
    estimateItemIndex: oldEstimateItemIndex
  } = listData[params.oldIndex];

  // prepare the new estimateItem, estimateGroupIndex, estimateItemIndex

  listData = arrayMove(listData, params.oldIndex, params.newIndex);

  const listItemBefore = listData[params.newIndex - 1];

  if (listItemBefore.type === 'GroupFooter') return; // don't allow it to be dropped between footer & header

  const newEstimateGroupIndex = listItemBefore.estimateGroupIndex
  const newEstimateItemIndex = listItemBefore.estimateItem ? (listItemBefore.estimateItemIndex + 1) : 0;
  const newEstimateItem = assign({}, oldEstimateItem, { estimate_group_id: listItemBefore.estimateGroup.id })



  // make the changes

  if (oldEstimateItemIndex < newEstimateItemIndex) {

    // insert the estimateItem in the new position
    estimate = estimate.updateIn(
      ['estimate_groups', newEstimateGroupIndex, 'estimate_items'],
      (list) => list.insert(newEstimateItemIndex, newEstimateItem)
    );

    // remove the estimateItem from the old position
    estimate = estimate.removeIn(['estimate_groups', oldEstimateGroupIndex, 'estimate_items', oldEstimateItemIndex]);

  } else {

    // remove the estimateItem from the old position
    estimate = estimate.removeIn(['estimate_groups', oldEstimateGroupIndex, 'estimate_items', oldEstimateItemIndex]);

    // insert the estimateItem in the new position
    estimate = estimate.updateIn(
      ['estimate_groups', newEstimateGroupIndex, 'estimate_items'],
      (list) => list.insert(newEstimateItemIndex, newEstimateItem)
    );

  }


  
  // save
  
  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}