import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import { Button, Popconfirm } from 'antd'
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons'
import moment from 'moment'
import { getTeamUrl } from 'Common'
import styles from './styles.css'

const Section = props => (
  <div className={styles.section}>
    {props.children}
  </div>
)

const Outlet = props => (
  <div className={styles.outlet}>
    {props.children}
  </div>
)

const Label = props => (
  <div className={styles.label}>
    {props.children}
  </div>
)

const Value = props => (
  <div className={styles.value}>
    {props.children}
  </div>
)

const Sidebar = props => {

  const {
    subOrder,
    onDropMedia,
    onDeleteMedia,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <Section>

        <Outlet>
          <Label>Subcontractor</Label>
          <Value>
            <NavLink to={getTeamUrl(`subcontractors/${subOrder.subcontractor.id}/view`)}>
              {subOrder.subcontractor.name}
            </NavLink>
          </Value>
        </Outlet>

        <Outlet>
          <Label>Client</Label>
          <Value>
            <NavLink to={getTeamUrl(`clients/${subOrder.project.client_site.client.id}/view`)}>
              {subOrder.project.client_site.client.name}
            </NavLink>
          </Value>
        </Outlet>

        <Outlet>
          <Label>Project</Label>
          <Value>
            <NavLink to={getTeamUrl(`projects/${subOrder.project.id}/view`)}>
              {subOrder.project.id}
              {subOrder.project.title && ` » ${subOrder.project.title}`}
            </NavLink>
          </Value>
        </Outlet>

        <Outlet>
          <Label>Site</Label>
          <Value>
            {[
              subOrder.project.client_site.name,
              subOrder.project.client_site.address_line_1,
              subOrder.project.client_site.address_line_2,
              subOrder.project.client_site.address_line_3,
              subOrder.project.client_site.town_or_city,
              subOrder.project.client_site.county,
              subOrder.project.client_site.postcode,
            ].filter(line => line).join(', ')}
          </Value>
        </Outlet>

        <Outlet>
          <Label>Owner</Label>
          <Value>{subOrder.user.full_name}</Value>
        </Outlet>

        <Outlet>
          <Label>Date</Label>
          <Value>{moment(subOrder.date).format('DD MMM YYYY')}</Value>
        </Outlet>

      </Section>

      <Section>

        <Outlet>
          <Label>Attachments</Label>
          <Value>

            {subOrder.media.map(file => (

              <div style={{ marginBottom: 4, marginTop: 4, display: 'flex' }} key={file.id}>
                <PaperClipOutlined style={{ marginTop: 2 }} />
                <div style={{ flex: 1, marginLeft: 4, marginRight: 4 }}>
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    {file.name}
                  </a>
                </div>
                <Popconfirm onConfirm={() => onDeleteMedia(file)} title="Delete attachment?" placement="topRight">
                  <DeleteOutlined style={{ marginTop: 2 }} className={styles.deleteFileButton} />
                </Popconfirm>
              </div>

            ))}

            {subOrder.media.length === 0 && (
              <div style={{ marginBottom: 4, marginTop: 4 }}>No files</div>
            )}

            <Dropzone
              disablePreview
              multiple={false}
              onDrop={acceptedFiles => onDropMedia(acceptedFiles)}
              style={{ width: 'auto', height: 'auto', border: 'none' }}
            >
              <Button block style={{ marginTop: 8 }}>
                Upload
              </Button>
            </Dropzone>

          </Value>
        </Outlet>

      </Section>

    </div>
    
  )

}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

Outlet.propTypes = {
  children: PropTypes.node.isRequired,
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
}

Value.propTypes = {
  children: PropTypes.node.isRequired,
}

Sidebar.propTypes = {
  subOrder: PropTypes.object.isRequired,
  onDropMedia: PropTypes.func.isRequired,
  onDeleteMedia: PropTypes.func.isRequired,
}

export default Sidebar
