import { createAction, handleActions } from 'redux-actions'

const setShowEmailModal = createAction("SCREENS/INVOICE_VIEW/SET_SHOW_EMAIL_MODAL")

const defaultState = false

const reducer = handleActions(
  {
    [setShowEmailModal]: (state, action) => action.payload,
  },
  defaultState
)

export { setShowEmailModal, reducer }