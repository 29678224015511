import { createAction, handleActions } from 'redux-actions'

const pushRemovedFileId = createAction('MODALS/RAMS_RECORD/PUSH_REMOVED_FILE_ID')
const resetRemovedFileIds = createAction('MODALS/RAMS_RECORD/RESET_REMOVED_FILE_IDS')

const defaultState = []

const reducer = handleActions(
  {
    [pushRemovedFileId]: (state, action) => [ ...state, action.payload ],
    [resetRemovedFileIds]: () => [],
  },
  defaultState
)

export { pushRemovedFileId, resetRemovedFileIds, reducer }