import { message } from 'antd'
import { push } from 'connected-react-router'
import { getTeamUrl } from 'Common'
import { uninvoiceValuation } from '../../../HttpRequests/valuations'
import { uninvoiceRetentionPayment } from '../../../HttpRequests/retentionPayments'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {
  
  try {

    const { invoice } = getState().screens.InvoiceView

    if (invoice.valuation) {

      await uninvoiceValuation({ id: invoice.valuation.id })

      message.success('Undone')

      dispatch(push(getTeamUrl(`valuations/${invoice.valuation.id}/view`)))

    }

    if (invoice.retention_payment) {

      await uninvoiceRetentionPayment({ id: invoice.retention_payment.id })

      message.success('Undone')

      dispatch(push(getTeamUrl(`projects/${invoice.project.id}/view?tab=retention_payments`)))

    }

    
  } catch (error) {

    handleException(error)

  }
  
}