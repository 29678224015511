// @flow
import * as React from 'react';
import { Modal, Button } from 'antd';
import Form from './Form';

type Props = {
  users: [],
  submitting: boolean,
  drives: boolean,
  coVehicle: boolean,
  hasRightToWork: boolean,
  subcontractor: {},
  componentDidMount: (props: any) => void,
  onClickCancel: () => void,
  onClickSubmit: () => void,
  onClickDelete: () => void,
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount(this.props);

  render () {

    const {
      users,
      submitting,
      drives,
      coVehicle,
      hasRightToWork,
      subcontractor,
      onClickCancel,
      onClickSubmit,
      onClickDelete,
    } = this.props;

    const footer = []

    if (subcontractor) {
      footer.push([
        <Button key='delete' style={{ float: 'left' }} type='danger' onClick={onClickDelete}>
          Delete
        </Button>,
      ])
    }

    footer.push([
      <Button key='cancel' disabled={submitting} onClick={onClickCancel}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
        Save
      </Button>,
    ])

    return (
        
      <Modal
        title={`${subcontractor ? 'Update Subcontractor': 'New Subcontractor'}`}
        footer={footer}
        onCancel={onClickCancel}
        visible
        width={900}
      >
        
        <Form
          users={users}
          drives={drives}
          coVehicle={coVehicle}
          hasRightToWork={hasRightToWork}
        />

      </Modal>
    )
  }
}