
import { createAction, handleActions } from 'redux-actions';

const setShowProjectCompleteModal = createAction("SCREENS/VALUATION_VIEW/SET_SHOW_PROJECT__COMPLETE_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowProjectCompleteModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowProjectCompleteModal, reducer };