import { delay } from 'lodash'
import fetch from '../Services/fetch'
import { resetData } from '../State/data'

export default (visible, valuationId) => (dispatch) => {

  if (visible) {
    dispatch(fetch(valuationId))
  } else {
    delay(() => {
      dispatch(resetData())
    }, 300)
  }
  
}
