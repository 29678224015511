import React from 'react'
import PropTypes from 'prop-types'
import { Select, Dropdown, Menu, Button } from 'antd'
import { CaretDownOutlined, PaperClipOutlined } from '@ant-design/icons'
import styles from './styles.css'

const Spacer = () => <div style={{ width: 12 }} />

const Header = props => {

  const {
    projectStatuses,
    subcontractor,
    projects,
    filters,
    onChangeFilter,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <div className={styles.left}>

        <h1 className={styles.header}>{subcontractor.name}</h1>

      </div>

      <div className={styles.right}>

        <Select
          value={filters.projectStatusKey}
          onChange={value => onChangeFilter('projectStatusKey', value)}
          allowClear
          placeholder='All Statuses'
          style={{ width: 180 }}
        >
          <Select.OptGroup label='Project Status'>
            {projectStatuses.map(projectStatus => (
              <Select.Option key={projectStatus.key} value={projectStatus.key}>
                {projectStatus.title}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </Select>

        <Spacer />

        <Select
          value={filters.projectId}
          onChange={value => onChangeFilter('projectId', value)}
          allowClear
          placeholder='All Projects'
          style={{ width: 280 }}
        >
          <Select.OptGroup label='Project'>
            {projects.map(project => (
              <Select.Option key={project.id} value={project.id}>
                {`[${project.id}] ${project.client_site.client.name} - ${project.client_site.name}`}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </Select>

        <Spacer />

        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu>
              <Menu.Item key='tbc' icon={<PaperClipOutlined />} onClick={() => { }}>Tbc</Menu.Item>
            </Menu>
          )}
        >
          <Button type='primary'>
            Actions
            <CaretDownOutlined />
          </Button>
        </Dropdown>

      </div>

    </div>
    
  )

}

Header.propTypes = {
  projectStatuses: PropTypes.array.isRequired,
  subcontractor: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
}

export default Header
