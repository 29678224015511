import { getLastThreeMonthsReport } from '../../../HttpRequests/dashboard';
import handleException from '../../../Core/Helpers/handleException';

import { loadData } from '../State/lastThreeMonthsReport';

export default () => async (dispatch, getSate) => {

  try {
    
    const params = {};

    const { clientId } = getSate().screens.Dashboard.lastThreeMonthsReport;

    if (clientId) {
      params['filter[client_id]'] = clientId;
    }

    const response = await getLastThreeMonthsReport({ params });

    dispatch(loadData(response.data.data));

  } catch(error) {

    handleException(error);

  }
}