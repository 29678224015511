import downloadFile from '../Services/downloadFile';

export default () => async (dispatch, getState) => {

  const { checkedItemPaths } = getState().screens.ProjectView;

  checkedItemPaths.forEach(async (path) => {
    await dispatch(downloadFile(path));
  })

}