import { createAction, handleActions } from 'redux-actions';

const loadClientContact = createAction("MODALS/INVOICE/LOAD_CLIENT_CONTACT");

const defaultState = []

const reducer = handleActions(
  {
    [loadClientContact]: (state, action) => action.payload,
  },
  defaultState
);

export { loadClientContact, reducer };