import { connect } from 'react-redux'
import SubValuation from './SubValuation'

import onClickEdit from './Handlers/onClickEdit'
import onClickDetach from './Handlers/onClickDetach'

const mapStateToProps = () => ({
})

const actionCreators = {
  onClickEdit,
  onClickDetach,
}

export default connect(mapStateToProps, actionCreators)(SubValuation)
