import { createSelector } from 'reselect'

const selectControls = state => state.screens.Planner.controls

export default createSelector(
  selectControls,
  (controls) => {

    if (controls.hideEmpty) return true

    if (controls.yAxis === 'projects') {

      if (controls.projectSearch) return true
      if (controls.contractsManagerId.length) return true
      if (controls.statusKey.length) return true
      if (controls.phaseKey.length) return true
      if (controls.startDateBetween.length) return true
      if (controls.omitted) return true
      if (controls.noFinishingSchedule) return true
      if (controls.noProgramme) return true
      if (controls.inductionRequired) return true
      if (controls.weeklyReturns) return true
      if (controls.termsAndConditions) return true
      if (controls.noValuationThisMonth) return true
      if (controls.missingRams) return true
      if (controls.expiringRams) return true
      if (controls.expiredRams) return true

    }

    if (controls.yAxis === 'subcontractors') {

      if (controls.subcontractorSearch) return true
      if (controls.cardType.length) return true

    }

    if ((controls.yAxis === 'projects' && !controls.summary) || controls.yAxis === 'subcontractors') {
      if (controls.unconfirmedOrRequired) return true
      if (controls.unattended) return true
      if (controls.unavailableOrAbsent) return true
      if (controls.duplicated) return true
      if (controls.unexpected) return true
      if (controls.attended) return true
      if (controls.alerts) return true
      if (controls.switched) return true
      if (controls.priceWork) return true
    }

    return false

  }
)
