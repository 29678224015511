// @flow
import * as React from 'react';
import { Modal, Button } from 'antd';
import Form from './Form';

type Props = {
  clientId: any,
  clientSite: any,
  submitting: boolean,
  componentWillMount: (props: any) => void,
  onClickCancel: () => void,
  onClickSubmit: () => void,
  onClickDelete: () => void,
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      clientId,
      clientSite,
      submitting,
      onClickCancel,
      onClickSubmit,
      onClickDelete,
    } = this.props;

    const footer = []

    if (clientSite) footer.push(
      <Button key='delete' type='danger' onClick={onClickDelete} style={{ float: 'left' }}>
        Delete
      </Button>,
    )

    footer.push(
      <Button key='cancel' onClick={() => onClickCancel()}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
        {clientSite ? 'Update' : 'Create'}
      </Button>,
    )

    return (
        
      <Modal
        title={`${clientSite ? 'Update Site': 'New Site'}`}
        visible
        footer={footer}
        onCancel={onClickCancel}
        width={550}
      >
        
        <Form clientId={clientId} />

      </Modal>
    )
  }
}