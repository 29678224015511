import { connect } from 'react-redux';
import Component from './Component';

import selectItemsListData from './Selectors/selectItemsListData';

import componentDidMount from './Handlers/componentDidMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import onClickTargetCostPercentageOutlet from './Handlers/onClickTargetCostPercentageOutlet';
import onClickTargetCostAmountOutlet from './Handlers/onClickTargetCostAmountOutlet';
import onChangeTargetCostPercentage from './Handlers/onChangeTargetCostPercentage';
import onChangeTargetCostAmount from './Handlers/onChangeTargetCostAmount';
import onBlurTargetCostPercentage from './Handlers/onBlurTargetCostPercentage';
import onBlurTargetCostAmount from './Handlers/onBlurTargetCostAmount';
import onTabTargetCostField from './Handlers/onTabTargetCostField';
import onCloseSetTargetCostsModal from './Handlers/onCloseSetTargetCostsModal';

const mapStateToProps = (state) => ({
  listData: selectItemsListData(state),
  serviceTypes: state.core.serviceTypes,
  editable: state.screens.ProjectView.TargetCosts.editable,
  activeItemInputKeys: state.screens.ProjectView.TargetCosts.activeItemInputKeys,
  showSetTargetCostsModal: state.screens.ProjectView.TargetCosts.showSetTargetCostsModal,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
  onClickTargetCostPercentageOutlet,
  onClickTargetCostAmountOutlet,
  onChangeTargetCostPercentage,
  onChangeTargetCostAmount,
  onBlurTargetCostPercentage,
  onBlurTargetCostAmount,
  onTabTargetCostField,
  onCloseSetTargetCostsModal,
};

export default connect(mapStateToProps, actionCreators)(Component);