import { connect } from 'react-redux';
import Component from './Component';

import componentDidMount from './Handlers/componentDidMount';
import onChangeTable from './Handlers/onChangeTable';
import onChangeSearch from './Handlers/onChangeSearch';
import onSubmitSearch from './Handlers/onSubmitSearch';
import onChangeFilter from './Handlers/onChangeFilter';
import onClickNewOrder from './Handlers/onClickNewOrder';
import onCloseOrderModal from './Handlers/onCloseOrderModal';
import didCreateOrder from './Handlers/didCreateOrder';
import onClickExport from './Handlers/onClickExport';

const mapStateToProps = (state) => ({
  clients: state.core.clients,
  orders: state.screens.Orders.orders,
  orderTotals: state.screens.Orders.orderTotals,
  searchTerm: state.screens.Orders.searchTerm,
  pagination: state.screens.Orders.pagination,
  filters: state.screens.Orders.filters,
  fetching: state.screens.Orders.fetching,
  showOrderModal: state.screens.Orders.showOrderModal,
});

const actionCreators = {
  componentDidMount,
  onChangeTable,
  onChangeSearch,
  onSubmitSearch,
  onChangeFilter,
  onClickNewOrder,
  onCloseOrderModal,
  didCreateOrder,
  onClickExport
};

export default connect(mapStateToProps, actionCreators)(Component);