import { connect } from 'react-redux'
import Sidebar from './Sidebar'

import onDropMedia from './Handlers/onDropMedia'
import onDeleteMedia from './Handlers/onDeleteMedia'

const mapStateToProps = (state) => ({
  subOrder: state.screens.SubOrder.subOrder,
})

const actionCreators = {
  onDropMedia,
  onDeleteMedia,
}

export default connect(mapStateToProps, actionCreators)(Sidebar)
