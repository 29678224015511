import { createAction, handleActions } from 'redux-actions'

const loadTotals = createAction("SCREENS/INVOICES/LOAD_TOTALS")

const defaultState = {
  total: 0,
}

const reducer = handleActions(
  {
    [loadTotals]: (state, action) => action.payload,
  },
  defaultState
)

export { loadTotals, reducer }