import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { getTeamUrl } from 'Common'

const Breadcrumbs = props => (

  <Breadcrumb separator='»'>

    <Breadcrumb.Item>
      Home
    </Breadcrumb.Item>

    <Breadcrumb.Item>
      <NavLink to={getTeamUrl('subcontractors')}>
        Subcontractors
      </NavLink>
    </Breadcrumb.Item>

    <Breadcrumb.Item>
      {props.subcontractor.name}
    </Breadcrumb.Item>

  </Breadcrumb>

)

Breadcrumbs.propTypes = {
  subcontractor: PropTypes.object.isRequired,
}

export default Breadcrumbs
