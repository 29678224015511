
import { createAction, handleActions } from 'redux-actions';

const setShowJobSheetModal = createAction("SCREENS/PROJECT_VIEW/SET_SHOW_JOB_SHEET_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowJobSheetModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowJobSheetModal, reducer };