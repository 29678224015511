export default (values) => {

  const errors = {}

  if (!values.valid_from) errors.valid_from = 'Required'
  if (!values.valid_to) errors.valid_to = 'Required'
  if (!Array.isArray(values.services) || values.services.length === 0) errors.services = 'Required'

  return errors
  
}