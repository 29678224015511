exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1BJVhVicITXFUyRaX9NJSp {\n  padding: 10px;\n}\n\n._50nLAQcYpxerDbPvdf34P {\n  display: flex;\n  align-items: center;\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n\n._39aTRXbkJOPlf0irMHL6ll {\n  height: 25px;\n  display: flex;\n  align-items: flex-end;\n}\n\n._1m3xqV-AnOlEmhJDP6TR1C {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Planner/Components/SubcontractorCellModal/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;CACf;;AAED;EACE,cAAc;EACd,oBAAoB;EACpB,iBAAiB;EACjB,oBAAoB;CACrB;;AAED;EACE,aAAa;EACb,cAAc;EACd,sBAAsB;CACvB;;AAED;EACE,cAAc;EACd,+BAA+B;EAC/B,oBAAoB;EACpB,iBAAiB;EACjB,oBAAoB;CACrB","file":"styles.css","sourcesContent":[".wrapper {\n  padding: 10px;\n}\n\n.project {\n  display: flex;\n  align-items: center;\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n\n.addProject {\n  height: 25px;\n  display: flex;\n  align-items: flex-end;\n}\n\n.inputRow {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_1BJVhVicITXFUyRaX9NJSp",
	"project": "_50nLAQcYpxerDbPvdf34P",
	"addProject": "_39aTRXbkJOPlf0irMHL6ll",
	"inputRow": "_1m3xqV-AnOlEmhJDP6TR1C"
};