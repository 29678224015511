import React from 'react'
import PropTypes from 'prop-types'
import { Table, Dropdown, Badge } from 'antd'
import {
  CaretDownOutlined,
  FormatPainterFilled,
} from '@ant-design/icons'
import { useVT } from 'virtualizedtableforantd4'
import { flatMap } from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import styles from './styles.css'

import ProjectSortMenu from '../ProjectSortMenu'
import ProjectCellShrunk from '../ProjectCellShrunk'
import SubcontractorPopover from '../SubcontractorPopover'
import SubcontractorDrop from '../SubcontractorDrop'
import SubcontractorDrag from '../SubcontractorDrag'
import JobSlots from '../JobSlots'
import TimesheetPostingSpan from '../TimesheetPostingSpan'
import CellRequired from '../CellRequired'
import CellAlert from '../CellAlert'

const Line = ({ content }) => <div style={{ height: '22px', lineHeight: '22px', fontSize: 13, fontWeight: 500 }}>{content}</div>

const ProjectTableShrunk = props => {

  const {
    periods,
    rows,
    fetching,
    onClickProject,
    onClickCell,
  } = props

  const [vt] = useVT(() => ({ scroll: { y: window.innerHeight - 290 } }), [])

  const columnWidth = (window.innerWidth - 120 - 400) / 7

  const today = moment().format('YYYY-MM-DD')

  return (

    <SubcontractorDrop>

      <Table
        dataSource={rows}
        rowKey='id'
        bordered
        loading={fetching}
        components={vt}
        scroll={{ y: window.innerHeight - 240 }}
        pagination={false}
        className={styles.table}
        style={{
          marginTop: '-1px',
          borderBottom: !!rows.length && '1px solid #f0f0f0',
        }}
      >

        <Table.Column
          key='project'
          title={(
            <Dropdown overlay={<ProjectSortMenu />} placement='bottomRight' trigger={['click']}>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: 12, cursor: 'pointer' }}>
                <div>{`Projects (${rows.length})`}</div>
                <CaretDownOutlined style={{ marginLeft: 5 }} />
              </div>
            </Dropdown>
          )}
          onHeaderCell={() => ({
            style: { padding: '5px 10px' },
          })}
          onCell={record => ({
            onClick: () => onClickProject(record),
            style: { height: 135, padding: 0 },
          })}
          render={(text, record) => <ProjectCellShrunk record={record} />}
        />

        {periods.map((period, index) => (

          <Table.Column
            key={period.date}
            title={(
              <SubcontractorPopover period={period} placement='bottom' mouseEnterDelay={0.5}>
                <div style={{ fontSize: 12 }}>
                  <span>{period.day_of_week}</span>
                  <span style={{ fontWeight: 400 }}>{` ${period.date_formatted}`}</span>
                </div>
              </SubcontractorPopover>
            )}
            width={columnWidth}
            onHeaderCell={() => ({
              className: classNames({
                [styles.today]: period.date === today,
                [styles.weekend]: period.is_weekend && period.date !== today,
              }),
              style: { padding: '5px 10px' },
            })}
            onCell={record => ({
              id: `${record.id}|${period.date}`,
              onClick: () => {
                const { subcontractors } = record.periods[index]
                const jobs = flatMap(subcontractors.map(subcontractor => subcontractor.jobs))
                onClickCell(period, record, jobs, record.periods[index].planner_cell)
              },
              style: { height: 135, padding: 0 },
            })}
            render={(text, record) => {
              const { subcontractors, confirmed_slots: confirmedSlots, required, alert } = record.periods[index]
              return (
                <div className={styles.cell}>
                  <div className={styles.cellBody}>
                    {subcontractors.map(subcontractor => (
                      <React.Fragment key={subcontractor.id}>
                        {subcontractor.jobs.length ? (
                          <SubcontractorDrag jobs={subcontractor.jobs}>
                            <div>
                              {subcontractor.is_multiple ? (
                                <Badge count={subcontractor.slots} size='small' style={{ backgroundColor: subcontractor.icon, fontSize: 7, fontWeight: 500 }} />
                              ) : (
                                <FormatPainterFilled style={{ color: subcontractor.icon, marginRight: 5 }} />
                              )}
                              {subcontractor.name}
                              <JobSlots jobs={subcontractor.jobs} fontSize={6} />
                            </div>
                          </SubcontractorDrag>
                        ) : (
                          <div>
                            <FormatPainterFilled style={{ color: subcontractor.icon, marginRight: 5 }} />
                            {subcontractor.name}
                          </div>
                        )}
                        {subcontractor.timesheet_postings.map(timesheetPosting => (
                          <TimesheetPostingSpan
                            key={timesheetPosting.id}
                            timesheetPosting={timesheetPosting}
                            nowColor={subcontractor.icon}
                            fontSize={9}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </div>
                  <div>
                    {!!alert && <CellAlert content={alert} fontSize={11} />}
                    {!!required && <CellRequired required={required} confirmedSlots={confirmedSlots} fontSize={11} />}
                  </div>
                </div>
              )
            }}
          />

        ))}

      </Table>

    </SubcontractorDrop>

  )

}

Line.propTypes = {
  content: PropTypes.any.isRequired,
}

ProjectTableShrunk.propTypes = {
  periods: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  onClickProject: PropTypes.func.isRequired,
  onClickCell: PropTypes.func.isRequired,
}

export default ProjectTableShrunk
