import { createAction, handleActions } from 'redux-actions';

const loadProject = createAction("SCREENS/PROJECT_VIEW/LOAD_PROJECT");
const updateStimateState = createAction("SCREENS/PROJECT_VIEW/UPDATE_ESTIMATE_PROJECT");
const updateProjectTotalCost = createAction("SCREENS/PROJECT_VIEW/UPDATE_PROJECT_TOTAL_COST");
const clearProject = createAction("SCREENS/PROJECT_VIEW/CLEAR_PROJECT");

const defaultState = null

const reducer = handleActions(
  {
    [loadProject]: (state, action) => action.payload,
    [updateStimateState]: (state, action) => Object.assign({}, state, {
      estimates: action.payload
    }),
    [updateProjectTotalCost]: (state, action) => Object.assign({}, state, {
      total_cost: action.payload
    }),
    [clearProject]: () => null
  },
  defaultState
);

export { loadProject, updateStimateState, updateProjectTotalCost, clearProject, reducer };