import React from 'react'
import PropTypes from 'prop-types'
import { Menu as MenuProvider } from 'antd'
import { FolderFilled } from '@ant-design/icons'
import styles from './styles.css'

export default class Menu extends React.PureComponent {

  static propTypes = {
    rootDirectories: PropTypes.array.isRequired,
    currentDirectoryPath: PropTypes.string,
    onClickRootDirectory: PropTypes.func.isRequired,
  }

  static defaultProps = {
    currentDirectoryPath: null,
  }

  getSelectedKeys() {

    const { rootDirectories, currentDirectoryPath } = this.props

    if (!currentDirectoryPath) return []

    return rootDirectories
      .filter(rootDirectory => currentDirectoryPath.startsWith(rootDirectory.path))
      .map(rootDirectory => rootDirectory.path)

  }

  render() {

    const {
      rootDirectories,
      onClickRootDirectory,
    } = this.props

    return (
      
      <div className={styles.wrapper}>

        <MenuProvider
          selectedKeys={this.getSelectedKeys()}
          onSelect={({ key }) => onClickRootDirectory(key)}
          style={{ minHeight: '100%' }}
        >

          {rootDirectories.map(rootDirectory => (
            <MenuProvider.Item key={rootDirectory.path} className={styles.menuItem}>
              <FolderFilled className={styles.folderIcon} />
              {` ${rootDirectory.name}`}
            </MenuProvider.Item>
          ))}

        </MenuProvider>

      </div>
      
    )

  }

}
