import * as React from 'react';
import PropTypes from 'prop-types';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import styles from './Component.css';

export default class extends React.PureComponent{

  static propTypes = {
    onClickExportStatistics: PropTypes.func.isRequired,
    onClickExportHistory: PropTypes.func.isRequired,
  }

  render () {

    const { onClickExportStatistics, onClickExportHistory } = this.props;

    return (
      <div className={styles.stack}>

        <h1 className={styles.heading}>
          Dashboard
        </h1>

        <div style={{ width: 10 }} />

        <Button type="primary" icon={<CloudDownloadOutlined />} onClick={onClickExportStatistics}>
          Statistics
        </Button>

        <div style={{ width: 10 }} />

        <Button icon={<CloudDownloadOutlined />} onClick={onClickExportHistory}>
          History
        </Button>

      </div>
    );
  }
}