import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors'
import fetchProjects from '../../../Core/Services/fetchProjects'
import fetchTimesheets from '../Services/fetchTimesheets'

export default () => async (dispatch) => {

  await dispatch(fetchTimesheets())

  dispatch(fetchSubcontractors())

  dispatch(fetchProjects())
  
}
