import moduleProps from '../moduleProps'
import fetchRootDirectories from '../Services/fetchRootDirectories'

export default props => (dispatch) => {
  
  moduleProps(props)

  dispatch(fetchRootDirectories())

}
