import { message } from 'antd'
import modalProps from '../modalProps'
import { createSubOrder, updateSubOrder } from '../../../HttpRequests/subOrders'
import handleException from '../../../Core/Helpers/handleException'

export default (values) => async () => {

  const { subOrder, orderItemIds } = modalProps()

  const data = {
    reference: values.reference,
    subcontractor_id: values.subcontractor_id,
    project_id: values.project_id,
    user_id: values.user_id,
    date: values.date,
    vat_perc: values.vat_perc,
    notes: values.notes || null,
  }

  let response

  try {

    if (subOrder) {

      response = await updateSubOrder({ id: subOrder.id, data })

      message.success(`Updated "${response.data.reference}"`)

    } else {

      data.order_item_ids = orderItemIds

      response = await createSubOrder({ data })

      message.success(`Created "${response.data.reference}"`)

    }

    modalProps().onClose(response.data)

  } catch (error) {

    handleException(error)

  }

}
