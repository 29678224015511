// @flow
import * as React from 'react';
import Screen from '../../Components/Screen';

import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import Filters from './Components/Filters';
import Table from './Components/Table';
import Footer from './Components/Footer';

import ValuationModal from '../../Modals/Valuation';

type Props = {
  valuations: [],
  valuationTotals: [],
  valuationStatuses: [],
  clients: [],
  users: [],
  pagination: {},
  filters: any,
  fetching: boolean,
  showValuationModal: boolean,
  searchTerm: string,
  componentDidMount: () => void,
  onClickNewValuation: Function,
  onCloseValuationModal: Function,
  didCreateValuation: Function,
  onChangeTable: Function, 
  onChangeSearch: Function,
  onSubmitSearch: Function,
  onChangeFilter: Function,
  onClickExport: Function
}

export default class extends React.PureComponent<Props> {

  componentDidMount = () => this.props.componentDidMount();

  render() {

    const {
      valuations,
      valuationTotals,
      valuationStatuses,
      clients,
      users,
      pagination,
      filters,
      fetching,
      showValuationModal,
      searchTerm,
      onClickNewValuation,
      onCloseValuationModal,
      didCreateValuation,
      onChangeTable,
      onChangeSearch,
      onSubmitSearch,
      onChangeFilter,
      onClickExport
    } = this.props;

    return (

      <Screen>

        <Breadcrumbs />

        <Header
          searchTerm={searchTerm}
          onClickNewValuation={onClickNewValuation}
          onChangeSearch={onChangeSearch}
          onSubmitSearch={onSubmitSearch}
          onClickExport={onClickExport}
        />

        <Filters
          filters={filters}
          valuationStatuses={valuationStatuses}
          clients={clients}
          users={users}
          onChangeFilter={onChangeFilter}
        />

        <Table
          valuations={valuations}
          fetching={fetching}
          pagination={pagination}
          onChangeTable={onChangeTable}
        />

        <Footer
          valuationTotals={valuationTotals}
        />

        { showValuationModal && (
          <ValuationModal
            onClose={onCloseValuationModal}
            didCreate={didCreateValuation}
          />
        )}
      </Screen>

    )

  };
}