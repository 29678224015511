import { createSelector } from 'reselect';

import type { ValuationTotals } from '../../../types';

const selectValuation = state => state.screens.ValuationView.valuation;

export default createSelector(
  selectValuation,
  (valuation) => {

    const valuationTotals: ValuationTotals = {
      previous_aggregate_total: 0,
      new_aggregate_total: 0,
      difference: 0
    }
    
    if (valuation) {

      valuation.orders.forEach((order) => order.order_groups.forEach((orgerGroup) => orgerGroup.order_items.forEach((orderItem) => {

        if (orderItem.flag === 'Omit' || orderItem.flag === 'By Others') return;
        
        valuationTotals.previous_aggregate_total += (Number(orderItem.valuation_item.previous_aggregate_percentage) * Number(orderItem.total)) / 100;
        valuationTotals.new_aggregate_total += (Number(orderItem.valuation_item.aggregate_percentage) * Number(orderItem.total)) / 100; 

      })));

    }

    valuationTotals.previous_aggregate_total = valuationTotals.previous_aggregate_total
    valuationTotals.new_aggregate_total = valuationTotals.new_aggregate_total
    valuationTotals.difference = valuationTotals.new_aggregate_total - valuationTotals.previous_aggregate_total

    return valuationTotals;

  }
)