import { getPlannerPeriodSubcontractors } from '../../../../../HttpRequests/planner'
import { setData } from '../State/data'
import { startFetching, finishFetching } from '../State/fetching'
import handleException from '../../../../../Core/Helpers/handleException'

export default (date) => async (dispatch) => {

  const params = {
    date,
  }

  dispatch(startFetching())

  try {

    const response = await getPlannerPeriodSubcontractors({ params })

    dispatch(setData(response.data))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
