import { createAction, handleActions } from 'redux-actions';

const loadGlobalContacts = createAction("CORE/GLOBAL_CONTACT/LOAD_GLOBAL_CONTACT");

const defaultState = []

const reducer = handleActions(
  {
    [loadGlobalContacts]: (state, action) => action.payload,    
  },
  defaultState
);

export { loadGlobalContacts, reducer };