import { message } from 'antd'
import { updateSubcontractor } from '../../../HttpRequests/subcontractors';

import handleException from '../../../Core/Helpers/handleException';

export default (values) => async () => {
  
  try {
    
    const data = {
      ...values,
      user: undefined,
      co_vehicle: undefined,
      deactivated_at: values.deactivated_at || null,
    };

    const response = await updateSubcontractor({ id: values.id, data });
    const subcontractor = response.data.data;

    message.success("Subcontractor Updated");

    return subcontractor;

  }  catch(error) {
    
    handleException(error);

    return false;
  }

}