import { connect } from 'react-redux'
import { isSubmitting } from 'redux-form'
import SubValuation from './SubValuation'

import { FORM_NAME } from './Form'

import componentDidMount from './Handlers/componentDidMount'
import onClickSubmit from './Handlers/onClickSubmit'

const mapStateToProps = (state) => ({
  subcontractors: state.core.subcontractors,
  projects: state.core.projects,
  users: state.core.users,
  submitting: isSubmitting(FORM_NAME)(state),
})

const actionCreators = {
  componentDidMount,
  onClickSubmit,
}

export default connect(mapStateToProps, actionCreators)(SubValuation)
