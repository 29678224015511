import React from 'react';
import { Field } from 'redux-form';
import { DatePickerField } from 'FormFields';

const labelColSpan = 4;
const wrapperColSpan = 17;

export default () => (

  <Field
    name="date"
    label="Date"
    component={DatePickerField}
    labelCol={{ span: labelColSpan }}
    wrapperCol={{ span: wrapperColSpan }}
    style={{ width: '100%' }}
    required
  />

)