import { createAction, handleActions } from 'redux-actions'

const setActiveTab = createAction('SCREENS/SUB_VALUATION/SET_ACTIVE_TAB')
const resetActiveTab = createAction('SCREENS/SUB_VALUATION/RESET_ACTIVE_TAB')

const defaultState = 'ITEMS'

const reducer = handleActions(
  {
    [setActiveTab]: (state, { payload }) => payload,
    [resetActiveTab]: () => defaultState,
  },
  defaultState,
)

export { setActiveTab, resetActiveTab, reducer }
