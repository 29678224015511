import { connect } from 'react-redux'
import SubcontractorDrawer from './SubcontractorDrawer'

import selectDateRange from '../../Selectors/selectDateRange'

import componentDidMount from './Handlers/componentDidMount'
import componentWillUnmount from './Handlers/componentWillUnmount'

const mapStateToProps = (state) => ({
  dateRange: selectDateRange(state),
  periods: state.screens.Planner.SubcontractorDrawer.data.periods,
  rows: state.screens.Planner.SubcontractorDrawer.data.rows,
  fetching: state.screens.Planner.SubcontractorDrawer.fetching,
})

const actionCreators = {
  componentDidMount,
  componentWillUnmount,
}

export default connect(mapStateToProps, actionCreators)(SubcontractorDrawer)
