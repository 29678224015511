import { createAction, handleActions } from 'redux-actions';

const loadRootDirectories = createAction("SCREENS/PROJECT_VIEW/LOAD_ROOT_DIRECTORIES");
const clearRootDirectories = createAction("SCREENS/PROJECT_VIEW/CLEAR_ROOT_DIRECTORIES");

const defaultState = []

const reducer = handleActions(
  {
    [loadRootDirectories]: (state, action) => action.payload,
    [clearRootDirectories]: () => []
  },
  defaultState
);

export { loadRootDirectories, clearRootDirectories, reducer };