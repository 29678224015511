import { message } from 'antd'
import handleException from '../../../Core/Helpers/handleException';
import { invoiceValuation } from '../../../HttpRequests/valuations';
import fetchValuation from './fetchValuation';

export default () => async (dispatch, getState) => {

  const { valuation } = getState().screens.ValuationView;
  
  try {

    await invoiceValuation({ id: valuation.id, data: { type: 'application' } });

    await dispatch(fetchValuation());

    message.success('Application Created');
    
  } catch(error) {

    handleException(error);

  }
  
}