import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { getTeamUrl } from 'Common'
import styles from './styles.css'

const Section = props => (
  <div className={styles.section}>
    {props.children}
  </div>
)

const Outlet = props => (
  <div className={styles.outlet}>
    {props.children}
  </div>
)

const Label = props => (
  <div className={styles.label}>
    {props.children}
  </div>
)

const Value = props => (
  <div className={styles.value}>
    {props.children}
  </div>
)

const Sidebar = props => {

  const {
    subValuation,
    statusLogs,
  } = props

  return (
    
    <div className={styles.wrapper}>

      <Section>

        <Outlet>
          <Label>Subcontractor</Label>
          <Value>
            <NavLink to={getTeamUrl(`subcontractors/${subValuation.subcontractor.id}/view`)}>
              {subValuation.subcontractor.name}
            </NavLink>
          </Value>
        </Outlet>

        <Outlet>
          <Label>Client</Label>
          <Value>
            <NavLink to={getTeamUrl(`clients/${subValuation.project.client_site.client.id}/view`)}>
              {subValuation.project.client_site.client.name}
            </NavLink>
          </Value>
        </Outlet>

        <Outlet>
          <Label>Project</Label>
          <Value>
            <NavLink to={getTeamUrl(`projects/${subValuation.project.id}/view`)}>
              {subValuation.project.id}
              {subValuation.project.title && ` » ${subValuation.project.title}`}
            </NavLink>
          </Value>
        </Outlet>

        <Outlet>
          <Label>Site</Label>
          <Value>
            {[
              subValuation.project.client_site.name,
              subValuation.project.client_site.address_line_1,
              subValuation.project.client_site.address_line_2,
              subValuation.project.client_site.address_line_3,
              subValuation.project.client_site.town_or_city,
              subValuation.project.client_site.county,
              subValuation.project.client_site.postcode,
            ].filter(line => line).join(', ')}
          </Value>
        </Outlet>
        
        <Outlet>
          <Label>Owner</Label>
          <Value>{subValuation.user.full_name}</Value>
        </Outlet>

        <Outlet>
          <Label>Date</Label>
          <Value>{moment(subValuation.date).format('DD MMM YYYY')}</Value>
        </Outlet>

        <Outlet>
          <Label>To Date</Label>
          <Value>{moment(subValuation.to_date).format('DD MMM YYYY')}</Value>
        </Outlet>

      </Section>

      {!!statusLogs.length && (
        
        <Section>

          {statusLogs.map(statusLog => (

            <Outlet key={statusLog.key}>
              <Label>{statusLog.label}</Label>
              <Value>{statusLog.value}</Value>
            </Outlet>

          ))}

        </Section>

      )}

    </div>
    
  )

}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

Outlet.propTypes = {
  children: PropTypes.node.isRequired,
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
}

Value.propTypes = {
  children: PropTypes.node.isRequired,
}

Sidebar.propTypes = {
  subValuation: PropTypes.object.isRequired,
  statusLogs: PropTypes.array.isRequired,
}

export default Sidebar
