import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Table, Dropdown, Menu } from 'antd'
import { ArrowDownOutlined, ArrowUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import numeral from 'numeral'
import moment from 'moment'
import classNames from 'classnames'
import { getTeamUrl } from 'Common'
import styles from './styles.css'

const subcontractorSortMenu = (onChangeSort) => (
  <Menu onClick={({ key }) => onChangeSort(key)} style={{ width: 130 }}>
    <Menu.ItemGroup title='Sort' />
    <Menu.Divider />
    <Menu.Item key='subcontractor.asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Ascending</Menu.Item>
    <Menu.Item key='subcontractor.desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Descending</Menu.Item>
  </Menu>
)

const projectSortMenu = (onChangeSort) => (
  <Menu onClick={({ key }) => onChangeSort(key)} style={{ width: 130 }}>
    <Menu.ItemGroup title='Sort' />
    <Menu.Divider />
    <Menu.SubMenu title='Project' key='project'>
      <Menu.Item key='project.asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Ascending</Menu.Item>
      <Menu.Item key='project.desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Descending</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Client' key='client'>
      <Menu.Item key='client.asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Ascending</Menu.Item>
      <Menu.Item key='client.desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Descending</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Site' key='site'>
      <Menu.Item key='client_site.asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Ascending</Menu.Item>
      <Menu.Item key='client_site.desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Descending</Menu.Item>
    </Menu.SubMenu>
  </Menu>
)

const startDateSortMenu = (onChangeSort) => (
  <Menu onClick={({ key }) => onChangeSort(key)} style={{ width: 130 }}>
    <Menu.ItemGroup title='Sort' />
    <Menu.Divider />
    <Menu.SubMenu title='Start Date' key='pr_start_date'>
      <Menu.Item key='pr_start_date.asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Earliest</Menu.Item>
      <Menu.Item key='pr_start_date.desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Latest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Last Client Val' key='pr_last_val_date'>
      <Menu.Item key='pr_last_val_date.asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Earliest</Menu.Item>
      <Menu.Item key='pr_last_val_date.desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Latest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Last Sub Val' key='sub_last_val_date'>
      <Menu.Item key='sub_last_val_date.asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Earliest</Menu.Item>
      <Menu.Item key='sub_last_val_date.desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Latest</Menu.Item>
    </Menu.SubMenu>
  </Menu>
)

const prTotalSortMenu = (onChangeSort) => (
  <Menu onClick={({ key }) => onChangeSort(key)} style={{ width: 130, float: 'right' }}>
    <Menu.ItemGroup title='Sort' />
    <Menu.Divider />
    <Menu.SubMenu title='Total' key='pr_total'>
      <Menu.Item key='pr_total.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='pr_total.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Complete' key='pr_complete'>
      <Menu.SubMenu title='Complete (£)' key='$pr_complete'>
        <Menu.Item key='pr_complete.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='pr_complete.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Complete (%)' key='pr_complete_perc'>
        <Menu.Item key='pr_complete_perc.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='pr_complete_perc.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu title='Remaining' key='pr_remaining'>
      <Menu.SubMenu title='Remaining (£)' key='$pr_remaining'>
        <Menu.Item key='pr_remaining.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='pr_remaining.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Remaining (%)' key='pr_remaining_perc'>
        <Menu.Item key='pr_remaining_perc.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='pr_remaining_perc.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
  </Menu>
)

const subTotalSortMenu = (onChangeSort) => (
  <Menu onClick={({ key }) => onChangeSort(key)} style={{ width: 130, float: 'right' }}>
    <Menu.ItemGroup title='Sort' />
    <Menu.Divider />
    <Menu.SubMenu title='Total' key='sub_total'>
      <Menu.Item key='sub_total.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='sub_total.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Complete' key='sub_complete'>
      <Menu.SubMenu title='Complete (£)' key='$sub_complete'>
        <Menu.Item key='sub_complete.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='sub_complete.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Complete (%)' key='sub_complete_perc'>
        <Menu.Item key='sub_complete_perc.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='sub_complete_perc.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
    <Menu.SubMenu title='Remaining' key='sub_remaining'>
      <Menu.SubMenu title='Remaining (£)' key='$sub_remaining'>
        <Menu.Item key='sub_remaining.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='sub_remaining.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu title='Remaining (%)' key='sub_remaining_perc'>
        <Menu.Item key='sub_remaining_perc.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='sub_remaining_perc.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    </Menu.SubMenu>
  </Menu>
)

const getSortByIcon = direction => {
  if (direction === 'up') return <ArrowUpOutlined style={{ marginRight: 5, color: '#000000d9' }} />
  if (direction === 'down') return <ArrowDownOutlined style={{ marginRight: 5, color: '#000000d9' }} />
  return false
}

const gbp = value => `${value < 0 ? '-' : ''}£${numeral(Math.abs(value)).format('0,0.00')}`

const perc = value => `${numeral(value).format('0,0')}%`

const getSubRemainingColour = subRemaining => {
  if (subRemaining > 0) return '#d49e20'
  if (subRemaining < 0) return 'red'
  return false
}

const rightAlign = {
  onHeaderCell: () => ({
    style: { textAlign: 'right' },
  }),
  onCell: () => ({
    style: { textAlign: 'right' },
  }),
}

const Projects = props => {

  const {
    tableRows,
    fetching,
    sortBy,
    onChangeSort,
  } = props

  return (
    
    <Table
      dataSource={tableRows}
      rowKey='key'
      loading={fetching}
    >

      <Table.Column
        title={(
          <Dropdown trigger={['click']} overlay={subcontractorSortMenu(onChangeSort)}>
            <div className={styles.column}>
              <div>Subcontractor</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        )}
        key='subcontractor'
        render={(text, record) => (
          <div style={{ fontSize: 18, fontWeight: 500 }}>
            {sortBy === 'subcontractor.asc' && getSortByIcon('up')}
            {sortBy === 'subcontractor.desc' && getSortByIcon('down')}
            <NavLink to={getTeamUrl(`sub_projects/${record.subcontractor.id}`)}>
              <div style={{ fontSize: 15 }}>{record.subcontractor.name}</div>
            </NavLink>
          </div>
        )}
      />

      <Table.Column
        title={(
          <Dropdown trigger={['click']} overlay={projectSortMenu(onChangeSort)}>
            <div className={styles.column}>
              <div>Project</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        )}
        key='project'
        render={(text, record) => (
          <React.Fragment>
            <div style={{ fontSize: 16 }}>
              {(sortBy === 'project.asc' || sortBy === 'client.asc') && getSortByIcon('up')}
              {(sortBy === 'project.desc' || sortBy === 'client.desc') && getSortByIcon('down')}
              <strong>{record.project.id}</strong>
              <span>{` ${record.project.client_site.client.name}`}</span>
            </div>
            <div style={{ fontSize: 15, color: '#999999' }}>
              {sortBy === 'client_site.asc' && getSortByIcon('up')}
              {sortBy === 'client_site.desc' && getSortByIcon('down')}
              {record.project.client_site.name}
            </div>
          </React.Fragment>
        )}
      />

      <Table.Column
        title={(
          <Dropdown trigger={['click']} overlay={startDateSortMenu(onChangeSort)}>
            <div className={classNames(styles.column)}>
              <div>Start Date</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        )}
        key='dates'
        render={(text, record) => (
          <React.Fragment>
            <div style={{ fontSize: 16 }}>
              {sortBy === 'pr_start_date.asc' && getSortByIcon('up')}
              {sortBy === 'pr_start_date.desc' && getSortByIcon('down')}
              <strong>{record.pr_start_date ? moment(record.pr_start_date).format('DD/MM/YYYY') : '-'}</strong>
            </div>
            <br />
            <div>
              {sortBy === 'pr_last_val_date.asc' && getSortByIcon('up')}
              {sortBy === 'pr_last_val_date.desc' && getSortByIcon('down')}
              {'Last Client Val: '}
              <strong>{record.pr_last_val_date ? moment(record.pr_last_val_date).format('DD/MM/YYYY') : '-'}</strong>
            </div>
            <div>
              {sortBy === 'sub_last_val_date.asc' && getSortByIcon('up')}
              {sortBy === 'sub_last_val_date.desc' && getSortByIcon('down')}
              {'Last Sub Val: '}
              <strong>{record.sub_last_val_date ? moment(record.sub_last_val_date).format('DD/MM/YYYY') : '-'}</strong>
            </div>
          </React.Fragment>
        )}
      />

      <Table.Column
        title={(
          <Dropdown trigger={['click']} overlay={prTotalSortMenu(onChangeSort)}>
            <div className={classNames(styles.column, styles.numeric)}>
              <div>Project Total</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        )}
        key='pr_total'
        {...rightAlign}
        render={(text, record) => (
          <React.Fragment>
            <div style={{ fontSize: 16 }}>
              {sortBy === 'pr_total.desc' && getSortByIcon('up')}
              {sortBy === 'pr_total.asc' && getSortByIcon('down')}
              <strong>{gbp(record.pr_total)}</strong>
            </div>
            <br />
            <div style={{ color: record.pr_complete !== 0 && 'green' }}>
              {(sortBy === 'pr_complete.desc' || sortBy === 'pr_complete_perc.desc') && getSortByIcon('up')}
              {(sortBy === 'pr_complete.asc' || sortBy === 'pr_complete_perc.asc') && getSortByIcon('down')}
              {'Complete: '}
              <strong>{gbp(record.pr_complete)}</strong>
              {` (${perc(record.pr_complete_perc)})`}
            </div>
            <div style={{ color: record.pr_remaining !== 0 && '#d49e20' }}>
              {(sortBy === 'pr_remaining.desc' || sortBy === 'pr_remaining_perc.desc') && getSortByIcon('up')}
              {(sortBy === 'pr_remaining.asc' || sortBy === 'pr_remaining_perc.asc') && getSortByIcon('down')}
              {'Remaining: '}
              <strong>{gbp(record.pr_remaining)}</strong>
              {` (${perc(record.pr_remaining_perc)})`}
            </div>
          </React.Fragment>
        )}
      />

      <Table.Column
        title={(
          <Dropdown trigger={['click']} overlay={subTotalSortMenu(onChangeSort)}>
            <div className={classNames(styles.column, styles.numeric)}>
              <div>Sub Total</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        )}
        key='sub_total'
        {...rightAlign}
        render={(text, record) => (
          <React.Fragment>
            <div style={{ fontSize: 16 }}>
              {sortBy === 'sub_total.desc' && getSortByIcon('up')}
              {sortBy === 'sub_total.asc' && getSortByIcon('down')}
              <strong>{gbp(record.sub_total)}</strong>
            </div>
            <br />
            <div style={{ color: record.sub_complete !== 0 && 'green' }}>
              {(sortBy === 'sub_complete.desc' || sortBy === 'sub_complete_perc.desc') && getSortByIcon('up')}
              {(sortBy === 'sub_complete.asc' || sortBy === 'sub_complete_perc.asc') && getSortByIcon('down')}
              {'Complete: '}
              <strong>{gbp(record.sub_complete)}</strong>
              {` (${perc(record.sub_complete_perc)})`}
            </div>
            <div style={{ color: getSubRemainingColour(record.sub_remaining) }}>
              {(sortBy === 'sub_remaining.desc' || sortBy === 'sub_remaining_perc.desc') && getSortByIcon('up')}
              {(sortBy === 'sub_remaining.asc' || sortBy === 'sub_remaining_perc.asc') && getSortByIcon('down')}
              {'Remaining: '}
              <strong>{gbp(record.sub_remaining)}</strong>
              {` (${perc(record.sub_remaining_perc)})`}
            </div>
          </React.Fragment>
        )}
      />

    </Table>

  )

}

Projects.propTypes = {
  tableRows: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  sortBy: PropTypes.string,
  onChangeSort: PropTypes.func.isRequired,
}

Projects.defaultProps = {
  sortBy: null,
}

export default Projects
