// @flow
import * as React from 'react';
import { Tabs } from 'antd';
import Screen from '../../Components/Screen';

import Initializing from './Components/Initializing';
import SubcontractorModal from '../../Modals/Subcontractor';
import FileManager from '../../Modules/FileManager';

import Breadcrumbs from './Components/Breadcrumbs';
import Header from './Components/Header';
import SubOrders from './Components/SubOrders';

type Props = {
  users: [],
  subcontractor: null|Object,
  showSubcontractorModal: boolean,

  componentDidMount: (props: any) => void,
  componentWillUnmount: () => void,
  onClickExportRateHistory: () => void,
  onClickEditSubcontractor: () => void,
  onChangeActiveTab: () => void,
  onCloseSubcontractorModal: () => void,
  didUpdateSubcontractor: () => void,
  didDeleteSubcontractor: () => void,
}

export default class extends React.PureComponent<Props> {
  
  componentDidMount = () => this.props.componentDidMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render() {
    const {
      users,
      subcontractor,
      showSubcontractorModal,

      onClickExportRateHistory,
      onClickEditSubcontractor,
      onChangeActiveTab,
      onCloseSubcontractorModal,
      didUpdateSubcontractor,
      didDeleteSubcontractor,
    } = this.props;

    const { TabPane } = Tabs;

    if (!subcontractor) return <Initializing />

    return (

      <Screen>
        
        <Breadcrumbs
          subcontractor={subcontractor}
        />

        <Header
          users={users}
          subcontractor={subcontractor}
          onClickExportRateHistory={onClickExportRateHistory}
          onClickEditSubcontractor={onClickEditSubcontractor}
        />

        <Tabs
          defaultActiveKey="FILES"
          className="p-t-10"
          onChange={onChangeActiveTab}
          animated={false}
        >

          <TabPane tab="FILES" key="FILES">

            <FileManager
              modelType='Subcontractor'
              modelId={subcontractor.id}
            />

          </TabPane>

          <TabPane tab="ORDERS" key="SUB_ORDERS">

            <SubOrders />

          </TabPane>

        </Tabs>

        {showSubcontractorModal && (
          <SubcontractorModal
            onClose={onCloseSubcontractorModal}
            didUpdate={didUpdateSubcontractor}
            didDelete={didDeleteSubcontractor}
            subcontractor={subcontractor}
          />
        )}

      </Screen>

    )
  };
}