// @flow

import * as React from 'react';
import List from './Components/List';

type Props = {
  listData: [],
  serviceTypes: [],
  editable: boolean,
  selectedOrderItemIds: [],
  onClickAddOrderItem: Function,
  onClickAddOrderGroup: Function,
  onClickRemoveOrderItem: Function,
  onClickRemoveOrderGroup: Function,
  onClickDuplicateOrderItem: Function,
  onClickDuplicateOrderGroup: Function,
  onBlurOrderItemField: Function,
  onChangeOrderItemField: Function,
  onChangeOrderGroupName: Function,
  onMoveOrderItem: Function,
  onToggleOrderItem: Function
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      listData,
      serviceTypes,
      editable,
      selectedOrderItemIds,
      onClickAddOrderItem,
      onClickAddOrderGroup,
      onClickRemoveOrderItem,
      onClickRemoveOrderGroup,
      onClickDuplicateOrderItem,
      onClickDuplicateOrderGroup,
      onBlurOrderItemField,
      onChangeOrderItemField,
      onChangeOrderGroupName,
      onMoveOrderItem,
      onToggleOrderItem
    } = this.props;

    return (

      <List
        listData={listData}
        serviceTypes={serviceTypes}
        editable={editable}
        selectedOrderItemIds={selectedOrderItemIds}
        onClickAddOrderItem={onClickAddOrderItem}
        onClickAddOrderGroup={onClickAddOrderGroup}
        onClickRemoveOrderItem={onClickRemoveOrderItem}
        onClickRemoveOrderGroup={onClickRemoveOrderGroup}
        onClickDuplicateOrderItem={onClickDuplicateOrderItem}
        onClickDuplicateOrderGroup={onClickDuplicateOrderGroup}
        onBlurOrderItemField={onBlurOrderItemField}
        onChangeOrderItemField={onChangeOrderItemField}
        onChangeOrderGroupName={onChangeOrderGroupName}
        onMoveOrderItem={onMoveOrderItem}
        onToggleOrderItem={onToggleOrderItem}
      />

    )
  }
}