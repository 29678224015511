import { cloneDeep, assign, find } from 'lodash';
import { loadOrder } from '../State/order';

export default (ids) => (dispatch, getState) => {

  const { order } = getState().screens.OrderView;

  const newOrder = assign(cloneDeep(order), { order_groups: [] });
  
  newOrder.order_groups = [];
  
  order.order_groups.forEach((orderGroup, orderGroupIndex) => {

    const orderItems = orderGroup.order_items.filter((orderItem, orderItemIndex) => !find(ids, { orderGroupIndex, orderItemIndex }));
    
    newOrder.order_groups.push(assign({}, orderGroup, {
      order_items: orderItems
    }));

  });
  
  dispatch(loadOrder(newOrder));

}