// @flow

import * as React from 'react';
import { last } from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import { CloudDownloadOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Dropdown, Menu, Checkbox } from 'antd';
import styles from './Item.css';

type Props = {
  style: any,
  mode: 'list'|'grid',
  item: {
    type: 'directory'|'file',
    path: string,
    last_modified: string,
    name: string
  },
  selected: boolean,
  checked: boolean,
  onSelectItem: (key: string, type: string) => void,
  onDoubleClickItem: (type: string, path: string) => void,
  onToggleItemCheckbox: () => void,
  onClickFileContextMenuItem: () => void

}

export default class extends React.PureComponent<Props> {

  getIconTypeAndColor () {

    const { type, name } = this.props.item;
    
    let iconType;
    let iconColor;

    if (type === 'directory') {

      iconType = 'folder'; 
      iconColor = '#444';

    } else if (type === 'file') {
      
      const extension = last(name.toLowerCase().split('.'));
      
      switch (extension) {

        default:
          iconType = 'file';
          iconColor = '#444';
          break;

        case 'doc':
        case 'docx':
          iconType = 'file-word';
          iconColor = '#2C5791';
          break;

        case 'ppt':
          iconType = 'file-ppt';
          iconColor = '#C05535';
          break;

        case 'xls':
        case 'xlsx':
        case 'xlsm':
        case 'csv':
          iconType = 'file-excel';
          iconColor = '#396A45';
          break;

        case 'pdf':
          iconType = 'file-pdf';
          iconColor = '#D4241C';
          break;

        case 'png':
        case 'jpg':
        case 'jpeg':
          iconType = 'picture';
          iconColor = '#1990FF';
          break;

      }

    }

    return { iconType, iconColor };

  }


  getMenu () {

    const { type, path } = this.props.item;
    const { onClickFileContextMenuItem } = this.props;

    return (
      <Menu onClick={(params) => onClickFileContextMenuItem(params, path)}>

        {type === 'directory' && (

          <Menu.Item key="RENAME_DIRECTORY">
            <EditOutlined />
            {' Rename Directory'}
          </Menu.Item>
          
        )}

        {type === 'directory' && (
            
          <Menu.Item key="DELETE_DIRECTORY">
            <DeleteOutlined />
            {' Delete Directory'}
          </Menu.Item>
          
        )}

        {type === 'file' && (
          
          <Menu.Item key="RENAME_FILE">
            <EditOutlined />
            {' Rename File'}
          </Menu.Item>
        
        )}

        {type === 'file' && (
            
          <Menu.Item key="DELETE_FILE">
            <DeleteOutlined />
            {' Delete File'}
          </Menu.Item>
          
        )}

        {type === 'file' && (
            
          <Menu.Item key="DOWNLOAD_FILE">
            <CloudDownloadOutlined />
            {' Download File'}
          </Menu.Item>

        )}

      </Menu>
    );

  }


  renderAsRow () {

    const {
      style,
      item,
      selected,
      checked,
      onSelectItem,
      onDoubleClickItem,
      onToggleItemCheckbox
    } = this.props;

    const menu = this.getMenu();

    const { iconType, iconColor } = this.getIconTypeAndColor();

    return (
      <Dropdown overlay={menu} trigger={['contextMenu']}>

        <div
          className={classnames(styles.row, { [styles.rowSelected]: selected })}
          style={style}
          onClick={() => onSelectItem(item.path, item.type)}
          onContextMenu={() => onSelectItem(item.path, item.type)}
          onDoubleClick={() => onDoubleClickItem(item.type, item.path)}
          role="presentation"
        >
          <div className={classnames(styles.col, styles.checkCol, { [styles.colSelected]: selected })}>

            <Checkbox
              checked={checked}
              value={checked}
              onClick={() => onToggleItemCheckbox(item.path)}
            />
            
          </div>

          <div className={classnames(styles.col, styles.nameCol, { [styles.colSelected]: selected })}>

            <LegacyIcon type={iconType} theme="filled" className={styles.colIcon} style={{ color: selected ? 'white' : iconColor }} />
            {item.name}

          </div>

          <div className={classnames(styles.col, styles.timestampCol, { [styles.colSelected]: selected })}>

            {item.type === 'directory' && '--'}
            {item.type === 'file' && moment(item.last_modified).format('DD/MM/YYYY [at] HH:mm:ss')}

          </div>

        </div>

      </Dropdown>
    );
  }


  renderAsIcon () {

    const {
      style,
      item,
      selected,
      onSelectItem,
      onDoubleClickItem
    } = this.props;

    const menu = this.getMenu();

    const { iconType, iconColor } = this.getIconTypeAndColor();

    return (
      <div
        className={styles.iconContainer}
        style={style}
        onClick={() => onSelectItem(item.path, item.type)}
        onContextMenu={() => onSelectItem(item.path, item.type)}
        onDoubleClick={() => onDoubleClickItem(item.type, item.path)}
        role="presentation"
      >
    
        <Dropdown overlay={menu} trigger={['contextMenu']}>

          <span>

            <div className={classnames(styles.iconWrapper, { [styles.iconWrapperSelected]: selected })}>

              {item.thumbnail && (
                <img src={item.thumbnail} className={styles.thumbnail} />
              )}

              {!item.thumbnail && (
                <LegacyIcon type={iconType} theme="filled" className={styles.icon} style={{ color: iconColor }} />
              )}

            </div>
              
            <div className={classnames(styles.name, styles.ellipsis, { [styles.nameSelected]: selected })}>
              {item.name}
            </div>
          
          </span>

        </Dropdown>

      </div>
    );

  }


  render () {

    const { mode } = this.props;

    if (mode === 'list') return this.renderAsRow();

    if (mode === 'grid') return this.renderAsIcon();

    return null;
  }
}