import { initialize } from 'redux-form'
import { last } from 'lodash'
import modalProps from '../modalProps'
import { FORM_NAME } from '../Form'

export default () => (dispatch, getState) => {

  const { newDirectory } = modalProps()
  
  const { selectedItemPath, selectedItemType } = getState().modules.FileManager

  const initialValues = {
    action: 'create_directory',
  }

  if (newDirectory) {
    dispatch(initialize(FORM_NAME, initialValues))
    return
  }

  const splitPath = selectedItemPath.split('/')
  const selectedItem = last(splitPath)

  initialValues.current = selectedItem

  if (selectedItemType === 'directory') {

    initialValues.action = 'rename_directory'
    initialValues.name = selectedItem
    
  }

  if (selectedItemType === 'file') {

    initialValues.action = 'rename_file'
    initialValues.name = selectedItem.split('.').slice(0, -1).join('.')

  }

  dispatch(initialize(FORM_NAME, initialValues))

}
