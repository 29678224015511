import { connect } from 'react-redux'
import Summary from './Summary'

import selectTableRows from './Selectors/selectTableRows'

import onChangeSort from './Handlers/onChangeSort'
import onClickRow from './Handlers/onClickRow'

const mapStateToProps = (state) => ({
  tableRows: selectTableRows(state),
  fetching: state.screens.Subbies.SubProjects.fetching,
  sortBy: state.screens.Subbies.SubProjects.sortBy,
})

const actionCreators = {
  onChangeSort,
  onClickRow,
}

export default connect(mapStateToProps, actionCreators)(Summary)
