import * as React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styles from './Component.css';

export default class extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool,
    orderItemId: PropTypes.number,
    onClickCancel: PropTypes.func.isRequired,
    onClickSubmit: PropTypes.func.isRequired
  }

  static defaultProps = {
    submitting: null,
    orderItemId: null
  }

  render () {

    const {
      submitting,
      orderItemId,
      onClickCancel,
      onClickSubmit
    } = this.props;

    return (
        
      <div className={styles.footer}>
      
        <Button
          type="default"
          disabled={submitting}
          onClick={onClickCancel}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          loading={submitting}
          onClick={onClickSubmit}
        >
          {orderItemId ? 'Update' : 'Create'}
        </Button>

      </div>

    )
  }
}