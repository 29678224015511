import { connect } from 'react-redux'
import Table from './Table'

import selectTableRows from './Selectors/selectTableRows'

import onChangeSort from './Handlers/onChangeSort'

const mapStateToProps = (state) => ({
  tableRows: selectTableRows(state),
  fetching: state.screens.SubProject.SubProjects.fetching,
  sortBy: state.screens.SubProject.SubProjects.sortBy,
})

const actionCreators = {
  onChangeSort,
}

export default connect(mapStateToProps, actionCreators)(Table)
