// @flow

import * as React from 'react';

import {
  CaretDownOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  DatabaseOutlined,
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  EyeOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  MailFilled,
  MailOutlined,
  PlusOutlined,
  RedoOutlined,
  RocketFilled,
  SyncOutlined,
  UndoOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Dropdown, Menu } from 'antd';
import NotesPopover from './Components/NotesPopover';
import styles from './Component.css';

const ButtonGroup = Button.Group;

type Props = {
  valuation: any,
  onClickActionMenuItem: (key: string) => void,
  onChangeNotes: Function,
  onChangeValuationField: Function
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      valuation,
      onClickActionMenuItem,
      onChangeNotes,
      onChangeValuationField
    } = this.props;

    const { Item, Divider } = Menu;
    const actionMenu = (

      <Menu onClick={({ key }) => onClickActionMenuItem(key)}>

        <Item key="SEND">
          <MailOutlined />
          {' Send via Email'}
        </Item>

        <Divider />

        <Menu.SubMenu
          title={(
            <span>
              <FilePdfOutlined />
              {' Valuation PDF'}
            </span>
          )}
        >

          <Item key="CLIENT_PDF">
            {' Client Version'}
          </Item>

          <Item key="INTERNAL_PDF">
            {' Internal Version'}
          </Item>

        </Menu.SubMenu>

        <Item key="EXCEL">
          <FileExcelOutlined />
          {' Valuation Excel'}
        </Item>

        <Divider />

        <Item key="HISTORY">
          <DatabaseOutlined />
          {' View History'}
        </Item>

        <Divider />

        <Item key="EDIT">
          <EditOutlined />
          {' Edit Details'}
        </Item>

        <Item key="REVISE" disabled={valuation.action_statuses.revise !== true}>
          <RedoOutlined />
          {' Revise Valuation'}
        </Item>

        <Item key="REFRESH" disabled={valuation.action_statuses.refresh !== true}>
          <SyncOutlined />
          {' Refresh Valuation'}
        </Item>

        <Divider />

        <Item key="CREATE_INVOICE" disabled={valuation.action_statuses.invoice !== true}>
          <PlusOutlined />
          {' Create Invoice'}
        </Item>

        <Item key="CREATE_APPLICATION" disabled={valuation.action_statuses.invoice !== true}>
          <PlusOutlined />
          {' Create Application'}
        </Item>

        <Divider />

        {(valuation.active_invoices.length > 0 && valuation.active_invoices[0].type !== 'APPLICATION') && ([

          <Item key="VIEW_INVOICE">
            <EyeOutlined />
            {' View Invoice'}
          </Item>,

          <Item key="UNDO_INVOICE">
            <UndoOutlined />
            {' Undo Invoice'}
          </Item>,

          <Divider key="DIVIDER" />,

        ])}

        {(valuation.active_invoices.length > 0 && valuation.active_invoices[0].type === 'APPLICATION') && ([

          <Item key="VIEW_APPLICATION">
            <EyeOutlined />
            {' View Application'}
          </Item>,

          <Item key="UNDO_APPLICATION">
            <UndoOutlined />
            {' Undo Application'}
          </Item>,

          <Divider key="DIVIDER" />

        ])}

        <Item key="DELETE_FILE" disabled={!valuation.payment_certificate_storage_path}>
          <DeleteOutlined />
          {' Delete Payment Certificate'}
        </Item>

        <Item key="DELETE" disabled={valuation.action_statuses.delete !== true}>
          <DeleteOutlined />
          {' Delete Valuation'}
        </Item>

      </Menu>
      
    );

    const clientActionMenu = (
      <Menu selectedKeys={[valuation.status_key.toString()]} onClick={({key}) => onChangeValuationField('status_key', key)}>
        <Item key='2'>
          <RocketFilled style={{ fontSize: 15, color: '#179ded'}} />
          {` Valuation in Progress`}
        </Item>
        
        <Item key='3'>
          <CloseCircleFilled style={{ fontSize: 15, color: 'grey' }} />
          {` Valuation Cancelled`}
        </Item>
      </Menu>
    )

    let finalAccountStatus = null;
    let finalAccountColor = null;

    if (valuation.final === 1) {
      if (!valuation.final_confirmed_at) {
        finalAccountStatus = 'Draft Final';
        finalAccountColor = '#179ded'
      } else if (valuation.final_confirmed_at) {
        finalAccountStatus = 'Final Account';
        finalAccountColor = '#32bd6a';
      }
    }

    
    return (
      <div className={styles.stack}>

        <h1 className={styles.heading}>

          {`Valuation ${valuation.number} ${valuation.reference_suffix ? `/ ${valuation.reference_suffix} ` : '' }`}

          {!!finalAccountStatus && (
            <span className={styles.statusTitle} style={{ backgroundColor: finalAccountColor }}>
              { finalAccountStatus }
            </span>
          )}

        </h1>

        <NotesPopover
          notes={valuation.notes}
          onChangeNotes={onChangeNotes}
        />

        <div style={{ width: 16 }} />
        <ButtonGroup>

          {valuation.status_key === 1 && (
            <React.Fragment>
              <Button disabled>
                Draft Mode
              </Button>

              <Button onClick={() => onChangeValuationField('status_key', 2)}>
                <MailFilled />
                {' Mark as Sent'}
              </Button>
            </React.Fragment>
          )}

          {(([1,4,5]).includes(valuation.status_key) === false) && (
            <React.Fragment>
              <Button onClick={() => onChangeValuationField('status_key', 1)}>
                <EditFilled />
                {' Make Draft'}
              </Button>
              
              <Dropdown overlay={clientActionMenu} trigger={['click']}>

                <Button style={{ color: valuation.status_key === 2 ? '#179ded' : 'grey'}}>

                  <LegacyIcon theme="filled" type={valuation.status_key === 2 ? 'rocket' : 'close-circle'} style={{ fontSize: 15}} />
                  { valuation.status_key === 2 ? 'Valuation in Progress' : 'Valuation Cancelled' }
                  <CaretDownOutlined />

                </Button>
              </Dropdown>
            </React.Fragment>
          )}

          {valuation.status_key === 4 && (
            <Button style={{ color: '#32bd6a'}} onClick={() => onClickActionMenuItem('VIEW_INVOICE')}>
              <CheckCircleFilled />
              {' Invoiced'}
            </Button>
          )}

          {valuation.status_key === 5 && (
            <Button style={{ color: '#32bd6a'}} onClick={() => onClickActionMenuItem('VIEW_APPLICATION')}>
              <CheckCircleFilled />
              {' Application Raised'}
            </Button>
          )}

        </ButtonGroup>

        <Dropdown overlay={actionMenu} trigger={['click']}>
          <Button type="primary" style={{ marginLeft: 16}}>
            {'Actions '}
            <CaretDownOutlined />
          </Button>
        </Dropdown>

      </div>
    );
  }
}