export default (values) => {

  const errors = {};
 

  if (values.retentionItems && values.retentionItems.length !== 0) {
    const retentionItemsErrors = [];

    values.retentionItems.forEach((field, index) => {
      
      const fieldError = {}

      if(!field.due_date) {

        fieldError.due_date = true;
        retentionItemsErrors[index] = fieldError;

      }

      if(!field.percentage) {

        fieldError.percentage = true;
        retentionItemsErrors[index] = fieldError;

      }

    });

    if (retentionItemsErrors.length > 0) {
      errors.retentionItems = retentionItemsErrors;
    }

  }

  return errors;
  
}