// @flow
import * as React from 'react';
import { Modal } from 'antd';
import Form from './Form';

type Props = {
  globalContacts: [],
  submitting: boolean,  
  componentWillMount: (props: any) => void,
  onClickCancel: () => void,
  onClickSubmit: () => void
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      globalContacts,
      submitting,
      onClickCancel,
      onClickSubmit
    } = this.props;

    return (
        
      <Modal
        title="Complete Project"
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText='Complete Project'
        width={650}
      >
        
        <Form globalContacts={globalContacts} />

      </Modal>
    )
  }
}