import { push } from 'connected-react-router';
import { clearSearchTerm } from '../State/searchTerm';

export default (activeTab) => async (dispatch, getState) => {

  const { pathname } = getState().router.location;

  dispatch(push(`${pathname}?tab=${activeTab}`));

  dispatch(clearSearchTerm());

}