import create from '../Services/create';
import update from '../Services/update';
import { getProps } from '../props';

export default async (values, dispatch) => {
  
  const { onClose, didCreate, didUpdate } = getProps();
    
  if (!values.id) {

    const client = await dispatch(create(values));
    
    onClose();

    if (client && didCreate) didCreate(client);

  }  else {
    
    const client = await dispatch(update(values));
    
    onClose();
    
    if (client && didUpdate) didUpdate(client)

  }
  
}