// @flow

import * as React from 'react';
import numeral from 'numeral';
import classnames from 'classnames';
import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import styles from './Component.css';

import type { OrderTotals } from '../../types';

type Props = {
  orderTotals: OrderTotals,
  order: any,
  editable: boolean,
  selectedOrderItemIds: [],
  onClickEdit: () => void,
  onClickCancel: () => void,
  onClickSave: () => void,
  onClickDeleteItems: () => void,
  onClickSubOrder: () => void,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      orderTotals,
      order,
      editable,
      selectedOrderItemIds,
      onClickEdit,
      onClickCancel,
      onClickSave,
      onClickDeleteItems,
      onClickSubOrder,
    } = this.props;

    return (
      <div className={styles.wrapper}>

        <div className={styles.buttons}>

          {!editable && (
            
            <Button
              className={styles.editButton}
              onClick={onClickEdit}
              disabled={order.action_statuses.handle_items !== true}
            >
              <EditOutlined />
              {'Edit Order'}
            </Button>

          )}

          {editable && (

            <React.Fragment>

              <Button
                className={styles.saveButton}
                onClick={onClickSave}
                type="primary"
              >
                {'Save Changes'}
              </Button>

              <div style={{ width: 8 }} />

              <Popconfirm
                title="Cancel your changes?"
                onConfirm={onClickCancel}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className={styles.cancelButton}
                  type="dashed"
                >
                  {'Cancel'}
                </Button>
              </Popconfirm>

            </React.Fragment>

          )}

          {(editable && selectedOrderItemIds.length > 0) && (
            <Button
              className={styles.deleteSelectedButton}
              type="danger"
              style={{ marginLeft: 20 }}
              onClick={onClickDeleteItems}
            >
              {`Delete Items (${selectedOrderItemIds.length})`}
            </Button>
          )}

          {(!editable && selectedOrderItemIds.length > 0) && (
            <Button
              type='link'
              onClick={onClickSubOrder}
            >
              <ArrowRightOutlined />
              {`Subcontractor Order (${selectedOrderItemIds.length})`}
            </Button>
          )}

        </div>

        <div className={styles.totals}>

          <div className={styles.total}>
            {`Subtotal: £${numeral(orderTotals.subtotal).format('0,0.00')}`}
          </div>

          <div className={styles.total}>
            {`Discount: £${numeral(orderTotals.discount).format('0,0.00')}`}
          </div>

          <div className={styles.total}>
            {`Total: £${numeral(orderTotals.total).format('0,0.00')}`}
          </div>

          <div className={classnames(styles.total, styles.target, { [styles.onTarget]: orderTotals.onTarget })}>
            {`Target: £${numeral(orderTotals.target).format('0,0.00')}`}
          </div>

        </div>

      </div>
    );
  }
}