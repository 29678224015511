import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Popover, DatePicker } from 'antd'

class FollowUpPopover extends React.PureComponent {

  state = {
    visible: false,
    followUpDate: null,
  }

  onVisibleChange = (visible) => {

    const {
      estimate,
      onChangeEstimateField,
    } = this.props

    const {
      followUpDate,
    } = this.state

    if (visible) {
      
      this.setState({
        visible,
        followUpDate: estimate.follow_up_date ? moment(estimate.follow_up_date) : null,
      })
      
    } else {
      
      this.setState({ visible })
      
      if (
        (followUpDate && followUpDate.format('YYYY-MM-DD')) !== estimate.follow_up_date
      ) {
        
        onChangeEstimateField(estimate.id, 'follow_up_date', followUpDate ? followUpDate.format('YYYY-MM-DD') : null)
        
      }
      
      setTimeout(() => {
        this.setState({
          visible,
          followUpDate: null,
        })
      }, 300)
      
    }
  }

  render () {

    const {
      estimate,
    } = this.props

    const {
      visible,
      followUpDate,
    } = this.state
  
    return (
  
      <Popover
        title="Follow up"
        trigger="click"
        visible={visible}
        onVisibleChange={this.onVisibleChange}
        content={(
          <div>

            <DatePicker
              style={{ width: 250 }}
              allowClear
              placeholder="Date"
              format="DD/MM/YYYY"
              value={followUpDate || undefined}
              onChange={value => this.setState({ followUpDate: value || null})}
            />

          </div>
        )}
      >
        <div style={{ cursor: 'pointer' }}>
          <div>
            {estimate.follow_up_date ? moment(estimate.follow_up_date).format('DD MMM YYYY') : '--'}
          </div>
        </div>
      </Popover>
  
    )
  }
}

FollowUpPopover.propTypes = {
  estimate: PropTypes.object.isRequired,
  onChangeEstimateField: PropTypes.func.isRequired,
}

export default FollowUpPopover