import { combineReducers } from 'redux'

import FileManager from './FileManager/reducer'
import TimesheetsDrawer from './TimesheetsDrawer/reducer'
import ValuationServicesPopover from './ValuationServicesPopover/reducer'

export default combineReducers({
  FileManager,
  TimesheetsDrawer,
  ValuationServicesPopover,
})
