// @flow

import { message } from 'antd'
import { pick } from 'lodash';
import { updateValuationItems } from '../../../HttpRequests/valuations';
import { setEditable } from '../State/editable';
import fetchValuation from './fetchValuation';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch: Function, getState: Function) => {
  
  try {
    
    const { valuation } = getState().screens.ValuationView;

    const { id } = valuation;

    const valuationItems = [];

    valuation.orders.forEach(order => order.order_groups.forEach(orderGroup => orderGroup.order_items.forEach(orderItem => {
      valuationItems.push(pick(orderItem.valuation_item, [
        'id',
        'complete_percentage',
        'complete_total'
      ]))
    })))

    await updateValuationItems({ id, data: valuationItems });

    dispatch(setEditable(false));

    message.success("Valuation Updated");
  
    await dispatch(fetchValuation());

  } catch (error) {

    handleException(error);

    throw error;
    
  }

}