import { createSelector } from 'reselect'

const selectSubProjects = state => state.screens.SubProject.Overview.subProjects

export default createSelector(
  selectSubProjects,
  (subProjects) => {

    const prTotal = subProjects.reduce((carry, row) => carry + row.pr_total, 0)
    const prComplete = subProjects.reduce((carry, row) => carry + row.pr_complete, 0)

    const subTotal = subProjects.reduce((carry, row) => carry + row.sub_total, 0)
    const subComplete = subProjects.reduce((carry, row) => carry + row.sub_complete, 0)

    return {
      quantity: subProjects.length,

      pr_total: prTotal,
      pr_complete: prComplete,
      pr_complete_perc: prTotal !== 0 ? (prComplete / prTotal) * 100 : 0,
      pr_remaining: prTotal - prComplete,

      sub_total: subTotal,
      sub_complete: subComplete,
      sub_complete_perc: subTotal !== 0 ? (subComplete / subTotal) * 100 : 0,
      sub_remaining: subTotal - subComplete,
    }

  },
)
