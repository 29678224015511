import axios from './axios';

export const getEstimates = ({ params } = {}) => axios.get(`estimates`, { params });
export const getEstimate = ({id, params }) => axios.get(`estimates/${id}`, { params });
export const createEstimate = ({ data }) => axios.post('estimates', data);
export const updateEstimate = ({ id, data }) => axios.put(`estimates/${id}`, data);
export const deleteEstimate = ({ id }) => axios.delete(`estimates/${id}`);
export const reviseEstimate = ({ id }) => axios.post(`estimates/${id}/revise`);
export const duplicateEstimate = ({ id, data }) => axios.post(`estimates/${id}/duplicate`, data);
export const emailEstimate = ({ id, data }) => axios.post(`estimates/${id}/send_email`, data);
export const updateEstimateItems = ({ id, data }) => axios.put(`estimates/${id}/items`, data);
export const updateEstimateOptionals = ({ id, data }) => axios.put(`estimates/${id}/optionals`, data);
export const updateEstimateCaveats = ({ id, data }) => axios.put(`estimates/${id}/caveats`, data);
export const uploadEstimateFile = ({ id, data } = {}) => axios.post(`/estimates/${id}/media`, data);
export const getEstimateTotals = ({ params } = {}) => axios.get(`estimates/totals`, { params });
