
import { createAction, handleActions } from 'redux-actions';

const setShowClientSiteModal = createAction("MODALS/CLIENT/SET_SHOW_CLIENT_SITE_MODAL");

const defaultState = false;

const reducer = handleActions(
  {
    [setShowClientSiteModal]: (state, action) => action.payload
  },
  defaultState
);

export { setShowClientSiteModal, reducer };