import React from 'react'
import PropTypes from 'prop-types'

const styles = {
  slot: {
    position: 'relative',
    bottom: 1,
    fontWeight: 500,
    marginLeft: 3,
  },
  priceWork: {
    padding: 2,
    borderRadius: 3,
  },
}

const JobSlots = ({ jobs, fontSize }) => (

  <React.Fragment>
    {jobs.map(job => {
      const style = {
        ...styles.slot,
        ...(job.price_work && styles.priceWork),
        fontSize,
      }
      if (job.price_work) return (
        <span key={job.id} style={{ ...style, backgroundColor: '#cfb000', color: 'white' }}>PW</span>
      )
      return (
        <React.Fragment key={job.id}>
          {job.slot === 'Day' && <span style={{ ...style, color: '#9e9e9e' }}>DAY</span>}
          {job.slot === 'AM' && <span style={{ ...style, color: '#3eaaff' }}>AM</span>}
          {job.slot === 'PM' && <span style={{ ...style, color: '#1e84d5' }}>PM</span>}
          {job.slot === 'Night' && <span style={{ ...style, color: '#7853b2' }}>NIGHT</span>}
        </React.Fragment>
      )
    })}
  </React.Fragment>
  
)

JobSlots.propTypes = {
  jobs: PropTypes.array.isRequired,
  fontSize: PropTypes.number,
}

JobSlots.defaultProps = {
  fontSize: 8,
}

export default JobSlots
