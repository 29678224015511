// @flow

import { message } from 'antd'
import { updateEstimateItems, updateEstimateOptionals, updateEstimateCaveats } from '../../../HttpRequests/estimates';
import { setEditable } from '../State/editable';
import { setSelectedEstimateItemIds } from '../State/selectedEstimateItemIds';
import fetchEstimate from './fetchEstimate';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch: Function, getState: Function) => {
  
  try {
    
    const { estimate } = getState().screens.EstimateView;

    const { id } = estimate;

    await updateEstimateItems({ id, data: estimate.estimate_groups });

    await updateEstimateOptionals({ id, data: estimate.estimate_optionals });

    await updateEstimateCaveats({ id, data: estimate.estimate_caveats });

    dispatch(setEditable(false));

    dispatch(setSelectedEstimateItemIds([]));

    message.success("Estimate Updated");
  
    await dispatch(fetchEstimate());

  } catch (error) {

    handleException(error);

    throw error;
    
  }

}