import { closeTimesheetModal } from '../State/timesheetModal'
import fetch from '../Services/fetch'

export default (timesheet) => (dispatch) => {

  dispatch(closeTimesheetModal())

  if (timesheet) {
    dispatch(fetch())
  }

}
