import { setProps } from '../props';
import initialize from '../Services/initialize';


export default (props: any) => async (dispatch) => {

  setProps(props);
  
  dispatch(initialize());
  
}