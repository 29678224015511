import { resetLayout } from '../State/layout'
import { resetRootDirectories } from '../State/rootDirectories'
import { resetCurrentDirectoryPath } from '../State/currentDirectoryPath'
import { resetCurrentDirectoryContents } from '../State/currentDirectoryContents'
import { resetSelectedItemPath } from '../State/selectedItemPath'
import { resetSelectedItemType } from '../State/selectedItemType'
import { resetCheckedItemPaths } from '../State/checkedItemPaths'

export default () => (dispatch) => {
  
  dispatch(resetLayout())
  dispatch(resetRootDirectories())
  dispatch(resetCurrentDirectoryPath())
  dispatch(resetCurrentDirectoryContents())
  dispatch(resetSelectedItemPath())
  dispatch(resetSelectedItemType())
  dispatch(resetCheckedItemPaths())

}
