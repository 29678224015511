import { createAction, handleActions } from 'redux-actions'

const setSelectedItemPath = createAction('MODULES/FILE_MANAGER/SET_SELECTED_ITEM_PATH')
const resetSelectedItemPath = createAction('MODULES/FILE_MANAGER/RESET_SELECTED_ITEM_PATH')

const defaultState = null

const reducer = handleActions(
  {
    [setSelectedItemPath]: (state, action) => action.payload,
    [resetSelectedItemPath]: () => defaultState,
  },
  defaultState,
)

export { setSelectedItemPath, resetSelectedItemPath, reducer }
