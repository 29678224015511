import React from 'react'
import PropTypes from 'prop-types'
import { Popover, Spin } from 'antd'
import numeral from 'numeral'
import styles from './styles.css'

const ValuationServicesPopover = props => {

  const {
    valuationId,
    serviceTypes,
    data,
    isFetching,
    children,
    onVisibleChange,
  } = props

  return (

    <Popover
      title='Services'
      onVisibleChange={visible => onVisibleChange(visible, valuationId)}
      content={(
        <div className={styles.serviceTypesWrapper}>
          {!serviceTypes.length || !data || isFetching ? <Spin /> : (
            <React.Fragment>
              {serviceTypes.map(serviceType => {
                const total = data.valuation_items.reduce((carry, valuationItem) => {
                  const orderItem = valuationItem.order_item
                  if (orderItem.type !== serviceType.key) return carry
                  if (orderItem.flag === 'By Others') return carry
                  if (orderItem.flag === 'Omit') return carry
                  return carry + Number(valuationItem.complete_total)
                }, 0)
                if (!total) return false
                return (
                  <div key={serviceType.key} className={styles.serviceTypeWrapper}>
                    <div
                      className={styles.serviceTypeIcon}
                      style={{ backgroundColor: serviceType.color_light, color: serviceType.color_dark }}
                    >
                      {serviceType.key}
                    </div>
                    <div>{`£${numeral(total).format('0,0.00')}`}</div>
                  </div>
                )
              })}
            </React.Fragment>
          )}
        </div>
      )}
    >
      {children}
    </Popover>

  )

}

ValuationServicesPopover.propTypes = {
  valuationId: PropTypes.number.isRequired,
  serviceTypes: PropTypes.array.isRequired,
  data: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onVisibleChange: PropTypes.func.isRequired,
}

ValuationServicesPopover.defaultProps = {
  data: null,
}

export default ValuationServicesPopover
