import { createAction, handleActions } from 'redux-actions';
import { assign } from 'lodash';


const loadData = createAction('SCREENS/DASHBOARD/YEAR_OVERVIEW_REPORT/LOAD_DATA');
const clearData = createAction('SCREENS/DASHBOARD/YEAR_OVERVIEW_REPORT/CLEAR_DATA');
const setMode = createAction('SCREENS/DASHBOARD/YEAR_OVERVIEW_REPORT/SET_MODE');

const defaultState = {
  data: null,
  mode: 'months'
}

const reducer = handleActions(
  {
    [loadData]: (state, action) => assign({}, state, { data: action.payload }),
    [clearData]: () => null,
    [setMode]: (state, action) => assign({}, state, { mode: action.payload })
  },
  defaultState
);

export { loadData, clearData, setMode, reducer };
