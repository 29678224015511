import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { currentDirectoryPath, selectedItemType, selectedItemPath, directoryActionType } = getProps();

  const initialValues = {};

  if (directoryActionType === 'edit') {

    const splitPath = selectedItemPath.split('/');
    const selected  = splitPath[splitPath.length - 1];
    
    initialValues.from = selected;

    if (selectedItemType === 'file') {
      
      initialValues.filename   = selected.split('.').slice(0, -1).join('.')

    }

    else {

      initialValues.folder = selected;

    }

  }

  initialValues.path = currentDirectoryPath;

  dispatch(initialize(FORM_NAME, initialValues));

}