import { getFormValues } from 'redux-form';
import { getProject } from '../../../HttpRequests/projects';
import { loadProject } from '../State/project';
import { FORM_NAME } from '../Form';
import alertIfClientIsHighRisk from './alertIfClientIsHighRisk';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const values = getFormValues(FORM_NAME)(getState());

  if (!values) return;
  
  try {

    const { project_id: id } = values;

    const params = {};
    
    params.include = [
      'client_site',
      'client_site.client',
      'client_site.client.client_contacts'
    ].join();

    const response = await getProject({ id, params });

    dispatch(loadProject(response.data.data));

    dispatch(alertIfClientIsHighRisk(response.data.data));

  } catch (error) {

    handleException(error);

  }
}