import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { SelectField, DateRangePickerField } from 'FormFields';

const labelColSpan = 4;
const wrapperColSpan = 17;

export default class extends React.PureComponent{

  static propTypes = {
    clients: PropTypes.array.isRequired
  }

  render () {

    const { clients } = this.props;

    const clientOptions = clients.map(client => ({ label: client.name, value: client.id }));
    
    return (
      
      <React.Fragment>

        <Field
          name="client_id"
          label="Client"          
          component={SelectField}
          options={clientOptions}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        <Field
          name="date_range"
          label="Date"
          component={DateRangePickerField}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          style={{ width: '100%'}}
          required
        />

      </React.Fragment>

    )
  }
}