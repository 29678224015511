import { message } from 'antd'
import { updateTimesheetPosting } from '../../../HttpRequests/timesheetPostings'

export default (values) => async () => {

  const { id } = values
    
  const data = {
    project_id: values.project_id,
    start_time: values.start_time,
    end_time: values.end_time,
  }

  const response = await updateTimesheetPosting({ id, data })

  const timesheetPosting = response.data

  message.success('Posting Updated')

  return timesheetPosting

}
