import { connect } from 'react-redux'
import List from './List'

import selectListItems from './Selectors/selectListItems'

const mapStateToProps = (state) => ({
  layout: state.modules.FileManager.layout,
  listItems: selectListItems(state),
  selectedItemPath: state.modules.FileManager.selectedItemPath,
  checkedItemPaths: state.modules.FileManager.checkedItemPaths,
})

const actionCreators = {
}

export default connect(mapStateToProps, actionCreators)(List)
