import immutable from 'immutable';
import { uploadOrderFile } from '../../../HttpRequests/orders';
import handleException from '../../../Core/Helpers/handleException';
import { loadOrder } from '../State/order';

export default (files: FileList) => async (dispatch, getState) => {

  try {
    const { id } = getState().screens.OrderView.order;
    
    const formdata = new FormData();
    formdata.append('file', files[0]);
    formdata.append('name', files[0].name);
    

    await uploadOrderFile({ id, data: formdata, config: {
    
      onUploadProgress: (progress) => {
        
        /* const uploaded =  */Math.floor((progress.loaded * 100) / progress.total);

      }
      
    }});

    let order = immutable.fromJS(getState().screens.OrderView.order);
    order = order.set('order_file_storage_path', files[0].name);

    order = order.toJS();

    dispatch(loadOrder(order));

  } catch(error) {

    handleException(error);

  }
}