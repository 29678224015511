import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import Header from './Components/Header'
import Projects from './Components/Projects'
import Summary from './Components/Summary'

const SubProjects = props => {

  const {
    mode,
    componentDidMount,
  } = props

  useEffect(() => {
    componentDidMount()
  }, [])

  return (
    
    <React.Fragment>

      <Header />

      {mode === 'projects' && <Projects />}

      {mode === 'summary' && <Summary />}

    </React.Fragment>

  )

}

SubProjects.propTypes = {
  mode: PropTypes.string.isRequired,
  componentDidMount: PropTypes.func.isRequired,
}

export default SubProjects
