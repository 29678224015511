import { change } from 'redux-form';
import fetchProject from '../Services/fetchProject';
import { FORM_NAME } from '../Form';

export default (clientContact) => async (dispatch) => {

  await dispatch(fetchProject());

  dispatch(change(FORM_NAME, 'client_contact_id', clientContact.id))
  
}