import React from 'react'
import PropTypes from 'prop-types'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Popover, Input, Button } from 'antd';

class NotesPopover extends React.PureComponent {

  state = {
    visible: false,
    editing: false,
    value: '',
  }

  componentDidMount () {
    this.setState({ value: this.props.notes || '' })
  }

  onVisibleChange = (visible) => {

    const {
      notes,
    } = this.props

    if (visible) {
      this.setState({
        visible,
        editing: !notes,
        value: notes || '',
      })
    } else {
      this.setState({
        visible,
        editing: false,
        value: ''
      })
    }
  }

  onEdit = () => {
    this.setState({ editing: true })
  }

  onSave = () => {
    this.props.onChange(this.state.value)
    this.setState({ visible: false })
  }

  render () {

    const {
      notes,
    } = this.props

    const {
      visible,
      editing,
      value,
    } = this.state
  
    return (
      <Popover
        visible={visible}
        title="Notes"
        trigger="click"
        onVisibleChange={this.onVisibleChange}
        content={(
          <div>

            {!editing && (
              <div style={{ width: 300 }}>
                <div style={{ marginBottom: 10 }}>{notes}</div>
                <a onClick={this.onEdit} role="presentation">
                  <EditOutlined />
                  {' Edit'}
                </a>
              </div>
            )}

            {editing && (
              <div>
                <Input.TextArea
                  value={value}
                  onChange={event => this.setState({ value: event.target.value })}
                  style={{ width: 400, height: 150, marginBottom: 10 }}
                />
                <br />
                <Button type="primary" size="small" onClick={this.onSave}>
                  Save
                </Button>
              </div>
            )}

          </div>
        )}
      >
        <InfoCircleOutlined
          style={{ color: notes ? '#1890FF' : '#CCC', fontSize: 18, cursor: 'pointer' }} />
      </Popover>
    );
  }
}

NotesPopover.propTypes = {
  notes: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

NotesPopover.defaultProps = {
  notes: null,
}

export default NotesPopover