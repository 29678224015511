import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.css'

const SubcontractorName = ({ subcontractor, onClickName }) => (
  <span
    role='presentation'
    onClick={e => {
      e.stopPropagation()
      onClickName(subcontractor.name)
    }}
    className={styles.name}
  >
    {subcontractor.name}
  </span>
)

SubcontractorName.propTypes = {
  subcontractor: PropTypes.object.isRequired,
  onClickName: PropTypes.func.isRequired,
}

export default SubcontractorName
