import { connect } from 'react-redux'
import { isSubmitting } from 'redux-form'
import SubOrder from './SubOrder'

import { FORM_NAME } from './Form'

import componentDidMount from './Handlers/componentDidMount'
import onClickSubmit from './Handlers/onClickSubmit'
import onClickAddToExisting from './Handlers/onClickAddToExisting'
import onCloseAddToExistingModal from './Handlers/onCloseAddToExistingModal'

const mapStateToProps = (state) => ({
  subcontractors: state.core.subcontractors,
  projects: state.core.projects,
  users: state.core.users,
  subOrders: state.core.subOrders,
  submitting: isSubmitting(FORM_NAME)(state),
  addToExistingModal: state.modals.SubOrder.addToExistingModal,
})

const actionCreators = {
  componentDidMount,
  onClickSubmit,
  onClickAddToExisting,
  onCloseAddToExistingModal,
}

export default connect(mapStateToProps, actionCreators)(SubOrder)
