import { connect } from 'react-redux';
import Component from './Component';

import onChangeTable from './Handlers/onChangeTable';
import onClickEdit from './Handlers/onClickEdit';

const mapStateToProps = (state) => ({
  itemDescriptions: state.screens.ItemDescriptions.itemDescriptions,
  pagination: state.screens.ItemDescriptions.pagination,
  fetching: state.screens.ItemDescriptions.fetching,
  serviceTypes: state.core.serviceTypes
});

const actionCreators = {
  onChangeTable,
  onClickEdit
};

export default connect(mapStateToProps, actionCreators)(Component);
