import { omit } from 'lodash';
import { getProjectTotals } from '../../../HttpRequests/projects';
import { loadProjectTotals } from '../State/projectTotals';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch, getState) => {

  const { searchTerm, filters } = getState().screens.Projects;

  try {

    const params = {};
    
    params.include = [
      'contracts_manager',
      'other_contracts_manager',
      'supervisor',
      'latest_valuations',
    ].join()

    if (searchTerm) {
      params.search = searchTerm;
    }

    if (filters.status_key !== 'all') {
      params['filter[status_key]'] = filters.status_key;
    }

    if (filters.phase_key.length > 0) {
      params['filter[phase_key]'] = filters.phase_key.join(',');
    }

    if (filters.client_id.length > 0) {
      params['filter[client_id]'] = filters.client_id.join(',');
    }

    if (filters.contracts_manager.length > 0) {
      params['filter[contracts_manager]'] = filters.contracts_manager.join(',');
    }

    if (filters.supervisor_id.length > 0) {
      params['filter[supervisor_id]'] = filters.supervisor_id.join(',');
    }

    if (filters.order_ack_sent !== undefined) {
      params['filter[order_ack_sent]'] = filters.order_ack_sent
    }

    if (filters.project_info_sent !== undefined) {
      params['filter[project_info_sent]'] = filters.project_info_sent
    }

    if (filters.no_valuation_this_month) {
      params['filter[no_valuation_this_month]'] = 1
    }

    const response = await getProjectTotals({ params: omit(params, ['page[size]', 'page[number]', 'csort']) });

    dispatch(loadProjectTotals(response.data.data));

  } catch (error) {

    handleException(error);

  }
}