exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1OTDaVLequ1QCMom8oW8oi {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n._28OAoB-FuC3VYZ-nx5OM7w {\n  flex: 1;\n  margin: 0px;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/EstimateView/Components/Header/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,YAAY;CACb","file":"Component.css","sourcesContent":[".stack {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.heading {\n  flex: 1;\n  margin: 0px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"stack": "_1OTDaVLequ1QCMom8oW8oi",
	"heading": "_28OAoB-FuC3VYZ-nx5OM7w"
};