import { getProps } from '../props';

export default async (values, dispatch, props) => {
  
  const { onClose } = getProps()

  const percentages = {};

  props.serviceTypes.forEach(serviceType => {

    const value = values[`type${serviceType.key}`]

    if (value !== null && value !== undefined) {
      percentages[serviceType.key] = value
    }

  })
  
  onClose(percentages);

}