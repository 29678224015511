import { connect } from 'react-redux'
import SubProjects from './SubProjects'

import componentDidMount from './Handlers/componentDidMount'

const mapStateToProps = (state) => ({
  mode: state.screens.Subbies.SubProjects.mode,
})

const actionCreators = {
  componentDidMount,
}

export default connect(mapStateToProps, actionCreators)(SubProjects)
