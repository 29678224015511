// @flow

import { fromJS } from 'immutable';
import { loadOrder } from '../State/order';

export type UpdateOrderGroupNameParams = {
  orderGroupIndex: number,
  name: string
}

export default (params: UpdateOrderGroupNameParams) => async (dispatch: Function, getState: Function) => {

  let order = fromJS(getState().screens.OrderView.order);

  order = order.setIn(['order_groups', params.orderGroupIndex, 'name'], params.name);

  order = order.toJS();

  dispatch(loadOrder(order));

}