import { message } from 'antd';
import { createDirectory } from '../../../HttpRequests/files';
import { getProps } from '../props';



export default (values) => async () => {


    const data = { 

      path: `${values.path}/${values.folder}`

    };
    
    const { projectId } = getProps();

    const folder = await createDirectory({ projectId, data });

    message.success("Directort Created");

    return folder;

}