import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'antd'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'

const ProjectSortMenu = props => (
  <Menu mode='vertical' onClick={({ key }) => props.onChangeSort(key)} style={{ width: 180 }}>
    <Menu.ItemGroup title='Sort By' />
    <Menu.Divider />
    <Menu.SubMenu key='id' title='No.'>
      <Menu.Item key='id|asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Asc</Menu.Item>
      <Menu.Item key='id|desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Desc</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu key='client_site.client' title='Client'>
      <Menu.Item key='client_site.client.name|asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Asc</Menu.Item>
      <Menu.Item key='client_site.client.name|desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Desc</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu key='contracts_manager' title='Manager'>
      <Menu.Item key='contracts_manager.full_name|asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Asc</Menu.Item>
      <Menu.Item key='contracts_manager.full_name|desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Desc</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu key='start_date' title='Start Date'>
      <Menu.Item key='start_date|asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Earliest</Menu.Item>
      <Menu.Item key='start_date|desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Latest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu key='last_valuation_date' title='Last Valuation Date'>
      <Menu.Item key='last_valuation_date|asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Earliest</Menu.Item>
      <Menu.Item key='last_valuation_date|desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Latest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu key='value_total' title='Value (£)'>
      <Menu.Item key='value_total|desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='value_total|asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu key='value_remaining' title='Remaining (£)'>
      <Menu.Item key='value_remaining|desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='value_remaining|asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu key='status' title='Status'>
      <Menu.Item key='status.title|asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Asc</Menu.Item>
      <Menu.Item key='status.title|desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Desc</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu key='phase' title='Phase'>
      <Menu.Item key='phase.title|asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Asc</Menu.Item>
      <Menu.Item key='phase.title|desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Desc</Menu.Item>
    </Menu.SubMenu>
  </Menu>
)

ProjectSortMenu.propTypes = {
  onChangeSort: PropTypes.func.isRequired,
}

export default ProjectSortMenu
