import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.css'

class SubOrderGroup extends React.PureComponent {

  static propTypes = {
    subOrderGroup: PropTypes.object.isRequired,
  }

  render() {

    const {
      subOrderGroup,
    } = this.props

    return (

      <div className={styles.wrapper}>

        <input
          value={subOrderGroup.name || ''}
          placeholder='Untitled Group'
          readOnly
          className={styles.name}
        />

        <div className={styles.headings}>

          <div className={styles.cell}>
            Qty
          </div>

          <div className={styles.cell}>
            Unit
          </div>

          <div className={styles.cell}>
            Rate
          </div>

          <div className={styles.cell}>
            Total
          </div>

          <div className={styles.cell}>
            Prev
          </div>

          <div className={styles.cell}>
            New
          </div>

          <div className={styles.cell}>
            Diff
          </div>

        </div>

      </div>

    )

  }

}

export default SubOrderGroup
