import { createAction, handleActions } from 'redux-actions';

const loadEstimateTenderStatuses = createAction("CORE/ESTIMATE_TENDER_STATUSES/LOAD_ESTIMATE_TENDER_STATUSES");
const clearEstimatesTenderStatuses = createAction("CORE/ESTIMATE_TENDER_STATUSES/CLEAR_ESTIMATE_TENDER_STATUSES");

const defaultState = []

const reducer = handleActions(
  {
    [loadEstimateTenderStatuses]: (state, action) => action.payload,
    [clearEstimatesTenderStatuses]: () => []
  },
  defaultState
);

export { loadEstimateTenderStatuses, clearEstimatesTenderStatuses, reducer };