import React from 'react';
import { DatePicker } from 'antd';

import ChartPanel from './ChartPanel';
import Chart from './Chart';

type Props = {
  config: Object,
  dateRange: Object,
  onChangeDateRange: Function
}

export default class extends React.PureComponent<Props> {
  
  render = () => (
      
    <ChartPanel
      title="Services Breakdown"
      renderTools={() => this.renderTools()}
      renderChart={() => this.renderChart()}
    />
      
  )

  renderTools = () => (
    <DatePicker.RangePicker value={this.props.dateRange} onChange={this.props.onChangeDateRange} />
  )

  renderChart = () => [
    <Chart key={0} config={this.props.config[0]} />,
    <Chart key={1} config={this.props.config[1]} />,
    <Chart key={2} config={this.props.config[2]} />
  ]

}
