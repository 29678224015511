import _props from '../props'
import { resetFiles } from '../State/files'
import { resetRemovedFileIds } from '../State/removedFileIds'
import { resetClientContacts } from '../State/clientContacts'

export default () => (dispatch) => {

  _props.clear()

  dispatch(resetFiles())
  dispatch(resetRemovedFileIds())
  dispatch(resetClientContacts())
  
}