import { createSelector } from 'reselect'
import { sumBy } from 'lodash'

const selectSubOrder = state => state.screens.SubOrder.subOrder

export default createSelector(
  selectSubOrder,
  (subOrder) => {

    const total = subOrder.sub_order_groups.reduce((carry, subOrderGroup) => {
      return carry + (sumBy(subOrderGroup.sub_order_items, 'total') || 0)
    }, 0)

    return { total }

  },
)
