import { connect } from 'react-redux'
import SubOrderItem from './SubOrderItem'

import onToggleItem from './Handlers/onToggleItem'
import onUpdateField from './Handlers/onUpdateField'
import onActionItem from './Handlers/onActionItem'

const mapStateToProps = (state) => ({
  serviceTypes: state.core.serviceTypes,
  editing: state.screens.SubOrder.editing,
  selectedItems: state.screens.SubOrder.selectedItems,
})

const actionCreators = {
  onToggleItem,
  onUpdateField,
  onActionItem,
}

export default connect(mapStateToProps, actionCreators)(SubOrderItem)
