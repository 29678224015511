import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.css'

export default class SubOrderPlaceholder extends React.PureComponent {

  static propTypes = {
    editing: PropTypes.bool.isRequired,
    onAddGroup: PropTypes.func.isRequired,
  }

  render() {

    const {
      editing,
      onAddGroup,
    } = this.props

    return (

      <div className={styles.wrapper}>

        <div className={styles.message}>

          {editing ? (

            <React.Fragment>

              {'This order is empty. '}

              <span>
                <span
                  role='presentation'
                  onClick={onAddGroup}
                  className={styles.addLink}
                >
                  {'Add a group'}
                </span>
                {' to get started.'}
              </span>

            </React.Fragment>

          ) : 'This order is empty.'}

        </div>

      </div>

    )

  }

}
