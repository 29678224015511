import { change } from 'redux-form'
import { FORM_NAME } from '../Form'
import { addClientContact } from '../State/clientContacts'

export default (clientContact) => async (dispatch) => {

  dispatch(addClientContact(clientContact))

  dispatch(change(FORM_NAME, 'client_contact_id', clientContact.id))
  
}
