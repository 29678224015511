import { createAction, handleActions } from 'redux-actions';

const setSelectedPaths = createAction("MODALS/EMAIL_VALUATION/SET_SELECTED_PATHS");
const resetSelectedPaths = createAction("MODALS/EMAIL_VALUATION/RESET_SELECTED_PATHS");

const defaultState = [];

const reducer = handleActions(
  {
    [setSelectedPaths]: (state, action) => action.payload,
    [resetSelectedPaths]: () => defaultState,
  },
  defaultState
);

export { setSelectedPaths, resetSelectedPaths, reducer };