import modalProps from '../modalProps'
import fetchSubcontractors from '../../../Core/Services/fetchSubcontractors'
import fetchUsers from '../../../Core/Services/fetchUsers'
import initialize from '../Services/initialize'

export default (props) => (dispatch) => {

  modalProps(props)

  dispatch(fetchSubcontractors())
  dispatch(fetchUsers())

  dispatch(initialize())

}
