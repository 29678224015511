// @flow

import { fromJS } from 'immutable';
import { arrayMove } from 'react-sortable-hoc';
import { clone } from 'lodash';
import selectCaveatsListData from '../Selectors/selectCaveatsListData';
import { loadEstimate } from '../State/estimate';

export type MoveEstimateCaveatParams = {
  oldIndex: number,
  newIndex: number
}

export default (params: MoveEstimateCaveatParams) => (dispatch: Function, getState: Function) => {


  // get immutable copy of the estimate
  let estimate = fromJS(getState().screens.EstimateView.estimate);

  // select the mapped list items
  let listData = selectCaveatsListData(getState());



  // dont't carry on if index hasnt moved
  if (params.oldIndex === params.newIndex) return;

  // don't allow it to be dropped at the beginning (before the first header)
  if (params.newIndex === 0) return;

  // don't allow it to be dropped at the end (after the last footer)
  if (params.newIndex === (listData.length-1)) return;
  


  // get the old estimateCaveat, estimateCaveatIndex
  const {
    estimateCaveat: oldEstimateCaveat,
    estimateCaveatIndex: oldEstimateCaveatIndex
  } = listData[params.oldIndex];

  // prepare the new estimateCaveat, estimateCaveatIndex

  listData = arrayMove(listData, params.oldIndex, params.newIndex);

  const listItemBefore = listData[params.newIndex - 1];

  if (listItemBefore.type === 'Footer') return; // don't allow it to be dropped between footer & header

  const newEstimateCaveatIndex = listItemBefore.estimateCaveat ? (listItemBefore.estimateCaveatIndex + 1) : 0;
  const newEstimateCaveat = clone(oldEstimateCaveat)



  // make the changes

  if (oldEstimateCaveatIndex < newEstimateCaveatIndex) {

    // insert the estimateCaveat in the new position
    estimate = estimate.updateIn(
      ['estimate_caveats'],
      (list) => list.insert(newEstimateCaveatIndex, newEstimateCaveat)
    );

    // remove the estimateCaveat from the old position
    estimate = estimate.removeIn(['estimate_caveats', oldEstimateCaveatIndex]);

  } else {

    // remove the estimateCaveat from the old position
    estimate = estimate.removeIn(['estimate_caveats', oldEstimateCaveatIndex]);

    // insert the estimateCaveat in the new position
    estimate = estimate.updateIn(
      ['estimate_caveats'],
      (list) => list.insert(newEstimateCaveatIndex, newEstimateCaveat)
    );

  }


  
  // save
  
  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}