// @flow

import { fromJS } from 'immutable';
import { loadOrder } from '../State/order';

export type RecalculateOrderItemParams = {
  orderGroupIndex: number,
  orderItemIndex: number
}

export default (params: RecalculateOrderItemParams) => (dispatch: Function, getState: Function) => {

  let order = fromJS(getState().screens.OrderView.order);

  let { quantity, rate } = order.getIn(['order_groups', params.orderGroupIndex, 'order_items', params.orderItemIndex]).toJS();
  const discountPercentage = order.get('discount_percentage');

  quantity = Number.isNaN(Number(quantity)) ? 0 :  Number(quantity);
  rate = Number.isNaN(Number(rate)) ? 0 :  Number(rate);

  let subtotal = quantity * rate;
  let discount = (subtotal / 100) * Number(discountPercentage);
  let total = subtotal - discount;

  subtotal = subtotal.toFixed(2);
  discount = discount.toFixed(2);
  total = total.toFixed(2);

  order = order.setIn(['order_groups', params.orderGroupIndex, 'order_items', params.orderItemIndex, 'subtotal'], subtotal);
  order = order.setIn(['order_groups', params.orderGroupIndex, 'order_items', params.orderItemIndex, 'discount'], discount);
  order = order.setIn(['order_groups', params.orderGroupIndex, 'order_items', params.orderItemIndex, 'total'], total);

  order = order.toJS();

  dispatch(loadOrder(order));

}