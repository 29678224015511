import { connect } from 'react-redux';
import Component from './Component';

import onClickEditClientSite from './Handlers/onClickEditClientSite'
import onClickEditClientContact from './Handlers/onClickEditClientContact'

const mapStateToProps = (state) => ({
  project: state.screens.ProjectView.project
})

const actionCreators = {
  onClickEditClientSite,
  onClickEditClientContact
};

export default connect(mapStateToProps, actionCreators)(Component);