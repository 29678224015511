import { message } from 'antd'
import { importEstimateItems } from '../../../HttpRequests/files'
import fetchEstimate from './fetchEstimate'
import handleException from '../../../Core/Helpers/handleException'

export default (fileProps) => async (dispatch, getState) => {

  const { estimate } = getState().screens.EstimateView

  const { file } = fileProps

  const data = new FormData()

  data.append('file', file)

  try {

    await importEstimateItems({ id: estimate.id, data })

    await dispatch(fetchEstimate())

    message.success('Data imported successfully')

  } catch (error) {

    handleException(error)

  }

}
