import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'

export default class extends React.PureComponent {

  static propTypes = {
    filters: PropTypes.object.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
  }

  render () {

    const {
      filters,
      onChangeFilter,
    } = this.props
    
    const typeOptions = [
      { title: 'All', key: 'All'},
      { title: 'Admin', key: 'Admin'},
      { title: 'Senior', key: 'Senior'},
      { title: 'Office', key: 'Office'},
      { title: 'Basic', key: 'Basic'},
      { title: 'Subcontractor', key: 'Subcontractor'},
    ]
    
    const statusOptions = [
      { title: 'All', key: 'All' },
      { title: 'Active', key: 'Active'},
      { title: 'Deactivated', key: 'Deactivated'},
    ]

    return (

      <div style={{ display: 'flex', marginBottom: 15 }}>
        
        <Radio.Group
          value={filters.type}
          onChange={(event) => onChangeFilter('type', event.target.value)}
        >

          {typeOptions.map(option => (
            <Radio.Button
              style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
              value={option.key}
              key={option.key}
            >
              {option.title}
            </Radio.Button>
          ))}

        </Radio.Group>

        <div style={{ width: 10 }} />
        
        <Radio.Group
          value={filters.status}
          onChange={(event) => onChangeFilter('status', event.target.value)}
        >

          {statusOptions.map(option => (
            <Radio.Button
              style={{ minWidth: 70, textAlign: 'center', fontSize: 12 }}
              value={option.key}
              key={option.key}
            >
              {option.title}
            </Radio.Button>
          ))}

        </Radio.Group>

      </div>

    )

  }

}
