import { getTopClientsReport } from '../../../HttpRequests/dashboard';
import handleException from '../../../Core/Helpers/handleException';

import { loadData } from '../State/topClientsReport';

export default () => async (dispatch) => {

  try {

    const response = await getTopClientsReport();

    dispatch(loadData(response.data.data));
    
  } catch(error) {
    handleException(error);

  }
}