import { createSelector } from 'reselect'

const selectData = state => state.screens.Subbies.SubProjects.data

export default createSelector(
  selectData,
  (data) => data.map(subProject => ({
    ...subProject,
    key: `${subProject.subcontractor.id}|${subProject.project.id}`,
  })),
)
