import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import Component from './Component';

import selectEmailOptions from './Selectors/selectEmailOptions';
import selectAllFiles from './Selectors/selectAllFiles';
import selectSelectedEmailToOption from './Selectors/selectSelectedEmailToOption';
import selectSelectedEmailCcOption from './Selectors/selectSelectedEmailCcOption';
import selectSelectedEmailBccOption from './Selectors/selectSelectedEmailBccOption';

import componentWillMount from './Handlers/componentWillMount';
import componentWillUnmount from './Handlers/componentWillUnmount';
import onClickCancel from './Handlers/onClickCancel';
import onClickSubmit from './Handlers/onClickSubmit';
import onSearchEmailOptions from './Handlers/onSearchEmailOptions';
import onSelectEmailOption from './Handlers/onSelectEmailOption';
import onAddFile from './Handlers/onAddFile';
import onRemoveFile from './Handlers/onRemoveFile';
import onChangeSelectedPaths from './Handlers/onChangeSelectedPaths';

import { FORM_NAME } from './Form';

const mapStateToProps = (state) => ({
  submitting: isSubmitting(FORM_NAME)(state),
  emailOptions: selectEmailOptions(state),
  fileList: state.modals.EmailValuation.fileList,
  allFiles: selectAllFiles(state),
  selectedPaths: state.modals.EmailValuation.selectedPaths,
  selectedEmailToOption: selectSelectedEmailToOption(state),
  selectedEmailCcOption: selectSelectedEmailCcOption(state),
  selectedEmailBccOption: selectSelectedEmailBccOption(state)
});

const actionCreators = {
  componentWillMount,
  componentWillUnmount,
  onClickCancel,
  onClickSubmit,
  onSearchEmailOptions,
  onSelectEmailOption,
  onAddFile,
  onRemoveFile,
  onChangeSelectedPaths,
};

export default connect(mapStateToProps, actionCreators)(Component);