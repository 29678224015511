import { updateTimesheet } from '../../../HttpRequests/timesheets'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { data, form } = getState().screens.TimesheetPosting

  const { timesheet_posting: { timesheet } } = data

  try {
    
    await updateTimesheet({
      id: timesheet.id,
      data: {
        notes: form.notes,
      },
    })

  } catch (error) {

    handleException(error)

  }

}
