import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'
import { UserDeleteOutlined } from '@ant-design/icons'

const CellUnavailable = ({ fontSize }) => (

  <Alert
    type='info'
    icon={<UserDeleteOutlined style={{ color: 'white' }} />}
    banner
    message={<span style={{ color: 'white' }}>Unavailable</span>}
    style={{ width: '100%', backgroundColor: '#3c3b3b', fontSize, }}
  />
  
)

CellUnavailable.propTypes = {
  fontSize: PropTypes.number,
}

CellUnavailable.defaultProps = {
  fontSize: 13,
}

export default CellUnavailable
