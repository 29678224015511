// @flow

import { message } from 'antd'
import { getProps } from '../props';
import { reviseValuation } from '../../../HttpRequests/valuations';
import switchValuation from './switchValuation';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch: Function) => {

  try {

    const { id } = getProps().match.params;

    const response = await reviseValuation({ id });

    const valuation = response.data.data;

    dispatch(switchValuation(valuation.id))

    message.success('Valuation Duplicated.');

  } catch (error) {

    handleException(error);

  }
}