// @flow

import * as React from 'react';
import styles from './Placeholder.css';

type Props = {
  editable: boolean,
  onClickAddEstimateOptional: () => void
}

class Component extends React.PureComponent<Props> {

  render () {

    const {
      editable,
      onClickAddEstimateOptional
    } = this.props;

    return (

      <div
        className={styles.wrapper}
      >

        <div className={styles.message}>

          {'There are no optionals on this estimate. '}

          {editable && (
            <span>
              <span
                className={styles.addLink}
                onClick={onClickAddEstimateOptional}
                role="presentation"
              >
                {'Add Optional'}
              </span>
              {' to get started.'}
            </span>
          )}

        </div>

      </div>

    )
  }
}

export default Component;