import { setProps } from '../props';
import initialize from '../Services/initialize';
import fetchClientContact from '../Services/fetchClientContact';
import fetchUsers from '../../../Core/Services/fetchUsers';

export default (props: any)  => async (dispatch) => {

  await dispatch(fetchClientContact());
  await dispatch(fetchUsers());
  
  setProps(props);

  dispatch(initialize());
  
}
