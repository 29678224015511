// @flow

import { fromJS } from 'immutable';
import { loadOrder } from '../State/order';

export type RemoveOrderGroupParams = {
  orderGroupIndex: number
}

export default (params: RemoveOrderGroupParams) => (dispatch: Function, getState: Function) => {

  let order = fromJS(getState().screens.OrderView.order);
  
  order = order.removeIn(['order_groups', params.orderGroupIndex]);

  order = order.toJS();
  
  dispatch(loadOrder(order));

}