import { connect } from 'react-redux'
import Toolbar from './Toolbar'

import onClickPathBreadcrumb from './Handlers/onClickPathBreadcrumb'
import onClickGrid from './Handlers/onClickGrid'
import onClickList from './Handlers/onClickList'
import onClickDelete from './Handlers/onClickDelete'
import onClickRename from './Handlers/onClickRename'
import onClickDownload from './Handlers/onClickDownload'
import onClickUpload from './Handlers/onClickUpload'
import onClickNewDirectory from './Handlers/onClickNewDirectory'

const mapStateToProps = (state) => ({
  layout: state.modules.FileManager.layout,
  currentDirectoryPath: state.modules.FileManager.currentDirectoryPath,
  selectedItemPath: state.modules.FileManager.selectedItemPath,
  selectedItemType: state.modules.FileManager.selectedItemType,
})

const actionCreators = {
  onClickPathBreadcrumb,
  onClickGrid,
  onClickList,
  onClickDelete,
  onClickRename,
  onClickDownload,
  onClickUpload,
  onClickNewDirectory,
}

export default connect(mapStateToProps, actionCreators)(Toolbar)
