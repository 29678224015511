// @flow
import * as React from 'react';
import { Modal, Button } from 'antd';
import Form from './Form';

type Props = {
  clientId: any,
  clientContact: any,
  submitting: boolean,
  componentWillMount: (props: any) => void,
  onClickCancel: () => void,
  onClickSubmit: () => void,
  onClickDelete: () => void,
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  render () {

    const {
      clientId,
      clientContact,
      submitting,
      onClickCancel,
      onClickSubmit,
      onClickDelete,
    } = this.props;

    const footer = []

    if (clientContact) footer.push(
      <Button key='delete' type='danger' onClick={onClickDelete} style={{ float: 'left' }}>
        Delete
      </Button>,
    )

    footer.push(
      <Button key='cancel' onClick={() => onClickCancel()}>
        Cancel
      </Button>,
      <Button key='save' type='primary' loading={submitting} onClick={onClickSubmit}>
        {clientContact ? 'Update' : 'Create'}
      </Button>,
    )

    return (
        
      <Modal
        title={`${ clientContact ? 'Update Contact': 'New Contact'}`}
        visible
        footer={footer}
        onCancel={onClickCancel}
      >
        
        <Form clientId={clientId} />

      </Modal>
    )
  }
}