import { combineReducers } from 'redux'

import { reducer as orders } from './State/orders'
import { reducer as fetching } from './State/fetching'
import { reducer as editable } from './State/editable'
import { reducer as selectedOrderGroupKeys } from './State/selectedOrderGroupKeys'
import { reducer as activeItemInputKeys } from './State/activeItemInputKeys'
import { reducer as showSetTargetCostsModal } from './State/showSetTargetCostsModal'

export default combineReducers({
  orders,
  fetching,
  editable,
  selectedOrderGroupKeys,
  activeItemInputKeys,
  showSetTargetCostsModal,
})
