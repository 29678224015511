// @flow

import * as React from 'react';
import { CloudDownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import styles from './Component.css';

type Props = {
  editable: boolean,
  onClickEdit: () => void,
  onClickCancel: () => void,
  onClickSave: () => void,
  onClickSetByService: () => void,
  onClickExport: () => void,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      editable,
      onClickEdit,
      onClickCancel,
      onClickSave,
      onClickSetByService,
      onClickExport,
    } = this.props;

    return (
      <div className={styles.wrapper}>

        <div className={styles.buttons}>

          {!editable && (

            <React.Fragment>
            
              <Button
                className={styles.editButton}
                onClick={onClickEdit}
              >
                <EditOutlined />
                {'Edit Targets'}
              </Button>

              <Button
                className={styles.exportButton}
                onClick={onClickExport}
                type="default"
                icon={<CloudDownloadOutlined />}
              >
                {'Export PDF'}
              </Button>
            
            </React.Fragment>

          )}

          {editable && (

            <React.Fragment>

              <Button
                className={styles.saveButton}
                onClick={onClickSave}
                type="primary"
              >
                {'Save Changes'}
              </Button>

              <div style={{ width: 8 }} />

              <Popconfirm
                title="Cancel your changes?"
                onConfirm={onClickCancel}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  className={styles.cancelButton}
                  type="dashed"
                >
                  {'Cancel'}
                </Button>
              </Popconfirm>

              <Button
                className={styles.setButton}
                onClick={onClickSetByService}
                type="dashed"
              >
                {'Set % by Service'}
              </Button>

            </React.Fragment>

          )}
            
        </div>

      </div>
    );
  }
}