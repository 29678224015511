import { cloneDeep, assign, find } from 'lodash';
import { loadEstimate } from '../State/estimate';

export default (ids) => (dispatch, getState) => {

  const { estimate } = getState().screens.EstimateView;

  const newEstimate = assign(cloneDeep(estimate), { estimate_groups: [] });
  
  newEstimate.estimate_groups = [];
  
  estimate.estimate_groups.forEach((estimateGroup, estimateGroupIndex) => {

    const estimateItems = estimateGroup.estimate_items.filter((estimateItem, estimateItemIndex) => !find(ids, { estimateGroupIndex, estimateItemIndex }));
    
    newEstimate.estimate_groups.push(assign({}, estimateGroup, {
      estimate_items: estimateItems
    }));

  });
  
  dispatch(loadEstimate(newEstimate));

}