import { createAction, handleActions } from 'redux-actions'

const setKeyDown = createAction('SCREENS/PLANNER/SET_KEY_DOWN')
const resetKeyDown = createAction('SCREENS/PLANNER/RESET_KEY_DOWN')

const defaultState = null

const reducer = handleActions(
  {
    [setKeyDown]: (state, action) => action.payload,
    [resetKeyDown]: () => defaultState,
  },
  defaultState,
)

export { setKeyDown, resetKeyDown, reducer }
