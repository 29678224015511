// @flow

import * as React from 'react';
import numeral from 'numeral';
import { Spin } from 'antd'

const styles = {

  footer: {
    paddingTop: 25,
    borderTop: '1px solid #DDD'
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },

  total: {
    marginRight: 40
  },

  label: {
    fontSize: 12,
    fontWeight: 500,
    color: '#999'
  },

  value: {
    fontSize: 15,
    fontWeight: 500,
    color: '#444'
  }

}

type Props = {
  estimateTotals: any,
  fetchingTotals: any,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      estimateTotals,
      fetchingTotals,
    } = this.props;

    return (

      <Spin spinning={fetchingTotals}>

        <div style={styles.footer}>

          <div style={styles.row}>

            <div style={styles.total}>
              <div style={styles.label}>
                Total Value
              </div>
              <div style={styles.value}>
                {`£${numeral(estimateTotals.total).format('0,0.00')}`}
              </div>
            </div>

          </div>

          <div style={styles.row}>

            {estimateTotals.statuses.map(status => (

              <div style={styles.total} key={status.title}>
                <div style={styles.label}>
                  {status.title}
                </div>
                <div style={styles.value}>
                  {`£${numeral(status.total).format('0,0.00')}`}
                </div>
              </div>

            ))}

          </div>
          
        </div>

      </Spin>

    )
  }
}