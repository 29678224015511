import { connect } from 'react-redux'
import Footer from './Footer'

import selectTotals from './Selectors/selectTotals'

import onClickEdit from './Handlers/onClickEdit'
import onClickSave from './Handlers/onClickSave'
import onClickCancel from './Handlers/onClickCancel'
import onClickDelete from './Handlers/onClickDelete'

const mapStateToProps = (state) => ({
  editing: state.screens.SubOrder.editing,
  selectedItems: state.screens.SubOrder.selectedItems,
  saving: state.screens.SubOrder.saving,
  totals: selectTotals(state),
})

const actionCreators = {
  onClickEdit,
  onClickSave,
  onClickCancel,
  onClickDelete,
}

export default connect(mapStateToProps, actionCreators)(Footer)
