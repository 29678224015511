import { fromJS } from 'immutable';
import { has } from 'lodash'
import updateTargetCostPercentage from './updateTargetCostPercentage';
import sanitiseTargetCostPercentage from './sanitiseTargetCostPercentage';

export default (percentages) => (dispatch, getState) => {

  const orders = fromJS(getState().screens.ProjectView.TargetCosts.orders);

  const { selectedOrderGroupKeys } = getState().screens.ProjectView.TargetCosts;

  orders.forEach((order, orderIndex) => {
    order.get('order_groups').forEach((orderGroup, orderGroupIndex) => {
      orderGroup.get('order_items').forEach((orderItem, orderItemIndex) => {

        if (selectedOrderGroupKeys) {
          if (orderIndex !== selectedOrderGroupKeys.orderIndex) return;
          if (orderGroupIndex !== selectedOrderGroupKeys.orderGroupIndex) return;
        }

        const { type, flag } = orderItem.toJS();

        if (type === null) return;

        if (flag === 'Omit' || flag === 'By Others') return;

        if (!has(percentages, type)) return;

        dispatch(updateTargetCostPercentage({
          orderIndex,
          orderGroupIndex,
          orderItemIndex,
          value: percentages[type],
        }))

        dispatch(sanitiseTargetCostPercentage({
          orderIndex,
          orderGroupIndex,
          orderItemIndex,
        }))

      })
    })
  })

}