import { createAction, handleActions } from 'redux-actions';

const setCurrentDirectoryPath = createAction("SCREENS/PROJECT_VIEW/SET_CURRENT_DIRECTORY_PATH");
const clearCurrentDirectoryPath = createAction("SCREENS/PROJECT_VIEW/CLEAR_CURRENT_DIRECTORY_PATH");

const defaultState = null;

const reducer = handleActions(
  {
    [setCurrentDirectoryPath]: (state, action) => action.payload,
    [clearCurrentDirectoryPath]: () => null
  },
  defaultState
);

export { setCurrentDirectoryPath, clearCurrentDirectoryPath, reducer };