import { get, trimStart } from 'lodash'

const getTeamUrl = (path, prefix = 'app') => {

  const teamUrl = `/${prefix}/teams/${get(window, 'state.currentTeam.slug')}`

  return `${teamUrl}/${trimStart(path, '/')}`

}

export default getTeamUrl
