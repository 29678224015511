import { setCurrentDirectoryPath } from '../../../State/currentDirectoryPath'
import { resetSelectedItemPath } from '../../../State/selectedItemPath'
import { resetSelectedItemType } from '../../../State/selectedItemType'
import { resetCheckedItemPaths } from '../../../State/checkedItemPaths'
import fetchDirectory from '../../../Services/fetchDirectory'

export default (index) => (dispatch, getState) => {

  const { currentDirectoryPath } = getState().modules.FileManager

  const newCurrentPath = currentDirectoryPath.split('/').slice(0, index + 1).join('/')

  dispatch(setCurrentDirectoryPath(newCurrentPath))

  dispatch(resetSelectedItemPath())

  dispatch(resetSelectedItemType())

  dispatch(resetCheckedItemPaths())

  dispatch(fetchDirectory())

}
