import { createAction, handleActions } from 'redux-actions';

const loadItemDescriptions = createAction('SCREENS/ITEM_DESCRIPTIONS/LOAD_ITEM_DESCRIPTIONS');

const defaultState = [];

const reducer = handleActions(
  {
    [loadItemDescriptions]: (state, action) => action.payload,
  },
  defaultState
);

export { loadItemDescriptions, reducer };