import moment from 'moment';
import { getTeamUrl } from 'Common';

export default (options) => async (dispatch, getState) => {

  const type = options.key;

  const { searchTerm, filters, sorting } = getState().screens.Estimates;

  const params = {};

  if (sorting.columnKey) {
    params.csort = (sorting.order === 'descend' ? '-' : '') + sorting.columnKey;
  }
  
  if (searchTerm) {
    params.search = searchTerm;
  }
  
  if (filters.smart_status !== 'all') {
    params['filter[smart_status]'] = filters.smart_status;
  }
    
  if (filters.extra_works_status === 'new') {
    params['filter[extra_works]'] = 0;
  } else if (filters.extra_works_status === 'extra') {
    params['filter[extra_works]'] = 1;
  }

  if (filters.priority) {
    params['filter[priority]'] = filters.priority;
  }

  if (filters.assigned === 'assigned') {
    params['filter[assigned]'] = true;
  } else if (filters.assigned === 'unassigned') {
    params['filter[unassigned]'] = true;
  } else {
    params['filter[assigned_to_user_id]'] = filters.assigned;
  }

  if (filters.user_id === 'assigned') {
    params['filter[has_owner]'] = true;
  } else if (filters.user_id === 'unassigned') {
    params['filter[doesnt_have_owner]'] = true;
  } else {
    params['filter[user_id]'] = filters.user_id;
  }
  
  if (filters.client_id.length > 0) {
    params['filter[client_id]'] = filters.client_id.join(',');
  }
    
  if (filters.status_key.length > 0) {
    params['filter[status_key]'] = filters.status_key.join(',');
  }
    
  if (filters.latest_action_key.length > 0) {
    params['filter[latest_action_key]'] = filters.latest_action_key.join(',');
  }

  if (filters.decision_maker_id) {
    params['filter[decision_maker_id]'] = filters.decision_maker_id;
  }
  
  if (Array.isArray(filters.date_between) && filters.date_between.length === 2)  {
    params['filter[date_between]'] = filters.date_between.map(date => moment(date).format('YYYY-MM-DD')).join();
  }

  if (filters.latest_only) {
    params['filter[latest_only]'] = true;
  }

  let url = `estimates/export?type=${type}`;

  Object.keys(params).forEach(key => {
    if (params[key]) url += `&${key}=${params[key]}`
  })

  window.location = getTeamUrl(url, 'exports');

}