import { combineReducers } from 'redux';

import { reducer as project } from './State/project';
import { reducer as showProjectModal } from './State/showProjectModal';
import { reducer as showClientContactModal } from './State/showClientContactModal';
import { reducer as showSelectEstimateOptionalsModal } from './State/showSelectEstimateOptionalsModal';

export default combineReducers({
  project,
  showProjectModal,
  showClientContactModal,
  showSelectEstimateOptionalsModal
})
