import React from 'react'
import { Table, Dropdown, Menu } from 'antd'
import { ArrowDownOutlined, ArrowUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import numeral from 'numeral'
import styles from './EstimatesTable.css'

const fieldSortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 120 }}>
    <Menu.ItemGroup title='Sort' />
    <Menu.Divider />
    <Menu.Item key='field.asc'><ArrowUpOutlined style={{ marginRight: 5 }} />Ascending</Menu.Item>
    <Menu.Item key='field.desc'><ArrowDownOutlined style={{ marginRight: 5 }} />Descending</Menu.Item>
  </Menu>
)

const postingsSortMenu = (onChangeSort, field) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 140, float: 'right' }}>
    <Menu.ItemGroup title='Sort by' />
    <Menu.Divider />
    <Menu.SubMenu title='Postings'>
      <Menu.Item key='quantity.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='quantity.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    {field === 'subcontractor_id' && (
      <Menu.SubMenu title='Projects'>
        <Menu.Item key='projects.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='projects.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    )}
    {field === 'project_id' && (
      <Menu.SubMenu title='Subcontractors'>
        <Menu.Item key='subcontractors.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
        <Menu.Item key='subcontractors.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
      </Menu.SubMenu>
    )}
  </Menu>
)

const hoursSortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 120, float: 'right' }}>
    <Menu.ItemGroup title='Sort by' />
    <Menu.Divider />
    <Menu.SubMenu title='Hours'>
      <Menu.Item key='hours.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='hours.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Deduction'>
      <Menu.Item key='deduction.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='deduction.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Adjustments'>
      <Menu.Item key='adjustments.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='adjustments.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Total'>
      <Menu.Item key='hours_worked.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='hours_worked.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
  </Menu>
)

const costSortMenu = (onChangeSort) => (
  <Menu mode='vertical' onClick={({ key }) => onChangeSort(key)} style={{ width: 120, float: 'right' }}>
    <Menu.ItemGroup title='Sort by' />
    <Menu.Divider />
    <Menu.SubMenu title='Cost'>
      <Menu.Item key='total_cost.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='total_cost.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
    <Menu.SubMenu title='Avg per hour'>
      <Menu.Item key='avg_hourly_rate.desc'><ArrowUpOutlined style={{ marginRight: 5 }} />Highest</Menu.Item>
      <Menu.Item key='avg_hourly_rate.asc'><ArrowDownOutlined style={{ marginRight: 5 }} />Lowest</Menu.Item>
    </Menu.SubMenu>
  </Menu>
)

const getSortByIcon = direction => <LegacyIcon type={`arrow-${direction}`} style={{ marginRight: 5, color: 'rgba(0, 0, 0, 0.65)' }} />

type Props = {
  setup: Object,
  sortBy: String,
  data: [],
  fetching: Boolean,
  isClickableRow: Boolean,
  hoursType: String,
  hoursFormat: String,
  onChangeSort: Function,
  onClickRow: Function,
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      setup,
      sortBy,
      data,
      fetching,
      isClickableRow,
      hoursType,
      hoursFormat,
      onChangeSort,
      onClickRow,
    } = this.props

    const columns = [
      {
        title: (
          <Dropdown overlay={fieldSortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <div>{setup.fields[setup.model][setup.field]}</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'field',
        width: 250,
        render: (text, record) => (
          <div>
            <div style={{ fontSize: 18, fontWeight: 500 }}>
              {sortBy === 'field.asc' && getSortByIcon('up')}
              {sortBy === 'field.desc' && getSortByIcon('down')}
              {record.field}
            </div>
            {record.h2 && <div style={{ fontSize: 16, fontWeight: 500 }}>{record.h2}</div>}
            {record.h3 && <div>{record.h3}</div>}
          </div>
        ),
      },
      {
        title: (
          <Dropdown overlay={postingsSortMenu(onChangeSort, setup.field)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}>
              <div>Postings</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'quantity',
        width: 200,
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div>
              {sortBy === 'quantity.asc' && getSortByIcon('down')}
              {sortBy === 'quantity.desc' && getSortByIcon('up')}
              <strong style={{ fontSize: '16px' }}>
                {record.quantity}
              </strong>
            </div>
            <div>&nbsp;</div>
            {setup.field === 'subcontractor_id' && (
              <div>
                {(sortBy === 'projects.asc') && getSortByIcon('down')}
                {(sortBy === 'projects.desc') && getSortByIcon('up')}
                Projects:
                <strong>
                  {` ${record.projects}`}
                </strong>
              </div>
            )}
            {setup.field === 'project_id' && (
              <div>
                {(sortBy === 'subcontractors.asc') && getSortByIcon('down')}
                {(sortBy === 'subcontractors.desc') && getSortByIcon('up')}
                Subcontractors:
                <strong>
                  {` ${record.subcontractors}`}
                </strong>
              </div>
            )}
            <div>&nbsp;</div>
          </div>
        )
      },
      {
        title: (
          <Dropdown overlay={hoursSortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}>
              <div>Hours</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'hours',
        width: 200,
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div>
              {sortBy === 'hours.asc' && getSortByIcon('down')}
              {sortBy === 'hours.desc' && getSortByIcon('up')}
              <strong style={{ fontSize: '16px' }}>
                {record[hoursFormat === 'time' ? 'hours_formatted' : 'hours']}
              </strong>
            </div>
            <div>&nbsp;</div>
            <div style={{ color: 'orange' }}>
              {(sortBy === 'deduction.asc') && getSortByIcon('down')}
              {(sortBy === 'deduction.desc') && getSortByIcon('up')}
              Deduction:
              <strong>
                {` ${record[hoursFormat === 'time' ? 'deduction_formatted' : 'deduction']}`}
              </strong>
            </div>
            <div style={{ color: '#9e9e9e', fontStyle: 'italic' }}>
              {(sortBy === 'adjustments.asc') && getSortByIcon('down')}
              {(sortBy === 'adjustments.desc') && getSortByIcon('up')}
              Adjustments:
              <strong>
                {` ${record[hoursFormat === 'time' ? 'adjustments_formatted' : 'adjustments']}`}
              </strong>
            </div>
            <div>
              {(sortBy === 'hours_worked.asc') && getSortByIcon('down')}
              {(sortBy === 'hours_worked.desc') && getSortByIcon('up')}
              Total:
              <strong>
                {` ${record[hoursFormat === 'time' ? 'hours_worked_formatted' : 'hours_worked']}`}
              </strong>
            </div>
          </div>
        )
      },
      {
        title: (
          <Dropdown overlay={costSortMenu(onChangeSort)} placement='bottomRight' trigger={['click']}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}>
              <div>Cost</div>
              <CaretDownOutlined style={{ marginLeft: 5 }} />
            </div>
          </Dropdown>
        ),
        key: 'cost',
        width: 200,
        className: styles.numericColumn,
        render: (text, record) => (
          <div>
            <div>
              {sortBy === 'total_cost.asc' && getSortByIcon('down')}
              {sortBy === 'total_cost.desc' && getSortByIcon('up')}
              <strong style={{ fontSize: '16px' }}>
                {`£${numeral(record.total_cost).format('0,0.00')}`}
              </strong>
            </div>
            {hoursType !== 'price-work' && (
              <React.Fragment>
                <div>&nbsp;</div>
                <div>
                  {(sortBy === 'deduction.asc') && getSortByIcon('down')}
                  {(sortBy === 'deduction.desc') && getSortByIcon('up')}
                  Avg per hour:
                  <strong>
                    {` £${numeral(record.avg_hourly_rate).format('0,0.00')}`}
                  </strong>
                </div>
              </React.Fragment>
            )}
          </div>
        )
      },
    ]
    
    return (

      <Table
        columns={columns}
        dataSource={data}
        rowKey='key'
        loading={fetching}
        onRow={record => ({
          onClick: () => onClickRow(record),
          style: { cursor: isClickableRow ? 'pointer' : 'default' }
        })}
      />

    )

  }

}
