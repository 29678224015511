import { Modal } from 'antd';
import uncompleteProject from '../Services/uncompleteProject';

const { confirm } = Modal;

export default () => async (dispatch) => {

  confirm({
    title: `Are you sure you want to uncomplete this Project?`,
    content: 'Note: If this project has retentions you will need to undo them first',
    okText: 'Yes, Uncomplete it',
    width: 550,
    onOk() {
      
      dispatch(uncompleteProject());
      
    }
  });

}