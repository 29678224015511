import { push } from 'connected-react-router'
import { getTeamUrl } from 'Common'
import { closeSubOrderModal } from '../State/subOrderModal'

export default (subOrder) => (dispatch) => {

  dispatch(closeSubOrderModal())

  if (!subOrder) return

  dispatch(push(getTeamUrl(`sub_orders/${subOrder.id}`)))

}
