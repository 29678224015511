import { connect } from 'react-redux'
import ProjectCell from './ProjectCell'

import onOmit from './Handlers/onOmit'
import onUnomit from './Handlers/onUnomit'

const mapStateToProps = () => ({
})

const actionCreators = {
  onOmit,
  onUnomit,
}

export default connect(mapStateToProps, actionCreators)(ProjectCell)
