import { combineReducers } from "redux"

import { reducer as files } from "./State/files"
import { reducer as removedFileIds } from "./State/removedFileIds"
import { reducer as clientContacts } from "./State/clientContacts"
import { reducer as clientContactModal } from "./State/clientContactModal"

export default combineReducers({
  files,
  removedFileIds,
  clientContacts,
  clientContactModal,
})
