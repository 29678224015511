import updateEstimateItemField from "../Services/updateEstimateItemField";

export default (description) => async (dispatch, getState) => {

  const { estimateGroupIndex, estimateItemIndex } = getState().screens.EstimateView.currentEstimateItem;

  dispatch(updateEstimateItemField({
    estimateGroupIndex,
    estimateItemIndex,
    field: 'description',
    value: description
  }));

}