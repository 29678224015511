// @flow

import { fromJS } from 'immutable';
import { loadOrder } from '../State/order';

export type UpdateOrderCaveatFieldParams = {
  orderCaveatIndex: number,
  field: string,
  value: string|null
}

export default (params: UpdateOrderCaveatFieldParams) => (dispatch: Function, getState: Function) => {

  let order = fromJS(getState().screens.OrderView.order);

  order = order.setIn(
    ['order_caveats', params.orderCaveatIndex, params.field],
    params.value
  )

  order = order.toJS();

  dispatch(loadOrder(order));

}