import { getSubValuations } from '../../../HttpRequests/subValuations'
import { setSubValuations } from '../State/subValuations'
import { startFetching, finishFetching } from '../State/fetching'
import { setPagination } from '../State/pagination'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch, getState) => {

  const { search, filters, sorting, pagination } = getState().screens.SubValuations

  const params = {
    search,
    sort: sorting.order ? `${sorting.order === 'descend' ? '-' : ''}${sorting.columnKey}` : '-date',
    include: [
      'subcontractor',
      'project.client_site.client',
      'user',
    ].join(','),
    'page[number]': pagination.pageNumber,
    'page[size]': pagination.pageSize,
  }

  if (filters.subcontractor_id.length) params['filter[subcontractor_id]'] = filters.subcontractor_id.join(',')
  if (filters.project_id.length) params['filter[project_id]'] = filters.project_id.join(',')
  if (filters.date_range.length) params['filter[date_range]'] = filters.date_range.map(date => date.format('YYYY-MM-DD')).join(',')
  if (filters.to_date_range.length) params['filter[to_date_range]'] = filters.to_date_range.map(date => date.format('YYYY-MM-DD')).join(',')
  if (filters.user_id.length) params['filter[user_id]'] = filters.user_id.join(',')
  if (filters.contracts_manager_id.length) params['filter[contracts_manager_id]'] = filters.contracts_manager_id.join(',')

  // Sorting issue with ID secondary sort as solution

  dispatch(startFetching())

  try {

    const response = await getSubValuations({ params })

    dispatch(setSubValuations(response.data.rows))

    dispatch(setPagination({
      pageNumber: response.data.pagination.current_page,
      pageSize: response.data.pagination.per_page,
      total: response.data.pagination.total,
    }))

  } catch (error) {

    handleException(error)

  } finally {

    dispatch(finishFetching())

  }

}
