import { connect } from 'react-redux'
import Table from './Table'

import onChangeTable from './Handlers/onChangeTable'
import onChangeStatus from './Handlers/onChangeStatus'
import onClickNotes from './Handlers/onClickNotes'

const mapStateToProps = (state) => ({
  subValuations: state.screens.Subbies.SubValuations.subValuations,
  fetching: state.screens.Subbies.SubValuations.fetching,
  pagination: state.screens.Subbies.SubValuations.pagination,
})

const actionCreators = {
  onChangeTable,
  onChangeStatus,
  onClickNotes,
}

export default connect(mapStateToProps, actionCreators)(Table)
