import { createAction, handleActions } from 'redux-actions'

const setSorting = createAction('SCREENS/SUBBIES/SUB_VALUATIONS/SET_SORTING')
const resetSorting = createAction('SCREENS/SUBBIES/SUB_VALUATIONS/RESET_SORTING')

const defaultState = {
  columnKey: null,
  order: null,
}

const reducer = handleActions(
  {
    [setSorting]: (state, { payload }) => payload,
    [resetSorting]: () => defaultState,
  },
  defaultState,
)

export { setSorting, resetSorting, reducer }
