import saveSubValuations from '../../../Services/saveSubValuations'
import { resetEditing } from '../../../State/editing'

export default () => async (dispatch) => {

  await dispatch(saveSubValuations())

  dispatch(resetEditing())

}
