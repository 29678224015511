import { connect } from 'react-redux'
import Header from './Header'

import selectIsFiltered from './Selectors/selectIsFiltered'

import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'
import onChangeFilter from './Handlers/onChangeFilter'
import onChangeMode from './Handlers/onChangeMode'
import onClickNewSubOrder from './Handlers/onClickNewSubOrder'
import onClickNewSubValuation from './Handlers/onClickNewSubValuation'

const mapStateToProps = (state) => ({
  projectStatuses: state.core.projectStatuses,
  subcontractors: state.core.subcontractors,
  projects: state.core.projects,
  users: state.core.users,
  search: state.screens.SubProjects.search,
  filters: state.screens.SubProjects.filters,
  isFiltered: selectIsFiltered(state),
  mode: state.screens.SubProjects.mode,
})

const actionCreators = {
  onChangeSearch,
  onSearch,
  onChangeFilter,
  onChangeMode,
  onClickNewSubOrder,
  onClickNewSubValuation,
}

export default connect(mapStateToProps, actionCreators)(Header)
