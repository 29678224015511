import React from 'react'
import PropTypes from 'prop-types'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio } from 'antd';
import styles from './Component.css'

export default class _Fields_ extends React.PureComponent {

  static propTypes = {
    submitting: PropTypes.bool,
    type: PropTypes.string,
    onChangeType: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitting: null,
    type: null,
  }

  render () {

    const {
      submitting,
      type,
      onChangeType,
    } = this.props

    return (
      
      <React.Fragment>

        <Form.Item
          label="Type"
          labelCol={{ span: 4 }}
        >

          <Radio.Group
            value={type}
            onChange={onChangeType}
            disabled={submitting}
          >

            <Radio className={styles.radio} value="invoice_single">
              <div className={styles.radioText}>
                <strong>Single Invoice</strong>
                <br />
                A single invoice for the entire valuation
              </div>
            </Radio>

            <Radio className={styles.radio} value="invoice_split">
              <div className={styles.radioText}>
                <strong>Split Invoice</strong>
                <br />
                Multiple invoices split by purchase order
              </div>
            </Radio>

          </Radio.Group>
        
        </Form.Item>

      </React.Fragment>

    )
  }
}