import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Select } from 'antd'

const AddToExistingModal = props => {

  const {
    subcontractors,
    projects,
    subOrders,
    projectId,
    onClose,
  } = props

  const [subcontractorId, setSubcontractorId] = useState(undefined)
  const [$projectId, setProjectId] = useState(projectId)
  const [subOrderId, setSubOrderId] = useState(undefined)
  const [submitted, setSubmitted] = useState(false)

  const submit = () => onClose(subOrderId)

  return (

    <Modal
      title='Add to Existing'
      visible
      okText='Add'
      onOk={() => {
        setSubmitted(true)
        if (subcontractorId && $projectId && subOrderId) {
          submit()
        }
      }}
      onCancel={() => onClose()}
      confirmLoading={false}
      maskClosable={false}
    >

      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
      >

        <Form.Item
          label='Subcontractor'
          validateStatus={(submitted && !subcontractorId && 'error') || undefined}
        >
          <Select
            value={subcontractorId}
            onChange={value => {
              setSubcontractorId(value)
              setSubOrderId(undefined)
            }}
            allowClear
            showSearch
            optionFilterProp='children'
          >
            {subcontractors.map(subcontractor => (
              <Select.Option key={subcontractor.id} value={subcontractor.id}>
                {subcontractor.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Project'
          validateStatus={(submitted && !projectId && 'error') || undefined}
        >
          <Select
            value={$projectId}
            onChange={value => {
              setProjectId(value)
              setSubOrderId(undefined)
            }}
            allowClear
            showSearch
            optionFilterProp='children'
          >
            {projects.map(project => (
              <Select.Option key={project.id} value={project.id}>
                {`[${project.id}] ${project.client_site.client.name} - ${project.client_site.name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Sub Order'
          validateStatus={(submitted && !subOrderId && 'error') || undefined}
        >
          <Select
            value={subOrderId}
            onChange={value => setSubOrderId(value)}
            allowClear
            showSearch
            optionFilterProp='children'
          >
            {subOrders.filter(subOrder => subOrder.subcontractor_id === subcontractorId && subOrder.project_id === projectId).map(subOrder => (
              <Select.Option key={subOrder.id} value={subOrder.id}>
                {subOrder.reference}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

      </Form>

    </Modal>

  )

}

AddToExistingModal.propTypes = {
  subcontractors: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  subOrders: PropTypes.array.isRequired,
  projectId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
}

AddToExistingModal.defaultProps = {
  projectId: null,
}

export default AddToExistingModal
