import { setPagination } from '../../../State/pagination'
import fetch from '../../../Services/fetch'
import fetchTotals from '../../../Services/fetchTotals'

export default () => (dispatch) => {

  dispatch(setPagination({ current: 1 }))

  dispatch(fetch())

  dispatch(fetchTotals())
  
}