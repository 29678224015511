import { setPagination } from '../State/pagination';
import { setSorting } from '../State/sorting';
import fetchClients from '../Services/fetchClients';

export default (pagination, filters, sorting) => async (dispatch) => {

  window.scrollTo(0, 0);

  dispatch(setPagination(pagination));

  dispatch(setSorting(sorting));
  
  await dispatch(fetchClients());

}