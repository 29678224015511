import { message } from 'antd'
import { deactivateUser } from '../../../HttpRequests/users'
import { getProps } from '../props'
import initialize from './initialize'
import handleException from '../../../Core/Helpers/handleException'

export default () => async (dispatch) => {

  const { id, didDeactivate } = getProps()

  try {

    await deactivateUser({ id })

    message.success('User Deactivated')

    dispatch(initialize())

    if (didDeactivate) didDeactivate()

  } catch (error) {

    handleException(error)

  }

}
