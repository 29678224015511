import { createSelector } from 'reselect'
import { get, orderBy } from 'lodash'
import numeral from 'numeral'
import moduleProps from '../../../moduleProps'

const selectData = state => state.modules.TimesheetsDrawer.data

export default createSelector(
  selectData,
  (data) => orderBy(data, ['date', 'hours_worked'], ['asc', 'desc']).map(timesheet => {
    const timesheetPosting = timesheet.timesheet_postings.length === 1 ? timesheet.timesheet_postings[0] : null
    const hoursFormat = get(moduleProps(), 'filters.hoursFormat', 'time')
    const hours = hoursFormat === 'time' ? timesheet.hours_formatted : numeral(timesheet.hours).format('0,0.00')
    const deduction = hoursFormat === 'time' ? timesheet.deduction_formatted : numeral(timesheet.deduction).format('0,0.00')
    const adjustments = hoursFormat === 'time' ? timesheet.adjustments_formatted : numeral(timesheet.adjustments).format('0,0.00')
    const hoursWorked = hoursFormat === 'time' ? timesheet.hours_worked_formatted : numeral(timesheet.hours_worked).format('0,0.00')
    return ({
      ...timesheet,
      key: `timesheet|${timesheet.id}`,
      project: get(timesheetPosting, 'project'),
      span: get(timesheetPosting, 'span'),
      hours,
      deduction,
      adjustments,
      hours_worked: hoursWorked,
      total_cost: `£${numeral(timesheet.total_cost).format('0,0.00')}`,
      children: timesheetPosting ? false : timesheet.timesheet_postings.map($timesheetPosting => {
        const $hours = hoursFormat === 'time' ? $timesheetPosting.hours_formatted : numeral($timesheetPosting.hours).format('0,0.00')
        const $deduction = hoursFormat === 'time' ? $timesheetPosting.deduction_formatted : numeral($timesheetPosting.deduction).format('0,0.00')
        const $adjustments = hoursFormat === 'time' ? $timesheetPosting.adjustments_formatted : numeral($timesheetPosting.adjustments).format('0,0.00')
        const $hoursWorked = hoursFormat === 'time' ? $timesheetPosting.hours_worked_formatted : numeral($timesheetPosting.hours_worked).format('0,0.00')
        return {
          ...$timesheetPosting,
          key: `timesheet-posting|${$timesheetPosting.id}`,
          hours: $hours,
          deduction: $deduction,
          adjustments: $adjustments,
          hours_worked: $hoursWorked,
          total_cost: $timesheetPosting.total_cost ? `£${numeral($timesheetPosting.total_cost).format('0,0.00')}` : null,
        }
      }),
    })
  }),
)
