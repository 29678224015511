exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._34EaQty3hvSvWxXzN0MPEM .ant-badge {\n  position: relative;\n  bottom: 1px;\n  margin-right: 5px;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Planner/Components/SubcontractorPopover/styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;CACnB","file":"styles.css","sourcesContent":[".bookedIn :global(.ant-badge) {\n  position: relative;\n  bottom: 1px;\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"bookedIn": "_34EaQty3hvSvWxXzN0MPEM"
};