import React from 'react';
import { Field } from 'redux-form';
import { InputField } from 'FormFields';

const labelColSpan = 4;
const wrapperColSpan = 16;

type Props = {
  submitting: boolean
}

export default class extends React.PureComponent<Props> {

  render () {

    const { submitting } = this.props;

    return (

      <div>

        <Field
          required
          component={InputField}
          name="name"
          label="Name"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        <Field
          component={InputField}
          name="address_line_1"
          placeholder="Address"
          label="Address"
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
        />

        <Field
          component={InputField}
          name="address_line_2"
          label=" "
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          colon={false}
        />


        <Field
          component={InputField}
          name="address_line_3"
          label=" "
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          colon={false}
        />


        <Field
          component={InputField}
          name="town_or_city"
          placeholder="Town / City"
          label=" "
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          colon={false}
        />

        <Field
          component={InputField}
          name="county"
          placeholder="County"
          label=" "
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          colon={false}
        />

        <Field
          component={InputField}
          name="postcode"
          placeholder="Postcode"
          label=" "
          disabled={submitting}
          autoComplete="off"
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          colon={false}
        />

      </div>

    )
  }
}