import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Popover, Avatar } from 'antd';
import { getActivityLogs } from '../../../HttpRequests/activityLogs'

class ActivityPopover extends React.PureComponent {
  
  state = {
    fetching: false,
    activityLogs: [],
  }
  
  onVisibleChange = async (visible) => {
    
    if (visible) {
      
      this.setState({ fetching: true, activityLogs: [] })
    
      const params = {
        'filter[subject_id]': this.props.estimate.id,
        'filter[subject_type]': 'App\\\\Models\\\\Estimate',
        'sort': '-created_at',
        'include': 'causer',
      }
      
      const response = await getActivityLogs({ params })
      
      this.setState({ fetching: false, activityLogs: response.data.data })
    
    } else {
      
      this.setState({ fetching: false, activityLogs: [] })
      
    }
    
  }

  getUserName = (log) => log.causer.first_name;
  
  prepareLog = (log) => {
    
    switch (log.log_name) {

      default:
        return {
          iconType: '',
          description: ''
        }

      case 'CREATE_ESTIMATE':
        return {
          iconType: 'create',
          description: (
            <span>
              {`${this.getUserName(log)} created estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'UPDATE_ESTIMATE':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'DELETE_ESTIMATE':
        return {
          iconType: 'delete',
          description: (
            <span>
              {`${this.getUserName(log)} deleted estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'HANDLE_ESTIMATE_ITEMS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated the items on estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'HANDLE_ESTIMATE_OPTIONALS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated optionals items on estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'HANDLE_ESTIMATE_CAVEATS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated the caveats on estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'DUPLICATE_ESTIMATE':
        return {
          iconType: 'duplicate',
          description: (
            <span>
              {`${this.getUserName(log)} duplicated estimate ${log.properties.full_reference_original} to ${log.properties.full_reference_copy}`}
            </span>
          )
        }

      case 'REVISE_ESTIMATE':
        return {
          iconType: 'revise',
          description: (
            <span>
              {`${this.getUserName(log)} revised estimate ${log.properties.full_reference_original} to ${log.properties.full_reference_copy}`}
            </span>
          )
        }

      case 'SEND_ESTIMATE_EMAIL':
        return {
          iconType: 'send',
          description: (
            <span>
              {`${this.getUserName(log)} emailed estimate ${log.properties.full_reference}`}
            </span>
          )
        }

      case 'UPDATE_ESTIMATE_STATUS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} marked Estimate ${log.properties.full_reference} as ${log.properties.status_title}`}              
            </span>
          )
        }

      case 'UPDATE_ESTIMATE_LATEST_ACTION':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} updated the Latest Action of Estimate ${log.properties.full_reference} to ${log.properties.latest_action_title}`}
            </span>
          )
        }

      case 'UPDATE_ESTIMATE_TENDER_STATUS':
        return {
          iconType: 'update',
          description: (
            <span>
              {`${this.getUserName(log)} marked Estimate ${log.properties.full_reference} as ${log.properties.tender_status_title}`}
            </span>
          )
        }
      
      case 'UPDATE_ESTIMATE_NOTES':
        return {
          iconType: 'notes',
          description: (
            <span>
              {`${this.getUserName(log)} updates the notes on estimate ${log.properties.full_reference}`}              
            </span>
          )
        }

    }

  }

  render () {

    const {
      fetching,
      activityLogs,
    } = this.state
    
    const type = {
      'create': { backgroundColor: '#3979D9', icon: 'plus' },
      'delete': { backgroundColor: '#D0021B', icon: 'delete' },
      'update': { backgroundColor: '#F5A623', icon: 'edit' },
      'send': { backgroundColor: '#8713C0', icon: 'mail' },
      'duplicate': { backgroundColor: '#3979D9', icon: 'copy' },
      'revise': { backgroundColor: '#F5A623', icon: 'edit' },
      'notes': { backgroundColor: '#40A9FF', icon: 'message' },
    };
    
    return (
      <Popover
        title="Recent Activity"
        trigger="click"
        onVisibleChange={this.onVisibleChange}
        content={(
          
          <div style={{ overflowY: 'scroll', maxHeight: 290, }}>
            
            {fetching && <LoadingOutlined spin style={{ margin: 'auto' }} />}
            
            {activityLogs.map(item => {
              
              const log = this.prepareLog(item)
              
              const iconStyle = {
                backgroundColor: type[log.iconType].backgroundColor,
                verticalAlign: 'middle'
              }
              
              return (
                <div key={item.id} style={{ display: 'flex', alignItems: 'center', padding: 8, paddingRight: 30 }}>

                  <Avatar style={iconStyle} icon={<LegacyIcon type={type[log.iconType].icon} />} />
                    
                  <div style={{ marginLeft: 16 }}>

                    <div style={{ fontSize: 11, color: '#999' }}>
                      {moment(item.created_at).format('DD/MM/YYYY [at] HH:mm:ss')}
                    </div>

                    <div>
                      {log.description}
                    </div>

                  </div>

                </div>
              );
              
            })}
            
          </div>  
              
        )}
      >
        <ClockCircleOutlined style={{ color: '#1890FF', fontSize: 18, cursor: 'pointer' }} />
      </Popover>
    );
  }
}

ActivityPopover.propTypes = {
  estimate: PropTypes.object.isRequired,
}

export default ActivityPopover