import React from 'react'
import PropTypes from 'prop-types'
import { WindowScroller, AutoSizer } from 'react-virtualized'

import SortableList from './Components/SortableList'
import SubValuation from './Components/SubValuation'
import SubOrder from './Components/SubOrder'
import SubOrderGroup from './Components/SubOrderGroup'
import SubOrderItem from './Components/SubOrderItem'
import SubOrderFooter from './Components/SubOrderFooter'
import SubValuationFooter from './Components/SubValuationFooter'

export default class Items extends React.PureComponent {

  static propTypes = {
    listData: PropTypes.array.isRequired,
    editing: PropTypes.bool.isRequired,
  }

  componentDidUpdate = () => this.SortableContainer.getWrappedInstance().List.recomputeRowHeights()

  SortableContainer

  renderRow = (rowProps) => {

    const {
      index,
      key,
      style,
    } = rowProps

    const {
      listData,
    } = this.props

    const {
      component,
      subValuation,
      subOrder,
      subOrderGroup,
      subOrderItem,
      subValuationItem,
    } = listData[index]

    if (component === 'SubValuation') {
      return (
        <div key={key} style={style}>
          <SubValuation
            index={index}
            subValuation={subValuation}
          />
        </div>
      )
    }

    if (component === 'SubOrder') {
      return (
        <div key={key} style={style}>
          <SubOrder
            index={index}
            subOrder={subOrder}
          />
        </div>
      )
    }

    if (component === 'SubOrderGroup') {
      return (
        <div key={key} style={style}>
          <SubOrderGroup
            index={index}
            subOrderGroup={subOrderGroup}
          />
        </div>
      )
    }

    if (component === 'SubOrderItem') {
      return (
        <div key={key} style={style}>
          <SubOrderItem
            index={index}
            subOrderItem={subOrderItem}
            subValuationItem={subValuationItem}
          />
        </div>
      )
    }

    if (component === 'SubOrderFooter') {
      return (
        <div key={key} style={style}>
          <SubOrderFooter
            index={index}
            subOrder={subOrder}
          />
        </div>
      )
    }

    if (component === 'SubValuationFooter') {
      return (
        <div key={key} style={style}>
          <SubValuationFooter
            index={index}
            subValuation={subValuation}
          />
        </div>
      )
    }

    if (component === 'Spacer') {
      return (
        <div key={key} style={style} />
      )
    }

  }

  render() {

    const {
      listData,
      editing,
    } = this.props

    return (

      <div style={{ pointerEvents: editing ? 'all' : 'none', marginBottom: 90 }}>

        <WindowScroller>

          {({ height, scrollTop }) => (

            <AutoSizer disableHeight>

              {({ width }) => (

                <SortableList
                  autoHeight
                  height={height}
                  width={width}
                  scrollTop={scrollTop}
                  lockAxis='y'
                  overscanRowCount={5}
                  useDragHandle
                  rowHeight={({ index }) => listData[index].height}
                  rowRenderer={this.renderRow}
                  rowCount={listData.length}
                  ref={ref => { this.SortableContainer = ref }}
                  useWindowAsScrollContainer
                />

              )}

            </AutoSizer>

          )}

        </WindowScroller>

      </div>

    )

  }

}
