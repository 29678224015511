export default [
  "Dulux Vinyl Matt",
  "Dulux Oil Eggshell",
  "Dulux Satinwood",
  "Dulux Diamond Matt",
  "Dulux Diamond Eggshell",
  "Dulux Super Matt",
  "Dulux Durable Flat Matt",
  "Dulux Metalshield",
  "Dulux Masonry",
  "Dulux Ultra Matt",
  "Dulux Weathershield",
  "Armstead Vinyl Matt",
  "Armstead Contract Matt",
  "Armstead Acrylic Eggshell",
  "Zinsser All Coat Water Based",
  "Zinsser Bulls eye 1-2-3",
  "Thames Coatings Tc266",
  "Upol Easy 1 Filler",
  "Thames Coatings Aqua Grip",
  "Elite Dry Fall Water Base",
  "Tikkurila Optiva Colour",
  "Tikkurila Optiva Matt 5",
  "Tikkurila Anti Reflex",
  "Tikkurila Panssari Akva",
  "Tikkurila Otex Akva",
  "Tikkurila Everal Aqua Semi Matt",
  "Tikkurila Optiva Ceramic Super Matt 3",
  "Selemix DTM",
]