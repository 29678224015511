import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Breadcrumb, Tooltip } from 'antd'
import {
  AppstoreOutlined,
  BarsOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FolderAddOutlined,
  FolderFilled,
  UploadOutlined,
} from '@ant-design/icons'
import { drop } from 'lodash'
import styles from './styles.css'

const Spacer = () => <div style={{ width: 10, display: 'inline-block' }} />

export default class Toolbar extends React.PureComponent {

  static propTypes = {
    layout: PropTypes.string.isRequired,
    currentDirectoryPath: PropTypes.string,
    selectedItemPath: PropTypes.string,
    selectedItemType: PropTypes.string,
    onClickPathBreadcrumb: PropTypes.func.isRequired,
    onClickGrid: PropTypes.func.isRequired,
    onClickList: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    onClickRename: PropTypes.func.isRequired,
    onClickDownload: PropTypes.func.isRequired,
    onClickUpload: PropTypes.func.isRequired,
    onClickNewDirectory: PropTypes.func.isRequired,
  }

  static defaultProps = {
    currentDirectoryPath: null,
    selectedItemPath: null,
    selectedItemType: null,
  }

  render() {

    const {
      layout,
      currentDirectoryPath,
      selectedItemPath,
      selectedItemType,
      onClickPathBreadcrumb,
      onClickGrid,
      onClickList,
      onClickDelete,
      onClickRename,
      onClickDownload,
      onClickUpload,
      onClickNewDirectory,
    } = this.props

    const structuredDirectoryPath = currentDirectoryPath ? drop(currentDirectoryPath.split('/'), 2) : []

    const hasSelectedDirectoryPath = !currentDirectoryPath ? { color: '#DDDDDD', pointerEvents: 'none' } : {}
    const hasSelectedItemPath = !selectedItemPath ? { color: '#DDDDDD', pointerEvents: 'none' } : {}
    const hasSelectedItemDirectoryPath = (!selectedItemPath || selectedItemType === 'directory') ? { color: '#DDDDDD', pointerEvents: 'none' } : {}

    return (

      <Row className={styles.wrapper}>

        <Col span={16}>

          <div className={styles.path}>

            <FolderFilled className={styles.pathIcon} />

            <Breadcrumb separator='/'>

              {structuredDirectoryPath.map((element, index) => (

                <Breadcrumb.Item key={index}>

                  {structuredDirectoryPath.length > (index + 1) ? (

                    <span
                      role='presentation'
                      onClick={() => onClickPathBreadcrumb(index + 2)}
                      className={styles.link}
                    >

                      {element}

                    </span>

                  ) : element}

                </Breadcrumb.Item>

              ))}

            </Breadcrumb>

          </div>

        </Col>

        <Col span={8} style={{ textAlign: 'right' }}>

          {layout === 'list' && (
            <Tooltip title='Grid' placement='top' className={styles.actionIcon}>
              <AppstoreOutlined onClick={onClickGrid} />
            </Tooltip>
          )}

          {layout === 'grid' && (
            <Tooltip title='List' placement='top' className={styles.actionIcon}>
              <BarsOutlined onClick={onClickList} />
            </Tooltip>
          )}

          <Spacer />

          <Tooltip title='Delete' placement='top' className={styles.actionIcon}>
            <DeleteOutlined onClick={onClickDelete} style={hasSelectedItemPath} />
          </Tooltip>

          <Spacer />

          <Tooltip title='Rename' placement='top' className={styles.actionIcon}>
            <EditOutlined onClick={onClickRename} style={hasSelectedItemPath} />
          </Tooltip>

          <Spacer />

          <Tooltip title='Download' placement='top' className={styles.actionIcon}>
            <DownloadOutlined onClick={onClickDownload} style={hasSelectedItemDirectoryPath} />
          </Tooltip>

          <Spacer />

          <Tooltip title='Upload' placement='top' className={styles.actionIcon}>
            <UploadOutlined onClick={onClickUpload} style={hasSelectedDirectoryPath} />
          </Tooltip>

          <Spacer />

          <Tooltip title='New Directory' placement='top' className={styles.actionIcon}>
            <FolderAddOutlined onClick={onClickNewDirectory} style={hasSelectedDirectoryPath} />
          </Tooltip>

        </Col>

      </Row>

    )

  }

}
