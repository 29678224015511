exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3GF91D4592JEGU2nAwV-f8 {\n  width: 25px;\n  font-size: 18px;\n  cursor: pointer;\n}\n\n._2bpKOq1viZx9X2c0-RsjYV table {\n  padding-left: 0px !important;\n  padding-right: 0px !important;\n}\n\n.JPOdTATpxQRDEpCgB5eu- {\n  text-align: center !important;\n}\n\n._1gMBuLublcqZHre6gfUjrr {\n  background-color: #9ef0ff !important;\n}\n\n._3B9SpnAXh91xI2Ch04lyQL {\n  background-color: #ffff9e !important;\n}\n\n._16GvNZRxnHGFe2LV10tuY5 {\n  display: flex;\n  align-items: flex-start;\n}\n\n._16GvNZRxnHGFe2LV10tuY5 .ant-badge {\n  position: relative;\n  top: 4px;\n  margin-right: 15px;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Planner/Components/SubcontractorDrawer/styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;CACjB;;AAED;EACE,6BAA6B;EAC7B,8BAA8B;CAC/B;;AAED;EACE,8BAA8B;CAC/B;;AAED;EACE,qCAAqC;CACtC;;AAED;EACE,qCAAqC;CACtC;;AAED;EACE,cAAc;EACd,wBAAwB;CACzB;;AAED;EACE,mBAAmB;EACnB,SAAS;EACT,mBAAmB;CACpB","file":"styles.css","sourcesContent":[".icon {\n  width: 25px;\n  font-size: 18px;\n  cursor: pointer;\n}\n\n.table table {\n  padding-left: 0px !important;\n  padding-right: 0px !important;\n}\n\n.header {\n  text-align: center !important;\n}\n\n.today {\n  background-color: #9ef0ff !important;\n}\n\n.weekend {\n  background-color: #ffff9e !important;\n}\n\n.multiple {\n  display: flex;\n  align-items: flex-start;\n}\n\n.multiple :global(.ant-badge) {\n  position: relative;\n  top: 4px;\n  margin-right: 15px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"icon": "_3GF91D4592JEGU2nAwV-f8",
	"table": "_2bpKOq1viZx9X2c0-RsjYV",
	"header": "JPOdTATpxQRDEpCgB5eu-",
	"today": "_1gMBuLublcqZHre6gfUjrr",
	"weekend": "_3B9SpnAXh91xI2Ch04lyQL",
	"multiple": "_16GvNZRxnHGFe2LV10tuY5"
};