import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import styles from './styles.css'

class SubOrderFooter extends React.PureComponent {

  static propTypes = {
    subOrder: PropTypes.object.isRequired,
  }

  render() {

    const {
      subOrder,
    } = this.props

    return (

      <div className={styles.wrapper}>

        <div className={styles.total}>
          {`Order Total: ${subOrder.total < 0 ? '-' : ''}£${numeral(Math.abs(subOrder.total)).format('0,0.00')}`}
        </div>

      </div>

    )

  }

}

export default SubOrderFooter
