import chance from 'chance'
import { setSubOrderGroups } from '../State/subOrder'

export default (guid) => (dispatch, getState) => {

  const { subOrder } = getState().screens.SubOrder

  const newSubOrderGroups = []

  subOrder.sub_order_groups.forEach(subOrderGroup => {

    newSubOrderGroups.push({ ...subOrderGroup })

    if (subOrderGroup.guid !== guid) return

    const newSubOrderItems = []

    subOrderGroup.sub_order_items.forEach(subOrderItem => {
      newSubOrderItems.push({
        ...subOrderItem,
        id: undefined,
        guid: chance().guid(),
      })
    })

    const newSubOrderGroup = {
      ...subOrderGroup,
      id: undefined,
      guid: chance().guid(),
      sub_order_items: newSubOrderItems,
    }

    newSubOrderGroups.push(newSubOrderGroup)

  })

  dispatch(setSubOrderGroups(newSubOrderGroups))

}
