import React from 'react';
import { get } from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { Table, Select } from 'antd';
import { getTeamUrl } from 'Common';

import ChartPanel from './ChartPanel';

const columns = [

  { 
    title: 'Project / Client / Site',  
    width: '30%',
    dataIndex: '',
    render: (data) => (
      <div>
        <div>
          <b>
            <NavLink to={getTeamUrl(`projects/${data.id}/view`)}>
              {data.id}
            </NavLink>
          </b>
          {' | '}
          <b>
            {get(data, 'client_site.client.name', '')}
          </b>
        </div>
        <div>
          {get(data, 'client_site.name', '')}
        </div>
      </div>
    )
  },

  { 
    title: 'Start Date',  
    width: '10%',
    dataIndex: '',
    render: (data) => data.start_date ? moment(get(data, 'start_date')).format('DD/MM/YYYY') : ''
  },

  { 
    title: 'Total',  
    width: '15%',
    dataIndex: '',
    render: (data) => (
      <b>
        {`£${numeral(get(data, 'value_total', 0)).format('0,0.00')}`}
      </b>
    )
  },

  { 
    title: 'Service Breakdown',  
    width: '45%',
    dataIndex: '',
    render: (data) => (
      <div>
        {[
          data.item_type_summary.D ? (
            `Decorating: £${numeral(data.item_type_summary.D).format('0,0.00')}`
          ) : null,
          data.item_type_summary.S && (
            `Spraying: £${numeral(data.item_type_summary.S).format('0,0.00')}`
          ) : null,
          data.item_type_summary.W && (
            `Wallpapering: £${numeral(data.item_type_summary.W).format('0,0.00')}`
          ) : null,
          data.item_type_summary.T && (
            `Tape & Joining: £${numeral(data.item_type_summary.T).format('0,0.00')}`
          ) : null,
          data.item_type_summary.F && (
            `Flooring: £${numeral(data.item_type_summary.F).format('0,0.00')}`
          ) : null,
          data.item_type_summary.I && (
            `Intumescent: £${numeral(data.item_type_summary.I).format('0,0.00')}`
          ) : null,
          data.item_type_summary.O && (
            `Other: £${numeral(data.item_type_summary.O).format('0,0.00')}`
          ) : null,
        ].filter(x => x).join(', ')}
      </div>
    )
  },

];

type Props = {
  newProjectsWithOrderReport: Object,
  days: string,
  onChangeDays: Function
}

export default class extends React.PureComponent<Props> {
  
  render = () => (
      
    <ChartPanel
      title="New Projects with Order"
      renderTools={() => this.renderTools()}
      renderChart={() => this.renderChart()}
    />
      
  )

  renderTools = () => (

    <Select
      value={this.props.days}
      showArrow
      allowClear={false}
      onChange={this.props.onChangeDays}
      style={{ width: 200 }}
    >
      <Select.Option key="7" value="7">Last 7 Days</Select.Option>
      <Select.Option key="14" value="14">Last 14 Days</Select.Option>
      <Select.Option key="28" value="28">Last 28 Days</Select.Option>
    </Select>

  )
  
  renderChart () {
    
    const { 
      newProjectsWithOrderReport
    } = this.props;

    return (
      
      <Table 
        dataSource={newProjectsWithOrderReport}
        columns={columns} 
        rowKey={record => record.id}
        bordered
        size="medium"
        style={{ flex: 1, margin: 15 }}
      />

    )
  }
}
