import { message } from 'antd';

import { createItemDescription } from '../../../HttpRequests/itemDescriptions';
import handleException from '../../../Core/Helpers/handleException';

export default (data) => async () => {

  try {

    await createItemDescription({ data });

    message.success('Item Description Created');

    return true;
    
  } catch (error) {
    
    handleException(error);

    return false;

  }
}
