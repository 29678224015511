import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'
import { CaretDownFilled, DisconnectOutlined, EditOutlined } from '@ant-design/icons'
import { getTeamUrl } from 'Common'
import styles from './styles.css'

class SubValuation extends React.PureComponent {

  static propTypes = {
    subValuation: PropTypes.object.isRequired,
    onClickEdit: PropTypes.func.isRequired,
    onClickDetach: PropTypes.func.isRequired,
  }

  render() {

    const {
      subValuation,
      onClickEdit,
      onClickDetach,
    } = this.props

    return (

      <div className={styles.wrapper}>

        <div className={styles.left}>

          <div className={styles.reference}>
            <NavLink to={getTeamUrl(`sub_valuations/${subValuation.id}`)} style={{ color: '#000000d9' }}>
              {subValuation.reference}
            </NavLink>
          </div>

          <div className={styles.actions}>
            <Dropdown
              trigger={['click']}
              overlay={(
                <Menu>
                  <Menu.Item icon={<EditOutlined />} key='edit' onClick={() => onClickEdit(subValuation)}>Edit Valuation</Menu.Item>
                  <Menu.Item icon={<DisconnectOutlined />} key='unlink' onClick={() => onClickDetach(subValuation)}>Unlink Valuation</Menu.Item>
                </Menu>
              )}
            >
              <CaretDownFilled />
            </Dropdown>
          </div>

        </div>

      </div>

    )

  }

}

export default SubValuation
