import { connect } from 'react-redux'
import ProjectSummaryTable from './ProjectSummaryTable'

import onClickProject from './Handlers/onClickProject'

const mapStateToProps = (state) => ({
  periods: state.screens.Planner.data.periods,
  rows: state.screens.Planner.data.rows,
  fetching: state.screens.Planner.fetching,
})

const actionCreators = {
  onClickProject,
}

export default connect(mapStateToProps, actionCreators)(ProjectSummaryTable)
