import { getYearOverviewReport } from '../../../HttpRequests/dashboard';
import handleException from '../../../Core/Helpers/handleException';

import { loadData } from '../State/yearOverviewReport';

export default () => async (dispatch) => {

  try {

    const response = await getYearOverviewReport();

    dispatch(loadData(response.data.data));
    
  } catch(error) {
    handleException(error);

  }
}