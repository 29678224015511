import { initialize } from 'redux-form';

import { getItemDescription } from '../../../HttpRequests/itemDescriptions';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { itemDescriptionId } = getProps();
  
  if (itemDescriptionId) {

    const response = await getItemDescription({ id: itemDescriptionId });

    const itemDescription = response.data.data;

    dispatch(initialize(FORM_NAME, itemDescription))
    
  } else {

    dispatch(initialize(FORM_NAME, { }))

  }

}