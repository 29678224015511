import React from 'react';
import { Select, Radio, DatePicker, Switch } from 'antd';

const { RangePicker } = DatePicker;

const defaultSelectProps = {
  style: { width: 230 },
  showArrow: true,
  showSearch: true,
  allowClear: true,
  notFoundContent: null,
  defaultActiveFirstOption: true,
  optionFilterProp: 'children',
};

const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'draft', label: 'Draft' },
  { value: 'in-progress', label: 'In Progress' },
  { value: 'approval', label: 'Approval' },
  { value: 'approved', label: 'Approved' },
  { value: 'pending', label: 'Pending' },
  { value: 'won', label: 'Won' },
  { value: 'lost', label: 'Lost' },
  { value: 'declined', label: 'Declined' }
];

const extraWorksOptions = [
  { value: 'all', label: 'All' },
  { value: 'new', label: 'New' },
  { value: 'extra', label: 'Extra' },
]

type Props = {
  filters: any,
  users: [],
  clients: [],
  clientContacts: [],
  estimateStatuses:[],
  estimateLatestActions:[],
  onChangeFilter: (key: any, value: any) => void
}

export default class extends React.PureComponent<Props> {

  render () {

    const {
      filters,
      users,
      clients,
      clientContacts,
      estimateStatuses,
      estimateLatestActions,
      onChangeFilter
    } = this.props;

    const clientContactsByClient = filters.client_id ? clientContacts.filter(clientContact => filters.client_id.includes(String(clientContact.client_id))) : []

    return (

      <div>

        <div style={{ display: 'flex', marginBottom: 15 }}>
            
          <Radio.Group
            value={filters.smart_status}
            onChange={(event) => onChangeFilter('smart_status', event.target.value)}
          >
            {statusOptions.map(option => (
              <Radio.Button
                style={{ minWidth: 70, textAlign: 'center' }}
                value={option.value}
                key={option.value}
              >
                {option.label}
              </Radio.Button>
            ))}
          </Radio.Group>

          <div style={{ width: 10 }} />

          <Radio.Group
            value={filters.extra_works_status}
            onChange={(event) => onChangeFilter('extra_works_status', event.target.value)}
          >
            {extraWorksOptions.map(option => (
              <Radio.Button
                style={{ minWidth: 70, textAlign: 'center' }}
                value={option.value}
                key={option.value}
              >
                {option.label}
              </Radio.Button>
            ))}
          </Radio.Group>

          <div style={{ width: 10 }} />

          <Select
            {...Object.assign({}, defaultSelectProps)}
            placeholder="Priority"
            value={filters.priority}
            onChange={(value) => onChangeFilter('priority', value)}
            style={{ width: 120 }}
          >
            {['High', 'Medium', 'Low'].map(option => (
              <Select.Option key={option}>
                {option}
              </Select.Option>
            ))}
          </Select>

          <div style={{ width: 10 }} />

          <RangePicker
            value={filters.date_between}
            onChange={(value) => onChangeFilter('date_between', value)}
            format="DD/MM/YYYY"
          />

          <div style={{ width: 10 }} />

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Switch
              checked={filters.latest_only}
              onChange={(value) => onChangeFilter('latest_only', value)}
            />
            <div style={{ width: 5 }} />
            <span>Latest Only</span>
          </div>
        
        </div>

        <div style={{ display: 'flex', marginBottom: 15 }}>

          <Select
            {...Object.assign({}, defaultSelectProps)}
            placeholder="Filter by Owner"
            value={filters.user_id}
            onChange={(value) => onChangeFilter('user_id', value)}
          >

            <Select.OptGroup label="Status">

              <Select.Option key='assigned'>
                {`Assigned`}
              </Select.Option>

              <Select.Option key='unassigned'>
                {`Unassigned`}
              </Select.Option>

            </Select.OptGroup>

            <Select.OptGroup label="Owned by:">

              {users.map(user => (
                <Select.Option key={user.id}>
                  {`${user.first_name} ${user.last_name}`}
                </Select.Option>
              ))}

            </Select.OptGroup>

          </Select>

          <div style={{ width: 10 }} />

          <Select
            {...defaultSelectProps}
            placeholder="Filter by Sales Contact"
            value={filters.assigned}
            onChange={(value) => onChangeFilter('assigned', value)}
          >
            <Select.OptGroup label="Status">

              <Select.Option key='assigned'>
                {`Assigned`}
              </Select.Option>

              <Select.Option key='unassigned'>
                {`Unassigned`}
              </Select.Option>

            </Select.OptGroup>
            
            <Select.OptGroup label="Assigned to:">

              {users.map(user => (
                <Select.Option key={user.id}>
                  {`${user.first_name} ${user.last_name}`}
                </Select.Option>
              ))}

            </Select.OptGroup>

          </Select>

          <div style={{ width: 10 }} />

          <Select
            {...Object.assign({}, defaultSelectProps)}
            placeholder="Filter by Client"
            value={filters.client_id}
            onChange={(value) => onChangeFilter('client_id', value)}
            mode="multiple"
          >
            
            {clients.map(client => (
              <Select.Option key={client.id}>
                {`${client.name}`}
              </Select.Option>
            ))}

          </Select>

          <div style={{ width: 10 }} />

          <Select
            {...Object.assign({}, defaultSelectProps)}
            placeholder="Filter by Status"
            value={filters.status_key}
            mode="multiple"
            onChange={(value) => onChangeFilter('status_key', value)}
            style={{ width: filters.status_key.length > 0 ? 400 : 230 }}
          >
            {estimateStatuses.map(item => (
              <Select.Option key={item.key}>
                {`${item.title}`}
              </Select.Option>
            ))}

          </Select>

          <div style={{ width: 10 }} />

          <Select
            {...Object.assign({}, defaultSelectProps)}
            placeholder="Filter by Latest Action"
            value={filters.latest_action_key}
            mode="multiple"
            onChange={(value) => onChangeFilter('latest_action_key', value)}
            style={{ width: filters.status_key.length > 0 ? 400 : 230 }}
          >
            {estimateLatestActions.map(item => (
              <Select.Option key={item.key}>
                {`${item.title}`}
              </Select.Option>
            ))}

          </Select>

          <div style={{ width: 10 }} />

          <Select
            {...Object.assign({}, defaultSelectProps)}
            placeholder="Filter by Decision Maker"
            value={filters.decision_maker_id}
            onChange={(value) => onChangeFilter('decision_maker_id', value)}
            style={{ width: 400 }}
          >

            {clientContactsByClient.map(clientContact => (
              <Select.Option key={clientContact.id}>
                {`${clientContact.full_name} [${clientContact.client.name}]`}
              </Select.Option>
            ))}

          </Select>

        </div>

      </div>

    )
  }
}