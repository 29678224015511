import { createAction, handleActions } from 'redux-actions'

const openEmailSubOrderModal = createAction('SCREENS/SUB_ORDER/OPEN_EMAIL_SUB_ORDER_MODAL')
const closeEmailSubOrderModal = createAction('SCREENS/SUB_ORDER/CLOSE_EMAIL_SUB_ORDER_MODAL')

const defaultState = {
  show: false,
}

const reducer = handleActions(
  {
    [openEmailSubOrderModal]: (state, { payload }) => ({
      ...payload,
      show: true,
    }),
    [closeEmailSubOrderModal]: () => defaultState,
  },
  defaultState,
)

export { openEmailSubOrderModal, closeEmailSubOrderModal, reducer }
