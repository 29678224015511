exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2Mp1Tq8dt2UPvDcdJ29nzb {\n  display: flex;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n._3jik5U5JshIQf26KuZuN8x {\n  flex: 1;\n  margin: 0px;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/SubInvoice/Components/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;CACrB;;AAED;EACE,QAAQ;EACR,YAAY;CACb","file":"styles.css","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  margin-bottom: 25px;\n}\n\n.header {\n  flex: 1;\n  margin: 0px;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_2Mp1Tq8dt2UPvDcdJ29nzb",
	"header": "_3jik5U5JshIQf26KuZuN8x"
};