// @flow

import { fromJS } from 'immutable';
import { loadOrder } from '../State/order';

export type RemoveOrderItemParams = {
  orderGroupIndex: number,
  orderItemIndex: number
}

export default (params: RemoveOrderItemParams) => (dispatch: Function, getState: Function) => {

  let order = fromJS(getState().screens.OrderView.order);
  
  order = order.removeIn(['order_groups', params.orderGroupIndex, 'order_items', params.orderItemIndex]);

  order = order.toJS();
  
  dispatch(loadOrder(order));

}