import { setSorting } from '../State/sorting'
import fetch from '../Services/fetch'

export default (pagination, filters, sorter) => (dispatch) => {
  
  dispatch(setSorting({
    columnKey: sorter.columnKey,
    descending: sorter.order === 'descend',
  }))

  dispatch(fetch())

}
