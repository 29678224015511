import { createAction, handleActions } from 'redux-actions'

const setSubInvoices = createAction('SCREENS/SUBBIES/SUB_INVOICES/SET_SUB_INVOICES')
const resetSubInvoices = createAction('SCREENS/SUBBIES/SUB_INVOICES/RESET_SUB_INVOICES')

const defaultState = []

const reducer = handleActions(
  {
    [setSubInvoices]: (state, { payload }) => payload,
    [resetSubInvoices]: () => [],
  },
  defaultState,
)

export { setSubInvoices, resetSubInvoices, reducer }
