import drawerProps from '../drawerProps'
import fetch from '../Services/fetch'

export default (props) => async (dispatch) => {

  drawerProps(props)

  dispatch(fetch())

}
