import fetch from '../Services/fetch'
import { setFilters } from '../State/filters'

export default (filter, value) => async (dispatch) => {

  dispatch(setFilters({ [filter]: value }))

  if (filter === 'hoursFormat') return

  dispatch(fetch())

}
