import { connect } from 'react-redux'
import ValuationServicesPopover from './ValuationServicesPopover'

import onVisibleChange from './Handlers/onVisibleChange'

const mapStateToProps = (state) => ({
  serviceTypes: state.core.serviceTypes,
  data: state.modules.ValuationServicesPopover.data,
  isFetching: state.modules.ValuationServicesPopover.isFetching,
})

const actionCreators = {
  onVisibleChange,
}

export default connect(mapStateToProps, actionCreators)(ValuationServicesPopover)
