// @flow

import { fromJS } from 'immutable';
import { arrayMove } from 'react-sortable-hoc';
import { clone } from 'lodash';
import selectOptionalsListData from '../Selectors/selectOptionalsListData';
import { loadEstimate } from '../State/estimate';

export type MoveEstimateOptionalParams = {
  oldIndex: number,
  newIndex: number
}

export default (params: MoveEstimateOptionalParams) => (dispatch: Function, getState: Function) => {


  // get immutable copy of the estimate
  let estimate = fromJS(getState().screens.EstimateView.estimate);

  // select the mapped list items
  let listData = selectOptionalsListData(getState());



  // dont't carry on if index hasnt moved
  if (params.oldIndex === params.newIndex) return;

  // don't allow it to be dropped at the beginning (before the first header)
  if (params.newIndex === 0) return;

  // don't allow it to be dropped at the end (after the last footer)
  if (params.newIndex === (listData.length-1)) return;
  


  // get the old estimateOptional, estimateOptionalIndex
  const {
    estimateOptional: oldEstimateOptional,
    estimateOptionalIndex: oldEstimateOptionalIndex
  } = listData[params.oldIndex];

  // prepare the new estimateOptional, estimateOptionalIndex

  listData = arrayMove(listData, params.oldIndex, params.newIndex);

  const listItemBefore = listData[params.newIndex - 1];

  if (listItemBefore.type === 'Footer') return; // don't allow it to be dropped between footer & header

  const newEstimateOptionalIndex = listItemBefore.estimateOptional ? (listItemBefore.estimateOptionalIndex + 1) : 0;
  const newEstimateOptional = clone(oldEstimateOptional)



  // make the changes

  if (oldEstimateOptionalIndex < newEstimateOptionalIndex) {

    // insert the estimateOptional in the new position
    estimate = estimate.updateIn(
      ['estimate_optionals'],
      (list) => list.insert(newEstimateOptionalIndex, newEstimateOptional)
    );

    // remove the estimateOptional from the old position
    estimate = estimate.removeIn(['estimate_optionals', oldEstimateOptionalIndex]);

  } else {

    // remove the estimateOptional from the old position
    estimate = estimate.removeIn(['estimate_optionals', oldEstimateOptionalIndex]);

    // insert the estimateOptional in the new position
    estimate = estimate.updateIn(
      ['estimate_optionals'],
      (list) => list.insert(newEstimateOptionalIndex, newEstimateOptional)
    );

  }


  
  // save
  
  estimate = estimate.toJS();
  
  dispatch(loadEstimate(estimate));

}