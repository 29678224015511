import { createAction, handleActions } from 'redux-actions'
import { assign } from 'lodash'

const loadData = createAction('SCREENS/DASHBOARD/ESTIMATES_BY_CLIENT_REPORT/LOAD_DATA')
const setType = createAction('SCREENS/DASHBOARD/ESTIMATES_BY_CLIENT_REPORT/SET_TYPE')
const setDateRange = createAction('SCREENS/DASHBOARD/ESTIMATES_BY_CLIENT_REPORT/SET_DATE_RANGE')
const clearData = createAction('SCREENS/DASHBOARD/ESTIMATES_BY_CLIENT_REPORT/CLEAR_DATA')

const defaultState = {
  data: {
    won: [],
    lost: [],
  },
  type: 'subtotal',
  dateRange: 'last_three_months',
}

const reducer = handleActions(
  {
    [loadData]: (state, action) => assign({}, state, { data: action.payload }),
    [setType]: (state, action) => assign({}, state, { type: action.payload }),
    [setDateRange]: (state, action) => assign({}, state, { dateRange: action.payload }),
    [clearData]: (state) => assign({}, state, { data: { ...defaultState.data } }),
  },
  defaultState,
)

export { loadData, setType, setDateRange, clearData, reducer }
