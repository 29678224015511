import { createAction, handleActions } from 'redux-actions'

const setActiveTab = createAction('SCREENS/PLANNER/PROJECT_CELL_MODAL/SET_ACTIVE_TAB')
const resetActiveTab = createAction('SCREENS/PLANNER/PROJECT_CELL_MODAL/RESET_ACTIVE_TAB')

const defaultState = 'Day'

const reducer = handleActions(
  {
    [setActiveTab]: (state, action) => action.payload,
    [resetActiveTab]: () => defaultState,
  },
  defaultState,
)

export { setActiveTab, resetActiveTab, reducer }
