// @flow

import { fromJS } from 'immutable';
import { loadEstimate } from '../State/estimate';

export type UpdateEstimateGroupNameParams = {
  estimateGroupIndex: number,
  name: string
}

export default (params: UpdateEstimateGroupNameParams) => async (dispatch: Function, getState: Function) => {

  let estimate = fromJS(getState().screens.EstimateView.estimate);

  estimate = estimate.setIn(['estimate_groups', params.estimateGroupIndex, 'name'], params.name);

  estimate = estimate.toJS();

  dispatch(loadEstimate(estimate));

}