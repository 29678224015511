exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2cLro8ZQbHix8SF8go9nG3 {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 65px;\n  display: flex;\n  align-items: center;\n}\n\n._3CsYwXvxjWCA4HN4lSQx1U {\n  cursor: pointer;\n  margin-right: 30px;\n  color: #999;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n\n._3BmKc5KUysTyXsfDQ82cin {\n  color: #1990FF;\n  opacity: 0.9;\n}\n\n._3BmKc5KUysTyXsfDQ82cin:hover {\n  color: #1990FF;\n  opacity: 1;\n}\n\n._1hh4kC9R0c9aARnL_o9h4b:hover {\n  color: #F5222D;\n}\n\n._1VXvHSW1_-C5HObQu0q9bm {\n  flex: 1;\n  text-align: right;\n  font-size: 14px;\n  font-weight: 600;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/OrderView/Components/Items/Components/GroupFooter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;EACd,oBAAoB;CACrB;;AAED;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,0BAA0B;KACvB,uBAAuB;MACtB,sBAAsB;UAClB,kBAAkB;CAC3B;;AAED;EACE,eAAe;EACf,aAAa;CACd;;AAED;EACE,eAAe;EACf,WAAW;CACZ;;AAED;EACE,eAAe;CAChB;;AAED;EACE,QAAQ;EACR,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;CAClB","file":"GroupFooter.css","sourcesContent":[".wrapper {\n  height: 100%;\n  margin-left: 30px;\n  margin-right: 65px;\n  display: flex;\n  align-items: center;\n}\n\n.link {\n  cursor: pointer;\n  margin-right: 30px;\n  color: #999;\n  font-weight: 500;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n\n.addLink {\n  color: #1990FF;\n  opacity: 0.9;\n}\n\n.addLink:hover {\n  color: #1990FF;\n  opacity: 1;\n}\n\n.removeLink:hover {\n  color: #F5222D;\n}\n\n.total {\n  flex: 1;\n  text-align: right;\n  font-size: 14px;\n  font-weight: 600;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_2cLro8ZQbHix8SF8go9nG3",
	"link": "_3CsYwXvxjWCA4HN4lSQx1U",
	"addLink": "_3BmKc5KUysTyXsfDQ82cin",
	"removeLink": "_1hh4kC9R0c9aARnL_o9h4b",
	"total": "_1VXvHSW1_-C5HObQu0q9bm"
};