import { initialize } from 'redux-form';
import { getOrderItem } from '../../../HttpRequests/orderItems';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { orderItemId } = getProps();

  const response = await getOrderItem({ id: orderItemId });

  const orderItem = response.data.data;

  dispatch(initialize(FORM_NAME, Object.assign({}, {
    description: orderItem.description
  })));

}