// @flow

import { sortBy, assign } from 'lodash';
import { getEstimate } from '../../../HttpRequests/estimates';
import { loadEstimate } from '../State/estimate';
import { setFetching } from '../State/fetching';
import { getProps } from '../props';
import handleException from '../../../Core/Helpers/handleException';

export default () => async (dispatch: Function) => {
  
  try {
    
    const { id } = getProps().match.params;

    const params = {};
    
    params.include = [
      'project',
      'project.client_site',
      'project.client_site.client',
      'estimate_groups',
      'estimate_groups.estimate_items',
      'estimate_optionals',
      'estimate_caveats',
      'client_contact',
      'decision_maker',
      'user',
      'assigned_to_user',
      'assigned_to_other_user',
      'media',
    ].join();

    params.append = [
      'full_reference',
      'action_statuses',
      'updatable_fields'
    ].join();

    dispatch(setFetching(true));

    const response = await getEstimate({ id, params });

    const estimate = response.data.data;

    estimate.estimate_groups = sortBy(
      estimate.estimate_groups.map(estimateGroup => assign(
        {},
        estimateGroup,
        { estimate_items: sortBy(estimateGroup.estimate_items, 'order_number') }
      )
    ), 'order_number');

    estimate.estimate_optionals = sortBy(estimate.estimate_optionals, 'order_number');

    estimate.estimate_caveats = sortBy(estimate.estimate_caveats, 'order_number');

    dispatch(loadEstimate(estimate));

    dispatch(setFetching(false));

  } catch (error) {

    handleException(error);

  }
}