import { setEditing } from '../../../State/editing'
import { setSubValuations } from '../../../State/subValuations'

export default () => (dispatch, getState) => {

  dispatch(setEditing(false))

  dispatch(setSubValuations(getState().screens.SubInvoice.originalItems))

}
