import { connect } from 'react-redux';
import Component from './Component';

import onClickActionMenuItem from './Handlers/onClickActionMenuItem';
import onChangeNotes from './Handlers/onChangeNotes';
import onChangeValuationField from './Handlers/onChangeValuationField';

const mapStateToProps = (state) => ({
  valuation: state.screens.ValuationView.valuation
})

const actionCreators = {
  onClickActionMenuItem,
  onChangeNotes,
  onChangeValuationField
};

export default connect(mapStateToProps, actionCreators)(Component);