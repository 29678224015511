// @flow

import { fromJS } from 'immutable';
import { assign } from 'lodash';
import { loadOrder } from '../State/order';

export type DuplicateOrderCaveatParams = {
  orderCaveatIndex: number
}

export default (params: DuplicateOrderCaveatParams) => (dispatch: Function, getState: Function) => {

  let order = fromJS(getState().screens.OrderView.order);

  const sourceOrderCaveat = order.getIn(['order_caveats', params.orderCaveatIndex]).toJS();

  const orderCaveat = assign({}, sourceOrderCaveat, { id: null });
  
  order = order.updateIn(
    ['order_caveats'],
    (list) => list.insert(params.orderCaveatIndex + 1, orderCaveat)
  );

  order = order.toJS();
  
  dispatch(loadOrder(order));

}