import { initialize } from 'redux-form';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {
  
  const { client } = getProps();

  if (getProps().client) {

    dispatch(initialize(FORM_NAME, {
      ...client,
      preferred_supplier: !!client.preferred_supplier,
    }))
    
  } else {

    dispatch(initialize(FORM_NAME, {
      preferred_supplier: false,
      billing_type: 'Unknown',
      payment_terms_days: 30,
      payment_terms_end_of_month: true,
      client_risk_level_key: 1
    }))

  }

}