import { createAction, handleActions } from 'redux-actions'

const loadRetentionPayments = createAction('SCREENS/RETENTION_PAYMENTS/LOAD_RETENTION_PAYMENTS')

const defaultState = []

const reducer = handleActions(
  {
    [loadRetentionPayments]: (state, action) => action.payload,
  },
  defaultState
)

export { loadRetentionPayments, reducer }