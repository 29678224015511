import saveGroups from '../../../Services/saveGroups'
import { resetEditing } from '../../../State/editing'

export default () => async (dispatch) => {

  await dispatch(saveGroups())

  dispatch(resetEditing())

}
