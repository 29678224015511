exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._193q_LxeOPXxnETFi5kEHg{\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px\n}\n\n._3XtSLFBp5VVLtLicM0TzqN{\n  flex: 1;\n  margin: 0px;\n}", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Caveats/Components/Header/Component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,oBAAoB;EACpB,mBAAmB;CACpB;;AAED;EACE,QAAQ;EACR,YAAY;CACb","file":"Component.css","sourcesContent":[".stack{\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-bottom: 25px\n}\n\n.heading{\n  flex: 1;\n  margin: 0px;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"stack": "_193q_LxeOPXxnETFi5kEHg",
	"heading": "_3XtSLFBp5VVLtLicM0TzqN"
};