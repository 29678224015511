// @flow

import * as React from 'react';
import styles from './index.css';
import List from './Components/List';

type Props = {
  project: {},
  onClickViewEmail: Function
}

export default class extends React.PureComponent<Props> {

  render(){

    const {
      project,
      onClickViewEmail
    } = this.props;

    
    return(
      <div className={styles.wrapper}>

        <div className={styles.body}>
          
          <List project={project} onClickViewEmail={onClickViewEmail} />

        </div>

      </div>
    )
  }
}