import { message } from 'antd'
import { updateProject } from '../../../HttpRequests/projects'
import fetch from './fetch'
import handleException from '../../../Core/Helpers/handleException'

export default (id) => async (dispatch) => {

  try {

    await updateProject({
      id,
      data: {
        rams_muted: 0
      }
    })

    await dispatch(fetch())

    message.success('Unmuted')
    
  } catch (error) {

    handleException(error)

  }
  
}