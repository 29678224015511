import { connect } from 'react-redux'
import Header from './Header'

import selectIsFiltered from './Selectors/selectIsFiltered'

import onChangeSearch from './Handlers/onChangeSearch'
import onSearch from './Handlers/onSearch'
import onChangeFilter from './Handlers/onChangeFilter'
import onClickExport from './Handlers/onClickExport'
import onClickNew from './Handlers/onClickNew'

const mapStateToProps = (state) => ({
  subcontractors: state.core.subcontractors,
  users: state.core.users,
  search: state.screens.Subbies.SubInvoices.search,
  filters: state.screens.Subbies.SubInvoices.filters,
  isFiltered: selectIsFiltered(state),
})

const actionCreators = {
  onChangeSearch,
  onSearch,
  onChangeFilter,
  onClickExport,
  onClickNew,
}

export default connect(mapStateToProps, actionCreators)(Header)
