import { setSubOrderGroups } from '../State/subOrder'

export default () => (dispatch, getState) => {

  const { subOrder, selectedItems } = getState().screens.SubOrder

  const newSubOrderGroups = []

  subOrder.sub_order_groups.forEach(subOrderGroup => {

    const newSubOrderItems = []

    subOrderGroup.sub_order_items.forEach(subOrderItem => {
      if (selectedItems.includes(subOrderItem.guid)) return
      newSubOrderItems.push({ ...subOrderItem })
    })

    newSubOrderGroups.push({
      ...subOrderGroup,
      sub_order_items: newSubOrderItems,
    })

  })

  dispatch(setSubOrderGroups(newSubOrderGroups))

}
