import { initialize } from 'redux-form';
import moment from 'moment';
import { pick } from 'lodash';
import { getUserIdForInput } from 'Common';
import { FORM_NAME } from '../Form';
import { getProps } from '../props';

export default () => async (dispatch) => {

  const { invoice, valuation } = getProps();

  if (invoice) {

    dispatch(initialize(FORM_NAME, pick(invoice, [
      'client_contact_id',
      'user_id',
      'date',
      'added_to_quickbooks'
    ])))
    
  } if (valuation) {

    dispatch(initialize(FORM_NAME, {
      valuation_id: valuation.id,
      client_contact_id: valuation.client_contact_id,
      user_id: getUserIdForInput(),
      date: moment().format('YYYY-MM-DD'),
      added_to_quickbooks: false
    }))

  }

}