import { createAction, handleActions } from 'redux-actions';
import { assign } from 'lodash';
import moment from 'moment';

const loadData = createAction('SCREENS/DASHBOARD/SERVICES_BREAKDOWN_REPORT/LOAD_DATA');
const clearData = createAction('SCREENS/DASHBOARD/SERVICES_BREAKDOWN_REPORT/CLEAR_DATA');
const setDateRange = createAction('SCREENS/DASHBOARD/SERVICES_BREAKDOWN_REPORT/SET_DATE_RANGE');

const defaultState = {
  data: null,
  dateRange: [moment().subtract(30,'days'), moment()]
}

const reducer = handleActions(
  {
    [loadData]: (state, action) => assign({}, state, { data: action.payload }),
    [clearData]: () => null,
    [setDateRange]: (state, action) => assign({}, state, { dateRange: action.payload })
  },
  defaultState
);

export { loadData, clearData, setDateRange, reducer };
