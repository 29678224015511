import { message } from 'antd'
import { resetUserPassword } from '../../../HttpRequests/users'

export default (id, values) => async () => {
    
  const data = {
    password: values.password,
  }

  const response = await resetUserPassword({ id, data })

  const user = response.data

  message.success('Password reset')

  return user

}
