import { createAction, handleActions } from 'redux-actions'

const setProjects = createAction('SCREENS/TIMESHEET_POSTING/SET_PROJECTS')
const resetProjects = createAction('SCREENS/TIMESHEET_POSTING/RESET_PROJECTS')

const defaultState = []

const reducer = handleActions(
  {
    [setProjects]: (state, action) => action.payload,
    [resetProjects]: () => defaultState,
  },
  defaultState,
)

export { setProjects, resetProjects, reducer }
