exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._1-RIFhEaWCsNDcC1QQlL6p {\n  text-align: right !important;\n}\n\n._18CXwoCDy6ApKHqT0I_Gms {\n  color: rgb(0, 168, 0);\n}\n\n._1jFRn_Pz6ik5hStBW1dMLn {\n  color: red;\n}\n", "", {"version":3,"sources":["/builds/sota-projects/vividfinishes/frontend/src/Screens/Insights/Components/EstimatesTable.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;CAC9B;;AAED;EACE,sBAAsB;CACvB;;AAED;EACE,WAAW;CACZ","file":"EstimatesTable.css","sourcesContent":[".numericColumn {\n  text-align: right !important;\n}\n\n.positive {\n  color: rgb(0, 168, 0);\n}\n\n.negative {\n  color: red;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"numericColumn": "_1-RIFhEaWCsNDcC1QQlL6p",
	"positive": "_18CXwoCDy6ApKHqT0I_Gms",
	"negative": "_1jFRn_Pz6ik5hStBW1dMLn"
};