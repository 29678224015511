import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import Screen from '../../Components/Screen'

import Initializing from './Components/Initializing'
import Breadcrumbs from './Components/Breadcrumbs'
import Header from './Components/Header'
import Tabs from './Components/Tabs'
import Items from './Components/Items'
import Footer from './Components/Footer'
import Sidebar from './Components/Sidebar'
import SubOrderModal from '../../Modals/SubOrder'
import EmailSubOrderModal from '../../Modals/EmailSubOrder'

const SubOrder = props => {

  const {
    subOrder,
    activeTab,
    subOrderModal,
    emailSubOrderModal,
    componentDidMount,
    componentWillUnmount,
    onCloseSubOrderModal,
    onCloseEmailSubOrderModal,
  } = props

  useEffect(() => {
    componentDidMount(props)
    return componentWillUnmount
  }, [])

  if (!subOrder) return <Initializing />

  return (

    <Screen style={{ marginRight: 280, paddingRight: 30 }}>

      <Breadcrumbs subOrder={subOrder} />

      <Divider />

      <div style={{ fontSize: 16, color: '#999', marginBottom: 8 }}>Sub Order</div>

      <Header />

      <Tabs />

      {activeTab === 'ITEMS' && <Items />}

      <Footer />

      <Sidebar />

      {subOrderModal.show && (
        <SubOrderModal
          {...subOrderModal}
          subOrder={subOrder}
          onClose={onCloseSubOrderModal}
        />
      )}

      {emailSubOrderModal.show && (
        <EmailSubOrderModal
          {...emailSubOrderModal}
          subOrder={subOrder}
          onClose={onCloseEmailSubOrderModal}
        />
      )}
      
    </Screen>
    
  )

}

SubOrder.propTypes = {
  subOrder: PropTypes.object,
  activeTab: PropTypes.string.isRequired,
  subOrderModal: PropTypes.object.isRequired,
  componentDidMount: PropTypes.func.isRequired,
  componentWillUnmount: PropTypes.func.isRequired,
  onCloseSubOrderModal: PropTypes.func.isRequired,
}

SubOrder.defaultProps = {
  subOrder: null,
}

export default SubOrder
