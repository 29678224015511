import { fromJS } from 'immutable';
import { loadOrders } from '../State/orders'

export default ({ orderIndex, orderGroupIndex, orderItemIndex }) => (dispatch, getState) => {

  let orders = fromJS(getState().screens.ProjectView.TargetCosts.orders);

  const orderItem = orders.getIn([
    orderIndex,
    'order_groups',
    orderGroupIndex,
    'order_items',
    orderItemIndex,
  ]).toJS();

  let targetCostPercentage = Number(orderItem.total) !== 0 ? ((Number(orderItem.target_cost_amount) / Number(orderItem.total)) * 100) : 100;
  
  targetCostPercentage = Number(targetCostPercentage.toFixed(6));

  orders = orders.updateIn(
    [orderIndex,'order_groups',orderGroupIndex,'order_items',orderItemIndex],
    map => map.merge(fromJS({
      target_cost_percentage: targetCostPercentage,
    }))
  );

  orders = orders.toJS();

  dispatch(loadOrders(orders));

}