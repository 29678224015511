import { connect } from 'react-redux'
import Component from './Component'

import onChangeTable from './Handlers/onChangeTable'
import onClickRecord from './Handlers/onClickRecord'
import onClickActionMenuItem from './Handlers/onClickActionMenuItem'
import onChangeProjectField from './Handlers/onChangeProjectField'

const mapStateToProps = (state) => ({
  projects: state.screens.RamsRecords.projects,
  pagination: state.screens.RamsRecords.pagination,
  fetching: state.screens.RamsRecords.fetching,
})

const actionCreators = {
  onChangeTable,
  onClickRecord,
  onClickActionMenuItem,
  onChangeProjectField,
}

export default connect(mapStateToProps, actionCreators)(Component)
