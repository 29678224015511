import React from 'react'
import { Modal } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import updateStatus from '../../../Services/updateStatus'

export default (id, currentStatus, newStatus) => (dispatch) => {

  if (newStatus === 'Pending') {
    Modal.confirm({
      title: 'Revert to "Pending"',
      content: 'Are you sure you want to revert this valuation\'s status to "Pending"?',
      icon: <WarningFilled style={{ color: 'orange' }} />,
      okText: 'Revert',
      onOk: () => dispatch(updateStatus(id, newStatus)),
    })
    return
  }

  if (newStatus === 'Approved' && currentStatus === 'Paid') {
    Modal.confirm({
      title: 'Revert to "Approved"',
      content: 'Are you sure you want to revert this valuation\'s status to "Approved"?',
      icon: <WarningFilled style={{ color: 'orange' }} />,
      okText: 'Revert',
      onOk: () => dispatch(updateStatus(id, newStatus)),
    })
    return
  }
  
  dispatch(updateStatus(id, newStatus))

}
