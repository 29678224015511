// @flow

import * as React from 'react';
import { Modal } from 'antd';
import Form from './Form';
import ProjectModal from '../Project'
import ClientContactModal from '../ClientContact'

type Props = {
  projects: [],
  users: [],
  clientContacts: [],
  clientId: any,
  submitting: boolean,
  showNewClientContactButton: boolean,
  showProjectModal: boolean,
  showClientContactModal: boolean,

  componentWillMount: (props: any) => void,
  componentWillUnmount: Function,
  didCreateClientContact: Function,
  didCreateProject: Function,
  onClickCancel: Function,
  onClickSubmit: Function,
  onChangeProject: Function,
  onClickNewProject: Function,
  onClickNewClientContact: Function,
  onCloseProjectModal: Function,
  onCloseClientContactModal: Function
}

export default class extends React.PureComponent<Props> {

  componentWillMount = () => this.props.componentWillMount(this.props);

  componentWillUnmount = () => this.props.componentWillUnmount();

  render () {

    const {
      projects,
      users,
      clientContacts,
      clientId,
      submitting,
      showNewClientContactButton,
      showProjectModal,
      showClientContactModal,
      
      didCreateClientContact,
      didCreateProject,
      onClickCancel,
      onClickSubmit,
      onChangeProject,
      onClickNewProject,
      onClickNewClientContact,
      onCloseProjectModal,
      onCloseClientContactModal
    } = this.props;

    return (
        
      <Modal
        title='New Estimate'
        visible
        onOk={onClickSubmit}
        onCancel={onClickCancel}
        confirmLoading={submitting}
        okText='Create'
        width={650}
      >
        
        <Form
          projects={projects}
          users={users}
          clientContacts={clientContacts}
          showNewClientContactButton={showNewClientContactButton}
          onClickNewProject={onClickNewProject}
          onClickNewClientContact={onClickNewClientContact}
          onChangeProject={onChangeProject}
        />

        {showProjectModal && (
          <ProjectModal
            onClose={onCloseProjectModal}
            didCreate={didCreateProject}
            clientId={clientId}
          />
        )}

        {showClientContactModal && (
          <ClientContactModal
            onClose={onCloseClientContactModal}
            didCreate={didCreateClientContact}
            clientId={clientId}
          />
        )}

      </Modal>
    )
  }
}