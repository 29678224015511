import { createAction, handleActions } from 'redux-actions'

const setShowRetentionPaymentModal = createAction("SCREENS/RETENTION_PAYMENTS/SET_SHOW_RETENTION_PAYMENT_MODAL")

const defaultState = false

const reducer = handleActions(
  {
    [setShowRetentionPaymentModal]: (state, action) => action.payload,
  },
  defaultState
)

export { setShowRetentionPaymentModal, reducer }